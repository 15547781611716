import {createMessage} from 'fiba/common/core/fibaError';
import ResultsGameSummary from 'fiba/common/core/models/api/results/ResultsGameSummary';

export function abbreviateGroupName(game?: Partial<ResultsGameSummary>): string {
  if (!game) {
    return '';
  }

  const groupName = game.groupName;
  if (game.gameIsPlayIn) {
    return 'PI';
  }
  if (/pool/i.test(groupName)) {
    // Pass e.g. Pool C as is
    return groupName;
  }
  if (/qualifying\s+draw/i.test(groupName)) {
    // Qualifiers
    return 'QD';
  }
  if (!/final/i.test(groupName)) {
    const message = `Unexpected game group name (${groupName})`;
    createMessage(message);
  }
  // e.g. Semi-Final, Final or Quarter-Final
  return groupName
    .split('-')
    .map(([firstLetter = '']) => firstLetter.toUpperCase())
    .join('');
}
