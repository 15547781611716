import CommonServices from 'fiba/common/core/models/CommonServices';
import tourTeamResultsActions from 'fiba/wt/stores/tourTeamResultsStore';

function tourTeamResultsController(tourId: string, tourTeamId: string) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['tourTeamResults', tourTeamId], () =>
      dispatch(tourTeamResultsActions.loadTourTeamResults(tourId, tourTeamId)),
    ).catch(e => {
      // TourTeamResults might fail with a 404 in certain cases
      // We handle this in the view layer
      // If we don't catch here the page crashes due to missing data

      //eslint-disable-next-line
      console.log(e);
    });
  };
}

export default tourTeamResultsController;
