import {Map, List, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import newsActions from 'fiba/wt/stores/newsStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Team, TeamLike} from 'fiba/common/core/models/api/teams/Team';
import {
  TeamPaymentDetails,
  TeamPaymentDetailsLike,
} from 'fiba/common/core/models/api/payment/TeamPaymentDetails';
import {NewsListLike} from 'fiba/common/core/models/api/feed/NewsList';
import {RootState} from './rootStore';
import {convertTeamLikeToTeam} from 'fiba/common/core/team';

export const storePath = 'teams';

export interface TeamStoreState extends Map<string, Team> {
  // TODO: `__meta`
}

const reducers = {
  loadTeam: (state: TeamStoreState, teamId: string): TeamStoreState =>
    state.updateIn(['__meta', teamId], setMeta.isLoading),

  loadTeamSuccess: (state: TeamStoreState, team: TeamLike): TeamStoreState => {
    const newTeam = convertTeamLikeToTeam(team);
    return state.set(team.id, newTeam).updateIn(['__meta', team.id], setMeta.isLoaded);
  },

  loadTeamError: (state: TeamStoreState, teamId: string, error: Error): TeamStoreState =>
    state.updateIn(['__meta', teamId], setMeta.isError),

  loadTeamPayment: (state: TeamStoreState, teamId: string): TeamStoreState =>
    state.updateIn(['__meta', 'payment', teamId], setMeta.isLoading),

  loadTeamPaymentSuccess: (
    state: TeamStoreState,
    teamId: string,
    payment: TeamPaymentDetailsLike,
  ): TeamStoreState =>
    state
      .setIn(['payment', payment.teamId], TeamPaymentDetails.fromJS(payment))
      .updateIn(['__meta', 'payment', payment.teamId], setMeta.isLoaded),

  loadTeamPaymentError: (state: TeamStoreState, teamId: string, error: Error): TeamStoreState =>
    state.updateIn(['__meta', 'payment', teamId], setMeta.isError),

  loadTeamsByEventSuccess: (
    state: TeamStoreState,
    eventId: string,
    teams: TeamLike[],
  ): TeamStoreState => teams.reduce(reducers.loadTeamSuccess, state),

  loadTeamsByCategorySuccess: (
    state: TeamStoreState,
    eventId: string,
    teams: TeamLike[],
  ): TeamStoreState => teams.reduce(reducers.loadTeamSuccess, state),
};

const externalReducers = {
  //
  // Team news
  [newsActions.types.loadNewsByTeam]: (
    state: TeamStoreState,
    teamId: string,
    eventId: string,
  ): TeamStoreState => state.updateIn(getNewsInternalMetaPath(teamId, eventId), setMeta.isLoading),

  [newsActions.types.loadNewsByTeamSuccess]: (
    state: TeamStoreState,
    teamId: string,
    eventId: string,
    news: NewsListLike,
  ): TeamStoreState =>
    state
      .updateIn(getNewsInternalMetaPath(teamId, eventId), setMeta.isLoaded)
      .setIn(
        getNewsRefsInternalMetaPath(teamId, eventId),
        List(news.items.map(newsItem => newsItem.slug)),
      ),

  [newsActions.types.loadNewsByTeamError]: (
    state: TeamStoreState,
    teamId: string,
    eventId: string,
    error: Error,
  ): TeamStoreState => state.updateIn(getNewsInternalMetaPath(teamId, eventId), setMeta.isError),
};

export const reducer = createReducer<TeamStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

export const actions = createActions(__filename, reducers, {
  loadTeam: (teamId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTeam(teamId)
      .then(res => actions.loadTeamSuccess(res.data))
      .catch(rethrow((err: Error) => actions.loadTeamError(teamId, err))),

  loadTeamError: (teamId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadTeamPayment: (teamId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTeamPayment(teamId)
      .then(res => actions.loadTeamPaymentSuccess(teamId, res.data))
      .catch(rethrow((err: Error) => actions.loadTeamPaymentError(teamId, err))),

  loadTeamsByEvent: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTeamsByEvent(eventId)
      .then(res => actions.loadTeamsByEventSuccess(eventId, res.data.teams)) // We are only interested in the list of teams
      .catch(rethrow((error: Error) => actions.loadTeamsByEventError(eventId, error))),

  loadTeamsByEventError: (eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadTeamsByCategory: (categoryId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTeamsByCategory(categoryId)
      .then(res => actions.loadTeamsByCategorySuccess(categoryId, res.data))
      .catch(rethrow((error: Error) => actions.loadTeamsByCategoryError(categoryId, error))),

  loadTeamsByCategoryError: (categoryId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<TeamStoreState>(Team);

// Selectors
export const getTeam = (state: RootState, teamId: string): Team | undefined =>
  state.getIn([storePath, teamId]) as Team;

export const getTeamMeta = (state: RootState, teamId: string): Map<any, any> | undefined =>
  state.getIn([storePath, '__meta', teamId]);

export const getTeamMetaOrEmpty = (state: RootState, teamId: string): Map<any, any> =>
  state.getIn([storePath, '__meta', teamId], Map());

export const getTeamNewsReferences = (state: RootState, teamId: string, eventId: string) =>
  state.getIn([storePath, ...getNewsRefsInternalMetaPath(teamId, eventId)], List());

export const getTeamNewsMetaPathParts = (teamId: string, eventId: string) => [
  storePath,
  ...getNewsInternalMetaPath(teamId, eventId),
];

export const getTeamNewsMetaPath = (teamId: string, eventId: string) =>
  getTeamNewsMetaPathParts(teamId, eventId).join('/');

const getNewsInternalMetaPath = (teamId: string, eventId: string) => [
  '__meta',
  teamId,
  'news',
  eventId,
];

const getNewsRefsInternalMetaPath = (teamId: string, eventId: string) => [
  ...getNewsInternalMetaPath(teamId, eventId),
  'refs',
];
