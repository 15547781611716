import teamRankingsActions from 'fiba/wt/stores/teamRankingsStore';
import CommonServices from 'fiba/wt/core/models/CommonServices';

interface Props {
  tourId: string;
}

export function teamRankingsController({tourId}: Props) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['teamRankings', tourId], () =>
      dispatch(teamRankingsActions.loadRankings(tourId)),
    );
  };
}
