import React from 'react';
import cx from 'classnames';
import {generateSizes, SizesSpec} from 'fiba/wt/utils/imageUtils';
import {StyleProp, superstyled, Omit, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {BoxStyleProps} from 'fiba/wt/ui/box/Box';
import {Placeholder, PlaceholderStyleProps} from 'fiba/wt/ui/placeholder/Placeholder';

// Image component that enforces alt attribute and provides a Box styling API

interface IImageOwn {
  src?: string;
  srcSet?: string;
  sizes?: SizesSpec;
  alt: string;
  title?: string;
  htmlWidth?: number;
  htmlHeight?: number;
  className?: string;
}

// Merge Own and Styled props.
// Exclude the default sizes definition, since ours supersedes it.
export type IImage = IImageOwn & Omit<StyledHTML<'img'>, 'sizes'>;

/* Styleable img component internals.
 * We have to pass around the html width and height props correctly,
 * so we prefix them.
 */
const ImageImpl: React.FunctionComponent<IImage> = ({
  className,
  sizes,
  htmlWidth,
  htmlHeight,
  ...rest
}) => (
  <img
    className={className}
    sizes={generateSizes(sizes)}
    width={htmlWidth && htmlWidth}
    height={htmlHeight && htmlHeight}
    {...rest}
  />
);

/* Styleable img component
 */
export const Image = superstyled<BoxStyleProps, IImage>(ImageImpl);

type IImageWithPlaceholder = IImage & {
  aspectRatio: StyleProp<'aspectRatio'>;
  containerClassName?: string;
  containerStyle?: PlaceholderStyleProps;
};

/** Image that holds its place based on an aspectRatio */
export const ImageWithPlaceholder: React.SFC<IImageWithPlaceholder> = ({
  aspectRatio,
  containerStyle,
  containerClassName,
  className,
  // NOTE: Putting ref out here because TS complains about it being LegacyRef when Image expects RefObject
  // Ref is not even a prop! So we won't be passing it down anyway...
  ref,
  ...imageProps
}) => {
  return (
    <Placeholder
      aspectRatio={aspectRatio}
      extraClassName={containerClassName}
      {...containerStyle}
      image={placeholderItemCls => (
        <Image className={cx(placeholderItemCls, className)} {...imageProps} />
      )}
    />
  );
};

ImageWithPlaceholder.displayName = 'ImageWithPlaceholder';
