const toJS = o => (o && typeof o.toJS === 'function' ? o.toJS() : o);

function serializePayload(payload) {
  const o = toJS(payload);

  // TODO: This will print `'[object Object]'` as the payload on server,
  // make it print the payload as is or limit it if it exceeds N size in chars
  return o && typeof o === 'object' && typeof window === 'undefined' ? o.toString() : o;
}

function serialize(action) {
  if (!action) {
    return action;
  }

  if (Array.isArray(action)) {
    return action.map(serialize);
  }

  if (typeof action.payload === 'undefined') {
    return action;
  }

  return {...action, payload: serializePayload(action.payload)};
}

function createLoggerMiddleware(msg) {
  return (/* store */) => next => action => {
    // eslint-disable-next-line no-console
    console.log(msg, serialize(action));
    return next(action);
  };
}

export default createLoggerMiddleware;
