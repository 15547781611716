import React from 'react';
import {routerPushLocation, embedHref} from 'fiba/common/services/routerService';
import {connect} from 'fiba/common/utils/reactUtils';

interface OwnProps {
  // TODO: Make href mandatory, and any SimpleLink with an action a button
  href: string;
  className?: string;
  tagName?: string;
  isExternal?: boolean;
  /** A plug-in function to hook into the onClick; gets called with the path */
  // TODO: Consider removing this altogether in another branch
  onClickInternal?: (path: string) => void;
  displayAsButton?: boolean;
}

/** Props plucked from the Redux state */
interface StateProps {
  host: string;
}

interface DispatchProps {
  onClick: (ev: React.SyntheticEvent) => Promise<void>;
}

/** Dispatch props that need config from Redux/State props to be complete */
interface PartialDispatchProps {
  onClick: (host: string) => (ev: React.SyntheticEvent) => Promise<void>;
}

type Props = OwnProps & StateProps & DispatchProps;

const SimpleLink: React.FC<Props & JSX.IntrinsicElements['a']> = props => {
  const {
    tagName,
    onClickInternal,
    children,
    isExternal,
    host,
    displayAsButton,
    className = 'SimpleLink',
    ...rest
  } = props;
  const buttonStyle = displayAsButton ? 'SimpleLink-Button' : '';

  return React.createElement(
    tagName || 'a',
    {
      className: `${buttonStyle} ${className} `,
      target: isExternal ? '_blank' : undefined,
      rel: isExternal ? 'noopener noreferrer' : '',
      ...rest,
    },
    children,
  );
};

const mapStateToProps = (state, {href, isExternal}: OwnProps) => ({
  // NOTE: Don't force embed url when `isExternal` is set to true,
  // also don't map null or empty `href` value into embed href
  href: !isExternal ? href && embedHref(href, state.getIn(['route', 'pathname'])) : href,
  host: state.getIn(['route', 'host']),
});

const mapPropsToDispatch = (
  dispatch,
  {href, isExternal, onClickInternal}: OwnProps,
): PartialDispatchProps => ({
  onClick: (host: string) => async (ev: React.SyntheticEvent) => {
    const anchor = ev.currentTarget as HTMLAnchorElement;

    // We have 3 different types of links that we need to support here
    // - Internal links inside the play site/domain that are handled with pushState
    // - External links with different domain that are handled by browser's default behaviour
    // - Any link that is opened in a new tab, handled by browser's default behaviour
    //
    // Note that latter 2 types of links can't be handled if you provide something else
    // for `tagName` than `a`
    //
    // And here's how we handle each of these cases:

    // Links that are opened in new tab, revert to browser's default behaviour. Properties
    // to open the link in new tab (`target`, `rel`) are generated to the underlying DOM element
    if (isExternal) {
      return;
    }

    // External links with different domain, revert to browser's default behaviour
    // NOTE: `host` is trickled down from `mapStateToProps` above via `mergeProps` below
    if (anchor.tagName === 'A' && anchor.href && host !== anchor.host) {
      return;
    }

    // Finally internal links when user clicked the link with left mouse button
    // that are handled via pushState
    const nativeEvent = ev.nativeEvent as MouseEvent;
    // Only apply to left-clicks
    // Only apply if no ctrl, shift, meta keys are pressent
    if (
      // Defer to the browser for modifier keys
      // For example, meta/ctrl + click opens pages in a new tab
      !nativeEvent.ctrlKey &&
      !nativeEvent.metaKey &&
      !nativeEvent.shiftKey &&
      // Only apply to "left" clicks
      nativeEvent.button < 1 &&
      // The anchor does not have a target attribute set to a value (not "", the default)
      !anchor.hasAttribute('target') &&
      // Ignore anchors with download intent
      !anchor.hasAttribute('download')
    ) {
      ev.preventDefault();

      // Construct a path that can be dispatched via router
      const path =
        anchor.tagName === 'A' ? `${anchor.pathname}${anchor.search}${anchor.hash}` : href;

      if (path && path !== '#') {
        await dispatch(routerPushLocation(path));
        // run onClickInternal after the route has changed it has access to the new DOM:
        if (onClickInternal) {
          onClickInternal(path);
        }
      }
    }
  },
});

export const mergeProps = (
  stateProps: StateProps,
  dispatchProps: PartialDispatchProps,
  ownProps: OwnProps,
): Props => ({
  ...ownProps,
  ...stateProps,
  ...dispatchProps,

  onClick: dispatchProps.onClick(stateProps.host),
});

export default connect<StateProps, PartialDispatchProps, OwnProps, Props>(
  mapStateToProps,
  mapPropsToDispatch,
  mergeProps,
)(SimpleLink);
