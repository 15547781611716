import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {ProCircuitTourTeam} from 'fiba/common/core/models/api/tour/ProCircuitTourTeam';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Link} from 'fiba/wt/ui/link/Link';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {getProCircuitTourTeams} from 'fiba/wt/stores/tourStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {RootState} from 'fiba/wt/stores/rootStore';
import {tourTeamPlayerLinkProvider} from 'fiba/wt/utils/linkUtils';
import {TeamList} from 'fiba/common/ui/teamList/TeamList';
import TourTeam from 'fiba/common/core/models/api/tour/TourTeam';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';

interface OwnProps {
  season: string;
  tourId: string;
}

interface ReduxProps {
  teams: List<ProCircuitTourTeam>;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state: RootState, {season}: OwnProps): ReduxProps => {
  return {
    teams: getProCircuitTourTeams(season, state.get('tours'), state.get('proCircuitTourTeams'))
      .sortBy(team => team.name)
      .toList(),
    metaPaths: [`tours/__meta/${season}/proCircuitTourTeams`],
  };
};

const TourProCircuitTeamsTableImpl: React.FC<Props> = ({season, teams, metaPaths}) => {
  const TeamTitle = ({team}: {team: TourTeam}) => (
    <SubHeading fontSize={['6', '5']}>
      {team.id ? (
        <Link href={`/${season}/teams/${team.id}`}>
          <TeamNameAssembly team={team} isFlagVisible isSuffixVisible />
        </Link>
      ) : (
        <TeamNameAssembly team={team} isFlagVisible isSuffixVisible />
      )}
    </SubHeading>
  );

  return (
    <div>
      <Loading metaPaths={metaPaths}>
        {() =>
          teams.size > 0 ? (
            <CardContainer pa="0" debugClassName="ProCircuitTourTeamsTable">
              <TeamList
                getPlayerLink={(teamId, playerId) =>
                  tourTeamPlayerLinkProvider(season, teamId)(playerId)
                }
                teamTitle={TeamTitle}
                teams={teams}
              />
            </CardContainer>
          ) : (
            <p className="tc">There are no teams yet.</p>
          )
        }
      </Loading>
    </div>
  );
};

export const TourProCircuitTeamsTable = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  TourProCircuitTeamsTableImpl,
);
