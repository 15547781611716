import React from 'react';
import cx from 'classnames';
import {ExternalLink} from '@fpapado/react-feather';
import {Tab, NavLinkStyle} from 'fiba/wt/ui/navigation/Navigation';
import {Link} from 'fiba/wt/ui/link/Link';
import {SubMenu} from 'fiba/wt/ui/sidebarNavigation/SubMenu';
import {omit} from 'lodash';

interface NavigationListOwnProps {
  tabs: Tab[];
  label?: string;
  onClick?: () => any;
  // Refs in case the caller wants to do something interesting, e.g. set up an IntersectionObserver
  firstItemRef?: React.Ref<HTMLLIElement>;
  lastItemRef?: React.Ref<HTMLLIElement>;
}

interface NavigationListStyleProps {
  navLinkStyle: NavLinkStyle;
  linkExtraClassName?: string;
  listItemExtraClassName?: string;
  extraClassName?: string;
  colorTheme?: 'dark' | 'light';
}

export type NavigationListProps = NavigationListOwnProps & NavigationListStyleProps;

export const NavigationList: React.FunctionComponent<NavigationListProps> = props => {
  const {
    tabs,
    onClick,
    navLinkStyle,
    label,
    colorTheme = 'dark',
    linkExtraClassName,
    listItemExtraClassName,
    extraClassName,
    firstItemRef,
    lastItemRef,
  } = props;
  return (
    <ul className={extraClassName} aria-label={label ? label : null}>
      {tabs.map((tab, index) => {
        if (!tab) {
          return null;
        }

        const {name, href, active, opensInNewTab, children} = tab;
        let itemRef: React.Ref<HTMLLIElement>;

        if (index === 0) {
          itemRef = firstItemRef;
        } else if (index === tabs.length - 1) {
          itemRef = lastItemRef;
        }

        return (
          <li key={name} className={cx(listItemExtraClassName)} ref={!!itemRef ? itemRef : null}>
            {children ? (
              <SubMenu
                parent={tab}
                {...omit(props, ['firstItemRef', 'lastItemRef'])}
                tabs={children}
              />
            ) : (
              <Link
                href={href}
                isExternal={opensInNewTab}
                colorTheme={active ? 'dark' : colorTheme}
                onClick={() => onClick && onClick()}
                extraClassName={cx(
                  'db link link--bare',
                  linkExtraClassName,
                  {[navLinkStyle.idle]: !active},
                  {[navLinkStyle.active]: active},
                )}
              >
                {name}
                {opensInNewTab ? (
                  <ExternalLink purpose="decorative" className="w1 mh1 mb1 v-mid" />
                ) : (
                  ''
                )}
              </Link>
            )}
          </li>
        );
      })}
    </ul>
  );
};
