import React from 'react';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {TourPhotoGallery} from 'fiba/wt/ui/tourPhotoGallery/TourPhotoGallery';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface Props {
  season: string;
  tourId: string;
}

export const TourPhotosPage: React.SFC<Props> = ({season, tourId}) => (
  <section>
    <PageHeading text="Photos" />
    <Section>
      <TourPhotoGallery tourId={tourId} season={season} />
    </Section>
  </section>
);
