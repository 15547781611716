import {
  isArray,
  isFunction,
  isPlainObject,
  some,
  identity,
  map,
  fromPairs,
  forEach,
  head,
} from 'lodash';
import {Reducer} from 'redux';
import {setMeta, getMeta} from 'fiba/common/services/cacheService';
import CommonServices from 'fiba/common/core/models/CommonServices';

export {setMeta, getMeta};

export function stripNamespace(ns: string) {
  const cwd = process.cwd();
  const reroot = ns.slice(0, cwd.length) === cwd ? ns.slice(cwd.length) : ns;
  const stripped = reroot.replace(/(\.ts|\.tsx)$/, '');
  return stripped[0] === '/' ? stripped.slice(1) : stripped;
}

interface FibaEnhancedReducerExtras<S> {
  initialState: S;
}

type FibaReducerMap<R> = {[K in keyof R]: R[K]};

type FibaEnhancedReducer<R, S> = Reducer<S> & FibaEnhancedReducerExtras<S> & FibaReducerMap<R>;

export function createReducer<R, S>(
  ns: string,
  reducers: R,
  initialState?: S,
): FibaEnhancedReducer<R, S> {
  const _ns = stripNamespace(ns);

  // Wrap the reducers with function that will pass `(state, payload, error?)` as parameters to the reducer
  const wrappedReducers = fromPairs(
    map(reducers as {[key: string]: any}, (reducer, key) => [
      _ns && key.indexOf('/') < 0 ? `${_ns}/${key}` : key,
      reducer,
    ]),
  );

  // Make the reducers available by accessing reducer[actionType]
  forEach(reducers as {[key: string]: any}, (r, key) => (reducer[key] = r));

  // Make sure the initialState is accessible via reducer
  (reducer as FibaEnhancedReducer<R, S>).initialState = initialState;

  return reducer as FibaEnhancedReducer<R, S>;

  function reducer(state = initialState, action) {
    const type = action && action.type;
    if (type && type in wrappedReducers) {
      return wrappedReducers[type](state, action.payload, action.error);
    }
    return state;
  }
}

const mapActionOrPayload = (type: string, payload, args) => {
  if (isArray(payload)) {
    return payload.map(_payload => mapActionOrPayload(type, _payload, args));
  }

  if (payload && (typeof payload.type !== 'undefined' || typeof payload.then !== 'undefined')) {
    return payload; // Assume FSA compatible action
  }

  if (isFunction(payload)) {
    return (services: CommonServices) => mapActionOrPayload(type, payload(services), args);
  }

  // Wrap the payload as payload into an FSA
  const action = {type};

  // FSA error handling, if any of the arguments is an error, mark it as error
  if (some(arg => arg instanceof Error, args)) {
    action['error'] = true;
  }

  // Assume that `payload` is a payload for an action with type `type`
  if (typeof payload !== 'undefined') {
    action['payload'] = payload;
  }

  return action;
};

/**
 * Takes a map of action creators and returns a map of wrapped action creators
 * that set the action to the name of the action creator if the type is not already set
 *
 * @param  {string}   type - Action type
 * @param  {Function} fn   - Function that generates the payload
 * @return {object}        - FSA conformant action
 */
export function createActionCreator(type: string, fn = identity) {
  return (...args) => {
    // TODO: consider fixing; it will be ok once we move the tech from WT
    // @ts-ignore
    const action = fn(...args);

    return mapActionOrPayload(type, action, args);
  };
}

export function createActionTypes(ns: string, actionMap): {[key: string]: string} {
  const _ns = stripNamespace(ns);

  return fromPairs(map(actionMap, (_, type) => [type, _ns ? `${_ns}/${type}` : type.toString()]));
}

interface ActionTypes<M> {
  types: {[K in keyof M]: string};
}

type ActionCreators<M> = {[K in keyof M]: M[K]};

type ActionCreatorsWithTypes<M> = ActionCreators<M> & ActionTypes<M>;

/**
 * Given a map of action creators, converts the functions with createAction with each
 * action creator generating an action with same type as the key in the map
 *
 * @param   {object} actionMap  - Map of action creators to map
 * @param   {string} [ns]       - Prefix the action types with this namespace
 * @return  {object}            - Converted map of action creators
 */
export function createActions<M>(ns: string, actionMap: M): ActionCreatorsWithTypes<M> {
  const _ns = stripNamespace(ns);
  const mappedActionMap = fromPairs(
    map(actionMap as {[key: string]: any}, (fn, type) => [
      type,
      createActionCreator(_ns ? `${_ns}/${type}` : type, fn as (...args: any[]) => any),
    ]),
  );
  mappedActionMap.types = createActionTypes(ns, actionMap) as any;
  return (mappedActionMap as unknown) as ActionCreators<M> & ActionTypes<M>;
}

// Helper to rethrow errors with dispatching intermediate actions from `catch` handlers
export function rethrow(handler) {
  // Return a `Promise.catch` handler that will dispatch
  return value => ({dispatch}) => {
    dispatch(handler(value));
    return Promise.reject(value);
  };
}

// Helper to ignore errors in `catch` handlers
export const nocatch = x => x;

export function bind(action) {
  if (isArray(action)) {
    return Promise.all(action);
  }
  if (action && isFunction(action.then)) {
    return action;
  }

  return Promise.resolve(action);
}

//
// Helper function to find an action with given type from the result of dispatched
// asynchronous action that might be an array resolved by `Promise.all`
export const findActionFromActionResults = (type: string, action) => {
  if (isArray(action)) {
    return head(action.map(item => findActionFromActionResults(type, item)).filter(Boolean));
  }

  if (isPlainObject(action) && typeof action.type !== 'undefined' && action.type === type) {
    return action;
  }
};
