import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Photo} from 'fiba/common/core/models/api/media/Photo';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {PhotoGallerySummary} from 'fiba/wt/ui/photoGallery/PhotoGallerySummary';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';

interface ITeamPhotos {
  teamId: string;
  createGalleryHref: ResourceLinkProvider;
}

interface ITeamPhotosState {
  photos: List<Photo>;
  metaPaths: string[];
}

type TeamPhotosProps = ITeamPhotos & ITeamPhotosState;

const mapStateToProps = (state, {teamId}: ITeamPhotos): ITeamPhotosState => ({
  photos: state.getIn(['media', 'photos', 'teams', teamId], List()),
  metaPaths: [`media/__meta/photos/teams/${teamId}`],
});

const _TeamPhotos: React.FunctionComponent<TeamPhotosProps> = ({
  teamId,
  photos,
  createGalleryHref,
  metaPaths,
}) => (
  <div className="TeamPhotos">
    <Loading metaPaths={metaPaths}>
      {() =>
        photos.size ? (
          <PhotoGallerySummary
            variant="standalone"
            title="Team Photos"
            imageUrl={photos.first().imageBaseUrl}
            galleryUrl={createGalleryHref(teamId)}
            total={photos.size}
          />
        ) : null
      }
    </Loading>
  </div>
);

export const TeamPhotos = connect<ITeamPhotosState, {}, ITeamPhotos>(mapStateToProps)(_TeamPhotos);
