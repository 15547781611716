/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultTourTeamResults = {
  tourId: null,
  teamId: null,
  standing: null,
  gamesPlayed: null,
  gamesWon: null,
  eventResults: null,
};
export const defaultTourTeamEventResult = {
  eventId: null,
  isMaster: null,
  teamId: null,
  eventName: null,
  eventShortName: null,
  start: null,
  end: null,
  standing: null,
  points: null,
  qualifiedAs: null,
  qualifiedToFinal: null,
  qualifiedFrom: null,
};
export const defaultTourTeamQualifiedSource = {type: null, id: null, name: null};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TourTeamResultsLike = {
  [K in keyof typeof defaultTourTeamResults]?: TourTeamResults[K];
};
export type TourTeamEventResultLike = {
  [K in keyof typeof defaultTourTeamEventResult]?: TourTeamEventResult[K];
};
export type TourTeamQualifiedSourceLike = {
  [K in keyof typeof defaultTourTeamQualifiedSource]?: TourTeamQualifiedSource[K];
};

export class TourTeamResults extends Record(defaultTourTeamResults) {
  tourId?: string;
  teamId?: string;
  standing?: number;
  gamesPlayed?: number;
  gamesWon?: number;
  eventResults?: List<TourTeamEventResult>;
  static fromJS(o: TourTeamResultsLike): TourTeamResults {
    return (
      o &&
      new TourTeamResults({
        tourId: o.tourId,
        teamId: o.teamId,
        standing: o.standing,
        gamesPlayed: o.gamesPlayed,
        gamesWon: o.gamesWon,
        eventResults:
          o.eventResults && List(o.eventResults).map(i => i && TourTeamEventResult.fromJS(i)),
      })
    );
  }
}
export class TourTeamEventResult extends Record(defaultTourTeamEventResult) {
  eventId?: string;
  isMaster?: boolean;
  teamId?: string;
  eventName?: string;
  eventShortName?: string;
  start?: string;
  end?: string;
  standing?: number;
  points?: number;
  qualifiedAs?: string;
  qualifiedToFinal?: boolean;
  qualifiedFrom?: TourTeamQualifiedSource;
  static fromJS(o: TourTeamEventResultLike): TourTeamEventResult {
    return (
      o &&
      new TourTeamEventResult({
        eventId: o.eventId,
        isMaster: o.isMaster,
        teamId: o.teamId,
        eventName: o.eventName,
        eventShortName: o.eventShortName,
        start: o.start,
        end: o.end,
        standing: o.standing,
        points: o.points,
        qualifiedAs: o.qualifiedAs,
        qualifiedToFinal: o.qualifiedToFinal,
        qualifiedFrom: o.qualifiedFrom && TourTeamQualifiedSource.fromJS(o.qualifiedFrom),
      })
    );
  }
}
export class TourTeamQualifiedSource extends Record(defaultTourTeamQualifiedSource) {
  type?: string;
  id?: string;
  name?: string;
  static fromJS(o: TourTeamQualifiedSourceLike): TourTeamQualifiedSource {
    return o && new TourTeamQualifiedSource({type: o.type, id: o.id, name: o.name});
  }
}

export default TourTeamResults;
