import React, {useContext} from 'react';
import {List} from 'immutable';
import Event from 'fiba/common/core/models/api/events/Event';
import {connect} from 'fiba/common/utils/reactUtils';
import {getTourSuperQuests} from 'fiba/wt/stores/tourStore';
import {Box} from 'fiba/wt/ui/box/Box';
import {Link} from 'fiba/wt/ui/link/Link';
import {ServicesContext} from 'fiba/common/services/services';
import Dates from 'fiba/common/utils/Dates';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {eventRootLink} from 'fiba/wt/utils/linkUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {deduceEventType} from 'fiba/wt/stores/eventStore';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface OwnProps {
  tourId: string;
  season: string;
}

interface ReduxProps {
  superQuestData: RemoteData.WebData<List<Event>>;
}

type Props = ReduxProps & OwnProps;

export const mapStateToProps = (state, {tourId}: OwnProps): ReduxProps => {
  const superQuestData = getTourSuperQuests(tourId, state.get('tours'), state.get('events'));

  return {superQuestData};
};

/**
 * A list of SuperQuests for a TourId, in columns.
 *
 * Similar (but not the same!) as the ChallengersPage.
 * SuperQuests do not have any sorting by event, or show "SuperQuest for XYZ" at the moment.
 * This is because SuperQuests have been added exceptionally under
 * the coronavirus crisis.
 *
 * In the future, we might have to show the related Masters names, and sort.
 * In that case, look into ChallengersPage for that logic.
 */
const SuperQuestsPageImpl: React.SFC<Props> = ({season, superQuestData}) => {
  return (
    <section>
      <PageHeading text="Super Quests" />
      <Section>
        <Box pv={['4', '4', '4']} ph={['3', '3', '4']} bgColor="silver-20">
          <ViewWebData data={superQuestData}>
            {superQuests => {
              return <ViewLoaded season={season} superQuests={superQuests} />;
            }}
          </ViewWebData>
        </Box>
      </Section>
    </section>
  );
};

//
// View with the loaded data

interface ViewLoadedProps {
  season: string;
  superQuests: List<Event>;
}

const ViewLoaded: React.FC<ViewLoadedProps> = ({season, superQuests}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);

  // We only set columns if we have more than one item
  // This avoids a Firefox quirk where a single item gets split into
  // columns itself. (This sounds like a bug / quirk in sizing,
  // but tbh no energy to investigate and file. This is fine.)
  const columnStyle: React.CSSProperties =
    superQuests.size > 1 ? {columnWidth: '24rem', columnGap: '1rem'} : {};

  return (
    <ul className="vs4 lh-title" style={columnStyle}>
      {superQuests
        .sortBy(ev => ev.startDate)
        .map((ev: Event) => (
          <li key={ev.id} className="db vs2 f5 f4-m" style={{breakInside: 'avoid'}}>
            <div className="f6 f5-m dark-20">
              {/* TODO: Consider custom format MM DD -DD */}
              {Dates.formatDateRange(localizer, ev.startDate, ev.endDate, 'medium')}
            </div>

            <div>
              <Link
                colorTheme="dark"
                href={eventRootLink(seasonConfig, season, ev.id, deduceEventType(ev))}
              >
                {/* Display a shortname, or, failing that, the full name */}
                {ev.shortName ? `${ev.shortName} SuperQuest` : ev.name}
              </Link>
            </div>
          </li>
        ))}
    </ul>
  );
};

export const SuperQuestsPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  SuperQuestsPageImpl,
);
