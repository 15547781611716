/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultGameReference = {
  eventId: null,
  eventName: null,
  eventShortName: null,
  gameId: null,
  gameHomeTeamName: null,
  gameAwayTeamName: null,
  gameRoundCode: null,
  gameHomeTeamSuffix: null,
  gameAwayTeamSuffix: null,
};
export const defaultTopPlayerStats = {
  playerId: null,
  playerSsoId: null,
  playerFirstName: null,
  playerLastName: null,
  imageUrl: null,
  teamName: null,
  teamId: null,
  teamNameSuffix: null,
  isTourTeam: null,
  value: null,
};
export const defaultTopTeamStats = {
  teamName: null,
  teamId: null,
  teamNameSuffix: null,
  teamNationality: null,
  isTourTeam: null,
  value: null,
};
export const defaultTopTeamWinningPercentageStats = {
  teamName: null,
  teamId: null,
  teamNameSuffix: null,
  teamNationality: null,
  isTourTeam: null,
  value: null,
  gamesWon: null,
  gamesLost: null,
};
export const defaultTopTeamWinBeforeLimitStats = {
  teamName: null,
  teamId: null,
  teamNameSuffix: null,
  teamNationality: null,
  isTourTeam: null,
  value: null,
  gamesWonBeforeLimit: null,
  gamesPlayed: null,
};
export const defaultNotablePlayerPerformanceStats = {
  playerId: null,
  playerSsoId: null,
  playerFirstName: null,
  playerLastName: null,
  imageUrl: null,
  teamName: null,
  teamId: null,
  teamInEventId: null,
  teamNameSuffix: null,
  isTourTeam: null,
  value: null,
  points: null,
  rebounds: null,
  highlights: null,
  game: null,
};
export const defaultTourStatsSummary = {
  topPlayerPlayerValue: null,
  topPlayerScorer: null,
  topPlayerHighlights: null,
  topPlayerKeyAssists: null,
  topPlayerRebounds: null,
  topPlayerBuzzerBeaters: null,
  topPlayerTripleFives: null,
  topPlayerDoubleFives: null,
  topPlayerDrives: null,
  topPlayerDunks: null,
  topPlayerBlocks: null,
  topPlayerTwoPointers: null,
  topPlayerFTPercentage: null,
  topPlayerOnePointPercentage: null,
  topPlayerTwoPointPercentage: null,
  topTeamWinningPercentage: null,
  topTeamWinsBeforeLimit: null,
  topTeamPointsScored: null,
  topTeamShootingValue: null,
  topTeamFewestPointsAllowed: null,
  topTeamHighlights: null,
  topTeamKeyAssists: null,
  topTeamDrives: null,
  topTeamRebounds: null,
  topTeamBuzzerBeaters: null,
  topTeamBlocks: null,
  topTeamFewestTurnOvers: null,
  topTeamFTPrecentage: null,
  topTeamOnePointPercentage: null,
  topTeamTwoPointPercentage: null,
  playerValueInSingleGame: null,
  excludedGames: null,
  updatedAt: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type GameReferenceLike = {
  [K in keyof typeof defaultGameReference]?: GameReference[K];
};
export type TopPlayerStatsLike = {
  [K in keyof typeof defaultTopPlayerStats]?: TopPlayerStats[K];
};
export type TopTeamStatsLike = {
  [K in keyof typeof defaultTopTeamStats]?: TopTeamStats[K];
};
export type TopTeamWinningPercentageStatsLike = {
  [K in keyof typeof defaultTopTeamWinningPercentageStats]?: TopTeamWinningPercentageStats[K];
};
export type TopTeamWinBeforeLimitStatsLike = {
  [K in keyof typeof defaultTopTeamWinBeforeLimitStats]?: TopTeamWinBeforeLimitStats[K];
};
export type NotablePlayerPerformanceStatsLike = {
  [K in keyof typeof defaultNotablePlayerPerformanceStats]?: NotablePlayerPerformanceStats[K];
};
export type TourStatsSummaryLike = {
  [K in keyof typeof defaultTourStatsSummary]?: TourStatsSummary[K];
};

export class GameReference extends Record(defaultGameReference) {
  eventId?: string;
  eventName?: string;
  eventShortName?: string;
  gameId?: string;
  gameHomeTeamName?: string;
  gameAwayTeamName?: string;
  gameRoundCode?: string;
  gameHomeTeamSuffix?: string;
  gameAwayTeamSuffix?: string;
  static fromJS(o: GameReferenceLike): GameReference {
    return (
      o &&
      new GameReference({
        eventId: o.eventId,
        eventName: o.eventName,
        eventShortName: o.eventShortName,
        gameId: o.gameId,
        gameHomeTeamName: o.gameHomeTeamName,
        gameAwayTeamName: o.gameAwayTeamName,
        gameRoundCode: o.gameRoundCode,
        gameHomeTeamSuffix: o.gameHomeTeamSuffix,
        gameAwayTeamSuffix: o.gameAwayTeamSuffix,
      })
    );
  }
}
export class TopPlayerStats extends Record(defaultTopPlayerStats) {
  playerId?: string;
  playerSsoId?: string;
  playerFirstName?: string;
  playerLastName?: string;
  imageUrl?: string;
  teamName?: string;
  teamId?: string;
  teamNameSuffix?: string;
  isTourTeam?: boolean;
  value?: number;
  static fromJS(o: TopPlayerStatsLike): TopPlayerStats {
    return (
      o &&
      new TopPlayerStats({
        playerId: o.playerId,
        playerSsoId: o.playerSsoId,
        playerFirstName: o.playerFirstName,
        playerLastName: o.playerLastName,
        imageUrl: o.imageUrl,
        teamName: o.teamName,
        teamId: o.teamId,
        teamNameSuffix: o.teamNameSuffix,
        isTourTeam: o.isTourTeam,
        value: o.value,
      })
    );
  }
}
export class TopTeamStats extends Record(defaultTopTeamStats) {
  teamName?: string;
  teamId?: string;
  teamNameSuffix?: string;
  teamNationality?: string;
  isTourTeam?: boolean;
  value?: number;
  static fromJS(o: TopTeamStatsLike): TopTeamStats {
    return (
      o &&
      new TopTeamStats({
        teamName: o.teamName,
        teamId: o.teamId,
        teamNameSuffix: o.teamNameSuffix,
        teamNationality: o.teamNationality,
        isTourTeam: o.isTourTeam,
        value: o.value,
      })
    );
  }
}
export class TopTeamWinningPercentageStats extends Record(defaultTopTeamWinningPercentageStats) {
  teamName?: string;
  teamId?: string;
  teamNameSuffix?: string;
  teamNationality?: string;
  isTourTeam?: boolean;
  value?: number;
  gamesWon?: number;
  gamesLost?: number;
  static fromJS(o: TopTeamWinningPercentageStatsLike): TopTeamWinningPercentageStats {
    return (
      o &&
      new TopTeamWinningPercentageStats({
        teamName: o.teamName,
        teamId: o.teamId,
        teamNameSuffix: o.teamNameSuffix,
        teamNationality: o.teamNationality,
        isTourTeam: o.isTourTeam,
        value: o.value,
        gamesWon: o.gamesWon,
        gamesLost: o.gamesLost,
      })
    );
  }
}
export class TopTeamWinBeforeLimitStats extends Record(defaultTopTeamWinBeforeLimitStats) {
  teamName?: string;
  teamId?: string;
  teamNameSuffix?: string;
  teamNationality?: string;
  isTourTeam?: boolean;
  value?: number;
  gamesWonBeforeLimit?: number;
  gamesPlayed?: number;
  static fromJS(o: TopTeamWinBeforeLimitStatsLike): TopTeamWinBeforeLimitStats {
    return (
      o &&
      new TopTeamWinBeforeLimitStats({
        teamName: o.teamName,
        teamId: o.teamId,
        teamNameSuffix: o.teamNameSuffix,
        teamNationality: o.teamNationality,
        isTourTeam: o.isTourTeam,
        value: o.value,
        gamesWonBeforeLimit: o.gamesWonBeforeLimit,
        gamesPlayed: o.gamesPlayed,
      })
    );
  }
}
export class NotablePlayerPerformanceStats extends Record(defaultNotablePlayerPerformanceStats) {
  playerId?: string;
  playerSsoId?: string;
  playerFirstName?: string;
  playerLastName?: string;
  imageUrl?: string;
  teamName?: string;
  teamId?: string;
  teamInEventId?: string;
  teamNameSuffix?: string;
  isTourTeam?: boolean;
  value?: number;
  points?: number;
  rebounds?: number;
  highlights?: number;
  game?: GameReference;
  static fromJS(o: NotablePlayerPerformanceStatsLike): NotablePlayerPerformanceStats {
    return (
      o &&
      new NotablePlayerPerformanceStats({
        playerId: o.playerId,
        playerSsoId: o.playerSsoId,
        playerFirstName: o.playerFirstName,
        playerLastName: o.playerLastName,
        imageUrl: o.imageUrl,
        teamName: o.teamName,
        teamId: o.teamId,
        teamInEventId: o.teamInEventId,
        teamNameSuffix: o.teamNameSuffix,
        isTourTeam: o.isTourTeam,
        value: o.value,
        points: o.points,
        rebounds: o.rebounds,
        highlights: o.highlights,
        game: o.game && GameReference.fromJS(o.game),
      })
    );
  }
}
export class TourStatsSummary extends Record(defaultTourStatsSummary) {
  topPlayerPlayerValue?: List<TopPlayerStats>;
  topPlayerScorer?: List<TopPlayerStats>;
  topPlayerHighlights?: List<TopPlayerStats>;
  topPlayerKeyAssists?: List<TopPlayerStats>;
  topPlayerRebounds?: List<TopPlayerStats>;
  topPlayerBuzzerBeaters?: List<TopPlayerStats>;
  topPlayerTripleFives?: List<TopPlayerStats>;
  topPlayerDoubleFives?: List<TopPlayerStats>;
  topPlayerDrives?: List<TopPlayerStats>;
  topPlayerDunks?: List<TopPlayerStats>;
  topPlayerBlocks?: List<TopPlayerStats>;
  topPlayerTwoPointers?: List<TopPlayerStats>;
  topPlayerFTPercentage?: List<TopPlayerStats>;
  topPlayerOnePointPercentage?: List<TopPlayerStats>;
  topPlayerTwoPointPercentage?: List<TopPlayerStats>;
  topTeamWinningPercentage?: List<TopTeamWinningPercentageStats>;
  topTeamWinsBeforeLimit?: List<TopTeamWinBeforeLimitStats>;
  topTeamPointsScored?: List<TopTeamStats>;
  topTeamShootingValue?: List<TopTeamStats>;
  topTeamFewestPointsAllowed?: List<TopTeamStats>;
  topTeamHighlights?: List<TopTeamStats>;
  topTeamKeyAssists?: List<TopTeamStats>;
  topTeamDrives?: List<TopTeamStats>;
  topTeamRebounds?: List<TopTeamStats>;
  topTeamBuzzerBeaters?: List<TopTeamStats>;
  topTeamBlocks?: List<TopTeamStats>;
  topTeamFewestTurnOvers?: List<TopTeamStats>;
  topTeamFTPrecentage?: List<TopTeamStats>;
  topTeamOnePointPercentage?: List<TopTeamStats>;
  topTeamTwoPointPercentage?: List<TopTeamStats>;
  playerValueInSingleGame?: List<NotablePlayerPerformanceStats>;
  excludedGames?: List<GameReference>;
  updatedAt?: string;
  static fromJS(o: TourStatsSummaryLike): TourStatsSummary {
    return (
      o &&
      new TourStatsSummary({
        topPlayerPlayerValue:
          o.topPlayerPlayerValue &&
          List(o.topPlayerPlayerValue).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerScorer:
          o.topPlayerScorer && List(o.topPlayerScorer).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerHighlights:
          o.topPlayerHighlights &&
          List(o.topPlayerHighlights).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerKeyAssists:
          o.topPlayerKeyAssists &&
          List(o.topPlayerKeyAssists).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerRebounds:
          o.topPlayerRebounds && List(o.topPlayerRebounds).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerBuzzerBeaters:
          o.topPlayerBuzzerBeaters &&
          List(o.topPlayerBuzzerBeaters).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerTripleFives:
          o.topPlayerTripleFives &&
          List(o.topPlayerTripleFives).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerDoubleFives:
          o.topPlayerDoubleFives &&
          List(o.topPlayerDoubleFives).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerDrives:
          o.topPlayerDrives && List(o.topPlayerDrives).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerDunks:
          o.topPlayerDunks && List(o.topPlayerDunks).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerBlocks:
          o.topPlayerBlocks && List(o.topPlayerBlocks).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerTwoPointers:
          o.topPlayerTwoPointers &&
          List(o.topPlayerTwoPointers).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerFTPercentage:
          o.topPlayerFTPercentage &&
          List(o.topPlayerFTPercentage).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerOnePointPercentage:
          o.topPlayerOnePointPercentage &&
          List(o.topPlayerOnePointPercentage).map(i => i && TopPlayerStats.fromJS(i)),
        topPlayerTwoPointPercentage:
          o.topPlayerTwoPointPercentage &&
          List(o.topPlayerTwoPointPercentage).map(i => i && TopPlayerStats.fromJS(i)),
        topTeamWinningPercentage:
          o.topTeamWinningPercentage &&
          List(o.topTeamWinningPercentage).map(i => i && TopTeamWinningPercentageStats.fromJS(i)),
        topTeamWinsBeforeLimit:
          o.topTeamWinsBeforeLimit &&
          List(o.topTeamWinsBeforeLimit).map(i => i && TopTeamWinBeforeLimitStats.fromJS(i)),
        topTeamPointsScored:
          o.topTeamPointsScored &&
          List(o.topTeamPointsScored).map(i => i && TopTeamStats.fromJS(i)),
        topTeamShootingValue:
          o.topTeamShootingValue &&
          List(o.topTeamShootingValue).map(i => i && TopTeamStats.fromJS(i)),
        topTeamFewestPointsAllowed:
          o.topTeamFewestPointsAllowed &&
          List(o.topTeamFewestPointsAllowed).map(i => i && TopTeamStats.fromJS(i)),
        topTeamHighlights:
          o.topTeamHighlights && List(o.topTeamHighlights).map(i => i && TopTeamStats.fromJS(i)),
        topTeamKeyAssists:
          o.topTeamKeyAssists && List(o.topTeamKeyAssists).map(i => i && TopTeamStats.fromJS(i)),
        topTeamDrives:
          o.topTeamDrives && List(o.topTeamDrives).map(i => i && TopTeamStats.fromJS(i)),
        topTeamRebounds:
          o.topTeamRebounds && List(o.topTeamRebounds).map(i => i && TopTeamStats.fromJS(i)),
        topTeamBuzzerBeaters:
          o.topTeamBuzzerBeaters &&
          List(o.topTeamBuzzerBeaters).map(i => i && TopTeamStats.fromJS(i)),
        topTeamBlocks:
          o.topTeamBlocks && List(o.topTeamBlocks).map(i => i && TopTeamStats.fromJS(i)),
        topTeamFewestTurnOvers:
          o.topTeamFewestTurnOvers &&
          List(o.topTeamFewestTurnOvers).map(i => i && TopTeamStats.fromJS(i)),
        topTeamFTPrecentage:
          o.topTeamFTPrecentage &&
          List(o.topTeamFTPrecentage).map(i => i && TopTeamStats.fromJS(i)),
        topTeamOnePointPercentage:
          o.topTeamOnePointPercentage &&
          List(o.topTeamOnePointPercentage).map(i => i && TopTeamStats.fromJS(i)),
        topTeamTwoPointPercentage:
          o.topTeamTwoPointPercentage &&
          List(o.topTeamTwoPointPercentage).map(i => i && TopTeamStats.fromJS(i)),
        playerValueInSingleGame:
          o.playerValueInSingleGame &&
          List(o.playerValueInSingleGame).map(i => i && NotablePlayerPerformanceStats.fromJS(i)),
        excludedGames:
          o.excludedGames && List(o.excludedGames).map(i => i && GameReference.fromJS(i)),
        updatedAt: o.updatedAt,
      })
    );
  }
}

export default TourStatsSummary;
