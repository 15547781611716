import React, {useContext} from 'react';
import {List, Map} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {GameStats} from 'fiba/common/core/models/api/stats/GameStats';
import {TeamGameStats} from 'fiba/common/core/models/api/stats/TeamGameStats';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import Player from 'fiba/common/core/models/api/players/Player';
import PlayerGameStats from 'fiba/common/core/models/api/stats/PlayerGameStats';
import {PlayerStoreState} from 'fiba/wt/stores/playerStore';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section, SubHeading, HeadingStyleProps} from 'fiba/wt/ui/heading/Heading';
import GameStatsTeamTable from 'fiba/wt/ui/gameBoxScore/GameStatsTeamTable';
import GameStatsPlayerTable from 'fiba/wt/ui/gameBoxScore/GameStatsPlayerTable';
import GameStatsTeamFoulsTable from 'fiba/wt/ui/gameBoxScore/GameStatsTeamFoulsTable';
import GameStatsTeamPossessionsTable from 'fiba/wt/ui/gameBoxScore/GameStatsTeamPossessionsTable';
import {isLegacySeason} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {BoxScoreLegend} from 'fiba/wt/ui/gameBoxScore/BoxScoreLegend';
import {RootState} from 'fiba/wt/stores/rootStore';
import {getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {
  EventConfiguration,
  getEventStatsVisibility,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

type GameTeam = 'home' | 'away';

interface OwnProps {
  eventId: string;
  gameId: string;
  team: GameTeam;
  headingStyles: HeadingStyleProps;
}

interface ReduxProps {
  eventConfig: EventConfiguration;
  gameStats: GameStats;
  playerMap: Map<string, Player>;
  players: PlayerStoreState;
  season: string;
  gameResult: ResultsGameDetails;
}

interface TeamStatsProps {
  captionId: string;
  teamStat: TeamGameStats;
  headingStyles: HeadingStyleProps;
  reduced: boolean;
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state: RootState, {gameId, eventId}: OwnProps): ReduxProps => {
  const gameStatsState = state.getIn(['gameStats', gameId]);
  const playerStore = state.get('players');
  const season = state.getIn(['route', 'payload', 'season']);
  const gameResultDetailsState = state.getIn(['games', 'details', gameId]);

  return {
    eventConfig: getEventConfiguration(state, eventId),
    gameStats: gameStatsState,
    playerMap: gameStatsState && playerMap(gameStatsState.playerStatistics, playerStore),
    gameResult: gameResultDetailsState,
    season: season,
    players: playerStore,
  };
};

const playerMap = (playerStats: List<PlayerGameStats>, playerStore: PlayerStoreState): Player =>
  playerStats.reduce(
    (map, player) => map.set(player.playerId, playerStore.get(player.playerId)),
    Map<string, Player>(),
  );

/*
 * Combines small tables with weird'ish spacings for nice responsiveness
 */
const _TeamStats: React.FC<TeamStatsProps> = ({
  captionId,
  teamStat,
  headingStyles = {},
  reduced = false,
}) => (
  <Flex flexWrap="wrap" debugClassName="GameStatsTeamStats">
    {!reduced && (
      <Box
        pr={['0', '0', '0', '3']}
        width={['100', '100', '100', '33']}
        debugClassName="GameStatsTeamPossessionsTable"
      >
        <GameStatsTeamPossessionsTable
          captionId={`${captionId}-possessions`}
          caption={<SubHeading {...headingStyles}>Possessions</SubHeading>}
          teamStats={teamStat}
        />
      </Box>
    )}

    <Box
      pr={['0', '2']}
      pl={['0', '2']}
      pt={['4', '3', '3', '0']}
      width={['100', '50', '50', '33']}
      debugClassName="GameStatsTeamTable"
    >
      <GameStatsTeamTable
        captionId={`${captionId}-team`}
        caption={<SubHeading {...headingStyles}>Team</SubHeading>}
        teamStats={teamStat}
      />
    </Box>

    <Box
      pl={['0', '3']}
      pt={['4', '3', '3', '0']}
      width={['100', '50', '50', '33']}
      debugClassName="GameStatsTeamFoulsTable"
    >
      <GameStatsTeamFoulsTable
        captionId={`${captionId}-fouls`}
        caption={<SubHeading {...headingStyles}>Fouls</SubHeading>}
        teamStats={teamStat}
      />
    </Box>
  </Flex>
);

function selectedTeam(team: GameTeam, gameStats: GameStats) {
  switch (team) {
    case 'away':
      return gameStats.awayTeamStatistics;
    case 'home':
    default:
      return gameStats.homeTeamStatistics;
  }
}

/*
 * Main component, shows reduced stats and legends for older seasons
 */
const _GameStatsTeamTables: React.FC<Props> = ({
  eventConfig,
  gameId,
  players,
  gameStats,
  headingStyles = {},
  gameResult,
  team = 'home',
  season,
}) => {
  const siteConfig = useContext(SiteConfigContext);
  const eventConfigHasReducedStats = !getEventStatsVisibility(siteConfig, eventConfig, season);
  const showReducedStats = eventConfigHasReducedStats || gameStats.hasReducedStatistics;

  return (
    // NOTE: Caption IDs here are unique within a gameId, but this could be prefixed more

    <Box pt="3">
      <Spacer vSpace="4" bgColor="fullwhite">
        <GameStatsPlayerTable
          players={players}
          captionId={`${gameId}-${team}-player-stats`}
          gameResult={gameResult}
          teamStats={selectedTeam(team, gameStats)}
          playerStats={gameStats.playerStatistics}
          reducedColumns={showReducedStats}
        />
        {!isLegacySeason(season) && (
          <Section>
            <_TeamStats
              captionId={`${gameId}-${team}-team-stats`}
              teamStat={selectedTeam(team, gameStats)}
              reduced={showReducedStats}
              headingStyles={headingStyles}
            />
          </Section>
        )}
      </Spacer>
      <BoxScoreLegend headingStyles={headingStyles} reduced={showReducedStats} />
    </Box>
  );
};

export const GameStatsTeamTables = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _GameStatsTeamTables,
);
