import React from 'react';
import {Box, BoxStyleProps} from 'fiba/wt/ui/box/Box';

interface Props extends BoxStyleProps {
  children: React.ReactNode;
  className?: string;
}

const DEFAULT_STYLES: BoxStyleProps = {
  shadow: '1',
  pa: ['3', '3', '4'],
  borderRadius: '1',
  textAlign: 'center',
  bgColor: 'fullwhite',
};

export const NoticeText: React.FunctionComponent<Props> = ({children, ...rest}) => {
  return (
    <Box {...DEFAULT_STYLES} {...rest}>
      {children}
    </Box>
  );
};
