import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios';
import moment from 'moment';
import {isError, isString} from 'lodash';
import {fromJS, Map} from 'immutable';
import url from 'fiba/common/utils/url';

import FibaError, {createMessage} from 'fiba/common/core/fibaError';
import {TicketResult} from 'fiba/play/ui/registration/RegistrationSelect';
import Event from '../core/models/api/events/Event';
import {CustomFormType} from 'fiba/common/core/customForms';
import CustomForm from 'fiba/common/core/models/api/customForms/CustomForm';
import {mapToCustomFormApiData} from 'fiba/common/stores/customFormAnswerStore';
import axiosPromiseToWebDataPromise from 'fiba/common/utils/axiosPromiseToWebDataPromise';
import createFibaError from 'fiba/common/utils/createFibaError';
import {isUUID} from 'fiba/wt/utils/routeUtils';

type LoginRequest = any;

const DEFAULT_TIMEOUT = 28000;
const DEFAULT_SEASON = '2024';

const oemResponseHandler = (isImmutableResponse: boolean) => (response: AxiosResponse) => {
  if (isError(response)) {
    const error = (response as unknown) as AxiosError;
    if (error.response) {
      throw createFibaError(error.response.data || {});
    } else {
      throw createMessage(error.message);
    }
  } else if (response.status !== 200) {
    throw createFibaError(response.data || {});
  } else if (response.data.success === false) {
    throw new FibaError(fromJS(response.data));
  }

  // NOTE: Using `response.data` in immutable response but `response` in non-immutable
  // is by intent. Play/embed actions want the `data` field, but WT actions want
  // the whole response. This might call for better separation for these domains,
  // cause if this keeps growing, this becomes a mess :(
  // Note that the above creation of immutable errors derive from code in common/
  // mainly fibaError, routerService and notificationStore
  return isImmutableResponse ? fromJS(response.data) : response;
};

export const oemResponseMiddleware = (isImmutableResponse: boolean) => (axios: AxiosInstance) => {
  axios.interceptors.response.use(
    oemResponseHandler(isImmutableResponse),
    oemResponseHandler(isImmutableResponse),
  );
  return axios;
};

//
// API client interface

type AxiosMiddleware = (axios: AxiosInstance) => AxiosInstance;

export class ApiClientService {
  axios: AxiosInstance;
  options: AxiosRequestConfig;

  constructor(options: string | AxiosRequestConfig, ...middlewares: AxiosMiddleware[]) {
    this.options = (isString(options) ? {baseURL: options} : options) as AxiosRequestConfig;
    this.axios = middlewares.reduce(
      (axios, middleware) => middleware(axios),
      axios.create(this.options),
    );
  }

  login(request: LoginRequest) {
    return this.axios.post('/v2/login', request, {timeout: DEFAULT_TIMEOUT});
  }

  logout() {
    return this.axios.post('/v2/logout', {timeout: DEFAULT_TIMEOUT});
  }

  signup(form, token) {
    const dateOfBirth = moment(form.get('dateOfBirth'), 'YYYY-MM-DD', true);
    const u13 = dateOfBirth.add(13, 'years').isSameOrAfter(moment());

    const submitData = {
      homeTownPlaceId: form.get('homeTownPlaceId'),
      dateOfBirth: form.get('dateOfBirth'),
      phoneNumber: !u13 ? form.get('phoneNumber') : null,
      email: !u13 ? form.get('email') : null,
      username: u13 ? form.get('username') : null,
      password: form.get('password'),
      firstName: !u13 ? form.get('firstname') : null,
      lastName: !u13 ? form.get('lastname') : null,
      gender: form.get('gender'),
      nationalityIoc: form.get('nationality'),
    };

    return this.axios.post(
      '/v2/profile/register',
      {
        submitData,
        token,
      },
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  //
  // User
  loadUser() {
    return this.axios.get('/v2/profile', {timeout: DEFAULT_TIMEOUT});
  }

  loadUserEventTeams(eventId: string) {
    return this.axios.get(url`/v2/profile/event/${eventId}/teams`, {timeout: DEFAULT_TIMEOUT});
  }

  loadUserFollowing() {
    return this.axios.get('/v2/profile/following', {timeout: DEFAULT_TIMEOUT});
  }

  loadEditableProfile() {
    return this.axios.get('/v2/profile/edit', {timeout: DEFAULT_TIMEOUT});
  }

  editProfile(form) {
    return this.axios.put('/v2/profile/edit', form.toJS(), {timeout: DEFAULT_TIMEOUT});
  }

  uploadUserProfileImage(data) {
    return this.axios.post('/v2/profile/photo/profile', data, {
      headers: {'content-type': 'multipart/form-data'},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  uploadUserCoverImage(data) {
    return this.axios.post('/v2/profile/photo/cover', data, {
      headers: {'content-type': 'multipart/form-data'},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  updateUserSocialMedia(data) {
    return this.axios.put('/v2/profile/social', data.toJS(), {timeout: DEFAULT_TIMEOUT});
  }

  followPlayer(playerId: string) {
    return this.axios.post(url`/v2/profile/following/${playerId}`, {timeout: DEFAULT_TIMEOUT});
  }

  unfollowPlayer(playerId: string) {
    return this.axios.delete(url`/v2/profile/following/${playerId}`, {timeout: DEFAULT_TIMEOUT});
  }

  resetPassword(form) {
    return this.axios.post('/v2/login/resetPassword', form.toJS(), {timeout: DEFAULT_TIMEOUT});
  }

  changePassword(form) {
    return this.axios.post('/v2/profile/changePassword', form.toJS(), {timeout: DEFAULT_TIMEOUT});
  }

  forgotPassword(form) {
    return this.axios.post('/v2/login/forgotPassword', form.toJS(), {timeout: DEFAULT_TIMEOUT});
  }

  verifyEmail(ssoId: string, token: string) {
    return this.axios.post('/v2/login/verifyEmail', {ssoId, token}, {timeout: DEFAULT_TIMEOUT});
  }

  acceptTerms() {
    return this.axios.post(
      '/v2/profile/contracts',
      {
        hasAcceptedTermsAndConditions: true,
        hasAcceptedPrivacyPolicy: true,
      },
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  resendVerificationEmail() {
    return this.axios.put('/v2/profile/resend-verification', {timeout: DEFAULT_TIMEOUT});
  }

  // Player profile
  loadPlayerProfile(playerId: string) {
    if (!isUUID(playerId) && playerId.length !== 36) {
      // eslint-disable-next-line no-console
      console.warn(`Warning: Invalid playerId ${playerId}`);
      return null;
    }
    return this.axios.get(url`/v2/player/${playerId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadPlayerFollowers(playerId: string) {
    return this.axios.get(url`/v2/player/${playerId}/followers`, {timeout: DEFAULT_TIMEOUT});
  }

  loadPlayerFollowing(playerId: string) {
    return this.axios.get(url`/v2/player/${playerId}/following`, {timeout: DEFAULT_TIMEOUT});
  }

  loadPlayerActivity(playerId: string, params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    return this.axios.get(url`/v2/player/${playerId}/activity`, {
      params: _params,
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadPlayerRankingActivity(playerId: string, params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    return this.axios.get(url`/v2/player/${playerId}/ranking-activity`, {
      params: _params,
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadPlayerRankingActivityBreakdown(playerInEventId: string, params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    return this.axios.get(url`/v2/player/${playerInEventId}/ranking-breakdown`, {
      params: _params,
      timeout: DEFAULT_TIMEOUT,
    });
  }

  searchPlayers(params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    return this.axios.get('/v2/search/players', {params: _params, timeout: DEFAULT_TIMEOUT});
  }

  //
  // Event Data
  loadEvent(eventId: string) {
    if (isUUID(eventId) && eventId.length === 36) {
      return this.axios.get(url`/v2/event/${eventId}`, {timeout: DEFAULT_TIMEOUT});
    }
    return null;
  }

  loadEventWebData(eventId: string) {
    return axiosPromiseToWebDataPromise(
      this.axios.get<Event>(url`/v2/event/${eventId}`, {timeout: DEFAULT_TIMEOUT}),
    );
  }

  loadEventStatsSummary(eventId: string) {
    return this.axios.get(url`/v2/event/${eventId}/stats`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTourStatsSummary(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/stats`, {timeout: DEFAULT_TIMEOUT});
  }

  loadProCircuitStatsSummary(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/stats?procircuit=true`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadFastestGamesByEvent(eventId: string) {
    return this.axios.get(url`/v2/event/${eventId}/fastestgames`, {timeout: DEFAULT_TIMEOUT});
  }

  loadEventRegistrationInformation(eventId: string) {
    return this.axios.get<Map<string, any>>(url`/v2/event/${eventId}/registrationInformation`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadQualificationsByTourAndEvent(tourId: string, eventId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/events/${eventId}/qualifications`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadPlayerEventStats(eventId: string, categoryId: string) {
    return this.axios.get(url`/v2/event/${eventId}/categories/${categoryId}/playerstats`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadTopScorersStats(categoryId: string) {
    return this.axios.get(url`/v2/category/${categoryId}/topscorers`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGamesSummaryByEvent(eventId: string) {
    return this.axios.get(url`/v2/event/${eventId}/games`, {timeout: DEFAULT_TIMEOUT});
  }

  loadWorldTourGamesSummary(eventId: string) {
    return this.axios.get(url`/v2/tourevent/${eventId}/games`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGameDetails(eventId: string, gameId: string) {
    return this.axios.get(url`/v2/event/${eventId}/games/${gameId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGameTableOfficialsData(gameId: string) {
    return this.axios.get(url`/v2/game/${gameId}/playbyplay/scoring`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGamePlayByPlayStatsData(gameId: string) {
    return this.axios.get(url`/v2/game/${gameId}/playbyplay/stats`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGameStats(gameId: string) {
    return this.axios.get(url`/v2/game/${gameId}/stats`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTeamTourStats(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/stats/team`, {timeout: DEFAULT_TIMEOUT});
  }

  loadProCircuitTeamTourStats(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/stats/team?procircuit=true`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadTeamEventStats(eventId: string, categoryId: string) {
    return this.axios.get(url`/v2/event/${eventId}/categories/${categoryId}/teamstats`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadPlayerTourStats(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/stats/player`, {timeout: DEFAULT_TIMEOUT});
  }

  loadProCircuitPlayerTourStats(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/stats/player?procircuit=true`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadCategoriesByEvent(eventId: string) {
    return this.axios.get(url`/v2/event/${eventId}/categories`, {timeout: DEFAULT_TIMEOUT});
  }
  // Get categories with classification type WorldTour
  loadWorldTourCategoriesByEvent(eventId: string) {
    return this.axios.get(url`/v2/tourevent/${eventId}/categories`, {timeout: DEFAULT_TIMEOUT});
  }

  loadActivitiesByEvent(eventId: string) {
    return this.axios.get(url`/v2/event/${eventId}/activities`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTeamsByEvent(eventId: string) {
    return this.axios.get(url`/v3/event/${eventId}/teams`, {timeout: DEFAULT_TIMEOUT});
  }

  loadEventEmbedConfig(eventId: string, embedType: string) {
    return this.axios.get(url`/v2/event/${eventId}/embed/${embedType}`, {timeout: DEFAULT_TIMEOUT});
  }

  searchEvents(params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    _params.distance = params.distance || 100;
    return this.axios.get('/v2/search/events', {params: _params, timeout: DEFAULT_TIMEOUT});
  }

  loadCategory(categoryId: string) {
    return this.axios.get(url`/v2/category/${categoryId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadResultsByCategory(categoryId: string) {
    return this.axios.get(url`/v2/category/${categoryId}/results`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTeamsByCategory(categoryId: string) {
    return this.axios.get(url`/v2/category/${categoryId}/teams`, {timeout: DEFAULT_TIMEOUT});
  }

  loadOrganizer(organizerId: string) {
    return this.axios.get(url`/v2/organizer/${organizerId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTeamRankings(count: number) {
    return this.axios.get(url`/v2/rankings/teams?count=${count.toString()}`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadTour(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadEventsByTour(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/events`, {timeout: DEFAULT_TIMEOUT});
  }

  loadChallengerEventsByTour(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/challengers`, {timeout: DEFAULT_TIMEOUT});
  }

  loadSuperQuestEventsByTour(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/superquests`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTeamsByTour(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/teams`, {timeout: DEFAULT_TIMEOUT});
  }

  loadProCircuitTeamsBySeason(season: string) {
    return this.axios.get(url`/v2/procircuittour/${season || DEFAULT_SEASON}/teams`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadTourResults(tourId: string, params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    return this.axios.get(url`/v2/tour/${tourId}/results`, {
      params: _params,
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadFastestGamesBySeason(season: string) {
    return this.axios.get(url`/v2/worldtours/${season || DEFAULT_SEASON}/fastestgames`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadFastestGamesByTour(tourId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/fastestgames`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTourEmbedConfig(tourId: string, embedType: string) {
    return this.axios.get(url`/v2/tour/${tourId}/embed/${embedType}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTourTeam(tourTeamId: string) {
    return this.axios.get(url`/v2/tour/teams/${tourTeamId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTourTeamResults(tourId: string, tourTeamId: string) {
    return this.axios.get(url`/v2/tour/${tourId}/teams/${tourTeamId}/results`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadCupEvents(season: string) {
    return this.axios.get(url`/v2/nationalcompetitions/${season || DEFAULT_SEASON}`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadNationalTeamSeasonEvents(eventsType: 'cups' | 'nationsleague', season: string) {
    return this.axios.get(url`/v2/nationalteamevents/${eventsType}/${season || DEFAULT_SEASON}`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadTeam(teamId: string) {
    return this.axios.get(url`/v2/team/${teamId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTeamPayment(teamId: string) {
    return this.axios.get(url`/v2/team/${teamId}/payment`, {timeout: DEFAULT_TIMEOUT});
  }

  updateTeam(teamId: string, team) {
    const submitData = {
      name: team.get('name'),
      email: team.get('email'),
      phoneNumber: team.get('phoneNumber'),
      teamMembers: team.get('teamMembers').toJS(),
      additionalInfo: team.get('additionalInfo'),
    };
    return this.axios.put(url`/v2/team/${teamId}`, submitData, {timeout: DEFAULT_TIMEOUT});
  }

  removePlayerFromTeam(teamId: string, playerId: string) {
    return this.axios.delete(url`/v2/team/${teamId}/player/${playerId}`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  acceptTeamInvitation(teamId: string) {
    return this.axios.post(
      url`/v2/team/${teamId}/invitation/accept`,
      {},
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  declineTeamInvitation(teamId: string) {
    return this.axios.post(
      url`/v2/team/${teamId}/invitation/reject`,
      {},
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  registerTeam(categoryId: string, form, customFormData, ticket: TicketResult) {
    const submitData = {
      name: form.get('teamName'),
      email: form.get('email'),
      phoneNumber: form.get('phoneNumber'),
      teamMembers: form.get('teamMembers'),
      registrationToken: ticket ? ticket.ticket.token : null,
      customFormSubmit: customFormData ? customFormData : null,
    };

    return this.axios.post(url`/v2/category/${categoryId}/register`, submitData, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  unregisterTeam(teamId: string) {
    return this.axios.delete(url`/v2/team/${teamId}`, {timeout: DEFAULT_TIMEOUT});
  }

  sendTeamEditLink(teamId: string, data) {
    const submitData = {
      email: data.get('email'),
    };

    return this.axios.post(url`/v2/team/${teamId}/sendEditLink`, submitData, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompleteLocation(input: string) {
    return this.axios.get(`/v2/autocomplete/locations`, {
      params: {input},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompleteLocationNarrow(input: string) {
    return this.axios.get(`/v2/profile/autocomplete/city/narrow`, {
      params: {input},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompleteLocationWide(input: string) {
    return this.axios.get(`/v2/profile/autocomplete/city/wide`, {
      params: {input},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompleteCitiesWide(input: string, lang?: string) {
    return this.axios.get('/v2/autocomplete/city/wide', {
      params: {input, lang},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompleteCitiesNarrow(input: string, lang?: string) {
    return this.axios.get('/v2/autocomplete/city/narrow', {
      params: {input, lang},
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompletePlayer(params) {
    const _params = typeof params.toJS === 'function' ? params.toJS() : params;
    return this.axios.get('/v2/autocomplete/players', {params: _params, timeout: DEFAULT_TIMEOUT});
  }

  autocompletePlayerFilteringNationality(input: string, size, countryIoc: string) {
    return this.axios.get(
      url`/v2/autocomplete/players?name=${input}&size=${size}&countryIoc=${countryIoc}`,
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  autocompleteOrganizer(input: string) {
    return this.axios.get(url`/v2/autocomplete/organizers?key=${input}`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  autocompleteTour(input: string) {
    return this.axios.get(url`/v2/autocomplete/tours?key=${input}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadTweets(hashtags: string[]) {
    const tags = hashtags.join();
    const pageSize = '4';
    return this.axios.get(url`/v2/feed/twitter?pageSize=${pageSize}&tags=${tags}`, {
      timeout: DEFAULT_TIMEOUT,
    });
  }

  loadCustomForm(eventId: string, categoryId: string, formType: CustomFormType) {
    return this.axios.get<any, Map<any, any>>(
      url`/events/${eventId}/categories/${categoryId}/formType/${formType}`,
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  loadCustomFormTeamAnswers(eventId: string, categoryId: string, teamId: string) {
    return this.axios.get<any, Map<any, any>>(
      url`/events/${eventId}/categories/${categoryId}/teams/${teamId}/answers`,
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  loadCustomFormPlayerAnswers(
    eventId: string,
    categoryId: string,
    teamId: string,
    playerId: string,
  ) {
    return this.axios.get<any, Map<any, any>>(
      url`/events/${eventId}/categories/${categoryId}/teams/${teamId}/players/${playerId}/answers`,
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  submitCustomFormTeamAnswers(
    eventId: string,
    categoryId: string,
    teamId: string,
    customForm: CustomForm,
    fields: Map<string, any>,
  ) {
    return this.axios.post<any, Map<any, any>>(
      url`/events/${eventId}/categories/${categoryId}/teams/${teamId}/answers`,
      mapToCustomFormApiData({
        eventId,
        categoryId,
        teamId,
        customFormType: CustomFormType.Team,
        customForm,
        fields,
      }),
      {timeout: DEFAULT_TIMEOUT},
    );
  }

  submitCustomFormPlayerAnswers(
    eventId: string,
    categoryId: string,
    teamId: string,
    playerId: string,
    customForm: CustomForm,
    fields: Map<string, any>,
  ) {
    return this.axios.post<any, Map<any, any>>(
      url`/events/${eventId}/categories/${categoryId}/teams/${teamId}/players/${playerId}/answers`,
      mapToCustomFormApiData({
        eventId,
        categoryId,
        playerId,
        customFormType: CustomFormType.Player,
        customForm,
        fields,
      }),
      {timeout: DEFAULT_TIMEOUT},
    );
  }
}

/**
 * Factory funtion for a client service with middleware.
 */
export function createApiClientService(
  options: string | AxiosRequestConfig,
  ...middlewares: AxiosMiddleware[]
) {
  return new ApiClientService(options, ...middlewares);
}

export default createApiClientService;
