import {Map, fromJS} from 'immutable';
import {mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import {NewsItem, NewsItemLike} from 'fiba/common/core/models/api/feed/NewsItem';
import {NewsList, NewsListLike} from 'fiba/common/core/models/api/feed/NewsList';
import {
  NewsItemPreview,
  NewsItemPreviewLike,
} from 'fiba/common/core/models/api/feed/NewsItemPreview';
import {NewsPreviewListLike} from 'fiba/common/core/models/api/feed/NewsPreviewList';
import {apiNewsToNewsItem, newsArrayToNewsItemPreviewList} from 'fiba/common/utils/contentApiUtils';

export interface NewsStoreState extends Map<string, Map<string, NewsList>> {
  // NOTE: The type should be Record<'full'|'previews', Map<stiring, Map<String, NewsList>>> & Meta
  /*
  {
    full: Map<string, NewsList>,
    preview: Map<string, NewsPreviewList>
  }
  */
  // TODO: `__meta`
  /*
    full: {...},
    previews: {...},
    __meta: {
      previews: ...
      full: ...
    }
  */
}

// TODO: loadNewsPreviewItem

const reducers = {
  //
  // Full item(s)
  loadNewsItem: (state: NewsStoreState, newsSlug: string): NewsStoreState =>
    state.updateIn(['__meta', 'full', newsSlug], setMeta.isLoading),

  loadNewsItemSuccess: (state: NewsStoreState, newsItem: NewsItemLike): NewsStoreState =>
    state
      .setIn(['full', newsItem.slug], NewsItem.fromJS(newsItem))
      .updateIn(['__meta', 'full', newsItem.slug], setMeta.isLoaded),

  loadNewsItemWithSlugOrIdSuccess: (
    state: NewsStoreState,
    slugOrId: string,
    newsItem: NewsItemLike,
  ): NewsStoreState =>
    state
      .setIn(['full', slugOrId], NewsItem.fromJS(apiNewsToNewsItem(newsItem)))
      .updateIn(['__meta', 'full', slugOrId], setMeta.isLoaded),

  loadNewsItemError: (state: NewsStoreState, slugOrId: string, error: Error): NewsStoreState => {
    return state.updateIn(['__meta', 'full', slugOrId], setMeta.isError);
  },

  loadNewsItemPreview: (state: NewsStoreState, newsSlug: string): NewsStoreState =>
    state.updateIn(['__meta', 'previews', newsSlug], setMeta.isLoading),

  //
  // Previews
  loadNewsItemPreviewSuccess: (
    state: NewsStoreState,
    newsItem: NewsItemPreviewLike,
  ): NewsStoreState =>
    state
      .setIn(['previews', newsItem.slug], NewsItemPreview.fromJS(newsItem))
      .updateIn(['__meta', 'previews', newsItem.slug], setMeta.isLoaded),

  loadNewsItemPreviewError: (
    state: NewsStoreState,
    newsSlug: string,
    error: Error,
  ): NewsStoreState => state.updateIn(['__meta', 'previews', newsSlug], setMeta.isError),

  loadNewsPreviewsByTourSuccess: (
    state: NewsStoreState,
    tourId: string,
    newsList: NewsPreviewListLike,
  ): NewsStoreState => newsList.items.reduce(reducers.loadNewsItemPreviewSuccess, state),

  loadNewsPreviewsByEventSuccess: (
    state: NewsStoreState,
    eventId: string,
    newsList: NewsPreviewListLike,
  ): NewsStoreState => newsList.items.reduce(reducers.loadNewsItemPreviewSuccess, state),

  loadNewsPreviewsByConferenceSuccess: (
    state: NewsStoreState,
    conferenceId: string,
    newsList: NewsPreviewListLike,
  ): NewsStoreState => newsList.items.reduce(reducers.loadNewsItemPreviewSuccess, state),

  loadNewsByTeamSuccess: (
    state: NewsStoreState,
    teamId: string,
    eventId: string,
    newsList: NewsListLike,
  ): NewsStoreState => newsList.items.reduce(reducers.loadNewsItemSuccess, state),
};

export const reducer = createReducer<NewsStoreState>(__filename, fromJS({}), reducers, {});

const actions = createActions(__filename, reducers, {
  loadNewsItem: (newsSlugOrId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadNewsItemBySlug(newsSlugOrId)
      .then(res => actions.loadNewsItemWithSlugOrIdSuccess(newsSlugOrId, res.data))
      .catch(rethrow((err: Error) => actions.loadNewsItemError(newsSlugOrId, err))),

  loadNewsPreviewsByTour: (tourId: string) => ({apiClient, contentApiClient}: CommonServices) => {
    const isNationsLeague = tourId.slice(0, 2) === 'NL';
    if (isNationsLeague) {
      const season = tourId.slice(2);
      return apiClient.loadNationalTeamSeasonEvents('nationsleague', season).then(res => {
        const conferenceIds = res.data.data.map(conference => conference.conferenceId);
        return contentApiClient
          .loadNewsPreviewsForNL(conferenceIds)
          .then(itemsInResponse =>
            actions.loadNewsPreviewsByTourSuccess(
              tourId,
              newsArrayToNewsItemPreviewList(itemsInResponse),
            ),
          )
          .catch(rethrow((err: Error) => actions.loadNewsPreviewsByTourError(tourId, err)));
      });
    } else {
      return contentApiClient
        .loadNewsPreviewsByTour(tourId)
        .then(res =>
          actions.loadNewsPreviewsByTourSuccess(tourId, newsArrayToNewsItemPreviewList(res.data)),
        )
        .catch(rethrow((err: Error) => actions.loadNewsPreviewsByTourError(tourId, err)));
    }
  },

  loadNewsPreviewsByTourError: (tourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadNewsPreviewsByEvent: (eventId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadNewsPreviewsByEvent(eventId)
      .then(res =>
        actions.loadNewsPreviewsByEventSuccess(eventId, newsArrayToNewsItemPreviewList(res.data)),
      )
      .catch(rethrow((err: Error) => actions.loadNewsPreviewsByEventError(eventId, err))),

  loadNewsByEvent: (conferenceId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadNewsPreviewsByTour(conferenceId)
      .then(res =>
        actions.loadNewsPreviewsByConferenceSuccess(
          conferenceId,
          newsArrayToNewsItemPreviewList(res.data),
        ),
      )
      .catch(rethrow((err: Error) => actions.loadNewsPreviewsByConferenceError(conferenceId, err))),

  loadNewsPreviewsByEventError: (eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadNewsPreviewsByConferenceError: (conferenceId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadNewsByTeam: (teamId: string, eventId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadNewsByTeam(teamId)
      .then(res =>
        actions.loadNewsByTeamSuccess(teamId, eventId, newsArrayToNewsItemPreviewList(res.data)),
      )
      .catch(rethrow((err: Error) => actions.loadNewsByTeamError(teamId, eventId, err))),

  loadNewsByTeamError: (teamId: string, eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export function deserializeState(state): NewsStoreState {
  const {__meta, full, previews} = state;

  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail
    __meta: __meta || {full: {}, previews: {}},
    //  full: Map<string, NewsItem>
    //  previews: Map<string, NewsItemPreview>
    full: full ? mapValues(full, NewsItem.fromJS) : {},
    previews: previews ? mapValues(previews, NewsItemPreview.fromJS) : {},
  });
}
