/**
 * Module responsible for rendering meta tags to the <head> of a document.
 * This includes both client and server methods, and it is important those are kept in sync.
 * We do not (cannot, really) use React for this, so we delegate to this more manual approach.
 * We are still declarative, in that meta tags are declared in the Routes file,
 * and can use the same data-loading setup as, say, components or title.
 */

export {MetaTag, addNewMetaTagsToDom, metaTagsToHtml, metaTagsToString, OgTag, GenericTag};

/** Used to give us common selectors for client and server rendering */
const INJECTED_TAG_ATTRIBUTE_MARKER = 'data-fiba-head';

type MetaTag =
  | {type: 'OgTag'; property: string; content: string}
  | {type: 'GenericTag'; name: string; content: string};

const OgTag = (property: string, content: string): MetaTag => ({type: 'OgTag', property, content});
const GenericTag = (name: string, content: string): MetaTag => ({
  type: 'GenericTag',
  name,
  content,
});

function addNewMetaTagsToDom(metaTags: MetaTag[]) {
  // Remove past meta tags
  const pastTags = document.head.querySelectorAll(`meta[${INJECTED_TAG_ATTRIBUTE_MARKER}]`);

  pastTags.forEach(tag => {
    tag.remove();
  });

  // Add new meta tags
  const newMetaTags = metaTagsToHtml(metaTags);

  newMetaTags.forEach(tag => {
    document.head.appendChild(tag);
  });
}

function metaTagsToHtml(metaTags: MetaTag[]) {
  return metaTags.map(tag => {
    const el = document.createElement('meta');
    el.setAttribute(INJECTED_TAG_ATTRIBUTE_MARKER, 'true');

    switch (tag.type) {
      case 'OgTag':
        el.setAttribute('property', tag.property);
        el.setAttribute('content', encodeSpecialCharacters(tag.content));
        break;

      case 'GenericTag':
        el.setAttribute('name', tag.name);
        el.setAttribute('content', encodeSpecialCharacters(tag.content));
        break;
    }
    return el;
  });
}

function metaTagsToString(metaTags: MetaTag[]) {
  return metaTags.map(tag => {
    switch (tag.type) {
      case 'OgTag':
        return `<meta property="${tag.property}" content="${encodeSpecialCharacters(
          tag.content,
        )}" ${INJECTED_TAG_ATTRIBUTE_MARKER}="true" />`;
      case 'GenericTag':
        return `<meta name="${tag.name}" content="${encodeSpecialCharacters(
          tag.content,
        )}" ${INJECTED_TAG_ATTRIBUTE_MARKER}="true" />`;
    }
  });
}

function encodeSpecialCharacters(str: string) {
  return (str || '')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#x27;');
}
