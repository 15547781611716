/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultPlayByPlayTableOfficialsAction = {
  type: null,
  remainingTime: null,
  homeTeamScore: null,
  awayTeamScore: null,
  homeTeamFouls: null,
  awayTeamFouls: null,
  playerId: null,
  teamId: null,
  points: null,
  isFreeThrow: null,
  foulType: null,
  awardValue: null,
  isRejected: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type PlayByPlayTableOfficialsActionLike = {
  [K in keyof typeof defaultPlayByPlayTableOfficialsAction]?: PlayByPlayTableOfficialsAction[K];
};

export class PlayByPlayTableOfficialsAction extends Record(defaultPlayByPlayTableOfficialsAction) {
  type?: string;
  remainingTime?: number;
  homeTeamScore?: number;
  awayTeamScore?: number;
  homeTeamFouls?: number;
  awayTeamFouls?: number;
  playerId?: string;
  teamId?: string;
  points?: number;
  isFreeThrow?: boolean;
  foulType?: string;
  awardValue?: number | null;
  isRejected?: boolean | null;
  static fromJS(o: PlayByPlayTableOfficialsActionLike): PlayByPlayTableOfficialsAction {
    return (
      o &&
      new PlayByPlayTableOfficialsAction({
        type: o.type,
        remainingTime: o.remainingTime,
        homeTeamScore: o.homeTeamScore,
        awayTeamScore: o.awayTeamScore,
        homeTeamFouls: o.homeTeamFouls,
        awayTeamFouls: o.awayTeamFouls,
        playerId: o.playerId,
        teamId: o.teamId,
        points: o.points,
        isFreeThrow: o.isFreeThrow,
        foulType: o.foulType,
        awardValue: o.awardValue,
        isRejected: o.isRejected,
      })
    );
  }
}

export default PlayByPlayTableOfficialsAction;
