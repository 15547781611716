/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultTeamStats = {
  teamId: null,
  winsBeforeLimit: null,
  teamFouls: null,
  teamFoulsAgainst: null,
  teamFoulsAgainstPerGame: null,
  teamFoulsPerGame: null,
  teamPointsAgainst: null,
  teamPointsAgainstPerGame: null,
  twoPointShotShare: null,
  possessionsPerGame: null,
  eventsPlayed: null,
  gamesPlayed: null,
  mainDrawsPlayed: null,
  totalGamesWon: null,
  totalWinAverage: null,
  totalGamesLost: null,
  pointsPerGame: null,
  highlightsPerGame: null,
  reboundsPerGame: null,
  turnoversPerGame: null,
  totalPointsMade: null,
  onePointsAttempted: null,
  onePointsMade: null,
  onePointsMissed: null,
  onePointsPercentage: null,
  twoPointsAttempted: null,
  twoPointsMade: null,
  twoPointsMissed: null,
  twoPointsPercentage: null,
  freeThrowsAttempted: null,
  freeThrowsMade: null,
  freeThrowsMissed: null,
  freeThrowsPercentage: null,
  shootingEfficiency: null,
  shootingValue: null,
  shootingValuePerGame: null,
  highlights: null,
  dunks: null,
  assists: null,
  keyAssists: null,
  buzzerBeaters: null,
  drives: null,
  turnovers: null,
  blockedShots: null,
  pointsDifference: null,
  totalRebounds: null,
  offensiveRebounds: null,
  defensiveRebounds: null,
  attemptsTotal: null,
  extraFreeThrows: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TeamStatsLike = {
  [K in keyof typeof defaultTeamStats]?: TeamStats[K];
};

export class TeamStats extends Record(defaultTeamStats) {
  teamId?: string;
  winsBeforeLimit?: number;
  teamFouls?: number;
  teamFoulsAgainst?: number;
  teamFoulsAgainstPerGame?: number;
  teamFoulsPerGame?: number;
  teamPointsAgainst?: number;
  teamPointsAgainstPerGame?: number;
  twoPointShotShare?: number;
  possessionsPerGame?: number;
  eventsPlayed?: number;
  gamesPlayed?: number;
  mainDrawsPlayed?: number;
  totalGamesWon?: number;
  totalWinAverage?: number;
  totalGamesLost?: number;
  pointsPerGame?: number;
  highlightsPerGame?: number;
  reboundsPerGame?: number;
  turnoversPerGame?: number;
  totalPointsMade?: number;
  onePointsAttempted?: number;
  onePointsMade?: number;
  onePointsMissed?: number;
  onePointsPercentage?: number;
  twoPointsAttempted?: number;
  twoPointsMade?: number;
  twoPointsMissed?: number;
  twoPointsPercentage?: number;
  freeThrowsAttempted?: number;
  freeThrowsMade?: number;
  freeThrowsMissed?: number;
  freeThrowsPercentage?: number;
  shootingEfficiency?: number;
  shootingValue?: number;
  shootingValuePerGame?: number;
  highlights?: number;
  dunks?: number;
  assists?: number;
  keyAssists?: number;
  buzzerBeaters?: number;
  drives?: number;
  turnovers?: number;
  blockedShots?: number;
  pointsDifference?: number;
  totalRebounds?: number;
  offensiveRebounds?: number;
  defensiveRebounds?: number;
  attemptsTotal?: number;
  extraFreeThrows?: number;
  static fromJS(o: TeamStatsLike): TeamStats {
    return (
      o &&
      new TeamStats({
        teamId: o.teamId,
        winsBeforeLimit: o.winsBeforeLimit,
        teamFouls: o.teamFouls,
        teamFoulsAgainst: o.teamFoulsAgainst,
        teamFoulsAgainstPerGame: o.teamFoulsAgainstPerGame,
        teamFoulsPerGame: o.teamFoulsPerGame,
        teamPointsAgainst: o.teamPointsAgainst,
        teamPointsAgainstPerGame: o.teamPointsAgainstPerGame,
        twoPointShotShare: o.twoPointShotShare,
        possessionsPerGame: o.possessionsPerGame,
        eventsPlayed: o.eventsPlayed,
        gamesPlayed: o.gamesPlayed,
        mainDrawsPlayed: o.mainDrawsPlayed,
        totalGamesWon: o.totalGamesWon,
        totalWinAverage: o.totalWinAverage,
        totalGamesLost: o.totalGamesLost,
        pointsPerGame: o.pointsPerGame,
        highlightsPerGame: o.highlightsPerGame,
        reboundsPerGame: o.reboundsPerGame,
        turnoversPerGame: o.turnoversPerGame,
        totalPointsMade: o.totalPointsMade,
        onePointsAttempted: o.onePointsAttempted,
        onePointsMade: o.onePointsMade,
        onePointsMissed: o.onePointsMissed,
        onePointsPercentage: o.onePointsPercentage,
        twoPointsAttempted: o.twoPointsAttempted,
        twoPointsMade: o.twoPointsMade,
        twoPointsMissed: o.twoPointsMissed,
        twoPointsPercentage: o.twoPointsPercentage,
        freeThrowsAttempted: o.freeThrowsAttempted,
        freeThrowsMade: o.freeThrowsMade,
        freeThrowsMissed: o.freeThrowsMissed,
        freeThrowsPercentage: o.freeThrowsPercentage,
        shootingEfficiency: o.shootingEfficiency,
        shootingValue: o.shootingValue,
        shootingValuePerGame: o.shootingValuePerGame,
        highlights: o.highlights,
        dunks: o.dunks,
        assists: o.assists,
        keyAssists: o.keyAssists,
        buzzerBeaters: o.buzzerBeaters,
        drives: o.drives,
        turnovers: o.turnovers,
        blockedShots: o.blockedShots,
        pointsDifference: o.pointsDifference,
        totalRebounds: o.totalRebounds,
        offensiveRebounds: o.offensiveRebounds,
        defensiveRebounds: o.defensiveRebounds,
        attemptsTotal: o.attemptsTotal,
        extraFreeThrows: o.extraFreeThrows,
      })
    );
  }
}

export default TeamStats;
