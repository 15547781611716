import React from 'react';
import {TourProgrammability} from 'fiba/wt/ui/tourProgrammability/TourProgrammability';
import {TourPageProps} from 'fiba/wt/ui/tourPage/TourPageCommon';
import {TourPageDefault} from 'fiba/wt/ui/tourPage/TourPageDefault';
import {TourPageBeforeSeason} from 'fiba/wt/ui/tourPage/TourPageBeforeSeason';
import {TourPagePreEvent} from 'fiba/wt/ui/tourPage/TourPagePreEvent';
import {TourPageLiveEvent} from 'fiba/wt/ui/tourPage/TourPageLiveEvent';
import {TourPagePostEvent} from 'fiba/wt/ui/tourPage/TourPagePostEvent';
import {TourPageBetweenEvents} from 'fiba/wt/ui/tourPage/TourPageBetweenEvents';
import {TourPageAfterSeason} from 'fiba/wt/ui/tourPage/TourPageAfterSeason';
import {getEventIdFromConfiguration} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

export const TourPage: React.FunctionComponent<TourPageProps> = props => (
  <SiteConfigContext.Consumer>
    {({features}) => (
      <div className="TourPage pr0 pr3-m">
        <TourProgrammability
          {...props}
          renderDefault={() => (
            <TourPageDefault {...props} showTwitterFeed={features.showTwitterFeed} />
          )}
          renderBeforeSeason={() => (
            <TourPageBeforeSeason {...props} showTwitterFeed={features.showTwitterFeed} />
          )}
          renderPreEvent={eventPub => (
            <TourPagePreEvent
              {...props}
              eventId={getEventIdFromConfiguration(eventPub)}
              showTwitterFeed={features.showTwitterFeed}
            />
          )}
          renderLiveEvent={eventPub => (
            <TourPageLiveEvent
              {...props}
              eventId={getEventIdFromConfiguration(eventPub)}
              showTwitterFeed={features.showTwitterFeed}
            />
          )}
          renderPostEvent={eventPub => (
            <TourPagePostEvent
              {...props}
              eventId={getEventIdFromConfiguration(eventPub)}
              showTwitterFeed={features.showTwitterFeed}
            />
          )}
          renderBetweenEvents={() => (
            <TourPageBetweenEvents {...props} showTwitterFeed={features.showTwitterFeed} />
          )}
          renderAfterSeason={() => (
            <TourPageAfterSeason {...props} showTwitterFeed={features.showTwitterFeed} />
          )}
        />
      </div>
    )}
  </SiteConfigContext.Consumer>
);
