import {Map} from 'immutable';
import moment from 'moment';
import {
  createMessage,
  createFieldError,
  createFieldValidationError,
  createValidationError,
  ValidationError,
} from 'fiba/common/core/fibaError';
import {
  isUploadableImageMimeTypeValid,
  isUploadableImageSizeValid,
  UPLOADABLE_IMAGE_MAX_SIZE_MB,
} from 'fiba/common/core/images';
import {canTeamCancelParticipation, findPlayerEntryInTeam} from 'fiba/common/core/team';
import Team from 'fiba/common/core/models/api/teams/Team';
import Category from 'fiba/common/core/models/api/events/Category';
import Player from 'fiba/common/core/models/api/players/Player';
import CustomForm from 'fiba/common/core/models/api/customForms/CustomForm';
import {isCustomFormQuestion} from 'fiba/common/core/models/api/customForms/CustomFormElement';
import {USERNAME_MIN_LENGTH} from 'fiba/common/utils/constants';
import {CustomFormElementType} from '../customForms';
import {validatePassword} from './password_validator';

/**
 * Validators are functions that receive data to validate and
 * return either a FibaError object when data contains validation
 * errors or null when data has no validation errors.
 *
 * data => FibaError|null
 */

const isValidEmail = (email: string) => /^[^@]+@[^@]+$/.test(email);
const isPositiveInteger = (numberString: string) => /^[1-9]+[0-9]*$/.test(numberString);
const isValidUsername = (username: string) => /^[A-Za-z0-9]+$/.test(username);

const isEmailEmpty = (email: string) => email.length < 1;
const isDefined = (anyVal: any) => anyVal !== undefined && anyVal !== null;

export const signupValidator = (data: Map<string, any>) => {
  const fieldErrors = [];
  const dateOfBirth = moment(data.get('dateOfBirth'), 'YYYY-MM-DD', true);
  const u13 = dateOfBirth.add(13, 'years').isSameOrAfter(moment());
  const password = data.get('password');
  const username = data.get('username');
  const firstname = data.get('firstname');
  const lastname = data.get('lastname');

  // TODO: Translate messages

  if (dateOfBirth.isValid() === false) {
    fieldErrors.push(createFieldError('dateOfBirth', 'Check birthday'));
  }

  if (!data.get('gender')) {
    fieldErrors.push(createFieldError('gender', 'Please select gender'));
  }

  if (!data.get('nationality')) {
    fieldErrors.push(createFieldError('nationality', 'Please select nationality'));
  }

  if (!data.get('homeTownPlaceId')) {
    fieldErrors.push(createFieldError('homeTown', 'Hometown not recognized'));
  }

  //
  // Over 13 checks
  if (!u13) {
    if (!firstname) {
      fieldErrors.push(createFieldError('firstname', 'Firstname cannot be empty'));
    }

    if (!lastname) {
      fieldErrors.push(createFieldError('lastname', 'Lastname cannot be empty'));
    }

    if (!data.get('email') || !isValidEmail(data.get('email'))) {
      fieldErrors.push(createFieldError('email', 'Email cannot be empty'));
    }

    if (data.get('email') && !isValidEmail(data.get('email'))) {
      fieldErrors.push(createFieldError('email', 'Email is not of valid form'));
    }

    if (!data.get('phoneNumber')) {
      fieldErrors.push(createFieldError('phoneNumber', 'Phone number cannot be empty'));
    }
  }

  //
  // U13 checks
  if (u13) {
    if (!username) {
      fieldErrors.push(createFieldError('username', 'Username cannot be empty'));
    } else if (username.length < USERNAME_MIN_LENGTH) {
      fieldErrors.push(
        createFieldError(
          'username',
          `Username too short, minimum length is ${USERNAME_MIN_LENGTH} characters`,
          'invalid',
        ),
      );
    } else if (!isValidUsername(username)) {
      fieldErrors.push(
        createFieldError(
          'username',
          'Username should only contain latin letters and numbers',
          'invalid',
        ),
      );
    }
  }

  if (!data.get('terms')) {
    fieldErrors.push(createFieldError('terms', 'You must agree to terms'));
  }

  const {isValid, errorMsg} = validatePassword(password);
  if (!isValid) {
    fieldErrors.push(createFieldError('password', errorMsg));
  }

  if (fieldErrors.length) {
    fieldErrors.push(createFieldError('', 'There are errors in signup form'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const registrationValidator = data => {
  const fieldErrors = [];

  if (!data.get('teamName')) {
    fieldErrors.push(createFieldError('teamName', 'Team name cannot be empty'));
  }

  if (data.get('teamName') && data.get('teamName').length > 20) {
    fieldErrors.push(createFieldError('teamName', 'Team name cannot be over 20 characters'));
  }

  if (!data.get('email') || isEmailEmpty(data.get('email'))) {
    fieldErrors.push(createFieldError('email', 'Email cannot be empty'));
  }

  if (data.get('email') && !isValidEmail(data.get('email'))) {
    fieldErrors.push(createFieldError('email', 'Email is not of valid form'));
  }

  if (!data.get('phoneNumber')) {
    fieldErrors.push(createFieldError('phoneNumber', 'Phone number cannot be empty'));
  }

  if (!data.get('terms')) {
    fieldErrors.push(createFieldError('terms', 'You must agree to terms'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const loginValidator = form => {
  const fieldErrors = [];

  if (!form.get('username')) {
    fieldErrors.push(createFieldError('username', "Username can't be empty"));
  }

  if (!form.get('password')) {
    fieldErrors.push(createFieldError('password', "Password can't be empty"));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const addExternalPlayerValidator = data => {
  const fieldErrors = [];

  const dateOfBirth = moment(data.get('dateOfBirth'), 'YYYY-MM-DD', true);

  if (dateOfBirth.isValid() === false) {
    fieldErrors.push(createFieldError('dateOfBirth', 'Please check birthday'));
  }

  if (!data.get('gender')) {
    fieldErrors.push(createFieldError('gender', 'Please select gender'));
  }

  if (!data.get('nationality')) {
    fieldErrors.push(createFieldError('nationality', 'Please select nationality'));
  }

  if (!data.get('firstName')) {
    fieldErrors.push(createFieldError('firstName', 'Firstname cannot be empty'));
  }

  if (!data.get('lastName')) {
    fieldErrors.push(createFieldError('lastName', 'Lastname cannot be empty'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const addPlayerValidator = data => {
  const fieldErrors = [];

  if (!data.getIn(['selectedPlayer', 'id'])) {
    fieldErrors.push(
      createFieldError('player', 'You must search and select a player or fill the form below'),
    );
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

// TODO: Refactor validateFormPromise() to allow validators to return other than undefined to signal success
export const removePlayerValidator = (team: Team, player: Player) => {
  const entry = findPlayerEntryInTeam(team, player);
  if (!entry) {
    return createMessage("Player doesn't exist");
  }
};

export const editProfileValidator = data => {
  const fieldErrors = [];
  const dateOfBirth = moment(data.get('dateOfBirth'), 'YYYY-MM-DD', true);
  const u13 = dateOfBirth.add(13, 'years').isSameOrAfter(moment());

  // TODO: Translate messages

  if (dateOfBirth.isValid() === false) {
    fieldErrors.push(createFieldError('dateOfBirth', 'Check birthday'));
  }

  if (!data.get('gender')) {
    fieldErrors.push(createFieldError('gender', 'Please select gender'));
  }

  if (!data.get('nationalityIoc')) {
    fieldErrors.push(createFieldError('nationality', 'Please select nationality'));
  }

  if (!data.get('homeTownPlaceId')) {
    fieldErrors.push(createFieldError('homeCity', 'Hometown not recognized'));
  }

  //
  // Over 13 checks
  if (!u13) {
    if (!data.get('firstName')) {
      fieldErrors.push(createFieldError('firstName', 'First name cannot be empty'));
    }

    if (!data.get('lastName')) {
      fieldErrors.push(createFieldError('lastName', 'Last name cannot be empty'));
    }

    // FIXME: Check if phone number is mandatory or not
    // if (!data.get('phoneNumber'))
    //   fieldErrors.push(createFieldError('phoneNumber', 'Phone number cannot be empty'));

    if (data.get('heightInCm') && !isPositiveInteger(data.get('heightInCm'))) {
      fieldErrors.push(createFieldError('heightInCm', 'Height must be given as integer'));
    }

    if (data.get('weightInKg') && !isPositiveInteger(data.get('weightInKg'))) {
      fieldErrors.push(createFieldError('weightInKg', 'Weight must be given as integer'));
    }
  }

  if (fieldErrors.length) {
    fieldErrors.push(createFieldError('', 'There are errors in signup form'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const resetPasswordValidator = data => {
  const fieldErrors = [];
  const newPassword = data.get('password');
  const repeatedPassword = data.get('password2');

  const {isValid, errorMsg} = validatePassword(newPassword);
  if (!isValid) {
    fieldErrors.push(createFieldError('password', errorMsg));
  }
  if (newPassword && repeatedPassword && newPassword !== repeatedPassword) {
    fieldErrors.push(createFieldError('password2', 'Passwords do not match'));
  }
  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const changePasswordValidator = data => {
  const fieldErrors = [];

  const oldPassword = data.get('oldPassword');
  const newPassword = data.get('newPassword');
  const newPassword2 = data.get('newPassword2');

  const {isValid, errorMsg} = validatePassword(newPassword);
  if (!isValid) {
    fieldErrors.push(createFieldError('newPassword', errorMsg));
  }

  if (!oldPassword) {
    fieldErrors.push(createFieldError('oldPassword', 'Please enter your old password'));
  }

  if (newPassword && newPassword2 && newPassword !== newPassword2) {
    fieldErrors.push(createFieldError('newPassword2', 'Passwords do not match'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const forgotPasswordValidator = data => {
  const fieldErrors = [];

  const email = data.get('email');

  if (!isValidEmail(email)) {
    fieldErrors.push(createFieldError('email', 'Please enter a valid e-mail address'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const uploadImageValidator = data => {
  const file = data.get('file');

  if (!isUploadableImageMimeTypeValid(file.type)) {
    return createMessage('Image has to be one of PNG, JPEG, GIF, TIFF or BMP');
  }

  if (!isUploadableImageSizeValid(file.size)) {
    return createValidationError(`Maximum size for image is ${UPLOADABLE_IMAGE_MAX_SIZE_MB}MB`);
  }

  return null;
};

export const sendTeamEditLinkValidator = data => {
  const fieldErrors = [];

  const email = data.get('email');

  if (!isValidEmail(email)) {
    fieldErrors.push(createFieldError('email', 'Please enter a valid e-mail address'));
  }

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};

export const cancelTeamParticipationValidator = (team: Team, category: Category) => {
  if (canTeamCancelParticipation(team, category)) {
    return null;
  } else {
    const fieldErrors = [];
    fieldErrors.push(
      createFieldError(
        'teamCancellation',
        'Team participation cancellation is not allowed as this team has scheduled games.',
      ),
    );

    return createFieldValidationError(fieldErrors);
  }
};

export const customFormsValidator = (
  fields: Map<string, any>,
  customForm: CustomForm,
): ValidationError | null => {
  const VALUE_MAX_LENGTH = 1000;
  const fieldErrors = [];

  customForm.segments.map(seg =>
    seg.elements.map(el => {
      if (!isCustomFormQuestion(el)) {
        return fieldErrors;
      }

      const fieldValue = fields.get(el.name);

      if (el.required && !isDefined(fieldValue)) {
        fieldErrors.push(createFieldError(el.name, 'This question is required'));
      } else if (
        el.type === CustomFormElementType.Number &&
        el.minValue &&
        isDefined(fieldValue) &&
        Number(fieldValue) < el.minValue
      ) {
        fieldErrors.push(createFieldError(el.name, `Min. allowed value is ${el.minValue}`));
      } else if (
        el.type === CustomFormElementType.Number &&
        el.maxValue &&
        isDefined(fieldValue) &&
        Number(fieldValue) > el.maxValue
      ) {
        fieldErrors.push(createFieldError(el.name, `Max. allowed value is ${el.maxValue}`));
      } else if (
        el.type === CustomFormElementType.FreeText &&
        el.minLength &&
        isDefined(fieldValue) &&
        fieldValue.length < el.minLength
      ) {
        fieldErrors.push(
          createFieldError(el.name, `Too short. Min. allowed length: ${el.minLength}`),
        );
      } else if (
        el.type === CustomFormElementType.FreeText &&
        el.maxLength &&
        isDefined(fieldValue) &&
        fieldValue.length > el.maxLength
      ) {
        fieldErrors.push(
          createFieldError(el.name, `Too long. Max. allowed length: ${el.maxLength}`),
        );
      } else if (isDefined(fieldValue) && fieldValue.length > VALUE_MAX_LENGTH) {
        fieldErrors.push(
          createFieldError(el.name, `Too long answer. Max. allowed length: 1000 characters`),
        );
      } else if (
        // Allow 00 and 0 through 99. Zero-padded single-digits (01, 02) are OK.
        el.type === CustomFormElementType.JerseyNumber &&
        !new RegExp('^[0-9][0-9]?$').test(fieldValue)
      ) {
        fieldErrors.push(createFieldError(el.name, 'Valid jersey numbers are 00 and 0-99'));
      }
    }),
  );

  return fieldErrors.length ? createFieldValidationError(fieldErrors) : null;
};
