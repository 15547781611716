import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {
  ContentfulEmbeddedMediaProvider,
  EmbeddedMediaTypes,
  renderMediaComponent,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

interface EventDunkPageProps {
  season: string;
  tourId: string;
  eventId: string;
}

export const EventDunkPage: React.FunctionComponent<EventDunkPageProps> = ({
  season,
  tourId,
  eventId,
}) => (
  <Spacer ph={['3', '3', '1']} debugClassName="EventDunkPage">
    <section>
      <EventPageHeading eventId={eventId} page="Dunk" />
      <Section>
        <Spacer vSpace="4">
          <div className="measure-very-wide">
            <ContentfulArticle
              contentPath={`events/${eventId}`}
              articleType={ArticleTypes.EventDunk}
            />
          </div>

          <ContentfulEmbeddedMediaProvider
            contentPath={`events/${eventId}`}
            mediaType={EmbeddedMediaTypes.YoutubeDunkPlaylist}
          >
            {media => renderMediaComponent(media, 720)}
          </ContentfulEmbeddedMediaProvider>
        </Spacer>
      </Section>
    </section>
  </Spacer>
);
