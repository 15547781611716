import React, {ReactElement, useContext} from 'react';
import {eventStatisticsLink, EventType} from 'fiba/wt/utils/linkUtils';
import {Tabs, Tab} from 'fiba/wt/ui/tabs/Tabs';
import {EventStatisticsFastestGames} from 'fiba/wt/ui/eventStatisticsPage/EventStatisticsFastestGames';
import {Box} from 'fiba/wt/ui/box/Box';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {EventStatisticsPlayersTab} from 'fiba/wt/ui/eventStatisticsPage/EventStatisticsPlayersTab';
import {EventStatisticsTeamsTab} from 'fiba/wt/ui/eventStatisticsPage/EventStatisticsTeamTab';
import {connect} from 'fiba/common/utils/reactUtils';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import {EventStatisticsSummary} from 'fiba/wt/ui/eventStatisticsPage/EventStatisticsSummary';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {capitalize} from 'lodash';
import {TopScorerStatistics} from 'fiba/wt/ui/statistics/TopScorerStatistics';
import {
  EventConfiguration,
  getEventStatsVisibility,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {routerReplaceLocation} from 'fiba/common/services/routerService';
import {isNt} from 'fiba/wt/utils/routeUtils';

export type EventStatisticsTab =
  | 'website-default-tab'
  | 'summary'
  | 'downloads'
  | 'fastest-games'
  | 'teams'
  | 'players'
  | 'top-scorers';

interface OwnProps {
  season: string;
  eventId: string;
  primaryTab: EventStatisticsTab;
  teamId: string;
  genderTab?: CategoryGenderTab;
}
interface ReduxProps {
  eventTypeData: WebData<EventType>;
  eventConfig: EventConfiguration;
}
interface DispatchProps {
  routeToStatsTab: (route: string) => null;
}

export type IEventStatisticsPage = OwnProps & ReduxProps & DispatchProps;

type TabProps = OwnProps & {eventType: EventType};
interface StatsTab {
  tabName: string;
  title: string;
  hideOnNT?: boolean;
}

// TODO: introduce enums to tabs
export const FULL_STATS_TABS: StatsTab[] = [
  {tabName: 'summary', title: 'Summary', hideOnNT: true}, // Temporarily hide for NT. See issue https://github.com/futurice/fiba-3x3-play/issues/2865
  {tabName: 'teams', title: 'Teams'},
  {tabName: 'players', title: 'Players'},
  {tabName: 'fastest-games', title: 'Fastest Games'},
];

export const REDUCED_STATS_TABS: StatsTab[] = [
  {tabName: 'top-scorers', title: 'Top Scorers'},
  {tabName: 'fastest-games', title: 'Fastest Games'},
];

function getEventStatisticsTab({primaryTab, ...props}: TabProps) {
  switch (primaryTab) {
    case 'summary':
      return <EventStatisticsSummary {...props} />;
    case 'fastest-games':
      return <EventStatisticsFastestGames {...props} />;
    case 'players':
      return <EventStatisticsPlayersTab {...props} />;
    case 'teams':
      return <EventStatisticsTeamsTab {...props} />;
    case 'top-scorers':
      return <TopScorerStatistics {...props} />;
    default:
      return null;
  }
}

export const getLandingTab = (showFullStats: boolean, isNTWebsite: boolean) => {
  if (!showFullStats) {
    return 'top-scorers';
  } else {
    return isNTWebsite ? 'teams' : 'summary';
  }
};

const RenderTab = ({tabName, title}: StatsTab): ReactElement => (
  <Tab href={`/${tabName}`} key={tabName}>
    {title}
  </Tab>
);
export const showTabOnNT = isNTWebsite => tab => !(isNTWebsite && tab.hideOnNT);

const FilteredStats = (showFullStats: boolean, isNTWebsite: boolean): ReactElement[] =>
  showFullStats
    ? FULL_STATS_TABS.filter(showTabOnNT(isNTWebsite)).map(RenderTab)
    : REDUCED_STATS_TABS.filter(showTabOnNT(isNTWebsite)).map(RenderTab);

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => ({
  eventTypeData: getEventType(state.get('events'), eventId),
  eventConfig: getEventConfiguration(state, eventId),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  routeToStatsTab: route => {
    dispatch(routerReplaceLocation(route));
    return null;
  },
});

const EventStatisticsPageImpl: React.FunctionComponent<IEventStatisticsPage> = props => {
  const headingId = 'event-stats-page';
  const {
    primaryTab,
    routeToStatsTab,
    season,
    eventConfig,
    eventId,
    eventTypeData,
    genderTab,
  } = props;
  const siteConfig = useContext(SiteConfigContext);
  const isNTWebsite = isNt(siteConfig);
  const showFullStats = getEventStatsVisibility(siteConfig, eventConfig, season);
  const landingTab = getLandingTab(showFullStats, isNTWebsite);
  return (
    <Spacer vSpace="3">
      <Spacer ph={['3', '3', '0']}>
        <EventPageHeading
          page={`Stats ${genderTab ? capitalize(genderTab) : ''}`}
          eventId={eventId}
        />
      </Spacer>
      <ViewWebData data={eventTypeData}>
        {eventType => {
          const eventStatsLink = eventStatisticsLink(
            siteConfig.seasonConfig,
            season,
            eventId,
            eventType,
            genderTab,
          );
          if (primaryTab === 'website-default-tab') {
            routeToStatsTab(`${eventStatsLink}/${landingTab}`);
            return null;
          } else {
            return (
              <Section>
                <Box shadow="1" bgColor="fullwhite" mh={['0', '0', '1']}>
                  <Tabs level="base" navigationLabeledBy={headingId} baseUrl={eventStatsLink}>
                    {FilteredStats(showFullStats, isNTWebsite)}
                  </Tabs>

                  <Box ph="3" pb="3" pt="3" debugClassName="EventStatisticsPage-Content">
                    {getEventStatisticsTab({...props, eventType})}
                  </Box>
                </Box>
              </Section>
            );
          }
        }}
      </ViewWebData>
    </Spacer>
  );
};

export const EventStatisticsPage = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EventStatisticsPageImpl);
