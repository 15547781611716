import React, {useContext} from 'react';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Link} from 'fiba/wt/ui/link/Link';
import {FlexCardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventTeamLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';

interface TeamListItemProps {
  season: string;
  eventId: string;
  eventType: EventType;
  team: Team;
  standing?: ResultsStanding;
}

export const TeamListCondensedItem: React.FC<TeamListItemProps> = ({
  season,
  eventId,
  eventType,
  team,
  standing,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const theme = useContext(ThemeContext);

  const heading = (
    <SubHeading fontSize={['4', '4', '5', '4']}>
      <TeamNameAssembly
        team={team}
        standing={standing}
        isFlagVisible
        isSeedVisible
        isSuffixVisible
      />
    </SubHeading>
  );

  return (
    <FlexCardContainer
      pa={['3', '3', '3', '4']}
      flexDirection={['column', 'column', 'row', 'row']}
      debugClassName="TeamListItem"
    >
      <Flex
        flexGrow="1"
        pv={['0', '0', '0', '0']}
        flexDirection={['column', 'column', 'row', 'column']}
        width={['100', '100', '75', '66']}
        alignItems={['start', 'start', 'center', 'start']}
      >
        {/* Team name and flag */}
        <Box pb={['0', '0', '0', '0']} width={['auto', 'auto', '25', 'auto']}>
          {/* Link to the team page, if the team is real */}
          {team.isPlaceholder ? (
            heading
          ) : (
            <Link
              colorTheme={theme.linkTheme}
              href={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)(team.id)}
              display="inline_block"
            >
              {heading}
            </Link>
          )}
        </Box>
      </Flex>
    </FlexCardContainer>
  );
};
