import React from 'react';
import {padStart} from 'lodash';

interface CountdownRendererProps {
  total: number;
  days: number | null;
  hours: string;
  minutes: string;
  seconds: string;
  milliseconds: number;
  completed: boolean;
}

const countdownBlock = (label: string, value: string) => (
  <div className="EventCountdown-Unit">
    <div className="EventCountdown-UnitGroup united">
      {Array.prototype.map.call(value, (ch: string, i: number) => (
        <div className="EventCountdown-UnitDigit" key={i}>
          {ch}
        </div>
      ))}
    </div>
    <p className="EventCountdown-Label roboto">{label}</p>
  </div>
);

export const eventCountdownRenderer = ({days, hours, minutes, seconds}: CountdownRendererProps) => (
  <div className="EventCountdown-Timer">
    {!!days && countdownBlock('Days', padStart(days.toString(), 2, '0'))}
    {!!days && <div className="EventCountdown-Sep">:</div>}
    {countdownBlock('Hours', hours.toString())}
    <div className="EventCountdown-Sep">:</div>
    {countdownBlock('Minutes', minutes.toString())}
    <div className="EventCountdown-Sep">:</div>
    {countdownBlock('Seconds', seconds.toString())}
  </div>
);
