import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {assetPath} from 'fiba/common/utils/reactUtils';
import {Heading} from 'fiba/wt/ui/heading/Heading';

export const DownPage = () => (
  <Box pt="2" bgColor="fullwhite" extraClassName="min-h-container">
    <section>
      <Box ph="3" borderColor="silver-50" pt="3" pb="3" mb="4" border="bottom">
        <Heading
          textAlign={['left', 'left', 'center']}
          textTransform="uppercase"
          color="fullblack"
          fontWeight="9"
          fontSize="4"
          fontFamily="united"
        >
          Sorry! We could not respond to you right now.
        </Heading>
      </Box>

      <Box ph="3" extraClassName="mw6" lineHeight="copy" ml="auto" mr="auto">
        <p>
          Our FIBA 3x3 servers are taking a little longer to respond than usual. Time to sit on the
          bench for a moment! :-)
        </p>
        <p className="pt4 pb4">
          Please check back after a few minutes and we should be back in the game stronger than
          before.
        </p>
        <img src={assetPath('img/3x3-empty-venue.jpg')} alt="" />
      </Box>
    </section>
  </Box>
);
