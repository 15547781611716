import React, {useContext} from 'react';
import {eventRootLink, eventStandingsLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {connect} from 'fiba/common/utils/reactUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {GhostLink} from 'fiba/wt/ui/link/Link';
import {EventFinalResults} from 'fiba/wt/ui/eventFinalResults/EventFinalResults';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';

// Props from caller
interface OwnProps {
  season: string;
  eventId: string;
}

// Derived props from Redux
interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
}

// Connect to redux
const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  return {
    eventTypeData: getEventType(state.get('events'), eventId),
  };
};

const PortalStandingsGhostLinks: React.SFC<{
  season: string;
  eventId: string;
  eventType: EventType;
}> = ({season, eventId, eventType}) => {
  const {seasonConfig, theme} = useContext(SiteConfigContext);
  return (
    <Flex
      pt="3"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      maxWidth="6"
      align="center"
    >
      <GhostLink
        variant={theme.ghostlinkTheme}
        extraClassName="flex-auto ma1"
        href={eventRootLink(seasonConfig, season, eventId, eventType)}
      >
        Go to <span className="db fw7">Event Overview</span>
      </GhostLink>
      <GhostLink
        variant={theme.ghostlinkTheme}
        extraClassName="flex-auto ma1"
        href={eventStandingsLinkProvider(seasonConfig, season, eventId, eventType)('')}
      >
        Go to <span className="db fw7">Event Standings</span>
      </GhostLink>
    </Flex>
  );
};

const _EventPortalPost: React.SFC<OwnProps & ReduxProps> = ({season, eventId, eventTypeData}) => (
  <ViewWebData data={eventTypeData}>
    {eventType => (
      <div className="EventPortalPost ph1 pt3">
        <EventFinalResults season={season} eventId={eventId} labelId="event-portal-final-results" />
        <PortalStandingsGhostLinks season={season} eventId={eventId} eventType={eventType} />
      </div>
    )}
  </ViewWebData>
);

export const EventPortalPost = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventPortalPost);
