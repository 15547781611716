import React, {useContext} from 'react';
import {List} from 'immutable';
import {TopTeamStats as EventTopTeamStats} from 'fiba/common/core/models/api/stats/EventStatsSummary';
import {TopTeamStats as TourTopTeamStats} from 'fiba/common/core/models/api/stats/TourStatsSummary';
import {P} from 'fiba/wt/ui/text/Text';
import {areStats, getTeamIdForLink} from 'fiba/wt/ui/statistics/statsSummary/utils';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import EmptyStats from 'fiba/wt/ui/statistics/statsSummary/EmptyStats';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

interface Props {
  title: string;
  stats: List<EventTopTeamStats | TourTopTeamStats>;
  createTeamHref: Function;
  numberOfDecimals?: number;
}

export default function TeamStats({title, stats, createTeamHref, numberOfDecimals = 0}: Props) {
  const {features} = useContext(SiteConfigContext);
  return (
    <div className="team-stats-item shadow-1">
      <P fontWeight="6" mb="3" ml="3">
        {title}
      </P>
      {!areStats(stats) ? (
        <EmptyStats />
      ) : (
        <ul>
          {stats.map((statsItem, index) => {
            const isFirstItem = index === 0;

            const teamLike = Team.fromJS({
              id: statsItem.teamId,
              name: statsItem.teamName,
              nameSuffix: statsItem.teamNameSuffix,
              nationality: statsItem.teamNationality,
            });
            const teamIdForLink = getTeamIdForLink(statsItem);
            return (
              <li key={statsItem.teamId} className={features.siteId}>
                <div className="rank">{index + 1}.</div>
                <div className="names">
                  {/* In Pro-Circuit level, we only render links to teams that played in WT */}
                  {teamIdForLink ? (
                    <a href={createTeamHref(teamIdForLink)}>
                      <TeamNameAssembly
                        team={teamLike}
                        isFlagVisible={isFirstItem}
                        isIocVisible={false}
                        isSuffixVisible
                      />
                    </a>
                  ) : (
                    <div>
                      <TeamNameAssembly
                        team={teamLike}
                        isFlagVisible={isFirstItem}
                        isIocVisible={false}
                        isSuffixVisible
                      />
                    </div>
                  )}
                </div>
                <p className="value">{statsItem.value.toFixed(numberOfDecimals)}</p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
