import React, {useContext} from 'react';
import {Box, BoxProvider} from 'fiba/wt/ui/box/Box';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {ArticleThemeContext, ArticleSize} from 'fiba/wt/ui/article/Article';
import {SponsorBox, SponsorType} from 'fiba/wt/ui/sponsorBox/SponsorBox';
import {SponsorBoxList} from 'fiba/wt/ui/sponsorBox/SponsorBoxList';
import {EventGuideSnippet} from 'fiba/wt/ui/eventPage/EventGuideSnippet';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';

export interface EventSidebarSecondaryProps {
  tourId: string;
  season: string;
  eventId: string;
}

export const EventSidebarSecondary: React.FunctionComponent<EventSidebarSecondaryProps> = ({
  tourId,
  season,
  eventId,
}) => {
  const theme = useContext(ThemeContext);
  return (
    <Box fontSize={theme.fontSize} debugClassName="EventSidebarSecondary">
      <ArticleThemeContext.Provider value={{articleSize: ArticleSize.Small}}>
        <Spacer vSpace="2">
          <Box color={theme.color} debugClassName="EventSidebarSecondary-Sponsor">
            <SponsorBox tourId={tourId} eventId={eventId} type={SponsorType.Sponsor1} />
          </Box>

          <BoxProvider
            ph="3"
            borderRadius="1"
            bgColor={theme.bgColor}
            color={theme.color}
            debugClassName="EventSidebarSecondary-Guide"
          >
            {({className}) => (
              <EventProgrammability
                eventId={eventId}
                season={season}
                renderDefault={() => null}
                renderPre={() => (
                  <Box className={className}>
                    <EventGuideSnippet tourId={tourId} season={season} eventId={eventId} />
                  </Box>
                )}
                renderLive={() => (
                  <Box className={className}>
                    <EventGuideSnippet tourId={tourId} season={season} eventId={eventId} />
                  </Box>
                )}
                renderPost={() => (
                  <Box className={className}>
                    <EventGuideSnippet tourId={tourId} season={season} eventId={eventId} />
                  </Box>
                )}
              />
            )}
          </BoxProvider>

          <Box color={theme.color} debugClassName="EventSidebarSecondary-Sponsors">
            <SponsorBoxList
              tourId={tourId}
              eventId={eventId}
              types={[SponsorType.Sponsor2, SponsorType.Sponsor3]}
            />
          </Box>
        </Spacer>
      </ArticleThemeContext.Provider>
    </Box>
  );
};
