import React from 'react';
import scrollIntoView from 'scroll-into-view-if-needed';
import {connect} from 'fiba/common/utils/reactUtils';

interface OwnProps {
  is?: string;
  className?: string;
  elementRef?: React.RefObject<HTMLElement>;
  scrollElementClassName?: string;
  id?: string;
}

interface ReduxProps {
  hasHydratedServerMarkup: boolean;
  pathname: string;
}

const mapStateToProps = (state): ReduxProps => ({
  pathname: state.getIn(['route', 'pathname']),
  hasHydratedServerMarkup: state.getIn(['route', 'hasHydratedServerMarkup']),
});

class FocusElementImpl extends React.Component<OwnProps & ReduxProps> {
  rootRef: React.RefObject<HTMLElement>;
  scrollRef: React.RefObject<HTMLDivElement>;

  constructor(props: OwnProps & ReduxProps) {
    super(props);

    if (props.elementRef) {
      this.rootRef = props.elementRef;
    } else {
      this.rootRef = React.createRef<HTMLElement>();
    }
    this.scrollRef = React.createRef<HTMLDivElement>();
    this.focusIfNecessary = this.focusIfNecessary.bind(this);
  }

  focusIfNecessary(prevPathname: string, nextPathname: string) {
    const focusNode = this.rootRef.current;
    const scrollNode = this.scrollRef.current;

    if (focusNode && nextPathname !== prevPathname) {
      // TODO: Why do we allocate this? (mutation?)
      prevPathname = nextPathname;

      // Set tabindex -1 before focusing
      // This allows us to focus, but avoids a critical bug where tabindex -1 captures focus
      // @see https://axesslab.com/skip-links/
      focusNode.setAttribute('tabindex', '-1');
      focusNode.focus();

      // On blur, remove tabindex
      focusNode.addEventListener('blur', () => {
        focusNode.removeAttribute('tabindex');
      });

      // Scroll the node into view, if needed
      if (scrollNode) {
        scrollIntoView(scrollNode, {scrollMode: 'if-needed', block: 'start', inline: 'nearest'});
      }
    }
  }

  //  TODO: Add more nuanced focus
  //  In EventLayout, focus the deeper content. But if coming from tourLayout and not serverRender (i.e. CDM without server),  then focus the larger content.
  //  Note that the solution described above is very specific. The more generic approach is to use a "FocusContext" and propagate to the lowest one
  //  Moreover, we have to focus different parts if navigating in-Event

  /* Illustrated
   *  Focus if currentRoute !== routePattern
   *  Hydrating from server -> Do nothing
   *  Tour -> Event: Focus whole Event
   *    <FocusElement routePattern="/:season/:eventSlug/">
   *  Event -> Another Event: Focus whole Event
   *    <FocusElement routePattern="/:season">
   *  Event -> Other page in Event: Focus ContentContainer
   *    <FocusElement routePattern="/:season/:eventSlug/*">
   *  Page in Tour -> Page in Tour: Focus whole page past Tour navigation
   *    <FocusElement routePattern="/:season">
   *  Event -> Page in Tour: Focus whole page past Tour navigation
   *    <FocusElement routePattern="/:season">
   */

  // TODO: Find a way to sync SkipLink to this!

  // If we navigate from TourLayout to EventLayout, the component will remount
  // Thus, we should account for this possiblity. However, on initial render (hydration)
  // this will cause jumpiness. It also doesn't make sense, since the user's focus
  // already starts at the top. So we need to check for that from the router.
  componentDidMount() {
    // This causes jumpiness on initial render, if the script comes in a while
    // after the user has started interacting with the page. So, only focus if we mount after hydration.
    if (this.props.hasHydratedServerMarkup) {
      this.focusIfNecessary(null, this.props.pathname);
    }
  }

  componentDidUpdate(prevProps: OwnProps & ReduxProps) {
    this.focusIfNecessary(prevProps.pathname, this.props.pathname);
  }

  render() {
    const {children, is, className, id, scrollElementClassName} = this.props;

    // FIXME:
    // TS 3.3 is being silly with "ref" not existing on string- components.
    // Not sure why
    const Component = is || ('div' as any);

    return (
      <Component ref={this.rootRef} className={className} id={id} style={{outline: 'none'}}>
        {/* It seems that scrolling to the focus element does not work super well */}
        <div ref={this.scrollRef} className={scrollElementClassName} />
        {children}
      </Component>
    );
  }
}

export const FocusElement = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(FocusElementImpl);
