import React, {useContext} from 'react';
import {GameBoxScore, GameBoxScoreTab} from 'fiba/wt/ui/gameBoxScore/GameBoxScore';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {eventRootLink, EventType} from 'fiba/wt/utils/linkUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import {connect} from 'fiba/common/utils/reactUtils';
import {
  GamePlayByPlayPage,
  GamePlayByPlayTab,
} from 'fiba/wt/ui/gamePlayByPlayPage/GamePlayByPlayPage';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {BlockText, P} from 'fiba/wt/ui/text/Text';
import {Map} from 'immutable';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {SpaceScale} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from '../viewWebData/ViewWebData';
import ResultsGameSummary from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {GameInfoPage} from 'fiba/wt/ui/gameInfoPage/GameInfoPage';

import Event from 'fiba/common/core/models/api/events/Event';
import {GameHeading} from 'fiba/wt/ui/gamePage/GameHeading';

export type GamePageTab =
  | ['info']
  | ['box-score', GameBoxScoreTab]
  | ['play-by-play', GamePlayByPlayTab];

interface OwnProps {
  gameId: string;
  eventId: string;
  season: string;
  tab: GamePageTab;
}

interface GameData {
  gameDetails: ResultsGameDetails;
  gameSummary: ResultsGameSummary;
  event: Event;
}
interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
  gameData: RemoteData.RemoteData<GameData, {}>;
}

type Props = OwnProps & ReduxProps;

const getGameTab = ({tab, ...props}: Props) => {
  switch (tab[0]) {
    case 'info':
      return (
        <Box>
          <GameInfoPage {...(props as any)} />
        </Box>
      );
    case 'play-by-play':
      return <GamePlayByPlayPage tab={tab[1]} {...(props as any)} />;
    case 'box-score':
    default:
      return <GameBoxScore tab={tab[1]} {...(props as any)} />;
  }
};

const PADDING_HORIZONTAL: SpaceScale[] = ['0', '3'];
const PADDING_VERTICAL: SpaceScale[] = ['0', '3', '0'];

const GamePageImpl: React.FC<Props> = props => {
  const headingId = 'event-games-page';
  const {gameData} = props;
  return (
    <Spacer vSpace="3" extraClassName="">
      <Box pt={PADDING_VERTICAL} ph={PADDING_HORIZONTAL}>
        <GameDetails gameData={gameData} headingId={headingId} />
      </Box>
      {RemoteData.match(gameData, {
        NotAsked: () => null,
        Loading: () => null,
        Success: gameData => {
          const {homeTeam, awayTeam, gameIsForfeit} = gameData.gameSummary;
          return gameIsForfeit ? (
            <Box
              bgColor="fullwhite"
              shadow="1"
              pa="4"
              mh={PADDING_HORIZONTAL}
              extraClassName="flex flex-column items-center justify-center"
            >
              <Heading fontSize={['4', '3']}>Game was forfeited</Heading>
              <P fontSize={['5', '4']}>
                Winner:{' '}
                <strong>{`${
                  homeTeam.teamIsWinner
                    ? homeTeam.teamName
                    : awayTeam.teamIsWinner
                    ? awayTeam.teamName
                    : 'N/A'
                }`}</strong>
              </P>
            </Box>
          ) : (
            <GameTabs {...props} />
          );
        },
        Failure: _anomaly => <GameTabs {...props} />,
      })}
    </Spacer>
  );
};

const mapStateToProps = (state, {eventId, gameId}: OwnProps): ReduxProps => {
  const gameData = RemoteData.map3(
    RemoteData.map(
      RemoteData.fromMetaObject(state.getIn(['games', '__meta', 'details', gameId], Map()).toJS()),
      () => state.getIn(['games', 'details', gameId]),
    ),
    RemoteData.map(
      RemoteData.fromMetaObject(state.getIn(['games', '__meta', 'summary', gameId], Map()).toJS()),
      () => state.getIn(['games', 'summary', gameId]),
    ),
    RemoteData.map(state.getIn(['events', eventId]) as RemoteData.WebData<Event>, event => event),

    (gameDetails, gameSummary, event) => ({gameDetails, gameSummary, event}),
  );

  return {
    eventTypeData: getEventType(state.get('events'), eventId),
    gameData,
  };
};

export const GamePage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(GamePageImpl);

// Utils
const GameDetails: React.FC<{
  headingId: string;
  gameData: RemoteData.RemoteData<GameData, {}>;
}> = ({headingId, gameData}) => {
  return RemoteData.match(gameData, {
    // When Loading, create an inline "Loading..." text, to preserve layout better
    Loading: () => <BlockText extraClassName="animated fade-in delay">Loading...</BlockText>,
    Success: gameData => <GameHeading id={headingId} gameData={gameData} />,
    Failure: _anomaly => <BlockText>There was an error fetching the data</BlockText>,
    NotAsked: () => null,
  });
};

const GameTabs: React.FC<Props> = props => {
  const headingId = 'event-games-page';
  const {season, eventId, eventTypeData, gameId} = props;
  const {seasonConfig} = useContext(SiteConfigContext);

  return (
    <ViewWebData data={eventTypeData}>
      {eventType => (
        <Box bgColor="fullwhite" shadow="1" mh={PADDING_HORIZONTAL}>
          <Tabs
            level="base"
            navigationLabeledBy={headingId}
            baseUrl={`${eventRootLink(seasonConfig, season, eventId, eventType)}/games/${gameId}`}
            extraClassName="bg-fullwhite dn-print"
          >
            <Tab href="/box-score" hrefAliases={['/']}>
              Box Score
            </Tab>
            <Tab href="/play-by-play">Play-by-Play</Tab>
            <Tab href="/info">Game Info</Tab>
          </Tabs>

          <Section>
            <Box pv={['3', '4']} ph={['3', '4']}>
              {getGameTab(props)}
            </Box>
          </Section>
        </Box>
      )}
    </ViewWebData>
  );
};
