import {get} from 'lodash';
import {Tab} from 'fiba/wt/ui/navigation/Navigation';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {isLegacySeason} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {
  EventType,
  eventRootLink,
  eventStandingsLink,
  eventTeamsLink,
  eventScheduleLink,
  eventNewsLink,
  eventPhotosLink,
  eventVideosLink,
  eventDunkLink,
  eventShootoutLink,
  eventHowToQualifyLink,
  eventMediaServicesLink,
  eventAwardsLink,
  eventPrizesLink,
  eventAboutLink,
  eventStatisticsLink,
  eventHistoryLink,
  eventConferenceStandingsLink,
} from 'fiba/wt/utils/linkUtils';
import {flatten} from 'lodash';
import {
  EventConfiguration,
  ContentfulItem,
  CustomPage,
  Link,
  NavigationSubMenu,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {Map} from 'immutable';
import {ContentTypes} from 'fiba/wt/services/contentfulService';
import {isNt} from 'fiba/wt/utils/routeUtils';

// TODO: Use the linkUtils (renamed to linkProviders and namespaced), instead of eventSlugFromId
// TODO: use the resource providers in this file
export type EventTabs =
  | 'overview'
  | 'standings'
  | 'standings/women'
  | 'standings/men'
  | 'teams'
  | 'teams/men'
  | 'teams/women'
  | 'games'
  | 'stats'
  | 'stats/men'
  | 'stats/women'
  | 'news'
  | 'photos'
  | 'videos'
  | 'dunk'
  | 'shootout'
  | 'about'
  | 'prizes'
  | 'awards'
  | 'how-to-qualify'
  | 'media-services'
  | 'tickets'
  | 'history';

interface ContentfulNavProps {
  season: string;
  eventId: string;
  eventTab: EventTabs;
  eventConfiguration: EventConfiguration;
  siteConfig: ISiteConfig;
  eventType: EventType;
}

interface TicketsPageNavProps {
  ticketsArticle: Map<string, any>;
  season: string;
  eventId: string;
  eventTab: EventTabs;
}

// Lists

// Shown in default/placeholder state
export const getDefaultSecondaryTabs = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
  isSingleEventConference: boolean,
  ticketsPageNav?: Tab,
): Tab[] => {
  const {siteId} = siteConfig.features;
  const commonTabs = flatten([
    Overview(siteConfig, season, eventId, eventTab, eventType),
    Teams(siteConfig, season, eventId, eventTab, eventType),
    ticketsPageNav,
    News(siteConfig, season, eventId, eventTab, eventType),
    Photos(siteConfig, season, eventId, eventTab, eventType),
    Videos(siteConfig, season, eventId, eventTab, eventType),
    About(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
  ]).filter(x => !!x);

  const womensSeriesTabs = [
    ...commonTabs,
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
  ];

  const cupsTabs = [
    ...commonTabs,
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    History(siteConfig, season, eventId, eventTab, eventType),
  ];

  const nationsLeagueTabs = [
    ...cupsTabs.slice(0, 3),
    ConferenceStandings(siteConfig, season, eventTab, eventId, isSingleEventConference),
    ...cupsTabs.slice(3),
  ];

  const worldTourTabs = [
    ...commonTabs,
    ['Challenger', 'WorldTour', 'WomensSeries'].includes(eventType) &&
      HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
    Prizes(siteConfig, season, eventId, eventTab, eventType),
  ];

  switch (siteId) {
    case 'womensseries':
      return womensSeriesTabs;
    case 'cups':
      return cupsTabs;
    case 'nationsleague':
      return nationsLeagueTabs;
    default:
      return worldTourTabs;
  }
};

// Shown in Pre
export const getPreSecondaryTabs = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
  isSingleEventConference: boolean,
  ticketsPageNav?: Tab,
): Tab[] => {
  const isWTMasters = siteConfig.features.siteId === 'worldtour' && eventType === 'WorldTour';
  const isWTChallenger = siteConfig.features.siteId === 'worldtour' && eventType === 'Challenger';

  const commonTabs = flatten([
    Overview(siteConfig, season, eventId, eventTab, eventType),
    Standings(siteConfig, season, eventId, eventTab, eventType),
    ticketsPageNav,
    Teams(siteConfig, season, eventId, eventTab, eventType),
    Games(siteConfig, season, eventId, eventTab, eventType),
    News(siteConfig, season, eventId, eventTab, eventType),
    Photos(siteConfig, season, eventId, eventTab, eventType),
    Videos(siteConfig, season, eventId, eventTab, eventType),
    About(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
  ]).filter(x => !!x);

  const worldTourTabs = [
    ...commonTabs,
    (isWTChallenger || isWTMasters) &&
      HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    isWTMasters && Dunk(siteConfig, season, eventId, eventTab, eventType),
    isWTMasters && Shootout(siteConfig, season, eventId, eventTab, eventType),
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
    Prizes(siteConfig, season, eventId, eventTab, eventType),
  ];

  const cupsTabs = [
    ...commonTabs,
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    History(siteConfig, season, eventId, eventTab, eventType),
  ];

  const nationsLeagueTabs = [
    ...cupsTabs.slice(0, 3),
    ConferenceStandings(siteConfig, season, eventTab, eventId, isSingleEventConference),
    ...cupsTabs.slice(3),
  ];

  const womensSeriesTabs = [
    ...commonTabs,
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
  ];

  switch (siteConfig.features.siteId) {
    case 'womensseries': {
      return womensSeriesTabs;
    }
    case 'cups': {
      return cupsTabs;
    }
    case 'nationsleague': {
      return nationsLeagueTabs;
    }
    default: {
      return worldTourTabs;
    }
  }
};

// Shown in Live and Post
// Hides Dunk and Shootout in Challengers
// For seasons 2012-2016 show reduced template
export const getLiveAndPostSecondaryTabs = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
  isSingleEventConference: boolean,
  ticketsPageNav?: Tab,
): Tab[] => {
  const isCups = siteConfig.features.siteId === 'cups';
  const isNationsLeague = siteConfig.features.siteId === 'nationsleague';
  const isWS = siteConfig.features.siteId === 'womensseries';

  const commonTabs = flatten([
    Overview(siteConfig, season, eventId, eventTab, eventType),
    Standings(siteConfig, season, eventId, eventTab, eventType),
    Teams(siteConfig, season, eventId, eventTab, eventType),
    Games(siteConfig, season, eventId, eventTab, eventType),
    ticketsPageNav,
    Stats(siteConfig, season, eventId, eventTab, eventType),
    News(siteConfig, season, eventId, eventTab, eventType),
    Photos(siteConfig, season, eventId, eventTab, eventType),
    Videos(siteConfig, season, eventId, eventTab, eventType),
  ]).filter(x => !!x);

  const worldTourTabs = [
    ...commonTabs,
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    Dunk(siteConfig, season, eventId, eventTab, eventType),
    Shootout(siteConfig, season, eventId, eventTab, eventType),
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
    Prizes(siteConfig, season, eventId, eventTab, eventType),
    About(siteConfig, season, eventId, eventTab, eventType),
  ];

  const worldTourChallengerTabs = [
    ...commonTabs,
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
    Prizes(siteConfig, season, eventId, eventTab, eventType),
    About(siteConfig, season, eventId, eventTab, eventType),
  ];

  const worldTourSuperQuestTabs = [
    ...commonTabs,
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
    Prizes(siteConfig, season, eventId, eventTab, eventType),
    About(siteConfig, season, eventId, eventTab, eventType),
  ];

  const womensSeriesTabs = [
    ...commonTabs,
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
    MediaServices(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
    About(siteConfig, season, eventId, eventTab, eventType),
  ];

  const legacySeasonsTabs = flatten([
    Overview(siteConfig, season, eventId, eventTab, eventType),
    Standings(siteConfig, season, eventId, eventTab, eventType),
    Teams(siteConfig, season, eventId, eventTab, eventType),
    Games(siteConfig, season, eventId, eventTab, eventType),
    Stats(siteConfig, season, eventId, eventTab, eventType),
    News(siteConfig, season, eventId, eventTab, eventType),
    Photos(siteConfig, season, eventId, eventTab, eventType),
    Videos(siteConfig, season, eventId, eventTab, eventType),
    HowToQualify(siteConfig, season, eventId, eventTab, eventType),
  ]);

  const cupsTabs = [
    ...commonTabs,
    About(siteConfig, season, eventId, eventTab, eventType),
    Awards(siteConfig, season, eventId, eventTab, eventType),
    History(siteConfig, season, eventId, eventTab, eventType),
  ];

  const nationsLeagueTabs = [
    ...cupsTabs.slice(0, 3),
    ConferenceStandings(siteConfig, season, eventTab, eventId, isSingleEventConference),
    ...cupsTabs.slice(3),
  ];

  // TODO: Get rid of isLegacySeason
  if (isCups) {
    return cupsTabs;
  } else if (isNationsLeague) {
    return nationsLeagueTabs;
  } else if (isLegacySeason(season)) {
    return legacySeasonsTabs;
  } else if (eventType === 'Challenger') {
    return worldTourChallengerTabs;
  } else if (eventType === 'SuperQuest') {
    return worldTourSuperQuestTabs;
  } else if (isWS) {
    return womensSeriesTabs;
  } else {
    return worldTourTabs;
  }
};

const Overview = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Overview',
  href: eventRootLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'overview',
});

const Standings = (...params: Parameters<typeof AllStandings>) => {
  return isNt(params[0])
    ? [MensStandings(...params), WomensStandings(...params)]
    : [AllStandings(...params)];
};

const AllStandings = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Standings',
  href: eventStandingsLink(siteConfig.seasonConfig, season, eventId, eventType, ''),
  active: eventTab === 'standings',
});

const WomensStandings = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: "Women's Standings",
  href: eventStandingsLink(siteConfig.seasonConfig, season, eventId, eventType, 'women'),
  active: eventTab === 'standings/women',
});

const MensStandings = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: "Men's Standings",
  href: eventStandingsLink(siteConfig.seasonConfig, season, eventId, eventType, 'men'),
  active: eventTab === 'standings/men',
});

const Teams = (...params: Parameters<typeof AllTeams>) => {
  return isNt(params[0]) ? [MensTeams(...params), WomensTeams(...params)] : [AllTeams(...params)];
};

const AllTeams = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Teams',
  href: eventTeamsLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'teams',
});

const WomensTeams = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: "Women's Teams",
  href: `${eventTeamsLink(siteConfig.seasonConfig, season, eventId, eventType)}/women`,
  active: eventTab === 'teams/women',
});

const MensTeams = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: "Men's Teams",
  href: `${eventTeamsLink(siteConfig.seasonConfig, season, eventId, eventType)}/men`,
  active: eventTab === 'teams/men',
});

const Games = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Games',
  href: eventScheduleLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'games',
});

const Stats = (...params: Parameters<typeof AllStats>): Tab[] => {
  const [siteConfig] = params;

  return isNt(siteConfig) ? [MensStats(...params), WomensStats(...params)] : [AllStats(...params)];
};

const AllStats = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
): Tab | null => {
  return {
    name: 'Stats',
    href: eventStatisticsLink(siteConfig.seasonConfig, season, eventId, eventType),
    active: eventTab === 'stats',
  };
};

const MensStats = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: "Men's Stats",
  href: `${eventStatisticsLink(siteConfig.seasonConfig, season, eventId, eventType, 'men')}`,
  active: eventTab === 'stats/men',
});

const WomensStats = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: "Women's Stats",
  href: `${eventStatisticsLink(siteConfig.seasonConfig, season, eventId, eventType, 'women')}`,
  active: eventTab === 'stats/women',
});

const ConferenceStandings = (
  siteConfig: ISiteConfig,
  season: string,
  eventTab: EventTabs,
  conferenceId: string,
  isSingleEventConference: boolean,
) =>
  isSingleEventConference
    ? null
    : {
        name: 'Conference Standings',
        href: eventConferenceStandingsLink(
          siteConfig.seasonConfig,
          season,
          conferenceId,
          'NationsLeague',
        ),
        active: eventTab === 'standings',
      };

const News = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'News',
  href: eventNewsLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'news',
});

const Photos = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Photos',
  href: eventPhotosLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'photos',
});

const Videos = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Videos',
  href: eventVideosLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'videos',
});

const Dunk = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Dunk',
  href: eventDunkLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'dunk',
});

const Shootout = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Shoot-Out',
  href: eventShootoutLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'shootout',
});

const HowToQualify = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'How to Qualify',
  href: eventHowToQualifyLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'how-to-qualify',
});

const History = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'History',
  href: eventHistoryLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'history',
});

const MediaServices = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Media Services',
  href: eventMediaServicesLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'media-services',
});

const Awards = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Awards',
  href: eventAwardsLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'awards',
});

const Prizes = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'Prizes',
  href: eventPrizesLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'prizes',
});

const About = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
) => ({
  name: 'About',
  href: eventAboutLink(siteConfig.seasonConfig, season, eventId, eventType),
  active: eventTab === 'about',
});

const childActive = (tabs: Tab[]): boolean => {
  return (
    tabs.some(tab => tab.active) ||
    tabs.filter(tab => !!tab.children).some(tab => childActive(tab.children))
  );
};

const parseNavigationMenu = (
  season: string,
  eventId: string,
  eventTab: EventTabs,
  navigationMenu: Array<ContentfulItem<NavigationSubMenu | Link | CustomPage>>,
  siteConfig: ISiteConfig,
  eventType: EventType,
) => {
  return navigationMenu.reduce((acc, navigationMenuItem) => {
    // To avoid deleted navigation menu item content without removing the item from the navigation menu on Contentful
    if (!navigationMenuItem.fields) {
      return acc;
    }

    const contentType = get(navigationMenuItem, 'sys.contentType.sys.id');
    const {CustomPage, Link, NavigationSubMenu} = ContentTypes;

    switch (contentType) {
      case CustomPage:
        const {
          fields: {shortName: customPageShortName, title: customPageTitle, slug},
        } = navigationMenuItem as ContentfulItem<CustomPage>;
        acc.push({
          name: customPageShortName || customPageTitle,
          href: `${eventRootLink(siteConfig.seasonConfig, season, eventId, eventType)}/${slug}`,
          active: eventTab === slug,
        });
        break;
      case Link:
        const {
          fields: {url, opensInNewTab, shortName: linkShortName, title: linkTitle},
        } = navigationMenuItem as ContentfulItem<Link>;
        acc.push({
          name: linkShortName || linkTitle,
          href: url,
          opensInNewTab,
          active: false,
        });
        break;
      case NavigationSubMenu:
        const {
          fields: {shortName: subMenuShortName, title: subMenuTitle, menuItems},
        } = navigationMenuItem as ContentfulItem<NavigationSubMenu>;
        const children = parseNavigationMenu(
          season,
          eventId,
          eventTab,
          menuItems,
          siteConfig,
          eventType,
        );
        acc.push({
          name: subMenuShortName || subMenuTitle,
          children,
          active: childActive(children),
        });
        break;
    }
    return acc;
  }, []);
};

export const getContentfulNav = ({
  eventConfiguration,
  eventTab,
  season,
  eventId,
  siteConfig,
  eventType,
}: ContentfulNavProps): Tab[] => {
  const navigationMenu = get(eventConfiguration, 'navigationMenu');

  if (!navigationMenu || navigationMenu.length === 0) {
    return [];
  }

  return parseNavigationMenu(season, eventId, eventTab, navigationMenu, siteConfig, eventType);
};

export const getTicketsPage = ({
  ticketsArticle,
  season,
  eventId,
  eventTab,
}: TicketsPageNavProps): Tab | null => {
  if (!ticketsArticle) {
    return null;
  }

  // Hard-code the tickets nav item: we want a unified name no
  // matter how it is named in Contentful
  return {
    name: 'Tickets',
    href: `/${season}/${eventId}/tickets`,
    active: eventTab === 'tickets',
  };
};
