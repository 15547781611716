import React from 'react';
import cx from 'classnames';
import {List} from 'immutable';
import {Table as StyledTable, TableLayoutStyleProps, Td, Th, Tr} from 'fiba/wt/ui/table/Table';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {ComparatorFunction} from 'fiba/wt/stores/reducers/sortableStore';
import {Box} from 'fiba/wt/ui/box/Box';
import {SortArrowDown, SortArrowUp, SortArrowUnset} from 'fiba/wt/ui/svg/svg';
import {useHorizontalScrollRegion} from 'fiba/wt/ui/hooks/useHorizontalScrollRegion';
import {ArrowRight} from '@fpapado/react-feather';

/* Future additions:
 *  - Sortable
 *  - Dark/light prop
 *  - Expose StyleProps for table
 */
type SortingOrder = 'none' | 'ascending' | 'descending';

const getNextSortOrderText = (columnDisplayName: string, currentSortOrder: SortingOrder) => {
  switch (currentSortOrder) {
    case 'none':
      return `Sort by ${columnDisplayName} in descending order`;
    case 'descending':
      return `Sort by ${columnDisplayName} in ascending order`;
    case 'ascending':
      return `Sort by ${columnDisplayName} in descending order`;
  }
};

export interface FieldSort {
  fieldName: string;
  sortOrder: SortingOrder;
}

export type DataType = 'text' | 'number';

/* A "Spec" is the main API for customising the table */
export interface IColumnSpec<RD> {
  // Name shown in header
  name: string;
  // Screen-reader friendly name; hidden visually
  srName?: string;
  // Datatype for deciding whether to align left or right
  dataType: DataType;
  // Custom comparator for sorting to override default
  comparator?: ComparatorFunction;
  renderColumn: (props: IRenderCallback<RD>) => React.ReactElement<any>;
  renderHeader?: (props: IHeaderRenderCallBack<RD>) => React.ReactElement<any>;
}

/* Render Prop Interface */
export interface IRenderCallback<RD> {
  rowData: RD;
  columnName: string;
  columnIndex: number;
  rowIndex: number;
  Td: typeof Td;
  getTdProps: ReturnType<typeof getTdProps>;
}

export interface IHeaderRenderCallBack<RD> {
  columnName: string;
  Th: typeof Th;
  getThProps: ReturnType<typeof getThProps>;
  columnSpec: IColumnSpec<RD>;
}

/* Constants */
/* Default Td Style, used with the callback props */

const defaultTdStyle: TableLayoutStyleProps = {
  // TODO: Consider tweaking
  verticalAlign: 'middle',
  lineHeight: 'title',
  fontSize: '7',
  ph: '2',
  pv: '2',
};

const defaultThStyle: TableLayoutStyleProps = {
  ...defaultTdStyle,
  fontWeight: '6',
  textTransform: 'uppercase',
};

/* Return the Td props, after merging defaults with the caller's */
type TdProps = {key: string} & TableLayoutStyleProps;
const getTdProps = (key: string, dataType: string) => (
  consumerProps: TableLayoutStyleProps = {},
): TdProps => ({
  key,
  ...defaultTdStyle,
  textAlign: dataType === 'text' ? 'left' : 'right',
  ...consumerProps,
});

type ThProps = {key: string} & TableLayoutStyleProps;
const getThProps = (key: string, dataType: string) => (
  consumerProps: TableLayoutStyleProps = {},
): ThProps => ({
  key,
  ...defaultThStyle,
  textAlign: dataType === 'text' ? 'left' : 'right',
  ...consumerProps,
});

/* DataTable Props */
export interface Props<CH extends string, RD> {
  captionId: string;
  caption?: React.ReactNode;
  rows: RD[] | List<RD>;
  columns: CH[];
  columnsSpec: Record<CH, IColumnSpec<RD>>;
  striped?: boolean;
  headerStyleProps?: TableLayoutStyleProps;
  headerExtraClassName?: string;
  rowExtraClassName?: string;
  transpose?: boolean;
  sortedBy?: FieldSort;
  frozenHeaders?: boolean;
  skipMaxWidth?: boolean;
  renderTop?: () => React.ReactNode;
  sortAction?: (key, columnSpec: Record<CH, IColumnSpec<RD>>) => any;
}

/* Customisable DataTable that handles design and accessibility defaults */
export function DataTable<CH extends string, RD>(props: Props<CH, RD>) {
  const {
    caption,
    captionId,
    columnsSpec,
    rows,
    columns,
    striped,
    headerStyleProps,
    headerExtraClassName,
    rowExtraClassName,
    transpose,
    sortedBy,
    sortAction,
    renderTop,
    skipMaxWidth,
    frozenHeaders,
  } = props;

  const {containerProps, isScrollable} = useHorizontalScrollRegion({'aria-labelledby': captionId});

  // Assemble Table row classes
  // TODO: provide a light variant
  // TODO: Connect this to ThemeContext and provide sensible values from there
  const stripedCls = cx({
    'striped--dark': striped,
  });
  const highlightCls = 'hover-bg-blue-palest focusw-bg-blue-palest';
  const TrCls = cx(stripedCls, highlightCls);

  const renderHeader = columnName =>
    columnsSpec[columnName].renderHeader ? customHeader(columnName) : defaultHeader(columnName);

  const getColumnSortOrder = (columnName: CH) => {
    if (!sortedBy) {
      return;
    }
    if (sortedBy.fieldName === columnName) {
      return sortedBy.sortOrder;
    } else {
      return 'none';
    }
  };

  // TODO: Pass sortOrder in, instead of calculating here
  const sortingButton = (sortingOrder: SortingOrder, columnName: CH, columnDisplayName: string) => {
    return sortAction ? (
      <button
        className="button-reset pa0 ml2 br2 focus-shadow"
        onClick={() => sortAction(columnName, columnsSpec)}
        aria-label={getNextSortOrderText(columnDisplayName, sortingOrder)}
      >
        {sortingIndicator(sortingOrder)}
      </button>
    ) : null;
  };

  // TODO: Merge with sortingButton
  const sortingIndicator = (sortOrder: SortingOrder) => {
    switch (sortOrder) {
      case 'descending':
        return <SortArrowDown className="w1 ht1 v-mid" purpose="decorative" />;
      case 'ascending':
        return <SortArrowUp className="w1 ht1 v-mid" purpose="decorative" />;
      case 'none':
        return <SortArrowUnset className="w1 ht1 v-mid" purpose="decorative" />;
    }
  };

  const defaultHeader = (columnName: CH) => {
    const sortingOrder = getColumnSortOrder(columnName);

    return (
      <Th
        key={columnName}
        role="columnheader"
        scope="col"
        aria-sort={sortingOrder}
        textAlign={columnsSpec[columnName].dataType === 'text' ? 'left' : 'right'}
        {...defaultThStyle}
        {...headerStyleProps}
        extraClassName={headerExtraClassName}
      >
        {/* If screen reader name exists, assume the column name is an abbreviation */}
        {/* TODO: Rework this to not use abbr */}
        <Flex display="inline_flex" alignItems="center">
          {columnsSpec[columnName].srName ? (
            <abbr className="ws-nowrap" title={columnsSpec[columnName].srName}>
              {columnsSpec[columnName].name}
            </abbr>
          ) : (
            <span className="ws-nowrap">{columnsSpec[columnName].name}</span>
          )}
          {sortingButton(
            sortingOrder,
            columnName,
            columnsSpec[columnName].srName || columnsSpec[columnName].name,
          )}
        </Flex>
      </Th>
    );
  };

  const customHeader = columnName =>
    columnsSpec[columnName].renderHeader({
      Th,
      columnName,
      getThProps: getThProps(columnName, columnsSpec[columnName].dataType),
      columnSpec: columnsSpec,
    });

  const createSortingPath = (sortedBy: FieldSort, spec) => {
    const fieldName = sortedBy && sortedBy.fieldName;
    return fieldName ? <div>{`Sorted by: ${spec[fieldName].name}`}</div> : undefined;
  };

  return (
    <Box extraClassName="DataTableContainer">
      <Box maxWidth={skipMaxWidth ? '100' : '6'}>
        {/* TODO: Revisit whether the caption is optional */}
        <div id={captionId}>
          {!!caption && <div>{caption}</div>}
          {createSortingPath(sortedBy, columnsSpec)}
        </div>
        {renderTop ? <Box mv="2">{renderTop()} </Box> : null}
      </Box>
      {isScrollable && (
        <Box display={['none', 'none', 'block']} textAlign="right" mt="1">
          <small className="steel-40 f7 tr">
            Scroll for more <ArrowRight className="v-btm" purpose="decorative" width=".8rem" />
          </small>
        </Box>
      )}
      <div className="DataTable">
        <div
          {...containerProps}
          className={cx(
            'overflow-x-auto focus-shadow',
            containerProps.className,
            frozenHeaders && 'DataTable-StickyContainer',
          )}
        >
          <StyledTable
            width="100"
            extraClassName={cx({'tnum br2': true, 'DataTable-Sticky': frozenHeaders})}
            /** TODO: add aria-labelledby here, and point to caption Id. Make Caption mandatory */
          >
            {!transpose ? (
              <tbody>
                {/* Column Headers */}
                <Tr bgColor="steel-40" color="silver-10" extraClassName={rowExtraClassName}>
                  {columns.map(columnName => renderHeader(columnName))}
                </Tr>
                {/* The cast to RD[] is to fulfill both Immutable List and native array */}
                {(rows as RD[]).map((row, rowIndex) => (
                  <tr key={rowIndex} className={cx(TrCls, rowExtraClassName)}>
                    {columns.map((columnName, columnIndex) =>
                      // Match the column name with the header, and invoke the render callback
                      columnsSpec[columnName].renderColumn({
                        Td,
                        columnName,
                        getTdProps: getTdProps(columnName, columnsSpec[columnName].dataType),
                        columnIndex,
                        rowIndex,
                        rowData: row,
                      }),
                    )}
                  </tr>
                ))}
              </tbody>
            ) : (
              <tbody>
                {/* Column Headers */}
                {columns.map((columnName, columnIndex) => (
                  <Tr key={columnName} extraClassName={cx(TrCls, rowExtraClassName)}>
                    <Th
                      role="rowheader"
                      scope="row"
                      textAlign="left"
                      {...defaultThStyle}
                      {...headerStyleProps}
                      extraClassName={headerExtraClassName}
                    >
                      {/* If screen reader name exists, assume the column name is an abbreviation */}
                      {columnsSpec[columnName].srName ? (
                        <abbr title={columnsSpec[columnName].srName}>
                          {columnsSpec[columnName].name}
                        </abbr>
                      ) : (
                        columnsSpec[columnName].name
                      )}
                    </Th>
                    {(rows as RD[]).map((row, rowIndex) =>
                      columnsSpec[columnName].renderColumn({
                        Td,
                        columnName,
                        getTdProps: getTdProps(columnName, columnsSpec[columnName].dataType),
                        columnIndex,
                        rowIndex,
                        rowData: row,
                      }),
                    )}
                  </Tr>
                ))}
              </tbody>
            )}
          </StyledTable>
        </div>
      </div>
    </Box>
  );
}
