import React from 'react';
import {Map} from 'immutable';
import moment from 'moment';
import {Asset} from 'contentful';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Season} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {DomainType} from 'fiba/common/utils/domainUtils';

export type EventProgrammabilityState = 'none' | 'pre' | 'live' | 'post';

export enum EventColorTheme {
  dark = 'Dark',
  light = 'Light',
}

export enum EventStatsVisibility {
  fullStats = 'Full stats',
  reducedStats = 'Reduced stats',
  useSeasonDefault = 'Use season default',
}

export type ContentfulEventConfigurationMap = ContentfulItem<EventConfiguration> & Map<string, any>;

export interface ContentfulItem<T> {
  fields: T;
  // Unnecessary field from Contentful. Do we need to store them on Redux store?
  metadata: {};
  sys: {};
}

export interface Link {
  title?: string;
  shortName: string;
  url: string;
  opensInNewTab: boolean;
}

export interface CustomPage {
  title?: string;
  shortName: string;
  slug: string;
  path?: string;
  content?: string;
}

export interface NavigationSubMenu {
  title?: string;
  shortName: string;
  menuItems: Array<ContentfulItem<NavigationSubMenu | Link | CustomPage>>;
}

export enum ImageAlignments {
  center = 'center',
  left = 'left',
  right = 'right',
  cover = 'cover',
}

type DomainsWithoutPlay = Exclude<DomainType, 'play'>;

/*
  defaultSeasons: {
    worldtour: Season;
    womensseries: Season;
    cups: Season;
    nationsleague: Season;
}
*/
export interface GlobalConfiguration {
  defaultSeasons: Record<DomainsWithoutPlay, Season>;
}

export type RoundCode = 'L32' | 'L16' | 'L8' | 'SF' | 'F';

// it is important that the round codes are in order of occurence in the bracket, it's relied on later
export const KORounds: RoundCode[] = ['L32', 'L16', 'L8', 'SF', 'F'];

type Enabled = 'Enabled';

export interface EventConfiguration {
  title?: string;
  path: string;
  publishDatetime?: string;
  startDatetime?: string;
  endDatetime?: string;
  unpublishDatetime?: string;
  showStats?: EventStatsVisibility;
  navigationMenu?: Array<ContentfulItem<NavigationSubMenu | Link | CustomPage>>;
  backgroundColor?: {
    value: string;
  };
  backgroundImage?: Asset;
  eventLogo?: Asset;
  colorTheme: EventColorTheme;
  headerBackgroundImage?: Asset;
  headerBackgroundImageAlign?: ImageAlignments;
  headerMobileBackgroundImage?: Asset;
  headerImageLink?: string;
  headerBackgroundColor?: {
    value: string;
  };
  hideExactGameTimes?: Enabled[];
  hideGamePairingsFrom?: RoundCode;
  hideGamePairingsUntil?: RoundCode;
  hideWomenGamePairingsUntil?: RoundCode;
  hideWomenGamePairingsFrom?: RoundCode;
}

interface EventProgrammabilityStateProviderProps {
  eventConfiguration?: EventConfiguration;
  // For testing purposes
  overrideNow?: moment.Moment | Date | string | number;
  overrideState?: EventProgrammabilityState;
  children: (state: EventProgrammabilityState) => React.ReactElement<any>;
}

export const getEventStatsVisibility = (
  siteConfig: ISiteConfig,
  eventConfig: EventConfiguration,
  season: string,
): boolean => {
  const showStats = eventConfig?.showStats ?? EventStatsVisibility.useSeasonDefault;
  const {showStatsForSeason} = siteConfig.seasonConfig.seasons[season];

  return (
    showStats === EventStatsVisibility.fullStats ||
    (showStats === EventStatsVisibility.useSeasonDefault && showStatsForSeason)
  );
};

export function getEventProgrammabilityState(
  eventConfiguration?: EventConfiguration,
  overrideNow?: moment.Moment | Date | string | number,
  overrideState?: EventProgrammabilityState,
): EventProgrammabilityState {
  // Development test case first
  if (overrideState) {
    return overrideState;
  }

  // Consider all events without configuration to be in default state
  if (!eventConfiguration) {
    return 'none';
  }
  // Converts to UTC
  const now = moment.utc(overrideNow);
  const {publishDatetime, startDatetime, endDatetime} = eventConfiguration;

  //Checks UTC time of client vs published and start time
  if (
    publishDatetime &&
    now.isSameOrAfter(publishDatetime) &&
    startDatetime &&
    now.isBefore(startDatetime)
  ) {
    return 'pre';
  } else if (
    startDatetime &&
    now.isSameOrAfter(startDatetime) &&
    endDatetime &&
    now.isBefore(endDatetime)
  ) {
    return 'live';
  } else if (endDatetime && now.isSameOrAfter(endDatetime)) {
    return 'post';
  } // NOTE: unpublishDatetime is not taken into account for event state

  return 'none';
}

export const EventProgrammabilityStateProvider: React.FunctionComponent<EventProgrammabilityStateProviderProps> = ({
  children,
  eventConfiguration,
  overrideNow,
  overrideState,
}) => children(getEventProgrammabilityState(eventConfiguration, overrideNow, overrideState));
