import {fromJS, Map} from 'immutable';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {createActions, createReducer} from 'fiba/wt/utils/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import {rethrow, setMeta} from 'fiba/common/stores/storeUtils';
import {mapValues} from 'lodash';
import {
  SortableStore,
  SortableStoreItem,
  sortingReducers,
} from 'fiba/wt/stores/reducers/sortableStore';
import {PlayerStatsLike} from 'fiba/common/core/models/api/stats/PlayerStats';
import PlayerTourStatsResponse, {
  PlayerTourStatsResponseLike,
} from 'fiba/common/core/models/api/stats/PlayerTourStatsResponse';
import ResultsTeam from 'fiba/common/core/models/api/results/ResultsTeam';
import TeamPlayer from 'fiba/common/core/models/api/stats/TeamPlayer';

export const statePath = (eventOrTourId: string) => [eventOrTourId];
const metaPath = (eventOrTourId: string) => ['__meta', ...statePath(eventOrTourId)];
export const storePath = 'proCircuitPlayerStats';
export const globalStatePath = (eventOrTourId: string) => [storePath, ...statePath(eventOrTourId)];
export const globalMetaPath = (eventOrTourId: string) => [storePath, ...metaPath(eventOrTourId)];

export interface PlayerStatRow extends PlayerStatsLike {
  player: TeamPlayer;
  team: ResultsTeam;
  updatedAt: string;
}

export interface ProCircuitPlayerStatsStore extends SortableStore<PlayerStatRow> {}

const mapResponse = (response: PlayerTourStatsResponse): SortableStoreItem<PlayerStatRow> => {
  const playersById: Map<string, TeamPlayer> = response.players.reduce(
    (map, value) => map.set(value.id, value),
    Map(),
  );

  const teamsById: Map<string, ResultsTeam> = response.teams.reduce(
    (map, value) => map.set(value.teamId, value),
    Map(),
  );

  const value: SortableStoreItem<PlayerStatRow> = {
    sortedBy: {
      fieldName: null,
      sortOrder: 'none',
    },
    updatedAt: response.updatedAt,
    data: response.playerStatistics
      .map(stat => {
        return fromJS({
          team: teamsById.get(playersById.get(stat.playerId).teamId),
          player: playersById.get(stat.playerId),
          ...stat.toJS(),
        });
      })
      .toList(),
  };

  return fromJS(value);
};

const reducers = {
  ...sortingReducers,

  loadProCircuitTourPlayerStats: (
    state: ProCircuitPlayerStatsStore,
    eventOrTourId: string,
  ): ProCircuitPlayerStatsStore => {
    return state.updateIn(metaPath(eventOrTourId), setMeta.isLoading);
  },

  loadProCircuitStatsSuccess: (
    state: ProCircuitPlayerStatsStore,
    eventOrTourId: string,
    response: PlayerTourStatsResponseLike,
  ): ProCircuitPlayerStatsStore => {
    /* If stats have totalPointsMade, let's default to sorting with that */
    response.playerStatistics.sort((a, b) => b.totalPointsMade - a.totalPointsMade);
    const value = mapResponse(PlayerTourStatsResponse.fromJS(response));
    return state
      .setIn(statePath(eventOrTourId), value)
      .updateIn(metaPath(eventOrTourId), setMeta.isLoaded);
  },

  loadProCircuitStatsError: (
    state: ProCircuitPlayerStatsStore,
    eventOrTourId: string,
    error: Error,
  ) => {
    return state.updateIn(metaPath(eventOrTourId), setMeta.isError);
  },
};

const externalReducers = {};

export const reducer = createReducer<ProCircuitPlayerStatsStore>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

export const actions = createActions(__filename, reducers, {
  loadProCircuitTourPlayerStats: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadProCircuitPlayerTourStats(tourId)
      .then(response => actions.loadProCircuitStatsSuccess(tourId, response.data))
      .catch(rethrow((err: Error) => actions.loadProCircuitStatsError(tourId, err))),

  loadProCircuitStatsError: (eventOrTourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

// Arbitrary JSON -> State
// State as JSON -> State (which is an Immutable map)
export const deserializeState = ({__meta, ...items}) => {
  // Make sure known things exist
  return fromJS({
    __meta: __meta || {},
    ...mapValues(items, item => fromJS(item)),
  });
};
