import React from 'react';

/* This module contains various SVG Icons as React Components.
 * The main benefit of including them like this, is that we can "inline" them
 * and even "lazy-load" them as needed, instead of having to always fetch
 * as <img> tag sources. Furthermore, we can tweak the colours and classes
 * using normal props, and even offer accessible defaults based on the icon
 * being standalone or not.
 *
 * Various Resources:
 * @see https://css-tricks.com/can-make-icon-system-accessible/
 * @see https://css-tricks.com/accessible-svgs/
 * @see http://nicolasgallagher.com/making-svg-icon-libraries-for-react-apps/
 * @see https://github.com/c8r/pixo
 */

interface ISVG extends React.SVGAttributes<SVGElement> {
  icon?: STATS_SUMMARY_ICON;
  fillColor?: string;
  className?: string;
  purpose: Purpose;
}
export type STATS_SUMMARY_ICON = 'player' | 'team' | 'singleGame';
type Purpose = 'decorative' | 'standalone';

export const LoadingSVG: React.SFC<ISVG> = ({fillColor = 'currentcolor', purpose, className}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 55"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    aria-labelledby="loading-svg-title"
  >
    <title id="loading-svg-title">Loading</title>

    <path
      fill={fillColor}
      fillRule="evenodd"
      d="M38.2026681 13l-1.6511855 7.0223031h2.7728654L54.1460433 43h9.0191096l1.6515289-7.0236767h-2.7723503L47.221606 13h-9.0189379zm34.2295212.0427519l-1.6302389 6.9383445h3.5552771l-.9839809 4.0449496H68.761877l-1.7066429 7.3911028h4.6585856l-1.0615868 4.5176245h-3.6093608l-1.6511855 7.0223031h9.8114794l5.6920145-4.3344265 1.7045826-7.176313-3.0304071-3.6428412 4.6280239-3.7178717 1.5886888-6.6634617-3.643528-4.3794104h-9.7103514zm-45.8501451 0l-5.6923579 4.3345982-1.6331576 6.7688821 3.0304071 3.6430129-4.6283674 3.7168415L16 38.5774943l3.643528 4.3792387h9.7098364l1.6298954-6.9376577h-3.5556204l1.0571227-4.4530674h4.6106828l1.7064712-7.3909311h-4.6596157l.9908486-4.1098501h3.1581478l1.6501554-7.0224748h-9.359408zm34.5966039.0429236l-7.2367495 6.4277253 4.5090397 6.9924282 7.2982161-6.4835259h2.8195663l1.6293804-6.9366276h-9.019453zM35.6188383 35.9763233h-2.4251841l-1.6300672 6.9383446h8.6257576l7.2018955-6.4476418-4.5107566-6.9920849-7.2616452 6.5013821z"
    />
  </svg>
);

// Same as 3x3.com, but passed through SVGOMG
export const MenuIcon: React.SFC<ISVG> = ({fillColor = 'currentcolor', className, purpose}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 16"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    aria-labelledby="menu-icon-title"
  >
    <title id="menu-icon-title">Menu</title>
    <g fill="none" fillRule="evenodd">
      <path d="M-2-4h24v24H-2z" />
      <path fill={fillColor} d="M20 9V7H0v2h20zm-10 7v-2H0v2h10zM20 2V0H10v2h10z" />
    </g>
  </svg>
);

export const CloseIcon: React.SFC<ISVG> = ({fillColor = 'currentcolor', className, purpose}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    aria-labelledby="close-icon-title"
  >
    <title id="close-icon-title">Close</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h24v24H0z" />
      <path
        fill={fillColor}
        d="M10.5857864 12L7.29289322 8.70710678l1.41421356-1.41421356L12 10.5857864l3.2928932-3.29289318 1.4142136 1.41421356L13.4142136 12l3.2928932 3.2928932-1.4142136 1.4142136L12 13.4142136l-3.29289322 3.2928932-1.41421356-1.4142136L10.5857864 12zM24 12c0-6.627417-5.372583-12-12-12S0 5.372583 0 12s5.372583 12 12 12 12-5.372583 12-12zM2 12C2 6.4771525 6.4771525 2 12 2s10 4.4771525 10 10-4.4771525 10-10 10S2 17.5228475 2 12z"
      />
    </g>
  </svg>
);

// TODO: add the 3x3 and wt logos here

export const WTLogo: React.SFC<ISVG> = ({
  fillColor = 'currentcolor',
  purpose,
  className,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 204 204"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...rest}
  >
    <title>3x3 World Tour</title>
    <defs>
      <path id="a" d="M.254.305h154.14v44.667H.254z" />
      <path id="c" d="M.408.052H60.2v87.006H.41z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(33.167 19)">
        <mask id="b" fill={fillColor}>
          <use xlinkHref="#a" />
        </mask>
        <path
          d="M33.59 21.002c2.716-1.176 12.626-4.9 18.265.602l-.014.029.192.141c.11.119.376.433.672.955-7.122 1.245-13.728 3.033-19.76 5.372-6.553 2.54-12.445 5.72-17.62 9.498C20.3 31.17 26.486 25.547 33.59 21.002zm39.176-12.42v12.143c-3.719.123-7.332.367-10.84.73-1.123-3.105-2.811-4.86-3.412-5.414a18.822 18.822 0 0 0-5.857-3.872 82.661 82.661 0 0 1 20.11-3.586zm9.11 0a82.66 82.66 0 0 1 19.985 3.547 18.834 18.834 0 0 0-5.96 3.912c-.587.542-2.212 2.24-3.334 5.218a148.66 148.66 0 0 0-10.69-.598V8.583zm20.48 13.222l.205-.2c6.487-6.329 18.865-.334 18.989-.273l.003-.004c6.674 4.344 12.527 9.64 17.3 15.67-4.88-3.535-10.368-6.529-16.425-8.94-6.26-2.493-13.13-4.362-20.553-5.604.206-.326.383-.541.481-.65zM.254 44.972H19.69c4.936-3.837 10.697-7.03 17.214-9.522 5.157-1.973 10.801-3.51 16.89-4.61-.182 3.038-.95 6.721-5.324 14.132h10.19c4.031-7.68 4.204-11.53 4.3-15.419a144.38 144.38 0 0 1 9.806-.675v16.094h9.11V28.816c3.286.071 6.478.25 9.575.533.094 3.957.2 7.806 4.305 15.623h10.19c-4.523-7.66-5.19-11.34-5.342-14.436 6.44 1.102 12.394 2.716 17.807 4.844 6.367 2.502 12.011 5.719 16.865 9.592h19.117C141.165 18.666 111.597.304 77.323.304c-17.99 0-34.68 5.062-48.397 13.671a17.79 17.79 0 0 0-.495.236l.033.052C16.26 22.031 6.46 32.628.254 44.972z"
          fill={fillColor}
          mask="url(#b)"
        />
      </g>
      <path
        fill={fillColor}
        d="M77.338 117.483l-21.237 18.915-7.076-.012-4.803 20.226 25.174.063 21.052-18.767zM130.588 70.251l-21.046 18.734 13.039 20.458 21.224-18.892 8.188.022 4.78-20.256zM133.602 137.245L90.49 70.315l-26.322-.064-4.872 20.419 8.098.019 43.108 66.93 26.325.06 4.873-20.418zM-1 144.792l10.59 12.816 28.29.072 4.8-20.263-10.361-.024 3.11-13 13.433.027 5.023-21.58-13.577-.03 2.915-12.004 9.203.017 4.86-20.51-27.275-.062-16.617 12.626-4.807 19.77 8.803 10.664L3.88 124.14z"
      />
      <g transform="translate(142.704 70.251)">
        <mask id="d" fill={fillColor}>
          <use xlinkHref="#c" />
        </mask>
        <path
          fill={fillColor}
          mask="url(#d)"
          d="M60.2 12.877L49.575.117 21.196.052l-4.82 20.163 10.399.025-2.908 11.755-13.471-.031-5.044 21.48 13.619.032-3.14 13.128-10.549-.027-4.874 20.41 28.684.071L45.756 74.49l5.035-20.854-8.834-10.615L55.51 32.242z"
        />
      </g>
      <path
        fill={fillColor}
        d="M14.223 170.744h3.737l-.554 11.36 3.587-11.36h4.848l-.085 11.36 3.118-11.36h3.289l-4.784 15.074H22.68l.042-11.47-3.63 11.47h-5.02zM42.24 182.89l.532-.546 1.383-8.127-.531-.546h-3.47l-.554.546-1.384 8.127.533.547h3.49zm-8.068.743l1.831-10.704 2.128-2.185h7.983l2.127 2.185-1.85 10.704-2.108 2.185h-8.004l-2.107-2.185zM55.488 177.385h3.911l.4-.415.547-3.124-.421-.437H56.16l-.672 3.976zm-.464 2.752l-.967 5.68h-3.806l2.565-15.073h9.464l2.04 2.119-.778 4.544-2.082 2.163.695 3.386h1.136l-.484 2.862h-3.702l-1.115-5.681h-2.966zM71.824 182.781h5.56l-.525 3.037h-9.524l2.672-15.074h3.94zM88.537 182.89l.547-.546 1.388-8.127-.546-.546h-3.68l-1.556 9.22h3.847zm-5.572-12.146h9.4l2.103 2.185-1.809 10.704-2.103 2.185H80.4l2.566-15.074zM113.229 173.846l-2.06 11.972h-3.821l2.06-11.972h-3.886l.531-3.102h11.528l-.552 3.102zM126.653 182.89l.532-.546 1.384-8.127-.532-.546h-3.47l-.553.546-1.384 8.127.533.547h3.49zm-8.067.743l1.83-10.704 2.13-2.185h7.981l2.128 2.185-1.851 10.704-2.108 2.185h-8.002l-2.108-2.185zM135.67 183.633l2.184-12.89h3.8l-1.973 11.514.546.545h3.233l.526-.545 1.952-11.513h3.8l-2.204 12.889-2.079 2.185h-7.685zM156.986 177.385h3.91l.4-.415.547-3.124-.42-.437h-3.764l-.673 3.976zm-.463 2.752l-.968 5.68h-3.806l2.565-15.073h9.464l2.04 2.119-.778 4.544-2.082 2.163.694 3.386h1.135l-.483 2.862h-3.701l-1.115-5.681h-2.965z"
      />
    </g>
  </svg>
);

export const SortArrowUp: React.SFC<ISVG> = ({
  fillColor = 'currentcolor',
  className,
  purpose,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    {...rest}
  >
    <g fill="none" fillRule="nonzero">
      <path d="M14 14H0V0h14z" />
      <path
        d="M11.8 8.4l-4-5.3333c-.3314-.4419-.9582-.5314-1.4-.2a1 1 0 0 0-.2.2L2.2 8.4c-.3314.4418-.2418 1.0686.2 1.4a1 1 0 0 0 .6.2h8c.5523 0 1-.4477 1-1a1 1 0 0 0-.2-.6z"
        stroke={fillColor}
        fill={fillColor}
      />
    </g>
  </svg>
);

export const SortArrowDown: React.SFC<ISVG> = ({
  fillColor = 'currentcolor',
  className,
  purpose,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    {...rest}
  >
    <g fill="none" fillRule="evenodd">
      <path
        d="M2.2 5.6l4 5.3333c.3314.4419.9582.5314 1.4.2a1 1 0 0 0 .2-.2l4-5.3333c.3314-.4418.2418-1.0686-.2-1.4A1 1 0 0 0 11 4H3c-.5523 0-1 .4477-1 1a1 1 0 0 0 .2.6z"
        stroke={fillColor}
        fill={fillColor}
        fillRule="nonzero"
      />
      <path d="M0 0h14v14H0z" />
    </g>
  </svg>
);

export const SortArrowUnset: React.SFC<ISVG> = ({
  fillColor = 'currentcolor',
  className,
  purpose,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    {...rest}
  >
    <g fill="none" fillRule="evenodd">
      <g fill={fillColor} fillRule="nonzero" stroke={fillColor}>
        <path d="M4.6 9.4364l2.2727 3.0303a.5.5 0 0 0 .8 0l2.2728-3.0303a.5.5 0 0 0-.4-.8H5a.5.5 0 0 0-.4.8zM9.9455 4.5636L7.6727 1.5333a.5.5 0 0 0-.8 0L4.6 4.5636a.5.5 0 0 0 .4.8h4.5455a.5.5 0 0 0 .4-.8z" />
      </g>
      <path d="M0 0h14v14H0z" />
    </g>
  </svg>
);

export const GamePadIcon: React.SFC<ISVG> = ({
  fillColor = 'currentcolor',
  className,
  purpose,
  ...rest
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-hidden={purpose === 'decorative'}
    role="img"
    focusable="false"
    viewBox="0 0 640 512"
    {...rest}
  >
    <path
      fill={fillColor}
      d="M480.07 96H160a160 160 0 1 0 114.24 272h91.52A160 160 0 1 0 480.07 96zM248 268a12 12 0 0 1-12 12h-52v52a12 12 0 0 1-12 12h-24a12 12 0 0 1-12-12v-52H84a12 12 0 0 1-12-12v-24a12 12 0 0 1 12-12h52v-52a12 12 0 0 1 12-12h24a12 12 0 0 1 12 12v52h52a12 12 0 0 1 12 12zm216 76a40 40 0 1 1 40-40 40 40 0 0 1-40 40zm64-96a40 40 0 1 1 40-40 40 40 0 0 1-40 40z"
    />
  </svg>
);

const STAT_SUMMARY_SVG_D = {
  player:
    'M12 11.4q-1.7 0-2.875-1.188Q7.95 9.025 7.95 7.35q0-1.7 1.175-2.875T12 3.3q1.7 0 2.875 1.175T16.05 7.35q0 1.675-1.175 2.862Q13.7 11.4 12 11.4Zm-8.25 9.425v-3q0-.9.45-1.637.45-.738 1.225-1.138 1.575-.8 3.225-1.2 1.65-.4 3.35-.4 1.675 0 3.338.4 1.662.4 3.237 1.2.775.4 1.225 1.138.45.737.45 1.637v3Zm2.15-2.15h12.2v-.85q0-.3-.125-.5t-.35-.325q-1.325-.625-2.775-1.012Q13.4 15.6 12 15.6q-1.4 0-2.85.375Q7.7 16.35 6.375 17q-.225.1-.35.312-.125.213-.125.513ZM12 9.25q.8 0 1.35-.55t.55-1.35q0-.8-.55-1.35T12 5.45q-.8 0-1.35.55t-.55 1.35q0 .8.55 1.35t1.35.55Zm0-1.9Zm0 11.325Z',
  team:
    'M.1 18.2v-1.625q0-1.2 1.163-1.938Q2.425 13.9 4.25 13.9h.35q-.325.55-.512 1.162-.188.613-.188 1.313V18.2Zm6 0v-1.775q0-1.625 1.638-2.6Q9.375 12.85 12 12.85q2.65 0 4.275.975 1.625.975 1.625 2.6V18.2Zm14 0v-1.825q0-.7-.175-1.313-.175-.612-.525-1.162h.35q1.875 0 3.013.737 1.137.738 1.137 1.938V18.2ZM12 15q-1.075 0-1.988.25-.912.25-1.337.675v.125h6.675v-.125q-.425-.425-1.325-.675Q13.125 15 12 15Zm-7.75-1.975q-.8 0-1.375-.575t-.575-1.4q0-.8.575-1.375T4.25 9.1q.825 0 1.4.562.575.563.575 1.388 0 .8-.575 1.388-.575.587-1.4.587Zm15.5 0q-.8 0-1.375-.575t-.575-1.4q0-.8.575-1.375T19.75 9.1q.825 0 1.388.562.562.563.562 1.388 0 .8-.562 1.388-.563.587-1.388.587Zm-7.75-.9q-1.25 0-2.125-.875T9 9.125Q9 7.875 9.875 7T12 6.125q1.25 0 2.125.875T15 9.125q0 1.25-.875 2.125T12 12.125Zm0-3.85q-.325 0-.587.25-.263.25-.263.6 0 .325.263.587.262.263.612.263t.588-.263q.237-.262.237-.612 0-.325-.25-.575-.25-.25-.6-.25Zm0 7.775Zm0-6.925Z',
  singleGame:
    'M4.125 11.3H7.3q-.125-1.075-.6-2.05-.475-.975-1.225-1.7-.55.8-.9 1.737-.35.938-.45 2.013Zm12.575 0h3.175q-.1-1.075-.45-2t-.9-1.75q-.775.775-1.237 1.725-.463.95-.588 2.025ZM5.475 16.45q.75-.775 1.225-1.738.475-.962.6-2.012H4.125q.125 1.05.463 1.988.337.937.887 1.762Zm13.05 0q.55-.825.9-1.762.35-.938.45-1.988H16.7q.125 1.05.588 2.012.462.963 1.237 1.738Zm-9.8-5.15H11.3V4.125q-1.45.15-2.712.75-1.263.6-2.213 1.6.975.975 1.588 2.187.612 1.213.762 2.638Zm3.975 0h2.575q.15-1.425.75-2.65.6-1.225 1.6-2.175-.95-1-2.212-1.6-1.263-.6-2.713-.75Zm-1.4 8.575V12.7H8.725q-.15 1.425-.75 2.65-.6 1.225-1.6 2.15.95 1 2.213 1.613 1.262.612 2.712.762Zm1.4 0q1.45-.15 2.713-.75 1.262-.6 2.212-1.6-1-.95-1.6-2.175-.6-1.225-.75-2.65H12.7ZM12 12Zm0 9.3q-1.925 0-3.625-.738-1.7-.737-2.95-1.987-1.25-1.25-1.987-2.95Q2.7 13.925 2.7 12t.738-3.625q.737-1.7 1.987-2.95 1.25-1.25 2.95-1.988Q10.075 2.7 12 2.7t3.625.737q1.7.738 2.95 1.988 1.25 1.25 1.987 2.95.738 1.7.738 3.625t-.738 3.625q-.737 1.7-1.987 2.95-1.25 1.25-2.95 1.987-1.7.738-3.625.738Z',
};

export const StatsSummaryIcon: React.SFC<ISVG> = ({
  icon = 'player',
  fillColor = 'currentcolor',
  className,
  purpose,
  ...rest
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      width="24"
      className={className}
      aria-hidden={purpose === 'decorative'}
      role="img"
      focusable="false"
      {...rest}
    >
      <path fill={fillColor} d={STAT_SUMMARY_SVG_D[icon]} />
    </svg>
  );
};
