import {superstyled, SuperStyled, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';

// Same as Box, but is a flex Nav container
// This is to make scanning through components easier
// and mostly a convenience. 'Box' can configure the self-flex properties
// and Nav can also configure the Container properties.
export type NavStyleProps = SuperStyled<
  | 'fontSize'
  | 'color'
  | 'bgColor'
  | 'display'
  | 'align'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'measure'
  | 'shadow'
  | 'position'
  | 'border'
  | 'borderRadius'
  | 'borderWidth'
  | 'borderColor'
  | 'ma'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'flex'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexOrder'
  | 'alignSelf'
  | 'flexDirection'
  | 'flexWrap'
  | 'alignItems'
  | 'alignContent'
  | 'justifyContent'
>;

// By default, the Nav is a display: flex (the direction is default by the browser)
const defaultNavProps: NavStyleProps = {
  display: 'flex',
};

export const Nav = superstyled<NavStyleProps, StyledHTML<'nav'>>('nav', defaultNavProps);
Nav.displayName = 'Nav';
