import {fromJS, Map} from 'immutable';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {createActions, createReducer} from 'fiba/wt/utils/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import TeamTourStatsResponse, {
  TeamTourStatsResponseLike,
} from 'fiba/common/core/models/api/stats/TeamTourStatsResponse';
import {rethrow, setMeta} from 'fiba/common/stores/storeUtils';
import ResultsTeam from 'fiba/common/core/models/api/results/ResultsTeam';
import {mapValues} from 'lodash';
import {
  SortableStore,
  SortableStoreItem,
  sortingReducers,
} from 'fiba/wt/stores/reducers/sortableStore';
import {TeamStats} from 'contrib/types/interfaces/api/stats/TeamStats';

export const statePath = (tourOrEventId: string) => [tourOrEventId];
const metaPath = (tourOrEventId: string) => ['__meta', ...statePath(tourOrEventId)];
export const storePath = 'proCircuitTeamStats';
export const globalStatePath = (tourOrEventId: string) => [storePath, ...statePath(tourOrEventId)];
export const globalMetaPath = (tourOrEventId: string) => [storePath, ...metaPath(tourOrEventId)];

export interface TeamStatRow extends TeamStats {
  teamName: string;
  teamNameSuffix: string;
  isTourTeam: boolean;
}

export interface ProCircuitTeamStatsStore extends SortableStore<TeamStatRow> {}

const mapResponse = (response: TeamTourStatsResponse): SortableStoreItem<TeamStatRow> => {
  const teamsById: Map<string, ResultsTeam> = response.teams.reduce(
    (map, value) => map.set(value.teamId, value),
    Map(),
  );

  const value: SortableStoreItem<TeamStatRow> = {
    sortedBy: {
      fieldName: null,
      sortOrder: 'none',
    },
    updatedAt: response.updatedAt,
    data: response.teamStatistics
      .map(stat =>
        // TODO: Use merge here
        fromJS({
          teamName: teamsById.get(stat.teamId).teamName,
          teamNameSuffix: teamsById.get(stat.teamId).teamNameSuffix,
          isTourTeam: teamsById.get(stat.teamId).isTourTeam,
          ...stat.toJS(),
        }),
      )
      .toList(),
  };

  return fromJS(value);
};

const reducers = {
  ...sortingReducers,

  loadProCircuitTourTeamStats: (
    state: ProCircuitTeamStatsStore,
    tourOrEventId: string,
  ): ProCircuitTeamStatsStore => {
    return state.updateIn(metaPath(tourOrEventId), setMeta.isLoading);
  },

  loadProCircuitTeamStatsSuccess: (
    state: ProCircuitTeamStatsStore,
    tourOrEventId: string,
    response: TeamTourStatsResponseLike,
  ): ProCircuitTeamStatsStore => {
    const value = mapResponse(TeamTourStatsResponse.fromJS(response));
    return state
      .setIn(statePath(tourOrEventId), value)
      .updateIn(metaPath(tourOrEventId), setMeta.isLoaded);
  },

  loadProCircuitStatsError: (
    state: ProCircuitTeamStatsStore,
    tourOrEventId: string,
    error: Error,
  ) => {
    return state.updateIn(metaPath(tourOrEventId), setMeta.isError);
  },
};

const externalReducers = {};

export const reducer = createReducer<ProCircuitTeamStatsStore>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

export const actions = createActions(__filename, reducers, {
  loadProCircuitTourTeamStats: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadProCircuitTeamTourStats(tourId)
      .then(response => actions.loadProCircuitTeamStatsSuccess(tourId, response.data))
      .catch(rethrow((err: Error) => actions.loadProCircuitStatsError(tourId, err))),

  loadProCircuitStatsError: (tourOrEventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

// Arbitrary JSON -> State
// State as JSON -> State (which is an Immutable map)
export const deserializeState = ({__meta, ...items}) => {
  // Make sure known things exist
  return fromJS({
    __meta: __meta || {},
    ...mapValues(items, item => fromJS(item)),
  });
};
