import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import EventStatsSummary from 'fiba/common/core/models/api/stats/EventStatsSummary';
import {
  curriedEventTeamPlayerLinkProvider,
  eventTeamLinkProvider,
  EventType,
  gameLinkProvider,
} from 'fiba/wt/utils/linkUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {ServicesContext} from 'fiba/common/services/services';
import {arePlayerStats, areTeamStats, areNotables} from 'fiba/wt/ui/statistics/statsSummary/utils';
import EmptyStats from 'fiba/wt/ui/statistics/statsSummary/EmptyStats';
import PlayerStats from 'fiba/wt/ui/statistics/statsSummary/PlayerStats';
import {NotablePerformanceStats} from 'fiba/wt/ui/statistics/statsSummary/NotablePerformanceStats';
import TeamStats from 'fiba/wt/ui/statistics/statsSummary/TeamStats';
import {CategoryHeader} from 'fiba/wt/ui/statistics/statsSummary/CategoryHeader';
import StatsSummaryFooter from 'fiba/wt/ui/statistics/statsSummary/StatsSummaryFooter';

interface OwnProps {
  season: string;
  eventId: string;
  eventType: EventType;
}
interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
  eventStatsSummary: EventStatsSummary;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  const eventStatsSummary: EventStatsSummary = state.getIn(['stats', 'eventStatsSummary', eventId]);
  return {
    eventStatsSummary,
    metaPaths: [`stats/__meta/eventStatsSummary/${eventId}`],
    eventTypeData: getEventType(state.get('events'), eventId),
  };
};

export const _EventStatisticsSummary: React.SFC<Props> = props => {
  const {season, eventId, eventStatsSummary, eventType, metaPaths} = props;
  const {seasonConfig} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);

  const curriedEventGameLinkProvider = (gameId: string) =>
    gameLinkProvider(seasonConfig, season, eventId, eventType)(gameId);

  const curriedEventTeamLinkProvider = (teamInEventId: string) =>
    eventTeamLinkProvider(seasonConfig, season, eventId, eventType)(teamInEventId);

  return (
    <Loading
      metaPaths={metaPaths}
      onError={() => <BlockText>There was a problem loading the event stats</BlockText>}
    >
      {() => (
        <>
          <div className="stats-container">
            <CategoryHeader icon={'player'} />
            {!arePlayerStats(eventStatsSummary) ? (
              <EmptyStats />
            ) : (
              <div
                className="player-stats-list"
                style={{'--player-list-size': 5} as React.CSSProperties}
              >
                <PlayerStats
                  title={'Top player value'}
                  stats={eventStatsSummary.topPlayerPlayerValue}
                  createPlayerHref={curriedEventTeamPlayerLinkProvider(
                    seasonConfig,
                    season,
                    eventId,
                    eventType,
                  )}
                  createTeamHref={curriedEventTeamLinkProvider}
                  numberOfDecimals={1}
                />
                <PlayerStats
                  title={'Top scorer'}
                  stats={eventStatsSummary.topPlayerScorer}
                  createPlayerHref={curriedEventTeamPlayerLinkProvider(
                    seasonConfig,
                    season,
                    eventId,
                    eventType,
                  )}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
                <PlayerStats
                  title={'Most highlights'}
                  stats={eventStatsSummary.topPlayerHighlights}
                  createPlayerHref={curriedEventTeamPlayerLinkProvider(
                    seasonConfig,
                    season,
                    eventId,
                    eventType,
                  )}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
                <PlayerStats
                  title={'Most key assists'}
                  stats={eventStatsSummary.topPlayerKeyAssists}
                  createPlayerHref={curriedEventTeamPlayerLinkProvider(
                    seasonConfig,
                    season,
                    eventId,
                    eventType,
                  )}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
                <PlayerStats
                  title={'Most rebounds'}
                  stats={eventStatsSummary.topPlayerRebounds}
                  createPlayerHref={curriedEventTeamPlayerLinkProvider(
                    seasonConfig,
                    season,
                    eventId,
                    eventType,
                  )}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
              </div>
            )}
            <hr className="hr" />
            <CategoryHeader icon={'team'} />
            {!areTeamStats(eventStatsSummary) ? (
              <EmptyStats />
            ) : (
              <div
                className="team-stats-list"
                style={{'--team-list-size': 4} as React.CSSProperties}
              >
                <TeamStats
                  title={'Most points scored'}
                  stats={eventStatsSummary.topTeamPointsScored}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
                <TeamStats
                  title={'Best shooting value'}
                  stats={eventStatsSummary.topTeamShootingValue}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
                <TeamStats
                  title={'Most highlights'}
                  stats={eventStatsSummary.topTeamHighlights}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
                <TeamStats
                  title={'Fewest points allowed'}
                  stats={eventStatsSummary.topTeamFewestPointsAllowed}
                  createTeamHref={curriedEventTeamLinkProvider}
                />
              </div>
            )}
          </div>
          {areNotables(eventStatsSummary) && (
            <>
              <hr className="hr" />
              <CategoryHeader icon={'singleGame'} />
              <div className="stats-container">
                <div
                  className="notable-stats-list"
                  style={{'--notable-list-size': 2} as React.CSSProperties}
                >
                  <NotablePerformanceStats
                    title={'Top player value'}
                    stats={eventStatsSummary.playerValueInSingleGame}
                    createPlayerHref={curriedEventTeamPlayerLinkProvider(
                      seasonConfig,
                      season,
                      eventId,
                      eventType,
                    )}
                    createTeamHref={curriedEventTeamLinkProvider}
                    createGameHref={curriedEventGameLinkProvider}
                    localizer={localizer}
                  />
                  <NotablePerformanceStats
                    title={'Top double-fives'}
                    stats={eventStatsSummary.doubleFives}
                    showValue={false}
                    createPlayerHref={curriedEventTeamPlayerLinkProvider(
                      seasonConfig,
                      season,
                      eventId,
                      eventType,
                    )}
                    createTeamHref={curriedEventTeamLinkProvider}
                    createGameHref={curriedEventGameLinkProvider}
                    localizer={localizer}
                  />
                </div>
                <div
                  className="notable-stats-list"
                  style={{'--notable-list-size': 2} as React.CSSProperties}
                >
                  <NotablePerformanceStats
                    title={'Buzzerbeaters'}
                    stats={eventStatsSummary.buzzerBeaters}
                    showValue={false}
                    showBreakdown={false}
                    showRank={false}
                    createPlayerHref={curriedEventTeamPlayerLinkProvider(
                      seasonConfig,
                      season,
                      eventId,
                      eventType,
                    )}
                    createTeamHref={curriedEventTeamLinkProvider}
                    createGameHref={curriedEventGameLinkProvider}
                    localizer={localizer}
                  />
                  <NotablePerformanceStats
                    title={'Triple-fives'}
                    stats={eventStatsSummary.tripleFives}
                    showValue={false}
                    showRank={false}
                    createPlayerHref={curriedEventTeamPlayerLinkProvider(
                      seasonConfig,
                      season,
                      eventId,
                      eventType,
                    )}
                    createTeamHref={curriedEventTeamLinkProvider}
                    createGameHref={curriedEventGameLinkProvider}
                    localizer={localizer}
                  />
                </div>
              </div>
            </>
          )}
          <StatsSummaryFooter updatedAt={eventStatsSummary.updatedAt} localizer={localizer} />
        </>
      )}
    </Loading>
  );
};

export const EventStatisticsSummary = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventStatisticsSummary,
);
