import React, {isValidElement} from 'react';
import {Map, List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {YoutubeEmbed} from 'fiba/wt/ui/youtubeEmbed/YoutubeEmbed';
import {ContentTypes, EmbeddedMediaTypes} from 'fiba/wt/services/contentfulService';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {GhostLink} from 'fiba/wt/ui/link/Link';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventVideosLink, EventType} from 'fiba/wt/utils/linkUtils';
import {getEventType} from 'fiba/wt/stores/eventStore';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {YoutubeVideoGrid} from 'fiba/wt/ui/youtubeGridView/YoutubeGridView';

export {EmbeddedMediaTypes};

interface OwnProps {
  title?: string | React.ReactNode;
  contentPath: string;
  mediaType: EmbeddedMediaTypes;
  widthHint?: number;
  eventId?: string;
  isSummary?: boolean;
  children?: React.ReactNode;
  emptyMediaContent?: React.ReactNode;
}

interface SummaryProps extends OwnProps {
  siteConfig: ISiteConfig;
  season: string;
}

type ContentfulMedia = Map<string, any>;

// Render proppy version
interface MediaProviderOwnProps {
  contentPath: string;
  mediaType: EmbeddedMediaTypes;
  children: (media: ContentfulMedia) => React.ReactNode;
  emptyMediaContent?: React.ReactNode;
}

interface ReduxProps {
  media: ContentfulMedia;
  eventTypeData: RemoteData.WebData<EventType>;
  metaPaths: string[];
}

const mapStateToProps = (state, {contentPath, mediaType, eventId}: OwnProps): ReduxProps => {
  const contentPathArr = contentPath.split('/');

  return {
    media: state
      .getIn(['content', ContentTypes.EmbeddedMedia, ...contentPathArr], List())
      .filter(media => media.getIn(['fields', 'mediaType']) === mediaType)
      .first(),
    metaPaths: [`content/__meta/${ContentTypes.EmbeddedMedia}/${contentPathArr.join('/')}`],
    eventTypeData: getEventType(state.get('events'), eventId),
  };
};

export function renderMediaComponent(
  media: ContentfulMedia,
  widthHint?: number,
  isSummary?: boolean,
): React.ReactElement<any> {
  const mediaUrls: List<string> = media.getIn(['fields', 'mediaUrl']) || List();
  const mediaType: EmbeddedMediaTypes = media.getIn(['fields', 'mediaType']);
  // If we are using the summary view, just fetch a single mediaUrl
  const selectedMediaUrls = !!isSummary ? mediaUrls.slice(0, 1) : mediaUrls;

  // Change limit here to 'summary' and slice the video list to one.
  // Then if we expand vids, max 4 is ok for summary

  if (
    mediaType === EmbeddedMediaTypes.YoutubeMainLivestream ||
    mediaType === EmbeddedMediaTypes.YoutubeDunkPlaylist ||
    mediaType === EmbeddedMediaTypes.YoutubeShootoutPlaylist
  ) {
    const [width, height] =
      widthHint == null
        ? [`${widthHint}`, `${widthHint * 0.5625}`] // 16:9 aspect ratio
        : ['720', '405']; // TODO: Discuss the default size
    return (
      <Spacer vSpace="3">
        {selectedMediaUrls.map(url => (
          <YoutubeEmbed key={url} url={url} width={width} height={height} aspectRatio="16x9" />
        ))}
      </Spacer>
    );
  }

  // For main playlists, split them to invdividual video grids
  // Other playlists can be added here if desired.
  if (mediaType === EmbeddedMediaTypes.YoutubeMainPlaylist) {
    const [width, height] =
      widthHint == null
        ? [`${widthHint}`, `${widthHint * 0.5625}`] // 16:9 aspect ratio
        : ['720', '405']; // TODO: Discuss the default size
    return (
      <Spacer vSpace="3">
        {selectedMediaUrls.map(url => (
          <YoutubeVideoGrid
            key={url}
            url={url}
            width={width}
            height={height}
            aspectRatio="16x9"
            // If we are in summary mode, only show a max of 4 videos in the grid.
            // This Scales well with videos taking up 100%, 50% or 25% of the containers width.
            limit={!!isSummary ? 4 : undefined}
            // If not in summary mode, highlight the first video (i.e. make it big)
            highlightFirstVideo={!isSummary}
          />
        ))}
      </Spacer>
    );
  }
}

// TODO: Deprecate in favor of the render proppy version?
const _ContentfulEmbeddedMedia: React.FC<OwnProps & ReduxProps> = ({
  title,
  media,
  widthHint,
  metaPaths,
  isSummary,
  children,
  emptyMediaContent = null,
}) => (
  <div className="ContentfulEmbeddedMedia">
    <Loading metaPaths={metaPaths}>
      {() =>
        media ? (
          <React.Fragment>
            {!!title && typeof title === 'string' && (
              <Heading fontSize="3" pb="3">
                {title}
              </Heading>
            )}
            {!!title && isValidElement(title) && title}
            {renderMediaComponent(media, widthHint, isSummary)}
            {children}
          </React.Fragment>
        ) : (
          emptyMediaContent
        )
      }
    </Loading>
  </div>
);

// TODO: Deprecate in favor of the render proppy version?
const _ContentfulEmbeddedMediaSummary: React.FC<SummaryProps & ReduxProps> = ({
  siteConfig,
  season,
  eventId,
  eventTypeData,
  isSummary,
  ...rest
}) => (
  // Default 'isSummary' prop to true if not defined.
  <_ContentfulEmbeddedMedia {...rest} isSummary={isSummary || true} eventTypeData={eventTypeData}>
    {RemoteData.match(eventTypeData, {
      NotAsked: () => null,
      Loading: () => null,
      Failure: () => 'There was an error fetching the video links',
      Success: eventType => (
        <div className="pv3 flex justify-center">
          <GhostLink
            variant={siteConfig.theme.ghostlinkTheme}
            extraClassName="pv3 w5"
            href={eventVideosLink(siteConfig.seasonConfig, season, eventId, eventType)}
          >
            See all event videos
          </GhostLink>
        </div>
      ),
    })}
  </_ContentfulEmbeddedMedia>
);

// Render proppy version of the above.
// Leaves title management to the caller, but does wrap the thing with `Loading` indicator
const _ContentfulEmbeddedMediaProvider: React.FC<MediaProviderOwnProps & ReduxProps> = ({
  media,
  metaPaths,
  children,
  emptyMediaContent = null,
}) => (
  <Loading metaPaths={metaPaths}>{() => (media ? children(media) : emptyMediaContent)}</Loading>
);

export const ContentfulEmbeddedMedia = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _ContentfulEmbeddedMedia,
);

export const ContentfulEmbeddedMediaSummary = connect<ReduxProps, {}, SummaryProps>(
  mapStateToProps,
)(_ContentfulEmbeddedMediaSummary);

export const ContentfulEmbeddedMediaProvider = connect<ReduxProps, {}, MediaProviderOwnProps>(
  mapStateToProps,
)(_ContentfulEmbeddedMediaProvider);
