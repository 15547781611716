import TeamGameStats from 'fiba/common/core/models/api/stats/TeamGameStats';
import React from 'react';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {asTimeStamp, hcell, cell} from 'fiba/wt/ui/gameBoxScore/gameBoxScoreUtils';

interface ITeamStat {
  type: string;
  value: number | string;
}

type TableColumns = 'TYPE' | 'VALUE';
type ColumnSpec = IColumnSpec<ITeamStat>;
type ColumnSpecRecord = () => Record<TableColumns, ColumnSpec>;

const createColumnsSpecs: ColumnSpecRecord = () => ({
  TYPE: createSpec('Action', 'text', hcell('type')),
  VALUE: createSpec('Count', 'number', cell('value')),
});

const createSpec = (displayName, dataType, renderer) => ({
  name: displayName,
  srName: displayName,
  dataType,
  renderColumn: renderer,
});

const mapGameStatToTeamStat = (teamStats: TeamGameStats): ITeamStat[] => [
  {type: 'Made shots', value: teamStats.totalMadeShots},
  {type: 'Total shots', value: teamStats.totalShots},
  {type: 'Extra free throws', value: teamStats.extraFreeThrows},
  {type: 'Challenges', value: teamStats.challenges},
  {type: 'Offensive rebounds', value: teamStats.offensiveRebounds},
  {type: 'Defensive rebounds', value: teamStats.defensiveRebounds},
  {type: 'Total rebounds', value: teamStats.totalRebounds},
  {type: 'Timeout', value: asTimeStamp(teamStats.timeoutTakenAt)},
];

interface ITeamStatTableProps {
  captionId: string;
  caption?: React.ReactNode;
  teamStats: TeamGameStats;
}

const GameStatsTeamTable: React.FunctionComponent<ITeamStatTableProps> = ({
  teamStats,
  caption,
  captionId,
}) => (
  <DataTable<TableColumns, ITeamStat>
    caption={caption}
    captionId={captionId}
    rows={mapGameStatToTeamStat(teamStats)}
    columns={['TYPE', 'VALUE']}
    columnsSpec={createColumnsSpecs()}
    headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
    headerExtraClassName="dark-20"
    rowExtraClassName="striped--light-even bb bw1 b--silver-20"
  />
);

export default GameStatsTeamTable;
