import {Map, List, fromJS} from 'immutable';
import {mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Photo, PhotoLike} from 'fiba/common/core/models/api/media/Photo';
import {PhotoListLike} from 'fiba/common/core/models/api/media/PhotoList';
import Video, {VideoLike} from 'fiba/common/core/models/api/media/Video';
import {Gallery, GalleryLike} from 'fiba/common/core/models/api/media/Gallery';
import {
  GalleryPreview,
  TourGalleryPreviewsLike,
  EventGalleryPreviewsLike,
  GalleryPreviewLike,
} from 'fiba/common/core/models/api/media/GalleryPreview';
import {formatKeysToCamelCase} from 'fiba/common/services/contentApiClientService';
import {
  eventGalleriesToGalleryPreviews,
  apiPhotosArrayToPhotoList,
  tourGalleriesToGalleryPreviews,
} from 'fiba/common/utils/contentApiUtils';
import {AxiosResponse} from 'axios';

export interface MediaStoreState extends Map<string, any> {
  // TODO: `__meta`
  /*
    videos:
      playlists: {$playlistId: Video[]},
    photos:
      tours: {$tourId: Photo[]},
      events: {$eventId: Photo[]},
      teams: {$teamId: Photo[]},
      players: {$playerId: Photo[]},
      games: {$gameId: Photo[]},
      galleries: {$galleryId: Photo[]},
    galleries:
      events: {$eventId: Gallery[]}
      // FIXME: This should be single Gallery, but for the sake of simplifiying deserializer it's the same format as events
      galleries: {$galleryId: Gallery[]}
    gallerypreviews:
      events: {$eventId: GalleryPreview[]}
  */
}

// Temp type to keep `deserializeState` typed for now (inb4 immer PR)
interface MediaStoreStateLike {
  videos: {
    playlists: {[key: string]: VideoLike[]};
  };
  photos: {
    tours: {[key: string]: PhotoLike[]};
    conferences: {[key: string]: PhotoLike[]};
    events: {[key: string]: PhotoLike[]};
    teams: {[key: string]: PhotoLike[]};
    players: {[key: string]: PhotoLike[]};
    games: {[key: string]: PhotoLike[]};
    galleries: {[key: string]: PhotoLike[]};
  };
  galleries: {
    events: {[key: string]: GalleryLike[]};
    conferences: {[key: string]: GalleryLike[]};
    galleries: {[key: string]: GalleryLike[]};
  };
  gallerypreviews: {
    tours: {[key: string]: GalleryPreviewLike[]};
    events: {[key: string]: GalleryPreviewLike[]};
    conferences: {[key: string]: GalleryPreviewLike[]};
  };
  tweets: string[];
  __meta: any;
}

const reducers = {
  //
  // Tour gallery previews
  loadGalleryPreviewsByTour: (state: MediaStoreState, tourId: string): MediaStoreState =>
    state.updateIn(['__meta', 'gallerypreviews', 'tours', tourId], setMeta.isLoading),

  loadGalleryPreviewsByTourSuccess: (
    state: MediaStoreState,
    tourId: string,
    galleryPreviewList: TourGalleryPreviewsLike,
  ): MediaStoreState =>
    state
      .updateIn(['__meta', 'gallerypreviews', 'tours', tourId], setMeta.isLoaded)
      .setIn(
        ['gallerypreviews', 'tours', tourId],
        List(galleryPreviewList.galleryPreviews.map(preview => GalleryPreview.fromJS(preview))),
      ),

  loadGalleryPreviewsByTourError: (
    state: MediaStoreState,
    tourId: string,
    error: Error,
  ): MediaStoreState =>
    state.updateIn(['__meta', 'gallerypreviews', 'tours', tourId], setMeta.isError),

  //
  // Event gallery previews
  loadGalleryPreviewsByEvent: (state: MediaStoreState, eventId: string): MediaStoreState =>
    state.updateIn(['__meta', 'gallerypreviews', 'events', eventId], setMeta.isLoading),

  loadGalleryPreviewsByConference: (
    state: MediaStoreState,
    conferenceId: string,
  ): MediaStoreState =>
    state.updateIn(['__meta', 'gallerypreviews', 'conferences', conferenceId], setMeta.isLoading),

  loadGalleryPreviewsByEventSuccess: (
    state: MediaStoreState,
    eventId: string,
    galleryPreviewList: EventGalleryPreviewsLike,
  ): MediaStoreState =>
    state
      .updateIn(['__meta', 'gallerypreviews', 'events', eventId], setMeta.isLoaded)
      .setIn(
        ['gallerypreviews', 'events', eventId],
        List(
          galleryPreviewList.galleryPreviews.map(preview =>
            GalleryPreview.fromJS(uglyHackToFixGalleryTitles(preview)),
          ),
        ),
      ),

  loadGalleryPreviewsByConferenceSuccess: (
    state: MediaStoreState,
    conferenceId: string,
    galleryPreviewList: EventGalleryPreviewsLike,
  ): MediaStoreState =>
    state
      .updateIn(['__meta', 'gallerypreviews', 'conferences', conferenceId], setMeta.isLoaded)
      .setIn(
        ['gallerypreviews', 'conferences', conferenceId],
        List(
          galleryPreviewList.galleryPreviews.map(preview =>
            GalleryPreview.fromJS(uglyHackToFixGalleryTitles(preview)),
          ),
        ),
      ),

  loadGalleryPreviewsByEventError: (
    state: MediaStoreState,
    eventId: string,
    error: Error,
  ): MediaStoreState => {
    // HACK
    // The Media API can return 404 for not found lists. In that case, return
    // an empty list instead of isError. This is annoying, and part of the fix
    // is making the Loading indicator not assume "all or nothing" for loading states.
    // We also don't have information about the error being a 404...
    return state
      .updateIn(['__meta', 'gallerypreviews', 'events', eventId], setMeta.isLoaded)
      .setIn(['gallerypreviews', 'events', eventId], List());
  },

  loadGalleryPreviewsByConferenceError: (
    state: MediaStoreState,
    conferenceId: string,
    error: Error,
  ): MediaStoreState => {
    // HACK
    // The Media API can return 404 for not found lists. In that case, return
    // an empty list instead of isError. This is annoying, and part of the fix
    // is making the Loading indicator not assume "all or nothing" for loading states.
    // We also don't have information about the error being a 404...
    return state
      .updateIn(['__meta', 'gallerypreviews', 'conferences', conferenceId], setMeta.isLoaded)
      .setIn(['gallerypreviews', 'conferences', conferenceId], List());
  },

  //
  // Event videos playlist
  // At the moment, the input for this action is fecthed via Contentful
  //
  // Note that when using "PlaylistURL" as an ID in the store we must remove (or encode) the various / characters
  // that can appear in the URL. This is because various components that interact with state (e.g. Loading) split
  // state paths by / characters. This obviously leads to problems when the state keys contain / characters.
  // It is easy enough to just escape these problematic characters using the JS encodeURIcomponent.
  loadVideosByYouTubePlaylistUrl: (state: MediaStoreState, playlistUrl: string): MediaStoreState =>
    state.updateIn(
      ['__meta', 'videos', 'playlists', encodeURIComponent(playlistUrl)],
      setMeta.isLoading,
    ),

  loadVideosByYouTubePlaylistUrlSuccess: (
    state: MediaStoreState,
    playlistUrl: string,
    videoList: VideoLike[],
  ): MediaStoreState =>
    state
      .updateIn(
        ['__meta', 'videos', 'playlists', encodeURIComponent(playlistUrl)],
        setMeta.isLoaded,
      )
      .setIn(
        ['videos', 'playlists', encodeURIComponent(playlistUrl)],
        List(videoList.map(video => Video.fromJS(formatKeysToCamelCase(video)))),
      ),

  loadVideosByYouTubePlaylistUrlError: (
    state: MediaStoreState,
    playlistUrl: string,
    error: Error,
  ): MediaStoreState =>
    state.updateIn(
      ['__meta', 'videos', 'playlists', encodeURIComponent(playlistUrl)],
      setMeta.isError,
    ),

  loadVideosByEventId: (state: MediaStoreState, playlistUrl: string): MediaStoreState =>
    state.updateIn(
      ['__meta', 'videos', 'playlists', encodeURIComponent(playlistUrl)],
      setMeta.isLoading,
    ),

  loadVideosByEventIdSuccess: (
    state: MediaStoreState,
    eventId: string,
    videoList: VideoLike[],
  ): MediaStoreState =>
    state
      .updateIn(['__meta', 'videos', 'playlists', eventId], setMeta.isLoaded)
      .setIn(['videos', 'playlists', eventId], List(videoList.map(video => Video.fromJS(video)))),

  loadVideosByEventIdError: (
    state: MediaStoreState,
    eventId: string,
    error: Error,
  ): MediaStoreState => state.updateIn(['__meta', 'videos', 'playlists', eventId], setMeta.isError),

  //
  // Team photos
  loadPhotosByTeam: (state: MediaStoreState, teamId: string): MediaStoreState =>
    state.updateIn(['__meta', 'photos', 'teams', teamId], setMeta.isLoading),

  loadPhotosByTeamSuccess: (
    state: MediaStoreState,
    teamId: string,
    photoList: PhotoListLike,
  ): MediaStoreState =>
    state
      .updateIn(['__meta', 'photos', 'teams', teamId], setMeta.isLoaded)
      .setIn(['photos', 'teams', teamId], List(photoList.photos.map(photo => Photo.fromJS(photo)))),

  loadPhotosByTeamError: (state: MediaStoreState, teamId: string, error: Error): MediaStoreState =>
    state.updateIn(['__meta', 'photos', 'teams', teamId], setMeta.isError),

  //
  // Gallery photos
  loadPhotosByGallery: (state: MediaStoreState, galleryId: string): MediaStoreState =>
    state.updateIn(['__meta', 'photos', 'galleries', galleryId], setMeta.isLoading),

  loadPhotosByGallerySuccess: (
    state: MediaStoreState,
    galleryId: string,
    response: AxiosResponse<any, any>,
  ): MediaStoreState => {
    const photoList = apiPhotosArrayToPhotoList(response['GalleryImages']);

    return state
      .updateIn(['__meta', 'photos', 'galleries', galleryId], setMeta.isLoaded)
      .setIn(['__meta', 'photos', 'galleries', galleryId, 'title'], response['GalleryName'])
      .setIn(
        ['photos', 'galleries', galleryId],
        List(photoList.photos.map(photo => Photo.fromJS(photo))),
      );
  },

  loadPhotosByGalleryError: (
    state: MediaStoreState,
    galleryId: string,
    error: Error,
  ): MediaStoreState =>
    state.updateIn(['__meta', 'photos', 'galleries', galleryId], setMeta.isError),

  loadTweets: (state: MediaStoreState): MediaStoreState =>
    state.updateIn(['__meta', 'tweets'], setMeta.isLoading),

  loadTweetsSuccess: (state: MediaStoreState, tweets: any): MediaStoreState => {
    const mappedTweets: string[] = tweets?.map(tweet => tweet.id);
    return state
      .setIn(['tweets'], List(mappedTweets))
      .updateIn(['__meta', 'tweets'], setMeta.isLoaded);
  },

  loadTweetsError: (state: MediaStoreState, error: Error): MediaStoreState =>
    state.updateIn(['__meta', 'tweets'], setMeta.isError),
};

function uglyHackToFixGalleryTitles(gallery: GalleryLike | GalleryPreview) {
  // This is an ugly way to override some gallery names because apparently you CANNOT do that in BBM :(

  if ([gallery['id'], gallery['galleryId']].includes('04542f30-f432-4404-b44d-0ff07706b6a6')) {
    return {...gallery, title: 'Jeddah Final'};
  }

  if ([gallery['id'], gallery['galleryId']].includes('55686696-f8b5-4703-bdbf-66fd4fa670e1')) {
    return {...gallery, title: 'WT Paris 2022 (FRA)'};
  }

  return gallery;
}

export const reducer = createReducer<MediaStoreState>(__filename, fromJS({}), reducers);

export const actions = createActions(__filename, reducers, {
  loadGalleryPreviewsByEvent: (eventId: string) => ({contentApiClient}: CommonServices) => {
    return contentApiClient
      .loadGalleryPreviewsByEvent(eventId)
      .then(res => {
        return actions.loadGalleryPreviewsByEventSuccess(
          eventId,
          eventGalleriesToGalleryPreviews(res.data, eventId),
        );
      })
      .catch((err: Error) => actions.loadGalleryPreviewsByEventError(eventId, err));
  },

  loadGalleryPreviewsByConference: (conferenceId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadGalleryPreviewsByTour(conferenceId)
      .then(res =>
        actions.loadGalleryPreviewsByConferenceSuccess(
          conferenceId,
          eventGalleriesToGalleryPreviews(res.data, conferenceId),
        ),
      )
      .catch((err: Error) => actions.loadGalleryPreviewsByConferenceError(conferenceId, err)),

  // For NL, first fetch events for the given tour,
  // then the galleries for each event.
  loadGalleryPreviewsByTour: (tourId: string) => ({
    apiClient,
    contentApiClient,
  }: CommonServices) => {
    const loadGalleryPreviewsByEventIds = (eventIds: string[]) => {
      return contentApiClient
        .loadGalleryPreviewsByEventIds(eventIds)
        .then(itemsInResponse => {
          return actions.loadGalleryPreviewsByTourSuccess(
            tourId,
            tourGalleriesToGalleryPreviews(itemsInResponse),
          );
        })
        .catch(rethrow((err: Error) => actions.loadGalleryPreviewsByTourError(tourId, err)));
    };

    const isNationsLeague = tourId.slice(0, 2) === 'NL';
    if (isNationsLeague) {
      const season = tourId.slice(2);
      return apiClient.loadNationalTeamSeasonEvents('nationsleague', season).then(res => {
        const events = res.data.data.map(conference => conference.events);
        const eventIds = events.flat().map(event => event.id);
        return loadGalleryPreviewsByEventIds(eventIds);
      });
    } else {
      const galleriesWithEvents = [];
      return contentApiClient
        .loadGalleryPreviewsByTour(tourId)
        .then(res => {
          const categoryIds = Object.keys(res.data).filter(
            categoryId => res.data[categoryId].length,
          );
          // Get event ID for each gallery returned. Ideally, event ID should be returned
          // in the content API response.
          return Promise.all(
            categoryIds.map(categoryId => {
              return apiClient.loadCategory(categoryId).then(response => {
                res.data[categoryId].forEach(gallery => {
                  galleriesWithEvents.push({
                    ...gallery,
                    eventId: response.data.eventId,
                  });
                });
              });
            }),
          ).then(() => {
            return actions.loadGalleryPreviewsByTourSuccess(
              tourId,
              tourGalleriesToGalleryPreviews(galleriesWithEvents),
            );
          });
        })
        .catch(rethrow((err: Error) => actions.loadGalleryPreviewsByTourError(tourId, err)));
    }
  },

  loadPhotosByTeam: (teamId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadPhotosByTeam(teamId)
      .then(res => {
        // TODO - use res.data once data in fetched, currently only empty array
        return actions.loadPhotosByTeamSuccess(teamId, {photos: []});
      })
      .catch(rethrow((err: Error) => actions.loadPhotosByTeamError(teamId, err))),

  loadPhotosByGallery: (galleryId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadPhotosByGallery(galleryId)
      .then(res => actions.loadPhotosByGallerySuccess(galleryId, res.data))
      .catch(rethrow((err: Error) => actions.loadPhotosByGalleryError(galleryId, err))),

  loadTweets: (hashtags: string[]) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTweets(hashtags)
      .then(res => actions.loadTweetsSuccess(res.data.items))
      .catch(rethrow((err: Error) => actions.loadTweetsError(err))),

  // Content API
  loadVideosByYouTubePlaylistUrl: (playlistUrl: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadVideosByYouTubePlaylistUrl(playlistUrl)
      .then(res => actions.loadVideosByYouTubePlaylistUrlSuccess(playlistUrl, res.data))
      .catch(
        rethrow((err: Error) => actions.loadVideosByYouTubePlaylistUrlError(playlistUrl, err)),
      ),

  loadVideosByEventId: (eventId: string) => ({contentApiClient}: CommonServices) =>
    contentApiClient
      .loadVideosByEventId(eventId)
      .then(res => actions.loadVideosByEventIdSuccess(eventId, res.data))
      .catch(rethrow((err: Error) => actions.loadVideosByEventIdError(eventId, err))),
});

export default actions;

export function deserializeState(state: MediaStoreStateLike): MediaStoreState {
  const {__meta, photos, galleries, gallerypreviews, tweets, videos} = state;
  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail on chrome
    __meta: __meta || {},
    photos:
      mapValues(photos, subphotos => mapValues(subphotos, items => items.map(Photo.fromJS))) || {},
    galleries:
      mapValues(galleries, subgalleries =>
        mapValues(subgalleries, items => items.map(Gallery.fromJS)),
      ) || {},

    gallerypreviews: !!gallerypreviews
      ? {
          tours: !!gallerypreviews.tours
            ? mapValues(gallerypreviews.tours, previews => previews.map(GalleryPreview.fromJS))
            : {},
          events: !!gallerypreviews.events
            ? mapValues(gallerypreviews.events, previews => previews.map(GalleryPreview.fromJS))
            : {},
          conferences: !!gallerypreviews.conferences
            ? mapValues(gallerypreviews.conferences, previews =>
                previews.map(GalleryPreview.fromJS),
              )
            : {},
        }
      : {},
    tweets,
    videos: !!videos
      ? {
          playlists: !!videos.playlists
            ? mapValues(videos.playlists, playlist => playlist.map(Video.fromJS))
            : {},
        }
      : {},
  });
}
