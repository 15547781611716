import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {getTimezone} from 'fiba/common/core/liveScoreUtils';
import uiConfigActions from 'fiba/common/stores/uiConfigStore';
import {PillSelect} from 'fiba/wt/ui/pillSelect/PillSelect';

interface OwnProps {
  games: List<ResultsGameSummary>;
  hideGroupStyle?: boolean;
  hideLegend?: boolean;
}

interface ReduxProps {
  isScheduleEventTime: boolean;
  eventTimezone: string;
  localTimezone: string;
}

interface DispatchProps {
  setIsScheduleEventTime: (isScheduleEventTime: boolean) => any;
}

type Props = OwnProps & ReduxProps & DispatchProps;

const mapStateToProps = (state, {games}: OwnProps): ReduxProps => {
  const isScheduleEventTime = state.getIn(['uiConfig', 'isScheduleEventTime']);
  const firstGame = games.find(game => !!game);
  const eventTimezone = firstGame && getTimezone(firstGame, 'event');
  const localTimezone = firstGame && getTimezone(firstGame, 'local');

  return {
    isScheduleEventTime,
    eventTimezone,
    localTimezone,
  };
};

const mapStateToDispatch = (dispatch): DispatchProps => ({
  setIsScheduleEventTime: isScheduleEventTime =>
    dispatch(uiConfigActions.setUiConfigValue('isScheduleEventTime', isScheduleEventTime)),
});

const _ScheduleTimezoneSwitcher: React.FunctionComponent<Props> = ({
  hideLegend,
  hideGroupStyle,
  isScheduleEventTime,
  eventTimezone,
  localTimezone,
  setIsScheduleEventTime,
}) => (
  <div>
    {/* TODO: localize */}
    <PillSelect
      controlLabel="Timezone:"
      hideGroupStyle={hideGroupStyle}
      hideLegend={hideLegend}
      inputNameId="schedule-tz-picker"
      options={[
        {label: `Event (${eventTimezone})`, value: 'event'},
        {label: `My time (${localTimezone})`, value: 'local'},
      ]}
      initial={isScheduleEventTime ? 'event' : 'local'}
      onChange={selected => setIsScheduleEventTime(selected === 'event')}
    />
  </div>
);

export const ScheduleTimezoneSwitcher = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapStateToDispatch,
)(_ScheduleTimezoneSwitcher);
