import React, {useContext} from 'react';
import {FastestGame} from 'fiba/common/core/models/api/fastestGame/FastestGame';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {StyledLink, Link} from 'fiba/wt/ui/link/Link';
import {eventTeamLinkProvider, gameLinkProvider} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {ArrowRightCircle} from '@fpapado/react-feather';
import {ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {formatGameName} from 'fiba/wt/utils/games/games';

//
// Column Config
// TODO: Consider renaming 'Game' to 'Teams'
type Columns = 'Number' | 'Game' | 'Event' | 'Round' | 'Score' | 'PlayTime' | 'Link';
type ColumnsSpec = IColumnSpec<FastestGame>;

// NOTE: Fastest Games is only for Masters events, therefore eventType=masters for all links here
// NOTE 2: This might be a bad assumption

const columnConfig = (
  seasonConfig: ISeasonConfig,
  season: string,
): Record<Columns, ColumnsSpec> => ({
  Number: {
    name: '#',
    srName: 'Number',
    dataType: 'number',
    renderColumn: ({rowIndex, Td, getTdProps}) => <Td {...getTdProps()}>{`${rowIndex + 1}.`}</Td>,
  },
  // TODO: This will probably have to be more complex, i.e. {homeTeamName, awayTeamName} => string
  // And thus we will have to change the RowData from [], to Record<[K in Columns], RowData[K]>
  Game: {
    name: 'Game',
    dataType: 'text',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps()}>
        <StyledLink
          href={eventTeamLinkProvider(
            seasonConfig,
            season,
            rowData.eventId,
            'WorldTour',
          )(rowData.homeTeam.teamInEventId)}
        >
          <TeamNameAssembly standing={rowData.homeTeam} isSuffixVisible={true} />
        </StyledLink>
        <span> vs. </span>
        <StyledLink
          href={eventTeamLinkProvider(
            seasonConfig,
            season,
            rowData.eventId,
            'WorldTour',
          )(rowData.awayTeam.teamInEventId)}
        >
          <TeamNameAssembly standing={rowData.awayTeam} isSuffixVisible={true} />
        </StyledLink>
      </Td>
    ),
  },
  Event: {
    name: 'Event',
    dataType: 'text',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps()}>{rowData.eventShortname || rowData.eventName}</Td>
    ),
  },
  Round: {
    name: 'Round',
    dataType: 'text',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps()}>{`${formatGameName(rowData)}`}</Td>
    ),
  },
  Score: {
    name: 'Score',
    dataType: 'number',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps()}>{`${rowData.homeTeam.teamScore} - ${rowData.awayTeam.teamScore}`}</Td>
    ),
  },
  PlayTime: {
    name: 'Playing time',
    dataType: 'number',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps()}>{secondsToMinutesSeconds(rowData.gameLength)}</Td>
    ),
  },
  Link: {
    name: 'Link',
    dataType: 'text',
    // FIXME: The styling for this is broken (shows as gray bg). Fix it if we need a hidden label, for example.
    // renderHeader: ({Th, getThProps}) => (
    //   <Th {...getThProps()}>
    //     <VisuallyHidden>Link</VisuallyHidden>
    //   </Th>
    // ),
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps()} extraClassName="v-mid">
        <Link
          href={gameLinkProvider(
            seasonConfig,
            season,
            rowData.eventId,
            'WorldTour',
          )(rowData.gameId)}
        >
          {/* NOTE: `mt2` is employed here as a visual tweak */}
          <ArrowRightCircle purpose="decorative" className="w2 ht2" />

          {/* Description for screen readers
           *
           * NOTE: This is just one interpretation of the accessible link name!
           *
           * You could argue that the fully unique name per page would be:
           * > Team A vs Team B in Saskatoon, Quarter-Final game 4
           * Would that be better though? That sounds like a lot for a list.
           * Can people infer it from context? Or do they rather list all links?
           */}
          <VisuallyHidden>
            <TeamNameAssembly standing={rowData.homeTeam} isSuffixVisible={true} /> vs.{' '}
            <TeamNameAssembly standing={rowData.awayTeam} isSuffixVisible={true} />
          </VisuallyHidden>
        </Link>
      </Td>
    ),
  },
});

//
// Main export

interface Props {
  season: string;
  columns: Columns[];
  rows: FastestGame[];
  caption: React.ReactNode;
  captionId: string;
}

export const FastestGamesTable: React.SFC<Props> = ({
  season,
  columns,
  rows,
  caption,
  captionId,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <DataTable<Columns, FastestGame>
      columnsSpec={columnConfig(seasonConfig, season)}
      captionId={captionId}
      caption={caption}
      columns={columns}
      rows={rows}
      // TODO: Make these the default?
      headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
      headerExtraClassName="dark-30"
      rowExtraClassName="striped--light-even bb bw1 b--silver-20"
    />
  );
};

//
// UTIL

/**
 * Convert a duration in seconds to minutes:seconds
 * WARNING: Does not handle hours.
 */
export function secondsToMinutesSeconds(duration: number): string {
  const minutes = Math.floor(duration / 60);
  const seconds = duration - minutes * 60;

  const minutesPadded = minutes < 10 ? '0' + minutes : minutes;
  const secondsPadded = seconds < 10 ? '0' + seconds : seconds;

  return `${minutesPadded}:${secondsPadded}`;
}
