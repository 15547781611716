import {decimalOrPlaceholder, valueOrPlaceholder} from 'fiba/wt/ui/gameBoxScore/gameBoxScoreUtils';
import {IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import * as React from 'react';

export const decimalColumn = (name, srName, decimals = 2, isPercentage = false): IColumnSpec<any> =>
  numberColumn(name, srName, v => decimalOrPlaceholder(v, decimals), isPercentage);

export const integerColumn = (name, srName): IColumnSpec<any> =>
  numberColumn(name, srName, valueOrPlaceholder);
const numberColumn = (name, srName, valueFormatter, isPercentage = false): IColumnSpec<any> => ({
  name,
  dataType: 'number',
  srName,
  renderColumn: renderNumber(valueFormatter, isPercentage),
});

const renderNumber = (valueFormatter, isPercentage) => ({
  rowData,
  columnName,
  columnIndex,
  Td,
  getTdProps,
}) => (
  <Td {...getTdProps()}>
    {valueFormatter(rowData[columnName])}
    {!!isPercentage && '%'}
  </Td>
);
