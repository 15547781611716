import React, {useContext} from 'react';
import {TourTeam} from 'fiba/common/core/models/api/tour/TourTeam';
import {connect} from 'fiba/common/utils/reactUtils';
import {Map} from 'immutable';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {SubHeading, Section} from 'fiba/wt/ui/heading/Heading';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {BackLink} from 'fiba/wt/ui/backlink/BackLink';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {TourTeamStops} from 'fiba/wt/ui/tourTeamPage/TourTeamStops';
import {TourTeamPlayers} from 'fiba/wt/ui/tourTeamPage/TourTeamPlayers';
import {FlexCardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {tourTeamPlayerLinkProvider} from 'fiba/wt/utils/linkUtils';
import TourTeamResults from 'fiba/common/core/models/api/results/TourTeamResults';

import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {globalDataPath, globalMetaPath} from 'fiba/wt/stores/tourTeamResultsStore';

interface ITourTeamOverview {
  tourId: string;
  season: string;
  teamId: string;
}

interface ITourTeamOverviewState {
  team: TourTeam;
  standingsData: RemoteData.RemoteData<TourTeamResults, {}>;
  metaPaths: string[];
}

const mapStateToProps = (state, {tourId, teamId}: ITourTeamOverview): ITourTeamOverviewState => {
  return {
    standingsData: RemoteData.map(
      RemoteData.fromMetaObject(state.getIn(globalMetaPath(teamId), Map()).toJS()),
      () => state.getIn(globalDataPath(teamId)),
    ),
    team: state.getIn(['tourTeams', teamId]),
    metaPaths: [`tourTeams/__meta/${teamId}`],
  };
};

type TourTeamOverview = ITourTeamOverview & ITourTeamOverviewState;

// FIXME: This is unsemantic
const definitionListRow = (title, data: number | string = '-') => (
  <Flex
    ph={['0', '2', '2', '0']}
    flexDirection={['row', 'row', 'row', 'column']}
    justifyContent={['between', 'start']}
    width={['100', '33']}
  >
    <dt className="pr3 pb2 pr0-l tl tr-l">{title}</dt>
    <dd className="b tr">{data}</dd>
  </Flex>
);

const _TourTeamOverview: React.FunctionComponent<TourTeamOverview> = ({
  tourId,
  season,
  teamId,
  team,
  standingsData,
  metaPaths,
}) => {
  const {features} = useContext(SiteConfigContext);
  const backUrl =
    features.siteId === 'womensseries' ? `/${season}/teams` : `/${season}/teams/world-tour/all`;
  return (
    <Spacer vSpace="3" debugClassName="TourTeamOverview">
      <Box pt={['4', '4', '0', '4']} ph={['2', '0']}>
        <BackLink href={backUrl} content="Back to tour teams" />
      </Box>

      <Loading metaPaths={metaPaths}>
        {() =>
          !!team && (
            <React.Fragment>
              <FlexCardContainer flexDirection="column" debugClassName="TourTeamOverview-Team">
                <Flex
                  flexDirection={['column', 'column', 'column', 'row']}
                  justifyContent="between"
                  alignItems={['start', 'start', 'start', 'center']}
                  pb={['0', '0', '0', '4']}
                >
                  <SubHeading fontSize="3" pb={['3', '3', '3', '0']}>
                    <TeamNameAssembly team={team} isFlagVisible isSuffixVisible />
                  </SubHeading>

                  {RemoteData.match(standingsData, {
                    NotAsked: () => null,
                    Loading: () => (
                      <div className="LoadingIndicator pv5 tc">
                        <div className="dib h4 w4 LoadingIndicator-Indicator">
                          <LoadingSVG purpose="standalone" aria-label="Loading..." />
                        </div>
                      </div>
                    ),
                    // Standings will be missing (404) if the team is not assigned to any event within the Tour
                    Failure: err => <BlockText>Standings currently unavailable</BlockText>,
                    Success: standing => (
                      <Box width={['100', '100', '100', '50']}>
                        <dl className="TourTeamOverview-StatsTable flex flex-column flex-row-s mnh0 mnh2-s mnh0-l">
                          {definitionListRow('Standing', standing.standing)}
                          {definitionListRow('Games played', standing.gamesPlayed)}
                          {definitionListRow('Games won', standing.gamesWon)}
                        </dl>
                      </Box>
                    ),
                  })}
                </Flex>

                <Box
                  mnh="4"
                  border="bottom"
                  borderWidth={['0', '0', '0', '1']}
                  borderColor="dark-50"
                  debugClassName="separator"
                />

                <Section>
                  <Box pt={['2', '2', '2', '4']} mnb={['3', '3']}>
                    <TourTeamPlayers
                      season={season}
                      tourId={tourId}
                      teamId={team.id}
                      playerLinkProvider={tourTeamPlayerLinkProvider(season, teamId)}
                    />
                  </Box>
                </Section>
              </FlexCardContainer>

              {RemoteData.match(standingsData, {
                NotAsked: () => null,
                Loading: () => (
                  <div className="LoadingIndicator pv5 tc">
                    <div className="dib h4 w4 LoadingIndicator-Indicator">
                      <LoadingSVG purpose="standalone" aria-label="Loading..." />
                    </div>
                  </div>
                ),
                // Results will be missing (404) if the team is not assigned to any event within the Tour
                Failure: err => <BlockText>No events</BlockText>,
                Success: standing => (
                  <Section>
                    <TourTeamStops season={season} teamStops={standing.eventResults} />
                  </Section>
                ),
              })}
            </React.Fragment>
          )
        }
      </Loading>
    </Spacer>
  );
};

export const TourTeamOverview = connect<ITourTeamOverviewState, {}, ITourTeamOverview>(
  mapStateToProps,
)(_TourTeamOverview);
