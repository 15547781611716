import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {BackLink} from 'fiba/wt/ui/backlink/BackLink';
import {PhotoGallery} from 'fiba/wt/ui/photoGallery/PhotoGallery';
import {Photo} from 'fiba/common/core/models/api/media/Photo';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventPhotosLink, EventType} from 'fiba/wt/utils/linkUtils';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface OwnProps {
  season: string;
  eventId: string;
  galleryId: string;
}

interface ReduxProps {
  galleryTitle?: string;
  photos: List<Photo>;
  eventTypeData: WebData<EventType>;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {eventId, galleryId}: OwnProps): ReduxProps => {
  const galleryTitle = state.getIn(['media', '__meta', 'photos', 'galleries', galleryId, 'title']);
  const photos = state.getIn(['media', 'photos', 'galleries', galleryId], List());
  const eventTypeData = getEventType(state.get('events'), eventId);
  const metaPaths = [`events/__meta/${eventId}`, `media/__meta/photos/galleries/${galleryId}`];

  return {
    photos,
    eventTypeData,
    metaPaths,
    galleryTitle,
  };
};

const _PhotoGalleryPage: React.FC<Props> = ({
  season,
  eventId,
  eventTypeData,
  photos,
  metaPaths,
  galleryTitle,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <ViewWebData data={eventTypeData}>
          {eventType => (
            <Spacer vSpace="4" ph="3" debugClassName="PhotoGalleryPage">
              <div>
                <PageHeading text={galleryTitle} />
                <BackLink
                  href={eventPhotosLink(seasonConfig, season, eventId, eventType)}
                  content="Back to list of galleries"
                />
              </div>

              <PhotoGallery photos={photos} />
            </Spacer>
          )}
        </ViewWebData>
      )}
    </Loading>
  );
};

export const PhotoGalleryPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _PhotoGalleryPage,
);
