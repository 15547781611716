import {getRoundNameLong} from 'fiba/wt/core/results';
import {LocalizerService} from 'fiba/common/services/services';
import {
  EventStatsSummary,
  TopPlayerStats as EventPlayerStats,
  TopTeamStats as EventTeamStats,
} from 'fiba/common/core/models/api/stats/EventStatsSummary';
import {
  TourStatsSummary,
  TopTeamWinBeforeLimitStats,
  TopTeamWinningPercentageStats,
  TopTeamStats as TourTeamStats,
  TopPlayerStats as TourPlayerStats,
} from 'fiba/common/core/models/api/stats/TourStatsSummary';

export const mapRoundCode = (roundCode: string, localizer: LocalizerService) => {
  if (roundCode === 'RR') {
    return 'Pools';
  }
  if (roundCode === 'QD') {
    return 'Qualifying Draw';
  }
  return getRoundNameLong(roundCode, localizer);
};

export const formatTeamName = (teamName, teamNameSuffix) =>
  teamNameSuffix ? `${teamName} ${teamNameSuffix}` : teamName;

export const areStats = stats => stats && !stats.isEmpty();

export const arePlayerStats = (statsSummary: EventStatsSummary | TourStatsSummary): boolean => {
  if (statsSummary instanceof EventStatsSummary) {
    return (
      !statsSummary.topPlayerPlayerValue.isEmpty() ||
      !statsSummary.topPlayerScorer.isEmpty() ||
      !statsSummary.topPlayerHighlights.isEmpty() ||
      !statsSummary.topPlayerKeyAssists.isEmpty() ||
      !statsSummary.topPlayerRebounds.isEmpty()
    );
  }
  if (statsSummary instanceof TourStatsSummary) {
    return (
      !statsSummary.topPlayerPlayerValue.isEmpty() ||
      !statsSummary.topPlayerScorer.isEmpty() ||
      !statsSummary.topPlayerHighlights.isEmpty() ||
      !statsSummary.topPlayerKeyAssists.isEmpty() ||
      !statsSummary.topPlayerRebounds.isEmpty() ||
      !statsSummary.topPlayerBuzzerBeaters.isEmpty() ||
      !statsSummary.topPlayerTripleFives.isEmpty() ||
      !statsSummary.topPlayerDoubleFives.isEmpty() ||
      !statsSummary.topPlayerDrives.isEmpty() ||
      !statsSummary.topPlayerDunks.isEmpty() ||
      !statsSummary.topPlayerBlocks.isEmpty() ||
      !statsSummary.topPlayerTwoPointers.isEmpty() ||
      !statsSummary.topPlayerFTPercentage.isEmpty() ||
      !statsSummary.topPlayerOnePointPercentage.isEmpty() ||
      !statsSummary.topPlayerTwoPointPercentage.isEmpty()
    );
  }
};

export const areTeamStats = (statsSummary: EventStatsSummary | TourStatsSummary): boolean => {
  if (statsSummary instanceof EventStatsSummary) {
    return (
      !statsSummary.topTeamPointsScored.isEmpty() ||
      !statsSummary.topTeamShootingValue.isEmpty() ||
      !statsSummary.topTeamHighlights.isEmpty() ||
      !statsSummary.topTeamFewestPointsAllowed.isEmpty()
    );
  }
  if (statsSummary instanceof TourStatsSummary) {
    return (
      !statsSummary.topTeamWinningPercentage.isEmpty() ||
      !statsSummary.topTeamWinsBeforeLimit.isEmpty() ||
      !statsSummary.topTeamPointsScored.isEmpty() ||
      !statsSummary.topTeamShootingValue.isEmpty() ||
      !statsSummary.topTeamFewestPointsAllowed.isEmpty() ||
      !statsSummary.topTeamHighlights.isEmpty() ||
      !statsSummary.topTeamKeyAssists.isEmpty() ||
      !statsSummary.topTeamDrives.isEmpty() ||
      !statsSummary.topTeamRebounds.isEmpty() ||
      !statsSummary.topTeamBuzzerBeaters.isEmpty() ||
      !statsSummary.topTeamBlocks.isEmpty() ||
      !statsSummary.topTeamFewestTurnOvers.isEmpty() ||
      !statsSummary.topTeamFTPrecentage.isEmpty() ||
      !statsSummary.topTeamOnePointPercentage.isEmpty() ||
      !statsSummary.topTeamTwoPointPercentage.isEmpty()
    );
  }
};

export const areNotables = (statsSummary: EventStatsSummary | TourStatsSummary): boolean => {
  if (statsSummary instanceof EventStatsSummary) {
    const {playerValueInSingleGame, buzzerBeaters, tripleFives} = statsSummary;
    return !playerValueInSingleGame.isEmpty() || !buzzerBeaters.isEmpty() || !tripleFives.isEmpty();
  }
  if (statsSummary instanceof TourStatsSummary) {
    return !statsSummary.playerValueInSingleGame.isEmpty();
  }
};

export type TourStats =
  | TourPlayerStats
  | TourTeamStats
  | TopTeamWinBeforeLimitStats
  | TopTeamWinningPercentageStats;

export type EventStats = EventPlayerStats | EventTeamStats;

export const getTeamIdForLink = (statsItem: EventStats | TourStats) => {
  const isTourStat = (statsItem as TourStats).isTourTeam !== undefined;
  // teamHref uses different team Ids for either a Tour or an Event
  if (isTourStat) {
    return (statsItem as TourStats).isTourTeam ? statsItem.teamId : null;
  } else {
    return (statsItem as EventStats).teamInEventId;
  }
};
