import {fromJS, Map} from 'immutable';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {createActions, createReducer, resolvePathWithGender} from 'fiba/wt/utils/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import {rethrow, setMeta} from 'fiba/common/stores/storeUtils';
import {mapValues} from 'lodash';
import {
  SortableStore,
  SortableStoreItem,
  sortingReducers,
} from 'fiba/wt/stores/reducers/sortableStore';
import {PlayerStatsLike} from 'fiba/common/core/models/api/stats/PlayerStats';
import PlayerTourStatsResponse, {
  PlayerTourStatsResponseLike,
} from 'fiba/common/core/models/api/stats/PlayerTourStatsResponse';
import ResultsTeam from 'fiba/common/core/models/api/results/ResultsTeam';
import TeamPlayer from 'fiba/common/core/models/api/stats/TeamPlayer';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';

export const statePath = (tourOrEventId: string, category?: string) => {
  if (category) {
    return [tourOrEventId, category];
  }
  return [tourOrEventId];
};
const metaPath = (tourOrEventId: string, category?: string) => [
  '__meta',
  ...statePath(tourOrEventId, category),
];
export const storePath = 'playerStats';
export const globalStatePath = (tourOrEventId: string, category?: string) => [
  storePath,
  ...statePath(tourOrEventId, category),
];
export const globalMetaPath = (tourOrEventId: string, category?: string) => [
  storePath,
  ...metaPath(tourOrEventId, category),
];

export interface PlayerStatRow extends PlayerStatsLike {
  player: TeamPlayer;
  team: ResultsTeam;
}

export interface PlayerStatsStore extends SortableStore<PlayerStatRow> {}

const mapResponse = (response: PlayerTourStatsResponse): SortableStoreItem<PlayerStatRow> => {
  const playersById: Map<string, TeamPlayer> = response.players.reduce(
    (map, value) => map.set(value.id, value),
    Map(),
  );

  const teamsById: Map<string, ResultsTeam> = response.teams.reduce(
    (map, value) => map.set(value.teamId, value),
    Map(),
  );

  const value: SortableStoreItem<PlayerStatRow> = {
    sortedBy: {
      fieldName: null,
      sortOrder: 'none',
    },
    updatedAt: response.updatedAt,
    data: response.playerStatistics
      .map(stat => {
        return fromJS({
          team: teamsById.get(playersById.get(stat.playerId).teamId),
          player: playersById.get(stat.playerId),
          ...stat.toJS(),
        });
      })
      .toList(),
  };

  return fromJS(value);
};

const reducers = {
  ...sortingReducers,

  loadTourPlayerStats: (state: PlayerStatsStore, eventOrTourId: string): PlayerStatsStore => {
    return state.updateIn(metaPath(eventOrTourId), setMeta.isLoading);
  },

  loadEventPlayerStats: (
    state: PlayerStatsStore,
    eventId: string,
    _categoryId: string,
    gender: CategoryGenderTab,
  ): PlayerStatsStore => {
    return state.updateIn(['__meta', ...resolvePathWithGender(eventId, gender)], setMeta.isLoading);
  },

  loadStatsSuccess: (
    state: PlayerStatsStore,
    eventOrTourId: string,
    response: PlayerTourStatsResponseLike,
    gender?: CategoryGenderTab,
  ): PlayerStatsStore => {
    /* If stats have totalPointsMade, let's default to sorting with that */
    response.playerStatistics.sort((a, b) => b.totalPointsMade - a.totalPointsMade);
    const value = mapResponse(PlayerTourStatsResponse.fromJS(response));
    const tourOrEventPath = resolvePathWithGender(eventOrTourId, gender);
    return state
      .setIn(tourOrEventPath, value)
      .updateIn(['__meta', ...tourOrEventPath], setMeta.isLoaded);
  },

  loadStatsError: (
    state: PlayerStatsStore,
    eventOrTourId: string,
    _error: Error,
    gender?: CategoryGenderTab,
  ) => state.updateIn(['__meta', ...resolvePathWithGender(eventOrTourId, gender)], setMeta.isError),
};

const externalReducers = {};

export const reducer = createReducer<PlayerStatsStore>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

export const actions = createActions(__filename, reducers, {
  loadTourPlayerStats: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadPlayerTourStats(tourId)
      .then(response => actions.loadStatsSuccess(tourId, response.data))
      .catch(rethrow((err: Error) => actions.loadStatsError(tourId, err))),

  loadEventPlayerStats: (eventId: string, categoryId: string, gender: CategoryGenderTab) => ({
    apiClient,
  }: CommonServices) =>
    apiClient
      .loadPlayerEventStats(eventId, categoryId)
      .then(response => actions.loadStatsSuccess(eventId, response.data, gender))
      .catch(rethrow((err: Error) => actions.loadStatsError(eventId, err, gender))),

  loadStatsError: (eventOrTourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

// Arbitrary JSON -> State
// State as JSON -> State (which is an Immutable map)
export const deserializeState = ({__meta, ...items}) => {
  // Make sure known things exist
  return fromJS({
    __meta: __meta || {},
    ...mapValues(items, item => fromJS(item)),
  });
};
