import statsActions from 'fiba/wt/stores/statsStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import * as TeamStats from 'fiba/wt/stores/teamStatsStore';
import * as ProCircuitTeamStats from 'fiba/wt/stores/proCircuitTeamStatsStore';
import * as PlayerStats from 'fiba/wt/stores/playerStatsStore';
import * as ProCircuitPlayerStats from 'fiba/wt/stores/proCircuitPlayerStatsStore';
import * as TopScorerStats from 'fiba/wt/stores/topScorerStatsStore';
import {CacheService} from 'fiba/common/services/cacheService';
import {CategoryGenderTab, filterCategoryByGender} from 'fiba/wt/utils/categories';
import {splitEventCategoriesByGender} from 'fiba/wt/stores/categoryStore';

function eventStatsController(eventId: string, gender?: CategoryGenderTab) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['stats', 'eventFastestGames', eventId], () => {
      const eventCategoriesByGender = splitEventCategoriesByGender(cache(['categories']), eventId);
      return dispatch(statsActions.loadEventFastestGames(eventId, gender, eventCategoriesByGender));
    });
  };
}

function eventStatTopScorersController(eventId: string, gender?: CategoryGenderTab) {
  return ({dispatch, cache}: CommonServices) =>
    cache(['topScorerStats', eventId], () => {
      const categoryId = findEventCategory(cache, eventId, gender);
      return dispatch(TopScorerStats.actions.loadStatsTopScorers(eventId, categoryId, gender));
    });
}

function eventStatSummaryController(eventId: string) {
  return ({dispatch, cache}: CommonServices) =>
    cache(['stats', 'eventStatsSummary', eventId], () =>
      dispatch(statsActions.loadEventStatsSummary(eventId)),
    );
}

function tourStatSummaryController(tourId: string) {
  return ({dispatch, cache}: CommonServices) =>
    cache(['stats', 'tourStatsSummary', tourId], () =>
      dispatch(statsActions.loadTourStatsSummary(tourId)),
    );
}

function tourStatsController({season, tourId}: {season: string; tourId: string}) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['stats', 'tourFastestGames', tourId], () =>
      dispatch(statsActions.loadSeasonFastestGames(season, tourId)),
    );
  };
}

// Separate controller for WS, since it has to get fastest games by tourId
function tourStatsControllerWS({tourId}: {tourId: string}) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['stats', 'eventFastestGames', tourId], () =>
      dispatch(statsActions.loadTourFastestGames(tourId)),
    );
  };
}

function tourTeamStatsController({tourId}: {tourId: string}) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(TeamStats.globalStatePath(tourId), () => {
      return dispatch(TeamStats.actions.loadTourTeamStats(tourId));
    });
  };
}

function tourPlayerStatsController({tourId}: {tourId: string}) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(PlayerStats.globalStatePath(tourId), () => {
      return dispatch(PlayerStats.actions.loadTourPlayerStats(tourId));
    });
  };
}

// Pro Circuit
function proCircuitStatSummaryController(tourId: string) {
  return ({dispatch, cache}: CommonServices) =>
    cache(['stats', 'proCircuitStatsSummary', tourId], () =>
      dispatch(statsActions.loadProCircuitStatsSummary(tourId)),
    );
}

function tourProCircuitTeamStatsController({tourId}: {tourId: string}) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(ProCircuitTeamStats.globalStatePath(tourId), () => {
      return dispatch(ProCircuitTeamStats.actions.loadProCircuitTourTeamStats(tourId));
    });
  };
}

function tourProCircuitPlayerStatsController({tourId}: {tourId: string}) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(ProCircuitPlayerStats.globalStatePath(tourId), () => {
      return dispatch(ProCircuitPlayerStats.actions.loadProCircuitTourPlayerStats(tourId));
    });
  };
}

const findEventCategory = (cache: CacheService, eventId: string, gender?: CategoryGenderTab) => {
  const eventCategories = cache(['categories'])
    .toList()
    .filter(category => category.get('eventId') === eventId)
    .filter(filterCategoryByGender(gender))
    .map(category => category.get('id'));

  if (eventCategories.size !== 1) {
    // In case of Cups, we have multiple categories per event but one per gender
    throw new Error(`Expected event to have one category but found ${eventCategories.size}`);
  }

  return eventCategories.get(0);
};

// NOTE: Assumes the event's categories are loaded beforehand (this should only be used with composeControllers()).
function eventPlayerStatsController(eventId: string, gender?: CategoryGenderTab) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(PlayerStats.globalStatePath(eventId), () => {
      return dispatch(
        PlayerStats.actions.loadEventPlayerStats(
          eventId,
          findEventCategory(cache, eventId, gender),
          gender,
        ),
      );
    }).catch(_ => {
      // We expect some EM errors to show up here for older events (due to missing stats data)
      // We catch here and show a simple error message on <PlayerStatistics>
      // We can catch here as the relevant metaPaths will reflect any error that happened further down in the chain
      // Not catching would propagate the error to the RouterService and navigate to /error
      // There is no need to rethrow here as only a small part of the Stats UI will be broken
    });
  };
}

function eventTeamStatsController(eventId: string, gender?: CategoryGenderTab) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(TeamStats.globalStatePath(eventId), () => {
      return dispatch(
        TeamStats.actions.loadEventTeamStats(
          eventId,
          findEventCategory(cache, eventId, gender),
          gender,
        ),
      );
    }).catch(_ => {
      // We expect some EM errors to show up here for older events (due to missing stats data)
      // We catch here and show a simple error message on <TeamStatistics>
      // We can catch here as the relevant metaPaths will reflect any error that happened further down in the chain
      // Not catching would propagate the error to the RouterService and navigate to /error
      // There is no need to rethrow here as only a small part of the Stats UI will be broken
    });
  };
}

export {
  eventStatsController,
  eventStatTopScorersController,
  eventStatSummaryController,
  eventPlayerStatsController,
  eventTeamStatsController,
  tourStatSummaryController,
  proCircuitStatSummaryController,
  tourStatsController,
  tourTeamStatsController,
  tourPlayerStatsController,
  tourStatsControllerWS,
  tourProCircuitTeamStatsController,
  tourProCircuitPlayerStatsController,
};
