import React from 'react';
import {useInView} from 'react-intersection-observer';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Placeholder} from 'fiba/wt/ui/placeholder/Placeholder';

type Props = {
  src: string;
  width: string;
  height: string;
  aspectRatio: StyleProp<'aspectRatio'>;
  delayShouldRenderCheck?: boolean;
} & React.IframeHTMLAttributes<HTMLIFrameElement>;

export const LazyIframe: React.FC<Props> = ({
  aspectRatio,
  delayShouldRenderCheck,
  ...iframeProps
}) => {
  // In some cases we want to delay determining if the iframe is in view. For example, this can
  // be when we have a large list of video embeds on a page that the user scrolls through quickly. In
  // this case (without a delay) we would try to render _everything_ - which is bad. With the delay, we
  // ensure a user is _actually_ looking at the element before trying to load it.
  const delay = delayShouldRenderCheck ? 500 : 0;
  const [ref, inView] = useInView({threshold: 0.01, delay, triggerOnce: true});
  return (
    <Placeholder
      aspectRatio={aspectRatio}
      image={cls =>
        inView ? (
          <iframe className={cls} ref={ref} {...iframeProps} />
        ) : (
          <div className={cls} ref={ref}>
            <noscript>
              <iframe className="ht-100 w-100" {...iframeProps} {...{loading: 'lazy'}} />
            </noscript>
          </div>
        )
      }
    />
  );
};
