import anyroute from 'anyroute';
import {Context as KoaContext} from 'koa';
import queryString from 'query-string';
import {isError, isFunction, isString} from 'lodash';
import {fromJS, Map} from 'immutable';
import {commonEnv} from 'fiba/common/config/env';
import {stripNamespace} from 'fiba/common/stores/storeUtils';
import FibaError, {NotFoundError} from 'fiba/common/core/fibaError';
import Routes, {RouteDefinition} from 'fiba/common/core/models/Routes';
import CommonServices from 'fiba/common/core/models/CommonServices';
import BrowserServices from 'fiba/common/core/models/BrowserServices';
import Controller from 'fiba/common/core/models/Controller';
import {addNewMetaTagsToDom} from '../utils/metaTags';
import reportCrash from 'fiba/common/client/services/crashReportingService';
import {
  PayloadTransformer,
  mapEventIdToShortName,
  transformPayload,
  isUUID,
} from 'fiba/wt/utils/routeUtils';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {getSeasonConfigs, getGlobalConfig} from 'fiba/wt/stores/contentStore';
import {mergeSeasonConfigs} from 'fiba/wt/utils/mergeSeasonConfigs';

export const ROUTER_PUSH_LOCATION = `${stripNamespace(__filename)}/pushLocation`;
export const ROUTER_REPLACE_LOCATION = `${stripNamespace(__filename)}/replaceLocation`;
export const ROUTER_ROUTE_CHANGED = `${stripNamespace(__filename)}/routeChanged`;
export const ROUTER_ROUTE_LOADED = `${stripNamespace(__filename)}/routeLoaded`;
export const ROUTER_SERVER_RENDER_HYDRATED = `${stripNamespace(__filename)}/serverRenderHydrated`;

// Action creators
export const routerPushLocation = location => ({
  type: ROUTER_PUSH_LOCATION,
  payload: location,
});

export const routerReplaceLocation = location => ({
  type: ROUTER_REPLACE_LOCATION,
  payload: location,
});

export const routerRouteChanged = route => ({
  type: ROUTER_ROUTE_CHANGED,
  payload: route,
});

export const routerRouteLoaded = route => ({
  type: ROUTER_ROUTE_LOADED,
  payload: route,
  error: isError(route),
});

export const routerServerRenderHydrated = () => ({
  type: ROUTER_SERVER_RENDER_HYDRATED,
});

export const isEmbed = (url: string) => /^\/embed\//.test(url);

export const isPathName = (url: string) => url && url.length && url[0] === '/';

export const embedHref = (href: string, currentUrl: string) =>
  isEmbed(currentUrl) && isPathName(href) && !isEmbed(href) ? `/embed${href}` : href;

export interface RouteParams {
  host: string;
  hostname: string;
  pathname: string;
  search: string;
  hash: string;
  query: any;
  extraPayload: {
    isServer?: boolean;
    isEmbed?: boolean;
  };
  resolveParams: PayloadTransformer;
}
// Like `embedHref` but accepts either history#location or url string as first parameter
function embedLocation(location, currentUrl) {
  if (isString(location)) {
    return embedHref(location, currentUrl);
  }

  return {
    ...location,
    pathname: embedHref(location.pathname, currentUrl),
  };
}

export function getRouteTitle(services, titleDesc) {
  if (isString(titleDesc)) {
    return titleDesc;
  }

  if (isFunction(titleDesc)) {
    return titleDesc(services);
  }

  return '';
}

export function getStatusFromError(e: Error): number {
  if (e instanceof NotFoundError) {
    return 404;
  }
  if (e instanceof FibaError && e.fiba.get('status')) {
    return e.fiba.get('status');
  }
  return 500;
}

export function getUrlFromError(e, status): string {
  if (e instanceof NotFoundError || status === 404) {
    return '/404';
  }
  return `/error?message=${encodeURIComponent(
    isString(e) ? e : e.message,
  )}&status=${encodeURIComponent(status)}`;
}

export type State = Map<string, any>;

export class RouterService {
  // TODO: Use an Immutable Record
  static reducer(state: State = fromJS({hasHydratedServerMarkup: false}), action) {
    switch (action.type) {
      // NOTE: Do not cause double-updates by updating the store in ROUTER_ROUTE_LOADED too,
      // that causes problems if you navigate between pages quickly enough for their loading
      // periods to overlap.
      case ROUTER_ROUTE_CHANGED:
        return state.merge(action.payload);
      case ROUTER_ROUTE_LOADED:
        // HACK: Considering the above comment, update only the title if and only if the URLs match
        if (state && action.payload && state.get('pathname') === action.payload.pathname) {
          return state.set('title', action.payload.title);
        }
      case ROUTER_SERVER_RENDER_HYDRATED:
        return state.set('hasHydratedServerMarkup', true);
      default:
        return state;
    }
  }

  // NOTE: Server router requires that `services` contains `store`-service and
  // browser router requires `store` and `history`-services, not before initialization
  // but before calling `server` or `browser` methods.
  // TODO: Move these out of the constructor
  constructor(
    public services: CommonServices,
    public routes: Routes,
    public defaultControllers: Controller[] = [],
    public defaultLayout?,
    public router = new anyroute(),
  ) {
    for (const path in routes) {
      if (routes.hasOwnProperty(path)) {
        const r = router.set(path, routes[path]);
        if (r.err) {
          // eslint-disable-next-line no-console
          console.warn('RouterService warning:', r.err);
        }
      }
    }
  }

  get(...args) {
    try {
      const r = this.router.get(...args);
      // Work around a quirk of anyroute
      if (r.err && r.err === 'not found') {
        return {err: new NotFoundError(r.err), handler: null, payload: {}};
      }
      return r;
    } catch (err) {
      return {err, handler: null, payload: {}};
    }
  }

  set(...args) {
    return this.router.set(...args);
  }

  /*
    Main route function executed on both server and client.
    - Finds the correct route from the route table based on the given pathname.
    - Dispatches `routerRouteChanged` action
    - Fires controllers defined for the route if any
    - After controllers are finished, will set the title and dispatch `routerRouteLoaded` action
  */
  async route({
    host,
    hostname,
    pathname,
    search,
    hash,
    query,
    extraPayload,
    resolveParams,
  }: RouteParams) {
    const {services, defaultLayout} = this;
    const {store} = services;
    const match = this.get(pathname, {
      ...query,
      ...extraPayload,
    });
    const {err, handler, payload} = match; // One could provide a default here

    // Throwing an error here will call this function recursively with new url
    if (!handler) {
      // Soft fail for the first time
      throw new NotFoundError('404');
    } else if (err) {
      // Soft fail for the first time
      throw err;
    }

    const resolvedPayload = resolveParams(payload);

    const {
      components,
      controllers,
      clientSideControllers,
      title,
      layout,
      renderMetaTags,
      developmentOnly,
    }: RouteDefinition = handler(resolvedPayload);

    if (developmentOnly && !commonEnv.DEBUG) {
      throw new NotFoundError('404');
    }

    // NOTE: If you have functions that need to be called with services/the resolved cache, then add them in finalRoute, below
    // This has bitten us a few times before :)
    const routeWithFiltersResolved = {
      pathname,
      search,
      hash,
      query,
      components,
      payload: resolvedPayload,
      host,
      hostname,
      layout: layout || defaultLayout,
    };

    // FIXME v2: In crash situation, should we not execute the controllers?
    // NOTE: Make sure controllers get executed first by wrapping `routerRouteChanged` into a promise
    const allControllers = this.defaultControllers.concat(controllers || []);
    const controllersPromise = Promise.all(allControllers.map(controller => controller(services)));
    const routePromise = Promise.resolve().then(() => {
      // NOTE: If there is a hash in the route, we defer to the browser, so we don't dispatch any
      // loading actions. The routeLoaded promise still gets run, so the history updates correctly.
      if (!routeWithFiltersResolved.hash) {
        store.dispatch(routerRouteChanged(routeWithFiltersResolved));
      }
    });

    await Promise.all([controllersPromise, routePromise]);

    // Fire off any registered async controllers if we are not on the server
    // We fire these controllers off AFTER the initial controllers all resolve to not intervene / fight for bandwidth
    // We do NOT await these controllers so they do not affect the time to initially hydrate the application ie return from this function
    if (!extraPayload.isServer && clientSideControllers) {
      clientSideControllers.map(controller => controller(services));
    }

    // Now that the controllers (data) has loaded, merge the route
    // with functions that need cache data

    const finalRoute = {
      ...routeWithFiltersResolved,
      title: getRouteTitle(services, title),
      metaTags: renderMetaTags !== undefined ? renderMetaTags(services) : undefined,
    };

    // Let the store know that the route has loaded
    store.dispatch(routerRouteLoaded(finalRoute));

    return finalRoute;
  }

  // Koa middleware for the server
  async server(
    ctx: KoaContext,
    resolveParams = params => params,
    initialPayload: RouteParams['extraPayload'] = {},
    isError = false,
  ) {
    const pathname = ctx.path;
    const {host, hostname, search, query} = ctx;
    const extraPayload = {...initialPayload, isServer: true};

    const hash = ''; // FIXME: Koa doesn't provide the hash from the URL

    try {
      const route = await this.route({
        host,
        hash,
        query,
        search,
        hostname,
        pathname,
        extraPayload,
        resolveParams,
      });
      return route;
    } catch (err) {
      ctx.status = getStatusFromError(err);
      ctx.url = embedHref(getUrlFromError(err, ctx.status), pathname);
      // eslint-disable-next-line no-console
      console.error(err);

      // Eliminate recursive error loops and also handle errors gracefully,
      if (isError) {
        throw err; // FIXME: Hard fail
      }
      return this.server(ctx, resolveParams, initialPayload, true);
    }
  }

  // Regular call-once-function for the browser
  browser(location, initialPayload: RouteParams['extraPayload'] = {}, siteConfig?: ISiteConfig) {
    const {history} = this.services as BrowserServices;

    history.listen(location => this.handleBrowserRoute(location, initialPayload, siteConfig));

    return this.handleBrowserRoute(location || history.location, initialPayload, siteConfig);
  }

  // Browser-side function invoked every time location changes

  async handleBrowserRoute(
    location,
    initialPayload: RouteParams['extraPayload'] = {},
    siteConfig?: ISiteConfig,
  ) {
    const {services} = this;
    const {history} = services as BrowserServices;
    const {pathname, search, hash} = location;
    const query = isString(search) ? queryString.parse(search) : search;
    const {host, hostname} = window.location;
    const isError = location.state && location.state.isError;

    // Resolve raw query parameters into final ones:
    let resolveParams = passThrough => passThrough;
    let mergedSeasonConfig = undefined;
    // Play does not have the same season config logic as WT et al:
    if (siteConfig) {
      const contentfulSeasonConfigs = getSeasonConfigs(services.store.getState());
      mergedSeasonConfig = mergeSeasonConfigs(contentfulSeasonConfigs, siteConfig);
      const globalConfig = getGlobalConfig(services.store.getState());

      const defaultSeason = globalConfig.defaultSeasons[siteConfig.features.siteId];

      resolveParams = transformPayload(mergedSeasonConfig, defaultSeason);
    }

    try {
      const route = await this.route({
        host,
        hostname,
        pathname,
        search,
        hash,
        query,
        resolveParams,
        extraPayload: initialPayload,
      });

      const shortName = mapEventIdToShortName(route.payload, mergedSeasonConfig);

      // somewhat defensive programming, but if user has added a short name that is a uuid,
      // then do nothing, in case user has accidentally added the event id as the short name
      // this prevents a redirect loop:
      if (shortName && !isUUID(shortName)) {
        // if the event has a short name, manipulate the URL to show it instead of the event id:
        const newPath = pathname.replace(route.payload.eventId, shortName);
        services.dispatch(routerReplaceLocation(newPath));
      }
      if (route.title) {
        document.title = route.title as string;
      }
      if (route.metaTags) {
        addNewMetaTagsToDom(route.metaTags);
      }
      return route;
    } catch (e) {
      reportCrash('handleBrowserRoute', e);

      // Eliminate recursive error loops and also handle errors gracefully
      if (isError) {
        return; // FIXME: Hard fail
      }
      const [pathname, search] = embedHref(
        getUrlFromError(e, getStatusFromError(e)),
        history.location.pathname,
      ).split('?');
      // Recurse directly instead of calling `history.replace` so that we can return a promise
      return this.handleBrowserRoute({pathname, search, state: {isError: true}});
    }
  }

  // Redux middleware for client
  createMiddleware() {
    const {history} = this.services as BrowserServices;

    return store => next => action => {
      const currentUrl = history.location.pathname;

      if (action && action.type === ROUTER_PUSH_LOCATION) {
        history.push(embedLocation(action.payload, currentUrl));
      } else if (action && action.type === ROUTER_REPLACE_LOCATION) {
        history.replace(embedLocation(action.payload, currentUrl));
      }

      return next(action);
    };
  }

  // Attach anchor listener to root element that will dispatch route actions
  // Modified version from https://gist.github.com/Daniel-Hug/abbded91dd55466e590b
  createAnchorListener(el = window.document) {
    const {store} = this.services;

    el.addEventListener('click', event => {
      // Only apply to left-clicks
      // Only apply if no ctrl, shift, meta keys are pressent
      if (
        // Defer to the browser for modifier keys
        // For example, meta/ctrl + click opens pages in a new tab
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey &&
        // Only apply to "left" clicks
        event.button < 1 &&
        event.target instanceof Element
      ) {
        // Find the closest anchor parent matching the target
        const anchor = event.target.closest('a');

        if (
          // The anchor exists
          !!anchor &&
          // The anchor does not have a target attribute set to a value (not "", the default)
          !anchor.target &&
          // Ignore anchors with download intent
          !anchor.hasAttribute('download')
        ) {
          // First check: we can force local link to work as external with `data-external` attribute
          if (anchor.hasAttribute('data-external')) {
            return;
          }

          // Second check: Check matching hostname
          const state = store.getState();
          const host = state.getIn(['route', 'host']);
          if (host !== anchor.host) {
            return;
          }

          event.preventDefault();

          const path = `${anchor.pathname}${anchor.search}${anchor.hash}`;
          store.dispatch(routerPushLocation(path));
        }
      }
    });
  }
}

/**
 *
 * @param services
 * @param routes
 * @param defaultControllers
 * @param defaultLayout
 */
export function createRouterService(
  services: CommonServices,
  routes: Routes,
  defaultControllers?: Controller[],
  defaultLayout?,
) {
  const router = new RouterService(services, routes, defaultControllers, defaultLayout);

  return router;
}

export default createRouterService;

export function createMiddleware(services) {
  const {history} = services as BrowserServices;

  return store => next => action => {
    const currentUrl = history.location.pathname;

    if (action && action.type === ROUTER_PUSH_LOCATION) {
      history.push(embedLocation(action.payload, currentUrl));
    } else if (action && action.type === ROUTER_REPLACE_LOCATION) {
      history.replace(embedLocation(action.payload, currentUrl));
    }

    return next(action);
  };
}
