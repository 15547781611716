import React from 'react';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';

export const SidebarSectionHeading: React.FC = ({children}) => (
  <SubHeading
    fontSize="4" // TODO: Size based on theme?
    fontFamily="roboto"
    fontWeight="6"
    textTransform="uppercase"
    textAlign="center"
  >
    {children}
  </SubHeading>
);
