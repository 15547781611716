import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {PlayerCard, IPlayerCard} from 'fiba/wt/ui/playerCard/PlayerCard';

type IPlayerPage = {
  playerId: string;
  pt?: StyleProp<'pt'>;
};

type IPlayerPageRedux = IPlayerCard & {metaPaths: string[]};

export const mapStateToProps = (state, {playerId}: IPlayerPage): IPlayerPageRedux => {
  const player = state.getIn(['players', playerId]);
  const metaPaths = [`players/__meta/${playerId}`];

  return {player, metaPaths};
};

//
// Main Component with Loading

export const _PlayerPage: React.SFC<IPlayerPage & IPlayerPageRedux> = ({player, pt, metaPaths}) => (
  <Spacer vSpace="3" ph={['0', '3', '1']} pt={pt}>
    <Loading metaPaths={metaPaths}>{() => <PlayerCard player={player} />}</Loading>
  </Spacer>
);

//
// Connected export

export const PlayerPage = connect<IPlayerPageRedux, {}, IPlayerPage>(mapStateToProps)(_PlayerPage);
