import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import {EventActivity, EventActivityLike} from 'fiba/common/core/models/api/events/EventActivity';

export interface EventActivityStoreState extends Map<string, EventActivity> {
  // TODO: `__meta`
}

const reducers = {
  loadActivitySuccess: (
    state: EventActivityStoreState,
    activity: EventActivityLike,
  ): EventActivityStoreState =>
    state
      .set(activity.activityId, EventActivity.fromJS(activity))
      .updateIn(['__meta', activity.activityId], setMeta.isLoaded),

  loadActivitiesByEventSuccess: (
    state: EventActivityStoreState,
    eventId: string,
    activities: EventActivityLike[],
  ): EventActivityStoreState => activities.reduce(reducers.loadActivitySuccess, state),
};

export const reducer = createReducer<EventActivityStoreState>(__filename, fromJS({}), reducers);

const actions = createActions(__filename, reducers, {
  loadActivitiesByEvent: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadActivitiesByEvent(eventId)
      .then(res => actions.loadActivitiesByEventSuccess(eventId, res.data))
      .catch(rethrow((err: Error) => actions.loadActivitiesByEventError(eventId, err))),

  loadActivitiesByEventError: (eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<EventActivityStoreState>(EventActivity);
