// This list of weak passwords is not a bullet-proof list but is a good start
import weakPasswords from './weak_passwords.json';
import {PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH} from 'fiba/common/utils/constants';
// TODO: Translate error messages
export const password_error = {
  empty: 'Your password cannot be empty.',
  short: `Your password is too short. Your password must be at least ${PASSWORD_MIN_LENGTH} characters.`,
  long: `Your password is too long. Your password must be at most ${PASSWORD_MAX_LENGTH} characters.`,
  common:
    'Your password is not strong enough. Avoid common words, names, dates and repeating characters to secure your account.',
};

// Refuses password containing one character repeated more than 3 times (e.g. "aaaa")
// but "aaaa1" is valid
const isRepetitive = (password: string): boolean => {
  const matchedString = password.match(/(.)\1{3,}/g);
  return matchedString && password.length === matchedString[0].length;
};

// Password validation is done according some security points
// from https://stealthbits.com/blog/nist-password-guidelines/
export const validatePassword = (password: string): {isValid: boolean; errorMsg?: string} => {
  if (!password || password.length === 0) {
    return {isValid: false, errorMsg: password_error.empty};
  }
  if (password.length < PASSWORD_MIN_LENGTH) {
    return {isValid: false, errorMsg: password_error.short};
  }
  if (password.length > PASSWORD_MAX_LENGTH) {
    return {isValid: false, errorMsg: password_error.long};
  }
  // Ignore weak passwords with less than ${PASSWORD_MIN_LENGTH} and more than ${PASSWORD_MAX_LENGTH} chars; handled already above
  // Context-specific passwords (fiba-related passwords) are weak.
  // Weak passwords has a curated list of the top weak passwords of 2020.
  // Repetitive passwords of one character
  if (weakPasswords.includes(password) || isRepetitive(password)) {
    return {isValid: false, errorMsg: password_error.common};
  }
  return {isValid: true};
};
