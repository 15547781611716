import {ROUTER_ROUTE_CHANGED, isEmbed} from 'fiba/common/services/routerService';

// HACK to provide GA with type of widget.
function getScreenName(url: string) {
  if (/(live-scores)/.test(url)) {
    return 'Live-scores';
  }
  if (/(standings)/.test(url)) {
    return 'Standings';
  }
  if (/(categories[^/])|(register)|(team-edit)/.test(url)) {
    return 'Registration';
  }
  if (/(results)|(categories[/])|(schedules)|(team[^-])/.test(url)) {
    return 'Results';
  }
}

export const analyticsMiddleware = (
  gtag,
  trackingId: string,
  initialUrl: string,
  lang: string,
) => (/*store*/) => {
  if (!trackingId) {
    return next => next;
  }

  return next => action => {
    const {type, payload} = action;

    if (type === ROUTER_ROUTE_CHANGED) {
      gtag('event', 'page_view', {
        page_title: isEmbed(initialUrl) ? getScreenName(initialUrl) : payload.pathname,
        page_location: payload.pathname,
        language: (lang || '').toLowerCase(),
      });
    }

    return next(action);
  };
};

export default analyticsMiddleware;
