import {NotFoundError} from 'fiba/common/core/fibaError';
import {ISeasonConfig, ISiteConfig, Season} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Context} from 'koa';

interface RawPayload {
  season?: string;
  // Masters event short URL:
  eventSlug?: string;
  // Challenger short URL:
  challengerEventSlug?: string;
  // Super quest short URL:
  superQuestEventSlug?: string;
}

//The transformer function passes raw data through, i.e. merges the old with the new,
// therefore extending the RawPayload:
interface TransformedPayload extends RawPayload {
  season: string;
  eventId?: string;
  tourId?: string;
}
export type PayloadTransformer = (rawPayLoad: RawPayload) => TransformedPayload;

enum SeasonConfigEventTypes {
  Event = 'events',
  Challenger = 'challengers',
  SuperQuest = 'superQuests',
}

// finds the latest season that contains the eventSlug
const findLatestSeasonForEvent = (eventSlug: string, seasonConfig: ISeasonConfig) => {
  const [season] =
    Object.entries(seasonConfig.seasons)
      // sort descending by year
      .sort(([year1], [year2]) => parseInt(year2) - parseInt(year1))
      // find latest occurence
      .find(([_year, season]) => {
        return (
          !!season.events[eventSlug] ||
          !!season.challengers[eventSlug] ||
          !!season.superQuests[eventSlug]
        );
      }) || [];

  return season;
};

const isShortUrlForEventType = (
  eventType: SeasonConfigEventTypes,
  eventSlug: string,
  seasonConfig: ISeasonConfig,
) => {
  return !!Object.values(seasonConfig.seasons)
    // find latest occurence
    .find(season => !!season[eventType] && !!season[eventType][eventSlug]);
};

export const isUUID = id =>
  /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(id);

/**
 *  This is a function which returns another function that is used to to transform the parameters of the requested URL using the season config WT/WS/Cups URL (e.g. 'worltour.fiba3x3.com/:season/:event')
 *  Resolves the following:
 * - season (e. 2022)
 * - tour id if one exists for the season
 * - event id if it exists and it's not an UUID already
 *  Must handle 4 scenarios:
 * - there is no slug value (user is not on event-specific page, e.g '/2016/teams')
 * - the slug value is an uuid already (user is not using a short URL, e.g, '2016/challengers/0881d4a5-b7bb-4658-92d5-778acba7144e')
 * - there is a garbage slug value - must result in 404, 'worldtour.fiba3x3.com/2021/sassadsa'
 * - there is a legit slug value which maps to an event id for the season && slug (i.e '2016/utsonomiya')
 */

export function transformPayload(
  seasonConfig: ISeasonConfig,
  defaultSeason: Season,
): PayloadTransformer {
  return function (rawPayload: RawPayload) {
    const season = resolveSeason(seasonConfig, defaultSeason, rawPayload);
    const tourId = resolveTourIdForSeason(seasonConfig, season);
    const eventId = resolveEventId(seasonConfig, rawPayload, season);

    let params: TransformedPayload = {season};

    if (tourId) {
      params = {...params, tourId};
    }

    if (eventId) {
      params = {...params, eventId};
    }
    return {...rawPayload, ...params};
  };
}

/**
 *  Resolve the event id and tour id from URL of WT and WS URL (e.g. 'worltour.fiba3x3.com/:season/:event')
 *  using the season config.
 *  Must handle 4 scenarios:
 * - there is no slug value (user is not on event-specific page, e.g '/2016/teams')
 * - the slug value is an uuid already (user is not using a short URL, e.g, '2016/challengers/0881d4a5-b7bb-4658-92d5-778acba7144e')
 * - there is a garbage slug value - must result in 404, 'worldtour.fiba3x3.com/2021/sassadsa'
 * - there is a legit slug value which maps to an event id for the season && slug (i.e '2016/utsonomiya')
 */

export function resolveEventId(seasonConfig: ISeasonConfig, payload: RawPayload, season: string) {
  let possibleEventId: string | undefined = undefined;

  if (payload.eventSlug) {
    possibleEventId = resolveEventSlug(seasonConfig, season, payload.eventSlug);
  } else if (payload.challengerEventSlug) {
    possibleEventId = resolveChallengerEventSlug(seasonConfig, season, payload.challengerEventSlug);
  } else if (payload.superQuestEventSlug) {
    possibleEventId = resolveSuperQuestEventSlug(seasonConfig, season, payload.superQuestEventSlug);
  }

  // garbage slug value:
  if (possibleEventId && !isUUID(possibleEventId)) {
    throw new NotFoundError('404');
  }
  return possibleEventId;
}

/**
 * Try to find matching eventId for the slug:
 * If no match, pass-through because the id might be resolved already if the URL points to the event id, i.e '2021/some-actual-event-uuid').
 */

const resolveEventSlug = (seasonConfig: ISeasonConfig, season: string, slug: string) => {
  const seasonMap = seasonConfig.seasons[season];
  return seasonMap && (seasonMap.events[slug.toLowerCase()] || slug);
};

//
// Challenger Event Slug

/**
 * Try to find matching eventId for the challenger event slug/id.
 * If no match, pass-through.
 */
const resolveChallengerEventSlug = (seasonConfig: ISeasonConfig, season: string, slug: string) => {
  const seasonMap = seasonConfig.seasons[season];
  return seasonMap && (seasonMap.challengers[slug.toLowerCase()] || slug);
};

//
// SuperQuest Event Slug

/**
 * Try to find matching eventId for the superQuest event slug/id.
 * If no match, pass-through.
 */
const resolveSuperQuestEventSlug = (seasonConfig: ISeasonConfig, season: string, slug: string) => {
  const seasonMap = seasonConfig.seasons[season];
  return seasonMap && (seasonMap.superQuests[slug.toLowerCase()] || slug);
};

export const resolveSeason = (
  seasonConfig: ISeasonConfig,
  defaultSeason: Season,
  rawPayload: RawPayload,
) => {
  const acceptedSeasons = Object.keys(seasonConfig.seasons);

  if (acceptedSeasons.includes(rawPayload.season)) {
    return rawPayload.season;
  }

  // Deduce the latest season if we got a shorturl without season
  if (rawPayload.eventSlug) {
    const season = findLatestSeasonForEvent(rawPayload.eventSlug, seasonConfig);

    if (season) {
      return season;
    }
  }

  // user is requesting a non-existent season, e.g. 'worltour.fiba3x3.com/adsds', return default season 404 will be handled later:
  return defaultSeason;
};

export const resolveTourIdForSeason = (seasonConfig: ISeasonConfig, season: string) => {
  const seasonMap = seasonConfig.seasons[season];

  return seasonMap?.tourId;
};

export const latestShortUrlRedirect = (
  eventSlug: string,
  seasonConfig: ISeasonConfig,
  defaultSeason: Season,
) => {
  const season = resolveSeason(seasonConfig, defaultSeason, {season: 'latest', eventSlug});

  // Event short urls take precedence before challengers and superQuests
  const isEventShortUrl = isShortUrlForEventType(
    SeasonConfigEventTypes.Event,
    eventSlug,
    seasonConfig,
  );

  //challengers before superquests
  const challengerPrefix =
    isShortUrlForEventType(SeasonConfigEventTypes.Challenger, eventSlug, seasonConfig) &&
    // ^check previous
    !isEventShortUrl
      ? `/${SeasonConfigEventTypes.Challenger}`
      : '';
  //lastly superquests
  const superQuestPrefix =
    isShortUrlForEventType(SeasonConfigEventTypes.SuperQuest, eventSlug, seasonConfig) &&
    // ^check previous
    !isEventShortUrl
      ? `/${SeasonConfigEventTypes.SuperQuest.toLowerCase()}`
      : '';

  return `/${season}${challengerPrefix || superQuestPrefix}/${eventSlug}`;
};

export const handleRedirects = (
  ctx: Context,
  seasonConfig: ISeasonConfig,
  defaultSeason: Season,
  latestRedirectSlugs: string[],
): string | null => {
  const pathParts = ctx.request.path.split('/').filter(x => !!x);

  // Check if we need to find the latest season (i.e. there is no year specified in the slug)
  // Years are specified as _exactly_ 4 characters, so if our first slug element matches that - it's a year and so we don't need to lookup the latest season.
  if (pathParts.length === 1 && !pathParts[0].match(/^\d\d\d\d$/)) {
    const eventSlug = pathParts[0];

    if (latestRedirectSlugs.includes(eventSlug)) {
      return `/${defaultSeason}${ctx.request.search || ''}`;
    }

    return `${latestShortUrlRedirect(eventSlug, seasonConfig, defaultSeason)}${
      ctx.request.search || ''
    }`;
  }

  return null;
};

// TODO: Add a test for this
// Get the event short name for the
export const mapEventIdToShortName = (
  payload: ReturnType<PayloadTransformer>,
  seasonConfig: ISeasonConfig,
): string | null => {
  const {
    season,
    eventId: resolvedEventId,
    eventSlug,
    challengerEventSlug,
    superQuestEventSlug,
  } = payload;
  const eventIdFromSlug = eventSlug || challengerEventSlug || superQuestEventSlug;

  // if the resolved event id is the same as the eventSlug from the URL,
  // we know that the user came to the site via long URL:
  const accessedViaLongUrl = resolvedEventId && resolvedEventId === eventIdFromSlug;

  if (!seasonConfig || !accessedViaLongUrl) {
    return null;
  }

  const seasonData = seasonConfig.seasons[season] || {};
  const shortUrls = {
    ...seasonData['events'],
    ...seasonData['challengers'],
    ...seasonData['superQuests'],
  };

  const shortUrl = Object.entries(shortUrls).find(
    ([_shortName, eventId]) => resolvedEventId === eventId,
  );

  return shortUrl ? shortUrl[0] : null;
};

export const isNt = (siteConfig: ISiteConfig) =>
  ['cups', 'nationsleague'].includes(siteConfig.features.siteId);
