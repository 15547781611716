import React, {useState} from 'react';
import {List} from 'immutable';
import {Photo} from 'fiba/common/core/models/api/media/Photo';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {PhotoGalleryItem} from 'fiba/wt/ui/photoGallery/PhotoGalleryItem';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Lightbox} from 'fiba/wt/ui/lightbox/Lightbox';
import {ImgixImage} from 'fiba/wt/ui/imgix/ImgixImage';

interface PhotoGalleryProps {
  title?: string;
  photos: List<Photo>;
}

// Image patterns
interface ImagePattern {
  aspectRatio: StyleProp<'aspectRatio'>;
  className: string;
}
const regularImage: ImagePattern = {aspectRatio: '6x4', className: null};
const tallImage: ImagePattern = {aspectRatio: '4x6', className: 'row-span-2'};
const pattern = [regularImage, tallImage, regularImage, regularImage, tallImage];
const patternLength = pattern.length;

/** Photo Gallery list/grid */
type LightboxState = {open: false} | {open: true; index: number};

export const PhotoGallery: React.FunctionComponent<PhotoGalleryProps> = ({title, photos}) => {
  // Hello friend! If in the future you need to re-use the lightbox implementation,
  // either copy-paste this pattern here, or maybe create a `useLightbox`
  // For example, const {Lightbox, setLightboxState} = useLightbox({open: false})
  // and then return (... <Lightbox getApplicationNode={...}>/* same as before */</Lightbox>)
  const [lightboxState, setLightboxState] = useState<LightboxState>({open: false});
  return (
    <div className="PhotoGallery">
      {!!title && <Heading>{title}</Heading>}

      <ul className="grid gt-gallery">
        {photos.map((photo, index) => {
          const imagePattern = pattern[index % patternLength];
          return (
            <li key={photo.id} className={imagePattern.className}>
              <div className="relative fill-cell">
                <PhotoGalleryItem
                  photo={photo}
                  onInteraction={() => setLightboxState({open: true, index: index})}
                  aspectRatio={imagePattern.aspectRatio}
                  // Sizes: 100vh, 100vh, between 12rem and 24rem, 24rem
                  // This is eyeballing between the tablet-desktop viewport; could use max(..., ...)
                  sizes={['100vh', null, '18rem', '24rem']}
                />
              </div>
            </li>
          );
        })}
      </ul>

      {/* The lightbox renders in a portal, instead of below the ul.
       * This allows us to control it here.
       */}
      <Lightbox
        isOpen={lightboxState.open}
        initialSlide={lightboxState.open && lightboxState.index}
        onClose={() => setLightboxState({open: false})}
        getApplicationNode={() => document.getElementsByClassName('.FibaPlayApp')[0] as HTMLElement}
      >
        {photos.map(photo => (
          <ImgixImage
            key={photo.id}
            baseUrl={photo.imageBaseUrl}
            // Sizes: 100vh - 2 * lightbox-padding (0.5rem) - 2 * lightbox-button-width (2 * 32)
            sizes={['calc(100vh - 2 * 0.5rem - 2 * 32px)']}
            // FIXME: most of these descriptions do not actually describe these image :(
            alt={photo.description ? photo.description : ''}
          />
        ))}
      </Lightbox>
    </div>
  );
};
