import {ContentfulSingleSeasonConfig} from 'fiba/wt/stores/contentStore';
import {ISiteConfig, ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {merge} from 'lodash';

export function mergeSeasonConfigs(
  contentfulSeasonConfigs: ContentfulSingleSeasonConfig[],
  siteConfig: ISiteConfig,
): ISeasonConfig {
  const {seasonConfigOverrides} = siteConfig;

  // filter out configs that don't belong to this site
  const seasons = contentfulSeasonConfigs
    .filter(csc => csc.fields.site === siteConfig.features.siteId)
    .reduce<ISeasonConfig>(
      (acc: ISeasonConfig, csc: ContentfulSingleSeasonConfig) => {
        const {fields} = csc;

        if (!fields) {
          return acc;
        }

        const {
          season,
          tourId,
          challengerShortUrls: challengers,
          mainShortUrls: events,
          superQuestShortUrls: superQuests,
          showStatsForSeason,
        } = fields;

        // merge the accumulator along with the "incoming" config;
        // then merge the result with the "override" config aka seasonConfig.tsx
        const result = merge(
          acc,
          {
            seasons: {
              [season]: {
                tourId,
                showStatsForSeason,
                events: {...mapToShortUrlsToSeasonConfigFormat(events)},
                challengers: {
                  ...mapToShortUrlsToSeasonConfigFormat(challengers),
                },
                superQuests: {
                  ...mapToShortUrlsToSeasonConfigFormat(superQuests),
                },
              },
            },
          },
          // the "override" config. Currently empty for all sites except WT
          {seasons: {[season]: seasonConfigOverrides?.seasons?.[season]}},
        );

        return result;
      },
      {seasons: {}},
    );

  return seasons;
}

function mapToShortUrlsToSeasonConfigFormat(
  events: ContentfulSingleSeasonConfig['fields']['mainShortUrls'],
): Record<string, string> {
  if (!Array.isArray(events)) {
    return {};
  }

  return events.reduce((acc, {fields}) => {
    // If a published seasonConfig has draft short urls linked, fields might be undefined
    if (fields && fields.shortName) {
      acc[fields.shortName.toLowerCase()] = fields.eventId;
    }

    return acc;
  }, {});
}
