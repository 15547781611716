import React from 'react';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {TourStatisticsDownloads} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsDownloads';
import {TourStatisticsSummary} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsSummary';
import {TourStatisticsFastestGames} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsFastestGames';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {tourStatisticsLinkProvider} from 'fiba/wt/utils/linkUtils';
import {TourStatisticsTeamsTab} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsTeamsTab';
import {TourStatisticsPlayersTab} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsPlayersTab';

export type TourStatisticsTab = 'summary' | 'downloads' | 'fastest-games' | 'teams' | 'players';

export interface ITourStatisticsPage {
  tourId: string;
  season: string;
  eventId: string;
  primaryTab: TourStatisticsTab;
}

function getTourStatisticsTab({primaryTab, ...props}: ITourStatisticsPage) {
  switch (primaryTab) {
    case 'downloads':
      return <TourStatisticsDownloads {...props} />;
    case 'summary':
      return <TourStatisticsSummary {...props} />;
    case 'fastest-games':
      return <TourStatisticsFastestGames {...props} />;
    case 'teams':
      return <TourStatisticsTeamsTab {...props} />;
    case 'players':
      return <TourStatisticsPlayersTab {...props} />;
    default:
      return null;
  }
}

export const TourStatisticsPage: React.FC<ITourStatisticsPage> = (props: ITourStatisticsPage) => {
  const headingId = 'tour-stats-heading';
  return (
    <Spacer vSpace={['4', '4', '5']}>
      <Spacer vSpace="3">
        <Tabs
          level="nested"
          navigationLabeledBy={headingId}
          baseUrl={`${tourStatisticsLinkProvider(props.season)}/world-tour`}
          baseUrlAlias={`${tourStatisticsLinkProvider(props.season)}`}
          extraClassName="TourStatisticsPage-Tabs"
        >
          <Tab href="/" hrefAliases={['/summary']}>
            Summary
          </Tab>
          <Tab href="/teams">Teams</Tab>
          <Tab href="/players">Players</Tab>
          <Tab href="/fastest-games">Fastest games</Tab>
          <Tab href="/downloads">Downloads</Tab>
        </Tabs>
      </Spacer>
      <Section>
        <Box
          debugClassName="TourStatisticsPage-Content"
          // Using `mt` gets overriden by media query (see link) and `marginTop` seems to fix the override issue
          // https://github.com/futurice/fiba-3x3-play/blob/d3445ba4ce6a779e03593a36ae4fae2317888937/fiba/wt/ui/stylingAPI/spacing.scss#L132
          style={{marginTop: '1rem'}}
        >
          {getTourStatisticsTab(props)}
        </Box>
      </Section>
    </Spacer>
  );
};
