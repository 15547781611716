import React, {useContext} from 'react';
import {List} from 'immutable';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {getPoolNameLong} from 'fiba/common/core/results';
import {ServicesContext} from 'fiba/common/services/services';
import {Tabs, Tab, TabPanels, TabPanel} from 'fiba/wt/ui/tabs/Tabs';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {ResourceLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {PoolStandingsSummarySubTab} from 'fiba/wt/ui/eventStandingsPage/PoolStandingsSummarySubTab';
import {PoolStandingsFullSubTab} from 'fiba/wt/ui/eventStandingsPage/PoolStandingsFullSubTab';

interface IPoolStandingsTab {
  season: string;
  eventId: string;
  eventType: EventType;
  pools: List<ResultsGroup>;
  title: string;
  createPoolHref: ResourceLinkProvider;
}

export const PoolStandingsTab: React.FunctionComponent<IPoolStandingsTab> = ({
  season,
  eventId,
  pools,
  title,
  createPoolHref,
  eventType,
}) => {
  const {localizer} = useContext(ServicesContext);
  return (
    <Spacer vSpace="4">
      <Tabs level="nested" navigationLabel="Pools" baseUrl={createPoolHref('')}>
        <Tab href="/">Overview</Tab>

        {pools.map(pool => (
          <Tab key={pool.groupId} href={`/${pool.groupId}`}>
            {getPoolNameLong(
              pool.get('roundCode'),
              pool.get('groupPoolCode'),
              pool.get('roundNumber'),
              localizer,
            )}
          </Tab>
        ))}
      </Tabs>

      <TabPanels baseUrl={createPoolHref('')}>
        <TabPanel href="/">
          <PoolStandingsSummarySubTab
            season={season}
            eventId={eventId}
            eventType={eventType}
            pools={pools}
            title={title}
            createPoolHref={createPoolHref}
          />
        </TabPanel>

        {pools.map(pool => (
          <TabPanel key={pool.groupId} href={`/${pool.groupId}`}>
            <PoolStandingsFullSubTab
              season={season}
              eventId={eventId}
              eventType={eventType}
              pool={pool}
            />
          </TabPanel>
        ))}
      </TabPanels>
    </Spacer>
  );
};
