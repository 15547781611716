import {fromJS, Map} from 'immutable';
import {createReducer, createActions, resolvePathWithGender} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {TopScorer, TopScorerLike} from 'fiba/common/core/models/api/stats/TopScorer';
import {mapValues} from 'lodash';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';

export interface StoreState extends Map<string, TopScorerLike[]> {}

const reducers = {
  loadStatsTopScorers: (
    state: StoreState,
    eventId: string,
    _categoryId: string,
    gender: CategoryGenderTab,
  ): StoreState =>
    state.updateIn(['__meta', ...resolvePathWithGender(eventId, gender)], setMeta.isLoading),

  loadTopScorerStatSuccess: (
    state: StoreState,
    eventId: string,
    topScorers: TopScorerLike[],
    gender?: CategoryGenderTab,
  ): StoreState => {
    const eventPath = resolvePathWithGender(eventId, gender);
    return state.setIn(eventPath, topScorers).updateIn(['__meta', ...eventPath], setMeta.isLoaded);
  },

  loadTopScorerStatsError: (
    state: StoreState,
    eventId: string,
    _error: Error,
    gender?: CategoryGenderTab,
  ): StoreState =>
    state.updateIn(['__meta', ...resolvePathWithGender(eventId, gender)], setMeta.isError),
};

export const reducer = createReducer<StoreState>(__filename, fromJS({}), reducers);

export const actions = createActions(__filename, reducers, {
  loadStatsTopScorers: (eventId: string, categoryId: string, gender: CategoryGenderTab) => ({
    apiClient,
  }: CommonServices) =>
    apiClient
      .loadTopScorersStats(categoryId)
      .then(response => actions.loadTopScorerStatSuccess(eventId, response.data.topScorers, gender))
      .catch(rethrow((err: Error) => actions.loadTopScorerStatsError(eventId, err, gender))),
});

export function deserializeState(state): StoreState {
  const {__meta, ...eventTopScorerStats} = state;
  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail on chrome
    __meta: __meta || {},
    ...mapValues(eventTopScorerStats, (eventStatsItem, id: string) =>
      eventStatsItem.map(TopScorer.fromJS),
    ),
  });
}
