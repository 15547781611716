import {Map} from 'immutable';
import {CustomForm} from 'fiba/common/core/models/api/customForms/CustomForm';
import {
  isCustomFormQuestion,
  CustomFormElement,
} from 'fiba/common/core/models/api/customForms/CustomFormElement';
import CustomFormPlayerAnswers from 'fiba/common/core/models/api/customForms/CustomFormPlayerAnswers';
import CustomFormTeamAnswers from 'fiba/common/core/models/api/customForms/CustomFormTeamAnswers';

export enum CustomFormType {
  Player = 'PLAYER',
  Team = 'TEAM',
}

/*
  See https://github.com/futurice/fiba-3x3-custom-forms/blob/master/src/const.ts#L14 for reference
*/
export enum CustomFormElementType {
  Divider = 'DIVIDER',
  FreeText = 'FREETEXT',
  Heading = 'HEADING',
  Options = 'OPTIONS',
  Number = 'NUMBER',
  JerseyNumber = 'JERSEY_NUMBER',
  Link = 'LINK',
}

export enum CustomFormSelectionType {
  Checkbox = 'CHECKBOX',
  Radio = 'RADIO',
  Select = 'SELECT',
}

export const mapFieldAnswersToCustomFormQuestions = (
  fields: Map<string, any>,
  customForm: CustomForm,
) => {
  const customFormData = customForm.segments
    .map(seg =>
      seg.elements.map(el => {
        if (isCustomFormQuestion(el) && fields.get(el.name)) {
          return {
            questionId: el.id,
            value: fields.get(el.name),
          };
        }
      }),
    )
    .flatten()
    .filter(el => el);
  return customFormData;
};

export const stripCustomFormFieldsFromForm = (fields: Map<string, any>, customForm: CustomForm) => {
  const customFormQuestions = customForm.segments
    .map(seg =>
      seg.elements.map(el => {
        if (isCustomFormQuestion(el)) {
          return el.name;
        }
      }),
    )
    .flatten()
    .filter(el => el);
  return fields.filter((_, key) => {
    return !customFormQuestions.includes(key);
  });
};

export const getExistingAnswerForCustomFormElement = (
  answers: CustomFormPlayerAnswers | CustomFormTeamAnswers | undefined,
  element: CustomFormElement,
) => answers && answers.answers.find(answer => answer.questionId === element.id);
