/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultGalleryPreview = {
  title: null,
  eventId: null,
  galleryId: null,
  imageUrl: null,
  total: null,
};
export const defaultTourGalleryPreviews = {tourId: null, galleryPreviews: null};
export const defaultEventGalleryPreviews = {eventId: null, galleryPreviews: null};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type GalleryPreviewLike = {
  [K in keyof typeof defaultGalleryPreview]?: GalleryPreview[K];
};
export type TourGalleryPreviewsLike = {
  [K in keyof typeof defaultTourGalleryPreviews]?: TourGalleryPreviews[K];
};
export type EventGalleryPreviewsLike = {
  [K in keyof typeof defaultEventGalleryPreviews]?: EventGalleryPreviews[K];
};

export class GalleryPreview extends Record(defaultGalleryPreview) {
  title?: string;
  eventId?: string;
  galleryId?: string;
  imageUrl?: string;
  total?: number;
  static fromJS(o: GalleryPreviewLike): GalleryPreview {
    return (
      o &&
      new GalleryPreview({
        title: o.title,
        eventId: o.eventId,
        galleryId: o.galleryId,
        imageUrl: o.imageUrl,
        total: o.total,
      })
    );
  }
}
export class TourGalleryPreviews extends Record(defaultTourGalleryPreviews) {
  tourId?: string;
  galleryPreviews?: List<GalleryPreview>;
  static fromJS(o: TourGalleryPreviewsLike): TourGalleryPreviews {
    return (
      o &&
      new TourGalleryPreviews({
        tourId: o.tourId,
        galleryPreviews:
          o.galleryPreviews && List(o.galleryPreviews).map(i => i && GalleryPreview.fromJS(i)),
      })
    );
  }
}
export class EventGalleryPreviews extends Record(defaultEventGalleryPreviews) {
  eventId?: string;
  galleryPreviews?: List<GalleryPreview>;
  static fromJS(o: EventGalleryPreviewsLike): EventGalleryPreviews {
    return (
      o &&
      new EventGalleryPreviews({
        eventId: o.eventId,
        galleryPreviews:
          o.galleryPreviews && List(o.galleryPreviews).map(i => i && GalleryPreview.fromJS(i)),
      })
    );
  }
}

export default GalleryPreview;
