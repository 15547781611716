import React from 'react';

import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';
import githubSchema from 'hast-util-sanitize/lib/github.json';

/**
 * A sanitization schema extending Github's,
 * that allows `<picture>` and `<source>`, since
 * we use those in our extensions.
 */
export const sanitizationSchema = {
  ...githubSchema,
  attributes: {
    ...githubSchema.attributes,
    source: ['src', 'srcset', 'sizes'],
    img: [...githubSchema.attributes.img, 'src', 'srcset', 'sizes', 'loading', 'class'],
  },
  tagNames: [...githubSchema.tagNames, 'picture', 'source'],
};

/**
 * Safely render a block of markdown to HTML.
 *
 * @example
 * import {Markdown} from 'fiba/common/ui/markdown/Markdown';
 * <Markdown content={translations.get('TRANSLATION_KEY')} />
 */
export const Markdown = ({content, ...props}) => (
  <div
    {...props}
    dangerouslySetInnerHTML={{
      __html: unified()
        .use(markdown)
        .use(html, {
          sanitize: sanitizationSchema,
        })
        .processSync(content)
        .toString(),
    }}
  />
);
