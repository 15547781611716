import {LocalizerService} from 'fiba/common/services/services';
import Player from 'fiba/common/core/models/api/players/Player';

export enum PlayerStatusInTeam {
  'Pending' = 'Pending',
  'Accepted' = 'Accepted',
  'WaitList' = 'WaitList',
  'Rejected' = 'Rejected',
}

export const isPlayerStatusPending = (player: Player) =>
  player.statusInTeam === PlayerStatusInTeam.Pending;

export const isPlayerStatusAccepted = (player: Player) =>
  player.statusInTeam === PlayerStatusInTeam.Accepted;

export const localiseUpDownGradeReason = (reason: string, localizer: LocalizerService) => {
  // This is a bit ugly, but it allows us to check translations statically
  switch (reason) {
    case 'PrizeMoney': // Deprecated
      return localizer.formatKey('EVENT_UPDOWNGRADE_PRIZE_MONEY');

    case 'NonWTCategoryInQuest': // Deprecated
      return localizer.formatKey('EVENT_UPDOWNGRADE_NON_WT_CATEGORY_IN_QUEST');

    case 'LargeTourFinal':
      return localizer.formatKey('EVENT_UPDOWNGRADE_LARGE_TOUR_FINAL');

    case 'IncompleteRegistrationData':
      return localizer.formatKey('EVENT_UPDOWNGRADE_INCOMPLETE_REGISTRATION_DATA');

    case 'NationalTeamAppearsMoreThanOnce':
      return localizer.formatKey('EVENT_UPDOWNGRADE_NATIONAL_TEAM_APPEARS_MORE_THAN_ONCE');

    case 'RecreationalCategory': // Deprecated
      return localizer.formatKey('EVENT_UPDOWNGRADE_RECREATIONAL_CATEGORY');

    case 'FemaleCategory':
      return localizer.formatKey('EVENT_UPDOWNGRADE_FEMALE_CATEGORY');

    case 'SmallSizedDivision':
      return localizer.formatKey('EVENT_UPDOWNGRADE_SMALL_SIZED_DIVISION');

    case 'MiniSizedDivision':
      return localizer.formatKey('EVENT_UPDOWNGRADE_MINI_SIZED_DIVISION');

    case 'IncompleteScoringData':
      return localizer.formatKey('EVENT_UPDOWNGRADE_INCOMPLETE_SCORING_DATA');

    case 'EliteCategory':
      return localizer.formatKey('EVENT_UPDOWNGRADE_ELITE_CATEGORY');

    default:
      return localizer.formatKey('EVENT_UPDOWNGRADE_UNKNOWN');
  }
};
