import * as Immutable from 'immutable';
import {combineReducers} from 'redux-immutable';

import * as routerService from 'fiba/common/services/routerService';
import * as contentStore from 'fiba/wt/stores/contentStore';
// FIXME: circular dependency at the moment:
// In the future, we plan to split up this repo and make a common directory to contain a template for all websites (excluding Play)
import * as cupsStore from 'fiba/cups/store';
import * as nationsLeagueStore from 'fiba/nationsleague/store';
import * as conferenceStore from 'fiba/nationsleague/conferenceStore';
import * as tourStore from 'fiba/wt/stores/tourStore';
import * as tourResultStore from 'fiba/wt/stores/tourResultStore';
import * as tourTeamStore from 'fiba/wt/stores/tourTeamStore';
import * as proCircuitTourTeamStore from 'fiba/wt/stores/proCircuitTourTeamStore';
import * as tourTeamResultsStore from 'fiba/wt/stores/tourTeamResultsStore';
import * as teamRankingsStore from 'fiba/wt/stores/teamRankingsStore';
import * as eventStore from 'fiba/wt/stores/eventStore';
import * as eventActivityStore from 'fiba/wt/stores/eventActivityStore';
import * as categoryStore from 'fiba/wt/stores/categoryStore';
import * as resultStore from 'fiba/wt/stores/resultStore';
import * as teamStore from 'fiba/wt/stores/teamStore';
import * as playerStore from 'fiba/wt/stores/playerStore';
import * as gameStore from 'fiba/wt/stores/gameStore';
import * as gameStatsStore from 'fiba/wt/stores/gameStatsStore';
import * as statsStore from 'fiba/wt/stores/statsStore';
import * as teamTourStatStore from 'fiba/wt/stores/teamStatsStore';
import * as proCircuitTeamTourStatStore from 'fiba/wt/stores/proCircuitTeamStatsStore';
import * as playerStatStore from 'fiba/wt/stores/playerStatsStore';
import * as topScorerStatsStore from 'fiba/wt/stores/topScorerStatsStore';
import * as proCircuitPlayerStatStore from 'fiba/wt/stores/proCircuitPlayerStatsStore';
import * as newsStore from 'fiba/wt/stores/newsStore';
import * as mediaStore from 'fiba/wt/stores/mediaStore';
import * as qualificationsStore from 'fiba/wt/stores/qualificationsStore';
import * as uiConfigStore from 'fiba/common/stores/uiConfigStore';
import {Reducer} from 'redux';

// Create an Immutable Record with the known keys
// Records only allow you assign to a known key, which makes type-checking very resilient
// Records also establish getters (rootStore.thing), which improves compatibility
// In order to use Records with combineReducers, we must:
//  1. Create a static type for the outer Record
//  2. Create a static type for the key-value pairs
//  3. Create a *runtime* template Record, that defines keys
//  4. Initialise the rootStore with the Record

// 2. Add fields to the root store state for the key-value pairs of state
const templateRootStoreKV = {
  route: undefined,
  content: undefined,
  cupEvents: undefined,
  conferences: undefined,
  nationsLeagueEvents: undefined,
  tours: undefined,
  tourResults: undefined,
  tourTeams: undefined,
  proCircuitTourTeams: undefined,
  tourTeamResults: undefined,
  [teamRankingsStore.storePath]: undefined,
  events: undefined,
  eventActivities: undefined,
  categories: undefined,
  results: undefined,
  [teamStore.storePath]: undefined,
  players: undefined,
  games: undefined,
  gameStats: undefined,
  stats: undefined,
  teamStats: undefined,
  proCircuitTeamStats: undefined,
  topScorerStats: undefined,
  playerStats: undefined,
  proCircuitPlayerStats: undefined,
  qualifications: undefined,
  news: undefined,
  media: undefined,
  uiConfig: undefined,
  translations: undefined,
  lang: undefined,
};

export class RootState extends Immutable.Record(templateRootStoreKV) {
  // 1. The composed root-level static type
  route: routerService.State;
  content: contentStore.ContentStoreState;
  cupEvents: cupsStore.StoreState;
  nationsLeagueEvents: nationsLeagueStore.StoreState;
  conferences: conferenceStore.ConferenceStoreState;
  tours: tourStore.TourStoreState;
  tourResults: tourResultStore.TourResultsStoreState;
  tourTeams: tourTeamStore.TourTeamStoreState;
  proCircuitTourTeams: proCircuitTourTeamStore.ProCircuitTourTeamStoreState;
  tourTeamResults: tourTeamResultsStore.TourTeamResultsStoreState;
  [teamRankingsStore.storePath]: teamRankingsStore.State;
  events: eventStore.EventStoreState;
  eventActivities: eventActivityStore.EventActivityStoreState;
  categories: categoryStore.CategoryStoreState;
  results: resultStore.ResultStoreState;
  [teamStore.storePath]: teamStore.TeamStoreState;
  players: playerStore.PlayerStoreState;
  games: gameStore.GameStoreState;
  gameStats: gameStatsStore.GameStatsStoreState;
  stats: statsStore.StatsStoreState;
  topScorerStats: topScorerStatsStore.StoreState;
  [teamTourStatStore.storePath]: teamTourStatStore.TeamStatsStore;
  [proCircuitTeamTourStatStore.storePath]: proCircuitTeamTourStatStore.ProCircuitTeamStatsStore;
  [playerStatStore.storePath]: playerStatStore.PlayerStatsStore;
  [proCircuitPlayerStatStore.storePath]: proCircuitPlayerStatStore.ProCircuitPlayerStatsStore;
  qualifications: qualificationsStore.State;
  news: newsStore.NewsStoreState;
  media: mediaStore.MediaStoreState;
  uiConfig: any;
  translations: Immutable.Map<string, string>;
  lang: string;
}

// 3. Tell Immutable to create a template Record with known keys, that we can pass to combineReducers
const TemplateRootStoreState = new RootState(templateRootStoreKV);

// 4. Finally, initialise the rootStore with the real runtime reducers
// TODO: Name this rootReducer
export const rootReducer: Reducer<RootState> = combineReducers(
  {
    route: routerService.RouterService.reducer,
    content: contentStore.reducer,
    cupEvents: cupsStore.reducer,
    nationsLeagueEvents: nationsLeagueStore.reducer,
    conferences: conferenceStore.reducer,
    tours: tourStore.reducer,
    tourResults: tourResultStore.reducer,
    tourTeams: tourTeamStore.reducer,
    proCircuitTourTeams: proCircuitTourTeamStore.reducer,
    tourTeamResults: tourTeamResultsStore.reducer,
    [teamRankingsStore.storePath]: teamRankingsStore.reducer,
    events: eventStore.reducer,
    eventActivities: eventActivityStore.reducer,
    categories: categoryStore.reducer,
    results: resultStore.reducer,
    [teamStore.storePath]: teamStore.reducer,
    players: playerStore.reducer,
    games: gameStore.reducer,
    gameStats: gameStatsStore.reducer,
    stats: statsStore.reducer,
    topScorerStats: topScorerStatsStore.reducer,
    [teamTourStatStore.storePath]: teamTourStatStore.reducer,
    [proCircuitTeamTourStatStore.storePath]: proCircuitTeamTourStatStore.reducer,
    [playerStatStore.storePath]: playerStatStore.reducer,
    [proCircuitPlayerStatStore.storePath]: proCircuitPlayerStatStore.reducer,
    qualifications: qualificationsStore.reducer,
    news: newsStore.reducer,
    media: mediaStore.reducer,
    uiConfig: uiConfigStore.reducer,
    translations: (state: Immutable.Map<string, string>) => state,
    lang: (state: string) => state,
  },
  () => TemplateRootStoreState as any,
) as Reducer<RootState>;

// 5. A JSON -> Immutable JS implementation, that delegates the slices
// to each reducer
export function deserializeState(state: any): RootState {
  const {
    cupEvents,
    nationsLeagueEvents,
    conferences,
    tours,
    tourResults,
    tourTeams,
    proCircuitTourTeams,
    tourTeamResults,
    teamRankings,
    events,
    eventActivities,
    categories,
    results,
    teams,
    players,
    games,
    gameStats,
    stats,
    news,
    media,
    qualifications,
    content,
    lang,
    topScorerStats,
    playerStats,
    proCircuitPlayerStats,
    teamStats,
    proCircuitTeamStats,
    route,
    translations,
    uiConfig,
  } = state;

  // Create the root store state, by calling out to each of the sub-reducers to
  // initialise their part
  const storeState = new RootState({
    cupEvents: cupsStore.deserializeState(cupEvents),
    nationsLeagueEvents: nationsLeagueStore.deserializeState(nationsLeagueEvents),
    conferences: conferenceStore.deserializeState(conferences),
    tours: tourStore.deserializeState(tours),
    tourResults: tourResultStore.deserializeState(tourResults),
    tourTeams: tourTeamStore.deserializeState(tourTeams),
    proCircuitTourTeams: proCircuitTourTeamStore.deserializeState(proCircuitTourTeams),
    tourTeamResults: tourTeamResultsStore.deserializeState(tourTeamResults),
    [teamRankingsStore.storePath]: teamRankingsStore.deserializeState(teamRankings),
    events: eventStore.deserializeState(events),
    eventActivities: eventActivityStore.deserializeState(eventActivities),
    categories: categoryStore.deserializeState(categories),
    results: resultStore.deserializeState(results),
    [teamStore.storePath]: teamStore.deserializeState(teams),
    players: playerStore.deserializeState(players),
    games: gameStore.deserializeState(games),
    gameStats: gameStatsStore.deserializeState(gameStats),
    stats: statsStore.deserializeState(stats),
    news: newsStore.deserializeState(news),
    media: mediaStore.deserializeState(media),
    qualifications: qualificationsStore.deserializeState(qualifications),
    content: Immutable.fromJS(content),
    lang: Immutable.fromJS(lang),
    topScorerStats: topScorerStatsStore.deserializeState(topScorerStats),
    playerStats: playerStatStore.deserializeState(playerStats),
    proCircuitPlayerStats: proCircuitPlayerStatStore.deserializeState(proCircuitPlayerStats),
    route: Immutable.fromJS(route),
    teamStats: teamTourStatStore.deserializeState(teamStats),
    proCircuitTeamStats: proCircuitTeamTourStatStore.deserializeState(proCircuitTeamStats),
    translations: Immutable.fromJS(translations),
    uiConfig: Immutable.fromJS(uiConfig),
  });
  return storeState;
}
