import {superstyled, SuperStyled, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';

export type CardStyleProps = SuperStyled<
  | 'fontSize'
  | 'fontWeight'
  | 'color'
  | 'bgColor'
  | 'opacity'
  | 'display'
  | 'align'
  | 'textAlign'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'measure'
  | 'shadow'
  | 'position'
  | 'border'
  | 'borderRadius'
  | 'borderWidth'
  | 'borderColor'
  | 'ma'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'flex'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexOrder'
  | 'alignSelf'
  | 'flexDirection'
  | 'flexWrap'
  | 'alignItems'
  | 'alignContent'
  | 'justifyContent'
>;

// Common props in every card-like component
const defaultCardProps: CardStyleProps = {
  bgColor: 'fullwhite',
  shadow: '1',
  pa: ['3', '3', '4', '4'],
};

// Common props for flexy cards
const defaultFlexCardProps: CardStyleProps = {
  ...defaultCardProps,
  display: 'flex',
};

// Common props for article-like components
const defaultArticleCardProps: CardStyleProps = {
  ...defaultCardProps,
  align: 'center',
};

export const CardContainer = superstyled<CardStyleProps, StyledHTML<'div'>>(
  'div',
  defaultCardProps,
);

export const FlexCardContainer = superstyled<CardStyleProps, StyledHTML<'div'>>(
  'div',
  defaultFlexCardProps,
);

export const ArticleCardContainer = superstyled<CardStyleProps, StyledHTML<'div'>>(
  'div',
  defaultArticleCardProps,
);
