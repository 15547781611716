import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';

export interface OwnProps {
  tourId: string;
  season: string;
}

export const ProCircuitTourStatisticsDownloads: React.FunctionComponent<OwnProps> = ({tourId}) => (
  <Spacer vSpace="3" measure="very-wide" align="center" debugClassName="TourStatisticsDownloads">
    <SubHeading>Downloads</SubHeading>
    <ContentfulArticle
      articleType={ArticleTypes.TourStatsProCircuitDownloads}
      contentPath={`tours/${tourId}`}
    />
  </Spacer>
);
