import React from 'react';
import {List, Map} from 'immutable';
import {TourStandings} from 'fiba/wt/ui/tourStandings/TourStandings';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {TourResultsStop} from 'fiba/common/core/models/api/results/TourResultsStop';
import {connect} from 'fiba/common/utils/reactUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import TourResults from 'fiba/common/core/models/api/results/TourResults';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';
import {Link} from 'fiba/wt/ui/link/Link';
import {ArrowRight} from '@fpapado/react-feather';

export interface OwnProps {
  tourId: string;
  season: string;
  stage: 'latest' | 'intermediate';
}

interface ReduxProps {
  data: RemoteData.RemoteData<{isFinalPlayed: boolean}, {}>;
}

export const mapStateToProps = (state, {tourId, stage}: OwnProps): ReduxProps => {
  // Adapt the meta-path business to RemoteData.
  // This makes accessing safer down the line.
  const tourResultsData = RemoteData.map(
    RemoteData.fromMetaObject(
      state.getIn(['tourResults', '__meta', tourId, 'latest'], Map()).toJS(),
    ),
    () => state.getIn(['tourResults', tourId, 'latest']) as TourResults,
  );

  // We must deduce whether the final is played, from the latest standings
  // This is important to signpost whether we will show Final or Pre-Final standings!
  const data = RemoteData.map(tourResultsData, tourResults => {
    // TODO: Maybe a warning here about empty categories?
    // WT/WS Events only have one category
    const category = tourResults.categories.get(0);
    const events = category.events;
    const teamStandings = category.teamStandings;

    const finalEvent = events.find(event => event.eventIsFinal);
    const finalEventId = finalEvent && finalEvent.eventId;

    const isFinalPlayed =
      finalEventId &&
      teamStandings.size > 0 &&
      teamStandings.some(standing => containsFinalStandings(standing.tourStops, finalEventId));

    return {
      isFinalPlayed,
    };
  });

  return {
    data,
  };
};

const containsFinalStandings = (tourStops: List<TourResultsStop>, finalEventId: string) => {
  const maybeFinal = tourStops.find(stop => stop.eventId === finalEventId);

  return maybeFinal && !!maybeFinal.standing;
};

const _TourStandingsPage: React.FunctionComponent<OwnProps & ReduxProps> = ({
  tourId,
  season,
  data,
  stage,
}) => {
  const headingId = 'tour-standings-heading';
  return (
    <section>
      <Section>
        {RemoteData.match(data, {
          Loading: () => <BlockText>Loading...</BlockText>,
          NotAsked: () => null,
          Failure: _err => (
            <BlockText>
              We could not fetch the data for Tour Standings. Please try again later.
            </BlockText>
          ),
          Success: ({isFinalPlayed}) => {
            const heading =
              stage === 'intermediate' ? 'Pre-Final Tour Standings' : 'Tour Standings';

            const preFinalToggleLink = (
              <Box pl="1" textAlign="right" fontSize="7">
                {stage !== 'intermediate' ? (
                  <Link colorTheme="blue" href={`/${season}/standings/intermediate`}>
                    Pre-final standings
                  </Link>
                ) : (
                  <Link colorTheme="blue" href={`/${season}/standings`}>
                    Final standings
                  </Link>
                )}
                <ArrowRight className="v-mid link--blue" width="16px" purpose="decorative" />
              </Box>
            );

            return (
              <React.Fragment>
                <PageHeading
                  containerProps={{display: 'flex', justifyContent: 'between', flexWrap: 'wrap'}}
                  text={heading}
                  id={headingId}
                >
                  {isFinalPlayed && preFinalToggleLink}
                </PageHeading>

                <Box bgColor="fullwhite" shadow="1">
                  <TourStandings
                    tourId={tourId}
                    season={season}
                    stage={isFinalPlayed ? stage : 'intermediate'}
                  />
                </Box>
              </React.Fragment>
            );
          },
        })}
      </Section>
    </section>
  );
};

export const TourStandingsPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _TourStandingsPage,
);
