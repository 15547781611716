import React from 'react';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {LinkColorTheme} from 'fiba/wt/ui/link/Link';

/*
  What do we need

  - Define fg and bg colors
  - Define link colors
  - Define accent colors?
  - Or more special, say tab colors
  -
 */

export interface IThemeContext {
  name: string; // Do we want to restrict this to given set?
  color: StyleProp<'color'>;
  faintColor: StyleProp<'color'>;
  bgColor: StyleProp<'bgColor'>;
  faintBgColor: StyleProp<'bgColor'>;
  headerColor: StyleProp<'bgColor'>;
  footerColors: {
    bgColor: StyleProp<'bgColor'>;
    borderColor: StyleProp<'borderColor'>;
  };
  // Alternative bgColor?
  // TODO: Link compatibility to theme
  linkTheme: LinkColorTheme;
  ghostlinkTheme: LinkColorTheme;
  fontSize: StyleProp<'fontSize'>;
  // TODO: Table colors here?
}

export const lightTheme: IThemeContext = {
  name: 'light',
  color: 'dark-50',
  faintColor: 'dark-20',
  bgColor: 'silver-10',
  headerColor: 'dark-50',
  footerColors: {
    bgColor: 'fullblack',
    borderColor: 'dark-40',
  },
  faintBgColor: 'silver-20',
  linkTheme: 'dark',
  ghostlinkTheme: 'light',
  fontSize: '6',
};

export const darkTheme: IThemeContext = {
  name: 'dark',
  color: 'silver-10',
  faintColor: 'silver-50',
  bgColor: 'dark-50',
  headerColor: 'silver-10',
  footerColors: {
    bgColor: 'fullwhite',
    borderColor: 'silver-10',
  },
  faintBgColor: 'dark-40',
  linkTheme: 'light',
  ghostlinkTheme: 'dark',
  fontSize: '6',
};

export const defaultTheme = lightTheme;

export const ThemeContext = React.createContext<IThemeContext>(defaultTheme);

export const ThemeContextConsumer = ThemeContext.Consumer;

// TODO: Do we need a component that wraps both provider and consumer?
// <ThemeProsumer theme="light">
//   {theme =>
//      <Box color={theme.color} bgColor={theme.bgColor} />
//   }
// </ThemeProsumer>

interface IThemeContextProvider {
  theme: 'dark' | 'light' | IThemeContext;
}

export const ThemeContextProvider: React.FunctionComponent<IThemeContextProvider> = ({
  theme,
  children,
}) => {
  if (theme === 'dark') {
    return <ThemeContext.Provider value={darkTheme}>{children}</ThemeContext.Provider>;
  } else if (theme === 'light') {
    return <ThemeContext.Provider value={lightTheme}>{children}</ThemeContext.Provider>;
  }

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>;
};
