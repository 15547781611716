import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta} from 'fiba/common/stores/storeUtils';
import tourActions from 'fiba/wt/stores/tourStore';
import {ProCircuitTourTeamListLike} from 'fiba/common/core/models/api/tour/ProCircuitTourTeamList';
import {
  ProCircuitTourTeam,
  ProCircuitTourTeamLike,
} from 'fiba/common/core/models/api/tour/ProCircuitTourTeam';

export interface ProCircuitTourTeamStoreState extends Map<string, ProCircuitTourTeam> {
  // TODO: `__meta`
}

const reducers = {
  loadProCircuitTourTeamSuccess: (
    state: ProCircuitTourTeamStoreState,
    proCircuitTourTeam: ProCircuitTourTeamLike,
  ): ProCircuitTourTeamStoreState =>
    state
      .set(proCircuitTourTeam.placeholderId, ProCircuitTourTeam.fromJS(proCircuitTourTeam))
      .updateIn(['__meta', proCircuitTourTeam.placeholderId], setMeta.isLoaded),
};

const externalReducers = {
  [tourActions.types.loadProCircuitTeamsBySeasonSuccess]: (
    state: ProCircuitTourTeamStoreState,
    season: string,
    data: ProCircuitTourTeamListLike,
  ): ProCircuitTourTeamStoreState => {
    const proCircuitTourTeams = data.teams;
    return proCircuitTourTeams.reduce(reducers.loadProCircuitTourTeamSuccess, state);
  },
};

export const reducer = createReducer<ProCircuitTourTeamStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

const actions = createActions(__filename, reducers, {});

export default actions;

export const deserializeState = createStoreReviver<ProCircuitTourTeamStoreState>(
  ProCircuitTourTeam,
);
