import React, {useContext, useState, useMemo} from 'react';
import {List} from 'immutable';
import Event from 'fiba/common/core/models/api/events/Event';
import {connect} from 'fiba/common/utils/reactUtils';
import {getTourChallengers, getTourEvents} from 'fiba/wt/stores/tourStore';
import {Box} from 'fiba/wt/ui/box/Box';
import {Link} from 'fiba/wt/ui/link/Link';
import {ServicesContext} from 'fiba/common/services/services';
import Dates from 'fiba/common/utils/Dates';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {PillSelect} from 'fiba/wt/ui/pillSelect/PillSelect';
import {Section, SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {eventRootLink} from 'fiba/wt/utils/linkUtils';
import {Cluster} from 'fiba/wt/ui/cluster/Cluster';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface OwnProps {
  tourId: string;
  season: string;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    challengers: List<Event>;
    eventNames: Record<string, string>;
  }>;
}

type Props = ReduxProps & OwnProps;

export const mapStateToProps = (state, {tourId}: OwnProps): ReduxProps => {
  const challengersData = getTourChallengers(tourId, state.get('tours'), state.get('events'));

  const eventNamesData = RemoteData.map(
    getTourEvents(tourId, state.get('tours'), state.get('events')),
    events =>
      events.reduce((acc, ev) => {
        acc[ev.id] = ev.shortName;
        return acc;
      }, {}) as Record<string, string>,
  );

  // While the data sources are not related per se, we want them as one, because partial
  // failures do not make sense in this context / lead to an inconsistent UI.
  const data = RemoteData.map2(challengersData, eventNamesData, (challengers, eventNames) => ({
    challengers,
    eventNames,
  }));

  return {data};
};

type SortState = 'date' | 'masters';

const ChallengersPageImpl: React.SFC<Props> = ({season, data}) => {
  const [sortState, setSortState] = useState<SortState>('date');

  return (
    <Spacer ph={['3', '3', '1']}>
      <section>
        <PageHeading text="Challengers" />

        <Section>
          <Box pv={['3']} ph={['3', '3', '4']} bgColor="silver-20">
            <Spacer vSpace="4">
              <Cluster gap="3" flexStyle={{justifyContent: 'between'}}>
                <Flex alignItems="center">
                  <PillSelect
                    controlLabel="Sort by:"
                    inputNameId="challenger-sort"
                    options={[
                      {label: 'Date', value: 'date'},
                      {label: 'Masters', value: 'masters'},
                    ]}
                    initial="date"
                    legendExtraClassName="f6 fw7"
                    selectedClassName="fw7 bg-silver-10"
                    onChange={userSelection => setSortState(userSelection)}
                  />
                </Flex>
              </Cluster>
              <ViewWebData data={data}>
                {({challengers, eventNames}) => (
                  <ViewLoaded
                    season={season}
                    challengers={challengers}
                    eventNames={eventNames}
                    sortState={sortState}
                  />
                )}
              </ViewWebData>
            </Spacer>
          </Box>
        </Section>
      </section>
    </Spacer>
  );
};

//
// ViewLoaded

interface ViewLoadedProps {
  season: string;
  challengers: List<Event>;
  eventNames: Record<string, string>;
  sortState: SortState;
}

const ViewLoaded: React.FC<ViewLoadedProps> = ({season, challengers, eventNames, sortState}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);
  const sortedChallengers: Event[] | Event[][] = useMemo(() => {
    switch (sortState) {
      case 'date':
        return challengers.sortBy(ev => ev.startDate).toJS();
      case 'masters':
        return challengers
          .groupBy(ev => ev.challengerFor)
          .toList()
          .toJS();
    }
  }, [challengers, sortState]);

  if (challengers.size === 0) {
    return <BlockText>There are no Challengers available yet.</BlockText>;
  }

  /* If sorted by masters, then render separate lists with headers */
  if (sortState === 'masters') {
    return (
      <div className="vs5 lh-title" style={{columnWidth: '24rem', columnGap: '1rem'}}>
        {(sortedChallengers as Event[][]).map(group => (
          <div className="vs4" key={group[0].challengerFor} style={{breakInside: 'avoid'}}>
            {eventNames[group[0].challengerFor] && (
              <SubHeading>
                <Link
                  colorTheme="dark"
                  href={eventRootLink(seasonConfig, season, group[0].challengerFor, 'Challenger')}
                >
                  {eventNames[group[0].challengerFor]} Masters
                </Link>
              </SubHeading>
            )}

            <ul className="vs4">
              {group.map(ev => (
                <li key={ev.id} className="db vs2 f5 f4-m">
                  <div className="f6 f5-m dark-20">
                    {Dates.formatDateRange(localizer, ev.startDate, ev.endDate, 'medium')}
                  </div>

                  <div>
                    <Link
                      colorTheme="dark"
                      href={eventRootLink(seasonConfig, season, ev.id, 'Challenger')}
                    >
                      {/* Display a shortname, or, failing that, the full name */}
                      {ev.shortName ? `${ev.shortName} Challenger` : ev.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    );
  }

  /* Otherwise, render a flat list */
  return (
    <ul className="vs4 lh-title" style={{columnWidth: '24rem', columnGap: '1rem'}}>
      {(sortedChallengers as Event[]).map((ev: Event) => (
        <li key={ev.id} className="db vs2 f5 f4-m" style={{breakInside: 'avoid'}}>
          <div className="f6 f5-m dark-20">
            {/* TODO: Consider custom format MM DD -DD */}
            {Dates.formatDateRange(localizer, ev.startDate, ev.endDate, 'medium')}
          </div>

          {/* TODO: Add a link / resource provider for this */}
          <div>
            <Link colorTheme="dark" href={eventRootLink(seasonConfig, season, ev.id, 'Challenger')}>
              {/* Display a shortname, or, failing that, the full name */}
              {ev.shortName ? `${ev.shortName} Challenger` : ev.name}
            </Link>
          </div>
        </li>
      ))}
    </ul>
  );
};

export const ChallengersPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  ChallengersPageImpl,
);
