import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface Props {
  tourId: string;
}

export const TourWhereToWatchPage: React.FC<Props> = ({tourId}) => {
  return (
    // NOTE: We use CSS to normalize the width of images in here
    <Spacer vSpace="3" ph={['3', '3', '0']} extraClassName="TourWhereToWatchPage">
      <PageHeading containerProps={{mh: '0'}} text="Where to Watch" />
      <Section>
        <ContentfulArticle
          contentPath={`tours/${tourId}`}
          articleType={ArticleTypes.TourWhereToWatch}
        />
      </Section>
    </Spacer>
  );
};
