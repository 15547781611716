import React from 'react';
import {Player} from 'fiba/common/core/models/api/players/Player';
import {ExternalLink} from '@fpapado/react-feather';
import {getProfileImageWt, getProfileImageSrcSet} from 'fiba/common/core/images';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {dangerousWidthToRem} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {generateSizes} from 'fiba/wt/utils/imageUtils';
import {getCountryByIocCode} from 'fiba/common/utils/Locations';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {ImageWithPlaceholder} from 'fiba/wt/ui/image/Image';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {GhostLink} from 'fiba/wt/ui/link/Link';

export interface IPlayerCard {
  player: Player;
}

//
// Constants

const IMAGE_OFFSET: Array<StyleProp<'pb'>> = ['6', '5', '5'];

// We are a bit conservative with the width, because the component can exist
// with a sidebar or not, which throws off the naive media query
const IMAGE_WIDTH: Array<StyleProp<'width'>> = ['4', '4', '4', '5'];

//
// Main Component

export const PlayerCard: React.SFC<IPlayerCard> = ({player}) => (
  <CardContainer pa="0" pb="5">
    {/* Cover and Profile */}
    <Box position="relative">
      <Box position="relative" mb={IMAGE_OFFSET}>
        <ImageWithPlaceholder
          // TODO: Make shorter on wide screens
          // TODO: make aspectRatio responsive?
          aspectRatio="21x9"
          alt=""
          className="o-fit-cover"
          // NOTE: Using the Fallback for now, because the full one is 2MB, and irresponsible to just serve it.
          src={player.coverImageFallbackUrl}
        />
      </Box>

      {/* "Full Profile" Link on wider than mobile */}
      <Box
        display={['none', 'block']}
        pr="3"
        pb="3"
        position="absolute"
        extraClassName="right-0 bottom-0 z-1"
      >
        <FullProfileLink playerId={player.id} />
      </Box>

      {/* Profile */}
      <Box
        mnb={IMAGE_OFFSET}
        ph={['3', '3', IMAGE_OFFSET[2]]}
        width="100"
        position="absolute"
        extraClassName="bottom-0"
      >
        <Flex flexDirection={['column', 'row']}>
          <Box
            width={IMAGE_WIDTH}
            borderColor="fullwhite"
            borderWidth="1"
            border="all"
            flexShrink="1"
            alignSelf={['center', 'start']}
          >
            <ImageWithPlaceholder
              aspectRatio="1x1"
              className="o-fit-contain o-pos-b"
              containerStyle={{bgColor: 'silver-20'}}
              alt={`Profile of ${getPlayerName(player)}`}
              sizes={generateSizes(IMAGE_WIDTH.map(dangerousWidthToRem))}
              src={getProfileImageWt(player, 'small')}
              srcSet={getProfileImageSrcSet('wt', player, ['tiny', 'small', 'medium'])}
            />
          </Box>
          <Flex pa="3" flexDirection="column" justifyContent="end">
            <SubHeading measure="normal" textAlign={['center', 'left']}>
              {getPlayerName(player)}
            </SubHeading>
          </Flex>
        </Flex>
      </Box>
    </Box>

    {/* Player Info */}
    {/* TODO: Figure out padding interaction with positioning
     * mt=5 is a bit hacky
     */}
    <Box mt="5" ph={['3', '3', IMAGE_OFFSET[2]]}>
      {/* Description items */}
      <Flex flexDirection="row" flexWrap="wrap" extraClassName="c-gap-3">
        {/* First set of descriptions */}
        <Box flex="auto" mt="4">
          <dl className="vs4">
            <Box className="vs3">
              <dt>Nationality</dt>
              <dd className="b">{player.nationality || '-'}</dd>
            </Box>
            <Box className="vs3">
              <dt>Hometown</dt>
              <dd className="b">{player.homeCity || '-'}</dd>
            </Box>
            <Box className="vs3">
              <dt>Gender / Age</dt>
              <dd className="b">
                {player.gender} / {player.age || '-'}
              </dd>
            </Box>
            <Box className="vs3">
              <dt>Height</dt>
              <dd className="b">{player.heightInCm || '-'}</dd>
            </Box>
          </dl>
        </Box>

        {/* Second set of descriptions */}
        <Box flex="auto" mt="4">
          <dl className="vs4">
            <Box className="vs3">
              <dt>World Ranking</dt>
              <dd className="b">{player.worldRankingPosition || 'N/A'}</dd>
            </Box>
            <Box className="vs3">
              <dt>
                Ranking
                <br /> Country ({player.homeCountry}) / Nationality (
                {getCountryByIocCode(player.nationality)})
              </dt>
              <dd className="b">
                {`${player.countryRankingPosition || 'N/A'} / ${
                  player.nationalityRankingPosition || 'N/A'
                }`}
              </dd>
            </Box>
            <Box className="vs3">
              <dt>Ranking Points</dt>
              <dd className="b">{player.rankingPoints}</dd>
            </Box>
            <Box className="vs3">
              <dt>Games Played</dt>
              <dd className="b">{player.gamesPlayed}</dd>
            </Box>
          </dl>
        </Box>
      </Flex>
    </Box>

    {/* "Full Profile" Link on mobile */}
    <Box display={['block', 'none']} mt="4" ph="3">
      <FullProfileLink playerId={player.id} />
    </Box>
  </CardContainer>
);

//
// Utils

const FullProfileLink: React.SFC<{playerId: string}> = ({playerId}) => (
  <GhostLink
    extraClassName="ttu"
    href={`https://play.fiba3x3.com/players/${playerId}`}
    isExternal={true}
    variant="dark"
  >
    <span className="mr3 v-mid b">Full Profile</span>
    <ExternalLink className="v-mid" purpose="decorative" />
  </GhostLink>
);
