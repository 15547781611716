import React from 'react';
import cx from 'classnames';
import {assetPath} from 'fiba/common/utils/reactUtils';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Navigation} from 'fiba/wt/ui/navigation/Navigation';
import {SeasonSelect} from 'fiba/wt/ui/tourLayout/SeasonSelect';
import {
  EventConfiguration,
  ImageAlignments,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {get} from 'lodash';
import {IMAGE_SIZES, IMAGE_X_SIZES} from 'fiba/common/utils/constants';
import {generateContentfulImageSetStyle} from 'fiba/wt/utils/imageUtils';

interface Props {
  season: string;
  siteConfig: ISiteConfig;
  eventId?: string;
  extraClassName?: string;
  bgColor?: string;
  eventConfiguration?: EventConfiguration;
  hideImage?: boolean;
  headerImageLink?: string;
  backgroundImageUrl?: string;
}

export const SeasonNavigation: React.FC<Props> = ({
  season,
  siteConfig,
  extraClassName,
  eventConfiguration,
  hideImage,
  bgColor,
  headerImageLink,
  backgroundImageUrl,
}) => {
  const {features, theme} = siteConfig;

  const backgroundColor = get(eventConfiguration, ['headerBackgroundColor', 'value']);

  const backgroundImageAlign = get(eventConfiguration, ['headerBackgroundImageAlign'], 'center');

  const useCover = backgroundImageAlign === ImageAlignments.cover;

  // Depending on the alignment we want to either cover the whole header
  // or only align to left or right corner of the available area
  // hence the different place where to apply this based on alignment
  const bgStyle = {
    width: '100%',
    backgroundSize: useCover ? 'cover' : 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    backgroundPositionX: useCover ? 'center' : backgroundImageAlign,
    ...(backgroundImageUrl
      ? generateContentfulImageSetStyle({
          url: backgroundImageUrl,
          sizes: IMAGE_SIZES,
          xSizes: IMAGE_X_SIZES,
          defaultSize: 1440,
        })
      : null),
  };
  const renderOlympicsLogo = (isSmall: boolean) => {
    const logoPath = isSmall ? '/img/logo-cups-mobile.svg' : '/img/logo-cups.svg';
    const olympicLogoPath = isSmall
      ? '/img/logo-paris-2024-mobile.svg'
      : '/img/logo-paris-2024.svg';
    if (
      eventConfiguration?.path?.includes('64c2e19d-b36c-4aa1-8457-4fdb28d0b526') ||
      eventConfiguration?.title?.includes('Paris 2024 Olympic Games')
    ) {
      return (
        <img
          className="db ht-100"
          src={assetPath(olympicLogoPath)}
          alt="Olympics"
          style={{marginLeft: '15px', ...(isSmall ? {maxWidth: 'none'} : {})}}
        />
      );
    } else {
      return (
        <img
          className="db ht-100"
          src={assetPath(logoPath)}
          alt="Cups"
          style={isSmall ? {maxWidth: 'none'} : {}}
        />
      );
    }
  };

  return (
    <div className={cx('CupsNavigation', extraClassName)}>
      <Navigation season={season}>
        <Flex
          bgColor={theme.headerColor}
          style={{
            ...(!bgColor ? {backgroundColor} : null),
            ...(useCover && !hideImage ? bgStyle : null),
          }}
          height="3"
        >
          {/* 3x3 large logo */}
          <a
            className="dn db-l flex justify-start ht3"
            /**
             * The www. subdomain is a workaround required for the Cups sites as in production for this home link to correctly send the user to https://fiba3x3.com.
             * This is because the Cups sites are hosted under the domain https://fiba3x3.com in LIVE, so if this link exactly matches the domain of the Cups site
             * then the Cups site does not redirect the user to https://fiba3x3.com, instead it tries to render a path of / in the Cups app (this is not desired behaviour).
             */
            href="https://www.fiba3x3.com"
            style={{minWidth: '13rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/3x3-logo-cut.svg')} alt="3x3" />
          </a>
          {/* 3x3 small logo */}
          <a
            className="db dn-l flex justify-start ht3"
            /**
             * The www. subdomain is a workaround required for the Cups sites as in production for this home link to correctly send the user to https://fiba3x3.com.
             * This is because the Cups sites are hosted under the domain https://fiba3x3.com in LIVE, so if this link exactly matches the domain of the Cups site
             * then the Cups site does not redirect the user to https://fiba3x3.com, instead it tries to render a path of / in the Cups app (this is not desired behaviour).
             */
            href="https://www.fiba3x3.com"
            style={{minWidth: '8rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/3x3-logo-cut-mobile.svg')} alt="3x3" />
          </a>
          {/* Cups large logo */}
          <a
            className="dn db-l flex justify-start ht3"
            href={`/${season}`}
            // marginLeft with negative value to align the logo parallelogram shapes
            style={{minWidth: '13rem', marginLeft: '-2.7rem'}}
          >
            {renderOlympicsLogo(false)}
          </a>
          {/* Cups small logo */}
          <a
            className="db dn-l flex justify-start ht3"
            href={`/${season}`}
            // marginLeft with negative value to align the logo parallelogram shapes
            style={{marginLeft: '-1.3rem'}}
          >
            {renderOlympicsLogo(true)}
          </a>
          <div style={{width: '100%', ...(!useCover && !hideImage ? bgStyle : null)}}>
            {!!headerImageLink && (
              <a
                href={headerImageLink}
                style={{display: 'block', height: '100%', width: '100%'}}
              ></a>
            )}
          </div>
          {!!features.showSeasonSelect && (
            <SeasonSelect
              containerStyleProps={{
                bgColor: 'fullwhite',
                ph: '3',
                height: '100',
                alignItems: 'center',
              }}
              buttonStyleProps={{color: 'dark-50'}}
            />
          )}{' '}
        </Flex>
      </Navigation>
    </div>
  );
};
