import React, {useContext} from 'react';
import {getTeamDisplayName} from 'fiba/wt/core/results';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {Flag} from 'fiba/wt/ui/flag/Flag';
import {isNumber} from 'lodash';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Box} from 'fiba/wt/ui/box/Box';

interface Props {
  standing: ResultsStanding;
  offset?: {top?: string; left?: string; right?: string};
}

interface AssemblyOutProps {
  name?: string;
  gamesWon?: number;
  gamesLost?: number;
  nationality?: string;
}

const assemblyPropsFromStanding = (
  standing: ResultsStanding,
  localizer: LocalizerService,
): AssemblyOutProps => ({
  name: getTeamDisplayName(standing, localizer),
  gamesWon: standing.gamesWon,
  gamesLost:
    isNumber(standing.gamesPlayed) &&
    isNumber(standing.gamesWon) &&
    standing.gamesPlayed - standing.gamesWon,
  nationality: standing.teamNationality,
});

export const EventPodiumTeamAssembly: React.FunctionComponent<Props> = ({standing, offset}) => {
  const {localizer} = useContext(ServicesContext);
  const {name, gamesWon, gamesLost, nationality} = assemblyPropsFromStanding(standing, localizer);

  return (
    <React.Fragment>
      <Box
        position={offset ? 'absolute' : 'inherit'}
        display="inline_block"
        textAlign="center"
        width="auto"
        style={offset || {}}
      >
        <div>
          <Flag ioc={nationality} width={45} includeAlt={true} />
        </div>
        {!!name && <SubHeading fontSize="5">{name}</SubHeading>}
        {!!isNumber(gamesLost) && (
          <Box fontSize="7">{`${gamesWon} ${gamesWon === 1 ? 'WIN' : 'WINS'} - ${gamesLost} ${
            gamesLost === 1 ? 'LOSS' : 'LOSSES'
          }`}</Box>
        )}
      </Box>
    </React.Fragment>
  );
};
