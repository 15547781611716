import {Map, List, fromJS} from 'immutable';
import {mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import {TourResults, TourResultsLike} from 'fiba/common/core/models/api/results/TourResults';
import TourResultsEvent from 'fiba/common/core/models/api/results/TourResultsEvent';
import TourResultsCategory from 'fiba/common/core/models/api/results/TourResultsCategory';

export interface TourResultsStoreState extends Map<string, Map<string, TourResults>> {}

// Temp type to make `deserializeState` typed, inb4 immer PR
interface TourResultsStoreStateLike {
  [key: string]: {
    latest?: TourResultsLike;
    intermediate?: TourResultsLike;
  };
  __meta: {};
}

const reducers = {
  loadTourResults: (
    state: TourResultsStoreState,
    tourId: string,
    stage: string,
  ): TourResultsStoreState => state.updateIn(['__meta', tourId, stage], setMeta.isLoading),

  loadTourResultsSuccess: (
    state: TourResultsStoreState,
    results: TourResultsLike,
    includeOnly: List<string>,
  ): TourResultsStoreState => {
    // NOTE: The types say that categories is a List, but really it is an Array
    // This is a mistake / bad assumption of the codegen. XYZLike has Plain JS types, not Immutable types.
    const filteredEvents: List<TourResultsEvent> =
      ((results.categories as any) as TourResultsCategory[]).length > 0
        ? List(results.categories[0].events.filter(event => includeOnly.includes(event.eventId)))
        : List();

    const filteredResults = TourResults.fromJS(results).setIn(
      ['categories', 0, 'events'],
      filteredEvents,
    ) as TourResultsLike;

    return state
      .setIn([results.tourId, results.stage], TourResults.fromJS(filteredResults))
      .updateIn(['__meta', results.tourId, results.stage], setMeta.isLoaded);
  },

  loadTourResultsError: (
    state: TourResultsStoreState,
    tourId: string,
    stage: string,
    error: Error,
  ): TourResultsStoreState => state.updateIn(['__meta', tourId, stage], setMeta.isError),
};

export const reducer = createReducer<TourResultsStoreState>(__filename, fromJS({}), reducers);

const actions = createActions(__filename, reducers, {
  loadTourResults: (tourId: string, stage: string, includeOnly: List<string>) => ({
    apiClient,
  }: CommonServices) =>
    apiClient
      .loadTourResults(tourId, {stage})
      .then(res => actions.loadTourResultsSuccess(res.data, includeOnly))
      .catch(rethrow((err: Error) => actions.loadTourResultsError(tourId, stage, err))),

  loadTourResultsError: (tourId: string, stage: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export function deserializeState(state: TourResultsStoreStateLike): TourResultsStoreState {
  const {__meta, ...items} = state;
  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail on chrome
    __meta: __meta || {},
    ...mapValues(items, stageResults =>
      mapValues(stageResults, results => TourResults.fromJS(results)),
    ),
  });
}
