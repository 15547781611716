import React from 'react';
import {SubHeading, HeadingStyleProps} from '../heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Box} from 'fiba/wt/ui/box/Box';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {Link} from 'fiba/wt/ui/link/Link';

const DEFAULT_HEADING_PROPS: HeadingStyleProps = {
  borderColor: 'silver-30',
  border: 'bottom',
  fontSize: '5',
  mb: '2',
  pb: '1',
  pt: '4',
};

const DefinitionRow: React.SFC<{term: string; definition: string}> = ({term, definition}) => (
  <Box style={{breakInside: 'avoid'}}>
    <dt className="fw6 pt2 dib pr1">{term}</dt>
    <dd className="steel-40 dib pr2">{definition}</dd>
  </Box>
);

const CalculationRow: React.SFC<{term: string; definition: string; calculation: string}> = ({
  term,
  definition,
  calculation,
}) => (
  <Box style={{breakInside: 'avoid'}}>
    <dt className="fw7 pt2">
      {term} ({definition})
    </dt>
    <dd className="steel-40">{calculation}</dd>
  </Box>
);
export interface Props {
  definition: string[][];
  headingStyles?: HeadingStyleProps;
}
export const Legend: React.FC<Props> = ({definition, headingStyles = DEFAULT_HEADING_PROPS}) => {
  const calculationRows = definition
    .filter(([term, definition, calculation]) => calculation !== undefined)
    .map(([term, definition, calculation]) => (
      <CalculationRow key={term} term={term} definition={definition} calculation={calculation} />
    ));

  return (
    <Spacer fontSize="7" bgColor="fullwhite">
      {/* Main legend section */}
      <SubHeading {...headingStyles}>Legend</SubHeading>
      {/* We use CSS multi-column layout here, to automatically flow in columns with an idealised width and gap */}
      <dl className="flex-s pl2 flex-wrap " style={{columnWidth: '10rem', columnGap: '1rem'}}>
        {definition.map(([term, definition]) => (
          <DefinitionRow key={term} term={term} definition={definition} />
        ))}
      </dl>

      {/* Calculations section - Checking if we have any calculation to display, otherwise we won't create this section at all */}
      {calculationRows.length > 0 ? (
        <>
          <SubHeading {...headingStyles}>Calculations</SubHeading>
          {/* CSS multi-column layout here too */}
          <dl className="pl1">
            {/* If the array has a third 'calculation' parameter,
        this mapping will display the calculation for given terms
        while retaining the same order of appearance as the above legend list */}
            {calculationRows}
          </dl>
        </>
      ) : (
        ''
      )}

      {/* Link to Fiba 3x3 Statisticians' Manual */}
      <BlockText mv="2" fontSize="7">
        For more information,{' '}
        <Link
          isExternal
          href="https://fiba3x3.basketball/docs/FIBA-3x3-Statisticians-Manual.pdf"
          colorTheme="blue"
        >
          check the FIBA 3x3 Statisticians' Manual (PDF)
        </Link>
        .
      </BlockText>
    </Spacer>
  );
};
