import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import newsActions from 'fiba/wt/stores/newsStore';
import CommonServices from 'fiba/wt/core/models/CommonServices';
import {getTeamNewsMetaPathParts} from 'fiba/wt/stores/teamStore';
import {getConferenceIdForEvent} from 'fiba/nationsleague/store';

export interface NewsControllerFeatures {
  newsId?: string;
  tourNewsPreviews?: string;
  conferenceNewsPreviews?: {eventId: string; season: string};
  eventNewsPreviews?: string;
  teamNews?: string;
}

function newsController(features: NewsControllerFeatures) {
  return ({dispatch, cache, store}: CommonServices) => {
    const featureLoaders = {
      newsId: (newsId: string) =>
        cache(['news', newsId], () => dispatch(newsActions.loadNewsItem(newsId))),

      tourNewsPreviews: (tourId: string) =>
        cache(['tours', tourId, 'news', 'previews'], () =>
          dispatch(newsActions.loadNewsPreviewsByTour(tourId)),
        ),

      // NOTE: events is WebData-based, so it does not automatically insert
      // '__meta' to the path. We must opt-in to it explicitly, because less data is stored there.
      eventNewsPreviews: (eventId: string) =>
        cache(['events', '__meta', eventId, 'news', 'previews'], () =>
          dispatch(newsActions.loadNewsPreviewsByEvent(eventId)),
        ),

      conferenceNewsPreviews: ({season, eventId}: {season: string; eventId: string}) => {
        const conferenceId = getConferenceIdForEvent(store.getState(), season, eventId);
        cache(['conferences', '__meta', conferenceId, 'news', 'previews'], () =>
          dispatch(newsActions.loadNewsByEvent(conferenceId)),
        );
      },

      teamNews: (teamId: string, eventId: string) =>
        cache(getTeamNewsMetaPathParts(teamId, eventId), () =>
          dispatch(newsActions.loadNewsByTeam(teamId, eventId)),
        ),
    };

    return loadFeatures(featureLoaders, features);
  };
}

export default newsController;
