import React from 'react';
import {P} from 'fiba/wt/ui/text/Text';

export default function EmptyStats() {
  return (
    <P mb="3" ml="3">
      No stats summary available for this category yet.
    </P>
  );
}
