import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {
  ContentfulEmbeddedMediaProvider,
  EmbeddedMediaTypes,
  renderMediaComponent,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

interface EventShootoutPageProps {
  season: string;
  tourId: string;
  eventId: string;
}

export const EventShootoutPage: React.FunctionComponent<EventShootoutPageProps> = ({eventId}) => (
  <Spacer vSpace="3" ph={['3', '3', '1']} debugClassName="EventShootoutPage">
    <section>
      <EventPageHeading eventId={eventId} page="Shoot-Out" />

      <Section>
        <Spacer vSpace="0">
          <div className="measure-very-wide">
            <ContentfulArticle
              contentPath={`events/${eventId}`}
              articleType={ArticleTypes.EventShootout}
            />
          </div>

          <ContentfulEmbeddedMediaProvider
            contentPath={`events/${eventId}`}
            mediaType={EmbeddedMediaTypes.YoutubeShootoutPlaylist}
          >
            {media => renderMediaComponent(media, 720)}
          </ContentfulEmbeddedMediaProvider>
        </Spacer>
      </Section>
    </section>
  </Spacer>
);
