import React, {useContext} from 'react';
import cx from 'classnames';
import {connect} from 'fiba/common/utils/reactUtils';
import {routerPushLocation} from 'fiba/common/services/routerService';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

interface ISeasonSelectMobile {
  onSelect?: (season: string) => any;
}

interface ISeasonSelectMobileReduxState {
  currentSeason: string;
}

interface ISeasonSelectMobileDispatch {
  onSelectInternal: (season: string) => any;
}

type SeasonSelectProps = ISeasonSelectMobile &
  ISeasonSelectMobileReduxState &
  ISeasonSelectMobileDispatch;

// Mapping state
const mapStateToProps = (state, props: ISeasonSelectMobile): ISeasonSelectMobileReduxState => ({
  currentSeason: state.getIn(['route', 'payload', 'season']),
});

const mapDispatchToProps = (
  dispatch,
  {onSelect}: ISeasonSelectMobile,
): ISeasonSelectMobileDispatch => ({
  onSelectInternal: season => {
    dispatch(routerPushLocation(`/${season}/`));

    if (onSelect) {
      onSelect(season);
    }
  },
});

// Component
const SeasonSelectMobileInner: React.SFC<SeasonSelectProps> = ({currentSeason, onSelect}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const seasons = Object.keys(seasonConfig.seasons).sort();

  return (
    <ul className="hs3 flex f4 fw7" aria-label="Seasons">
      {seasons
        .map(season => (
          <li key={season}>
            <a
              href={`/${season}/`}
              onClick={() => onSelect && onSelect(season)}
              className={cx(
                'focus-shadow',
                currentSeason === season
                  ? 'db link--bare ph3 pv1 br2 bg-silver-10 dark-50 b'
                  : 'db link--bare ph3 pv1 br2 silver-10',
              )}
            >
              {season}
            </a>
          </li>
        ))
        .reverse()}
    </ul>
  );
};

export const SeasonSelectMobile = connect<
  ISeasonSelectMobileReduxState,
  ISeasonSelectMobileDispatch,
  ISeasonSelectMobile
>(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonSelectMobileInner);
