/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {Player} from '../players/Player';

export const defaultTeamRankingsResponse = {rankings: null, updatedAt: null, total: null};
export const defaultTeamRanking = {
  teamMembers: null,
  teamRank: null,
  teamName: null,
  teamNameSuffix: null,
  rankingPoints: null,
  isValidated: null,
  countryIoc: null,
  rankDiff: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TeamRankingsResponseLike = {
  [K in keyof typeof defaultTeamRankingsResponse]?: TeamRankingsResponse[K];
};
export type TeamRankingLike = {
  [K in keyof typeof defaultTeamRanking]?: TeamRanking[K];
};

export class TeamRankingsResponse extends Record(defaultTeamRankingsResponse) {
  rankings?: List<TeamRanking>;
  updatedAt?: string;
  total?: number;
  static fromJS(o: TeamRankingsResponseLike): TeamRankingsResponse {
    return (
      o &&
      new TeamRankingsResponse({
        rankings: o.rankings && List(o.rankings).map(i => i && TeamRanking.fromJS(i)),
        updatedAt: o.updatedAt,
        total: o.total,
      })
    );
  }
}
export class TeamRanking extends Record(defaultTeamRanking) {
  teamMembers?: List<Player>;
  teamRank?: number;
  teamName?: string;
  teamNameSuffix?: string;
  rankingPoints?: number;
  isValidated?: boolean;
  countryIoc?: string;
  rankDiff?: number;
  static fromJS(o: TeamRankingLike): TeamRanking {
    return (
      o &&
      new TeamRanking({
        teamMembers: o.teamMembers && List(o.teamMembers).map(i => i && Player.fromJS(i)),
        teamRank: o.teamRank,
        teamName: o.teamName,
        teamNameSuffix: o.teamNameSuffix,
        rankingPoints: o.rankingPoints,
        isValidated: o.isValidated,
        countryIoc: o.countryIoc,
        rankDiff: o.rankDiff,
      })
    );
  }
}

export default TeamRankingsResponse;
