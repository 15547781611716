import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import ResultsStanding from 'fiba/common/core/models/api/results/ResultsStanding';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {TeamPlayers} from 'fiba/wt/ui/teamPlayers/TeamPlayers';
import {TeamSchedule} from 'fiba/wt/ui/teamSchedule/TeamSchedule';
import {TeamPhotos} from 'fiba/wt/ui/teamPhotos/TeamPhotos';
import {SubHeading, Section} from 'fiba/wt/ui/heading/Heading';
import {
  gameLinkProvider,
  eventTeamLinkProvider,
  ResourceLinkProvider,
  eventTeamPlayerLinkProvider,
  EventType,
} from 'fiba/wt/utils/linkUtils';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from '../viewWebData/ViewWebData';
import Team from 'fiba/common/core/models/api/teams/Team';
import {getTeam, getTeamMetaOrEmpty} from 'fiba/wt/stores/teamStore';
import {TeamNews} from '../teamNews/TeamNews';

interface OwnProps {
  season: string;
  eventId: string;
  teamId: string;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    eventType: EventType;
    team?: Team;
    standing?: ResultsStanding;
  }>;
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {eventId, teamId}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);

  // NOTE: This code is specialized for WT which assumes that the team appears only in one category.
  // In play we would have a handle to related `categoryId` which would simplify fetching the standing.
  const standingData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds
        .flatMap(
          (categoryId: string) =>
            state.getIn(['results', categoryId, 'standings']) as List<ResultsStanding>,
        )
        .find((standing: ResultsStanding) => standing && standing.teamId === teamId),
  );

  const teamData = RemoteData.map(
    RemoteData.fromMetaObject(getTeamMetaOrEmpty(state, teamId).toJS()),
    () => getTeam(state, teamId),
  ) as RemoteData.WebData<Team>;

  // Merge the data sources
  const data = RemoteData.map3(
    eventTypeData,
    standingData,
    teamData,
    (eventType, standing, team) => ({
      eventType,
      standing,
      team,
    }),
  );

  return {data};
};

// TODO: Move to linkUtils
const teamGalleryLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
): ResourceLinkProvider => (teamId: string) =>
  `${eventTeamLinkProvider(seasonConfig, season, eventId, eventType)(teamId)}/photos`;

const definitionListRow = (title: string, data: string | number = '-') => (
  <Flex
    flexDirection={['row', 'row', 'row', 'column']}
    justifyContent={['between', 'between', 'between', 'start']}
    width={['100', '100', '100']}
  >
    <dt className="pr3 pr0-l pb2 tl tr-l">{title}</dt>
    <dd className="b tr">{data}</dd>
  </Flex>
);

export const _EventTeamPage: React.FunctionComponent<Props> = ({season, eventId, teamId, data}) => {
  const siteConfig = useContext(SiteConfigContext);
  const seasonConfig = siteConfig.seasonConfig;
  return (
    <Box ph={['0', '3', '1']} debugClassName="EventTeamPage">
      <ViewWebData data={data}>
        {({eventType, team, standing}) => (
          <>
            <CardContainer debugClassName="EventTeamPage-TeamCard">
              <Flex
                flexDirection={['column', 'column', 'column', 'row']}
                justifyContent="between"
                alignItems={['start', 'start', 'start', 'center']}
                pb={['0', '0', '0', '4']}
              >
                <SubHeading fontSize="3" pb={['3', '3', '3', '0']}>
                  <TeamNameAssembly team={team} standing={standing} isFlagVisible isSuffixVisible />
                </SubHeading>

                <Section>
                  <dl className="EventTeamPage-StatsTable pb3 flex flex-column flex-row-s w-100 w-50-l">
                    {/* NOTE: These two are mutually exclusive, either show standing or show seed if standing is not available */}
                    {!!standing &&
                      !standing.teamRank &&
                      definitionListRow('Seed', standing.teamSeed)}
                    {!!standing &&
                      standing.teamRank &&
                      definitionListRow('Final standing', standing.teamRank)}
                  </dl>
                </Section>
              </Flex>

              <Box
                mnh="4"
                border="bottom"
                borderWidth={['0', '0', '1', '1']}
                borderColor="dark-50"
                extraClassName="separator"
              />

              <Section>
                <Box
                  pt={['0', '0', '4', '4']}
                  ml="auto"
                  mr="auto"
                  maxWidth="8"
                  mnb={['3', '3']}
                  debugClassName="EventTeamPage-Team"
                >
                  <TeamPlayers
                    teamId={teamId}
                    playerLinkProvider={eventTeamPlayerLinkProvider(
                      seasonConfig,
                      season,
                      eventId,
                      eventType,
                      teamId,
                    )}
                  />
                </Box>
              </Section>
            </CardContainer>
            <Section>
              <CardContainer debugClassName="EventTeamPage-ScheduleCard">
                <SubHeading fontSize="3" pb="4">
                  Games
                </SubHeading>

                <Section>
                  <TeamSchedule
                    eventId={eventId}
                    teamId={teamId}
                    createGameHref={gameLinkProvider(seasonConfig, season, eventId, eventType)}
                    createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
                  />
                </Section>
              </CardContainer>

              <div className="EventTeamPage-Photos">
                <TeamPhotos
                  teamId={teamId}
                  createGalleryHref={teamGalleryLinkProvider(
                    seasonConfig,
                    season,
                    eventId,
                    eventType,
                  )}
                />
              </div>
              <div className="EventTeamPage-News">
                <TeamNews eventId={eventId} teamId={teamId} season={season} siteConfig={siteConfig}>
                  {children => <div className="mt3">{children}</div>}
                </TeamNews>
              </div>
            </Section>
          </>
        )}
      </ViewWebData>
    </Box>
  );
};

export const EventTeamPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventTeamPage);
