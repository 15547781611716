import React from 'react';
import {unionize, ofType, UnionOf} from 'unionize';
import {Link} from 'fiba/wt/ui/link/Link';
import {Box} from 'fiba/wt/ui/box/Box';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';

//
// MODEL

// Represents whether an error has a human or machine representation
type ErrorDetail = UnionOf<typeof ErrorDetail>;

const ErrorDetail = unionize({
  Human: ofType<{msg: string}>(),
  Machine: ofType<{msg: string}>(),
});

//
// STATUS HANDLING

const statuses = {
  // In most cases, the page will 404 before the message,
  // so it is normal not to see this often.
  404: 'The resource you are looking for was not found. (404)',
  500: 'There was an internal server error. (500)',
};

const statusToDetail = (status: string): ErrorDetail => {
  if (statuses[status]) {
    return ErrorDetail.Human({msg: statuses[status]});
  }

  // Fall back to number if a human representation is not available
  return ErrorDetail.Machine({msg: `Error: Status {status}`});
};

//
// MESSAGE HANDLING

const messages = {
  'Network Error': 'There was a network problem.',
  'com.fiba.fiba3x3.exceptions.ApiExceptions$UpstreamTimedOutException':
    'The site is taking too long to load.',
};

const messageToDetail = (message: string): ErrorDetail => {
  if (messages[message]) {
    return ErrorDetail.Human({msg: messages[message]});
  }

  return ErrorDetail.Machine({msg: message});
};

//
// MAIN ERROR TRANSFORM

/** Convert info about the error into a human readable version.
 * Priorities, in order of descriptiveness:
 *  - If a human message representation exists, show that.
 *  - Otherwise, if a human status representation exists, show that.
 *  - If neither exists as human, show the machine status
 *  - Anything that is left, hide it it as a machine detail
 */
const errorToHuman = ({status, message, error}: IHumanError): IHumanMessage => {
  // TODO: Figure out what 'error' would be
  const messageDetail = messageToDetail(message);
  const statusDetail = statusToDetail(status);

  return ErrorDetail.match(messageDetail, {
    Human: ({msg: humanMessage}) => ({primary: humanMessage, secondary: [statusDetail.msg]}),
    Machine: ({msg: machineMessage}) =>
      ErrorDetail.match(statusDetail, {
        Human: ({msg: humanStatus}) => ({primary: humanStatus, secondary: [machineMessage]}),
        Machine: ({msg: machineStatus}) => ({primary: machineStatus, secondary: [machineMessage]}),
      }),
  });
};

//
// COMPONENT

/** Component interface */
interface IHumanError {
  status: string;
  error: unknown;
  message: string;
}

/** Type representing a structured message */
interface IHumanMessage {
  primary: string;
  secondary: string[];
}

/** Takes an Error, and displays a prompt to the user */
export const HumanError: React.SFC<IHumanError> = ({status, error, message}) => {
  const {primary, secondary} = errorToHuman({status, error, message});

  return (
    <Box
      pv="5"
      ph="3"
      color="dark-50"
      bgColor="clay-20"
      border="all"
      borderWidth="2"
      borderColor="red-20"
    >
      <Spacer vSpace="4">
        <Spacer vSpace="3">
          <SubHeading>{primary}</SubHeading>

          <Box>
            <Link href="/">Go back to the home page</Link>
          </Box>
        </Spacer>

        <details>
          <summary>Details</summary>
          {!!secondary && (
            <ul>
              {secondary.map((reason, index) => (
                <li key={index}>{reason}</li>
              ))}
            </ul>
          )}
        </details>
      </Spacer>
    </Box>
  );
};
