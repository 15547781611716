import React from 'react';
import {
  ContentfulEmbeddedMedia,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface Props {
  season: string;
  tourId: string;
}

export const TourVideosPage: React.SFC<Props> = ({tourId}) => (
  <section>
    <PageHeading text="Videos" />
    <ContentfulEmbeddedMedia
      contentPath={`tours/${tourId}`}
      mediaType={EmbeddedMediaTypes.YoutubeMainPlaylist}
      widthHint={720} // TODO: discuss
    />
  </section>
);
