import React from 'react';
import cx from 'classnames';
import {Photo} from 'fiba/common/core/models/api/media/Photo';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {FauxButton} from 'fiba/wt/ui/fauxButton/FauxButton';
import {ILazyImgixImage, LazyImgixImage} from 'fiba/wt/ui/imgix/LazyImgixImage';

type ForwardImageProps = Pick<ILazyImgixImage, 'sizes'>;

interface PhotoGalleryItemProps extends ForwardImageProps {
  photo: Photo;
  onInteraction?: () => any;
  aspectRatio?: StyleProp<'aspectRatio'>;
}

// TODO: Make proper, or refactor something
/* Util to convert aspectRatio prop to pair
 * @example: ratioToPair('6x4') === [6, 4]
 * */
export const ratioToPair = (ratio: string): [number, number] =>
  ratio.split('x').map(i => parseInt(i, 10)) as [number, number];

// Main component
export const PhotoGalleryItem: React.FunctionComponent<PhotoGalleryItemProps> = ({
  photo,
  onInteraction,
  aspectRatio = '8x5',
  sizes,
}) => (
  <FauxButton
    tag="div"
    className={cx('PhotoGalleryItem fill-cell', {
      'PhotoGalleryItem--interactable focus-shadow': !!onInteraction,
      pointer: !!onInteraction,
    })}
    onInteraction={onInteraction}
    aria-label="See full image"
  >
    <LazyImgixImage
      // Normally, fill-cell would be undesirable, but in the case of Grid, things are constrained already,
      // thus fill-cell/ht-100 is good to fill gaps under images
      containerClassName="fill-cell"
      className="o-fit-cover"
      baseUrl={photo.imageBaseUrl}
      aspectRatio={ratioToPair(aspectRatio)}
      sizes={sizes}
      // TODO: most of these descriptions do not actually describe these image :(
      alt={photo.description ? photo.description : ''}
    />
  </FauxButton>
);
