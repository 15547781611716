import React from 'react';
import * as ReactIs from 'react-is';
import cx from 'classnames';
import {trimEnd} from 'lodash';
import {connect} from 'fiba/common/utils/reactUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';

/**
 * A bare-markup Tab implementation.
 *
 * Accessibility (a11y) notes:
 * Notably, we do not use the Tabpanel ARIA Pattern. This is on purpose, for a few reasons:
 * - Complex interaction
 * - We do not want to hide content
 * - We did not do user research
 *
 * Thus, we have decided that keeping tabs as nav + ul + li + a is a simpler way to go about it.
 * It may be more verbose, or less "proper" (it seems like a tabpanel!), but we stick to the first rule of
 * ARIA: "No ARIA is better than bad ARIA". Given lack of resources to test the ARIA version (with attributes, tabindex,
 * state announcement changes), we lean on the more tried-and-true
 *
 *  TODO: Consider passing a "focusRef" that will be focused on transition
 *
 * @see https://tink.uk/using-tabbed-interfaces-with-a-screen-reader/
 * @see https://www.w3.org/TR/wai-aria-practices-1.1/#tabpanel
 * @see https://inclusive-components.design/tabbed-interfaces/
 */

interface ITab {
  href: string;
  /** Array of url aliases to the same content */
  hrefAliases?: string[];
  /** Applied automagically by <Tabs> */
  active?: boolean;
  extraClassName?: string;
  /** In few cases, we need to style the <li> item rather than the <a>, hence why we have this prop **/
  extraLiClassName?: string;
  /** Tabs within tabs break the metaphor, so we need to adjust styles on level */
  level?: 'base' | 'nested';
}

type TabsOwnProps = {
  /** Prefix for all tabs's urls, most often the current pages url */
  baseUrl: string;
  /** Tabs within tabs break the metaphor, so we need to adjust styles on level */
  level: 'base' | 'nested';
  /** If you have a page with nested tabs you can use this to match alternative routes and show active tab correctly */
  baseUrlAlias?: string;
  /** Used to apply the active tab style to the container nav */
  extraClassName?: string;
  /** Used to apply styles to the wrapping ul */
  extraUlClassName?: string;
  /** Whether to allow native word wrapping */
  wrap?: boolean;
} & LabelOrLabeledBy;

export type LabelOrLabeledBy =
  | {
      /** An accessible navigation label, to distinguish this nav */
      navigationLabel: string;
      navigationLabeledBy?: string;
    }
  | {
      navigationLabel?: string;
      /** An id (or list of ids) to label the navigation by */
      navigationLabeledBy: string;
    };

type ITabPanel = ITab;

interface ITabPanels {
  /** Prefix for all tabs's urls, most often the current pages url */
  baseUrl: string;
  /** Tab can be accessed via an alternative URL */
  baseUrlAlias?: string;
  /** Used to apply the active tab style to the correct child */
  extraClassName?: string;
}

//
// TAB

/** Link to be used for navigating in a tab-like fashion */
export const Tab: React.SFC<ITab> = ({
  href,
  active,
  level,
  extraClassName,
  extraLiClassName,
  children,
}) => {
  return (
    <li className={cx('db flex-grow-1 tc', extraLiClassName)}>
      <a
        href={href}
        className={
          level === 'base'
            ? // Index card style
              cx(
                'db link--bare pv3 ph3 ',
                active
                  ? 'blue-electric b--blue-electric bt bw2 fw7 br1'
                  : 'dark-50 bg-silver-20 br bl b--silver-40',
                extraClassName,
              )
            : // Flatter style
              cx(
                'db link--bare pv2 ph2 br2',
                active ? 'bg-blue-electric fullwhite fw7' : 'bg-silver-20 dark-50',
              )
        }
        /** NOTE: This will be announced as "XYZ, current, link". Helps give an indication of the element being current */
        aria-current={active ? true : null}
      >
        {children}
      </a>
    </li>
  );
};

//
// TABS

interface TabsReduxProps {
  currentUrl: string;
}

type TabsProps = TabsOwnProps & TabsReduxProps;

const mapStateToProps = state => ({
  currentUrl: trimEnd(state.getIn(['route', 'pathname']), '/'),
});

/** Container for a list of tabs */
const TabsImpl: React.FunctionComponent<TabsProps> = ({
  navigationLabel,
  navigationLabeledBy,
  baseUrl,
  currentUrl,
  level,
  baseUrlAlias,
  wrap = false,
  extraClassName,
  extraUlClassName,
  children,
}) => {
  const baseUrlMatches = urlBaseMatches(baseUrl, currentUrl);
  const baseUrlAliasMatches = urlBaseMatches(baseUrlAlias, currentUrl);

  function mapChild(child: React.ReactElement<ITab>) {
    if (!child) {
      return null;
    }

    if (ReactIs.typeOf(child) === ReactIs.Fragment) {
      return React.cloneElement(child, {}, React.Children.map(child.props['children'], mapChild));
    }

    const currentPathIsActive =
      pathIsActive(baseUrl, currentUrl, child.props) ||
      (baseUrlAlias && pathIsActive(baseUrlAlias, currentUrl, child.props));

    return React.cloneElement(child, {
      href: `${baseUrl}${child.props.href}`,
      active: (baseUrlMatches || baseUrlAliasMatches) && currentPathIsActive,
      level: level,
    });
  }

  return (
    <nav className={extraClassName} aria-labelledby={navigationLabeledBy}>
      {navigationLabel ? <VisuallyHidden>{navigationLabel}</VisuallyHidden> : null}
      <ul
        className={cx(
          'flex overflow-x-auto scroll-touch',
          wrap ? '' : 'ws-nowrap',
          level === 'nested' ? 'hs2 hs3-m hs4-m' : '',
          extraUlClassName,
        )}
      >
        {React.Children.map(children, mapChild)}
      </ul>
    </nav>
  );
};

export const Tabs = connect<TabsReduxProps, {}, TabsOwnProps>(mapStateToProps)(TabsImpl);

//
// TAB PANEL

export const TabPanel: React.SFC<ITabPanel> = ({extraClassName, children}) => (
  <Box extraClassName={extraClassName}>{children}</Box>
);

//
// TAB PANELS

type TabPanels = ITabPanels & TabsReduxProps;

const TabPanelsImpl: React.SFC<TabPanels> = ({
  baseUrl,
  currentUrl,
  extraClassName,
  children,
  baseUrlAlias,
}) => {
  const baseUrlMatches = urlBaseMatches(baseUrl, currentUrl);
  const baseUrlAliasMatches = urlBaseMatches(baseUrlAlias, currentUrl);

  let activeChild: React.ReactElement<ITab> | null = null;

  function findActiveChild(child: React.ReactElement<ITabPanel>) {
    if (!child || activeChild) {
      return;
    }
    if (ReactIs.typeOf(child) === ReactIs.Fragment) {
      React.Children.forEach(child.props['children'], findActiveChild);
      return;
    }

    const currentPathIsActive =
      pathIsActive(baseUrl, currentUrl, child.props) ||
      (baseUrlAlias && pathIsActive(baseUrlAlias, currentUrl, child.props));

    if ((baseUrlMatches || baseUrlAliasMatches) && currentPathIsActive) {
      activeChild = child;
    }
  }

  React.Children.forEach(children, findActiveChild);

  return (
    <Box extraClassName={extraClassName}>
      {activeChild && React.cloneElement(activeChild, {active: true})}
    </Box>
  );
};

export const TabPanels = connect<TabsReduxProps, {}, ITabPanels>(mapStateToProps)(TabPanelsImpl);

function urlBaseMatches(baseUrl: string, currentUrl: string): boolean {
  return baseUrl && currentUrl.startsWith(trimEnd(baseUrl, '/'));
}

function pathIsActive(baseUrl: string, currentUrl: string, tabProps: ITab): boolean {
  const hrefs = [tabProps.href].concat(tabProps.hrefAliases || []);

  return hrefs.some(href => {
    if (href === '/') {
      // this is the base tab, so current location must match base URL exactly:
      return trimEnd(baseUrl, '/') === currentUrl;
    }

    // the part that comes after the base URL (e.g. /pools):
    const currentPath = trimEnd(currentUrl.substring(baseUrl.length), '/');

    //  e.g. current path `/pools` matches tab `/pools`:
    const hrefIsActive = currentPath === trimEnd(href, '/');
    // e.g. current path `/pools/$poolId` matches tab `/pools`:
    const hrefBaseIsActive = currentPath.startsWith(`${href}/`);

    return hrefIsActive || hrefBaseIsActive;
  });
}
