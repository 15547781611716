import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import {bind} from 'fiba/common/stores/storeUtils';
import mediaActions from 'fiba/wt/stores/mediaStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {getConferenceIdForEvent} from 'fiba/nationsleague/store';

interface IGalleryOptions {
  galleryId?: string;
  loadPhotos?: boolean;
}

interface ITwitterOptions {
  hashtags: string[];
}

export interface MediaControllerFeatures {
  // All of these accept a related id
  tourGalleryPreview?: string;
  eventGalleryPreview?: string;
  conferenceGalleryPreview?: {season: string; eventId: string};
  teamPhotos?: string;
  playerPhotos?: string;
  gamePhotos?: string;
  gallery?: IGalleryOptions;
  tweets?: ITwitterOptions;
}

function mediaController(features: MediaControllerFeatures) {
  return ({dispatch, cache, store}: CommonServices) => {
    const featureLoaders = {
      // Previews
      tourGalleryPreview: (tourId: string) =>
        cache(['media', 'gallerypreviews', 'tours', tourId], () => {
          return dispatch(mediaActions.loadGalleryPreviewsByTour(tourId));
        }),

      eventGalleryPreview: (eventId: string) =>
        cache(['media', 'gallerypreviews', 'events', eventId], () =>
          dispatch(mediaActions.loadGalleryPreviewsByEvent(eventId)),
        ),

      conferenceGalleryPreview: ({season, eventId}: {season: string; eventId: string}) => {
        const conferenceId = getConferenceIdForEvent(store.getState(), season, eventId);

        return cache(['media', 'gallerypreviews', 'conferences', conferenceId], () =>
          dispatch(mediaActions.loadGalleryPreviewsByConference(conferenceId)),
        );
      },

      // Photos
      teamPhotos: (teamId: string) =>
        cache(['media', 'photos', 'teams', teamId], () =>
          dispatch(mediaActions.loadPhotosByTeam(teamId)),
        ),

      gallery: ({galleryId, loadPhotos}: IGalleryOptions) =>
        bind([
          loadPhotos &&
            cache(['media', 'photos', 'galleries', galleryId], () =>
              dispatch(mediaActions.loadPhotosByGallery(galleryId)),
            ),
        ]),

      tweets: ({hashtags}: ITwitterOptions) =>
        cache(['media', 'tweets'], () => dispatch(mediaActions.loadTweets(hashtags))),
    };

    return loadFeatures(featureLoaders, features);
  };
}

export default mediaController;
