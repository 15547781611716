import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {NewsList} from 'fiba/wt/ui/newsList/NewsList';
import {NewsListCondensed} from 'fiba/wt/ui/newsList/NewsListCondensed';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {BlockText} from 'fiba/wt/ui/text/Text';

interface ITourNews {
  tourId: string;
  season: string;
}

interface ITourNewsState {
  news: List<NewsItem>;
  metaPaths: string[];
}

const TOUR_NEWS_CONDENSED_COUNT = 10;

type TourNewsProps = ITourNews & ITourNewsState;

export const mapStateToNewsProps = (state, {tourId}: ITourNews): ITourNewsState => {
  const metaPath = `tours/__meta/${tourId}/news/previews`;

  return {
    news: state
      .getIn([...metaPath.split('/'), 'refs'], List())
      .map((newsItemId: string) => state.getIn(['news', 'previews', newsItemId])),
    metaPaths: [metaPath],
  };
};

export const mapStateToNewsCondensedProps = (state, ownProps: ITourNews): ITourNewsState => {
  const newsProps = mapStateToNewsProps(state, ownProps);
  return {...newsProps, news: newsProps.news.take(TOUR_NEWS_CONDENSED_COUNT) as List<NewsItem>};
};

// Loaders

// NOTE: Wraps with padding via NewsList
export const _TourNews: React.FunctionComponent<TourNewsProps> = ({
  season,
  tourId,
  news,
  metaPaths,
}: TourNewsProps) => (
  <div className="TourNews">
    <Loading metaPaths={metaPaths}>
      {() =>
        news.size === 0 ? (
          <BlockText>There are no news articles to show.</BlockText>
        ) : (
          <NewsList news={news} baseUrl={`/${season}/news`} columnStyle="auto" />
        )
      }
    </Loading>
  </div>
);

// TODO: this probably also needs a summary version
export const _TourNewsCondensed: React.FunctionComponent<TourNewsProps> = ({
  season,
  news,
  metaPaths,
}: TourNewsProps) => (
  <div className="TourNews">
    <Loading metaPaths={metaPaths}>
      {() => <NewsListCondensed news={news} baseUrl={`/${season}/news`} />}
    </Loading>
  </div>
);

// Store-connected full/condensed exports
export const TourNews = connect<ITourNewsState, {}, ITourNews>(mapStateToNewsProps)(_TourNews);

export const TourNewsCondensed = connect<ITourNewsState, {}, ITourNews>(
  mapStateToNewsCondensedProps,
)(_TourNewsCondensed);
