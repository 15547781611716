import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {TourNews} from 'fiba/wt/ui/tourNews/TourNews';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

export interface TourNewsPageProps {
  tourId: string;
  season: string;
}

// TODO: Variable number of items in TourNews
// TODO: Pagination? It would help with store size as well
export const TourNewsPage: React.FunctionComponent<TourNewsPageProps> = ({
  tourId,
  season,
}: TourNewsPageProps) => (
  <Spacer vSpace="3" pa={['3', '3', '0']}>
    <PageHeading containerProps={{mh: '0'}} text={`${season} Season News`} />

    <Section>
      <TourNews tourId={tourId} season={season} />
    </Section>
  </Spacer>
);
