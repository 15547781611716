import {Record} from 'immutable';

export {
  Anomaly,
  Category,
  Unavailable,
  Interrupted,
  Incorrect,
  Forbidden,
  Unsupported,
  NotFound,
  Conflict,
  Fault,
  Busy,
  fromHttpStatusCode,
  toImmutableRecord,
};

/**
 * Anomalies capture errors as information that is simple, actionable, generic, and extensible.
 * Simple: Anomalies contain only information about the error, not e.g. flow control or causality.
 * Actionable: the Category aims to be a top-level partitioning of all kinds of errors, allowing many programs that need to branch during error-handling to branch only on this keyword.
 * Generic: Anomalies are represented as ordinary maps, and can be created and consumed without any special API.
 * Extensible: As maps are open, applications can add their own context via namespaced keywords. That said, try to do as much as possible by dispatching only via {category}
 *
 * @see https://github.com/cognitect-labs/anomalies
 * @see https://gist.github.com/olivergeorge/0eeac90c44a2019afa4508504462e002
 */
type Anomaly = {
  category: Category;
  message?: string;
};

/*
 The category column contains the name of an anomaly category.

  The retry column is "yes" if a replay of the same activity might
  reasonably lead to a different outcome. When a program encounters a
  retryable anomaly, it may be reasonable to back off and try again.

  The "fix" column provides an example of how a programmer or operator
  might fix problems in this category.

  | category     | retry | fix                      |
  | ------------ | ----- | ------------------------ |
  | :unavailable | yes   | make sure callee healthy |
  | :interrupted | yes   | stop interrupting        |
  | :incorrect   | no    | fix caller bug           |
  | :forbidden   | no    | fix caller creds         |
  | :unsupported | no    | fix caller verb          |
  | :not-found   | no    | fix caller noun          |
  | :conflict    | no    | coordinate with callee   |
  | :fault       | no    | fix callee bug           |
  | :busy        | yes   | backoff and retry        |
*/
type Category =
  | 'unavailable'
  | 'interrupted'
  | 'incorrect'
  | 'forbidden'
  | 'unsupported'
  | 'not-found'
  | 'conflict'
  | 'fault'
  | 'busy';

/**
 * Internal constructor for Anomaly ImmutableJS record.
 */
const AnomalyRecord = Record({
  category: null,
  message: null,
});

/* Type constructors
 * Immutable by default.
 */
const Anomaly = (category: Category, message: string): Anomaly =>
  new AnomalyRecord({category, message}) as any;

/**
 * Retryable: Yes
 * Fix: Make sure callee is healthy
 */
const Unavailable = (msg?: string) => Anomaly('unavailable', msg);
/**
 * Retryable: Yes
 * Fix: Stop interrupting
 */
const Interrupted = (msg?: string) => Anomaly('interrupted', msg);
/**
 * Retryable: No
 * Fix: Fix caller bug
 */
const Incorrect = (msg?: string) => Anomaly('incorrect', msg);
/**
 * Retryable: No
 * Fix: Fix caller credentials
 */
const Forbidden = (msg?: string) => Anomaly('forbidden', msg);
/**
 * Retryable: No
 * Fix: Fix caller verb (e.g. POST/GET etc.)
 */
const Unsupported = (msg?: string) => Anomaly('unsupported', msg);
/**
 * Retryable: No
 * Fix: Fix caller noun
 */
const NotFound = (msg?: string) => Anomaly('not-found', msg);
/**
 * Retryable: No
 * Fix: Coordinate with callee
 */
const Conflict = (msg?: string) => Anomaly('conflict', msg);
/**
 * Retryable: No
 * Fix: Fix callee bug
 */
const Fault = (msg?: string) => Anomaly('fault', msg);
/**
 * Retryable: Yes
 * Fix: Back off and retry
 */
const Busy = (msg?: string) => Anomaly('busy', msg);

//
// Utility transforms
const fromHttpStatusCode = (code: number) => {
  switch (code) {
    case 403:
      return Forbidden();
    case 404:
      return NotFound();
    case 503:
      return Busy();
    case 504:
      return Unavailable();
  }
  // Other possible errors in the error range
  // i.e. the caller has messed up
  if (code >= 400 || code <= 499) {
    return Incorrect();
  }
  // 500 range, i.e. the callee has an error
  return Fault();
};

//
// TODO: Offer a match function

//
// ImmutableJS conversion

/**
 * Convert from plain JS to Immutable Record.
 * This is done for compatibility with our Immutable JS setup.
 * In the future, we might consider changing the internal representation
 * to plain JS, and removing this function altogether.
 *
 * This is similar to a map() on the value, but with
 * the added property of changing the internal representation.
 */
function toImmutableRecord({category, message}: Anomaly): Anomaly {
  return Anomaly(category, message);
}
