import Koa from 'koa';
import {v4 as uuid_v4} from 'uuid';

// Middleware to ensure we have a Heroku compatible `X-Request-ID` header

export function createRequestIdMiddleware() {
  return (ctx: Koa.Context, next: () => any) => {
    if (!ctx.request.get('x-request-id')) {
      ctx.request.headers['x-request-id'] = createRequestId();
    }

    return next();
  };
}

export function createRequestId(prefix?: string) {
  return `${prefix ? `${prefix}/` : ``}${uuid_v4()}`;
}

export default createRequestIdMiddleware;
