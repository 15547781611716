import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {EventActivity} from 'fiba/common/core/models/api/events/EventActivity';
import {Category} from 'fiba/common/core/models/api/events/Category';
import {Court} from 'fiba/common/core/models/api/events/Court';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Box} from 'fiba/wt/ui/box/Box';
import {PillSelect} from 'fiba/wt/ui/pillSelect/PillSelect';
import {
  // @ts-ignore Import and re-exporting type
  ScheduleFilterType,
  ScheduleFilterState,
  getFilterState,
  setFilterValue,
  applyFiltersToGames,
  applyFiltersToActivities,
} from 'fiba/wt/ui/schedule/scheduleUtils';
import {ScheduleFilters} from 'fiba/wt/ui/schedule/ScheduleFilters';
import {ScheduleTimezoneSwitcher} from 'fiba/wt/ui/schedule/ScheduleTimezoneSwitcher';
// @ts-ignore Import and re-exporting type
import {ScheduleGames, ScheduleExtraColumnType} from 'fiba/wt/ui/schedule/ScheduleGames';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {SubHeading, Section} from 'fiba/wt/ui/heading/Heading';
import {getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {ENABLED} from 'fiba/common/utils/constants';

interface OwnProps {
  id?: string; // Mandatory if `filters` exist
  eventId: string;
  games?: List<ResultsGameSummary>;
  activities?: List<EventActivity>;
  categories?: List<Category>;
  courts?: List<Court>;
  filters?: ScheduleFilterType[];
  extraColumns?: ScheduleExtraColumnType[];
  createTeamHref?: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
  showActivityToggle?: boolean;
}

interface ReduxProps {
  filterState: ScheduleFilterState;
  isScheduleEventTime: boolean;
  eventConfiguration: EventConfiguration;
}

interface DispatchProps {
  setFilterValue: (id: string, filterType: ScheduleFilterType, value: string) => any;
}

export const mapStateToProps = (state, {id, eventId}: OwnProps): ReduxProps => ({
  filterState: getFilterState(state, id),
  isScheduleEventTime: state.getIn(['uiConfig', 'isScheduleEventTime'], true),
  eventConfiguration: getEventConfiguration(state, eventId),
});

export const mapDispatchProps = (dispatch, {id}: OwnProps): DispatchProps => ({
  setFilterValue: (id, filterType, value) => dispatch(setFilterValue(id, filterType, value)),
});

type Props = OwnProps & ReduxProps & DispatchProps;

const _Schedule: React.FC<Props> = ({
  id,
  games = List(),
  activities = List(),
  categories = List(),
  courts = List(),
  filters = [],
  extraColumns = [],
  isScheduleEventTime,
  createTeamHref,
  createGameHref,
  filterState,
  setFilterValue,
  eventConfiguration,
}: Props) => {
  const [activityFilter, setActivityFilter] = React.useState('allActivities');

  if (games.size === 0) {
    return <p className="tc">The game schedule will be announced later. </p>;
  }

  const hideExactGameTimesEnabled = eventConfiguration?.hideExactGameTimes?.includes(ENABLED);
  const hideGamePairingsFrom = eventConfiguration?.hideGamePairingsFrom;
  const hideGamePairingsUntil = eventConfiguration?.hideGamePairingsUntil;
  const hideWomenGamePairingsFrom = eventConfiguration?.hideWomenGamePairingsFrom;
  const hideWomenGamePairingsUntil = eventConfiguration?.hideWomenGamePairingsUntil;

  return (
    <Spacer vSpace="4" debugClassName="Schedule">
      <Spacer vSpace="3">
        <Section>
          <Spacer vSpace={['3', '3', '4']}>
            {filters.length > 0 && (
              <ScheduleFilters
                id={id}
                games={games}
                filterState={filterState}
                categories={categories}
                courts={courts}
                filters={filters}
                setFilterValue={setFilterValue}
              />
            )}

            <ScheduleTimezoneSwitcher games={games} />

            {!!activities.count() && (
              <Box debugClassName="Schedule-ActivitySwitch">
                <PillSelect
                  controlLabel="Activity visibility:"
                  inputNameId="schedule-activity-picker"
                  options={[
                    {label: 'All activities', value: 'allActivities'},
                    {label: 'Games only', value: 'gamesOnly'},
                  ]}
                  initial="allActivities"
                  onChange={selected => setActivityFilter(selected)}
                />
              </Box>
            )}
          </Spacer>
        </Section>
      </Spacer>

      <Spacer vSpace="3">
        <SubHeading>Schedule</SubHeading>
        <Section>
          <Box debugClassName="Schedule-Games">
            <ScheduleGames
              games={applyFiltersToGames(games, filterState)}
              activities={applyFiltersToActivities(activities, filterState)}
              hideCourtName={courts.size < 2}
              hideCategoryName={categories.size < 2}
              extraColumns={extraColumns}
              isScheduleEventTime={isScheduleEventTime}
              showOnlyGames={activityFilter === 'gamesOnly'}
              createTeamHref={createTeamHref}
              createGameHref={createGameHref}
              hideExactGameTimesEnabled={hideExactGameTimesEnabled}
              categories={categories}
              hideGamePairingsFrom={hideGamePairingsFrom}
              hideWomenGamePairingsFrom={hideWomenGamePairingsFrom}
              hideGamePairingsUntil={hideGamePairingsUntil}
              hideWomenGamePairingsUntil={hideWomenGamePairingsUntil}
            />
          </Box>
        </Section>
      </Spacer>
    </Spacer>
  );
};

export const Schedule = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchProps,
)(_Schedule);
