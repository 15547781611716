import React, {useState, useRef, useEffect} from 'react';

// Running useLayoutEffect on the server will throw an error. To avoid this,
// replace it with useEffect which has an identical function signature.
// https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85?permalink_comment_id=4150784#gistcomment-4150784
if (typeof window === 'undefined') {
  React.useLayoutEffect = useEffect;
}

type Props = LabelOrId;

export interface State {
  isScrollable?: boolean;
}

/** Full args to the render callback */
interface Return {
  containerProps: ContainerProps;
  isScrollable: boolean;
}

/** Props to the render callback */
type ContainerProps = {
  ref: (ref: HTMLElement) => void;
  className: string;
  tabIndex: number;
  role?: 'group';
  'aria-label'?: string;
  'aria-labelledby'?: string;
};

type LabelOrId =
  | {'aria-label': string; 'aria-labelledby'?: never}
  | {'aria-labelledby': string; 'aria-label'?: never};

/**
 * Custom hook that notifies us whether the component overflows on mount.
 * Used for horizontal scroll regions, and setting the correct roles and labels, so that content remains accessible by keyboard.
 */
export function useHorizontalScrollRegion(props: Props): Return {
  const [isScrollable, setScrollable] = useState(false);

  // Use a "callback ref" to ensure that the ref exists on initial render
  // This is a tricky and annoying concept
  // @see https://github.com/facebook/react/issues/14387 and https://github.com/facebook/react/issues/14387#issuecomment-462303348
  // for why we can't pass a "ref" from outside to this hook, and need this way
  const [ref, setRef] = useState<HTMLElement>(null);

  const observerRef = useRef<ResizeObserver>(null);

  // Create a persistent observer
  // It will set the scrollable state when the element is wider than the viewport
  if (typeof window !== 'undefined' && 'ResizeObserver' in window) {
    // Do not re-create observer, if we alerady have one
    if (!observerRef.current) {
      observerRef.current = new (window as Window &
        typeof globalThis &
        WindowWithResizeObserver).ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }
          const entry = entries[0];
          const {scrollWidth, clientWidth} = entry.target;
          const isScrollable = scrollWidth > clientWidth;

          setScrollable(isScrollable);
        });
      });
    }
  }

  // When the ref changes, observe the element
  //
  // See the additional comment on top the file.
  React.useLayoutEffect(() => {
    if (ref && observerRef.current) {
      observerRef.current.observe(ref);

      // On effect clear, unobserve the element
      return () => {
        observerRef.current.unobserve(ref);
      };
    }
  }, [ref]);

  return {
    isScrollable,
    containerProps: {
      className: 'overflow-x-auto scroll-touch focus-shadow',
      ref: setRef,
      tabIndex: isScrollable ? 0 : null,
      role: isScrollable ? 'group' : null,
      'aria-label': isScrollable ? props['aria-label'] : null,
      'aria-labelledby': isScrollable ? props['aria-labelledby'] : null,
    },
  };
}

/**
 * The ResizeObserver interface is used to observe changes to Element's content
 * rect.
 *
 * It is modeled after MutationObserver and IntersectionObserver.
 */
interface ResizeObserverInterface {
  new (callback: ResizeObserverCallback);

  /**
   * Adds target to the list of observed elements.
   */
  observe: (target: Element) => void;

  /**
   * Removes target from the list of observed elements.
   */
  unobserve: (target: Element) => void;

  /**
   * Clears both the observationTargets and activeTargets lists.
   */
  disconnect: () => void;
}

interface WindowWithResizeObserver {
  ResizeObserver: ResizeObserverInterface;
}

/**
 * This callback delivers ResizeObserver's notifications. It is invoked by a
 * broadcast active observations algorithm.
 */
interface ResizeObserverCallback {
  (entries: ResizeObserverEntry[], observer: ResizeObserver): void;
}

interface ResizeObserverEntry {
  /**
   * @param target The Element whose size has changed.
   */
  new (target: Element);

  /**
   * The Element whose size has changed.
   */
  readonly target: Element;

  /**
   * Element's content rect when ResizeObserverCallback is invoked.
   */
  readonly contentRect: DOMRectReadOnly;
}

interface DOMRectReadOnly {
  fromRect(other: DOMRectInit | undefined): DOMRectReadOnly;

  readonly x: number;
  readonly y: number;
  readonly width: number;
  readonly height: number;
  readonly top: number;
  readonly right: number;
  readonly bottom: number;
  readonly left: number;

  toJSON: () => any;
}
