import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {List} from 'immutable';
import {FieldSort} from 'fiba/wt/ui/dataTable/DataTable';
import * as ProCircuitPlayerStats from 'fiba/wt/stores/proCircuitPlayerStatsStore';
import {PlayerStatsTable} from 'fiba/wt/ui/statistics/PlayerStatsTable';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';
import {tourTeamLinkProvider, tourTeamPlayerLinkProvider} from 'fiba/wt/utils/linkUtils';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

interface OwnProps {
  tourId: string;
  season: string;
  eventId: string;
}

interface StateProps {
  stats: List<ProCircuitPlayerStats.PlayerStatRow>;
  sortedBy: FieldSort;
  updatedAt?: string;
  filteredData: List<ProCircuitPlayerStats.PlayerStatRow>;
  metaPaths: string[];
}

interface DispatchProps {
  changeSorting: (field: string, columnsSpec) => any;
  changeFiltering: (filterBy: FilterOptions) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const fieldToList = (data, field) => {
  const fieldValue = data && field(data.toJS());
  return (data && fieldValue && List.of(...fieldValue)) || List();
};

const mapStateToProps = (state, {tourId}: OwnProps): StateProps => {
  const loaded = state.getIn(ProCircuitPlayerStats.globalStatePath(tourId));
  const metaPaths = [ProCircuitPlayerStats.globalMetaPath(tourId).join('/')];

  return {
    stats: fieldToList(loaded, js => js.data),
    updatedAt: loaded && loaded.get('updatedAt'),
    sortedBy: loaded && loaded.get('sortedBy', undefined),
    filteredData: fieldToList(loaded, js => js.filteredData),
    metaPaths,
  };
};

const mapDispatchToProps = (dispatch, {tourId}: OwnProps): DispatchProps => ({
  changeSorting: (field: string, columnsSpec) => {
    dispatch(
      ProCircuitPlayerStats.actions.sortStats(
        ProCircuitPlayerStats.statePath(tourId),
        field,
        columnsSpec,
      ),
    );
  },
  changeFiltering: (filterBy: FilterOptions) => {
    dispatch(
      ProCircuitPlayerStats.actions.filterStats(ProCircuitPlayerStats.statePath(tourId), filterBy),
    );
  },
});

const _ProCircuitPlayerStatistics: React.FunctionComponent<Props> = ({
  stats,
  sortedBy,
  updatedAt,
  changeSorting,
  changeFiltering,
  filteredData,
  tourId,
  metaPaths,
  season,
  eventId,
}) => {
  return (
    <div>
      <Loading metaPaths={metaPaths}>
        {() => (
          <PlayerStatsTable
            stats={stats}
            captionId={`${tourId}-player-stats`}
            filteredData={filteredData}
            sortedBy={sortedBy}
            updatedAt={updatedAt}
            sortAction={changeSorting}
            filterAction={changeFiltering}
            season={season}
            createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
            renderTeamName={rowData =>
              /* At the tour level, make sure that we do not link non-WT teams.
               * This might change in the future.
               */
              rowData.team.isTourTeam ? (
                <StyledLink href={tourTeamLinkProvider(season)(rowData.team.teamId)}>
                  <TeamNameAssembly
                    standing={rowData['team']}
                    isSuffixVisible
                    isIocVisible={false}
                  />
                </StyledLink>
              ) : (
                <TeamNameAssembly standing={rowData['team']} isSuffixVisible isIocVisible={false} />
              )
            }
            eventId={eventId}
          />
        )}
      </Loading>
    </div>
  );
};

export const ProCircuitPlayerStatistics = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(_ProCircuitPlayerStatistics);

// Curry TourTeamPlayerLinkProvider since we need to have  teamId and playerId from rowData in playerStatisticsTable
const curriedTourTeamPlayerLinkProvider = (season: string) => {
  return (teamId: string) => {
    return (playerId: string) => {
      return tourTeamPlayerLinkProvider(season, teamId)(playerId);
    };
  };
};
