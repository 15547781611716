/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultCategory = {
  isTeamsHidden: null,
  id: null,
  categoryName: null,
  eventId: null,
  status: null,
  registrationType: null,
  startDatetime: null,
  endDatetime: null,
  registrationStartDatetime: null,
  registrationEndDatetime: null,
  name: null,
  categoryColor: null,
  gender: null,
  teamsMaximum: null,
  teamsCurrentlyRegistered: null,
  priceCurrencySymbol: null,
  priceCurrencyCode: null,
  price: null,
  externalRegistrationUrl: null,
  timingStatus: null,
  registrationEligibilityText: null,
  registrationEligibilityDescription: null,
  minimumAge: null,
  maximumAge: null,
  paymentType: null,
  paypalPaymentHref: null,
  cannotRegisterToCategory: null,
  cannotRegisterToCategoryReason: null,
  classificationType: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type CategoryLike = {
  [K in keyof typeof defaultCategory]?: Category[K];
};

export class Category extends Record(defaultCategory) {
  isTeamsHidden?: boolean;
  id?: string;
  categoryName?: string;
  eventId?: string;
  status?: string;
  registrationType?: string;
  startDatetime?: string;
  endDatetime?: string;
  registrationStartDatetime?: string;
  registrationEndDatetime?: string;
  name?: string;
  categoryColor?: string;
  gender?: 'Male' | 'Female';
  teamsMaximum?: number;
  teamsCurrentlyRegistered?: number;
  priceCurrencySymbol?: string;
  priceCurrencyCode?: string;
  price?: number;
  externalRegistrationUrl?: string;
  timingStatus?: string;
  registrationEligibilityText?: string;
  registrationEligibilityDescription?: string;
  minimumAge?: number;
  maximumAge?: number;
  paymentType?: string;
  paypalPaymentHref?: string;
  cannotRegisterToCategory?: boolean;
  cannotRegisterToCategoryReason?: string;
  classificationType?: string;
  static fromJS(o: CategoryLike): Category {
    return (
      o &&
      new Category({
        isTeamsHidden: o.isTeamsHidden,
        id: o.id,
        categoryName: o.categoryName,
        eventId: o.eventId,
        status: o.status,
        registrationType: o.registrationType,
        startDatetime: o.startDatetime,
        endDatetime: o.endDatetime,
        registrationStartDatetime: o.registrationStartDatetime,
        registrationEndDatetime: o.registrationEndDatetime,
        name: o.name,
        categoryColor: o.categoryColor,
        gender: o.gender,
        teamsMaximum: o.teamsMaximum,
        teamsCurrentlyRegistered: o.teamsCurrentlyRegistered,
        priceCurrencySymbol: o.priceCurrencySymbol,
        priceCurrencyCode: o.priceCurrencyCode,
        price: o.price,
        externalRegistrationUrl: o.externalRegistrationUrl,
        timingStatus: o.timingStatus,
        registrationEligibilityText: o.registrationEligibilityText,
        registrationEligibilityDescription: o.registrationEligibilityDescription,
        minimumAge: o.minimumAge,
        maximumAge: o.maximumAge,
        paymentType: o.paymentType,
        paypalPaymentHref: o.paypalPaymentHref,
        cannotRegisterToCategory: o.cannotRegisterToCategory,
        cannotRegisterToCategoryReason: o.cannotRegisterToCategoryReason,
        classificationType: o.classificationType,
      })
    );
  }
}

export default Category;
