import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import {
  NationalTeamEventsLike,
  NationalTeamEvents,
} from 'fiba/common/core/models/api/NationalTeamEvents';

export interface StoreState extends Map<string, NationalTeamEventsLike> {
  //   __meta: {
  //     season: {
  //       data: [...],
  //     },
  //   },
  // };
}

const reducers = {
  loadSeasonEvents: (state: StoreState, season: string): StoreState =>
    state.updateIn(['__meta', season], setMeta.isLoading),

  loadSeasonEventsSuccess: (
    state: StoreState,
    season: string,
    events: NationalTeamEventsLike,
  ): StoreState =>
    state
      .set(season, NationalTeamEvents.fromJS(events))
      .updateIn(['__meta', season], setMeta.isLoaded),

  loadSeasonEventsError: (state: StoreState, season: string, _error: Error): StoreState =>
    state.updateIn(['__meta', season], setMeta.isError),
};

export const reducer = createReducer<StoreState>(__filename, fromJS({}), reducers);

const actions = createActions(__filename, reducers, {
  loadSeasonEvents: (season: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadNationalTeamSeasonEvents('cups', season)
      .then(res => actions.loadSeasonEventsSuccess(season, res.data))
      .catch(rethrow((err: Error) => actions.loadSeasonEventsError(season, err))),

  loadSeasonEventsError: (_season: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<StoreState>(NationalTeamEvents);
