import React from 'react';
import cx from 'classnames';
import {LazyImage} from 'react-lazy-images';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Placeholder, PlaceholderStyleProps} from 'fiba/wt/ui/placeholder/Placeholder';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Image} from '@fpapado/react-feather';

interface Props {
  src: string;
  alt: string;
  aspectRatio: StyleProp<'aspectRatio'>;
  srcSet?: string;
  className?: string;
  containerClassName?: string;
  containerStyle?: PlaceholderStyleProps;
  loadEagerly?: boolean;
}

// TODO: placeholder image, error fallback image
/** Pre-composed version of Placeholder, Imgix, and LazyImage */
/** A Lazy image with a placeholder */
export const LazyImageWithPlaceholder: React.FC<Props> = ({
  aspectRatio,
  containerClassName,
  containerStyle,
  className,
  loadEagerly,
  ...imageProps
}) => (
  // The container handles the aspectRatio
  <Placeholder
    aspectRatio={aspectRatio}
    extraClassName={containerClassName}
    {...containerStyle}
    image={placeholderItemCls => (
      <React.Fragment>
        {/* Pass the sources and image params to LazyImage */}
        <LazyImage
          {...imageProps}
          // Render a box if not loaded
          placeholder={({ref}) => <div ref={ref} className={cx(placeholderItemCls, className)} />}
          // Render an `img` with the relevant image props (src, alt etc.)
          actual={({imageProps}) => (
            <img
              {...imageProps}
              className={cx(placeholderItemCls, className, 'animated fade-in')}
            />
          )}
          // Render a basic "error message
          error={() => (
            <Flex
              pa="3"
              alignItems="center"
              justifyContent="center"
              extraClassName={placeholderItemCls}
            >
              <Image
                className="w3 ht3"
                purpose="standalone"
                aria-label="We could not load this image."
              />
            </Flex>
          )}
          loadEagerly={loadEagerly}
          debounceDurationMs={800}
          experimentalDecode={true}
        />
        {/* Show the actual image outright if no JS.
         * We progressively enhance so that loading=lazy kicks in, if supported
         */}
        <noscript>
          <img
            {...imageProps}
            className={cx(placeholderItemCls, className)}
            {...{loading: loadEagerly === true ? 'eager' : 'lazy'}}
          />
        </noscript>
      </React.Fragment>
    )}
  />
);
