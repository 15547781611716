import React from 'react';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {ThemeContextConsumer} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Box} from 'fiba/wt/ui/box/Box';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {ImgixImageWithPlaceholder} from 'fiba/wt/ui/imgix/ImgixImageWithPlaceholder';
import {Article} from 'fiba/wt/ui/article/Article';
import {PublishedDate} from 'fiba/wt/ui/publishedDate/PublishedDate';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';

interface Props {
  newsItem: NewsItem;
}

/* Almost full viewport at mobile and -s, ballpark 100vw for -md.
 * For -l and above, take the minimum of mw9 (the container) - the sidebar, or the viewport.
 *
 * Extrinsic from Responsive Image Lint.
 * Does not really account for EventNews (another sidebar), but that is ok.
 */
const imageSizes =
  '(min-width: 1620px) 1216px, (min-width: 780px) calc(92.2vw - 259px), (min-width: 500px) calc(100vw - 32px), 100vw';

// TODO: tags/listTags
export const NewsArticle: React.FC<Props> = ({newsItem}) => (
  <CardContainer debugClassName="NewsArticle">
    <article>
      <Box mnh={['3', '3', '4', '4']} mnt={['3', '3', '4', '4']}>
        <ImgixImageWithPlaceholder
          key={`${newsItem.id}-wide`}
          baseUrl={newsItem.imageBaseUrl}
          aspectRatio={[6, 4]}
          sizes={imageSizes}
          // Intentionally leave it blank, so that screen readers ignore it
          // and do not read out the filename. We do not have useful descriptions
          // of these, and they do not contribute to the content anyway.
          alt=""
        />
      </Box>
      <ThemeContextConsumer>
        {theme => (
          <Box
            pv={['4', '4', '4', '5']}
            borderRadius="1"
            color={theme.color}
            debugClassName="NewsArticle-ArticleContainer"
          >
            {/* Font-size mainly set for correct width in measure calculation: Article has
                  max-width of 38em (measure-very-wide) and font-size of 18px (f5)
                */}
            <Box
              pb={['4', '4', '4', '5']}
              align="center"
              fontSize={['6', '6', '5']}
              measure="very-wide"
            >
              <Heading fontSize={['4', '4', '2', '2']}>{newsItem.title}</Heading>
              <BlockText fontSize={['7', '7', '5']} mt="3">
                <PublishedDate date={newsItem.date} />
              </BlockText>
            </Box>

            <Article align="center">
              <div dangerouslySetInnerHTML={{__html: newsItem.content}} />
            </Article>
          </Box>
        )}
      </ThemeContextConsumer>
    </article>
  </CardContainer>
);
