import Category from 'fiba/common/core/models/api/events/Category';

export type CategoryGenderTab = 'men' | 'women' | '';

export const filterCategoryByGender = (tab: CategoryGenderTab) => (category: Category): boolean => {
  switch (tab) {
    case 'women': {
      return category.gender === 'Female';
    }
    case 'men': {
      return category.gender === 'Male';
    }
    default: {
      // by default return true, so there is no filtering:
      return true;
    }
  }
};
