import moment from 'moment';
import {Th} from 'fiba/wt/ui/table/Table';
import React from 'react';

export const valueOrPlaceholder = value => (value == null ? '' : value);
export const decimalOrPlaceholder = (value, digits = 2) =>
  valueOrPlaceholder(value && value.toFixed(digits));
export const asTimeStamp = millisecs => millisecs && moment.utc(millisecs).format('mm:ss');

export const hcell = field => ({rowData, Td, getTdProps}) => (
  <Th {...getTdProps({})} scope={'row'}>
    {valueOrPlaceholder(rowData[field])}
  </Th>
);

export const cell = field => ({rowData, Td, getTdProps}) => (
  <Td {...getTdProps({})}>{valueOrPlaceholder(rowData[field])}</Td>
);
