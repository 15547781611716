import React from 'react';
import cx from 'classnames';
import {createStyleProvider, StyledAPI, StyleProps} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Box} from 'fiba/wt/ui/box/Box';

// Tuning knobs for this component
type PlaceholderStylingAPI = StyledAPI<
  'bgColor' | 'aspectRatio' | 'color' | 'borderRadius' | 'border' | 'borderColor' | 'borderWidth'
>;

export type PlaceholderStyleProps = StyleProps<PlaceholderStylingAPI> & {extraClassName?: string};

interface PlaceholderOwnProps {
  image: (cls: string) => React.ReactElement<any>;
}

type PlaceholderProps = PlaceholderOwnProps & PlaceholderStyleProps;

const defaultStyleProps: PlaceholderStyleProps = {
  bgColor: 'silver-40',
  color: 'dark-40',
  aspectRatio: '6x4',
};

// TODO: consider full-on HoC
// TODO: Wth, we can add these directly to Box. Past self, what did you have in mind?
const StyleProvider = createStyleProvider<PlaceholderStyleProps>(defaultStyleProps);

export const Placeholder: React.FunctionComponent<PlaceholderProps> = ({
  image,
  extraClassName,
  ...rest
}) => (
  <StyleProvider {...rest}>
    {({className}) => (
      <Box
        extraClassName={cx(className, extraClassName)}
        display="block"
        position="relative"
        width="100"
        height="0"
      >
        {image('absolute top-0 left-0 w-100 ht-100')}
      </Box>
    )}
  </StyleProvider>
);

Placeholder.displayName = 'Placeholder';
