import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import {bind} from 'fiba/common/stores/storeUtils';
import eventController, {EventControllerFeatures} from 'fiba/wt/controllers/eventController';
import categoryController, {
  CategoryControllerFeatures,
} from 'fiba/wt/controllers/categoryController';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import teamActions, {getTeamNewsMetaPathParts} from 'fiba/wt/stores/teamStore';
import newsActions from 'fiba/wt/stores/newsStore';

interface TeamControllerFeatures {
  payment?: boolean;
  newsForEvent?: string;
  event?: EventControllerFeatures;
  category?: CategoryControllerFeatures;
}

function teamController(teamId: string, features: TeamControllerFeatures) {
  return ({dispatch, cache}: CommonServices) => {
    // Extra features that can be loaded with team
    const featureLoaders = {
      payment: () =>
        cache(['teams', 'payment', teamId], () => dispatch(teamActions.loadTeamPayment(teamId))),

      // Special version of news that also loads news related to each player
      newsForEvent: (eventId: string, team: Team) => {
        return bind([
          cache(getTeamNewsMetaPathParts(teamId, eventId), () =>
            dispatch(newsActions.loadNewsByTeam(teamId, eventId)),
          ),
        ]);
      },

      event: (features: EventControllerFeatures, team: Team) =>
        dispatch(eventController(team.eventId, features)),

      category: (features: CategoryControllerFeatures, team: Team) =>
        dispatch(categoryController(team.categoryId, features)),
    };

    return cache(['teams', teamId], () => dispatch(teamActions.loadTeam(teamId))).then(() =>
      loadFeatures(featureLoaders, features, cache(['teams', teamId])),
    );
    // Don't set a catch handler, but let the errors ripple back to router
  };
}

export default teamController;
