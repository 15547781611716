import React from 'react';
import {Props as DataTableProps, DataTable} from 'fiba/wt/ui/dataTable/DataTable';
import {FilterInput} from 'fiba/wt/ui/filterInput/FilterInput';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';
import {Box} from 'fiba/wt/ui/box/Box';
import {P} from 'fiba/wt/ui/text/Text';

interface FilteredTableProps {
  min: number;
  max: number;
  field: string;
  fieldDisplayName: string;
  filterAction: (filterBy: FilterOptions) => any;
}

export type FilteredDataTableProps<CH extends string, RD> = DataTableProps<CH, RD> &
  FilteredTableProps;

const renderFilter = ({max, min, field, fieldDisplayName, filterAction}) => (
  <div className="mw6">
    <P fontWeight="6" mb="3">
      Filter stats by {fieldDisplayName}
    </P>
    <Box ml="2" mb="4">
      <FilterInput max={max} min={min} field={field} filterAction={filterAction} />
    </Box>
  </div>
);

export const FilteredDataTable = <CH extends string, RD>(props: FilteredDataTableProps<CH, RD>) => {
  const {max, min, field, filterAction, fieldDisplayName, ...dataTableProps} = props;
  return (
    <DataTable
      {...dataTableProps}
      renderTop={() => renderFilter({max, min, field, filterAction, fieldDisplayName})}
    />
  );
};
