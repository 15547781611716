import React from 'react';
import {ChevronDown, ChevronUp} from '@fpapado/react-feather';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {useToggle} from 'fiba/wt/ui/hooks/useToggle';

interface RenderToggleArgs {
  open: boolean;
  toggle: () => void;
  toggleProps: {
    'aria-expanded': boolean;
  };
}

interface IArrowCollapsingPanel {
  title: React.ReactNode;
}

interface ICollapsingPanel {
  /** For supplying a custom element which has a callback to toggle the state */
  renderToggle: (args: RenderToggleArgs) => React.ReactNode;
}

/** Simple collapsing panel with clickable title button and an arrow. */
export const ArrowCollapsingPanel: React.FunctionComponent<IArrowCollapsingPanel> = ({
  title,
  children,
}) => (
  <CollapsingPanel
    renderToggle={({open, toggle, toggleProps}) => (
      <button
        {...toggleProps}
        onClick={toggle}
        className="pa0 w-100 button-reset CollapsingPanel-Toggle"
      >
        <Flex
          pa="3"
          justifyContent="between"
          alignItems="center"
          border="bottom"
          borderWidth="1"
          borderColor="silver-20"
          bgColor={open ? 'silver-30' : 'inherit'}
        >
          <SubHeading fontWeight={open ? '6' : '4'} fontSize="6">
            {title}
          </SubHeading>
          <span className="CollapsingPanel-ToggleIcon">
            {open ? <ChevronUp purpose="decorative" /> : <ChevronDown purpose="decorative" />}
          </span>
        </Flex>
      </button>
    )}
  >
    {children}
  </CollapsingPanel>
);

/**
 * Flexible Collapsing Panel base that handles important state and markup,
 * and defers rendering to the caller via render props.
 * Use this to build custom panels on top.
 */
export const CollapsingPanel: React.SFC<ICollapsingPanel> = ({renderToggle, children}) => {
  const {on: open, toggle} = useToggle({initial: false});

  return (
    <React.Fragment>
      {renderToggle({open, toggle, toggleProps: {'aria-expanded': open}})}
      <Box display={open ? 'block' : 'none'} debugClassName="CollapsingPanel-Panel">
        {children}
      </Box>
    </React.Fragment>
  );
};
