import React from 'react';

export function useOnClickOutside(el: HTMLElement, handler: (ev?: MouseEvent) => void) {
  React.useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!el || el.contains(event.target as HTMLElement)) {
        // do not trigger if the event originates from a descendant element
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [el, handler]);
}
