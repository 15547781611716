import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {TourTeamLayout} from 'fiba/wt/ui/tourTeamPage/TourTeamLayout';
import {TourTeamOverview} from 'fiba/wt/ui/tourTeamPage/TourTeamOverview';

interface ITourTeamPage {
  tourId: string;
  season: string;
  teamId: string;
}

export const TourTeamPage: React.FunctionComponent<ITourTeamPage> = ({season, tourId, teamId}) => (
  <TourTeamLayout tourId={tourId} season={season} teamNavBehavior="navigate">
    <Box
      ph={['0', '3']}
      pb="3"
      pt="0"
      width="100"
      color="dark-50"
      extraClassName="TourTeamsPage-Team"
    >
      <TourTeamOverview tourId={tourId} season={season} teamId={teamId} />
    </Box>
  </TourTeamLayout>
);
