import React from 'react';

type IFauxButton = {
  tag: string;
  className?: string;
  onInteraction?: () => any;
} & React.HTMLAttributes<HTMLButtonElement>;

/**
 * Accessible "button" usurper.
 * Establishes the correct role, tabindex, and key/mouse interaction if interactive.
 */
export const FauxButton: React.FunctionComponent<IFauxButton> = ({
  tag,
  className,
  onInteraction,
  children,
  ...rest
}) =>
  React.createElement(
    tag,
    {
      role: onInteraction ? 'button' : null,
      tabIndex: onInteraction ? '0' : null,
      className,
      onClick: onInteraction,
      onKeyDown: handleKeyActivation(onInteraction),
      ...rest,
    },
    children,
  );

// Handle the keyboard activation same as a button
const handleKeyActivation = (cb: (ev: KeyboardEvent) => any) => (event: KeyboardEvent) => {
  switch (event.key) {
    case 'Enter':
    case ' ':
      event.preventDefault();
      cb(event);
      break;
  }
};
