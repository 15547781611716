import {AxiosPromise} from 'axios';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import * as Anomaly from 'fiba/wt/utils/Anomaly';

/**
 * Transform a Promise with an Axios response to a WebData response
 *
 * @example
 * loadEvent(eventId: string) {
 *   return axiosPromiseToWebDataPromise(
 *    this.axios.get(url`/v2/event/${eventId}`)
 *   );
 * }
 */
const axiosPromiseToWebDataPromise = <Data extends {}>(
  promise: AxiosPromise<Data>,
): Promise<RemoteData.WebData<Data>> => {
  return (
    promise
      // Status in the 2xx range
      .then(axiosResponse => {
        return RemoteData.Success(axiosResponse.data);
      })
      .catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          return RemoteData.Failure(Anomaly.fromHttpStatusCode(error.response.status));
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          // TODO: Consider 'Fault'
          return RemoteData.Failure(Anomaly.Unavailable());
        } else {
          // Something happened in setting up the request that triggered an Error
          return RemoteData.Failure(Anomaly.Fault());
        }
      })
  );
};

export default axiosPromiseToWebDataPromise;
