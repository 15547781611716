import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import gameStatActions from 'fiba/wt/stores/gameStatsStore';
import gameActions from 'fiba/wt/stores/gameStore';

export interface GameControllerFeatures {
  summary: boolean;
  stats?: boolean;
  playByPlayTableOfficialsData?: boolean;
  playByPlayStatisticsData?: boolean;
}

function gameController(eventId: string, gameId: string, features: GameControllerFeatures) {
  return ({dispatch, cache}: CommonServices) => {
    const featureLoaders = {
      stats: () =>
        cache(['games', 'gameStats', gameId], () =>
          dispatch(gameStatActions.loadGameStats(gameId)),
        ),

      summary: () =>
        cache(['games', 'summary', gameId], () =>
          dispatch(gameActions.loadGamesSummaryByEvent(eventId)),
        ),

      playByPlayTableOfficialsData: () => {
        return cache(['games', 'playByPlayTableOfficialsData', gameId], () =>
          dispatch(gameActions.loadGamePlayByPlayTableOfficialsData(gameId)),
        );
      },

      playByPlayStatisticsData: () => {
        return cache(['games', 'playByPlayStatsData', gameId], () =>
          dispatch(gameActions.loadGamePlayByPlayStatsData(gameId)),
        );
      },
    };

    return cache(['games', 'details', gameId], () =>
      dispatch(gameActions.loadGameDetails(eventId, gameId)),
    ).then(() => loadFeatures(featureLoaders, features, cache(['games', 'details', gameId])));
  };
}

export default gameController;
