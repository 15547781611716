/* Map Styling API values to CSS classes that set them */
import {mapValues, assign} from 'lodash';

export const responsiveSuffixes = ['', '-s', '-m', '-l'];

/* NOTE: use sparingly, may break in hilarious ways */
// Further note: if we generated our stuff from JS, on the other hand,
// this would be perfectly safe. PostCSS?
export const dangerousViewportValues = ['', '480px', '768px', '1024px'];

// Keeping these keys tied to scaleWidth means they are less likely to get out of sync
const widthRemMap: Record<keyof typeof scaleWidth, string> = {
  0: '0',
  1: '1rem',
  2: '2rem',
  3: '4rem',
  4: '8rem',
  5: '16rem',
  '5-half': '24rem',
  6: '32rem',
};

export enum ResponsiveUtils {
  Display,
  VSpace,
  FontWeight,
  Height,
  MaxWidth,
  Position,
  Padding,
  Margin,
  TextAlign,
  TypeScale,
  LineHeight,
  Width,
  Flex,
  FlexGrow,
  FlexShrink,
  FlexOrder,
  AlignSelf,
  FlexDirection,
  FlexWrap,
  AlignItems,
  AlignContent,
  JustifyContent,
}

/** Convert a styled width to a rem value.
 * Used for passing widths to sizes.
 * @see widths.scss
 */
export const dangerousWidthToRem = (i: keyof typeof widthRemMap) => widthRemMap[i];
/* Danger end */

// TODO: readonly and/or enums?
// TODO: consider generating these
const colors = {
  // Utility
  transparent: 'transparent',
  inherit: 'color-inherit',
  fullwhite: 'fullwhite',
  fullblack: 'fullblack',
  // Brand color
  sand: 'sand',
  // Semantic colors @see themes.scss
  fg: 'fg',
  fg_pale: 'fg-pale',
  bg: 'bg',
  'blue-electric': 'blue-electric',
  'green-highlight': 'green-highlight',
  // New colours
  'dark-10': 'dark-10',
  'dark-20': 'dark-20',
  'dark-30': 'dark-30',
  'dark-40': 'dark-40',
  'dark-50': 'dark-50',
  'steel-10': 'steel-10',
  'steel-20': 'steel-20',
  'steel-30': 'steel-30',
  'steel-40': 'steel-40',
  'steel-50': 'steel-50',
  'green-10': 'green-10',
  'green-20': 'green-20',
  'green-30': 'green-30',
  'green-40': 'green-40',
  'green-50': 'green-50',
  'purple-10': 'purple-10',
  'purple-20': 'purple-20',
  'purple-30': 'purple-30',
  'purple-40': 'purple-40',
  'purple-50': 'purple-50',
  'pink-20': 'pink-20',
  'blue-10': 'blue-10',
  'blue-20': 'blue-20',
  'blue-30': 'blue-30',
  'blue-40': 'blue-40',
  'blue-50': 'blue-50',
  'red-10': 'red-10',
  'red-20': 'red-20',
  'red-30': 'red-30',
  'red-40': 'red-40',
  'red-50': 'red-50',
  'gold-10': 'gold-10',
  'gold-20': 'gold-20',
  'gold-30': 'gold-30',
  'gold-40': 'gold-40',
  'gold-50': 'gold-50',
  'silver-10': 'silver-10',
  'silver-20': 'silver-20',
  'silver-30': 'silver-30',
  'silver-40': 'silver-40',
  'silver-50': 'silver-50',
  'clay-10': 'clay-10',
  'clay-20': 'clay-20',
  'clay-30': 'clay-30',
  'clay-40': 'clay-40',
  'clay-50': 'clay-50',
  'aqua-10': 'aqua-10',
  'aqua-20': 'aqua-20',
  'aqua-30': 'aqua-30',
  'aqua-40': 'aqua-40',
  'aqua-50': 'aqua-50',
  'play-bg': 'play-bg',
  'blue-electric-darker': 'blue-electric-darker',
};

export const FgColor = colors;

export const HoverColor = mapValues(colors, color => `hover-${color}`);
export const HoverBgColor = mapValues(colors, color => `hover-bg-${color}`);

export const FocusColor = mapValues(colors, color => `focus-${color}`);
export const FocusBgColor = mapValues(colors, color => `focus-bg-${color}`);

export const BgColor = assign(
  mapValues(colors, color => `bg-${color}`),
  {
    gradient_blue: 'bg-gradient-blue',
    gradient_black: 'bg-gradient-black',
    inherit: 'bg-inherit',
  },
);

export const BorderColor = assign(
  mapValues(colors, color => `b--${color}`),
  {
    inherit: 'b--inherit',
  },
);

export const Opacity = {
  '0': 'o-0',
  '10': 'o-10',
  '20': 'o-20',
  '30': 'o-30',
  '40': 'o-40',
  '50': 'o-50',
  '60': 'o-60',
  '70': 'o-70',
  '80': 'o-80',
  '90': 'o-90',
  '100': 'o-100',
};

//
// Width
const scaleWidth = {
  '0': 'w0',
  '1': 'w1',
  '2': 'w2',
  '3': 'w3',
  '4': 'w4',
  '5': 'w5',
  '5-half': 'w5-half',
  '6': 'w6',
};

export const Width = {
  ...scaleWidth,
  '25': 'w-25',
  '33': 'w-33',
  '50': 'w-50',
  '60': 'w-60',
  '66': 'w-66',
  '75': 'w-75',
  '100': 'w-100',
  auto: 'w-auto',
};

export const Height = {
  // TODO: fill in with literals
  '0': 'ht0',
  '1': 'ht1',
  '2': 'ht2',
  '2-half': 'ht2-half',
  '3': 'ht3',
  '4': 'ht4',
  '5': 'ht5',
  '25': 'ht-25',
  '50': 'ht-50',
  '75': 'ht-75',
  '100': 'ht-100',
  auto: 'ht-auto',
  inherit: 'ht-inherit',
};

export const Centering = {
  center: 'center',
};

export const TextAlign = {
  initial: 'ti',
  left: 'tl',
  right: 'tr',
  center: 'tc',
  justify: 'tj',
};

export const VerticalAlign = {
  baseline: 'v-base',
  middle: 'v-mid',
  top: 'v-top',
  bottom: 'v-btm',
};

export const TextTransform = {
  uppercase: 'ttu',
  capitalize: 'ttc',
  lowercase: 'ttl',
  none: 'ttn',
};

export type MaxWidthScale = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '100';

type MaxWidthStyle = {[k in MaxWidthScale]: string};

export const MaxWidth: MaxWidthStyle = {
  '1': 'mw1',
  '2': 'mw2',
  '3': 'mw3',
  '4': 'mw4',
  '5': 'mw5',
  '6': 'mw6',
  '7': 'mw7',
  '8': 'mw8',
  '9': 'mw9',
  '100': 'mw-100',
};

export type MinWidthScale = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '100';

type MinWidthStyle = {[k in MinWidthScale]: string};

export const MinWidth: MinWidthStyle = {
  '1': 'miw1',
  '2': 'miw2',
  '3': 'miw3',
  '4': 'miw4',
  '5': 'miw5',
  '6': 'miw6',
  '7': 'miw7',
  '8': 'miw8',
  '9': 'miw9',
  '100': 'miw-100',
};

export const Measure = {
  normal: 'measure',
  wide: 'measure-wide',
  'very-wide': 'measure-very-wide',
  narrow: 'measure-narrow',
};

export const LineHeight = {
  solid: 'lh-solid',
  title: 'lh-title',
  copy: 'lh-copy',
};

export const LetterSpacing = {
  tiny: 'tracked-tiny',
  tight: 'tracked-tight',
  small: 'tracked-small',
  mega: 'tracked-mega',
};

export const TypeScale = {
  // Hero
  '1': 'f1',
  // Large Title
  '2': 'f2',
  // Title
  '3': 'f3',
  // Small Title
  '4': 'f4',
  // Large Text
  '5': 'f5',
  // Regular Text
  '6': 'f6',
  // Small Text
  '7': 'f7',
  // Tiny text
  '8': 'f8',
};

export type TypeScale = keyof typeof TypeScale;

export const FontFamily = {
  roboto: 'roboto',
  united: 'united',
};

export const FontWeight = {
  '4': 'fw4',
  '6': 'fw7',
  // '7': 'fw7', // Deprecated Grafik Bold
  '9': 'fw9', // United-only
};

export type SpaceScale = '0' | '1' | '2' | '3' | '4' | '5' | '6' | '7';
export type SpaceLiteral = 'auto';

type SpaceStyle = {[k in SpaceScale]: string};
type SpaceLiteralStyle = {[k in SpaceLiteral]: string};

export const VSpace: SpaceStyle = {
  '0': 'vs0',
  '1': 'vs1',
  '2': 'vs2',
  '3': 'vs3',
  '4': 'vs4',
  '5': 'vs5',
  '6': 'vs6',
  '7': 'vs7',
};

export const HSpace: SpaceStyle = {
  '0': 'hs0',
  '1': 'hs1',
  '2': 'hs2',
  '3': 'hs3',
  '4': 'hs4',
  '5': 'hs5',
  '6': 'hs6',
  '7': 'hs7',
};

const ma: SpaceStyle = {
  '0': 'ma0',
  '1': 'ma1',
  '2': 'ma2',
  '3': 'ma3',
  '4': 'ma4',
  '5': 'ma5',
  '6': 'ma6',
  '7': 'ma7',
};

const mv: SpaceStyle = {
  '0': 'mv0',
  '1': 'mv1',
  '2': 'mv2',
  '3': 'mv3',
  '4': 'mv4',
  '5': 'mv5',
  '6': 'mv6',
  '7': 'mv7',
};

const mh: SpaceStyle = {
  '0': 'mh0',
  '1': 'mh1',
  '2': 'mh2',
  '3': 'mh3',
  '4': 'mh4',
  '5': 'mh5',
  '6': 'mh6',
  '7': 'mh7',
};

const ml: SpaceStyle & SpaceLiteralStyle = {
  '0': 'ml0',
  '1': 'ml1',
  '2': 'ml2',
  '3': 'ml3',
  '4': 'ml4',
  '5': 'ml5',
  '6': 'ml6',
  '7': 'ml7',
  auto: 'ml-auto',
};

const mt: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mt0',
  '1': 'mt1',
  '2': 'mt2',
  '3': 'mt3',
  '4': 'mt4',
  '5': 'mt5',
  '6': 'mt6',
  '7': 'mt7',
  auto: 'mt-auto',
};

const mr: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mr0',
  '1': 'mr1',
  '2': 'mr2',
  '3': 'mr3',
  '4': 'mr4',
  '5': 'mr5',
  '6': 'mr6',
  '7': 'mr7',
  auto: 'mr-auto',
};

const mb: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mb0',
  '1': 'mb1',
  '2': 'mb2',
  '3': 'mb3',
  '4': 'mb4',
  '5': 'mb5',
  '6': 'mb6',
  '7': 'mb7',
  auto: 'mb-auto',
};

const mn: SpaceStyle = {
  '0': 'mn0',
  '1': 'mn1',
  '2': 'mn2',
  '3': 'mn3',
  '4': 'mn4',
  '5': 'mn5',
  '6': 'mn5',
  '7': 'mn7',
};

const mnv: SpaceStyle = {
  '0': 'mnv0',
  '1': 'mnv1',
  '2': 'mnv2',
  '3': 'mnv3',
  '4': 'mnv4',
  '5': 'mnv5',
  '6': 'mnv6',
  '7': 'mnv7',
};

const mnh: SpaceStyle = {
  '0': 'mnh0',
  '1': 'mnh1',
  '2': 'mnh2',
  '3': 'mnh3',
  '4': 'mnh4',
  '5': 'mnh5',
  '6': 'mnh6',
  '7': 'mnh7',
};

const mnl: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mnl0',
  '1': 'mnl1',
  '2': 'mnl2',
  '3': 'mnl3',
  '4': 'mnl4',
  '5': 'mnl5',
  '6': 'mnl6',
  '7': 'mnl7',
  auto: 'mnl-auto',
};

const mnt: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mnt0',
  '1': 'mnt1',
  '2': 'mnt2',
  '3': 'mnt3',
  '4': 'mnt4',
  '5': 'mnt5',
  '6': 'mnt6',
  '7': 'mnt7',
  auto: 'mnt-auto',
};

const mnr: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mnr0',
  '1': 'mnr1',
  '2': 'mnr2',
  '3': 'mnr3',
  '4': 'mnr4',
  '5': 'mnr5',
  '6': 'mnr6',
  '7': 'mnr7',
  auto: 'mnr-auto',
};

const mnb: SpaceStyle & SpaceLiteralStyle = {
  '0': 'mnb0',
  '1': 'mnb1',
  '2': 'mnb2',
  '3': 'mnb3',
  '4': 'mnb4',
  '5': 'mnb5',
  '6': 'mnb6',
  '7': 'mnb7',
  auto: 'mnb-auto',
};

export const Margin = {ma, mv, mh, ml, mt, mr, mb, mn, mnv, mnh, mnl, mnr, mnt, mnb};

const pa: SpaceStyle = {
  '0': 'pa0',
  '1': 'pa1',
  '2': 'pa2',
  '3': 'pa3',
  '4': 'pa4',
  '5': 'pa5',
  '6': 'pa6',
  '7': 'pa7',
};

const pv: SpaceStyle = {
  '0': 'pv0',
  '1': 'pv1',
  '2': 'pv2',
  '3': 'pv3',
  '4': 'pv4',
  '5': 'pv5',
  '6': 'pv6',
  '7': 'pv7',
};
const ph: SpaceStyle = {
  '0': 'ph0',
  '1': 'ph1',
  '2': 'ph2',
  '3': 'ph3',
  '4': 'ph4',
  '5': 'ph5',
  '6': 'ph6',
  '7': 'ph7',
};

const pl: SpaceStyle = {
  '0': 'pl0',
  '1': 'pl1',
  '2': 'pl2',
  '3': 'pl3',
  '4': 'pl4',
  '5': 'pl5',
  '6': 'pl6',
  '7': 'pl7',
};

const pt: SpaceStyle = {
  '0': 'pt0',
  '1': 'pt1',
  '2': 'pt2',
  '3': 'pt3',
  '4': 'pt4',
  '5': 'pt5',
  '6': 'pt6',
  '7': 'pt7',
};

const pr: SpaceStyle = {
  '0': 'pr0',
  '1': 'pr1',
  '2': 'pr2',
  '3': 'pr3',
  '4': 'pr4',
  '5': 'pr5',
  '6': 'pr6',
  '7': 'pr7',
};

const pb: SpaceStyle = {
  '0': 'pb0',
  '1': 'pb1',
  '2': 'pb2',
  '3': 'pb3',
  '4': 'pb4',
  '5': 'pb5',
  '6': 'pb6',
  '7': 'pb7',
};

export const Padding = {pa, pv, ph, pl, pt, pr, pb};

export const Border = {
  all: 'ba',
  top: 'bt',
  right: 'br',
  bottom: 'bb',
  left: 'bl',
  none: 'bn',
};

export type RadiusScale = '0' | '1' | '2' | '3' | '4' | '100' | 'pill';
type RadiusStyle = {[k in RadiusScale]: string};
export const BorderRadius: RadiusStyle = {
  '0': 'br0',
  '1': 'br1',
  '2': 'br2',
  '3': 'br3',
  '4': 'br4',
  '100': 'br-100',
  pill: 'br-pill',
};

export const BorderWidth = {
  '0': 'bw0',
  '1': 'bw1',
  '2': 'bw2',
  '3': 'bw3',
  '4': 'bw4',
  '5': 'bw5',
};

export type BoxShadowScale = '1' | '2' | '3' | '4' | '5';
type BoxShadowStyle = {[k in BoxShadowScale]: string};
export const BoxShadow: BoxShadowStyle = {
  '1': 'shadow-1',
  '2': 'shadow-2',
  '3': 'shadow-3',
  '4': 'shadow-4',
  '5': 'shadow-5',
};

export type DisplayTypes =
  | 'block'
  | 'inline'
  | 'inline_block'
  | 'table'
  | 'flex'
  | 'inline_flex'
  | 'none';
type DisplayStyle = {readonly [k in DisplayTypes]: string};
export const Display: DisplayStyle = {
  block: 'db',
  inline: 'di',
  inline_block: 'dib',
  table: 'dtbl',
  flex: 'flex',
  inline_flex: 'inline-flex',
  none: 'dn',
};

export const Position = {
  static: 'static',
  relative: 'relative',
  absolute: 'absolute',
  fixed: 'fixed',
  sticky: 'sticky',
  inherit: 'inherit',
};

export const AspectRatio = {
  '21x9': 'aspect-ratio--21x9',
  '16x9': 'aspect-ratio--16x9',
  '9x16': 'aspect-ratio--9x16',
  '4x3': 'aspect-ratio--4x3',
  '3x4': 'aspect-ratio--3x4',
  '6x4': 'aspect-ratio--6x4',
  '4x6': 'aspect-ratio--4x6',
  '8x5': 'aspect-ratio--8x5',
  '5x8': 'aspect-ratio--5x8',
  '7x5': 'aspect-ratio--7x5',
  '5x7': 'aspect-ratio--5x7',
  '1x1': 'aspect-ratio--1x1',
};

//
// Flexbox Items
export const Flex = {
  none: 'none',
  auto: 'flex-auto',
  '1': 'flex-1',
  '0': 'flex-0',
};

export const FlexGrow = {
  '0': 'flex-grow-0',
  '1': 'flex-grow-1',
};

export const FlexShrink = {
  '0': 'flex-shrink-0',
  '1': 'flex-shrink-1',
};

export const FlexOrder = {
  '0': 'order-0',
  '1': 'order-1',
  '2': 'order-2',
  '3': 'order-3',
  '4': 'order-4',
  '5': 'order-5',
  '6': 'order-6',
  '7': 'order-7',
  '8': 'order-8',
  last: 'order-last',
};

export const AlignSelf = {
  start: 'self-start',
  end: 'self-end',
  center: 'self-center',
  baseline: 'self-baseline',
  stretch: 'self-stretch',
};

// Flexbox Container
export const FlexDirection = {
  row: 'flex-row',
  column: 'flex-column',
  'row-reverse': 'flex-row-reverse',
  'column-reverse': 'flex-column-reverse',
};

export const FlexWrap = {
  wrap: 'flex-wrap',
  nowrap: 'flex-nowrap',
  'wrap-reverse': 'flex-wrap-reverse',
};

export const AlignItems = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
};

export const AlignContent = {
  start: 'content-start',
  end: 'content-end',
  center: 'content-center',
  baseline: 'content-baseline',
  stretch: 'content-stretch',
};

export const JustifyContent = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
};
