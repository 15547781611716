import React from 'react';
import {List, Map} from 'immutable';
import {assetPath, connect} from 'fiba/common/utils/reactUtils';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import Event from 'fiba/common/core/models/api/events/Event';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {take} from 'lodash';
import {EventPodiumTeamAssembly} from 'fiba/wt/ui/eventResultsPodium/EventPodiumTeamAssembly';
import {PortalStandingsHighlight} from 'fiba/wt/ui/eventFinalResults/EventFinalResults';

// Props from caller
interface OwnProps {
  labelId: string;
  season: string;
  eventId: string;
}

// Derived props from Redux
interface ReduxProps {
  eventNameData: RemoteData.WebData<string>;
  categoryNameData: RemoteData.WebData<Map<string, string>>;
  standingsData: RemoteData.WebData<Map<string, List<ResultsStanding>>>;
}

type Props = OwnProps & ReduxProps;

// Connect to redux
const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  // Get the event
  const eventNameData = RemoteData.map(
    state.getIn(['events', eventId], RemoteData.NotAsked()) as RemoteData.WebData<Event>,
    event => event.shortName || event.name,
  );

  const categoryNameData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds.reduce(
        (acc, categoryId) =>
          acc.set(categoryId, state.getIn(['categories', categoryId, 'name'], '')),
        Map() as Map<string, string>,
      ),
  );

  const standingsData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds.reduce(
        (acc, categoryId) =>
          acc.set(
            categoryId,
            state.getIn(['results', categoryId, 'standings'], List()) as List<ResultsStanding>,
          ),
        Map() as Map<string, List<ResultsStanding>>,
      ),
  );

  return {
    eventNameData,
    categoryNameData,
    standingsData,
  };
};

const _EventResultsPodium: React.SFC<Props> = ({
  labelId,
  standingsData,
  eventNameData,
  categoryNameData,
}) => {
  const categoryNames: Map<string, string> = RemoteData.match(categoryNameData, {
    Success: data => data,
    default: () => Map(),
  });

  const standingOffsets = {
    0: {top: '22%', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto'},
    1: {top: '34%', right: '67%'},
    2: {top: '42%', left: '68%'},
  };

  return (
    <Spacer vSpace="3">
      <PortalStandingsHighlight id={labelId}>
        {'Standings' +
          RemoteData.match(eventNameData, {
            Success: eventName => `: ${eventName}`,
            default: () => '',
          })}
      </PortalStandingsHighlight>

      <ViewWebData data={standingsData}>
        {standings =>
          standings.size > 0 && (
            <CardContainer pa="3" mb="4" position="relative" extraClassName="EventPodium">
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="around"
                extraClassName="EventPodium-Container"
              >
                {Object.entries(standings.toJS())
                  .sort(([cIdA, _categoryA], [cIdB, _categoryB]) => {
                    return categoryNames.get(cIdA).localeCompare(categoryNames.get(cIdB));
                  })
                  .map(([categoryId, categoryStandings]: [string, ResultsStanding[]], idx) => (
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      width="50"
                      extraClassName="EventPodium-ColumnContainer"
                      key={categoryId}
                    >
                      <Heading
                        fontWeight="9"
                        fontSize="3"
                        mb="2"
                        extraClassName="EventPodium-CategoryName"
                      >
                        {categoryNames.get(categoryId)}
                      </Heading>
                      <Box
                        display={['none', 'none', 'block']}
                        extraClassName="EventPodium-Content"
                        style={{backgroundImage: `url(${assetPath('img/3x3-podium-bg.jpg')})`}}
                      >
                        {take(categoryStandings, 3).map((categoryStanding, idx) => (
                          <EventPodiumTeamAssembly
                            standing={categoryStanding}
                            offset={standingOffsets[idx]}
                            key={categoryStanding.teamId}
                          />
                        ))}
                      </Box>

                      <Flex
                        width="100"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        display={['block', 'block', 'none']}
                      >
                        {take(categoryStandings, 3).map(categoryStanding => (
                          <Flex
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="between"
                            mt="1"
                            pt="2"
                            pb="2"
                            bgColor="silver-10"
                            border="all"
                            borderWidth="1"
                            borderColor="silver-20"
                            key={categoryStanding.teamId}
                          >
                            <Box width="50" textAlign="center" fontSize="1" fontWeight="9">
                              {categoryStanding.teamRank}.
                            </Box>
                            <Box width="50" textAlign="center" pt="1">
                              <EventPodiumTeamAssembly standing={categoryStanding} />
                            </Box>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                  ))}
              </Flex>
            </CardContainer>
          )
        }
      </ViewWebData>
    </Spacer>
  );
};

export const EventResultsPodium = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventResultsPodium,
);
