import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {SeedingsTable, Columns} from 'fiba/wt/ui/seedingsTable/SeedingsTable';
import {Category} from 'fiba/common/core/models/api/events/Category';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';

export type SeedingsTableColumns = Columns;

const defaultColumns: Columns[] = ['seed', 'name', 'rankingPoints', 'pool', 'qualificationType'];

export interface OwnProps {
  categoryId: string;
  columns?: Columns[];
  showCategoryName?: boolean;
  createTeamHref?: ResourceLinkProvider;
  cellSpacing?: StyleProp<'pv'>;
}

export interface ReduxProps {
  category?: Category;
  standings: List<ResultsStanding>;
  poolGroups: List<ResultsGroup>;
  qualifyingDrawGroups: List<ResultsGroup>;
  metaPaths: string[];
}

export const mapStateToProps = (state, {categoryId}: OwnProps): ReduxProps => {
  const category = state.getIn(['categories', categoryId]);
  const standings = state.getIn(['results', categoryId, 'standings'], List());
  const qualifyingDrawGroups = state.getIn(['results', categoryId, 'qualifyingDrawGroups'], List());
  const poolGroups = state.getIn(['results', categoryId, 'poolGroups'], List());
  const metaPaths = [`categories/__meta/${categoryId}`, `results/__meta/${categoryId}`];

  return {category, standings, poolGroups, qualifyingDrawGroups, metaPaths};
};

const _CategorySeedings: React.FunctionComponent<OwnProps & ReduxProps> = ({
  category,
  categoryId,
  standings,
  poolGroups,
  qualifyingDrawGroups,
  columns = defaultColumns,
  showCategoryName = true,
  createTeamHref,
  metaPaths,
  cellSpacing,
}) => {
  /** Unique caption if one table per categoryId */
  const captionId = `category-seedings-${categoryId}`;

  /** Visually hide the category name if specified, but keep accessible */
  const caption =
    !!category &&
    (showCategoryName ? (
      <SubHeading>{category.name}</SubHeading>
    ) : (
      <VisuallyHidden>
        <SubHeading>{category.name}</SubHeading>
      </VisuallyHidden>
    ));

  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <SeedingsTable
          captionId={captionId}
          caption={caption}
          cellSpacing={cellSpacing}
          standings={standings}
          poolGroups={poolGroups}
          qualifyingDrawGroups={qualifyingDrawGroups}
          columns={columns || defaultColumns}
          createTeamHref={createTeamHref}
        />
      )}
    </Loading>
  );
};

export const CategorySeedings = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _CategorySeedings,
);
