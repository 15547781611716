// import {List} from 'immutable';
import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import {bind} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {
  ContentTypes,
  transformEventConfigurationMapToEventConfiguration,
} from 'fiba/wt/stores/contentStore';
import eventController from 'fiba/wt/controllers/eventController';
import contentController from 'fiba/wt/controllers/contentController';
import {getTourProgrammabilityState} from 'fiba/wt//ui/tourProgrammability/TourProgrammabilityStateProvider';
import {getEventIdFromConfiguration} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {List} from 'immutable';

export interface TourProgrammabilityControllerFeatures {
  deriveFromTourState?: boolean;
}

function tourProgrammabilityController(
  tourId: string,
  season: string,
  features: TourProgrammabilityControllerFeatures,
) {
  return (commonServices: CommonServices) => {
    const {cache} = commonServices;
    const featureLoaders = {
      deriveFromTourState: () => {
        // HACKY STUFF WARNING
        // Is this the right way to do this? It kind of assumes that these are already loaded
        // but this is the same thing that the mediaController does with tourPhotos.

        // Pick up eventConfiguration for specified tourId from the cache,
        // where the contentController has left them.
        // NOTE: The tour configuration may not be in the cache, so we provide a fallback value incase.
        // TODO: Better type the cache, getting rid of the "any" type so it helps catch these conditions.
        const eventConfiguration = (
          cache(['content', ContentTypes.EventConfigurator, 'tourEvents', tourId]) || List()
        )
          .map(transformEventConfigurationMapToEventConfiguration)
          .toJS();

        // Derive tour programmability from the aggregate of its eventConfiguration
        // @see TourProgrammability.tsx for the overrideNow hack
        const overrideNow = cache(['route', 'payload', '_now']);
        const events = cache(['events']);
        const tourProgrammabilityState = getTourProgrammabilityState(
          eventConfiguration,
          events,
          overrideNow,
        );

        // Pluck eventId from configuration
        const eventId = getEventIdFromConfiguration(tourProgrammabilityState.eventConfiguration);

        /* Load features that we a priori know will be used for the Portal:
         * - All: Event data general
         * - Pre: eventConfiguration
         * - Post + Live: Event data results
         * - LIVE: eventEmbeddedMedia(Livestream), eventGames (Cards)
         * TODO: Could use those as a callback
         */
        switch (tourProgrammabilityState.state) {
          case 'event-in-pre': {
            // General Event + EventConfiguration (countdown)
            return bind([
              eventController(eventId, {})(commonServices),
              contentController({
                eventConfiguration: {tourId, season, eventId},
              })(commonServices),
            ]);
          }

          case 'event-in-live': {
            // General Event + Event Results + eventGames (Cards) + embeddedMedia (Livestream)
            return bind([
              eventController(eventId, {
                // TODO: is categories actually needed?
                categories: {results: true},
                games: true,
              })(commonServices),
              contentController({
                eventEmbeddedMedia: {tourId, season, eventId},
              })(commonServices),
            ]);
          }

          case 'event-in-post': {
            // General Event + Event Results (Standings)
            return bind([
              eventController(eventId, {
                categories: {results: true},
                games: true,
              })(commonServices),
            ]);
          }

          // Do nothing in other cases
          // TODO: is this the best thing to return?
          default:
            return Promise.resolve({});
        }
      },
    };

    return loadFeatures(featureLoaders, features);
  };
}

export default tourProgrammabilityController;
