/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultPlayByPlayStatisticsAction = {
  type: null,
  homeTeamScore: null,
  awayTeamScore: null,
  homeTeamFouls: null,
  awayTeamFouls: null,
  playerId: null,
  teamId: null,
  points: null,
  isDunk: null,
  isDrive: null,
  isBuzzerBeater: null,
  isMade: null,
  isFreeThrow: null,
  isPossessionFreeThrow: null,
  isNonPossessionFreeThrow: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type PlayByPlayStatisticsActionLike = {
  [K in keyof typeof defaultPlayByPlayStatisticsAction]?: PlayByPlayStatisticsAction[K];
};

export class PlayByPlayStatisticsAction extends Record(defaultPlayByPlayStatisticsAction) {
  type?:
    | 'ScoreStatActionDto'
    | 'KeyAssistStatActionDto'
    | 'TurnOverStatActionDto'
    | 'BlockStatActionDto'
    | 'FoulStatActionDto'
    | 'OffensiveReboundStatDto'
    | 'DefensiveReboundStatDto'
    | 'StatGameFinalizationActionDto';
  homeTeamScore?: number;
  awayTeamScore?: number;
  homeTeamFouls?: number;
  awayTeamFouls?: number;
  playerId?: string;
  teamId?: string;
  points?: number;
  isDunk?: boolean;
  isDrive?: boolean;
  isBuzzerBeater?: boolean;
  isMade?: boolean;
  isFreeThrow?: boolean;
  isPossessionFreeThrow?: boolean;
  isNonPossessionFreeThrow?: boolean;
  static fromJS(o: PlayByPlayStatisticsActionLike): PlayByPlayStatisticsAction {
    return (
      o &&
      new PlayByPlayStatisticsAction({
        type: o.type,
        homeTeamScore: o.homeTeamScore,
        awayTeamScore: o.awayTeamScore,
        homeTeamFouls: o.homeTeamFouls,
        awayTeamFouls: o.awayTeamFouls,
        playerId: o.playerId,
        teamId: o.teamId,
        points: o.points,
        isDunk: o.isDunk,
        isDrive: o.isDrive,
        isBuzzerBeater: o.isBuzzerBeater,
        isMade: o.isMade,
        isFreeThrow: o.isFreeThrow,
        isPossessionFreeThrow: o.isPossessionFreeThrow,
        isNonPossessionFreeThrow: o.isNonPossessionFreeThrow,
      })
    );
  }
}

export default PlayByPlayStatisticsAction;
