import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {EventCountdown} from 'fiba/wt/ui/eventCountdown/EventCountdown';
import {EventPageHeroImage} from 'fiba/wt/ui/eventPage/EventPageHeroImage';
import {EventNewsSummary} from 'fiba/wt/ui/eventNews/EventNewsSummary';
import {
  ContentfulEmbeddedMediaSummary,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {EventPageProps} from 'fiba/wt/ui/eventPage/EventPageCommon';
import {EventOverviewHeading} from 'fiba/wt/ui/eventPage/EventOverviewHeading';
import {Box} from 'fiba/wt/ui/box/Box';
import {EventPhotoGallery} from 'fiba/wt/ui/eventPhotoGallery/EventPhotoGallery';

export const EventPagePre: React.FC<EventPageProps> = ({eventId, season, siteConfig}) => (
  <Spacer debugClassName="EventPagePre">
    {/* Wrap countdown in section so it wont insert `h1` which is a task for the hero image */}
    <Section>
      <EventCountdown season={season} eventId={eventId} />
    </Section>

    <EventOverviewHeading season={season} eventId={eventId} />

    <Section>
      <EventPageHeroImage season={season} eventId={eventId} />
      <EventNewsSummary season={season} eventId={eventId} siteConfig={siteConfig} />
      <ContentfulEmbeddedMediaSummary
        title="Videos"
        contentPath={`events/${eventId}`}
        mediaType={EmbeddedMediaTypes.YoutubeMainPlaylist}
        widthHint={720} // TODO: discuss
        season={season}
        eventId={eventId}
        siteConfig={siteConfig}
      />

      <Box>
        <EventPhotoGallery heading="Photos" season={season} eventId={eventId} />
      </Box>
    </Section>
  </Spacer>
);
