import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {
  ContentfulImageLink,
  ContentfulImageChildProps,
  ImageLinkTypes,
} from 'fiba/wt/ui/contentfulImageLink/ContentfulImageLink';
import {MaybeExternalLink} from 'fiba/wt/ui/maybeExternalLink/MaybeExternalLink';
import {ImageWithPlaceholder} from '../image/Image';

interface TourPageHeroImageProps {
  tourId: string;
  season: string;
}

export const TourPageHeroImage: React.FunctionComponent<TourPageHeroImageProps> = ({tourId}) => (
  <div className="TourPageHeroImage">
    <ContentfulHeroImage contentPath={`tours/${tourId}`} />
  </div>
);

/** Get a HeroImage ContentLink representation, for a certain content path */
export const ContentfulHeroImage: React.FunctionComponent<{
  contentPath: string;
}> = ({contentPath}) => {
  return (
    <ContentfulImageLink contentPath={contentPath} imageLinkType={ImageLinkTypes.HeroImage}>
      {([imageProps]: ContentfulImageChildProps[]) => {
        return !!imageProps
          ? imageProps.image && (
              <MaybeExternalLink
                href={imageProps.link}
                colorTheme="light"
                className="db relative w-100 silver-10 focus-shadow"
              >
                {Icon => (
                  <>
                    <ImageWithPlaceholder
                      aspectRatio="16x9"
                      alt=""
                      src={`${imageProps.image.fields.file.url}?w=1280&h=720&fl=progressive&q=85&fit=fill&f=faces&fm=jpg`}
                    />

                    <Box pa={['3', '3', '4']} position="absolute" extraClassName="bottom-0 left-0">
                      <Heading fontSize={['3', '3', '2', '1']} extraClassName="text-shadow-1">
                        {imageProps.title} <Icon />
                      </Heading>
                    </Box>
                  </>
                )}
              </MaybeExternalLink>
            )
          : null;
      }}
    </ContentfulImageLink>
  );
};
