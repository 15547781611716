import ResultsGameSummary from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {Map, List} from 'immutable';
import moment from 'moment';

// Constants for `currentGameState` in livescores api response
// @see https://fiba-3x3-scores-backend-uat.herokuapp.com/docs
export const GAME_STATE_STARTING = 'GAME_STATE_STARTING';
export const GAME_STATE_RUNNING = 'GAME_STATE_RUNNING';
export const GAME_STATE_PAUSED = 'GAME_STATE_PAUSED';
export const GAME_STATE_OVERTIME = 'GAME_STATE_OVERTIME';
export const GAME_STATE_FINISHED = 'GAME_STATE_FINISHED';

export const createRenderGame = (game, live = Map()) =>
  // Merge live data to the game in case it is not final
  isFinal(game) ? game : game.mergeDeep(live);

export const isLive = game => !!game.get('currentGameState') && !isFinal(game);

export const isStarting = game => game.get('currentGameState') === GAME_STATE_STARTING;

export const isRunning = game => game.get('currentGameState') === GAME_STATE_RUNNING;

export const isPaused = game => game.get('currentGameState') === GAME_STATE_PAUSED;

export const isOvertime = game => game.get('currentGameState') === GAME_STATE_OVERTIME;

export const isFinal = game =>
  typeof game.getIn(['homeTeam', 'teamIsWinner']) !== 'undefined' ||
  game.get('currentGameState') === GAME_STATE_FINISHED;

// FIXME: Logic based on datetime or (isFinal || isLive)?
export const isFuture = game => !isFinal(game) && !isLive(game);

export const isPoolGame = game => game.get('roundCode') === 'RR';

/**
 * Create boolean list parallel to renderGames describing wether the game with index n is initial game of the day
 * @param  {List} renderGames List of renderable games
 * @return {List}             List of booleans
 */
export function tagInitialGames(renderGames) {
  const withPrev = renderGames.zip(List([null]).concat(renderGames.butLast()));

  const initialGames = withPrev.map(([next, prev]) => {
    const nextDate = moment(next.get('gameStartDatetime') || '').startOf('day');

    if (!prev) {
      return nextDate.isValid();
    }

    const prevDate = moment(prev.get('gameStartDatetime') || '').startOf('day');

    if ((!prevDate.isValid() && nextDate.isValid()) || prevDate.isBefore(nextDate)) {
      return true;
    }

    return false;
  });

  return initialGames;
}

/**
 * Merge event games with live score data, both found inside event data
 * @param  {Map}    event      Event info
 * @return {List}              List of render games
 */
export function createRenderGames(event) {
  if (!event) {
    return List();
  }

  const renderGames = event
    .get('games', List())
    .filter(game => !!game.get('gameStartDatetime'))
    .map(game => createRenderGame(game, event.getIn(['ongoingGames', game.get('gameId')])));

  return renderGames;
}

export function findCurrentRenderGameIndex(renderGames) {
  // 1) Scan for games that is marked live
  const firstLive = renderGames.findEntry(game => isLive(game));
  if (firstLive) {
    return firstLive[0];
  }

  // 2) Find the first game that is not yet finished
  const nextGame = renderGames.findEntry(game => !isFinal(game));

  if (nextGame) {
    return nextGame[0];
  }

  return renderGames.size - 1;
}

const sign = (x: number) => (x >= 0 ? '+' : '-');

export const getTimezone = (game: ResultsGameSummary, time: 'event' | 'local') => {
  if (!game) {
    return '';
  }

  const gameStartDatetime =
    time === 'event'
      ? moment.parseZone(game.get('gameStartDatetime'))
      : moment(game.get('gameStartDatetime'));

  const utcOffset = gameStartDatetime.utcOffset();

  // Form the string so that if it is even hours, it shows up as UTC+HH or UTC-HH,
  // if not then display it as UTC+HH:mm or UTC-HH:mm
  const offsetMinutes = Math.abs(utcOffset % 60);
  const offsetHours = Math.floor(Math.abs(utcOffset / 60));
  const offsetString = offsetMinutes === 0 ? offsetHours : `${offsetHours}:${offsetMinutes}`;

  return `UTC${sign(utcOffset)}${offsetString}`;
};
