import {createReducer} from 'fiba/wt/utils/storeUtils';
import {List, fromJS, Map} from 'immutable';
import newsActions from 'fiba/wt/stores/newsStore';
import {NewsListLike} from 'fiba/common/core/models/api/feed/NewsList';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {mapValues} from 'lodash';
import {toRemoteDataWithList} from 'fiba/wt/stores/eventStore';

export interface ConferenceStoreState extends Map<string, Map<string, any>> {
  /*
  {
    __meta {
      <confereceId>: {news: {previews: []}},
    }
  }
  */
}

export const reducer = createReducer<ConferenceStoreState>(
  __filename,
  fromJS({__isWebDataBased: true}),
  {},
  {
    [newsActions.types.loadNewsByEvent]: (
      state: ConferenceStoreState,
      conferenceId: string,
    ): ConferenceStoreState =>
      state.updateIn(['__meta', conferenceId, 'news', 'previews'], () => RemoteData.Loading()),
    [newsActions.types.loadNewsPreviewsByConferenceSuccess]: (
      state: ConferenceStoreState,
      conferenceId: string,
      news: NewsListLike,
    ): ConferenceStoreState =>
      state.updateIn(['__meta', conferenceId, 'news', 'previews'], () =>
        RemoteData.Success(List(news.items.map(newsItem => newsItem.slug))),
      ),
    [newsActions.types.loadNewsPreviewsByConferenceError]: (
      state: ConferenceStoreState,
      conferenceId: string,
      error,
    ): ConferenceStoreState =>
      state.updateIn(['__meta', conferenceId, 'news', 'previews'], () => RemoteData.Failure(error)),
  },
);

interface MetaLike {
  news?: {previews: RemoteData.WebData<string[]>};
}

interface StateLike {
  __isWebDataBased?: boolean;
  __meta?: Record<string, MetaLike>;
}

export function deserializeState(state: StateLike): ConferenceStoreState {
  const {__meta, __isWebDataBased} = state;

  if (__meta === undefined) {
    return fromJS({__meta: {news: {previews: RemoteData.NotAsked()}}});
  }

  const deserializedMeta = mapValues(__meta, metaValue => {
    const {news} = metaValue;

    return {
      news:
        news === undefined
          ? {previews: RemoteData.NotAsked()}
          : {previews: toRemoteDataWithList(news.previews)},
    };
  });

  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail
    __isWebDataBased: __isWebDataBased ?? true,
    __meta: deserializedMeta || {},
  });
}
