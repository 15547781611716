import React from 'react';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Heading} from 'fiba/wt/ui/heading/Heading';

export const NotFoundPage = () => (
  <Flex
    extraClassName="min-h-container"
    pa="2"
    flex="1"
    alignItems="center"
    flexDirection="column"
    justifyContent="center"
  >
    <Heading extraClassName="tc" fontSize="2" pb="4">
      404: Page not found
    </Heading>
    <p className="pb4 f5 fw4 tc">Sorry! The page you were looking for is not available.</p>

    <div>
      <a
        onClick={e => {
          return history.back();
          e.preventDefault();
        }}
        className="ph3 pv2 mr2 link pointer"
      >
        Back
      </a>

      <a className="link pointer" href="/">
        Home
      </a>
    </div>
  </Flex>
);

export default NotFoundPage;
