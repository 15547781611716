import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {Box} from 'fiba/wt/ui/box/Box';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {Th} from 'fiba/wt/ui/table/Table';
import {TopScorer} from 'fiba/common/core/models/api/stats/TopScorer';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {
  eventTeamLinkProvider,
  EventType,
  ResourceLinkProvider,
  curriedEventTeamPlayerLinkProvider,
} from 'fiba/wt/utils/linkUtils';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import Team from 'fiba/common/core/models/api/teams/Team';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {resolvePathWithGender} from 'fiba/wt/utils/storeUtils';

type ColumnHeaders = 'rank' | 'player' | 'team' | 'totalPoints' | 'gamesPlayed';

export type TeamPlayerHrefSpec = (teamId: string) => (playerId: string) => string;
type ColumnSpec = IColumnSpec<TopScorer>;
type ColumnSpecRecord = Record<ColumnHeaders, ColumnSpec>;

const createColumnsSpec = (
  createPlayerHref: TeamPlayerHrefSpec,
  createTeamHref: ResourceLinkProvider,
): ColumnSpecRecord => {
  return {
    rank: {
      name: 'Rank',
      dataType: 'text',
      renderColumn: ({rowData, getTdProps}) => <Th {...getTdProps()}>{rowData.rankLabel}</Th>,
    },
    player: {
      name: 'Player',
      dataType: 'text',
      renderColumn: ({rowData, Td, getTdProps}) => {
        const {teamId, playerId, playerFirstName, playerLastName} = rowData;
        return (
          <Td {...getTdProps()}>
            <StyledLink href={createPlayerHref(teamId)(playerId)}>
              {playerFirstName} {playerLastName}
            </StyledLink>
          </Td>
        );
      },
    },
    team: {
      name: 'Team',
      dataType: 'text',
      renderColumn: ({rowData, Td, getTdProps}) => {
        const {teamId, teamName, teamInEventId, teamNameSuffix} = rowData;
        const teamLike = Team.fromJS({
          id: teamId,
          name: teamName,
          nameSuffix: teamNameSuffix,
        });
        return (
          <Td {...getTdProps()} extraClassName="ws-nowrap">
            <StyledLink href={createTeamHref(teamInEventId)}>
              <TeamNameAssembly team={teamLike} isSuffixVisible isIocVisible={true} />
            </StyledLink>
          </Td>
        );
      },
    },
    totalPoints: {
      name: 'Total Points',
      dataType: 'number',
      renderColumn: ({rowData, Td, getTdProps}) => <Td {...getTdProps()}>{rowData.totalPoints}</Td>,
    },
    gamesPlayed: {
      name: 'Games played',
      dataType: 'number',
      renderColumn: ({rowData, Td, getTdProps}) => <Td {...getTdProps()}>{rowData.gamesPlayed}</Td>,
    },
  };
};

const mapStateToProps = (state, {eventId, genderTab}: OwnProps): ReduxProps => {
  const eventPath = resolvePathWithGender(eventId, genderTab);
  return {
    stats: state.getIn(['topScorerStats', ...eventPath]),
    metaPaths: [`topScorerStats/__meta/${eventPath.join('/')}`],
  };
};

interface ReduxProps {
  stats: TopScorer[];
  updatedAt?: string;
  metaPaths: string[];
}

interface OwnProps {
  eventId: string;
  season: string;
  eventType: EventType;
  genderTab?: CategoryGenderTab;
}

type Props = OwnProps & ReduxProps;

const _TopScorerStatistics: React.FC<Props> = ({stats, season, eventType, eventId, metaPaths}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const createPlayerHref = curriedEventTeamPlayerLinkProvider(
    seasonConfig,
    season,
    eventId,
    eventType,
  );
  const createTeamHref = (teamInEventId: string) =>
    eventTeamLinkProvider(seasonConfig, season, eventId, eventType)(teamInEventId);
  const columnsSpec = createColumnsSpec(createPlayerHref, createTeamHref);
  return (
    <div>
      <Loading
        metaPaths={metaPaths}
        onError={() => <BlockText>There was a problem loading Player Stats</BlockText>}
      >
        {() => (
          <Spacer vSpace="4">
            <Box>
              <DataTable<ColumnHeaders, TopScorer>
                captionId={`${eventId}-top-scorers`}
                caption={<SubHeading extraClassName="mb2">Top Scorers stats</SubHeading>}
                rows={stats}
                columns={['rank', 'player', 'team', 'totalPoints', 'gamesPlayed']}
                columnsSpec={columnsSpec}
                headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
                headerExtraClassName="dark-30"
                rowExtraClassName="striped--light-even bb bw1 b--silver-20 striped--fullwhite"
              />
            </Box>
          </Spacer>
        )}
      </Loading>
    </div>
  );
};

export const TopScorerStatistics = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _TopScorerStatistics,
);
