import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import contentActions, {ContentTypes} from 'fiba/wt/stores/contentStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {RequireAtLeastOne} from 'fiba/common/core/typeUtils';

interface EventGroupParams {
  tourId: string;
  season: string;
}

// Currently event-specific Contentful entries are linked via path field
// which contains either the tourId (WT/WS) or season (Cups) so at least one of those is required:
type EventContentParams = RequireAtLeastOne<EventGroupParams> & {
  eventId: string;
};

type SeasonContentParams = {
  season: string;
};

export interface ContentControllerFeatures {
  globalConfig?: {};
  allSeasonConfigs?: {};
  tourArticles?: EventGroupParams;
  tourEmbeddedMedia?: EventGroupParams;
  tourImageLinks?: EventGroupParams;
  tourConfiguration?: EventGroupParams;
  tourSponsors?: EventGroupParams;
  eventArticles?: EventContentParams;
  eventCustomPages?: EventContentParams;
  eventEmbeddedMedia?: EventContentParams;
  eventImageLinks?: EventContentParams;
  eventConfiguration?: EventContentParams;
  eventSponsors?: EventContentParams;
  seasonSponsors?: SeasonContentParams;
}

function contentController(features: ContentControllerFeatures) {
  return ({dispatch, cache}: CommonServices) => {
    const featureLoaders = {
      tourArticles: ({tourId, season}: EventGroupParams) =>
        cache(['content', ContentTypes.Article, 'tours', tourId], () =>
          dispatch(contentActions.loadTourContent(ContentTypes.Article, tourId, season)),
        ),

      tourEmbeddedMedia: ({tourId, season}: EventGroupParams) =>
        cache(['content', ContentTypes.EmbeddedMedia, 'tours', tourId], () =>
          dispatch(contentActions.loadTourContent(ContentTypes.EmbeddedMedia, tourId, season)),
        ),

      tourImageLinks: ({tourId, season}: EventGroupParams) =>
        cache(['content', ContentTypes.ImageLink, 'tours', tourId], () =>
          dispatch(contentActions.loadTourContent(ContentTypes.ImageLink, tourId, season)),
        ),

      globalConfig: () => {
        return cache(['content', ContentTypes.GlobalConfig], () =>
          dispatch(contentActions.loadGlobalConfig()),
        );
      },

      allSeasonConfigs: () => {
        return cache(['content', ContentTypes.SeasonConfig], () =>
          dispatch(contentActions.loadAllSeasonConfigs()),
        );
      },

      tourSponsors: ({tourId, season}: EventGroupParams) => {
        // Contentful API returns only one level of depth expanded by default.
        // For sponsors, we want to expand all of them so we don't have to do multiple requests
        const contentfulIncludeDepth = 4;
        return cache(['content', ContentTypes.FooterSponsorsSection, 'tours', tourId], () =>
          dispatch(
            contentActions.loadTourContent(
              ContentTypes.FooterSponsorsSection,
              tourId,
              season,
              contentfulIncludeDepth,
            ),
          ),
        );
      },

      seasonSponsors: ({season}: SeasonContentParams) => {
        // Contentful API returns only one level of depth expanded by default.
        // For sponsors, we want to expand all of them so we don't have to do multiple requests
        const contentfulIncludeDepth = 4;
        return cache(['content', ContentTypes.FooterSponsorsSection, 'seasons', season], () =>
          dispatch(
            contentActions.loadSeasonContent(
              ContentTypes.FooterSponsorsSection,
              season,
              contentfulIncludeDepth,
            ),
          ),
        );
      },

      tourConfiguration: ({tourId, season}: EventGroupParams) =>
        cache(['content', ContentTypes.EventConfigurator, 'tourEvents', tourId], () =>
          dispatch(
            contentActions.loadTourEventsContent(ContentTypes.EventConfigurator, tourId, season),
          ),
        ),

      eventArticles: ({tourId, season, eventId}: EventContentParams) =>
        cache(['content', ContentTypes.Article, 'events', eventId], () =>
          dispatch(contentActions.loadEventContent(ContentTypes.Article, eventId, tourId, season)),
        ),

      eventCustomPages: ({tourId, season, eventId}: EventContentParams) =>
        cache(['content', ContentTypes.CustomPage, 'events', eventId], () =>
          dispatch(
            contentActions.loadEventContent(ContentTypes.CustomPage, eventId, tourId, season),
          ),
        ),

      eventEmbeddedMedia: ({tourId, season, eventId}: EventContentParams) =>
        cache(['content', ContentTypes.EmbeddedMedia, 'events', eventId], () =>
          dispatch(
            contentActions.loadEventContent(ContentTypes.EmbeddedMedia, eventId, tourId, season),
          ),
        ),

      eventImageLinks: ({tourId, season, eventId}: EventContentParams) =>
        cache(['content', ContentTypes.ImageLink, 'events', eventId], () =>
          dispatch(
            contentActions.loadEventContent(ContentTypes.ImageLink, eventId, tourId, season),
          ),
        ),

      eventSponsors: ({tourId, eventId, season}: EventContentParams) => {
        // Contentful API returns only one level of depth expanded by default.
        // For sponsors, we want to expand all of them so we don't have to do multiple requests
        const contentfulIncludeDepth = 4;
        return cache(['content', ContentTypes.FooterSponsorsSection, 'events', eventId], () =>
          dispatch(
            contentActions.loadEventContent(
              ContentTypes.FooterSponsorsSection,
              eventId,
              tourId,
              season,
              contentfulIncludeDepth,
            ),
          ),
        );
      },

      eventConfiguration: ({tourId, season, eventId}: EventContentParams) => {
        // To get nested nav menus, this should be enough for now as 4 tiers of navigation probably shouldn't be done anyway
        const contentfulIncludeDepth = 4;
        return cache(['content', ContentTypes.EventConfigurator, 'events', eventId], () =>
          dispatch(
            contentActions.loadEventContent(
              ContentTypes.EventConfigurator,
              eventId,
              tourId,
              season,
              contentfulIncludeDepth,
            ),
          ),
        );
      },
    };

    return loadFeatures(featureLoaders, features);
  };
}

export default contentController;
