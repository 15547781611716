import React, {useContext, useRef} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {
  ThemeContextProvider,
  ThemeContextConsumer,
  lightTheme,
  darkTheme,
} from 'fiba/wt/ui/themeContext/ThemeContext';
import {TopHeader} from 'fiba/wt/ui/tourLayout/TopHeader';
import {TourNavigation} from 'fiba/wt/ui/tourLayout/TourNavigation';
import {TourNavigationMobile} from 'fiba/wt/ui/tourLayout/TourNavigationMobile';
import {TourLayoutFooter, ISponsorSection} from 'fiba/wt/ui/tourLayout/TourLayoutFooter';
import {FocusElement} from 'fiba/wt/ui/focusElement/FocusElement';
import {SkipLink, skipLinkTargetId} from 'fiba/wt/ui/skipLink/SkipLink';
import {Section, LevelContext} from 'fiba/wt/ui/heading/HeadingContext';
import {SiteConfigContext, ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {SentryErrorBoundary} from 'fiba/wt/ui/errorBoundary/ErrorBoundary';
import {SeasonNavigation as CupsSeasonNavigation} from 'fiba/cups/navigation/SeasonNavigation';
import {SeasonNavigation as NLSeasonNavigation} from 'fiba/nationsleague/navigation/SeasonNavigation';

import {
  EventColorTheme,
  EventConfiguration,
} from '../eventProgrammability/EventProgrammabilityStateProvider';
import {get} from 'lodash';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {ContentfulPicture} from 'fiba/wt/ui/contentfulPicture/ContentfulPicture';
import {eventRootLink} from 'fiba/wt/utils/linkUtils';
import {NLSeasonNavigationMobile} from 'fiba/nationsleague/navigation/SeasonNavigationMobile';

export interface TourLayoutProps {
  tourId: string;
  season: string;
  tourTab?: string;
  components: {[key: string]: React.ReactNode};
  sponsorSections: ISponsorSection[];
  eventId?: string;
  eventConfiguration?: EventConfiguration;
}

export const TourLayout: React.FunctionComponent<TourLayoutProps> = ({
  components,
  eventConfiguration,
  ...tourProps
}) => {
  const skipLinkTargetRef = useRef<HTMLElement>(null);
  const siteConfig = useContext(SiteConfigContext);

  let theme;

  if (eventConfiguration) {
    theme = eventConfiguration.colorTheme === EventColorTheme.dark ? darkTheme : lightTheme;
  } else {
    theme = siteConfig.theme;
  }

  return (
    <ThemeContextProvider theme={theme}>
      <LevelContext.Provider value={{level: 1}}>
        <ThemeContextConsumer>
          {theme => (
            <Box bgColor={theme.bgColor} extraClassName="TourLayout min-vh-100">
              {/* header */}
              <LayoutHeader
                {...tourProps}
                eventConfiguration={eventConfiguration}
                siteConfig={siteConfig}
                skipLinkTargetRef={skipLinkTargetRef}
              />

              {/* Main Container */}
              <FocusElement
                is="main"
                elementRef={skipLinkTargetRef}
                id={skipLinkTargetId}
                className="pv2-m ph3-m mw9 center"
              >
                {/* Primary Container: Content + Widget sidebar */}
                <Box extraClassName="TourLayout-PrimaryContainer flex flex-row justify-center">
                  {/* Content Container */}
                  <Box
                    fontSize={theme.fontSize}
                    bgColor={theme.bgColor}
                    color={theme.color}
                    extraClassName="TourLayout-ContentContainer flex-auto min-h-container"
                  >
                    {/* Wrap the page main content in an error boundary, to catch unexpected errors more gracefully */}
                    <SentryErrorBoundary componentNameForContext="App">
                      {components['content']}
                    </SentryErrorBoundary>
                  </Box>

                  {/* Widget Sidebar */}
                  <ThemeContextProvider theme={{...lightTheme, fontSize: '7'}}>
                    {!!components['sidebar'] && (
                      <Box width="5" flexShrink="0" extraClassName="TourLayout-SidebarContainer">
                        {components['sidebar']}
                      </Box>
                    )}
                  </ThemeContextProvider>
                </Box>
              </FocusElement>
              <Section>
                <TourLayoutFooter {...tourProps} />
              </Section>
            </Box>
          )}
        </ThemeContextConsumer>
      </LevelContext.Provider>
    </ThemeContextProvider>
  );
};

//
// LayoutHeader

export interface LayoutHeaderProps {
  tourId: string;
  season: string;
  siteConfig: ISiteConfig;
  skipLinkTargetRef: React.RefObject<HTMLElement>;
  tourTab?: string;
  eventConfiguration?: EventConfiguration;
  eventId?: string;
}

export const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  skipLinkTargetRef,
  children,
  siteConfig,
  eventConfiguration,
  ...tourProps
}) => {
  const {siteId} = siteConfig.features;

  const backgroundImage = get(eventConfiguration, ['headerBackgroundImage']);
  const backgroundImageUrl = get(eventConfiguration, [
    'headerBackgroundImage',
    'fields',
    'file',
    'url',
  ]);

  const mobileBackgroundImage = get(eventConfiguration, ['headerMobileBackgroundImage']);
  const mobileBackgroundImageUrl = get(eventConfiguration, [
    'headerMobileBackgroundImage',
    'fields',
    'file',
    'url',
  ]);

  const headerImageLink =
    get(eventConfiguration, ['headerImageLink']) ||
    eventRootLink(siteConfig.seasonConfig, tourProps.season, tourProps.eventId, 'Cup');

  const backgroundColor = get(eventConfiguration, ['headerBackgroundColor', 'value']);

  const navigation =
    siteId === 'cups' ? (
      <CupsSeasonNavigation
        headerImageLink={backgroundImage && headerImageLink}
        backgroundImageUrl={backgroundImageUrl}
        siteConfig={siteConfig}
        eventConfiguration={eventConfiguration}
        season={tourProps.season}
        extraClassName="dn db-m"
      />
    ) : siteId === 'nationsleague' ? (
      <NLSeasonNavigation
        siteConfig={siteConfig}
        season={tourProps.season}
        extraClassName="dn db-m"
        tourTab={tourProps.tourTab}
      />
    ) : (
      <TourNavigation {...tourProps} siteConfig={siteConfig} extraClassName="dn db-m" />
    );

  const mobileNavigation =
    siteId === 'cups' ? (
      <CupsSeasonNavigation
        headerImageLink={mobileBackgroundImage && headerImageLink}
        backgroundImageUrl={mobileBackgroundImageUrl}
        bgColor={'dark-50'}
        hideImage={true}
        siteConfig={siteConfig}
        season={tourProps.season}
        eventConfiguration={eventConfiguration}
        extraClassName="z-1"
      />
    ) : siteId === 'nationsleague' ? (
      <NLSeasonNavigationMobile
        siteConfig={siteConfig}
        season={tourProps.season}
        tourTab={tourProps.tourTab}
      />
    ) : (
      <TourNavigationMobile {...tourProps} siteConfig={siteConfig} />
    );

  return (
    <header className="TourLayout-Header z-nav-main top-0 left-0">
      <SkipLink targetRef={skipLinkTargetRef} />

      {/* Desktop topmost header (external links) */}
      <TopHeader display={['none', 'none', 'block']} extraClassName="z-nav-header" />

      {/* Desktop Tour level Navigation */}

      {navigation}

      {/* Mobile Navigation */}
      <Box display={['block', 'block', 'none']}>{mobileNavigation}</Box>

      {/* Display:block from some reason leaves a huge gap inside a header, hence the flexes */}
      {backgroundImage && (
        <Flex
          display={['flex', 'flex', 'none']}
          style={backgroundColor && {backgroundColor: backgroundColor}}
          bgColor={backgroundColor ? 'inherit' : 'dark-50'}
        >
          {headerImageLink ? (
            <a style={{display: 'flex'}} href={headerImageLink}>
              <ContentfulPicture
                style={{marginBottom: '-1px', width: '100%', display: 'flex'}}
                alt="Banner image"
                asset={backgroundImage}
              />
            </a>
          ) : (
            <ContentfulPicture
              style={{marginBottom: '-1px', width: '100%', display: 'flex'}}
              alt="Banner image"
              asset={backgroundImage}
            />
          )}
        </Flex>
      )}

      {/* Any extras, e.g. event mobile navigation */}
      {children}
    </header>
  );
};
