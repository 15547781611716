import React from 'react';
import cx from 'classnames';

interface Option<OptionValue extends string> {
  label: string;
  value: OptionValue;
}

interface IPillSelect<OptionValue extends string> {
  /** Array of Options, which consist of label and value of each pill */
  options: Array<Option<OptionValue>>;
  /** Unique name for the inputs. */
  inputNameId: string;
  /** The initial selected option. */
  initial: OptionValue;
  /* Control label that will be announced to screen readers. */
  controlLabel: string;
  /** Whether to hide the legend visually. You probably don't want this :) */
  hideLegend?: boolean;
  /** Extra visual class for the legend */
  legendExtraClassName?: string;
  /** A class for selection */
  selectedClassName?: string;
  /** Whether to hide the visual style for the group */
  hideGroupStyle?: boolean;
  /** Callback function, fired when the state changes. */
  onChange?: (state: OptionValue) => void;

  // TODO: Could have a "renderLegend" to allow for h1-6 tags. Best with a <Section> element.
}

interface IPillSelectState<OptionValue extends string> {
  selected: OptionValue;
}

// TODO: Consider a blue background style, with a stacked border. Will be more perceivable.
const defaultSelectedCls = 'bg-silver-30';

/**
 * Switch button with explicit states and accessibility baked-in.
 * Use this to create grouped "pill" options.
 *
 * NOTE: It might be preferable to use SwitchButton if the states are binary and of the on/off kind.
 *
 * Inspired by Scott O'Hara's article:
 * @see https://scottaohara.github.io/a11y_styled_form_controls/src/radio-button--pill/
 *
 * Similarly, it might be desirable to use a group of buttons instead, if changes happen on input
 * @see https://www.w3.org/TR/UNDERSTANDING-WCAG20/consistent-behavior-unpredictable-change.html
 *
 * @todo use React Hooks
 */
export class PillSelect<OptionValue extends string> extends React.Component<
  IPillSelect<OptionValue>,
  IPillSelectState<OptionValue>
> {
  constructor(props: IPillSelect<OptionValue>) {
    super(props);
    this.state = {selected: this.props.initial};

    // Bind methods
    this.handleSelection = this.handleSelection.bind(this);
  }

  /** Set state, handle callbacks if specified */
  handleSelection(selected: OptionValue) {
    return (ev: React.ChangeEvent<HTMLInputElement>) => {
      // NOTE: dispatches the onChange asynchronously, after the setState has been called.
      // This takes scripting time from 160ms to 65ms in profiling, since we do not have
      // to resolve the event handler synchronously.
      this.setState({selected}, () => this.props.onChange && this.props.onChange(selected));
    };
  }

  render() {
    const {
      options,
      inputNameId,
      controlLabel,
      hideLegend,
      legendExtraClassName,
      selectedClassName = defaultSelectedCls,
      hideGroupStyle = false,
    } = this.props;
    // Namespace these to be sure
    const {selected} = this.state;

    return (
      <fieldset className="PillSelect">
        <legend className={cx('mb2 fw7', legendExtraClassName, hideLegend && 'visually-hidden')}>
          <span>{controlLabel}</span>
        </legend>

        <div
          className={cx(
            'flex items-center w-min-c',
            !hideGroupStyle && 'pa1 ba bw1 br-pill b--dark-30',
          )}
        >
          {options.map(option => (
            /** The input is visually hidden, positioned relative to the label wrapper, so that some AT can interact with it better */
            <div key={option.value} className="relative ws-nowrap">
              <input
                type="radio"
                name={inputNameId}
                id={`switch--${option.value}`}
                checked={selected === option.value}
                onChange={this.handleSelection(option.value)}
              />
              <label
                htmlFor={`switch--${option.value}`}
                className={cx(
                  'db pv1 ph2 ph3-m ba bw2 br-pill b--transparent',
                  selected === option.value && selectedClassName,
                )}
              >
                {option.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    );
  }
}
