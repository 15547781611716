import moment from 'moment';
import {Map} from 'immutable';
import Event from 'fiba/common/core/models/api/events/Event';
import {getCookieValue} from 'fiba/common/utils/cookies';
import {
  isProfileUnconfirmed,
  isOlderThanMinimumAge,
  isYoungerThanMaximumAge,
} from 'fiba/play/utils/player';

export enum RegistrationStage {
  Method = 'METHOD',
  Form = 'FORM',
  TeamForm = 'TEAM_FORM',
  Verify = 'VERIFY',
  TeamEdit = 'TEAM_EDIT',
  AwaitingOrganizerConfirmation = 'AWAITING_ORGANIZER_CONFIRMATION',
  Done = 'DONE',
  Unknown = 'UNKNOWN',
}

// Could be better off as Records, but for now they are Maps from the store
// Mostly partials of their typed counterparts
export type CategoryLikeMap = Map<any, any>;
export type PlayerLikeMap = Map<any, any>;

export type PlayerInviteValidationError = {
  name: string;
  message: string;
};

export const creatPlayerInviteValidationError = (
  name: PlayerInviteValidationError['name'],
  message: PlayerInviteValidationError['message'],
): PlayerInviteValidationError => ({
  name,
  message,
});

export const playerInviteValidatorFactory = (
  player: PlayerLikeMap,
  category: CategoryLikeMap,
  event: Event,
) => {
  const errors: PlayerInviteValidationError[] = [];

  const minimumAge = category.get('minimumAge');
  const maximumAge = category.get('maximumAge');
  const gender = category.get('gender');

  if (isProfileUnconfirmed(player.get('emailVerified'), player.get('isExternal'))) {
    errors.push(
      creatPlayerInviteValidationError(
        'unconfirmedProfile',
        'This player does not have a confirmed profile.',
      ),
    );
  }

  if (typeof minimumAge !== 'undefined' && !isOlderThanMinimumAge(player, category, event)) {
    errors.push(
      creatPlayerInviteValidationError(
        'minAge',
        "This player's age does not meet the category minimum age criteria.",
      ),
    );
  }

  if (typeof maximumAge !== 'undefined' && !isYoungerThanMaximumAge(player, category, event)) {
    errors.push(
      creatPlayerInviteValidationError(
        'maxAge',
        "This player's age exceeds category maximum age criteria.",
      ),
    );
  }

  if (!!gender && gender !== player.get('gender')) {
    errors.push(
      creatPlayerInviteValidationError(
        'gender',
        "This player's gender does not match the category gender.",
      ),
    );
  }

  return {
    errors,
    hasErrors: Object.keys(errors).length > 0,
    getMessages: () => Object.values(errors).map(({message}) => message),
  };
};

export const isUserUnder13 = (user: Map<any, any>, now = moment()) => {
  const dob = user.get('dateOfBirth');

  if (dob) {
    // The DOB from app backend is currently coming in various formats so let Moment parse this
    // TODO: once it is certain app backend is consistent in its return we could revise this
    const dateOfBirth = moment(dob);
    return dateOfBirth.add(13, 'years').isSameOrAfter(now);
  }

  return false;
};

export const getTicket = (state: Map<string, any>, categoryId: string) =>
  getCookieValue(`fiba_cookie_registration_${categoryId}`, state.get('cookies'))
    ? JSON.parse(getCookieValue(`fiba_cookie_registration_${categoryId}`, state.get('cookies')))
    : undefined;

export const getEventTeamRegistrationRoute = (eventId: string, isEmbed = false) =>
  isEmbed ? `/embed/events/${eventId}/register` : `/events/${eventId}/register`;

export const getTeamRegisterCategoryRoute = (
  eventId: string,
  categoryId: string,
  isEmbed = false,
) =>
  isEmbed
    ? `/embed/events/${eventId}/register/${categoryId}`
    : `/events/${eventId}/register/${categoryId}`;

export const getTeamRegisterLoginRoute = (eventId: string, categoryId: string, isEmbed = false) =>
  isEmbed
    ? `/embed/events/${eventId}/register/${categoryId}/login`
    : `/events/${eventId}/register/${categoryId}/login`;

export const getTeamRegisterProfileRoute = (eventId: string, categoryId: string, isEmbed = false) =>
  isEmbed
    ? `/embed/events/${eventId}/register/${categoryId}/form/profile`
    : `/events/${eventId}/register/${categoryId}/form/profile`;

export const getTeamRegisterDoneRoute = (eventId: string, categoryId: string, isEmbed = false) =>
  isEmbed
    ? `/embed/events/${eventId}/register/${categoryId}/done`
    : `/events/${eventId}/register/${categoryId}/done`;

export const getEventTeamRegistrationU13ErrorRoute = (
  eventId: string,
  categoryId: string,
  isEmbed = false,
) =>
  isEmbed
    ? `/embed/events/${eventId}/register/${categoryId}/u13-error`
    : `/events/${eventId}/register/${categoryId}/u13-error`;
