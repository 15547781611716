import {UNDEFINED_IOC} from 'fiba/common/utils/Locations';
import {Map} from 'immutable';
import Player from 'fiba/common/core/models/api/players/Player';

export enum SessionToken {
  PlayerInvitation = 'PlayerInvitationSession',
  PlayerParticipation = 'PlayerParticipationSession',
  TeamEmailVerified = 'TeamEmailVerifiedSession',
  EditTeam = 'EditTeamSession',
  EditMultipleTeams = 'EditMultipleTeamsSession',
}

export const getTokenType = (user: Map<any, any>) => user.getIn(['fibaState', 'type']);
export const getTokenEmail = (user: Map<any, any>) => user.getIn(['fibaState', 'email']);
export const getTokenPlayerId = (user: Map<any, any>) => user.getIn(['fibaState', 'playerId']);

export const isPlayerInvitationSession = (user: Map<any, any>) =>
  getTokenType(user) === SessionToken.PlayerInvitation;
export const isPlayerParticipationSession = (user: Map<any, any>) =>
  getTokenType(user) === SessionToken.PlayerParticipation;
export const isTeamEmailVerifiedSession = (user: Map<any, any>) =>
  getTokenType(user) === SessionToken.TeamEmailVerified;
export const isEditTeamSession = (user: Map<any, any>) =>
  getTokenType(user) === SessionToken.EditTeam;
export const isEditMultipleTeamsSession = (user: Map<any, any>) =>
  getTokenType(user) === SessionToken.EditMultipleTeams;

export const isYouByUser = (user: Map<any, any>, player: Player) => {
  return player.id && user.getIn(['data', 'id']) === player.id;
};

export const isYouByToken = (user: Map<any, any>, player: Player) =>
  (player.email && getTokenEmail(user) === player.email) ||
  (player.id && getTokenPlayerId(user) === player.id);

export const isYou = (user: Map<any, any>, player: Player) =>
  isYouByToken(user, player) || isYouByUser(user, player);
export const isUserValid = (user: Map<any, any>) => !!user.get('data');

export const getValidUserEmail = (user: Map<any, any>) => {
  const email = user.getIn(['data', 'email']);
  if (email && email.indexOf('@') > -1) {
    return email;
  }
  return undefined;
};

export const getUserPhoneNumber = (user: Map<any, any>) => user.getIn(['data', 'phoneNumber']);

export const isUserEmailVerified = (user: Map<any, any>) => !!user.getIn(['data', 'emailVerified']);

// Form any of the following permutations from fields `homeCity`, `homeCountry` and `nationality`
// (unless `includeNationality` is set to falsy, then nationality is not included)
// city
// country
// city, country
// city, country (nat)
// city (nat)
// country (nat)
// (nat)
// <empty>
export const getHomeLocation = (user: Map<any, any>, includeNationality = true) => {
  const location = [];
  const nationality =
    user.get('nationality') === UNDEFINED_IOC || !user.get('nationality')
      ? []
      : [`(${user.get('nationality')})`];

  if (user.get('homeCity') && user.get('homeCity') !== 'DefaultCity') {
    location.push(`${user.get('homeCity')}`);
  }
  if (user.get('homeCountry') && user.get('homeCountry') !== 'DefaultNation') {
    location.push(`${user.get('homeCountry')}`);
  }

  return includeNationality
    ? [location.join(', ')].concat(nationality).join(' ')
    : [location.join(', ')];
};

export const getProfileGender = (user: Map<any, any>, translations: Map<string, string>) => {
  const gender = user.get('gender');
  return gender ? translations.get(gender.toUpperCase()) : '-';
};

export const getUserId = (user: Map<any, any>) => user.getIn(['data', 'id']);
