import React from 'react';
import cx from 'classnames';
import {List} from 'immutable';
import {TourTeam} from 'fiba/common/core/models/api/tour/TourTeam';
import {getProfileImage} from 'fiba/common/core/images';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Box} from 'fiba/wt/ui/box/Box';
import {LazyImageWithPlaceholder} from 'fiba/wt/ui/image/LazyImageWithPlaceholder';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import RegularTeam from 'fiba/common/core/models/api/teams/Team';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {ProCircuitTourTeam} from 'fiba/common/core/models/api/tour/ProCircuitTourTeam';
import SimpleLink from 'fiba/common/ui/simpleLink/SimpleLink';

type Team = ProCircuitTourTeam | TourTeam | RegularTeam;

interface ITeam {
  team: Team;
}
interface Props {
  teams: List<Team>;
  className?: string;
  playerClassName?: string;
  teamTitle?: React.FC<ITeam>;
  getPlayerLink?: (teamId: string, playerId: string) => string;
  genderizeDefaultImages?: boolean;
  showPlayerFirstName?: boolean;
}

export const TeamList: React.FC<Props> = ({teams, className = '', ...rest}) => (
  <ul className={`TeamList ${className}`}>
    {teams.map(team => (
      <li key={team.id} id={team.id}>
        <TeamRow team={team} key={team.id} {...rest} />
      </li>
    ))}
  </ul>
);

type TeamRowProps = Pick<
  Props,
  | 'teamTitle'
  | 'getPlayerLink'
  | 'genderizeDefaultImages'
  | 'playerClassName'
  | 'showPlayerFirstName'
> &
  ITeam;

const TeamRow: React.FC<TeamRowProps> = ({
  team,
  getPlayerLink,
  playerClassName = '',
  teamTitle: TeamTitle,
  showPlayerFirstName = false,
  genderizeDefaultImages = false,
}) => {
  return (
    <Box borderColor="silver-30" border="bottom">
      <Flex
        pv="3"
        ph={['2', '3', '4']}
        extraClassName="ml-auto mr-auto mw8"
        flexWrap={['wrap', 'wrap', 'nowrap']}
        debugClassName="TourTeamsRow"
      >
        <Box
          width={['100', '100', '5-half', '6']}
          pa="2"
          pb={['2', '2', '1']}
          alignSelf="center"
          extraClassName="ph3-l "
        >
          {TeamTitle ? (
            <TeamTitle team={team} />
          ) : (
            <SubHeading fontSize={['5', '4']}>
              <TeamNameAssembly team={team} isFlagVisible isSuffixVisible />
            </SubHeading>
          )}
        </Box>

        <div className="w-100 ">
          <ul className={cx('w-100 flex flex-wrap')}>
            {team.teamMembers
              .sortBy(player => player.lastName)
              .map(teamMember => (
                <li key={teamMember.id} className={`pa1 pa2-s w-16 mw4 db ${playerClassName}`}>
                  <LazyImageWithPlaceholder
                    aspectRatio="1x1"
                    src={getProfileImage(
                      genderizeDefaultImages ? 'play' : 'wt',
                      teamMember,
                      'small',
                    )}
                    alt={`Profile of ${getPlayerName(teamMember)}`}
                    className="o-fit-contain o-pos-b"
                    containerStyle={{bgColor: 'silver-30'}}
                  />

                  <p className="TeamList-PlayerLink">
                    {getPlayerLink ? (
                      <SimpleLink
                        className="link link--dark"
                        href={getPlayerLink(team.id, teamMember.id)}
                      >
                        {showPlayerFirstName && teamMember.firstName} {teamMember.lastName}
                      </SimpleLink>
                    ) : (
                      teamMember.lastName
                    )}
                  </p>
                </li>
              ))}
          </ul>
        </div>
      </Flex>
    </Box>
  );
};
