import React, {useContext} from 'react';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section, Heading} from 'fiba/wt/ui/heading/Heading';
import {Box} from 'fiba/wt/ui/box/Box';
import {eventRootLink, EventType} from 'fiba/wt/utils/linkUtils';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {connect} from 'fiba/common/utils/reactUtils';
import {RootState} from 'fiba/wt/stores/rootStore';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {PlayByPlayStatisticsFeed} from 'fiba/wt/ui/playByPlayFeed/statistics/Feed';
import {PlayByPlayTableOfficialsFeed} from 'fiba/wt/ui/playByPlayFeed/officialsTable/Feed';

export type GamePlayByPlayTab = 'root' | 'table-officials-data' | 'statistics-data';

interface OwnProps {
  season: string;
  eventId: string;
  gameId: string;
  tab: GamePlayByPlayTab;
}

interface ReduxProps {
  eventTypeData: WebData<EventType>;
}

type Props = OwnProps & ReduxProps;

const getTabView = ({tab, gameId}: {tab: GamePlayByPlayTab; gameId: string}) => {
  switch (tab) {
    case 'statistics-data':
      return <PlayByPlayStatisticsFeed gameId={gameId} />;
    case 'table-officials-data':
    case 'root':
    default:
      return <PlayByPlayTableOfficialsFeed gameId={gameId} />;
  }
};

const GamePlayByPlayPageImpl: React.FC<Props> = props => {
  const {season, eventId, eventTypeData, gameId, tab} = props;
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <Spacer vSpace="3">
      {/* NOTE: We want this header for a better document outline (heading hierarchy).
       * However, due to the tabs being there, and discussions internally, we have decided
       * to not have them present visually. Ideally we would, but we don't.
       */}
      <VisuallyHidden>
        <Heading pt="4" pb="3" ph="3">
          Play by Play
        </Heading>
      </VisuallyHidden>
      <ViewWebData data={eventTypeData}>
        {eventType => (
          <>
            <Tabs
              level="nested"
              extraClassName="ph3 mw6 ca ml-auto mr-auto"
              navigationLabel="Play by Play"
              baseUrl={`${eventRootLink(
                seasonConfig,
                season,
                eventId,
                eventType,
              )}/games/${gameId}/play-by-play`}
            >
              <Tab href="/table-officials-data" hrefAliases={['/']}>
                Table Officials Data
              </Tab>
              <Tab href="/statistics-data">Statistics Data</Tab>
            </Tabs>
            <Box pv="3">
              <Section>{getTabView({tab, gameId})}</Section>
            </Box>
          </>
        )}
      </ViewWebData>
    </Spacer>
  );
};

const mapStateToProps = (state: RootState, {eventId}: OwnProps): ReduxProps => ({
  eventTypeData: getEventType(state.get('events'), eventId),
});

export const GamePlayByPlayPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  GamePlayByPlayPageImpl,
);
