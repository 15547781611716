import {List, Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/wt/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import {
  NationalTeamEventsLike,
  NationalTeamEvents,
  NationalTeamEventsCategory,
} from 'fiba/common/core/models/api/NationalTeamEvents';
import {RootState} from 'fiba/wt/stores/rootStore';

export interface StoreState extends Map<string, NationalTeamEventsLike> {
  //   __meta: {
  //     season: {
  //       data: [...],
  //     },
  //   },
  // };
}

const reducers = {
  loadNationsLeagueSeasonEvents: (state: StoreState, season: string): StoreState =>
    state.updateIn(['__meta', season], setMeta.isLoading),

  loadNationsLeagueSeasonEventsSuccess: (
    state: StoreState,
    season: string,
    events: NationalTeamEventsLike,
  ): StoreState =>
    state
      .set(season, NationalTeamEvents.fromJS(events))
      .updateIn(['__meta', season], setMeta.isLoaded),

  loadNationsLeagueSeasonEventsError: (
    state: StoreState,
    season: string,
    _error: Error,
  ): StoreState => state.updateIn(['__meta', season], setMeta.isError),
};

export const reducer = createReducer<StoreState>(__filename, fromJS({}), reducers);

// This function deliberately is very careful about the data missing as it is used to also
// check if an event is a NL game or not, thus the whole NL structure might be missing from state.
export const getConferenceIdForEvent = (
  state: RootState,
  season: string,
  eventId: string,
): string | undefined => {
  const seasonEvents: List<NationalTeamEventsCategory> = state.getIn([
    'nationsLeagueEvents',
    season,
    'data',
  ]);

  const conferenceId = seasonEvents?.find(conference =>
    conference.events.some(event => event.id === eventId),
  )?.conferenceId;

  return conferenceId;
};

const actions = createActions(__filename, reducers, {
  loadNationsLeagueSeasonEvents: (season: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadNationalTeamSeasonEvents('nationsleague', season)
      .then(res => actions.loadNationsLeagueSeasonEventsSuccess(season, res.data))
      .catch(rethrow((err: Error) => actions.loadNationsLeagueSeasonEventsError(season, err))),

  loadNationsLeagueSeasonEventsError: (_season: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<StoreState>(NationalTeamEvents);
