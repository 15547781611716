import React, {useContext} from 'react';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Link} from 'fiba/wt/ui/link/Link';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';
import {FlexCardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {EventTeamPlayersView} from 'fiba/wt/ui/eventTeamPlayers/EventTeamPlayers';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventTeamLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';

interface TeamListItemProps {
  season: string;
  eventId: string;
  eventType: EventType;
  team: Team;
  standing?: ResultsStanding;
}

const tableRow = (header: string, data: string | number) => (
  <tr>
    <th className="pv1 pr2 tl v-mid">{header}</th>
    <td className="pv1 tr b v-mid tnum">{data}</td>
  </tr>
);

interface IStatsTable {
  standing?: ResultsStanding;
  season: string;
  eventId: string;
}

const StatsTableLarge = ({standing, season, eventId}: IStatsTable) =>
  !!standing && (
    <table className="dtbl w-100 ht-100">
      <tbody>
        <EventProgrammability
          season={season}
          eventId={eventId}
          renderDefault={() => null}
          renderLive={() => tableRow('Standing', 'playing')}
          renderPost={() => tableRow('Standing', standing.teamRank)}
        />
        {/*   NOTE: If a team has 0 games played, we assume that they played in the Qualifying Draw
      (QD).  This is a feature from #1571. If a team has truly played 0 games but no QD, then it is
      a data issue  and the team should not show up at all in the results. If these assumptions
      change in the future, feel free to change this!*/}
        {tableRow('Games played', standing.gamesPlayed === 0 ? 'Played QD' : standing.gamesPlayed)}
        {tableRow('Games won', standing.gamesWon)}
        {tableRow('Win %', Math.round(standing.winRatio * 100))}
        {tableRow('Total points', standing.totalPoints)}
        {tableRow('Average points', (Math.round(standing.averagePoints * 10) / 10).toFixed(1))}
      </tbody>
    </table>
  );

const StatsTableMedium = ({standing, season, eventId}: IStatsTable) =>
  !!standing && (
    <table className="dtbl w-100 ht-100">
      <tbody>
        <EventProgrammability
          season={season}
          eventId={eventId}
          renderDefault={() => null}
          renderLive={() => tableRow('Standing', 'Playing')}
          renderPost={() => tableRow('Standing', standing.teamRank)}
        />
        {tableRow('Played', standing.gamesPlayed)}
        {tableRow('Won', standing.gamesWon)}
      </tbody>
    </table>
  );

const StatsTableSmall = ({standing}: Pick<IStatsTable, 'standing'>) =>
  !!standing && (
    <React.Fragment>
      <span className="pr3 tl v-mid">Played</span>
      <span className="pr4 tr b v-mid">{standing.gamesPlayed}</span>
      <span className="pr3 tl v-mid">Won</span>
      <span className="tr b v-mid">{standing.gamesWon}</span>
    </React.Fragment>
  );

export const TeamListItem: React.FC<TeamListItemProps> = ({
  season,
  eventId,
  eventType,
  team,
  standing,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const theme = useContext(ThemeContext);

  const heading = (
    <SubHeading fontSize={['4', '4', '5', '4']}>
      <TeamNameAssembly
        team={team}
        standing={standing}
        isFlagVisible
        isSeedVisible
        isSuffixVisible
      />
    </SubHeading>
  );

  return (
    <FlexCardContainer
      pa={['3', '3', '3', '4']}
      flexDirection={['column', 'column', 'row', 'row']}
      debugClassName="TeamListItem"
    >
      <Flex
        flexGrow="1"
        pv={['0', '0', '3', '0']}
        flexDirection={['column', 'column', 'row', 'column']}
        width={['100', '100', '75', '66']}
        alignItems={['start', 'start', 'center', 'start']}
      >
        {/* Team name and flag */}
        <Box pb={['0', '0', '0', '4']} width={['auto', 'auto', '25', 'auto']}>
          {/* Link to the team page, if the team is real */}
          {team.isPlaceholder ? (
            heading
          ) : (
            <Link
              colorTheme={theme.linkTheme}
              href={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)(team.id)}
              display="inline_block"
            >
              {heading}
            </Link>
          )}
        </Box>

        <Box
          border="bottom"
          mnl="4"
          alignSelf="stretch"
          borderWidth={['0', '0', '0', '1']}
          borderColor="dark-50"
          display={['none', 'none', 'none', 'block']}
          debugClassName="separator"
        />

        {/* Team stats on mobile */}
        <Box
          display={['block', 'block', 'none', 'none']}
          pt="2"
          pb="3"
          debugClassName="TeamListItem-TeamStatsSmall"
        >
          <EventProgrammability
            season={season}
            eventId={eventId}
            renderDefault={() => null}
            renderLive={() => <StatsTableSmall standing={standing} />}
            renderPost={() => <StatsTableSmall standing={standing} />}
          />
        </Box>

        {/* Team images */}
        <Box
          width={['100', '100', '75', '100']}
          pr={['0', '0', '3', '4']}
          pl={['0', '0', '3', '0']}
          pt={['0', '0', '0', '4']}
          debugClassName="TeamListItem-PlayerImages"
        >
          <EventTeamPlayersView
            team={team}
            season={season}
            eventId={eventId}
            eventType={eventType}
          />
        </Box>
      </Flex>

      {/* Team stats on desktop */}
      <EventProgrammability
        season={season}
        eventId={eventId}
        renderDefault={() => null}
        renderLive={() => (
          <Box
            width="33"
            pv="3"
            ph="4"
            mnv="4"
            mnr="4"
            display={['none', 'none', 'none', 'block']}
            bgColor="silver-20"
            debugClassName="TeamListItem-TeamStatsLarge"
          >
            <StatsTableLarge standing={standing} season={season} eventId={eventId} />
          </Box>
        )}
        renderPost={() => (
          <Box
            width="33"
            pv="3"
            ph="4"
            mnv="4"
            mnr="4"
            display={['none', 'none', 'none', 'block']}
            bgColor="silver-20"
            debugClassName="TeamListItem-TeamStatsLarge"
          >
            <StatsTableLarge standing={standing} season={season} eventId={eventId} />
          </Box>
        )}
      />

      {/* Team stats on tablet */}
      <Box
        display={['none', 'none', 'block', 'none']}
        debugClassName="TeamListItem-TeamStatsMedium"
      >
        <EventProgrammability
          season={season}
          eventId={eventId}
          renderDefault={() => null}
          renderLive={() => (
            <StatsTableMedium standing={standing} season={season} eventId={eventId} />
          )}
          renderPost={() => (
            <StatsTableMedium standing={standing} season={season} eventId={eventId} />
          )}
        />
      </Box>
    </FlexCardContainer>
  );
};
