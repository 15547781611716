import {superstyled, SuperStyled, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';

// tuning knobs for this component
// all of them are optional by default
// export this for convenience, e.g for declaring a "theme"
export type TextStyleProps = SuperStyled<
  | 'fontSize'
  | 'color'
  | 'textAlign'
  | 'measure'
  | 'lineHeight'
  | 'fontWeight'
  | 'textTransform'
  | 'fontFamily'
  | 'align'
  | 'ma'
  | 'mt'
  | 'mb'
  | 'ml'
  | 'mr'
  | 'mh'
  | 'mv'
>;

const defaultTextProps: TextStyleProps = {
  mt: '3',
  mb: '3',
  fontSize: '6',
  measure: 'wide',
  lineHeight: 'copy',
};

export const P = superstyled<TextStyleProps, StyledHTML<'p'>>('p', defaultTextProps);
P.displayName = 'P';

const defaultBlockTextProps: TextStyleProps = {
  fontSize: '6',
  measure: 'wide',
  lineHeight: 'copy',
};

export const BlockText = superstyled<TextStyleProps, StyledHTML<'p'>>('p', defaultBlockTextProps);
BlockText.displayName = 'BlockText';
