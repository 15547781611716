import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {EventActivity} from 'fiba/common/core/models/api/events/EventActivity';
import {Category} from 'fiba/common/core/models/api/events/Category';
import {Court} from 'fiba/common/core/models/api/events/Court';
import {Schedule} from 'fiba/wt/ui/schedule/Schedule';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import Event from 'fiba/common/core/models/api/events/Event';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {NoticeText} from 'fiba/wt/ui/NoticeText/NoticeText';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {ScheduleFilterType} from 'fiba/wt/ui/schedule/scheduleUtils';

interface OwnProps {
  eventId: string;
  filters?: ScheduleFilterType[];
  createTeamHref?: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    games: List<ResultsGameSummary>;
    activities: List<EventActivity>;
    categories: List<Category>;
    courts: List<Court>;
  }>;
}

export const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  // NOTE: For all of thesese (categories, games/summary, /activities), we assume
  // that if the refs (__meta) are loaded in eventStore, thene they are loaded in the respective
  // stores as well. This is OK because that's how the stores work, but we should
  // move them over to RemoteData combinations once those stores are RemoteData based as well
  const gamesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'games', 'summary'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    gameIds =>
      gameIds.map(gameId => state.getIn(['games', 'summary', gameId])) as List<ResultsGameSummary>,
  );

  const activitiesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'activities'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    activityIds =>
      activityIds.map(activityId => state.getIn(['eventActivities', activityId])) as List<
        EventActivity
      >,
  );

  // Collect required data for filters if any

  const categoriesData: RemoteData.WebData<List<Category>> = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds.map(categoryId => state.getIn(['categories', categoryId])) as List<Category>,
  );

  const courtsData: RemoteData.WebData<List<Court>> = RemoteData.map(
    state.getIn(['events', eventId], RemoteData.NotAsked()) as RemoteData.WebData<Event>,
    event => event.courts,
  );

  // Compose loading state from above
  const data = RemoteData.map4(
    gamesData,
    activitiesData,
    categoriesData,
    courtsData,
    (games, activities, categories, courts) => {
      return {
        games,
        activities,
        categories,
        courts,
      };
    },
  );

  return {
    data,
  };
};

type Props = OwnProps & ReduxProps;

export const EventScheduleImpl: React.FC<Props> = ({
  eventId,
  filters,
  data,
  createTeamHref,
  createGameHref,
}) => (
  <div className="EventSchedule">
    <ViewWebData data={data}>
      {({games, categories, activities, courts}) => {
        if (!games || games.size === 0) {
          return <NoticeText mt="4">The schedule will be released closer to the event.</NoticeText>;
        }
        return (
          <CardContainer>
            <Schedule
              id={eventId}
              eventId={eventId}
              games={games}
              activities={activities}
              categories={categories}
              courts={courts}
              filters={filters}
              createTeamHref={createTeamHref}
              createGameHref={createGameHref}
              extraColumns={['court', 'category']}
            />
          </CardContainer>
        );
      }}
    </ViewWebData>
  </div>
);

export const EventSchedule = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(EventScheduleImpl);
