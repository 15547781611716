import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';

export interface TourGuideProps {
  tourId: string;
  season: string;
}

export const TourGuide: React.FunctionComponent<TourGuideProps> = ({tourId, season}) => (
  <div className="TourGuide">
    <ContentfulArticle
      contentPath={`tours/${tourId}`}
      articleType={ArticleTypes.TourGuideSnippet}
    />
  </div>
);
