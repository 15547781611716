import React, {useState, useMemo} from 'react';
import PlayByPlayTableOfficialsData, {
  PlayByPlayTableOfficialsDataEmpty,
} from 'fiba/common/core/models/api/playByPlay/PlayByPlayTableOfficialsData';
import Action from 'fiba/common/core/models/api/playByPlay/PlayByPlayTableOfficialsAction';
import PlayByPlayPlayer from 'fiba/common/core/models/api/playByPlay/PlayByPlayPlayer';
import PlayByPlayTeam from 'fiba/common/core/models/api/playByPlay/PlayByPlayTeam';
import {connect, assetPath} from 'fiba/common/utils/reactUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {RootState} from 'fiba/wt/stores/rootStore';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';
import {Map, List} from 'immutable';
import {ImageWithPlaceholder} from 'fiba/wt/ui/image/Image';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Box, BoxStyleProps} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {TypeScale} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {styledAPItoClassNames, StyleMapType} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {PillSelect} from 'fiba/wt/ui/pillSelect/PillSelect';
import {getPlayerName} from 'fiba/wt/ui/playByPlayFeed/PlayerName';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

// TODO: this could be moved contrib/types
// and used for PlayByPlayTableOfficialsAction action type for better typing
const ACTION_TYPES = {
  FOUL: 'FoulAction',
  SCORE: 'ScoreAction',
  FREE_THROW_AWARD: 'FreeThrowAwardAction',
  GAME_FINALIZATION: 'GameFinalizationAction',
  TEAM_CHALLENGE: 'TeamChallengeAction',
  TIMEOUT: 'TimeoutAction',
  CLOCK_RESET: 'ClockResetAction',
  CLOCK_STOP: 'ClockStopAction',
  CLOCK_START: 'ClockStartAction',
  FIRST_POSSESSION: 'FirstPossessionAction',
};

interface OwnProps {
  gameId: string;
}

interface ReduxProps {
  data: RemoteData.RemoteData<PlayByPlayTableOfficialsData | PlayByPlayTableOfficialsDataEmpty, {}>;
}

type Props = ReduxProps & OwnProps;

const mapStateToProps = (state: RootState, {gameId}: OwnProps): ReduxProps => {
  return {
    data: RemoteData.map(
      RemoteData.fromMetaObject(
        state.getIn(['games', '__meta', 'playByPlayTableOfficialsData', gameId], Map()).toJS(),
      ),
      () => state.getIn(['games', 'playByPlayTableOfficialsData', gameId]),
    ),
  };
};

const PlayByPlayTableOfficialsFeedImpl: React.FC<Props> = ({data}) => {
  return RemoteData.match(data, {
    Success: tableOfficialsData => {
      // We got data, but it is empty
      if (tableOfficialsData instanceof PlayByPlayTableOfficialsDataEmpty) {
        return (
          <Spacer vSpace="3" pt="4">
            <Box maxWidth="5" align="center">
              <ImageWithPlaceholder
                containerStyle={{bgColor: 'transparent'}}
                aspectRatio="1x1"
                src={assetPath('/img/data-coming-soon.svg')}
                alt="A box with sheets of data falling in."
              />
            </Box>
            <BlockText textAlign="center" align="center" fontSize="5">
              Play-by-Play data coming soon!
            </BlockText>
          </Spacer>
        );
      }
      // We got data, and it is ok
      else {
        return <Feed tableData={tableOfficialsData} />;
      }
    },
    // The meta was an unexpected error
    Failure: () => <BlockText>An unexpected error occured.</BlockText>,
    // TODO: Make BareLoadingIndicator
    Loading: () => (
      <div className="LoadingIndicator pv5 tc">
        <div className="dib h4 w4 LoadingIndicator-Indicator">
          <LoadingSVG purpose="standalone" />
        </div>
      </div>
    ),
    NotAsked: () => null,
  });
};

export const PlayByPlayTableOfficialsFeed = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  PlayByPlayTableOfficialsFeedImpl,
);

//
// Feed

interface FeedProps {
  tableData: PlayByPlayTableOfficialsData;
  initialFilter?: Filter;
}

// Font sizes for copy and title text
// NOTE: Tying the font size to breakpoints is a bit meh, but
// better than doing nothing :)
const TEXT_FONT_SIZE: TypeScale[] = ['6'];
const TITLE_FONT_SIZE: TypeScale[] = ['4', '4'];
const LIST_STYLE_CLASS = styledAPItoClassNames({
  vSpace: '3',
  width: '100',
  fontSize: TEXT_FONT_SIZE,
  fontWeight: '4',
  lineHeight: 'title',
});

type Filter = 'scoresFoulsOnly' | 'allActions';

const filteredActions = [ACTION_TYPES.SCORE, ACTION_TYPES.FOUL, ACTION_TYPES.FREE_THROW_AWARD];
// Filter keeping only Scores/Fouls
const filterScoresFoulsOnly = (actions: List<Action>): List<Action> => {
  return actions.filter(action => filteredActions.includes(action.type)).toList();
};

export const Feed: React.FC<FeedProps> = ({tableData, initialFilter = 'scoresFoulsOnly'}) => {
  //Filter for the pill select to show only Scores/Fouls, or all actions
  const [actionsFilter, setActionsFilter] = useState<Filter>(initialFilter);

  // Filter the actions as specified by the user, and remove action type Game Finalization
  const actionsArray = useMemo<List<Action>>(() => {
    return (actionsFilter === 'allActions'
      ? tableData.actions
      : filterScoresFoulsOnly(tableData.actions)
    )
      .filter(action => action.type !== ACTION_TYPES.GAME_FINALIZATION)
      .toList();
  }, [actionsFilter, tableData.actions]);

  const playersLookup = useMemo<Map<string, PlayByPlayPlayer>>(() => {
    return Map(tableData.players.map(player => [player.id, player]));
  }, [tableData.players]);

  return (
    // NOTE: We use tabular numbers throughout
    <Spacer vSpace="4" width="100" extraClassName="tnum PlayByPlayTableOfficialsFeed-Feed">
      <Box>
        <PillSelect
          options={[
            {label: 'Scores and fouls', value: 'scoresFoulsOnly'},
            {label: 'All actions', value: 'allActions'},
          ]}
          inputNameId="actions-switch"
          initial="scoresFoulsOnly"
          controlLabel="Actions visibility:"
          onChange={selected => setActionsFilter(selected)}
        />
      </Box>
      <ul className={`actions ${LIST_STYLE_CLASS}`}>
        {actionsArray.map((action, index) => (
          // NOTE: We use CSS to include the line linking items
          <li key={index}>
            <ViewAction
              homeTeam={tableData.homeTeam}
              awayTeam={tableData.awayTeam}
              players={playersLookup}
              action={action}
            />
          </li>
        ))}
      </ul>
    </Spacer>
  );
};

// Action views

/**
 * Display an action.
 * If the action belongs to a team, then assign it to the respective side of the screen.
 * Otherwise, display the general / centered view for actions.
 */
const ViewAction: React.FC<{
  homeTeam: PlayByPlayTeam;
  awayTeam: PlayByPlayTeam;
  action: Action;
  players: Map<string, PlayByPlayPlayer>;
}> = ({homeTeam, awayTeam, action, players}) => {
  const actionTitle = translateActionType(action);
  switch (action.teamId) {
    case homeTeam.teamId:
      return renderTeamActions('row', action, actionTitle, players, 'blue-20', homeTeam);
    case awayTeam.teamId:
      return renderTeamActions('row-reverse', action, actionTitle, players, 'purple-10', awayTeam);
    default:
      return (
        <Box pa="3" width="100" textAlign="center" bgColor="silver-20">
          {renderGeneralTimeScores(action, actionTitle)}
        </Box>
      );
  }
};

/**
 * Display teams in different colors
 * Narrow screen: score board on the start, team action on the end
 * Wide screen: home team on the start, away team on the end
 * General actions displayed in-between
 */
const renderTeamActions = (
  flexDirection: 'row' | 'row-reverse',
  action: Action,
  actionTitle: string,
  players: Map<string, PlayByPlayPlayer>,
  bgcolor: keyof StyleMapType['bgColor'],
  team: PlayByPlayTeam,
) => {
  const {minutes, seconds} = getMinutesSeconds(action.remainingTime);
  return (
    <Flex
      width="100"
      bgColor="silver-20"
      // On narrow screens, always flip the items; otherwise, go with the one specified
      flexDirection={['row-reverse', flexDirection]}
      flexWrap={'wrap'}
    >
      <Flex
        pa="3"
        fontSize={TEXT_FONT_SIZE}
        width={['50', '33']}
        bgColor={bgcolor}
        color="dark-50"
        alignItems="center"
      >
        <Box>
          {/*If player made a non-foul action*/}
          {action.playerId && action.type !== ACTION_TYPES.FOUL && (
            <BlockText fontSize={TEXT_FONT_SIZE}>
              <span className="fw7">{getPlayerName(players, action.playerId)}</span>
              <br />
              <span>{actionSubtitle(action)}</span>
            </BlockText>
          )}
          {/*For foul, ignore player Id if set*/}
          {action.type === ACTION_TYPES.FOUL && (
            <BlockText fontSize={TEXT_FONT_SIZE}>
              <span className="fw7">{actionTitle}</span>
              <br />
              <span>
                <TeamNameAssembly standing={team} isSuffixVisible isIocVisible={false} />
              </span>
            </BlockText>
          )}
          {/* Otherwise, team action */}
          {action.teamId && !action.playerId && action.type !== ACTION_TYPES.FOUL && (
            <BlockText fontSize={TEXT_FONT_SIZE}>
              <span className="fw7">{actionTitle}</span>
              <br />
              <span>
                <TeamNameAssembly standing={team} isSuffixVisible isIocVisible={false} />
              </span>
            </BlockText>
          )}
        </Box>
      </Flex>

      <Flex
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        pa="3"
        width={['50', '33']}
      >
        <Box textAlign="center">
          {minutes}:{seconds}
        </Box>
        {renderTeamScoreOrFouls(action)}
      </Flex>
    </Flex>
  );
};

// Show action type and time for general action, sometimes also score or fouls
const renderGeneralTimeScores = (action: Action, actionType: string) => {
  const {minutes, seconds} = getMinutesSeconds(action.remainingTime);
  return (
    <>
      <Box textAlign="center">
        {actionType} {minutes}:{seconds}
      </Box>
      {renderTeamScoreOrFouls(action)}
    </>
  );
};

const SCORE_BOX_STYLES: BoxStyleProps = {
  border: 'all',
  borderColor: 'steel-20',
  borderRadius: '2',
  borderWidth: '1',
  pv: '1',
  ph: '2',
};

// Show Time/Score or Time/Foul assigned to team
const renderTeamScoreOrFouls = (action: Action) => {
  switch (action.type) {
    case ACTION_TYPES.FOUL:
    case ACTION_TYPES.TEAM_CHALLENGE:
      return (
        <Flex flexWrap="nowrap" justifyContent="center">
          <Box mr="2" {...SCORE_BOX_STYLES}>
            {action.homeTeamFouls}
          </Box>
          <Box fontWeight="6" fontSize={TITLE_FONT_SIZE}>
            {' '}
            -{' '}
          </Box>
          <Box {...SCORE_BOX_STYLES} ml="2">
            {action.awayTeamFouls}
          </Box>
        </Flex>
      );
    case ACTION_TYPES.SCORE:
    case ACTION_TYPES.FREE_THROW_AWARD:
      return (
        <Box fontWeight="6" textAlign="center" fontSize={TITLE_FONT_SIZE}>
          {action.homeTeamScore} - {action.awayTeamScore}
        </Box>
      );

    default:
      return null;
  }
};

// Utils

// Display player actions (Point shot made, Free throw made, and unsportsmanlike or disqualifying fouls)
// There are rare occasions in which the player commits an unsportsmanlike or disqualifying foul
// and according to https://github.com/futurice/fiba-3x3-play/wiki/Displaying-Play-by-play
// we need to display more descriptive texts

const actionSubtitle = (action: Action) => {
  if (action.foulType) {
    switch (action.foulType) {
      case 'FOUL_TYPE_UNSPORSTMANLIKE': // Please, don't correct this intentional typo; it's the same in Scores
        return 'Unsportsmanlike foul';
      case 'FOUL_TYPE_DISQUALIFYING':
        return 'Disqualifying foul';
      default:
        return null;
    }
  } else if (action.isFreeThrow) {
    return 'Free throw made';
  } else {
    return `${action.points} point shot made`;
  }
};

// Format time to MM:SS
const padWithZero = (time: number): string => {
  return (time < 10 ? '0' : '') + time;
};

const getMinutesSeconds = (time: number): {minutes: string; seconds: string} => {
  const date = new Date(time);
  return {
    minutes: padWithZero(date.getMinutes()),
    seconds: padWithZero(date.getSeconds()),
  };
};

// Mapping actions, could just remove action word but this gives more flexibility
const translateActionType = (action: Action): string => {
  switch (action.type) {
    case ACTION_TYPES.FIRST_POSSESSION:
      return 'First Possession';
    case ACTION_TYPES.CLOCK_START:
      return 'Clock Start';
    case ACTION_TYPES.CLOCK_STOP:
      return 'Clock Stop';
    case ACTION_TYPES.CLOCK_RESET:
      return 'Clock Reset';
    case ACTION_TYPES.SCORE:
      return 'Score';
    case ACTION_TYPES.FOUL:
      return 'Foul';
    case ACTION_TYPES.FREE_THROW_AWARD:
      return `${action.awardValue || ''} free throw${action.awardValue !== 1 ? 's' : ''} awarded`;
    case ACTION_TYPES.TIMEOUT:
      return 'Timeout';
    case ACTION_TYPES.TEAM_CHALLENGE:
      return getChallengeTitle(action.isRejected);
    default:
      return action.type;
  }
};

function getChallengeTitle(rejected: boolean) {
  if (rejected === true) {
    return 'Team Challenge lost';
  } else if (rejected === false) {
    return 'Team Challenge won';
  } else {
    return 'Team Challenge unclear';
  }
}
