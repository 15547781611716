import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {EventGuide} from 'fiba/wt/ui/eventPage/EventGuide';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

interface EventAboutPageProps {
  season: string;
  tourId: string;
  eventId: string;
}

export const EventAboutPage: React.FunctionComponent<EventAboutPageProps> = ({
  eventId,
  ...props
}) => (
  <Spacer ph={['3', '3', '1']} debugClassName="EventMediaServicesPage">
    {/* TODO: Remove extra titles from Contentful articles, and fully qualify the "About" with the event name */}
    <section>
      <EventPageHeading eventId={eventId} page="About" />
      <Section>
        <Box measure="very-wide">
          <EventGuide eventId={eventId} {...props} />
        </Box>
      </Section>
    </section>
  </Spacer>
);
