import React, {useContext} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {ArticleThemeContext, ArticleSize} from 'fiba/wt/ui/article/Article';
import {Link} from 'fiba/wt/ui/link/Link';
import {TourGuide} from 'fiba/wt/ui/tourGuide/TourGuide';
import {TourStandings} from 'fiba/wt/ui/tourStandings/TourStandings';
import {SidebarSectionHeading} from 'fiba/wt/ui/sidebarSection/SidebarSection';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {SponsorBoxList, SponsorType} from 'fiba/wt/ui/sponsorBox/SponsorBoxList';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {TourProgrammability} from 'fiba/wt/ui/tourProgrammability/TourProgrammability';
import {Section} from 'fiba/wt/ui/heading/Heading';

export interface Props {
  tourId: string;
  season: string;
}

const TourSidebarStandings: React.FC<Props> = ({season, tourId}) => {
  return (
    <TourProgrammability
      tourId={tourId}
      season={season}
      renderDefault={() => null}
      renderBeforeSeason={() => null}
      renderPreEvent={() => (
        <SeasonStandings seasonIsOver={false} season={season} tourId={tourId} />
      )}
      renderLiveEvent={() => (
        <SeasonStandings seasonIsOver={false} season={season} tourId={tourId} />
      )}
      renderPostEvent={() => (
        <SeasonStandings seasonIsOver={false} season={season} tourId={tourId} />
      )}
      renderBetweenEvents={() => (
        <SeasonStandings seasonIsOver={false} season={season} tourId={tourId} />
      )}
      renderAfterSeason={() => (
        <SeasonStandings seasonIsOver={true} season={season} tourId={tourId} />
      )}
    />
  );
};

export const TourSidebar: React.FC<Props> = ({tourId, season}) => {
  const theme = useContext(ThemeContext);

  return (
    <Box debugClassName="TourSidebar" fontSize={theme.fontSize}>
      <ArticleThemeContext.Provider value={{articleSize: ArticleSize.Small}}>
        <Section>
          <Spacer vSpace="3">
            <Spacer vSpace="4" pa="3" bgColor="fullwhite" shadow="1">
              <Spacer vSpace="3">
                <SidebarSectionHeading>About the Tour</SidebarSectionHeading>
                <Box fontSize={theme.fontSize}>
                  <TourGuide tourId={tourId} season={season} />
                </Box>
              </Spacer>
              <TourSidebarStandings season={season} tourId={tourId} />
            </Spacer>

            <SponsorBoxList
              tourId={tourId}
              types={[SponsorType.Sponsor1, SponsorType.Sponsor2, SponsorType.Sponsor3]}
            />
          </Spacer>
        </Section>
      </ArticleThemeContext.Provider>
    </Box>
  );
};

const SeasonStandings = ({seasonIsOver, tourId, season}) => {
  const titleText = seasonIsOver ? 'Final Standings' : 'Race to the Final';
  const theme = useContext(ThemeContext);

  return (
    <Spacer vSpace="3" width="100">
      <Section>
        <TourStandings
          heading={<SidebarSectionHeading>{titleText}</SidebarSectionHeading>}
          tourId={tourId}
          season={season}
          stage="latest"
          numRows={12}
          columns={['rank', 'name']}
        />
        <Box textAlign="right" fontSize="7">
          <Link colorTheme={theme.linkTheme} href={`/${season}/standings`}>
            See all standings
          </Link>
        </Box>
      </Section>
    </Spacer>
  );
};
