/*
  Cache service abstracts our model of loading and storing resources.

  This is still WIP and some features are missing, but the basic gist is this:

  - Resource stored in redux store under `/events/$eventId` should have metadata
    stored in `/events/__meta/$eventId` which holds information about the state
    of loading that resource. It should be a `Map` that looks something like this:
    - `isLoading`, boolean indicating whether the resource is currently being loaded
    - `isLoaded`, boolean indicating whether the resource is loaded
    - `isError`, boolean indicating if there was an error in loading the resource

  - Cache service itself is a function that you call with a path to the resource you
    want to access. A metadata will be collected from a path that is generated from
    the resource path by inserting `__meta` after the root element of the path.
    If the function is called with the single argument of the resource path, it will
    operate synchronously returning either the resource or void value if it doesn't exist.
    The function can also be called by giving a function as a second argument in which
    case the cache function will operate asynchronously returning a promise. In this case
    if the resource exists the promise will be resolved with the resource. If the resource
    doesn't exist, the function that was given as the second argument will be called and
    it should load the given resource and return a promise which will then be returned
    by the cache function.

  - What is not yet implemented is a `timestamp/expires`-field that would allow us to control
    reloading of resources after certain time instead of loading the resources only initially
    and upon explicit data updates. We are currently also lacking error messages stored in
    this data.

  - Also currently not all stores follow the metadata-structure correctly. These should be
    fixed so that we can more reliably track resource and metadata paths.
    For instance in category-store, results are loaded under `/category/results/$categoryId`,
    but the metadata exists in `/category/__meta/$categoryId/results`. The metadata
    structure should follow the actual data structure so that we can always generate a
    metadata-path from the resource path and vice versa by removing or adding `__meta`
    after the stores root item.

  Another possibility in place of this parallel metadata-structure that we could
  experiment with is taking a functional approach and wrap resources in type constructors
  that would mirror the state of the resource. Since `isLoading`, `isLoaded` and `isError`
  are mutually exclusive we could map the resources in to `ResourceLoaded(x, timestamp)`,
  `ResourceLoading(x)` and `ResourceError(x, err)`. Or alternatively not wrapping the
  resource itself, but using these constructors to generate the metadata.

  @example
  ```
  const services = {..other services..};
  const cache = createCacheService(services);
  const event = cache(['events', eventId]);
  // This will do a lookup for metadata from `['events', '__meta', eventId]`
  const promise = cache(['events', eventId], () => eventActions.loadEvent(eventId));
  ```
*/

import {Map} from 'immutable';
import {isArray, isFunction} from 'lodash';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import CommonServices from 'fiba/common/core/models/CommonServices';

export type OnShouldLoadCallback = (services: CommonServices) => Promise<any> | Array<Promise<any>>;

export interface CacheService {
  <T = any>(path: string[]): T;
  <T = any>(path: string[], onShouldLoad: OnShouldLoadCallback): Promise<T>;
}

// NOTE: Only works on root state
// TODO: Find a clever store structure where the metadata would exist in ['__meta', ...path] in relation to given resource path
export function getMeta(path: string[], state: Map<string, any>) {
  const [root, ...rest] = path;
  return state.getIn([root, '__meta', ...rest]) || Map();
}

export const setMeta = {
  isLoading: (meta = Map()) => meta.set('isLoading', true),

  isLoaded: (meta = Map()) => meta.set('isLoaded', true).delete('isLoading').delete('isError'),

  isError: (meta = Map()) => meta.set('isError', true).delete('isLoading').delete('isLoaded'),
};

function createCacheService(services: CommonServices): CacheService {
  return function cacheService(path: string[], onShouldLoad?: OnShouldLoadCallback) {
    const {store} = services;

    // TODO: Make this generic, so it can be specialised to the store
    const state: Map<string, any> = store.getState();

    // NOTE: We have two codepaths, as we migrate from __meta to WebData

    //
    // MODERN CODEPATH

    // Check if we have the RemoteData- based resources, and use those
    // We do this in an extremely duck-type-y way, because we must
    // move the stores incrementally. If we didn't do this, we'd have to
    // move them all in one PR, and that would be nigh-impossible
    const isWebDataBased = state.getIn([path[0], '__isWebDataBased'], false);
    if (isWebDataBased === true) {
      // Get the requested resource, with "NotAsked" as the undefined value
      const requestedResource: RemoteData.WebData<any> = state.getIn(path, RemoteData.NotAsked());

      // Synchronous version just selects data by path from the state
      if (!onShouldLoad) {
        return requestedResource;
      }

      // Asynchronous version returns a promise that will be either resolved with the
      // cached value or rejected with an error stating the resource path
      return RemoteData.match(requestedResource, {
        // Just return if a success
        Success: data => {
          return Promise.resolve(data);
        },
        // Reject if an error; this is for compatibility with LEGACY
        // TODO: Consider how to best handle / communicate these!
        Failure: anomaly => {
          return Promise.reject(
            new Error(
              `Problem getting: ${path.join('/')} from cache. Anomaly: ${anomaly.category}.`,
            ),
          );
        },
        // If Loading or NotAsked, kick off the controller, and
        // wait for the promises it sends us
        default: () => {
          const promise = onShouldLoad({...services});
          if (isArray(promise)) {
            return Promise.all(promise);
          }

          if (promise && isFunction(promise.then)) {
            return promise;
          }
          return Promise.resolve(promise);
        },
      });
    }

    //
    // LEGACY CODEPATH
    // Synchronous version just selects data by path from the state
    if (!onShouldLoad) {
      return state.getIn(path);
    }

    // Asynchronous version returns a promise that will be either resolved with the
    // cached value or rejected with an error stating the resource path

    const meta = getMeta(path, state);

    if (meta.get('isError')) {
      return Promise.reject(new Error(`Problem getting: ${path.join('/')} from cache`));
    }

    if (meta.get('isLoaded')) {
      return Promise.resolve(state.getIn(path));
    }

    // Don't re-fetch the same data if currently loading
    if (meta.get('isLoading')) {
      return Promise.resolve();
    }

    const promise = onShouldLoad({...services});
    if (isArray(promise)) {
      return Promise.all(promise);
    }

    if (promise && isFunction(promise.then)) {
      return promise;
    }
    return Promise.resolve(promise);
  };
}

export default createCacheService;
