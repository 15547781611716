import React from 'react';
import {Link2} from '@fpapado/react-feather';
import {ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {ArticleCardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Heading, Section, SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Link} from 'fiba/wt/ui/link/Link';
import {Box} from 'fiba/wt/ui/box/Box';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {ContentTypes} from 'fiba/wt/stores/contentStore';
import {connect} from 'fiba/common/utils/reactUtils';
import {List, Map} from 'immutable';
import {Article} from 'fiba/wt/ui/article/Article';
import {EmbeddedMediaTypes} from 'fiba/wt/services/contentfulService';
import {ContentfulEmbeddedMedia} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {ContentfulMarkdown} from 'fiba/wt/ui/contentfulMarkdown/ContentfulMarkdown';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface OwnProps {
  season: string;
  tourId: string;
}

interface ReduxProps {
  articleSections: Array<{
    displayName: string;
    article: {content: string};
  }>;
  metaPaths: string[];
  contentPath: string;
}

type Props = OwnProps & ReduxProps;

interface PageSection {
  displayName: string;
  articleType: ArticleTypes;
}

// If you want to add a section, add it to this list.
const pageSections: PageSection[] = [
  {displayName: 'About', articleType: ArticleTypes.TourGuide},
  {displayName: 'Competition Format', articleType: ArticleTypes.TourCompetitionFormat},
  {displayName: 'Dunk', articleType: ArticleTypes.TourDunk},
  {displayName: 'Shoot-Out', articleType: ArticleTypes.TourShootout},
  {displayName: 'Awards', articleType: ArticleTypes.TourAwards},
  {displayName: 'Prizes', articleType: ArticleTypes.TourPrizes},
  {displayName: 'Ranking', articleType: ArticleTypes.TourRanking},
  {displayName: 'Regulations', articleType: ArticleTypes.TourHandbook},
  {displayName: 'Media Services', articleType: ArticleTypes.TourMediaServices},
  {displayName: 'History', articleType: ArticleTypes.TourHistory},
];

const pageMedia: {[K in string]?: EmbeddedMediaTypes} = {
  Dunk: EmbeddedMediaTypes.YoutubeDunkPlaylist,
  Shootout: EmbeddedMediaTypes.YoutubeShootoutPlaylist,
};

// This page can be considered a "Composite Contentful Article", that handles the loading and existence
// of every section at the top
const mapStateToProps = (state, {tourId}: OwnProps): ReduxProps => {
  const contentPath = `tours/${tourId}`;
  const contentPathArr = contentPath.split('/');

  const articlesWithMeta = pageSections
    .map(({displayName, articleType}) => {
      // Lift content up, so we can skip adding to the list if there is no content
      const article = state
        .getIn(['content', ContentTypes.Article, ...contentPathArr], List())
        .filter(
          (article: Map<string, any>) => article.getIn(['fields', 'articleType']) === articleType,
        )
        .first();

      return article
        ? {
            displayName,
            article: {
              content: article.getIn(['fields', 'content']),
            },
            metaPaths: [`content/__meta/${ContentTypes.Article}/${contentPath}`],
          }
        : undefined;
    })
    .filter(section => !!section);

  // Articles: Record<ArticleType, ContentfulArticle>
  return {
    contentPath,
    articleSections: articlesWithMeta.map(({displayName, article}) => ({displayName, article})),
    metaPaths: [].concat(...articlesWithMeta.map(item => item.metaPaths)),
  };
};

// TODO: Consider vSpace of 5-4-3 vs 4-3-2
const TourMorePageImpl: React.FunctionComponent<Props> = ({
  articleSections,
  contentPath,
  metaPaths,
}) => {
  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <ArticleCardContainer>
          <Spacer vSpace="5" measure="very-wide" align="center">
            <Spacer vSpace="4">
              <PageHeading containerProps={{mh: '0'}} text="Information About the Tour" />
              <Spacer vSpace="3">
                <SubHeading>Jump to a section</SubHeading>
                <ol className="flex hs3 overflow-x-auto ws-nowrap">
                  {articleSections.map(({displayName}) => (
                    <li key={displayName}>
                      <Link data-external href={`#${slugify(displayName)}`}>
                        {displayName}
                      </Link>
                    </li>
                  ))}
                </ol>
              </Spacer>
            </Spacer>
            <Section>
              {articleSections.map(
                ({displayName, article}) =>
                  !!article && (
                    <Spacer vSpace="4" key={displayName}>
                      <Heading id={slugify(displayName)}>{displayName}</Heading>
                      {pageMedia[displayName] ? (
                        <ContentfulEmbeddedMedia
                          contentPath={contentPath}
                          mediaType={pageMedia[displayName]}
                        />
                      ) : null}
                      <Article>
                        <ContentfulMarkdown content={article.content} />
                      </Article>
                      <Box>
                        <Link2 purpose="decorative" className="w1 ht1 mr1 v-mid" />
                        <Link href={`#${slugify(displayName)}`} data-external>
                          Link to {displayName} section
                        </Link>
                      </Box>
                    </Spacer>
                  ),
              )}
            </Section>
          </Spacer>
        </ArticleCardContainer>
      )}
    </Loading>
  );
};

export const TourMorePage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(TourMorePageImpl);

function slugify(str: string) {
  return str
    .replace(/[^\w\s]/gi, '')
    .replace(/\s/gi, '-')
    .toLowerCase();
}
