import partialSeasonConfig from 'fiba/wt/config/seasonConfig';
import features from 'fiba/wt/config/features';
import {lightTheme} from 'fiba/wt/ui/themeContext/ThemeContext';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

const SiteConfig: ISiteConfig = {
  seasonConfigOverrides: partialSeasonConfig,
  seasonConfig: {seasons: {}},
  features,
  theme: lightTheme,
};

export default SiteConfig;
