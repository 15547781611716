import React, {useMemo} from 'react';
import {ExternalLink} from '@fpapado/react-feather';
import {connect} from 'fiba/common/utils/reactUtils';
import {Link, LinkColorTheme} from 'fiba/wt/ui/link/Link';

//
// Types

interface IMaybeExternalLinkOwn {
  href: string;
  colorTheme: LinkColorTheme;
  children: (
    externalSvg: React.ComponentType<{size?: string | number; className?: string}>,
  ) => React.ReactNode;
  className?: string;
}

interface IMaybeExternalLinkRedux {
  pageHost: string;
  pageHostname: string;
}

type IMaybeExternalLink = IMaybeExternalLinkOwn & IMaybeExternalLinkRedux;

//
// mapStateToProps; get the URL from the Redux store
const mapStateToProps = state => {
  return {
    pageHost: state.getIn(['route', 'host']),
    pageHostname: state.getIn(['route', 'hostname']),
  };
};

//
// Component Implementation

const _MaybeExternalLink: React.SFC<IMaybeExternalLink> = ({
  href,
  className,
  pageHost,
  pageHostname,
  colorTheme,
  children,
}) => {
  const isExternal = useMemo(() => {
    // Get a normalised URL object from the target url
    // NOTE: The url can be relative, for internal links
    // In that case, we provide a base URL to resolve it with
    // TODO: Could not get protocol-relative URLs (//) to work, should figure out why...
    const targetUrl = new URL(href, `https://${pageHost}/`);
    return targetUrl.hostname !== pageHostname;
  }, [href, pageHost, pageHostname]);

  return (
    <Link colorTheme={colorTheme} href={href} isExternal={isExternal} extraClassName={className}>
      {children(
        isExternal
          ? ({size, className}) => (
              <ExternalLink
                purpose="standalone"
                aria-label="(opens in new tab)"
                size={size}
                className={className}
              />
            )
          : ({}) => <></>,
      )}
    </Link>
  );
};

//
// Connected export
export const MaybeExternalLink = connect<IMaybeExternalLinkRedux, {}, IMaybeExternalLinkOwn>(
  mapStateToProps,
)(_MaybeExternalLink);
