import React, {useContext} from 'react';
import cx from 'classnames';
import {connect} from 'fiba/common/utils/reactUtils';
import {routerPushLocation} from 'fiba/common/services/routerService';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {BoxProvider, BoxStyleProps} from 'fiba/wt/ui/box/Box';
import {Flex, FlexStyleProps} from 'fiba/wt/ui/flex/Flex';

interface ISeasonSelect {
  onSelect?: (season: string) => any;
  buttonStyleProps?: BoxStyleProps;
  containerStyleProps?: FlexStyleProps;
}

interface ISeasonSelectReduxState {
  // Where <string> means <season>
  currentSeason: string;
}

interface ISeasonSelectDispatch {
  onSelectInternal: (season: string) => any;
}

type SeasonSelectProps = ISeasonSelect & ISeasonSelectReduxState & ISeasonSelectDispatch;

// Mapping state
const mapStateToProps = (state, props: ISeasonSelect): ISeasonSelectReduxState => ({
  // FIXME: Change when ids come from API
  // This is not coming from the state atm, but it might be in the future
  // NOTE: Sort the seasons by descending order
  currentSeason: state.getIn(['route', 'payload', 'season']),
});

const mapDispatchToProps = (dispatch, {onSelect}: ISeasonSelect): ISeasonSelectDispatch => ({
  onSelectInternal: season => {
    dispatch(routerPushLocation(`/${season}/`));

    if (onSelect) {
      onSelect(season);
    }
  },
});

// TODO: Use hooks
// Component
const SeasonSelectInner: React.FunctionComponent<SeasonSelectProps> = props => {
  const {currentSeason, containerStyleProps, buttonStyleProps, onSelectInternal} = props;
  const {seasonConfig} = useContext(SiteConfigContext);
  const seasons = Object.keys(seasonConfig.seasons).sort().reverse();

  // TODO: localise / localize
  return (
    <Flex {...containerStyleProps} position="relative" debugClassName="SeasonSelect">
      <details className="w-100 details-overlay">
        <BoxProvider fontSize="6" fontWeight="6" {...buttonStyleProps}>
          {({className}) => (
            <summary className={cx('w-max-c pointer select-none', className)}>
              {!!currentSeason ? currentSeason : 'Select Season'}
            </summary>
          )}
        </BoxProvider>

        <ul className=" bt b--silver-50 pa3 vs3 absolute left-0 right-0 top-100 z-nav-popout bg-silver-10 shadow-2 overflow-y-auto">
          {seasons.map(season => (
            <li key={season}>
              <a
                className="db link link--dark underline-focus"
                href={`/${season}/`}
                onClick={_ev => onSelectInternal(season)}
              >
                {season}
              </a>
            </li>
          ))}
        </ul>
      </details>
    </Flex>
  );
};

// Connect
export const SeasonSelect = connect<ISeasonSelectReduxState, ISeasonSelectDispatch, ISeasonSelect>(
  mapStateToProps,
  mapDispatchToProps,
)(SeasonSelectInner);
