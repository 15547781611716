import {Map, List, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import tourResultActions from 'fiba/wt/stores/tourResultStore';
import newsActions from 'fiba/wt/stores/newsStore';
import {Tour, TourLike} from 'fiba/common/core/models/api/Tour';
import {TourResultsLike} from 'fiba/common/core/models/api/results/TourResults';
import {Event, EventLike} from 'fiba/common/core/models/api/events/Event';
import {TourTeamListLike} from 'fiba/common/core/models/api/tour/TourTeamList';
import {TourTeam} from 'fiba/common/core/models/api/tour/TourTeam';
import {ProCircuitTourTeamListLike} from 'fiba/common/core/models/api/tour/ProCircuitTourTeamList';
import {ProCircuitTourTeam} from 'fiba/common/core/models/api/tour/ProCircuitTourTeam';
import {NewsPreviewListLike} from 'fiba/common/core/models/api/feed/NewsPreviewList';
import * as RemoteData from 'fiba/wt/utils/RemoteData';

export interface TourStoreState extends Map<string, Tour> {
  // TODO: `__meta`
  // __meta: { $tourId: { results: { $stage: TourResults } } }
}

const reducers = {
  //
  // Tour
  loadTour: (state: TourStoreState, tourId: string): TourStoreState =>
    state.updateIn(['__meta', tourId], setMeta.isLoading),

  loadTourSuccess: (state: TourStoreState, tour: TourLike): TourStoreState =>
    state.set(tour.id, Tour.fromJS(tour)).updateIn(['__meta', tour.id], setMeta.isLoaded),

  loadTourError: (state: TourStoreState, tourId: string, error: Error): TourStoreState =>
    state.updateIn(['__meta', tourId], setMeta.isError),

  //
  // Tour events
  loadEventsByTour: (state: TourStoreState, tourId: string): TourStoreState =>
    state.updateIn(['__meta', tourId, 'events'], setMeta.isLoading),

  loadEventsByTourSuccess: (
    state: TourStoreState,
    tourId: string,
    events: EventLike[],
  ): TourStoreState =>
    state
      .setIn(['__meta', tourId, 'events', 'refs'], fromJS(events.map(event => event.id)))
      .updateIn(['__meta', tourId, 'events'], setMeta.isLoaded),

  loadEventsByTourError: (state: TourStoreState, tourId: string, error: Error): TourStoreState =>
    state.updateIn(['__meta', tourId, 'events'], setMeta.isError),

  //
  // Tour challenger events
  loadChallengerEventsByTour: (state: TourStoreState, tourId: string): TourStoreState =>
    state.updateIn(['__meta', tourId, 'challengers'], setMeta.isLoading),

  loadChallengerEventsByTourSuccess: (
    state: TourStoreState,
    tourId: string,
    events: EventLike[],
  ): TourStoreState =>
    state
      .setIn(['__meta', tourId, 'challengers', 'refs'], fromJS(events.map(event => event.id)))
      .updateIn(['__meta', tourId, 'challengers'], setMeta.isLoaded),

  loadChallengerEventsByTourError: (
    state: TourStoreState,
    tourId: string,
    error: Error,
  ): TourStoreState => state.updateIn(['__meta', tourId, 'challengers'], setMeta.isError),

  //
  // Tour SuperQuest events
  loadSuperQuestEventsByTour: (state: TourStoreState, tourId: string): TourStoreState =>
    state.updateIn(['__meta', tourId, 'superQuests'], setMeta.isLoading),

  loadSuperQuestEventsByTourSuccess: (
    state: TourStoreState,
    tourId: string,
    events: EventLike[],
  ): TourStoreState =>
    state
      .setIn(['__meta', tourId, 'superQuests', 'refs'], fromJS(events.map(event => event.id)))
      .updateIn(['__meta', tourId, 'superQuests'], setMeta.isLoaded),

  loadSuperQuestEventsByTourError: (
    state: TourStoreState,
    tourId: string,
    error: Error,
  ): TourStoreState => state.updateIn(['__meta', tourId, 'superQuests'], setMeta.isError),

  //
  // Tour teams
  loadTeamsByTour: (state: TourStoreState, tourId: string): TourStoreState =>
    state.updateIn(['__meta', tourId, 'tourTeams'], setMeta.isLoading),

  loadTeamsByTourSuccess: (
    state: TourStoreState,
    tourId: string,
    data: TourTeamListLike,
  ): TourStoreState => {
    const tourTeams = data.teams;
    return state
      .setIn(
        ['__meta', tourId, 'tourTeams', 'refs'],
        fromJS(tourTeams.map(tourTeam => tourTeam.id)),
      )
      .updateIn(['__meta', tourId, 'tourTeams'], setMeta.isLoaded);
  },

  loadTeamsByTourError: (state: TourStoreState, tourId: string, error: Error): TourStoreState =>
    state.updateIn(['__meta', tourId, 'tourTeams'], setMeta.isError),

  //
  // Pro Circuit Tour teams
  loadProCircuitTeamsBySeason: (state: TourStoreState, season: string): TourStoreState =>
    state.updateIn(['__meta', season, 'proCircuitTourTeams'], setMeta.isLoading),

  loadProCircuitTeamsBySeasonSuccess: (
    state: TourStoreState,
    season: string,
    data: ProCircuitTourTeamListLike,
  ): TourStoreState => {
    const proCircuitTourTeams = data.teams;
    return state
      .setIn(
        ['__meta', season, 'proCircuitTourTeams', 'refs'],
        fromJS(proCircuitTourTeams.map(tourTeam => tourTeam.placeholderId)),
      )
      .updateIn(['__meta', season, 'proCircuitTourTeams'], setMeta.isLoaded);
  },

  loadProCircuitTeamsBySeasonError: (
    state: TourStoreState,
    season: string,
    error: Error,
  ): TourStoreState => state.updateIn(['__meta', season, 'proCircuitTourTeams'], setMeta.isError),
};

const externalReducers = {
  //
  // Tour results
  [tourResultActions.types.loadTourResults]: (
    state: TourStoreState,
    tourId: string,
    stage: string,
  ): TourStoreState => state.updateIn(['__meta', 'results', tourId, stage], setMeta.isLoading),

  [tourResultActions.types.loadTourResultsSuccess]: (
    state: TourStoreState,
    results: TourResultsLike,
  ): TourStoreState =>
    state.updateIn(['__meta', 'results', results.tourId, results.stage], setMeta.isLoaded),

  [tourResultActions.types.loadTourResultsError]: (
    state: TourStoreState,
    tourId: string,
    stage: string,
    error: Error,
  ): TourStoreState => state.updateIn(['__meta', 'results', tourId, stage], setMeta.isError),

  //
  // Tour news
  [newsActions.types.loadNewsPreviewsByTour]: (
    state: TourStoreState,
    tourId: string,
  ): TourStoreState => state.updateIn(['__meta', tourId, 'news', 'previews'], setMeta.isLoading),

  [newsActions.types.loadNewsPreviewsByTourSuccess]: (
    state: TourStoreState,
    tourId: string,
    news: NewsPreviewListLike,
  ): TourStoreState =>
    state
      .updateIn(['__meta', tourId, 'news', 'previews'], setMeta.isLoaded)
      .setIn(
        ['__meta', tourId, 'news', 'previews', 'refs'],
        List(news.items.map(newsItem => newsItem.slug)),
      ),

  [newsActions.types.loadNewsPreviewsByTourError]: (
    state: TourStoreState,
    tourId: string,
    error: Error,
  ): TourStoreState => state.updateIn(['__meta', tourId, 'news', 'previews'], setMeta.isError),
};

export const reducer = createReducer<TourStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

const actions = createActions(__filename, reducers, {
  loadTour: (tourId: string, season?: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTour(tourId)
      .then(res => actions.loadTourSuccess(res.data))
      .catch(rethrow((err: Error) => actions.loadTourError(tourId, err))),

  loadTourError: (tourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // Masters Events
  loadEventsByTour: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadEventsByTour(tourId)
      .then(res => actions.loadEventsByTourSuccess(tourId, res.data.events))
      .catch(rethrow((err: Error) => actions.loadEventsByTourError(tourId, err))),

  loadEventsByTourError: (tourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // Challengers
  loadChallengerEventsByTour: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadChallengerEventsByTour(tourId)
      .then(res => actions.loadChallengerEventsByTourSuccess(tourId, res.data.challengers))
      .catch(rethrow((err: Error) => actions.loadChallengerEventsByTourError(tourId, err))),

  loadChallengerEventsByTourError: (tourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // SuperQuests
  loadSuperQuestEventsByTour: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadSuperQuestEventsByTour(tourId)
      .then(res => actions.loadSuperQuestEventsByTourSuccess(tourId, res.data.superQuests))
      .catch(rethrow((err: Error) => actions.loadSuperQuestEventsByTourError(tourId, err))),

  loadSuperQuestChallengerEventsByTourError: (tourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // Teams
  loadTeamsByTour: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTeamsByTour(tourId)
      .then(res => actions.loadTeamsByTourSuccess(tourId, res.data))
      .catch(rethrow((err: Error) => actions.loadTeamsByTourError(tourId, err))),

  loadTeamsByTourError: (tourId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadProCircuitTeamsBySeason: (season: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadProCircuitTeamsBySeason(season)
      .then(res => actions.loadProCircuitTeamsBySeasonSuccess(season, res.data))
      .catch(rethrow((err: Error) => actions.loadProCircuitTeamsBySeasonError(season, err))),

  loadProCircuitTeamsBySeasonError: (season: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<TourStoreState>(Tour);

//
// Utils / Selectors

/**
 * Get the Masters events for a tourId.
 * TODO: Rename to getTourMasters, perhaps.
 */
export const getTourEvents = (
  tourId: string,
  tours: TourStoreState,
  events: Map<string, RemoteData.WebData<Event>>,
): RemoteData.WebData<List<Event>> => {
  // List<WebData<Event>>
  const eventDataList: List<RemoteData.WebData<Event>> = tours
    // Get the list of a tour's Masters
    // TODO: Move this to RemoteData as well
    .getIn(['__meta', tourId, 'events', 'refs'], List())
    // Get those events from the event store
    .map((eventId: string) => events.get(eventId));

  // Transform to WebData<List<Event>>
  // This is a traverse :)
  const eventListData = eventDataList.reduce((listData, eventData) => {
    return RemoteData.map2(listData, eventData, (list, event) => list.push(event));
  }, RemoteData.Success(List<Event>()));

  return eventListData;
};

/**
 * Get the Challenger events for a tourId.
 */
export const getTourChallengers = (
  tourId: string,
  tours: TourStoreState,
  events: Map<string, RemoteData.WebData<Event>>,
): RemoteData.WebData<List<Event>> => {
  // List<WebData<Event>>
  const eventDataList: List<RemoteData.WebData<Event>> = tours
    // Get the list of a tour's Challengers
    .getIn(['__meta', tourId, 'challengers', 'refs'], List())
    // Get those events from the event store
    .map((eventId: string) => events.get(eventId));

  // Transform to WebData<List<Event>>
  const eventListData = eventDataList.reduce((listData, eventData) => {
    return RemoteData.map2(listData, eventData, (list, event) => list.push(event));
  }, RemoteData.Success(List<Event>()));

  return eventListData;
};

/**
 * Get the SuperQuest events for a tourId.
 */
export const getTourSuperQuests = (
  tourId: string,
  tours: TourStoreState,
  events: Map<string, RemoteData.WebData<Event>>,
): RemoteData.WebData<List<Event>> => {
  // List<WebData<Event>>
  const eventDataList: List<RemoteData.WebData<Event>> = tours
    // Get the list of a tour's SuperQuests
    .getIn(['__meta', tourId, 'superQuests', 'refs'], List())
    // Get those events from the event store
    .map((eventId: string) => events.get(eventId));

  // Transform to WebData<List<Event>>
  const eventListData = eventDataList.reduce((listData, eventData) => {
    return RemoteData.map2(listData, eventData, (list, event) => list.push(event));
  }, RemoteData.Success(List<Event>()));

  return eventListData;
};

export const getTourTeams = (
  tourId: string,
  tours: TourStoreState,
  tourTeams: Map<string, TourTeam>,
): List<TourTeam> =>
  tours
    .getIn(['__meta', tourId, 'tourTeams', 'refs'], List())
    .map((teamId: string) => tourTeams.get(teamId))
    .filter((tourTeam: TourTeam) => !!tourTeam);

export const getProCircuitTourTeams = (
  season: string,
  tours: TourStoreState,
  proCircuitTourTeams: Map<string, ProCircuitTourTeam>,
): List<ProCircuitTourTeam> =>
  tours
    .getIn(['__meta', season, 'proCircuitTourTeams', 'refs'], List())
    .map((teamId: string) => proCircuitTourTeams.get(teamId))
    .filter((proCircuitTourTeam: ProCircuitTourTeam) => !!proCircuitTourTeam);
