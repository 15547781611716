import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {Box} from 'fiba/wt/ui/box/Box';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Tabs, Tab, TabPanels, TabPanel} from 'fiba/wt/ui/tabs/Tabs';
import {EventStandingsTab} from 'fiba/wt/ui/eventStandingsPage/EventStandingsTab';
import {BracketsTab} from 'fiba/wt/ui/eventStandingsPage/BracketsTab';
import {PoolStandingsTab} from 'fiba/wt/ui/eventStandingsPage/PoolStandingsTab';
import {EventStandingsPreviewTab} from 'fiba/wt/ui/eventStandingsPage/EventStandingsPreviewTab';
import {eventStandingsLinkProvider, eventStandingsLink, EventType} from 'fiba/wt/utils/linkUtils';
import {ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {NoticeText} from 'fiba/wt/ui/NoticeText/NoticeText';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {CategoryGenderTab, filterCategoryByGender} from 'fiba/wt/utils/categories';
import {capitalize} from 'lodash';
import Category from 'fiba/common/core/models/api/events/Category';

type EventStandingsTab = 'standings' | 'brackets' | 'pools' | 'qualifying-draw' | 'preview';

interface OwnProps {
  season: string;
  eventId: string;
  poolId?: string;
  standingsTab: 'standings' | 'pool' | 'brackets';
  genderTab?: CategoryGenderTab;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    eventType: EventType;

    qualifyingDrawGroups: List<ResultsGroup>;
    poolGroups: List<ResultsGroup>;
    knockoutBrackets: List<List<ResultsGameSummary>>;

    // NOTE: These two are mutually exclusive
    hasOnlyPreviewTab: boolean;
    showFinalStandingsTab: boolean;
    filteredCategoryIds: List<string>;
  }>;
}

export type Props = OwnProps & ReduxProps;

const createPoolHrefProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  genderTab: CategoryGenderTab,
) => (poolId: string) =>
  eventStandingsLinkProvider(
    seasonConfig,
    season,
    eventId,
    eventType,
    genderTab,
  )(`pools${poolId ? `/${poolId}` : ''}`);

const createQDHrefProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  genderTab: CategoryGenderTab,
) => (poolId: string) =>
  eventStandingsLinkProvider(
    seasonConfig,
    season,
    eventId,
    eventType,
    genderTab,
  )(`qd${poolId ? `/${poolId}` : ''}`);

const isGamePlayed = (game: ResultsGameSummary): boolean =>
  (!!game && game.homeTeam && game.homeTeam.teamIsWinner) ||
  (game.awayTeam && game.awayTeam.teamIsWinner) ||
  game.gameIsForfeit;

const arePoolGamesPlayed = (pools: List<ResultsGroup>): boolean =>
  pools.every(pool => pool.groupGames.size > 0 && pool.groupGames.every(isGamePlayed));

const mapStateToProps = (state, {eventId, genderTab}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);

  // NOTE: We are assuming here that there is only 1 category and 1 set of brackets,
  // to support multiple categories and/or multiple set of brackets this needs to be modified accordingly
  // NOTE: We are assuming that if 'events/__meta/eventId/categories' is loaded, then the categories
  // are OK as well. This is fine, but we should change fully to RemoteData once the ResultsStore
  // is RemoteData- based.
  const standingsInfoData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds => {
      // Collect the full standings/seedings for the event
      const filteredCategoryIds: List<string> = categoryIds
        .map((categoryId: string) => state.getIn(['categories', categoryId]))
        .filter(filterCategoryByGender(genderTab))
        .map((category: Category) => category.id)
        .toList();

      const standings: List<ResultsStanding> = filteredCategoryIds
        .flatMap(categoryId => state.getIn(['results', categoryId, 'standings']) as ResultsStanding)
        .filter(standing => !!standing)
        .toList();

      // Collect pool groups and knockout brackets for the event
      const qualifyingDrawGroups: List<ResultsGroup> = filteredCategoryIds
        .flatMap(
          categoryId =>
            state.getIn(['results', categoryId, 'qualifyingDrawGroups']) as ResultsGroup,
        )
        .filter(pool => !!pool)
        .toList();

      const poolGroups: List<ResultsGroup> = filteredCategoryIds
        .flatMap(categoryId => state.getIn(['results', categoryId, 'poolGroups']) as ResultsGroup)
        .filter(pool => !!pool)
        .toList();

      const knockoutBrackets: List<List<ResultsGameSummary>> = filteredCategoryIds
        .flatMap(
          categoryId =>
            state.getIn(['results', categoryId, 'knockoutBrackets']) as List<
              List<ResultsGameSummary>
            >,
        )
        .filter(brackets => !!brackets)
        .toList();

      // From spec:
      // > When no schedule or team published just show the Preview tab => hide every other tab
      const combinedPools = qualifyingDrawGroups.concat(poolGroups);
      const hasAnyScheduledGames =
        combinedPools.size > 0 && combinedPools.some(pool => pool.groupGames.size > 0);
      const hasAnyTeamsAssigned = standings.size > 0;
      const hasOnlyPreviewTab = !hasAnyScheduledGames || !hasAnyTeamsAssigned;

      // From spec:
      // > Until either all QD pools have been played or all pools have been played (not just one game
      // > or some of the games....but all games) hide the Final Standings tab (see more on this at the end of this document)
      //
      // Clarification, _if_ QD pools exist and all games are played - or if QD pools _don't_ exist then only account pool games
      // NOTE: Concatenating to single clause for lazy execution of pool games check
      const showFinalStandingsTab =
        !hasOnlyPreviewTab && qualifyingDrawGroups.size > 0
          ? arePoolGamesPlayed(qualifyingDrawGroups)
          : arePoolGamesPlayed(poolGroups);

      return {
        filteredCategoryIds,
        qualifyingDrawGroups,
        poolGroups,
        knockoutBrackets,
        showFinalStandingsTab,
        hasOnlyPreviewTab,
      };
    },
  );

  // Merge the data sources, so they all succeed or fail as one
  const data = RemoteData.map2(eventTypeData, standingsInfoData, (eventType, standingsInfo) => ({
    ...standingsInfo,
    eventType,
  }));

  return {
    data,
  };
};

const EventStandingsPageView: React.FunctionComponent<Props> = ({
  season,
  eventId,
  genderTab,
  data,
}) => {
  const headingId = 'event-standings-heading';
  const {seasonConfig} = useContext(SiteConfigContext);

  return (
    <Spacer ph={['3', '3', '1']}>
      <section>
        <EventPageHeading
          eventId={eventId}
          id={headingId}
          page={`Standings ${genderTab ? capitalize(genderTab) : ''}`}
        />
        <Section>
          {/* NOTE: We have a very coarse-grained Loading... match here */}
          <ViewWebData data={data}>
            {({
              eventType,
              hasOnlyPreviewTab,
              showFinalStandingsTab,
              qualifyingDrawGroups,
              poolGroups,
              knockoutBrackets,
              filteredCategoryIds,
            }) => {
              const noDataExists = [knockoutBrackets, qualifyingDrawGroups, poolGroups].every(
                list => !list || list.size === 0,
              );

              if (noDataExists) {
                return (
                  <NoticeText mt="4">
                    <p>Standings are not available yet.</p>
                  </NoticeText>
                );
              }
              return (
                <Box extraClassName="bl br" bgColor="fullwhite" borderColor="silver-20">
                  <Tabs
                    level="base"
                    navigationLabeledBy={headingId}
                    baseUrl={eventStandingsLink(
                      seasonConfig,
                      season,
                      eventId,
                      eventType,
                      genderTab,
                    )}
                  >
                    {!hasOnlyPreviewTab && (
                      <React.Fragment>
                        {showFinalStandingsTab && <Tab href="/">Standings</Tab>}

                        <Tab href="/brackets">Brackets</Tab>

                        <Tab href="/pools">Pools</Tab>

                        {qualifyingDrawGroups.size > 0 && <Tab href="/qd">Qualifying Draw</Tab>}
                      </React.Fragment>
                    )}

                    <Tab
                      href="/preview"
                      hrefAliases={hasOnlyPreviewTab || !showFinalStandingsTab ? ['/'] : []}
                    >
                      Preview
                    </Tab>
                  </Tabs>

                  <Box ph="3" pb="3" pt="4">
                    <TabPanels
                      baseUrl={eventStandingsLink(
                        seasonConfig,
                        season,
                        eventId,
                        eventType,
                        genderTab,
                      )}
                      // TODO remove comment:
                      // provide the event long URL as alias so the tabs still work if user uses it:
                      baseUrlAlias={eventStandingsLink(
                        seasonConfig,
                        season,
                        eventId,
                        eventType,
                        genderTab,
                        false,
                      )}
                    >
                      {!hasOnlyPreviewTab && (
                        <React.Fragment>
                          {showFinalStandingsTab && (
                            <TabPanel href="/">
                              <EventStandingsTab
                                season={season}
                                eventId={eventId}
                                eventType={eventType}
                                categoryIdFilter={filteredCategoryIds}
                                qualifyingDrawGroups={qualifyingDrawGroups}
                                poolGroups={poolGroups}
                                knockoutBrackets={knockoutBrackets}
                                createPoolHref={createPoolHrefProvider(
                                  seasonConfig,
                                  season,
                                  eventId,
                                  eventType,
                                  genderTab,
                                )}
                                createQDHref={createQDHrefProvider(
                                  seasonConfig,
                                  season,
                                  eventId,
                                  eventType,
                                  genderTab,
                                )}
                              />
                            </TabPanel>
                          )}

                          <TabPanel href="/brackets">
                            <BracketsTab
                              season={season}
                              eventId={eventId}
                              knockoutBrackets={knockoutBrackets}
                            />
                          </TabPanel>

                          <TabPanel href="/pools">
                            <PoolStandingsTab
                              season={season}
                              eventId={eventId}
                              eventType={eventType}
                              pools={poolGroups}
                              title="Pool standings overview"
                              createPoolHref={createPoolHrefProvider(
                                seasonConfig,
                                season,
                                eventId,
                                eventType,
                                genderTab,
                              )}
                            />
                          </TabPanel>

                          {qualifyingDrawGroups.size > 0 && (
                            <TabPanel href="/qd">
                              <PoolStandingsTab
                                season={season}
                                eventId={eventId}
                                eventType={eventType}
                                title="Qualification draw overview"
                                pools={qualifyingDrawGroups}
                                createPoolHref={createQDHrefProvider(
                                  seasonConfig,
                                  season,
                                  eventId,
                                  eventType,
                                  genderTab,
                                )}
                              />
                            </TabPanel>
                          )}
                        </React.Fragment>
                      )}

                      <TabPanel
                        href="/preview"
                        hrefAliases={hasOnlyPreviewTab || !showFinalStandingsTab ? ['/'] : []}
                      >
                        <EventStandingsPreviewTab
                          season={season}
                          eventId={eventId}
                          categoryIdFilter={filteredCategoryIds}
                        />
                      </TabPanel>
                    </TabPanels>
                  </Box>
                </Box>
              );
            }}
          </ViewWebData>
        </Section>
      </section>
    </Spacer>
  );
};

export const EventStandingsPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  EventStandingsPageView,
);
