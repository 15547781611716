import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {Schedule} from 'fiba/wt/ui/schedule/Schedule';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {BlockText} from 'fiba/wt/ui/text/Text';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';

interface OwnProps {
  eventId: string;
  teamId: string;
  createTeamHref?: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
}

interface ReduxProps {
  gamesData: RemoteData.WebData<List<ResultsGameSummary>>;
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {eventId, teamId}: OwnProps): ReduxProps => {
  // NOTE: We assume that if the events/__meta (refs) are loaded, then the
  // game summary itself is loaded, and get it directly from the state.
  // TODO: Move this to RemoteData once the gamesStore is RemoteData-based
  const gamesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'games', 'summary'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    gameIds =>
      gameIds
        .map((gameId: string) => state.getIn(['games', 'summary', gameId]) as ResultsGameSummary)
        .filter(
          // Pick games where the team has played either as home or away
          game =>
            teamId &&
            ((game.homeTeam && game.homeTeam.teamId === teamId) ||
              (game.awayTeam && game.awayTeam.teamId === teamId)),
        ) as List<ResultsGameSummary>,
  );

  return {
    gamesData,
  };
};

const _TeamSchedule: React.FC<Props> = ({
  teamId,
  eventId,
  gamesData,
  createTeamHref,
  createGameHref,
}) => (
  <div className="TeamSchedule">
    <ViewWebData data={gamesData}>
      {games =>
        games.isEmpty() ? (
          <BlockText>This team does not have games scheduled yet.</BlockText>
        ) : (
          <Schedule
            id={teamId}
            eventId={eventId}
            games={games}
            createTeamHref={createTeamHref}
            createGameHref={createGameHref}
            extraColumns={['category', 'court']}
          />
        )
      }
    </ViewWebData>
  </div>
);

export const TeamSchedule = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_TeamSchedule);
