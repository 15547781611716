import React from 'react';

interface Opts {
  initial?: boolean;
  onChange?: (state: boolean) => any;
}

/** A hook that allows toggling an `on` boolean state, with an explicit override. */
export const useToggle = ({initial = false, onChange}: Opts) => {
  const [on, setOn] = React.useState<boolean>(initial);
  const toggle = () => {
    setOn(!on);
    onChange && onChange(on);
  };
  return {on, toggle, setOn};
};
