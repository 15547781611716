import React from 'react';
import {getEventName} from 'fiba/wt/stores/eventStore';
import {RootState} from 'fiba/wt/stores/rootStore';
import {Heading, HeadingStyleProps} from 'fiba/wt/ui/heading/Heading';
import {connect} from 'fiba/common/utils/reactUtils';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {Box, BoxStyleProps} from 'fiba/wt/ui/box/Box';

interface OwnProps {
  eventId: string;
  id?: string;
  page?: string;
  season?: string;
  className?: 'string';
  children?: React.ReactNode;
  topSection?: React.ReactNode;
  containerProps?: BoxStyleProps;
  headingProps?: HeadingStyleProps;
}

interface ReduxProps {
  eventName: RemoteData.WebData<string>;
}

const DEFAULT_CONTAINER_PROPS: BoxStyleProps = {
  pt: ['4', '4', '2'],
  pb: '2',
  border: 'bottom',
  borderColor: 'silver-40',
  mb: '3',
};

const DEFAULT_HEADING_PROPS: HeadingStyleProps = {
  fontSize: '3',
  fontFamily: 'united',
  lineHeight: 'solid',
  letterSpacing: 'tiny',
};

type Props = ReduxProps & OwnProps;

function _EvenPageHeading({
  eventName,
  id,
  season = '',
  page = '',
  headingProps = {},
  containerProps = {},
  children,
  topSection,
}: Props) {
  return (
    <ViewWebData data={eventName}>
      {eventName => (
        <Box {...DEFAULT_CONTAINER_PROPS} {...containerProps}>
          {topSection}
          <Heading {...DEFAULT_HEADING_PROPS} {...headingProps} id={id}>
            {eventName}
            {season && ` ${season}`}
            {page && `: ${page}`}
          </Heading>
          {children}
        </Box>
      )}
    </ViewWebData>
  );
}

const mapStateToProps = (state: RootState, {eventId}: OwnProps): ReduxProps => {
  const eventName = getEventName(state, eventId);
  return {eventName};
};

export const EventPageHeading = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EvenPageHeading,
);
