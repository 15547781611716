import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Map} from 'immutable';
import {DangerousContentfulMarkdown} from 'fiba/wt/ui/contentfulMarkdown/ContentfulMarkdown';
import {Article, StyleProps} from 'fiba/wt/ui/article/Article';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {ContentTypes, ArticleTypes} from 'fiba/wt/services/contentfulService';
import {getArticleFromState} from 'fiba/wt/stores/contentStore';

export {ArticleTypes};

interface IContentfulArticle {
  contentPath: string;
  articleType: ArticleTypes;
  articleStyleProps?: StyleProps;
  article?: Map<string, any>;
}

interface IContentfulArticleState {
  article: Map<string, any>;
  metaPaths: string[];
}

type ContentfulArticleProps = IContentfulArticle & IContentfulArticleState;

const mapStateToProps = (
  state,
  {contentPath, articleType, article}: IContentfulArticle,
): IContentfulArticleState => {
  const contentPathArr = contentPath.split('/');
  const isArticle = article && article.size > 0;
  return {
    article: isArticle ? article : getArticleFromState({state, contentPathArr, articleType}),
    metaPaths: [`content/__meta/${ContentTypes.Article}/${contentPathArr[0]}/${contentPathArr[1]}`],
  };
};

const _ContentfulArticle: React.FunctionComponent<ContentfulArticleProps> = ({
  metaPaths,
  article,
  articleStyleProps,
}) => {
  return (
    <div className="ContentfulArticle">
      <Loading metaPaths={metaPaths}>
        {() =>
          !!article && (
            <Article {...articleStyleProps}>
              <DangerousContentfulMarkdown content={article.getIn(['fields', 'content'])} />
            </Article>
          )
        }
      </Loading>
    </div>
  );
};

export const ContentfulArticle = connect<IContentfulArticleState, {}, IContentfulArticle>(
  mapStateToProps,
)(_ContentfulArticle);
