import {superstyled, SuperStyled, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {H} from 'fiba/wt/ui/heading/H';

// Re-export for convencience
export {Section} from 'fiba/wt/ui/heading/HeadingContext';

// TODO: change once the stylingAPI accepts defaultProps not in T
// i.e. 'ma' should not be customisable and is only used as a reset
export type HeadingStyleProps = SuperStyled<
  | 'fontSize'
  | 'measure'
  | 'color'
  | 'lineHeight'
  | 'fontWeight'
  | 'textAlign'
  | 'textTransform'
  | 'fontFamily'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'ml'
  | 'mv'
  | 'mh'
  | 'ma'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'pl'
  | 'pv'
  | 'ph'
  | 'pa'
  | 'border'
  | 'borderColor'
  | 'letterSpacing'
>;

//
// Heading
// Reset margins, add proper lineheight and a distinct font size
const defaultHeadingProps: HeadingStyleProps = {
  ma: '0',
  fontSize: ['3', '3', '2'],
  fontWeight: '6',
  lineHeight: 'title',
};

export const Heading = superstyled<HeadingStyleProps, StyledHTML<'h1'>>(H, defaultHeadingProps);
Heading.displayName = 'Heading';

//
// Subheading
const defaultSubHeadingProps: HeadingStyleProps = {
  ma: '0',
  fontSize: '4',
  fontWeight: '6',
  lineHeight: 'title',
};

export const SubHeading = superstyled<HeadingStyleProps, StyledHTML<'h2'>>(
  H,
  defaultSubHeadingProps,
);
SubHeading.displayName = 'SubHeading';
