import Category from 'fiba/common/core/models/api/events/Category';
import moment from 'moment';
import {Map} from 'immutable';
import {getTeamRegisterCategoryRoute} from 'fiba/common/core/registration';

export enum ClassificationType {
  'NotSet' = 'NotSet',
  'Under13' = 'Under13',
  'Under15' = 'Under15',
  'Under18' = 'Under18',
  'Under23' = 'Under23',
  'Over40' = 'Over40',
  'WorldTour' = 'WorldTour',
  'Open' = 'Open',
  'Wheelchair' = 'Wheelchair',
}

export enum RegistrationType {
  'PublicFullyConfirmed' = 'PublicFullyConfirmed',
  'PublicTeamLead' = 'PublicTeamLead',
  'PublicOrganizerConfirmed' = 'PublicOrganizerConfirmed',
  'ExternalLink' = 'ExternalLink',
  'OrganizerManaged' = 'OrganizerManaged',
  'Unknown' = 'Unknown',
}

export const hasValidRegistrationType = (category: Category) => {
  return Object.values(RegistrationType).includes(category.get('registrationType'));
};

export const getRegistrationTypeConfig = (category: Category) => {
  switch (category.get('registrationType')) {
    case RegistrationType.PublicFullyConfirmed:
      return {
        type: RegistrationType.PublicFullyConfirmed,
        name: 'Fully confirmed',
        configuration: 'Min. 3 confirmed players',
      };
    case RegistrationType.PublicTeamLead:
      return {
        type: RegistrationType.PublicTeamLead,
        name: 'Team lead',
        configuration: 'Only Team lead required',
      };
    case RegistrationType.PublicOrganizerConfirmed:
      return {
        type: RegistrationType.PublicOrganizerConfirmed,
        name: 'Organizer Confirmed',
        configuration: 'Organizer must confirm team',
      };
    case RegistrationType.ExternalLink:
      return {
        type: RegistrationType.ExternalLink,
        name: 'External',
        configuration: 'External registration',
      };
    case RegistrationType.OrganizerManaged:
      return {
        type: RegistrationType.OrganizerManaged,
        name: 'Organizer managed',
        configuration: 'Contact organizer',
      };
    default:
      return {
        type: RegistrationType.Unknown,
        name: 'Unknown',
        configuration: 'Unknown',
      };
  }
};

export type RegistrationTypeConfig = ReturnType<typeof getRegistrationTypeConfig>;

export const isPublicRegistrationType = (category: Category) => {
  const registrationType: string = category.get('registrationType');
  return (
    registrationType === RegistrationType.PublicFullyConfirmed ||
    registrationType === RegistrationType.PublicTeamLead ||
    registrationType === RegistrationType.PublicOrganizerConfirmed
  );
};

export const isExternalRegistrationType = (category: Category) => {
  return category.get('registrationType') === RegistrationType.ExternalLink;
};

export const isOrganizerManagedRegistrationType = (category: Category) => {
  return category.get('registrationType') === RegistrationType.OrganizerManaged;
};

export const isEditableRegistrationType = (category: Category) =>
  !isOrganizerManagedRegistrationType(category) && !isExternalRegistrationType(category);

export const isFullyConfirmedRegistrationType = (category: Category) => {
  return (
    category.get('registrationType') === RegistrationType.PublicFullyConfirmed ||
    category.get('registrationType') === RegistrationType.PublicOrganizerConfirmed
  );
};

export const isTeamLeadRegistrationType = (category: Category) => {
  return category.get('registrationType') === RegistrationType.PublicTeamLead;
};

export const isOrganizerConfirmedRegistrationType = (category: Category) => {
  return category.get('registrationType') === RegistrationType.PublicOrganizerConfirmed;
};

export const isUnkownRegistrationType = (category: Category) => {
  const {type} = getRegistrationTypeConfig(category);
  return type === RegistrationType.Unknown;
};

export const isBeforeCategoryStartDay = (category: Category) => {
  return moment().isBefore(moment(category.get('startDatetime')), 'day');
};

export function getCategoryRegistrationUrl(
  category: Category,
  event: Map<string, any>,
  isEmbed: boolean,
) {
  // If external registration is configured
  if (isExternalRegistrationType(category)) {
    return category.get('externalRegistrationUrl');
  }

  // Resort to a relative Play registration URL
  // Components such as SimpleLink will augment the href if in the embed context for us
  return getTeamRegisterCategoryRoute(event.get('id'), category.get('id'), isEmbed);
}
