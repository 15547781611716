import * as Sentry from '@sentry/react';
import {ServicesContext} from 'fiba/common/services/services';
import {StoreService} from 'fiba/common/services/storeService';
import {getSeasonConfigs} from 'fiba/wt/stores/contentStore';
import {
  ISiteConfig,
  SiteConfigContext,
  ISeasonConfig,
} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Map} from 'immutable';
import {mapValues} from 'lodash';
import React from 'react';
import {hot} from 'react-hot-loader/root';
import {connect, Provider} from 'react-redux';
import {mergeSeasonConfigs} from 'fiba/wt/utils/mergeSeasonConfigs';
import {RootState} from 'fiba/wt/stores/rootStore';
import CommonServices from '../core/models/CommonServices';

interface OwnProps {
  store: StoreService<any>; // FIXME: Type of state
  services: CommonServices;
  siteConfig?: ISiteConfig;
}

interface ReduxProps {
  layout: React.FunctionComponent<any>;
  params: {[key: string]: any};
  components: {[key: string]: React.ReactNode};
  mergedSeasonConfig: ISeasonConfig;
}

const App: React.FunctionComponent<OwnProps & ReduxProps> = ({
  store,
  services,
  layout,
  params,
  components,
  siteConfig,
  mergedSeasonConfig,
}) => (
  // Catch any runaway top-level errors inside the React tree
  // The application will still crash and burn, but we'll get a report
  // We could do something nicer here, but we don't at the moment
  // Some apps, like WT/WS wrap specific routes with boundaries as well
  // so that only parts of the application crash
  <Sentry.ErrorBoundary
    beforeCapture={scope => {
      // A bit of minor context, about the boundary handling the error
      scope.setContext('Error Boundary', {component: 'App'});
    }}
  >
    <Provider store={store}>
      <ServicesContext.Provider value={services}>
        <SiteConfigContext.Provider value={{...siteConfig, seasonConfig: mergedSeasonConfig}}>
          {layout({...params, components})}
        </SiteConfigContext.Provider>
      </ServicesContext.Provider>
    </Provider>
  </Sentry.ErrorBoundary>
);

const mapStateToProps = (state: RootState, props: OwnProps) => {
  const route = state.get('route');
  const params = (route && route.get('payload').toJS()) || {};
  const layout = route && route.get('layout');
  const contentfulSeasonConfigs = getSeasonConfigs(state);
  // NOTE: We call the `component` function here directly and pass the rendered
  // component forwards so that react doesn't generate an anonymous component
  // in the middle that would cause the whole page to be re-rendered when there
  // are certain components that rely on the page not being re-rendered (such as `EventHero`).
  const _components = route && route.getIn(['components'], Map()).toJS();
  const components = mapValues(_components, component => component(params));
  const mergedSeasonConfig = props.siteConfig
    ? mergeSeasonConfigs(contentfulSeasonConfigs, props.siteConfig)
    : undefined;

  return {
    layout,
    params,
    components,
    mergedSeasonConfig,
  };
};

// TODO: Consider whether the hot goes inside connect, or outside...
// @see https://github.com/gaearon/react-hot-loader#important
export default module.hot ? hot(connect(mapStateToProps)(App)) : connect(mapStateToProps)(App);
