import React, {useContext} from 'react';
import {List} from 'immutable';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {getPoolNameLong} from 'fiba/common/core/results';
import {ServicesContext} from 'fiba/common/services/services';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Link} from 'fiba/wt/ui/link/Link';
import {PoolStandings} from 'fiba/wt/ui/poolStandings/PoolStandings';
import {eventTeamLinkProvider, ResourceLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';

interface IPoolsOverview {
  season: string;
  eventId: string;
  eventType: EventType;
  pools: List<ResultsGroup>;
  createPoolHref: ResourceLinkProvider;
}

export const PoolsOverview: React.FunctionComponent<IPoolsOverview> = ({
  season,
  eventId,
  pools,
  eventType,
  createPoolHref,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);
  return (
    <Box debugClassName="PoolsOverview" pb={['3', '3', '4']}>
      <Box>
        <Flex flexWrap="wrap" flexDirection={['column', 'column', 'row']} pt="3" mnh={['0', '3']}>
          {pools.map(pool => (
            <Box
              key={pool.groupId}
              pt="4"
              ph={['0', '3']}
              flexGrow="1"
              flexShrink="0"
              width={['100', '100', '6']}
            >
              {/* NOTE: it is important that the a heading element precedes the table, to act as a caption.
               * Ideally, it would be inside the StandingsTable caption prop, but that would include the
               * link to the screen reader output, giving "Pool A see full Pool A". That is undesirable,
               * so we pull this out instead.
               */}
              <Flex justifyContent="between" flexWrap="wrap">
                <SubHeading textAlign="left">
                  {getPoolNameLong(pool.roundCode, pool.groupPoolCode, pool.roundNumber, localizer)}
                </SubHeading>
                <Box fontSize="6">
                  <Link colorTheme="dark" href={createPoolHref(pool.groupId)}>
                    <span aria-hidden="true">See full</span>
                    {/* NOTE: assumes ltr order */}
                    <VisuallyHidden>
                      {`See full ${getPoolNameLong(
                        pool.roundCode,
                        pool.groupPoolCode,
                        pool.roundNumber,
                        localizer,
                      )} Standings`}
                    </VisuallyHidden>
                  </Link>
                </Box>
              </Flex>

              <PoolStandings
                standings={pool.groupStandings || List()}
                captionId={`pools-overview-${pool.groupPoolCode}`}
                columns={['rank', 'name', 'gamesPlayed', 'gamesWon', 'totalPoints']}
                createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
              />
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
