import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import tourActions from 'fiba/wt/stores/tourStore';
import tourResultActions from 'fiba/wt/stores/tourResultStore';

interface TourResultsFeatures {
  stage?: 'latest' | 'intermediate';
}

export interface TourControllerFeatures {
  events?: boolean;
  challengers?: boolean;
  superQuests?: boolean;
  results?: TourResultsFeatures;
  teams?: boolean;
  proCircuitTeams?: {season: string};
}

function tourController(tourId: string, features: TourControllerFeatures) {
  return ({dispatch, cache}: CommonServices) => {
    const featureLoaders = {
      events: () =>
        // FIXME: This path doesn't exist, but it does for metadata, @see services/cacheService.tsx for additional TODO
        cache(['tours', tourId, 'events'], () => dispatch(tourActions.loadEventsByTour(tourId))),

      challengers: () =>
        cache(['tours', tourId, 'challengers'], () =>
          dispatch(tourActions.loadChallengerEventsByTour(tourId)),
        ),

      superQuests: () =>
        cache(['tours', tourId, 'superQuests'], () =>
          dispatch(tourActions.loadSuperQuestEventsByTour(tourId)),
        ),

      results: ({stage}: TourResultsFeatures) =>
        cache(['tourResults', tourId, stage], () => {
          const eventIdsToInclude: any[] =
            cache(['tours', '__meta', tourId, 'events', 'refs']) || [];

          // This feature controller should be run in sequence with the tourController
          // otherwise we will not have events to include, and end up filtering them out
          // This is kind of silly. I am not sure why we need to filter by event ids to include; shouldn't they
          // come by tour already? Maybe it has to do with handling the final? Not sure...
          if (process.env.NODE_ENV !== 'production' && eventIdsToInclude.length === 0) {
            // eslint-disable-next-line no-console
            console.warn(
              'The results feature loader of tourController had [] as the event ids to include. You should run it in composeControllers after the tourController, to ensure that does not happen.',
            );
          }

          return dispatch(tourResultActions.loadTourResults(tourId, stage, eventIdsToInclude));
        }),

      teams: () =>
        cache(['tours', tourId, 'tourTeams'], () => dispatch(tourActions.loadTeamsByTour(tourId))),

      proCircuitTeams: () =>
        cache(['tours', features.proCircuitTeams.season, 'proCircuitTourTeams'], () =>
          dispatch(tourActions.loadProCircuitTeamsBySeason(features.proCircuitTeams.season)),
        ),
    };

    return cache(['tours', tourId], () => dispatch(tourActions.loadTour(tourId))).then(() =>
      loadFeatures(featureLoaders, features, cache(['tours', tourId])),
    );
  };
}

export default tourController;
