import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {NewsHighlight} from 'fiba/wt/ui/newsHighlight/NewsHighlight';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {GhostLink} from 'fiba/wt/ui/link/Link';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventNewsLink, EventType} from 'fiba/wt/utils/linkUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {getConferenceIdForEvent} from 'fiba/nationsleague/store';

interface OwnProps {
  season: string;
  eventId: string;
  siteConfig: ISiteConfig;
}

interface ReduxProps {
  newsData: RemoteData.WebData<List<NewsItem>>;
  eventTypeData: RemoteData.WebData<EventType>;
}

type Props = OwnProps & ReduxProps;

export const EVENT_NEWS_ITEM_COUNT = 10;

export const mapStateToProps = (state, {eventId, season}: OwnProps): ReduxProps => {
  const conferenceId = getConferenceIdForEvent(state, season, eventId);

  return {
    newsData: RemoteData.map(
      state.getIn(
        conferenceId
          ? ['conferences', '__meta', conferenceId, 'news', 'previews']
          : ['events', '__meta', eventId, 'news', 'previews'],
        // NOTE: We declare "NotAsked" as the undefined value of getIn
        RemoteData.NotAsked(),
      ) as RemoteData.WebData<List<string>>,
      // NOTE: News is currently not WebData-based
      // However, we know they have loaded because we checked the meta
      // TODO: In the future, change this to a `map` or `match` call
      newsItemIds =>
        newsItemIds
          .map(newsItemId => state.getIn(['news', 'previews', newsItemId]))
          .sort((item1, item2) => {
            if (item2.date === item1.date) {
              return 0;
            }
            return new Date(item2.date) > new Date(item1.date) ? 1 : -1;
          })
          .take(EVENT_NEWS_ITEM_COUNT)
          .toList(),
    ),
    eventTypeData: getEventType(state.get('events'), eventId),
  };
};

//
// Loaders

const _EventNewsSummary: React.FC<Props> = ({
  season,
  eventId,
  eventTypeData,
  newsData,
  siteConfig,
}: Props) => (
  <div className="EventNewsSummary">
    <Heading fontSize="3" pb="3">
      News
    </Heading>
    <ViewWebData data={newsData}>
      {news =>
        news.size > 0 && (
          <React.Fragment>
            <NewsHighlight
              news={news}
              // NOTE: We do a sleight-of-hand here, and default `eventType` to masters in not-success case
              // In practice, the data should already be loaded here, so this is fine.
              // And even if that assumption were wrong, it is not the end of the world!
              baseUrl={RemoteData.match(eventTypeData, {
                Success: eventType =>
                  eventNewsLink(siteConfig.seasonConfig, season, eventId, eventType),
                default: () => eventNewsLink(siteConfig.seasonConfig, season, eventId, 'WorldTour'),
              })}
              // NOTE: Extrinsic from ResponsiveImageLint
              // This is because they are important for loading, so we need the best estimates,
              // even if they are more brittle.
              largeItemSizes="(min-width: 1620px) 772px, (min-width: 1040px) calc(59.11vw - 174px), (min-width: 780px) calc(100vw - 320px), calc(100vw - 32px)"
              smallItemSizes="(min-width: 1620px) 428px, (min-width: 1040px) calc(29.46vw - 43px), (min-width: 780px) calc(50vw - 160px), calc(100vw - 32px)"
            />

            <div className="pv3 flex justify-center">
              {RemoteData.match(eventTypeData, {
                Success: eventType => (
                  <GhostLink
                    variant={siteConfig.theme.ghostlinkTheme}
                    extraClassName="pv3 w5"
                    href={eventNewsLink(siteConfig.seasonConfig, season, eventId, eventType)}
                  >
                    See all event news
                  </GhostLink>
                ),
                default: () => null,
              })}
            </div>
          </React.Fragment>
        )
      }
    </ViewWebData>
  </div>
);

export const EventNewsSummary = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventNewsSummary,
);
