import React from 'react';
import {PlaceholderStyleProps} from 'fiba/wt/ui/placeholder/Placeholder';
import {ImgixQuery, getImgixImageProps} from 'fiba/wt/ui/imgix/Imgix';
import {toAspectRatio} from 'fiba/wt/ui/imgix/ImgixImageWithPlaceholder';
import {LazyImageWithPlaceholder} from 'fiba/wt/ui/image/LazyImageWithPlaceholder';

//
// LAZY WITH PLACEHOLDER

export type ILazyImgixImage = ImgixQuery & ILazyImgixImageOwn;
interface ILazyImgixImageOwn {
  alt: string;
  className?: string;
  containerClassName?: string;
  containerStyle?: PlaceholderStyleProps;
  loadEagerly?: boolean;
}

// TODO: placeholder image, error fallback image
/** Pre-composed version of Imgix, and LazyImageWithPlaceholder */
export const LazyImgixImage: React.FC<ILazyImgixImage> = ({
  containerClassName,
  containerStyle,
  className,
  loadEagerly,
  alt,
  ...imgixQueryProps
}) => {
  const imageProps = getImgixImageProps(imgixQueryProps);

  return (
    <LazyImageWithPlaceholder
      aspectRatio={toAspectRatio(imgixQueryProps.aspectRatio)}
      alt={alt}
      className={className}
      containerClassName={containerClassName}
      containerStyle={containerStyle}
      loadEagerly={loadEagerly}
      {...imageProps}
    />
  );
};
