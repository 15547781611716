import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {EventOverviewHeading} from 'fiba/wt/ui/eventPage/EventOverviewHeading';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {GameCardWidget} from 'fiba/wt/ui/gameCardWidget/GameCardWidget';
import {EventNewsSummary} from 'fiba/wt/ui/eventNews/EventNewsSummary';
import {
  ContentfulEmbeddedMediaSummary,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {EventPageProps} from 'fiba/wt/ui/eventPage/EventPageCommon';
import {Box} from 'fiba/wt/ui/box/Box';
import {EventPhotoGallery} from 'fiba/wt/ui/eventPhotoGallery/EventPhotoGallery';

export const EventPageLive: React.FunctionComponent<EventPageProps> = ({
  eventId,
  season,
  siteConfig,
}) => (
  <Spacer debugClassName="EventPageLive">
    <EventOverviewHeading season={season} eventId={eventId} />

    <Section>
      {/* Override loading context behaviour so that we don't get flashing loading indicators
        when GameCardWidget refreshes the data or receives an error upon refreshing
    */}
      <GameCardWidget season={season} eventId={eventId} />
      <ContentfulEmbeddedMediaSummary
        title="Livestream"
        contentPath={`events/${eventId}`}
        mediaType={EmbeddedMediaTypes.YoutubeMainLivestream}
        widthHint={720} // TODO: discuss
        season={season}
        eventId={eventId}
        siteConfig={siteConfig}
      />
      <EventNewsSummary season={season} eventId={eventId} siteConfig={siteConfig} />
      <Box>
        <EventPhotoGallery heading="Photos" season={season} eventId={eventId} />
      </Box>
    </Section>
  </Spacer>
);
