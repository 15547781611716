import React from 'react';
import {List} from 'immutable';
import cx from 'classnames';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {ResultsTeam} from 'fiba/common/core/models/api/results/ResultsTeam';
import {EventActivity} from 'fiba/common/core/models/api/events/EventActivity';
import {ServicesContext} from 'fiba/common/services/services';
import {LocalizerService} from 'fiba/common/services/localizerService';
import {getTeamDisplayScore} from 'fiba/common/core/results';
import {groupGamesAndActivities, getDisplayTimeFromString} from 'fiba/wt/ui/schedule/scheduleUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import {StyledLink, Link} from 'fiba/wt/ui/link/Link';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {Table, Tr, Td, Th, TableLayoutStyleProps} from 'fiba/wt/ui/table/Table';
import {ArrowRightCircle} from '@fpapado/react-feather';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {P} from 'fiba/wt/ui/text/Text';
import {
  KORounds,
  RoundCode,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {Category} from 'fiba/common/core/models/api/events/Category';

export type ScheduleExtraColumnType = 'court' | 'category';

interface ScheduleGamesProps {
  games: List<ResultsGameSummary | EventActivity>;
  activities: List<EventActivity>;
  hideCourtName?: boolean;
  hideCategoryName?: boolean;
  extraColumns: ScheduleExtraColumnType[];
  isScheduleEventTime: boolean;
  showOnlyGames?: boolean;
  createTeamHref?: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
  hideExactGameTimesEnabled?: boolean;
  hideGamePairingsFrom?: RoundCode;
  hideGamePairingsUntil?: RoundCode;
  hideWomenGamePairingsFrom?: RoundCode;
  hideWomenGamePairingsUntil?: RoundCode;
  categories: List<Category>;
}

// Helpers
interface CellProps extends React.TdHTMLAttributes<HTMLTableDataCellElement> {
  bold?: boolean;
  extraClassName?: string;
}

const Cell: React.FC<CellProps & TableLayoutStyleProps> = ({bold, extraClassName, ...props}) => (
  <Td ph="2" pv="3" {...props} extraClassName={cx('v-mid', extraClassName, {fw7: bold})} />
);

interface ScheduleResultsGameRowProps {
  game: ResultsGameSummary;
  hideCourtName: boolean;
  hideCategoryName: boolean;
  extraColumns: ScheduleExtraColumnType[];
  isScheduleEventTime: boolean;
  createTeamHref: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
  localizer: LocalizerService;
  playInRoundCode: string;
  hideExactGameTime?: boolean;
}

interface HideExactGameTimesDisclaimerProps {
  games: List<ResultsGameSummary>;
  showCategory?: boolean;
  playInRoundCode: string;
}

const isWinnerStyle = (team: ResultsTeam) => ({
  fw7: team && team.teamIsWinner,
});

const HideExactGameTimesDisclaimer: React.FC<HideExactGameTimesDisclaimerProps> = ({
  games,
  showCategory,
  playInRoundCode,
}) => {
  return (
    <Box
      style={{width: 'fit-content', minWidth: '70%'}}
      pv="3"
      pb="3"
      borderRadius="2"
      mb="3"
      extraClassName="bg-silver-10"
    >
      <P mt="0" ml="3" fontWeight="9">
        The following games will be scheduled in due course
      </P>
      <Table extraClassName="HideDisclaimer" width="100" ph="2">
        <Tr>
          <Th ph="1"></Th>
          <Th ph="1" fontWeight="6" textAlign="center">
            Game
          </Th>
          <Th ph="1"></Th>
          <Th ph="1" fontWeight="6">
            Round
          </Th>
          {showCategory && (
            <Th ph="1" fontWeight="6">
              Category
            </Th>
          )}
        </Tr>
        {games.map((game: ResultsGameSummary) => (
          <Tr key={game.gameId}>
            <Cell textAlign="center" pv="0" ph="1">
              <TeamNameAssembly
                playInRoundCode={playInRoundCode}
                standing={game.homeTeam}
                isSuffixVisible
                isIocVisible={false}
              />
            </Cell>
            <Cell textAlign="center" pv="1" ph="0">
              <span className="ph1">-</span>
            </Cell>
            <Cell textAlign="center" pv="1" ph="1">
              <TeamNameAssembly
                playInRoundCode={playInRoundCode}
                standing={game.awayTeam}
                isSuffixVisible
                isIocVisible={false}
              />
            </Cell>
            <Cell textAlign="center" pv="1" ph="1">
              {game.gameIsPlayIn ? 'Play-In' : game.groupName}
            </Cell>
            {showCategory && (
              <Cell textAlign="center" pv="1" ph="1">
                {game.categoryName}
              </Cell>
            )}
          </Tr>
        ))}
      </Table>
    </Box>
  );
};

const ScheduleResultsGameRow: React.FC<ScheduleResultsGameRowProps> = ({
  game,
  playInRoundCode,
  hideCourtName,
  hideCategoryName,
  extraColumns,
  isScheduleEventTime,
  createTeamHref,
  createGameHref,
  localizer,
  hideExactGameTime,
}) => {
  const homeClass = cx('pb1', isWinnerStyle(game.homeTeam));
  const awayClass = cx('pt1', isWinnerStyle(game.awayTeam));
  const displayTime = getDisplayTimeFromString(
    game.gameStartDatetime,
    isScheduleEventTime,
    localizer,
  );

  return (
    <Tr extraClassName="ScheduleResultsGameRow bb bw1 b--silver-20 striped--light">
      <Th scope="row" ph="2" extraClassName="v-mid tl">
        {/* TODO: Show time in event or local timezone */}
        {isCompleted(game) ? (
          <StyledLink href={createGameHref(game.gameId)} extraClassName="fw7">
            {displayTime}
          </StyledLink>
        ) : (
          displayTime
        )}
      </Th>

      <Cell>
        <Box extraClassName={homeClass}>
          {hideExactGameTime ? (
            'To be determined'
          ) : game.homeTeam && game.homeTeam.teamId ? (
            <StyledLink href={createTeamHref(game.homeTeam.teamId)}>
              <TeamNameAssembly
                playInRoundCode={playInRoundCode}
                standing={game.homeTeam}
                isSuffixVisible
                isIocVisible={false}
              />
            </StyledLink>
          ) : (
            <TeamNameAssembly
              playInRoundCode={playInRoundCode}
              standing={game.homeTeam}
              isSuffixVisible
              isIocVisible={false}
            />
          )}
        </Box>

        <Box extraClassName={awayClass}>
          {hideExactGameTime ? (
            'To be determined'
          ) : game.awayTeam && game.awayTeam.teamId ? (
            <StyledLink href={createTeamHref(game.awayTeam.teamId)}>
              <TeamNameAssembly
                playInRoundCode={playInRoundCode}
                standing={game.awayTeam}
                isSuffixVisible
                isIocVisible={false}
              />
            </StyledLink>
          ) : (
            <TeamNameAssembly
              playInRoundCode={playInRoundCode}
              standing={game.awayTeam}
              isSuffixVisible
              isIocVisible={false}
            />
          )}
        </Box>
      </Cell>

      <Cell pr="3" textAlign="right">
        <Flex alignItems="center" justifyContent="end">
          {game.gameIsOvertime && <div className="mr3">OT</div>}
          <div>
            <div className={homeClass}>{getTeamDisplayScore(game.homeTeam, game, localizer)}</div>
            <div className={awayClass}>{getTeamDisplayScore(game.awayTeam, game, localizer)}</div>
          </div>
        </Flex>
      </Cell>

      {extraColumns.indexOf('court') >= 0 && !hideCourtName && (
        <Cell>{game.courtName || 'N/A'}</Cell>
      )}

      {extraColumns.indexOf('category') >= 0 && !game.gameIsPlayIn && (
        <Cell>
          {!hideCategoryName && !hideExactGameTime && <Box pb="1">{game.categoryName}</Box>}
          {/* FIXME: Now that roundNumber exists, formulate a function that will localize the full game name properly
              @see getPoolName*, getRoundName* and getGameName at fiba/common/core/results.tsx
          */}
          <Box pt="1">{game.groupName}</Box>
        </Cell>
      )}
      {extraColumns.indexOf('category') >= 0 && game.gameIsPlayIn && (
        <Cell>
          {!hideCategoryName && !hideExactGameTime && <Box pb="1">{game.categoryName}</Box>}
          <Box pt="1">Play-In</Box>
        </Cell>
      )}

      {/* Explicit link to game page */}
      <Cell>
        {isCompleted(game) ? (
          <Flex alignItems="center">
            <Link display="block" href={createGameHref(game.gameId)} extraClassName="dim">
              {/* NOTE: `mt2` is employed here as a visual tweak */}
              <ArrowRightCircle purpose="decorative" className="mt2 w2 ht2" />

              {/* Description for screen readers. We keep this the same as the time link,
               * for consistency ("same link text leads to the same place").
               */}
              <VisuallyHidden>{displayTime}</VisuallyHidden>
            </Link>
          </Flex>
        ) : (
          ''
        )}
      </Cell>
    </Tr>
  );
};

interface ActivityRowProps {
  activity: EventActivity;
  extraColumns: ScheduleExtraColumnType[];
  isScheduleEventTime: boolean;
  localizer: LocalizerService;
}

const ActivityRow: React.FC<ActivityRowProps> = ({
  activity,
  extraColumns,
  isScheduleEventTime,
  localizer,
}) => (
  <Tr extraClassName="ActivityRow bb bw1 b--silver-20 striped--light dark-20">
    <Th scope="row" pv="2" ph="2" extraClassName="v-mid tl">
      {getDisplayTimeFromString(activity.activityStartDatetime, isScheduleEventTime, localizer)}
    </Th>

    <Cell pv="2" ph="2" colSpan={extraColumns.length + 2}>
      {activity.activityName}
    </Cell>
  </Tr>
);

const isCompleted = (game: ResultsGameSummary) =>
  game.homeTeam && typeof game.homeTeam.teamIsWinner !== 'undefined';
const isResultsGame = (item: ResultsGameSummary | EventActivity): item is ResultsGameSummary =>
  !!(item as ResultsGameSummary).gameId;
const isActivity = (item: ResultsGameSummary | EventActivity): item is EventActivity =>
  !!(item as EventActivity).activityId;

const dayContainsHiddenRoundKOGames = (
  items: List<ResultsGameSummary | EventActivity>,
  firstRoundKOGames: List<ResultsGameSummary>,
) =>
  items
    .filter(isResultsGame)
    .some((game: ResultsGameSummary) =>
      firstRoundKOGames.some(firstRoundKOGame => game.gameId === firstRoundKOGame.gameId),
    );

export const getHiddenRoundCodes = (from?: RoundCode, until?: RoundCode) => {
  // We default to first round if only "hideGamePairingsUntil" is set
  const hideGamesFrom = from || 'L32';

  // We default to QF if only "hideGamePairingsFrom" is set
  // If hideGamesFrom is set to SF we default to SF
  const hideGamesUntil = until || (hideGamesFrom === 'SF' ? 'SF' : 'L8');

  return KORounds.slice(
    KORounds.indexOf(hideGamesFrom),
    // We want the "hide until" to be inclusive
    KORounds.indexOf(hideGamesUntil) + 1,
  );
};

export const ScheduleGames: React.FC<ScheduleGamesProps> = ({
  games,
  activities,
  hideCourtName,
  hideCategoryName,
  extraColumns,
  isScheduleEventTime,
  showOnlyGames,
  createTeamHref,
  createGameHref,
  hideExactGameTimesEnabled,
  hideGamePairingsFrom,
  hideGamePairingsUntil,
  categories,
  hideWomenGamePairingsFrom,
  hideWomenGamePairingsUntil,
}) => {
  const womenCategoryIds = categories
    .filter(category => category.gender === 'Female')
    .map(category => category.id);

  const playInRoundCode = (games
    .filter(game => isResultsGame(game) && game.gameIsPlayIn)
    .first() as ResultsGameSummary)?.roundCode;

  const isMultiCategory = categories.size > 1;

  // This will also cover categories that don't have gender set or mixed gender
  const hiddenKORoundsMen = getHiddenRoundCodes(hideGamePairingsFrom, hideGamePairingsUntil);

  const hiddenKORoundsWomen = getHiddenRoundCodes(
    hideWomenGamePairingsFrom,
    hideWomenGamePairingsUntil,
  );

  return (
    <ServicesContext.Consumer>
      {({localizer}) => (
        <div className="ScheduleGames overflow-x-auto">
          {games.size > 0 ? (
            <Table width="100">
              {/* TODO: Apply local/event time to dates as well */}
              {groupGamesAndActivities(games, activities, isScheduleEventTime, localizer)
                .map((items, dateString) => {
                  const HiddenKORoundGames: List<ResultsGameSummary> = items
                    .filter(isResultsGame)
                    .filter((item: ResultsGameSummary) =>
                      (womenCategoryIds.includes(item.categoryId)
                        ? hiddenKORoundsWomen
                        : hiddenKORoundsMen
                      ).includes(item.roundCode as RoundCode),
                    )
                    .toList();
                  return (
                    <tbody key={dateString}>
                      <Tr>
                        <Th
                          fontWeight="6"
                          fontSize="4"
                          pb="3"
                          ph="2"
                          pt="4"
                          colSpan={extraColumns.length + 2}
                          extraClassName="tl"
                        >
                          {dateString}
                        </Th>
                      </Tr>
                      {dayContainsHiddenRoundKOGames(items, HiddenKORoundGames) &&
                        hideExactGameTimesEnabled && (
                          <Tr>
                            <Td colSpan={extraColumns.length + 4}>
                              <HideExactGameTimesDisclaimer
                                games={HiddenKORoundGames}
                                showCategory={isMultiCategory}
                                playInRoundCode={playInRoundCode}
                              />
                            </Td>
                          </Tr>
                        )}
                      <Tr border="bottom" borderWidth="1" borderColor="silver-20">
                        <Th pv="1" ph="2" textAlign="left">
                          TIME
                        </Th>
                        <Th pv="1" ph="2" textAlign="left">
                          TEAM
                        </Th>
                        <Th pv="1" ph="2" textAlign="right">
                          SCORE
                        </Th>
                        {extraColumns.indexOf('category') >= 0 && (
                          <Th pv="1" ph="2" textAlign="left">
                            ROUND
                          </Th>
                        )}
                        <Th pv="1" ph="2" textAlign="left">
                          LINK
                        </Th>
                      </Tr>
                      {items.map(item => {
                        if (isResultsGame(item)) {
                          return (
                            <ScheduleResultsGameRow
                              key={item.gameId}
                              game={item}
                              hideCourtName={hideCourtName}
                              hideCategoryName={hideCategoryName}
                              extraColumns={extraColumns}
                              isScheduleEventTime={isScheduleEventTime}
                              createTeamHref={createTeamHref}
                              createGameHref={createGameHref}
                              localizer={localizer}
                              playInRoundCode={playInRoundCode}
                              hideExactGameTime={
                                hideExactGameTimesEnabled &&
                                HiddenKORoundGames.some(game => game.gameId === item.gameId)
                              }
                            />
                          );
                        } else if (isActivity(item) && !showOnlyGames) {
                          return (
                            <ActivityRow
                              key={item.activityId}
                              activity={item}
                              extraColumns={extraColumns}
                              isScheduleEventTime={isScheduleEventTime}
                              localizer={localizer}
                            />
                          );
                        }
                      })}
                    </tbody>
                  );
                })
                .toList()
                .flatten()}
            </Table>
          ) : (
            <p>No games found</p>
          )}
        </div>
      )}
    </ServicesContext.Consumer>
  );
};
