import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import CommonServices from 'fiba/wt/core/models/CommonServices';
import TourTeamResults, {
  TourTeamResultsLike,
} from 'fiba/common/core/models/api/results/TourTeamResults';

export interface TourTeamResultsStoreState extends Map<string, TourTeamResults> {
  // TODO: `__meta`
}

const metaPath = teamId => ['__meta', teamId];
export const globalMetaPath = teamId => ['tourTeamResults', '__meta', teamId];
export const globalDataPath = teamId => ['tourTeamResults', teamId];

const reducers = {
  loadTourTeamResults: (
    state: TourTeamResultsStoreState,
    tourId: string,
    tourTeamId: string,
  ): TourTeamResultsStoreState => {
    return state.updateIn(metaPath(tourTeamId), setMeta.isLoading);
  },

  loadTourTeamResultsSuccess: (
    state: TourTeamResultsStoreState,
    tourTeam: TourTeamResultsLike,
  ): TourTeamResultsStoreState => {
    return state
      .set(tourTeam.teamId, TourTeamResults.fromJS(tourTeam))
      .updateIn(metaPath(tourTeam.teamId), setMeta.isLoaded);
  },

  loadTourTeamResultsError: (
    state: TourTeamResultsStoreState,
    tourTeamId: string,
    error: Error,
  ): TourTeamResultsStoreState => state.updateIn(metaPath(tourTeamId), setMeta.isError),
};

export const reducer = createReducer<TourTeamResultsStoreState>(__filename, fromJS({}), reducers);

const actions = createActions(__filename, reducers, {
  loadTourTeamResults: (tourId: string, tourTeamId: string) => ({apiClient}: CommonServices) => {
    return apiClient
      .loadTourTeamResults(tourId, tourTeamId)
      .then(res => actions.loadTourTeamResultsSuccess(res.data))
      .catch((err: Error) => actions.loadTourTeamResultsError(tourTeamId, err));
  },

  loadTourTeamResultsError: (tourTeamId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<TourTeamResultsStoreState>(TourTeamResults);
