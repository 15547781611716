import React from 'react';
import {List, Map} from 'immutable';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {connect} from 'fiba/common/utils/reactUtils';
import {areStandingsFinal} from 'fiba/wt/ui/standingsTable/standingsTableHelpers';
import {Box} from 'fiba/wt/ui/box/Box';
import {TeamList} from 'fiba/wt/ui/teamList/TeamList';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {getTeam} from 'fiba/wt/stores/teamStore';
import {EventType} from 'fiba/wt/utils/linkUtils';
import {NoticeText} from 'fiba/wt/ui/NoticeText/NoticeText';

interface OwnProps {
  season: string;
  eventId: string;
  categoryIdFilter?: List<string>;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    filteredTeams: List<Team>;
    eventType: EventType;
    standings: Map<string, ResultsStanding>;
    isFinalStandings: boolean;
  }>;
}

export const mapStateToProps = (state, {eventId, categoryIdFilter}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);

  // We get the standings by getting all categories,
  // querying the standings for each of them, and then
  // filtering for those that are the WT teams
  const standingsData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds
        .filter(categoryId => !categoryIdFilter || categoryIdFilter.includes(categoryId))
        .flatMap(
          categoryId =>
            // NOTE: We get from the state directly because we assume/know they are loaded
            // if the refs in __meta are loaded. This could be wrong! We should change this once
            // resultsStore is WebData- based as well
            state.getIn(['results', categoryId, 'standings'], List()) as List<ResultsStanding>,
        )
        // Only keep the standings that exist and have a teamId.
        // In practice, this filters for WT/Challenger events, compared to
        // other categories. Legacy seasons, for example, have more than one category.
        .filter(standing => !!standing || !!standing.teamId)
        // Construct a Map of teamId to Standing
        .reduce((standingsMap, standing) => {
          return standingsMap.set(standing.teamId, standing);
        }, Map<string, ResultsStanding>()),
  );

  // Teams filtered by categoryId(s)
  const unsortedTeamsData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'teams'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    teamIds =>
      teamIds
        // NOTE: We get from the state directly because we assume/know they are loaded
        // if the refs in __meta are loaded. This could be wrong! We should change this once
        // teamStore is WebData- based as well
        .map(teamId => getTeam(state, teamId))
        .filter(team => !!team)
        // Make sure to only include teams that are part of the filtered categories
        .filter(team => !categoryIdFilter || categoryIdFilter.includes(team.categoryId))
        .toList(),
  );

  // When we have fetched the standings data, check if the stadnings are final.
  const isFinalStandingsData = RemoteData.map(standingsData, standings => {
    return areStandingsFinal(standings.toList());
  });

  // To get the teams data, we have to merge the standings and unsorted teams data on success
  const filteredTeamsData = RemoteData.map2(
    standingsData,
    unsortedTeamsData,
    (standings, unsortedTeams) => {
      return (
        unsortedTeams
          // HACK: Remove teams without a standing; this can happen in legacy seasons,
          // where we have teams from multiple categories.
          // This only works if the standings are final btw.
          // If the standings are not final, there might be intermediate ones,
          // for example in running WT seasons, before playing games. In that case,
          // we do not filter, because it would hide every team!
          .filter(team => standings.get(team.id) !== undefined)
          // apply selected sort: fetch team from standings
          .toList()
      );
    },
  );

  // Finally, to get the data, we merge teamsData and eventTypeData,
  // so that they fail or succeed as one
  const data = RemoteData.map4(
    eventTypeData,
    standingsData,
    filteredTeamsData,
    isFinalStandingsData,
    (eventType, standings, filteredTeams, isFinalStandings) => {
      return {eventType, standings, filteredTeams, isFinalStandings};
    },
  );

  return {
    data,
  };
};

type Props = OwnProps & ReduxProps;

export const _EventTeams: React.FC<Props> = ({season, eventId, data}) => (
  <ViewWebData data={data}>
    {({eventType, filteredTeams, standings, isFinalStandings}) => {
      return (
        <Box debugClassName="EventTeams">
          {filteredTeams.size === 0 ? (
            <NoticeText mt="4">
              <p>Teams will be announced later.</p>
            </NoticeText>
          ) : (
            <TeamList
              season={season}
              eventId={eventId}
              eventType={eventType}
              teams={filteredTeams}
              standings={standings}
              isFinalStandings={isFinalStandings}
            />
          )}
        </Box>
      );
    }}
  </ViewWebData>
);

export const EventTeams = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventTeams);
