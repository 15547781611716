export default {
  eventRegistrationEnabled: true,
  eventSocialMediaSharingEnabled: false,
  playerSocialMediaSharingEnabled: false,
  playerFollowEnabled: true,
  playerProfileEditingEnabled: true,
  tourSocialMediaSharingEnabled: false,
  wtInlineCriticalCss: process.env.FIBA_HOT ? false : true,
  wtLoadFontsAsync: true,
};
