import React from 'react';
import {EventSeedings} from 'fiba/wt/ui/eventSeedings/EventSeedings';
import {List} from 'immutable';

interface Props {
  season: string;
  eventId: string;
  categoryIdFilter?: List<string>;
}

export const EventStandingsPreviewTab: React.FC<Props> = ({season, eventId, categoryIdFilter}) => (
  <div className="EventStandingsPreviewTab">
    <EventSeedings categoryIdFilter={categoryIdFilter} season={season} eventId={eventId} />
  </div>
);
