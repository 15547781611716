import React from 'react';
import {ThemeContextConsumer} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Box} from 'fiba/wt/ui/box/Box';
import {TourNewsCondensed} from 'fiba/wt/ui/tourNews/TourNews';

interface ITourNewsSidebar {
  tourId: string;
  season: string;
}

export const TourNewsSidebar: React.FunctionComponent<ITourNewsSidebar> = ({tourId, season}) => (
  <ThemeContextConsumer>
    {theme => (
      <Box
        width="100"
        pv={['2', '2', '3']}
        ph={['0', '3', '0']}
        color={theme.color}
        bgColor={theme.bgColor}
        debugClassName="TourNewsSidebar"
      >
        <TourNewsCondensed tourId={tourId} season={season} />
      </Box>
    )}
  </ThemeContextConsumer>
);
