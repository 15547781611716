import {fromJS, Map} from 'immutable';
import {createActions, createReducer, setMeta, rethrow, bind} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import Team from 'fiba/common/core/models/api/teams/Team';
import CustomFormTeamAnswers from 'fiba/common/core/models/api/customForms/CustomFormTeamAnswers';
import CustomFormPlayerAnswers from 'fiba/common/core/models/api/customForms/CustomFormPlayerAnswers';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {RootState} from 'fiba/play/stores/rootStore';
import {getForm} from 'fiba/common/stores/customFormStore';
import {CustomFormType, mapFieldAnswersToCustomFormQuestions} from 'fiba/common/core/customForms';
import {validateFormPromise} from 'fiba/common/stores/formStore';
import {customFormsValidator} from 'fiba/common/core/validators';
import {getTeam} from 'fiba/common/stores/teamStore';
import {isTeamLead, isTeamLeadByToken} from 'fiba/common/core/team';
import {getUserId, getTokenPlayerId} from 'fiba/common/core/user';
import CustomForm from 'fiba/common/core/models/api/customForms/CustomForm';
import {isBeforeCategoryStartDay} from 'fiba/common/core/category';
import {getCategory} from 'fiba/common/stores/categoryStore';

export const storePath = 'customFormAnswers';

export interface CustomFormAnswerState extends Map<string, any> {
  // __meta: {
  //   [key: string]: {categories: {[categoryId]: {[teamOrPlayerId]: {'isLoading' | 'isLoaded' | 'isError'}}}};
  // };
  // events: {
  //   [key: string]: {
  //     categories: {
  //       [key: string]: {
  //         <Map<string, <CustomFormTeamAnswers | CustomFormPlayerAnswers>>
  //       }
  //     }
  //   }
  // };
}

const actions = createActions(__filename, {
  loadCustomFormAnswers: (eventId: string, categoryId: string, teamOrPlayerId: string) => [
    {eventId, categoryId, teamOrPlayerId},
  ],
  loadCustomFormTeamAnswers: (team: Team) => ({apiClient}: CommonServices) => [
    actions.loadCustomFormAnswers(team.eventId, team.categoryId, team.id),
    apiClient
      .loadCustomFormTeamAnswers(team.eventId, team.categoryId, team.id)
      .then((res: Map<any, any>) => CustomFormTeamAnswers.fromJS(res.toJS())) // FIXME: is there a cleaner way?
      .then(teamAnswers => actions.loadCustomFormAnswersSuccess(teamAnswers))
      .catch(
        rethrow(err =>
          actions.loadCustomFormAnswersError(team.eventId, team.categoryId, team.id, err),
        ),
      ),
  ],
  loadCustomFormPlayerAnswers: (team: Team, playerId: string) => ({apiClient}: CommonServices) => [
    actions.loadCustomFormAnswers(team.eventId, team.categoryId, playerId),
    apiClient
      .loadCustomFormPlayerAnswers(team.eventId, team.categoryId, team.id, playerId)
      .then((res: Map<any, any>) => CustomFormPlayerAnswers.fromJS(res.toJS())) // FIXME: is there a cleaner way?
      .then(playerAnswers => actions.loadCustomFormAnswersSuccess(playerAnswers))
      .catch(
        rethrow(err =>
          actions.loadCustomFormAnswersError(team.eventId, team.categoryId, playerId, err),
        ),
      ),
  ],
  loadCustomFormAnswersSuccess: (answers: CustomFormPlayerAnswers | CustomFormTeamAnswers) => [
    {answers},
  ],

  loadCustomFormAnswersError: (
    eventId: string,
    categoryId: string,
    teamOrPlayerId: string,
    error,
  ) => [{eventId, categoryId, teamOrPlayerId}, notificationActions.addNotificationFromError(error)],

  submitCustomFormTeamAnswers: (eventId, categoryId, teamId, formFields) => ({
    store,
    dispatch,
    apiClient,
  }: CommonServices) => [
    {eventId, categoryId, teamId, formFields},
    () => {
      const teamForm = getForm(store.getState(), eventId, categoryId, CustomFormType.Team);
      const team = getTeam(store.getState(), teamId);
      if (!isBeforeCategoryStartDay(getCategory(store.getState(), categoryId))) {
        return bind(
          dispatch(
            notificationActions.addNotificationFromError(
              Error('Could not submit answers. Registration is closed.'),
            ),
          ),
        );
      }
      return validateFormPromise(customFormsValidator, formFields, teamForm)
        .then(customFormFields =>
          apiClient.submitCustomFormTeamAnswers(
            eventId,
            categoryId,
            teamId,
            teamForm,
            customFormFields,
          ),
        )
        .then(() => bind(dispatch(actions.submitCustomFormSuccess)))
        .then(() => bind(dispatch(actions.loadCustomFormTeamAnswers(team))))
        .catch(rethrow(actions.submitCustomFormError));
    },
  ],

  submitCustomFormPlayerAnswers: (eventId, categoryId, teamId, playerId, formFields) => ({
    store,
    dispatch,
    apiClient,
  }: CommonServices) => [
    {eventId, categoryId, teamId, playerId, formFields},
    () => {
      const playerForm = getForm(store.getState(), eventId, categoryId, CustomFormType.Player);
      const team = getTeam(store.getState(), teamId);
      if (!isBeforeCategoryStartDay(getCategory(store.getState(), categoryId))) {
        return bind(
          dispatch(
            notificationActions.addNotificationFromError(
              Error('Could not submit answers. Registration is closed.'),
            ),
          ),
        );
      }
      return validateFormPromise(customFormsValidator, formFields, playerForm)
        .then(customFormFields =>
          apiClient.submitCustomFormPlayerAnswers(
            eventId,
            categoryId,
            teamId,
            playerId,
            playerForm,
            customFormFields,
          ),
        )
        .then(() => bind(dispatch(actions.submitCustomFormSuccess)))
        .then(() => bind(dispatch(actions.loadCustomFormPlayerAnswers(team, playerId))))
        .catch(rethrow(actions.submitCustomFormError));
    },
  ],

  submitCustomFormSuccess: () => [
    undefined,
    notificationActions.addNotification('success', 'Answers submitted successfully!'),
  ],

  submitCustomFormError: error => [error, notificationActions.addNotificationFromError(error)],

  // Called on logout
  clearCustomFormAnswers: () => [undefined],
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    loadCustomFormAnswers: (
      state: CustomFormAnswerState,
      {
        eventId,
        categoryId,
        teamOrPlayerId,
      }: {eventId: string; categoryId: string; teamOrPlayerId: string},
    ): CustomFormAnswerState =>
      state.updateIn(
        ['__meta', eventId, 'categories', categoryId, teamOrPlayerId],
        setMeta.isLoading,
      ),

    loadCustomFormAnswersSuccess: (
      state: CustomFormAnswerState,
      {answers}: {answers: CustomFormPlayerAnswers | CustomFormTeamAnswers},
    ): CustomFormAnswerState => {
      if (isCustomFormPlayerAnswers(answers)) {
        return state
          .updateIn(
            ['__meta', answers.eventId, 'categories', answers.categoryId, answers.playerId],
            setMeta.isLoaded,
          )
          .setIn(
            ['events', answers.eventId, 'categories', answers.categoryId, answers.playerId],
            answers,
          );
      } else {
        return state
          .updateIn(
            ['__meta', answers.eventId, 'categories', answers.categoryId, answers.teamId],
            setMeta.isLoaded,
          )
          .setIn(
            ['events', answers.eventId, 'categories', answers.categoryId, answers.teamId],
            answers,
          );
      }
    },

    loadCustomFormAnswersError: (
      state: CustomFormAnswerState,
      {
        eventId,
        categoryId,
        teamOrPlayerId,
      }: {eventId: string; categoryId: string; teamOrPlayerId: string},
    ): CustomFormAnswerState =>
      state.updateIn(
        ['__meta', eventId, 'categories', categoryId, teamOrPlayerId],
        setMeta.isError,
      ),

    clearCustomFormAnswers: (state: CustomFormAnswerState) => state.clear(),
  },
  fromJS({}),
);

// Deserialize state
export function deserializeState({__meta = {}, events = {}} = {}) {
  const convertedAnswers = Object.keys(events).reduce((answers, eventId) => {
    answers[eventId] = {categories: {}};

    Object.keys(events[eventId]['categories']).map(categoryId => {
      answers[eventId]['categories'][categoryId] = Object.keys(
        events[eventId]['categories'][categoryId],
      ).reduce((categoryAnswers, teamOrPlayerId) => {
        const currentAnswer = events[eventId].categories[categoryId][teamOrPlayerId];
        categoryAnswers[teamOrPlayerId] = isCustomFormPlayerAnswers(currentAnswer)
          ? CustomFormPlayerAnswers.fromJS(events[eventId].categories[categoryId][teamOrPlayerId])
          : CustomFormTeamAnswers.fromJS(events[eventId].categories[categoryId][teamOrPlayerId]);

        return categoryAnswers;
      }, {});
    });

    return answers;
  }, {});

  return fromJS({
    __meta,
    events: convertedAnswers,
  });
}

// Selectors
export const getFormAnswers = (
  state: RootState,
  eventId: string,
  categoryId: string,
  teamOrPlayerId: string,
): CustomFormTeamAnswers | CustomFormPlayerAnswers | undefined => {
  return teamOrPlayerId
    ? state.getIn([storePath, 'events', eventId, 'categories', categoryId, teamOrPlayerId])
    : undefined;
};

export const getFormAnswersLoading = (
  state: RootState,
  eventId: string,
  categoryId: string,
  teamOrPlayerId: string,
): boolean =>
  state.getIn([
    storePath,
    '__meta',
    eventId,
    'categories',
    categoryId,
    teamOrPlayerId,
    'isLoading',
  ]);

// Utils
const isCustomFormPlayerAnswers = (
  answers: CustomFormPlayerAnswers | CustomFormTeamAnswers,
): answers is CustomFormPlayerAnswers => CustomFormType.Player === answers.type;

export const hasPlayerAnswerAccess = (state: any, teamId: string, playerId: string) => {
  const user = state.get('user');
  const team = getTeam(state, teamId);
  return playerId
    ? isTeamLead(user, team) ||
        isTeamLeadByToken(user, team) ||
        getUserId(user) === playerId ||
        getTokenPlayerId(user) === playerId
    : false;
};

export const mapToCustomFormApiData = ({
  eventId,
  categoryId,
  customFormType,
  customForm,
  fields,
  playerId,
  teamId,
}: {
  eventId: string;
  categoryId: string;
  playerId?: string;
  teamId?: string;
  customFormType: CustomFormType;
  customForm: CustomForm;
  fields: Map<string, any>;
}) => {
  return {
    eventId,
    categoryId,
    playerId,
    teamId,
    type: customFormType,
    answers: mapFieldAnswersToCustomFormQuestions(fields, customForm),
  };
};
