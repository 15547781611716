import CommonServices from 'fiba/common/core/models/CommonServices';
import qualificationActions from 'fiba/wt/stores/qualificationsStore';

interface Props {
  tourId: string;
  eventId: string;
}

function eventQualificationsController({tourId, eventId}: Props) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['qualifications', 'eventQualifications', eventId], () =>
      dispatch(qualificationActions.loadEventQualifications(tourId, eventId)),
    );
  };
}

export {eventQualificationsController};
