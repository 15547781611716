import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {ProCircuitPlayerStatistics} from 'fiba/wt/ui/statistics/ProCircuitPlayerStatistics';

export interface Props {
  tourId: string;
  season: string;
  eventId: string;
}

export const ProCircuitTourStatisticsPlayersTab: React.FunctionComponent<Props> = props => {
  const {tourId, season, eventId} = props;

  return (
    <Spacer vSpace="4">
      <ProCircuitPlayerStatistics tourId={tourId} season={season} eventId={eventId} />
    </Spacer>
  );
};
