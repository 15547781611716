import React from 'react';
import cx from 'classnames';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {ChevronRight, ChevronLeft} from '@fpapado/react-feather';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Box} from 'fiba/wt/ui/box/Box';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {useInView} from 'react-intersection-observer';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {useHorizontalScrollRegion} from 'fiba/wt/ui/hooks/useHorizontalScrollRegion';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import Event from 'fiba/common/core/models/api/events/Event';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';

// Props from caller
interface OwnProps {
  labelId: string;
  season: string;
  eventId: string;
}

// Derived props from Redux
interface ReduxProps {
  eventNameData: RemoteData.WebData<string>;
  standingsData: RemoteData.WebData<List<ResultsStanding>>;
}

type Props = OwnProps & ReduxProps;

// Connect to redux
const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  // Get the event
  const eventNameData = RemoteData.map(
    state.getIn(['events', eventId], RemoteData.NotAsked()) as RemoteData.WebData<Event>,
    event => event.shortName || event.name,
  );

  const standingsData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    // NOTE: In reality, we should be checking that ['results', categoryId, 'standings'] is also Loaded
    // However, we don't have it as WebData yet!
    // We have them as implicit List() and also in the refs above, so maybe this is fine
    // TODO: We'll have to change this once category store is WebData based
    categoryIds =>
      categoryIds
        .flatMap(
          categoryId =>
            state.getIn(['results', categoryId, 'standings'], List()) as List<ResultsStanding>,
        )
        .toList(),
  );

  return {
    eventNameData,
    standingsData,
  };
};

export const PortalStandingsHighlight: React.SFC<{id?: string}> = ({id, children}) => (
  // NOTE: Flex is here so that Box will have max-content width on wider screens. We could use w-max-c as well.
  <Flex>
    <Box bgColor="steel-40" borderRadius="1" width={['100', 'auto']}>
      <Heading
        lineHeight="copy"
        id={id}
        pv="1"
        ph="3"
        color="fullwhite"
        fontWeight="6"
        fontSize="7"
        textTransform="uppercase"
      >
        {children}
      </Heading>
    </Box>
  </Flex>
);

const _EventFinalResults: React.SFC<Props> = ({labelId, standingsData, eventNameData}) => {
  const [firstRef, firstInView] = useInView();
  const [lastRef, lastInView] = useInView();
  const {containerProps} = useHorizontalScrollRegion({
    'aria-labelledby': labelId,
  });
  return (
    <Spacer vSpace="3">
      <PortalStandingsHighlight id={labelId}>
        {'Standings' +
          RemoteData.match(eventNameData, {
            Success: eventName => `: ${eventName}`,
            default: () => '',
          })}
      </PortalStandingsHighlight>

      <ViewWebData data={standingsData}>
        {standings =>
          standings.size > 0 && (
            <CardContainer pa="3" position="relative" extraClassName="EventFinalResults">
              <div className="EventFinalResults-FadeLeft ph2 flex items-center justify-start dark-40 w2 absolute top-0 left-0 ht-100">
                {!firstInView ? <ChevronLeft className="w1" purpose="decorative" /> : null}
              </div>

              <ol
                {...containerProps}
                className={cx(
                  'EventFinalResults-StandingsList f6 overflow-x-auto scroll-touch',
                  containerProps.className,
                )}
              >
                {standings.map((standing, index) => (
                  <li
                    key={standing.get('teamId')}
                    className="pv1 fw6 pl3 ws-nowrap"
                    ref={index === 0 ? firstRef : index === standings.size - 1 ? lastRef : null}
                  >
                    <span className="dib miw1 mr1">
                      {standing.teamRank ? `${standing.teamRank}.` : '–'}
                    </span>
                    <TeamNameAssembly standing={standing} isFlagVisible isSuffixVisible />
                  </li>
                ))}
              </ol>

              <div className="EventFinalResults-FadeRight ph2 flex items-center justify-end dark-40 w2 absolute top-0 right-0 ht-100">
                {!lastInView ? <ChevronRight className="w1" purpose="decorative" /> : null}
              </div>
            </CardContainer>
          )
        }
      </ViewWebData>
    </Spacer>
  );
};

export const EventFinalResults = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventFinalResults,
);
