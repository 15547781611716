import React, {useContext} from 'react';
import cx from 'classnames';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {PublishedDate} from 'fiba/wt/ui/publishedDate/PublishedDate';
import {ImgixImageWithPlaceholder} from 'fiba/wt/ui/imgix/ImgixImageWithPlaceholder';
import {Link} from 'fiba/wt/ui/link/Link';

interface Props {
  newsItem: NewsItem;
  baseUrl: string;
  className?: string;
  largeItemSizes: string;
  smallItemSizes: string;
  isLargeItem?: boolean;
}

export const NewsHighlightItem: React.FC<Props> = ({
  newsItem,
  baseUrl,
  className,
  largeItemSizes,
  smallItemSizes,
  isLargeItem,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Link
      href={`${baseUrl}/${newsItem.slug}`}
      colorTheme={theme.linkTheme}
      extraClassName={cx('db card-top-level bg-fullwhite shadow-1', className)}
    >
      <Flex flexDirection="column" width="100" height="100">
        <ImgixImageWithPlaceholder
          containerClassName="flex-grow-1"
          containerStyle={{color: theme.bgColor as any}}
          baseUrl={newsItem.imageBaseUrl}
          aspectRatio={[6, 4]}
          sizes={
            // NOTE: These sizes are calculated extrinsically, using RespImgLint
            // This is because they are important for loading, so we need the best estimates,
            // even if they are more brittle.
            isLargeItem ? largeItemSizes : smallItemSizes
          }
          alt=""
        />

        <Flex
          bgColor="fullwhite"
          pa="3"
          flexGrow={isLargeItem ? '0' : '1'}
          flexDirection="column"
          justifyContent="between"
          debugClassName="NewsHighlightItem-TextBox"
        >
          <Heading fontSize={isLargeItem ? ['5', '3', '2', '2'] : '5'}>{newsItem.title}</Heading>
          <BlockText mt="3" fontSize="7" color="dark-50" extraClassName="tdn">
            <PublishedDate date={newsItem.date} />
          </BlockText>
        </Flex>
      </Flex>
    </Link>
  );
};
