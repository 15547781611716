import * as Sentry from '@sentry/react';

type AppName = 'play' | 'embed' | 'wt' | 'ws' | 'cups' | 'nationsleague';

/**
 * Initialise a sentry client for the lifetime of the application.
 * Handles common concerns for every app, such as environment variables,
 * filtering, disabling in development, and adding the client app's name.
 */
export function initialiseSentry(appName: AppName) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    // Only send events in production builds (uat, qa, live, on-demand for development)
    enabled: process.env.SENTRY_ENABLED === 'true',
    // Annotate with a tag of the name of the app (play, embed, wt, ws)
    // tags are searchable and static for the lifetime of the program
    beforeSend: event => {
      // event.tags is optional, so let's check that it's defined
      if (event.tags) {
        event.tags['fiba-app'] = appName;
      } else {
        event.tags = {'fiba-app': appName};
      }
      return event;
    },
    environment: process.env.SENTRY_ENVIRONMENT || 'development',
    // Limit to 30 breadcrumbs. Might increase or decrease as needed.
    maxBreadcrumbs: 30,
  });
}
