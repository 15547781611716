import React, {useContext, useMemo} from 'react';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Section, Heading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {Cluster} from 'fiba/wt/ui/cluster/Cluster';
import moment from 'moment';
import {ServicesContext} from 'fiba/common/services/services';
import {TypeScale} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {formatGameName} from 'fiba/wt/utils/games/games';

interface OwnProps {
  season: string;
  eventId: string;
  gameId: string;
}

interface ReduxProps {
  metaPaths: string[];
  gameDetails: ResultsGameDetails;
}

type Props = ReduxProps & OwnProps;

const mapStateToProps = (state, {gameId, eventId}: OwnProps): ReduxProps => {
  return {
    metaPaths: [`games/__meta/details/${gameId}`],
    gameDetails: state.getIn(['games', 'details', gameId]),
  };
};

const GameInfoPageImpl: React.FC<Props> = props => {
  const {metaPaths, gameDetails} = props;
  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <Spacer vSpace="3" bgColor="fullwhite">
          {/* NOTE: We want this header for a better document outline (heading hierarchy).
           * However, due to the tabs being there, and discussions internally, we have decided
           * to not have them present visually. Ideally we would, but we don't.
           */}
          <VisuallyHidden>
            <Heading pt="4" pb="3" ph="3">
              Game Info
            </Heading>
          </VisuallyHidden>
          <Section>
            <InfoBox gameDetails={gameDetails} />
          </Section>
        </Spacer>
      )}
    </Loading>
  );
};

const infoboxFontSize: TypeScale[] = ['6'];

const InfoBox = ({gameDetails}: {gameDetails: ResultsGameDetails}) => {
  const {localizer} = useContext(ServicesContext);

  const parsedStartDate = useMemo(
    () => parseGameStartDatetime(gameDetails.gameStartDatetime, 'YYYY-MM-DDTHH:mm-Z'),
    [gameDetails.gameStartDatetime],
  );
  return (
    <Spacer vSpace="2" fontWeight="6" extraClassName="tnum">
      <Cluster gap="1">
        {/* Date, e.g. Saturday, November 2, 2019 */}
        <BlockText fontSize={infoboxFontSize}>
          {localizer.format('{gameDate, date, custom}', {
            gameDate: parsedStartDate,
          })}
        </BlockText>
        {/* Court name - time, e.g. "Main Court" - 1:00 PM */}
        <BlockText fontSize={infoboxFontSize}>
          {gameDetails.courtName}
          {' - '}
          {localizer.format('{gameTime, date, timeOnly}', {
            gameTime: parsedStartDate,
          })}
        </BlockText>
      </Cluster>

      <BlockText fontSize={infoboxFontSize}>{formatGameName(gameDetails)}</BlockText>

      {/* NOTE: It is possible to have only ref 1, only ref 2, or both. So we must handle those cases */}
      {/* If either (or both) ref is present */}
      {(!!gameDetails.referee1 || !!gameDetails.referee2) && (
        <BlockText fontSize={infoboxFontSize}>
          Referees:{' '}
          {!!gameDetails.referee1 && !!gameDetails.referee1.firstName && (
            <span>
              {gameDetails.referee1.firstName} {gameDetails.referee1.lastName}
            </span>
          )}
          {/* Only inlcude a comma if both refs are present */}
          {!!gameDetails.referee1 && !!gameDetails.referee2 && ', '}
          {!!gameDetails.referee2 && !!gameDetails.referee2.firstName && (
            <span>
              {gameDetails.referee2.firstName} {gameDetails.referee2.lastName}
            </span>
          )}
        </BlockText>
      )}
    </Spacer>
  );
};

const parseGameStartDatetime = (gameStartDatetime: string, format: string) =>
  moment.utc(gameStartDatetime, format);

export const GameInfoPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(GameInfoPageImpl);
