/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {FastestGameTeam} from './FastestGameTeam';

export const defaultFastestGame = {
  eventId: null,
  eventName: null,
  eventShortname: null,
  gameId: null,
  gameName: null,
  categoryId: null,
  categoryName: null,
  gameLength: null,
  homeTeam: null,
  awayTeam: null,
  gameIsPlayIn: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type FastestGameLike = {
  [K in keyof typeof defaultFastestGame]?: FastestGame[K];
};

export class FastestGame extends Record(defaultFastestGame) {
  eventId?: string;
  eventName?: string;
  eventShortname?: string;
  gameId?: string;
  gameName?: string;
  categoryId?: string;
  categoryName?: string;
  gameLength?: number;
  homeTeam?: FastestGameTeam;
  awayTeam?: FastestGameTeam;
  gameIsPlayIn?: boolean;
  static fromJS(o: FastestGameLike): FastestGame {
    return (
      o &&
      new FastestGame({
        eventId: o.eventId,
        eventName: o.eventName,
        eventShortname: o.eventShortname,
        gameId: o.gameId,
        gameName: o.gameName,
        categoryId: o.categoryId,
        categoryName: o.categoryName,
        gameLength: o.gameLength,
        homeTeam: o.homeTeam && FastestGameTeam.fromJS(o.homeTeam),
        awayTeam: o.awayTeam && FastestGameTeam.fromJS(o.awayTeam),
        gameIsPlayIn: o.gameIsPlayIn,
      })
    );
  }
}

export default FastestGame;
