import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import {GameStatsLike, GameStats} from 'fiba/common/core/models/api/stats/GameStats';
import {PlayerLike} from 'fiba/common/core/models/api/players/Player';

export interface GameStatsStoreState extends Map<string, GameStats> {
  // TODO: `__meta`
}

const reducers = {
  loadGameStats: (state: GameStatsStoreState, gameId: string): GameStatsStoreState =>
    state.updateIn(['__meta', gameId], setMeta.isLoading),

  loadGameStatsSuccess: (
    state: GameStatsStoreState,
    gameId: string,
    gameStats: GameStatsLike,
    players: PlayerLike[],
  ): GameStatsStoreState =>
    state.set(gameId, GameStats.fromJS(gameStats)).updateIn(['__meta', gameId], setMeta.isLoaded),

  loadGameStatsError: (
    state: GameStatsStoreState,
    gameId: string,
    error: Error,
  ): GameStatsStoreState => state.updateIn(['__meta', gameId], setMeta.isError),
};

const externalReducers = {};

export const reducer = createReducer<GameStatsStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

const actions = createActions(__filename, reducers, {
  loadGameStats: (gameId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadGameStats(gameId)
      .then(res => actions.loadGameStatsSuccess(gameId, res.data.gameStatistics, res.data.players))
      .catch(rethrow((err: Error) => actions.loadGameStatsError(gameId, err))),

  loadGameStatsError: (gameId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<GameStatsStoreState>(GameStats);
