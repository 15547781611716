/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {Court} from './Court';
import {Organizer} from '../Organizer';
import {Tour} from '../Tour';

export const defaultEvent = {
  isFull: null,
  isTeamsHidden: null,
  registrationIsOpen: null,
  eventStatus: null,
  id: null,
  name: null,
  shortName: null,
  eventLevelColor: null,
  website: null,
  publicWebsiteHref: null,
  registrationHref: null,
  liveStreamHref: null,
  teamsRegisteredCount: null,
  startDate: null,
  endDate: null,
  registrationStartDatetime: null,
  registrationEndDatetime: null,
  timingStatus: null,
  latitude: null,
  longitude: null,
  location: null,
  addressLine1: null,
  addressLine2: null,
  city: null,
  region: null,
  country: null,
  cityCountryIso2: null,
  description: null,
  organizer: null,
  organizerId: null,
  tourId: null,
  courts: null,
  tour: null,
  imageTinyUrl: null,
  imageSmallUrl: null,
  imageMediumUrl: null,
  imageLargeUrl: null,
  challengerFor: null,
  superQuestFor: null,
  eventIsESport: null,
  eventType: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type EventLike = {
  [K in keyof typeof defaultEvent]?: Event[K];
};

export class Event extends Record(defaultEvent) {
  isFull?: boolean;
  isTeamsHidden?: boolean;
  registrationIsOpen?: boolean;
  eventStatus?: string;
  id?: string;
  name?: string;
  shortName?: string;
  eventLevelColor?: string;
  website?: string;
  publicWebsiteHref?: string;
  registrationHref?: string;
  liveStreamHref?: string;
  teamsRegisteredCount?: number;
  startDate?: string;
  endDate?: string;
  registrationStartDatetime?: string;
  registrationEndDatetime?: string;
  timingStatus?: string;
  latitude?: number;
  longitude?: number;
  location?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  region?: string;
  country?: string;
  cityCountryIso2?: string;
  description?: string;
  organizer?: Organizer;
  organizerId?: string;
  tourId?: string;
  courts?: List<Court>;
  tour?: Tour;
  imageTinyUrl?: string;
  imageSmallUrl?: string;
  imageMediumUrl?: string;
  imageLargeUrl?: string;
  challengerFor?: string;
  superQuestFor?: string | null;
  eventIsESport?: boolean;
  eventType?:
    | null
    | 'WorldTour'
    | 'Challenger'
    | 'SuperQuest'
    | 'WomensSeries'
    | 'NationsLeague'
    | 'Cup';
  static fromJS(o: EventLike): Event {
    return (
      o &&
      new Event({
        isFull: o.isFull,
        isTeamsHidden: o.isTeamsHidden,
        registrationIsOpen: o.registrationIsOpen,
        eventStatus: o.eventStatus,
        id: o.id,
        name: o.name,
        shortName: o.shortName,
        eventLevelColor: o.eventLevelColor,
        website: o.website,
        publicWebsiteHref: o.publicWebsiteHref,
        registrationHref: o.registrationHref,
        liveStreamHref: o.liveStreamHref,
        teamsRegisteredCount: o.teamsRegisteredCount,
        startDate: o.startDate,
        endDate: o.endDate,
        registrationStartDatetime: o.registrationStartDatetime,
        registrationEndDatetime: o.registrationEndDatetime,
        timingStatus: o.timingStatus,
        latitude: o.latitude,
        longitude: o.longitude,
        location: o.location,
        addressLine1: o.addressLine1,
        addressLine2: o.addressLine2,
        city: o.city,
        region: o.region,
        country: o.country,
        cityCountryIso2: o.cityCountryIso2,
        description: o.description,
        organizer: o.organizer && Organizer.fromJS(o.organizer),
        organizerId: o.organizerId,
        tourId: o.tourId,
        courts: o.courts && List(o.courts).map(i => i && Court.fromJS(i)),
        tour: o.tour && Tour.fromJS(o.tour),
        imageTinyUrl: o.imageTinyUrl,
        imageSmallUrl: o.imageSmallUrl,
        imageMediumUrl: o.imageMediumUrl,
        imageLargeUrl: o.imageLargeUrl,
        challengerFor: o.challengerFor,
        superQuestFor: o.superQuestFor,
        eventIsESport: o.eventIsESport,
        eventType: o.eventType,
      })
    );
  }
}

export default Event;
