import {createStore, applyMiddleware, Store, Middleware, Reducer} from 'redux';
import multi from 'redux-multi';
import promise from 'redux-promise';
import {isFunction, flowRight} from 'lodash';
import {commonEnv} from 'fiba/common/config/env';
import createLoggerMiddleware from 'fiba/common/services/loggerMiddleware';

export type StoreService<S> = Store<S>;
export type DispatchService = (action: any) => any;

function createServicesMiddleware(services) {
  return ({dispatch}) => next => action => {
    if (isFunction(action)) {
      return dispatch(action({...services}));
    } else {
      return next(action);
    }
  };
}

// Middleware that will filter out all dispatched values that are not valid flux actions.
// This should be put after any middleware that handles those values such as arrays, thunks or promises.
const filterFSA = () => next => action => {
  if (action && action.type) {
    return next(action);
  }

  return action;
};

export function createStoreService<T, State>(
  services,
  reducer: Reducer<State>,
  preloadState,
  ...externalMiddleware: Middleware[]
): StoreService<T> {
  const devTools =
    typeof window !== 'undefined' && window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
      ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
      : flowRight;
  const loggerMiddleware = commonEnv.DEBUG
    ? createLoggerMiddleware('dispatch')
    : () => next => next;
  const internalMiddleware = [
    multi,
    promise,
    createServicesMiddleware(services),
    filterFSA,
    loggerMiddleware,
  ];
  const middleware = internalMiddleware.concat(externalMiddleware);
  const store = createStore(reducer, preloadState, devTools(applyMiddleware(...middleware)));
  return store;
}

export default createStoreService;
