import React from 'react';
import {ServicesContext} from 'fiba/common/services/services';
import {parseLocalDatetime} from 'fiba/common/utils/schedule';

interface PublishedDateProps {
  date: string;
  className?: string;
}

export const PublishedDate: React.FunctionComponent<PublishedDateProps> = ({date, className}) => (
  <ServicesContext.Consumer>
    {({localizer}) => (
      <React.Fragment>
        <time dateTime={date} className={className}>
          {localizer.format('{date, date, custom}', {date: parseLocalDatetime(date)})}
        </time>
      </React.Fragment>
    )}
  </ServicesContext.Consumer>
);
