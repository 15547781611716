import React, {useContext} from 'react';
import {EventSchedule} from 'fiba/wt/ui/eventSchedule/EventSchedule';
import {eventTeamLinkProvider, gameLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {connect} from 'fiba/common/utils/reactUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';

interface OwnProps {
  season: string;
  eventId: string;
}

interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => ({
  eventTypeData: getEventType(state.get('events'), eventId),
});

const EventSchedulePageImpl: React.FC<Props> = ({season, eventId, eventTypeData}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <Spacer ph={['3', '2', '1']}>
      <section>
        <EventPageHeading eventId={eventId} page="Games" />
        <Section>
          <ViewWebData data={eventTypeData}>
            {eventType => (
              <EventSchedule
                eventId={eventId}
                filters={['team', 'category']}
                createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
                createGameHref={gameLinkProvider(seasonConfig, season, eventId, eventType)}
              />
            )}
          </ViewWebData>
        </Section>
      </section>
    </Spacer>
  );
};

export const EventSchedulePage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  EventSchedulePageImpl,
);
