import React, {useContext} from 'react';
import {List} from 'immutable';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {getWinPercentage, getAveragePoints} from 'fiba/common/core/results';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Link} from 'fiba/wt/ui/link/Link';
import {TableLayoutStyleProps} from 'fiba/wt/ui/table/Table';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';

// Props
export interface IStandingsTable {
  columns: StandingsTableColumns[];
  standings: List<ResultsStanding>;
  showOnlyFinalStandings?: boolean;
  showQualifierDrawDropouts?: boolean;
  // NOTE: if caption is not provided, make sure the table is preceded by a heading
  caption?: string | React.ReactNode;
  captionId: string;
  createTeamHref?: ResourceLinkProvider;
  cellSpacing?: StyleProp<'pv'>;
}

// Available columns
export type StandingsTableColumns =
  | 'rank'
  | 'name'
  | 'gamesPlayed'
  | 'gamesWon'
  | 'winRatio'
  | 'averagePoints'
  | 'totalPoints';

// Spec
type StandingsColumnSpec = IColumnSpec<ResultsStanding>;

const tdStyle: TableLayoutStyleProps = {
  fontWeight: '4',
};

// It would be nice to have a direct flag to state that this standing was a dropout
// from qualifying draw rounds, but we can use this condition from the spec:
// > Teams with a rank but 0 games played are QD eliminated
const isQualifierDrawDropout = (standing: ResultsStanding): boolean =>
  standing && typeof standing.teamRank !== 'undefined' && standing.gamesPlayed === 0;

// Specify dependencies of the StandingsColumnsSpec getter
// We Haskell now
interface IGetStandingsColumnsSpec {
  createTeamHref?: ResourceLinkProvider;
  localizer: LocalizerService;
  showQualifierDrawDropouts?: boolean;
  cellSpacing?: StyleProp<'pv'>;
}

const getStandingsColumnsSpec = ({
  createTeamHref,
  localizer,
  showQualifierDrawDropouts,
  cellSpacing,
}: IGetStandingsColumnsSpec): Record<StandingsTableColumns, StandingsColumnSpec> => ({
  rank: {
    name: '#',
    srName: 'Rank',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})} extraClassName="column-fit">
        {rowData.teamRank ? `${rowData.teamRank}.` : '-'}
      </Td>
    ),
  },
  name: {
    name: 'Name',
    dataType: 'text',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {createTeamHref && rowData.teamId ? (
          <Link colorTheme="dark" href={createTeamHref(rowData.teamId)}>
            <TeamNameAssembly standing={rowData} isSeedVisible isSuffixVisible />
          </Link>
        ) : (
          <TeamNameAssembly standing={rowData} isSeedVisible isSuffixVisible />
        )}
      </Td>
    ),
  },
  gamesPlayed: {
    name: 'GP',
    srName: 'Games Played',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {showQualifierDrawDropouts && isQualifierDrawDropout(rowData) ? 'QD' : rowData.gamesPlayed}
      </Td>
    ),
  },
  gamesWon: {
    name: 'GW',
    srName: 'Games Won',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {showQualifierDrawDropouts && isQualifierDrawDropout(rowData) ? '' : rowData.gamesWon}
      </Td>
    ),
  },
  winRatio: {
    name: 'W%',
    srName: 'Win Percentage',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {showQualifierDrawDropouts && isQualifierDrawDropout(rowData)
          ? ''
          : getWinPercentage(rowData, localizer)}
      </Td>
    ),
  },
  averagePoints: {
    name: 'PTS AVG',
    srName: 'Points Average',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {showQualifierDrawDropouts && isQualifierDrawDropout(rowData)
          ? ''
          : getAveragePoints(rowData, localizer)}
      </Td>
    ),
  },
  totalPoints: {
    name: 'PTS',
    srName: 'Points',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {showQualifierDrawDropouts && isQualifierDrawDropout(rowData) ? '' : rowData.totalPoints}
      </Td>
    ),
  },
});

const filterStandings = (standings: List<ResultsStanding>, showOnlyFinalStandings: boolean) => {
  return showOnlyFinalStandings
    ? standings.filter(standing => standing.teamRank != null).toArray()
    : standings;
};

// Specialisation

export const StandingsTable: React.FunctionComponent<IStandingsTable> = ({
  columns,
  standings,
  showOnlyFinalStandings,
  showQualifierDrawDropouts,
  caption,
  captionId,
  createTeamHref,
  cellSpacing = '3',
}) => {
  const {localizer} = useContext(ServicesContext);
  return (
    <DataTable<StandingsTableColumns, ResultsStanding>
      columnsSpec={getStandingsColumnsSpec({
        localizer,
        createTeamHref,
        showQualifierDrawDropouts,
        cellSpacing,
      })}
      caption={caption}
      captionId={captionId}
      columns={columns}
      rows={filterStandings(standings, showOnlyFinalStandings)}
      headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
      headerExtraClassName="dark-20"
      rowExtraClassName="StandingsTable-TableRow striped--light-even bb bw1 b--silver-20"
    />
  );
};
