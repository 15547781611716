import {Map, fromJS} from 'immutable';
import {flatMap, concat, mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import resultActions from 'fiba/wt/stores/resultStore';
import {
  ResultsGameSummary,
  ResultsGameSummaryLike,
} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {
  ResultsGameDetails,
  ResultsGameDetailsLike,
} from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {ResultsLike} from 'fiba/common/core/models/api/results/Results';
import {ResultsGroupLike} from 'fiba/common/core/models/api/results/ResultsGroup';
import FibaError from 'fiba/common/core/fibaError';
import PlayByPlayTableOfficialsData, {
  PlayByPlayTableOfficialsDataLike,
  PlayByPlayTableOfficialsDataEmpty,
} from 'fiba/common/core/models/api/playByPlay/PlayByPlayTableOfficialsData';
import PlayByPlayStatisticsData, {
  PlayByPlayStatisticsDataLike,
} from 'fiba/common/core/models/api/playByPlay/PlayByPlayStatisticsData';

export interface GameStoreState extends Map<string, Map<string, ResultsGameSummary>> {
  /*
  {
    details: Map<string, ResultsGameSummary>,
    summary: Map<string, ResultsGameDetails>,
    playByPlayStatisticsData: Map<string, PlayByPlayStatisticsData>,
    playByPlayTableOfficialsData: Map<string, PlayByPlayTableOfficialsData|PlayByPlayTableOfficialsDataEmpty>
    __meta {
      details: {...},
      summary: {...},
      playByPlayStatisticsData: {...},
      playByPlayTableOfficialsData: {...},
    }
  }
  */
}

const reducers = {
  // Details
  loadGameDetails: (state: GameStoreState, _eventId: string, gameId: string): GameStoreState =>
    state.updateIn(['__meta', 'details', gameId], setMeta.isLoading),

  loadGameDetailsSuccess: (state: GameStoreState, game: ResultsGameDetailsLike): GameStoreState =>
    state
      .setIn(['details', game.gameId], ResultsGameDetails.fromJS(game))
      .updateIn(['__meta', 'details', game.gameId], setMeta.isLoaded),

  // Summary
  loadGameSummary: (state: GameStoreState, _eventId: string, gameId: string): GameStoreState =>
    state.updateIn(['__meta', 'summary', gameId], setMeta.isLoading),

  loadGameSummarySuccess: (state: GameStoreState, game: ResultsGameSummaryLike): GameStoreState =>
    state
      .setIn(['summary', game.gameId], ResultsGameSummary.fromJS(game))
      .updateIn(['__meta', 'summary', game.gameId], setMeta.isLoaded),

  loadGamesSummaryByEventSuccess: (
    state: GameStoreState,
    eventId: string,
    games: ResultsGameSummaryLike[],
  ): GameStoreState => games.reduce(reducers.loadGameSummarySuccess, state),

  // Play by Play Table Officials Data
  loadGamePlayByPlayTableOfficialsData: (state: GameStoreState, gameId: string): GameStoreState =>
    state.updateIn(['__meta', 'playByPlayTableOfficialsData', gameId], setMeta.isLoading),

  loadGamePlayByPlayTableOfficialsDataSuccess: (
    state: GameStoreState,
    gameId: string,
    data: PlayByPlayTableOfficialsDataLike,
  ): GameStoreState =>
    state
      .setIn(['playByPlayTableOfficialsData', gameId], PlayByPlayTableOfficialsData.fromJS(data))
      .updateIn(['__meta', 'playByPlayTableOfficialsData', gameId], setMeta.isLoaded),

  loadGamePlayByPlayTableOfficialsDataError: (
    state: GameStoreState,
    gameId: string,
    error: FibaError,
  ): GameStoreState => {
    // Assume that 404 is empty stuff; mark it as such, and set to loaded
    if (error.fiba.get('status') === 404) {
      return state
        .setIn(['playByPlayTableOfficialsData', gameId], new PlayByPlayTableOfficialsDataEmpty())
        .updateIn(['__meta', 'playByPlayTableOfficialsData', gameId], setMeta.isLoaded);
    }
    // Otherwise, it's an error proper; mark meta isError
    else {
      return state.updateIn(['__meta', 'playByPlayTableOfficialsData', gameId], setMeta.isError);
    }
  },

  // Play by Play Table Officials Data
  loadGamePlayByPlayStatsData: (state: GameStoreState, gameId: string): GameStoreState =>
    state.updateIn(['__meta', 'playByPlayStatsData', gameId], setMeta.isLoading),

  loadGamePlayByPlayStatsDataSuccess: (
    state: GameStoreState,
    gameId: string,
    data: PlayByPlayStatisticsDataLike,
  ): GameStoreState =>
    state
      .setIn(['playByPlayStatsData', gameId], PlayByPlayStatisticsData.fromJS(data))
      .updateIn(['__meta', 'playByPlayStatsData', gameId], setMeta.isLoaded),

  loadGamePlayByPlayStatsDataError: (
    state: GameStoreState,
    gameId: string,
    error: FibaError,
  ): GameStoreState => {
    // Unlike Table Officials Data, Statistics Data does not return 404 for empty
    return state.updateIn(['__meta', 'playByPlayStatsData', gameId], setMeta.isError);
  },
};

const externalReducers = {
  [resultActions.types.loadResultsByCategorySuccess]: (
    state: GameStoreState,
    categoryId: string,
    results: ResultsLike,
  ): GameStoreState => {
    const games = concat(
      flatMap(results.qualifyingDrawGroups || [], group => (group as ResultsGroupLike).groupGames),
      flatMap(results.poolGroups || [], group => (group as ResultsGroupLike).groupGames),
      flatMap(results.knockoutGroups || [], group => (group as ResultsGroupLike).groupGames),
    ) as ResultsGameSummaryLike[];

    return games.reduce(reducers.loadGameSummarySuccess, state);
  },
};

export const reducer = createReducer<GameStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

const actions = createActions(__filename, reducers, {
  // Details
  loadGameDetails: (eventId: string, gameId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadGameDetails(eventId, gameId)
      .then(res => actions.loadGameDetailsSuccess(res.data))
      .catch(rethrow((err: Error) => actions.loadGameDetailsError(eventId, gameId, err))),

  loadGameDetailsError: (eventId: string, gameId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // Summary
  loadGamesSummaryByEvent: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadGamesSummaryByEvent(eventId)
      .then(res => actions.loadGamesSummaryByEventSuccess(eventId, res.data))
      .catch(rethrow((err: Error) => actions.loadGamesSummaryByEventError(eventId, err))),

  loadGamesSummaryByEventError: (eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // Getting games with classification type WorldTour
  loadWorldTourGamesSummary: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadWorldTourGamesSummary(eventId)
      .then(res => actions.loadGamesSummaryByEventSuccess(eventId, res.data))
      .catch(rethrow((err: Error) => actions.loadGamesSummaryByEventError(eventId, err))),

  loadWorldTourGamesSummaryError: (eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  // Play by Play Officials Data
  loadGamePlayByPlayTableOfficialsData: (gameId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadGameTableOfficialsData(gameId)
      .then(res => actions.loadGamePlayByPlayTableOfficialsDataSuccess(gameId, res.data))
      // NOTE: We do not rethrow; we postprocess these errors in the reducer
      .catch(err => actions.loadGamePlayByPlayTableOfficialsDataError(gameId, err)),

  // Play by Play Stats Data
  loadGamePlayByPlayStatsData: (gameId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadGamePlayByPlayStatsData(gameId)
      .then(res => actions.loadGamePlayByPlayStatsDataSuccess(gameId, res.data))
      .catch(rethrow((err: Error) => actions.loadGamePlayByPlayStatsDataError(gameId, err))),
});

export default actions;

export function deserializeState(state): GameStoreState {
  const {__meta, details, summary, playByPlayTableOfficialsData, playByPlayStatsData} = state;

  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail
    __meta: __meta || {
      details: {},
      summary: {},
      playByPlayTableOfficialsData: {},
      playByPlayStatsData: {},
    },
    //  details: Map<string, NewsItem>
    //  summary: Map<string, NewsItemPreview>
    details: details ? mapValues(details, ResultsGameDetails.fromJS) : {},
    summary: summary ? mapValues(summary, ResultsGameSummary.fromJS) : {},
    playByPlayTableOfficialsData: playByPlayTableOfficialsData
      ? mapValues(playByPlayTableOfficialsData, PlayByPlayTableOfficialsData.fromJS)
      : {},
    playByPlayStatsData: playByPlayStatsData
      ? mapValues(playByPlayStatsData, PlayByPlayStatisticsData.fromJS)
      : {},
  });
}
