import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta} from 'fiba/common/stores/storeUtils';
import tourActions from 'fiba/wt/stores/tourStore';
import {TourTeamListLike} from 'fiba/common/core/models/api/tour/TourTeamList';
import {TourTeam, TourTeamLike} from 'fiba/common/core/models/api/tour/TourTeam';

export interface TourTeamStoreState extends Map<string, TourTeam> {
  // TODO: `__meta`
}

const reducers = {
  loadTourTeamSuccess: (state: TourTeamStoreState, tourTeam: TourTeamLike): TourTeamStoreState =>
    state
      .set(tourTeam.id, TourTeam.fromJS(tourTeam))
      .updateIn(['__meta', tourTeam.id], setMeta.isLoaded),
};

const externalReducers = {
  [tourActions.types.loadTeamsByTourSuccess]: (
    state: TourTeamStoreState,
    tourId: string,
    data: TourTeamListLike,
  ): TourTeamStoreState => {
    const tourTeams = data.teams;
    return tourTeams.reduce(reducers.loadTourTeamSuccess, state);
  },
};

export const reducer = createReducer<TourTeamStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

const actions = createActions(__filename, reducers, {});

export default actions;

export const deserializeState = createStoreReviver<TourTeamStoreState>(TourTeam);
