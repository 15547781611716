import uuid from 'uuid';
import {stripNamespace} from 'fiba/common/stores/storeUtils';

// @see https://github.com/futurice/fiba-3x3-scores/blob/master/fiba/scores/backend/apis/LiveScoresApiV2.tsx

//
// External types

export const SCORES_CLIENT_EVENT = `${stripNamespace(__filename)}/scoresClientEvent`;
export const SCORES_CLIENT_ERROR = `${stripNamespace(__filename)}/scoresClientError`;
export const SCORES_CLIENT_CONNECT = `${stripNamespace(__filename)}/scoresClientConnect`;
export const SCORES_CLIENT_DISCONNECT = `${stripNamespace(__filename)}/scoresClientDisconnect`;

export const scoresClientEvent = (eventId: string, data: IGameStatusMap) => ({
  type: SCORES_CLIENT_EVENT,
  payload: {eventId, data},
});
export type IScoresClientEvent = ReturnType<typeof scoresClientEvent>;

export const scoresClientError = (data: ILiveScoresApiV2Error) => ({
  type: SCORES_CLIENT_ERROR,
  payload: data,
});
export type IScoresClientError = ReturnType<typeof scoresClientError>;

export const scoresClientConnect = (eventId: string) => ({
  type: SCORES_CLIENT_CONNECT,
  payload: eventId,
});
export type IScoresClientConnect = ReturnType<typeof scoresClientConnect>;

export const scoresClientDisconnect = (eventId: string) => ({
  type: SCORES_CLIENT_DISCONNECT,
  payload: eventId,
});
export type IScoresClientDisconnect = ReturnType<typeof scoresClientDisconnect>;

export enum GameState {
  STARTING = 'GAME_STATE_STARTING',
  RUNNING = 'GAME_STATE_RUNNING',
  PAUSED = 'GAME_STATE_PAUSED',
  OVERTIME = 'GAME_STATE_OVERTIME',
  FINISHED = 'GAME_STATE_FINISHED',
}

export interface IGameStatus {
  currentGameState: GameState;
  homeTeamScore: number;
  awayTeamScore: number;
  remainingGameTime: string; // e.g. "10:00"
}

export interface IGameStatusMap {
  [key: string]: IGameStatus;
}

//
// Internal types

enum ApiCommand {
  SUBSCRIBE = 'subscribe',
}

enum MessageType {
  SUBSCRIBED = 'subscribed',
  GAME_STATUS_UPDATE = 'game-status-update',
}

interface ILiveScoresApiV2Request {
  apiName: 'LiveScoresApiV2';
  apiCommand: ApiCommand;
  requestId: string;
}

interface ILiveScoresApiV2Response {
  apiName: ILiveScoresApiV2Request['apiName'];
  requestId: ILiveScoresApiV2Request['requestId'];
  messageType: MessageType;
  data: any;
}

export interface ILiveScoresApiV2Error {
  apiName: ILiveScoresApiV2Request['apiName'];
  errorMessage: string;
  errorDetails: string;
  errorContext: string;
}

export interface ISubscribeCommand extends ILiveScoresApiV2Request {
  apiCommand: ApiCommand.SUBSCRIBE;
  eventId: string;
}

interface ISubscribedMessage extends ILiveScoresApiV2Response {
  messageType: MessageType.SUBSCRIBED;
  data: null;
}

interface IGameStatusUpdateMessage extends ILiveScoresApiV2Response {
  messageType: MessageType.GAME_STATUS_UPDATE;
  data: {[key: string]: IGameStatus};
}

const createSubscribeCommand = (eventId: string): ISubscribeCommand => ({
  apiName: 'LiveScoresApiV2',
  apiCommand: ApiCommand.SUBSCRIBE,
  requestId: uuid.v4(),
  eventId,
});

const isLiveScoresError = (data: any): data is ILiveScoresApiV2Error => !!data.errorMessage;

const isSubscribedMessage = (data: any): data is ISubscribedMessage =>
  data.messageType === MessageType.SUBSCRIBED;

const isGameStatusUpdateMessage = (data: any): data is IGameStatusUpdateMessage =>
  data.messageType === MessageType.GAME_STATUS_UPDATE;

//
// Middleware function

export function createScoresClientV2Middleware(baseUrl: string) {
  return ({dispatch}) => {
    // NOTE: Do nothing if on the server
    if (typeof window === 'undefined') {
      return next => next;
    }

    const sockets: {[key: string]: WebSocket} = {};

    // Middleware
    return next => action => {
      if (action && action.type === SCORES_CLIENT_CONNECT) {
        return openConnection(action.payload);
      }

      if (action && action.type === SCORES_CLIENT_DISCONNECT) {
        return closeConnection(action.payload);
      }

      return next(action);
    };

    function openConnection(eventId: string) {
      if (!!sockets[eventId]) {
        return sockets[eventId];
      }

      const ws = new WebSocket(baseUrl);

      ws.onopen = function () {
        ws.send(JSON.stringify(createSubscribeCommand(eventId)));
      };

      ws.onerror = function (error) {
        // TODO: Default error handling, what to do?
        closeConnection(eventId);
        // TODO: Retry connection?
      };

      ws.onmessage = function (message) {
        // TODO: Default error handling, what to do?
        const data = JSON.parse(message.data) as ILiveScoresApiV2Response | ILiveScoresApiV2Error;

        if (isLiveScoresError(data)) {
          dispatch(scoresClientError(data));
        } else if (isGameStatusUpdateMessage(data)) {
          dispatch(scoresClientEvent(eventId, data.data));
        } else if (isSubscribedMessage(data)) {
          // TODO: What to do? Should we have an indicator on the page, where to store the status?
        }
      };

      sockets[eventId] = ws;
      return ws;
    }

    function closeConnection(eventId: string) {
      const ws = sockets[eventId];
      if (ws) {
        ws.close();
      }
      delete sockets[eventId];
    }
  };
}
