import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {PlayerStatistics} from 'fiba/wt/ui/statistics/PlayerStatistics';
import {tourTeamLinkProvider, tourTeamPlayerLinkProvider} from 'fiba/wt/utils/linkUtils';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

export interface Props {
  tourId: string;
  season: string;
  eventId: string;
}

export const TourStatisticsPlayersTab: React.FunctionComponent<Props> = props => {
  const {tourId, season, eventId} = props;

  // Curry TourTeamPlayerLinkProvider since we need to have  teamId and playerId from rowData in playerStatisticsTable
  const curriedTourTeamPlayerLinkProvider = (season: string) => {
    return (teamId: string) => {
      return (playerId: string) => {
        return tourTeamPlayerLinkProvider(season, teamId)(playerId);
      };
    };
  };

  return (
    <Spacer vSpace="4">
      <PlayerStatistics
        tourOrEventId={tourId}
        season={season}
        eventId={eventId}
        createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
        renderTeamName={rowData => (
          /* At the WT-level, all teams are WT teams, so we don't check like we do in ProCircuitPlayerSTatistics */
          <StyledLink href={tourTeamLinkProvider(season)(rowData.team.teamId)}>
            <TeamNameAssembly standing={rowData['team']} isSuffixVisible isIocVisible={false} />
          </StyledLink>
        )}
      />
    </Spacer>
  );
};
