import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import categoryActions from 'fiba/wt/stores/categoryStore';
import resultActions from 'fiba/wt/stores/resultStore';
import teamActions from 'fiba/wt/stores/teamStore';
import eventController, {EventControllerFeatures} from 'fiba/wt/controllers/eventController';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Category} from 'fiba/common/core/models/api/events/Category';

export interface CategoryControllerFeatures {
  results?: boolean;
  teams?: boolean;
  event?: EventControllerFeatures;
}

function categoryController(categoryId: string, features: CategoryControllerFeatures) {
  return (services: CommonServices) => {
    const {dispatch, cache} = services;
    // Extra features that can be loaded with category
    const featureLoaders = {
      results: () =>
        cache(['categories', categoryId, 'results'], () =>
          dispatch(resultActions.loadResultsByCategory(categoryId)),
        ),

      teams: () =>
        cache(['categories', categoryId, 'teams'], () =>
          dispatch(teamActions.loadTeamsByCategory(categoryId)),
        ),

      event: (features: EventControllerFeatures, category: Category) =>
        eventController(category.eventId, features)(services),
    };

    return cache(['categories', categoryId], () => {
      return dispatch(categoryActions.loadCategory(categoryId));
    }).then(() => loadFeatures(featureLoaders, features, cache(['categories', categoryId])));
    // Don't set a catch handler, but let the errors ripple back to router
  };
}

export default categoryController;
