import process from 'process';
import {fromJS} from 'immutable';
import {mapKeys, mapValues, isArray} from 'lodash';
import {Action, ActionCreator} from 'redux';

/*

  Functions `createReducer` and `createActions` allow you to write reducers and
  derive basic action creator functions from them. In addition to these you can
  create custom action creators such as async actions or dispatch multiple actions.
  All actions and reducers created with these functions will be automatically
  namespaced with the given namespace which can be `__dirname` or custom path/name.

  @requires 'redux-multi`

  @example
  ```
  interface StoreState {
    // ...
  }

  const initialState = ...

  const reducers = {
    // Basic derived action creators will produce an action that has the action creator
    // arguments as an array in `payload`. That array will be destructured to arg1..argN
    loadFoo: (state: StoreState, id) => state,
    loadFooSuccess: (state: StoreState, foo) => state,
    loadFooError: (state: StoreState, error) => state,

    loadBar: (state: StoreState, ...payload) => state,
    loadBarSuccess: (state: StoreState, ...payload) => state,
    loadBarError: (state: StoreState, ...payload) => state,
  };

  const reducer = createReducer<StoreState>(__filename, initialState, reducers);

  const actions = createActions(__dirname, reducers, {
    // Custom actions here. Note that these will generate a _sentinel_ action
    // with the same rules as to those that are generated from the reducers.
    // For e.g. `loadFoo would dispatch `{type: 'name/space/loadFoo', payload: [id]}`.
    // Which also means that the reducer will be called for this action.
    // This also demonstrates the use of _thunk_ and _promise_ middlewares
    loadFoo: (id) => ({apiClient}) =>
      apiClient.loadBaz(id)
        .then(actions.loadFooSuccess)
        .catch(actions.loadFooError)

    // Same thing but using async actions with _promise_ middleware and global fetch
    loadBar: async (id) => {
      try {
        r = await fetch(`faz/${id}`).then(res => res.json());
        return actions.bar(r);
      } catch (e) {
        return actions.foo(e);
      }
    }
  })
  ```

  You can now call actions
  ```
  actions.loadFoo('foo');
  // {type: '/dir/store.js/loadFoo', payload: ['foo']}
  ```

  You can also find a map of namespaced action types under `action.types`. This can be utilized
  to reduce over actions on other store:

  ```
  const reducers = {...}
  const externalReducers = {
    // They key resolves to the namespaced action type of `otherStoreAction`
    [otherStoreActions.types.otherStoreAction]: (state) => state,
  };

  // Note `externalReducers` being passed as the last argument
  const reducer = createReducer(__dirname, initialState, reducers, externalReducers);
  ```
 */

const isNamespaced = (str: string) => str.indexOf('/') >= 0;

export function getNamespace(filename: string) {
  const cwd = process.cwd();
  const reroot = filename.slice(0, cwd.length) === cwd ? filename.slice(cwd.length) : filename;
  const stripped = reroot.replace(/(\.ts|\.tsx)$/, '');
  return stripped[0] === '/' ? stripped.slice(1) : stripped;
}

export const nskey = (ns: string, key: string) => (isNamespaced(key) ? key : `${ns}/${key}`);

export type Reducer<S> = (state: S, ...payload: any[]) => S;
export interface ReducerMap<S> {
  [key: string]: Reducer<S>;
}

export function createReducer<S>(
  filename: string,
  initialState: S,
  reducers: ReducerMap<S>,
  externalReducers?: ReducerMap<S>,
) {
  const ns = getNamespace(filename);
  const _reducers = {
    ...mapKeys(reducers, (_, key) => nskey(ns, key)),
    ...externalReducers,
  };

  return reducer;

  function reducer(state = initialState, action) {
    const {type, payload} = action;
    if (type in _reducers) {
      if (isArray(payload)) {
        return _reducers[type](state, ...payload);
      } else {
        return _reducers[type](state, payload);
      }
    }
    return state;
  }
}

type ActionsOf<R> = {[K in keyof R]: (...payload: any[]) => Action};
type ExtraActions<A> = {[K in keyof A]: A[K]};
// eslint-disable-next-line: interface-over-type-literal
type ActionTypesOf<R, A> = {
  types: {[K in keyof A]: string} & {[K in keyof R]: string};
};

export function createActions<R, A>(
  filename: string,
  reducers: R,
  extraActions: A,
): ExtraActions<A> & ActionsOf<R> & ActionTypesOf<A, R> {
  const ns = getNamespace(filename);
  // TODO: Filter out keys with already namespaced items
  const actions = mapValues<any, ActionCreator<any>>(reducers, (_, key) => (...payload) => ({
    type: nskey(ns, key),
    payload,
  }));
  const _extraActions = mapValues<any, ActionCreator<any>>(
    extraActions,
    (action, key) => (...payload) => [{type: nskey(ns, key), payload}, action(...payload)],
  );
  const types = mapValues<any, string>({...(reducers as any), ...(extraActions as any)}, (_, key) =>
    nskey(ns, key),
  );

  return {...actions, ...(_extraActions as any), types};
}

//
// Conversion of server-side state to client-side state for a simple store that
// has items of type `reviverClass` keyed by their id in a `Map`
export function createStoreReviver<S>(reviverClass) {
  return reviver;

  function reviver(state): S {
    const {__meta, ...items} = state;
    return fromJS({
      // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail on chrome
      __meta: __meta || {},
      ...mapValues(items, (item, id: string) => reviverClass.fromJS(item)),
    });
  }
}

export const resolvePathWithGender = (eventOrTourId: string, gender): string[] =>
  gender ? [eventOrTourId, gender] : [eventOrTourId];
