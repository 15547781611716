import {Map, List, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import teamActions from 'fiba/wt/stores/teamStore';
import resultActions from 'fiba/wt/stores/resultStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Category, CategoryLike} from 'fiba/common/core/models/api/events/Category';
import {TeamLike} from 'fiba/common/core/models/api/teams/Team';
import {ResultsLike} from 'fiba/common/core/models/api/results/Results';

export interface CategoryStoreState extends Map<string, Category> {
  // TODO: `__meta`
}

const reducers = {
  loadCategory: (state: CategoryStoreState, categoryId: string): CategoryStoreState =>
    state.updateIn(['__meta', categoryId], setMeta.isLoading),

  loadCategorySuccess: (state: CategoryStoreState, category: CategoryLike): CategoryStoreState =>
    state
      .set(category.id, Category.fromJS(category))
      // FIXME: Chrome throws `Error: invalid keyPath` here
      .updateIn(['__meta', category.id], setMeta.isLoaded),

  loadCategoryError: (
    state: CategoryStoreState,
    categoryId: string,
    error: Error,
  ): CategoryStoreState => state.updateIn(['__meta', categoryId], setMeta.isError),

  loadCategoriesByEventSuccess: (
    state: CategoryStoreState,
    eventId: string,
    categories: CategoryLike[],
  ): CategoryStoreState => {
    return categories.reduce(reducers.loadCategorySuccess, state);
  },
};

export const reducer = createReducer<CategoryStoreState>(__filename, fromJS({}), reducers, {
  [teamActions.types.loadTeamsByCategory]: (
    state: CategoryStoreState,
    categoryId,
  ): CategoryStoreState => state.updateIn(['__meta', categoryId, 'teams'], setMeta.isLoading),

  [teamActions.types.loadTeamsByCategorySuccess]: (
    state: CategoryStoreState,
    categoryId: string,
    teams: TeamLike[],
  ): CategoryStoreState =>
    state
      .updateIn(['__meta', categoryId, 'teams'], setMeta.isLoaded)
      .setIn(['__meta', categoryId, 'teams', 'refs'], List(teams.map(team => team.id))),

  [teamActions.types.loadTeamsByCategoryError]: (
    state: CategoryStoreState,
    categoryId: string,
    error,
  ): CategoryStoreState => state.updateIn(['__meta', categoryId, 'teams'], setMeta.isError),

  [resultActions.types.loadResultsByCategory]: (
    state: CategoryStoreState,
    categoryId: string,
  ): CategoryStoreState => state.updateIn(['__meta', categoryId, 'results'], setMeta.isLoading),

  [resultActions.types.loadResultsByCategorySuccess]: (
    state: CategoryStoreState,
    categoryId,
    results: ResultsLike,
  ): CategoryStoreState => state.updateIn(['__meta', categoryId, 'results'], setMeta.isLoaded),

  [resultActions.types.loadResultsByCategoryError]: (
    state: CategoryStoreState,
    categoryId: string,
  ): CategoryStoreState => state.updateIn(['__meta', categoryId, 'results'], setMeta.isError),
});

export const actions = createActions(__filename, reducers, {
  loadCategory: (categoryId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadCategory(categoryId)
      .then(res => actions.loadCategorySuccess(res.data))
      .catch(rethrow((err: Error) => actions.loadCategoryError(categoryId, err))),

  loadCategoryError: (categoryId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),

  loadCategoriesByEvent: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadCategoriesByEvent(eventId)
      .then(res => actions.loadCategoriesByEventSuccess(eventId, res.data.categories))
      .catch(err => actions.loadCategoriesByEventError(eventId, err)),

  // Almost identical with loadCategoriesByEvent but getting categories with classification type WorldTour
  loadWorldTourCategoriesByEvent: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadWorldTourCategoriesByEvent(eventId)
      .then(res => actions.loadCategoriesByEventSuccess(eventId, res.data.categories))
      .catch(err => {
        // HOTFIX: Fri 15 Nov, 2019, to remove
        // We set "success" with empty categories in case of error
        // This sucks, but it gets things working (?)
        // TODO: In eventStore, ensure that isError is set correctly
        return actions.loadCategoriesByEventSuccess(eventId, []);
      }),

  loadCategoriesByEventError: (eventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<CategoryStoreState>(Category);

export const splitEventCategoriesByGender = (categories: CategoryStoreState, eventId: string) =>
  categories
    .filter(category => category.get('eventId') === eventId)
    .reduce((acc, category) => {
      const {id, gender} = category;
      return {...acc, [gender]: id}; // Assuming each gender has one category
    }, {} as Map<string, List<Category>>);
