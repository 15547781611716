import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {ContentTypes, ImageLinkTypes} from 'fiba/wt/services/contentfulService';

export {ImageLinkTypes};

export interface ContentfulImageChildProps {
  id: string;
  title: string;
  image: any;
  link: string;
  type: string;
  path: string;
}

interface ContentfulImageLinkOwnProps {
  contentPath: string;
  imageLinkType: ImageLinkTypes;
  children: (props: ContentfulImageChildProps[]) => React.ReactElement<any>;
}

interface ContentfulImageLinkStateProps {
  imageLinks: ContentfulImageChildProps[];
  metaPath: string;
}

type ContentfulImageLinkProps = ContentfulImageLinkOwnProps & ContentfulImageLinkStateProps;

const mapStateToProps = (
  state,
  {contentPath, imageLinkType}: ContentfulImageLinkOwnProps,
): ContentfulImageLinkStateProps => {
  const contentPathArr = contentPath.split('/');

  return {
    imageLinks: state
      .getIn(['content', ContentTypes.ImageLink, ...contentPathArr], List())
      .filter(imageLink => imageLink.getIn(['fields', 'type']) === imageLinkType)
      .map(imageLink => ({
        id: imageLink.getIn(['sys', 'id']),
        ...imageLink.get('fields').toJS(),
      }))
      .toArray(),
    metaPath: `content/__meta/${ContentTypes.ImageLink}/${contentPathArr.join('/')}`,
  };
};

const _ContentfulImageLink: React.FunctionComponent<ContentfulImageLinkProps> = ({
  imageLinks,
  metaPath,
  children,
}) => <Loading metaPaths={[metaPath]}>{() => children(imageLinks)}</Loading>;

export const ContentfulImageLink = connect<
  ContentfulImageLinkStateProps,
  {},
  ContentfulImageLinkOwnProps
>(mapStateToProps)(_ContentfulImageLink);
