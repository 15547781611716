import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';

export interface ITourStatisticsDownloads {
  tourId: string;
  season: string;
}

export const TourStatisticsDownloads: React.FunctionComponent<ITourStatisticsDownloads> = ({
  tourId,
}) => (
  <Spacer vSpace="3" measure="very-wide" align="center" debugClassName="TourStatisticsDownloads">
    <SubHeading>Downloads</SubHeading>
    <ContentfulArticle
      articleType={ArticleTypes.TourStatsDownloads}
      contentPath={`tours/${tourId}`}
    />
  </Spacer>
);
