import React from 'react';
import {commonEnv} from 'fiba/common/config/env';
import IframeResizer from 'iframe-resizer-react';

// This component is meant as a gateway to the calendar application:
// https://github.com/futurice/fiba-3x3-competition-calendar
// which is written in Elm and hosted independently
// The props that are listed here are mostly parameters accepted by the calendar app
// and should be kept in sync with it.

type DisplayMode = 'embedded' | 'stripped' | 'standalone';

type Filter =
  | 'WomensSeries'
  | 'ProCircuit'
  | 'WorldTourMasters'
  | 'RoadToWorldTour'
  | 'Cups'
  | 'FibaOfficial';

interface CommonProps {
  defaultSeason?: number;
  displayMode?: DisplayMode;
  hideSeasonSelector?: boolean;
  iframeTitle?: string;
  id?: string;
}

// How to Qualify page has no filter so it takes fewer parameters:

interface HowToQualifyProps {
  type: 'how-to-qualify';
  hideFilter?: never;
  defaultFilter?: never;
}

interface CalendarProps {
  type: 'calendar';
  hideFilter?: boolean;
  defaultFilter?: Filter;
}

type CompetitionCalendarProps = CommonProps & (HowToQualifyProps | CalendarProps);

export const CompetitionCalendar: React.FunctionComponent<CompetitionCalendarProps> = ({
  iframeTitle = 'Competition Calendar',
  id,
  ...props
}) => {
  const embedUrl = getUrl(props);
  return (
    <IframeResizer
      id={id}
      src={embedUrl.href}
      heightCalculationMethod="max"
      // Note: Using min-width to set the width of the iframe,
      // works around an issue in iOS that can prevent the iFrame
      // from sizing correctly.
      // @see https://github.com/davidjbradshaw/iframe-resizer-react#typical-setup
      style={{width: '1px', minWidth: '100%'}}
      title={iframeTitle}
    />
  );
};

function getUrl(props: CompetitionCalendarProps): URL {
  const {
    type,
    hideFilter,
    defaultSeason,
    defaultFilter,
    hideSeasonSelector,
    displayMode = 'embedded',
  } = props;

  let path = `${type}`;

  if (defaultSeason) {
    path = `${path}/${defaultSeason}`;
  }
  if (defaultFilter) {
    path = `${path}/${defaultFilter}`;
  }

  const url = new URL(path, commonEnv.COMPETITION_CALENDAR_URL);
  if (displayMode) {
    url.searchParams.set('displayMode', displayMode);
  }
  if (hideFilter) {
    url.searchParams.set('hideFilter', 'true');
  }
  if (hideSeasonSelector) {
    url.searchParams.set('hideSeasonSelector', 'true');
  }

  return url;
}
