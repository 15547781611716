import React from 'react';
import {List} from 'immutable';
import cx from 'classnames';
import {TourTeam} from 'fiba/common/core/models/api/tour/TourTeam';
import {connect} from 'fiba/common/utils/reactUtils';
import {getTourTeams} from 'fiba/wt/stores/tourStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Link} from 'fiba/wt/ui/link/Link';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';

// Base interface
interface ITourTeamsNav {
  tourId: string;
  season: string;
  navBehavior: 'scroll' | 'navigate';
  metaPath?: string;
}

// State from store
interface ITourTeamsNavState {
  teams: List<TourTeam>;
  metaPath: string;
  currentTeamId: string;
  currentTeamName?: string;
}

// Function for selecting a team from dropdown
interface ITourTeamsNavDispatch {
  onSelectInternal: (selectedTeamId: string) => any;
}

type TourTeamsNav = ITourTeamsNav & ITourTeamsNavState & ITourTeamsNavDispatch;

// Get all data from store
const mapStateToProps = (state, {tourId}: ITourTeamsNav): ITourTeamsNavState => ({
  teams: getTourTeams(tourId, state.get('tours'), state.get('tourTeams'))
    .sortBy(team => team.name)
    .toList(),
  metaPath: `tours/__meta/${tourId}/tourTeams`,
  currentTeamId: state.getIn(['route', 'payload', 'teamId'], ''),
  currentTeamName: state.getIn(
    ['tourTeams', state.getIn(['route', 'payload', 'teamId']), 'name'],
    '',
  ),
});

// Function for navigating to selected team and closing dropdown
const mapDispatchToProps = (dispatch, {season}: ITourTeamsNav): ITourTeamsNavDispatch => ({
  /* eslint-disable @typescript-eslint/no-empty-function */
  onSelectInternal: _selectedTeamId => {},
});

const TourTeamsNavMain: React.FunctionComponent<TourTeamsNav> = props => {
  const {
    season,
    teams,
    navBehavior,
    metaPath,
    currentTeamId,
    currentTeamName,
    onSelectInternal,
  } = props;

  return (
    <Loading metaPaths={[metaPath]}>
      {() => (
        <Flex justifyContent="end" debugClassName="TourTeamsNav">
          <Box
            display={['none', 'none', 'block', 'block']}
            width="100"
            pv="2"
            pr="3"
            borderRadius="1"
            bgColor="silver-10"
            extraClassName="overflow-y-auto vh-100"
            debugClassName="TourTeamsNav--Desktop"
          >
            <ul>
              {teams.map(team => (
                <li key={team.id} className="pt2">
                  <a
                    data-external={navBehavior === 'scroll' ? true : undefined}
                    href={`/${season}/teams${navBehavior === 'scroll' ? '#' : '/'}${team.id}`}
                    className={cx('db pa2 pl3 f6 fw7 link link--bare', {
                      'br2 dark-50 bg-silver-20': currentTeamId === team.id,
                      'dark-20 hover-dark-50': currentTeamId !== team.id,
                    })}
                  >
                    {team.name}
                  </a>
                </li>
              ))}
            </ul>
          </Box>

          {/* TODO: Resets/ normalise */}
          <details className="relative w-100 w-66-s db dn-m">
            <summary className="w-100 pv3 ph4 bg-fullwhite shadow-1 pointer">
              <span className="f6 fw7">{currentTeamName || 'Select team'}</span>
            </summary>

            <ul className="absolute left-0 right-0 top-100 pa3 mt1 shadow-2 bg-fullwhite overflow-y-auto max-vh-60 TourTeamsNav-MobilePopout">
              {teams.map(team => (
                <li key={team.id} className="pt2">
                  <Link
                    display="block"
                    href={`/${season}/teams/${team.id}`}
                    extraClassName="pa2"
                    onClick={_ev => onSelectInternal(team.id)}
                  >
                    {team.name}
                  </Link>
                </li>
              ))}
            </ul>
          </details>
        </Flex>
      )}
    </Loading>
  );
};

export const TourTeamsNav = connect<ITourTeamsNavState, ITourTeamsNavDispatch, ITourTeamsNav>(
  mapStateToProps,
  mapDispatchToProps,
)(TourTeamsNavMain);
