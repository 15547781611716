/*
  Event programmability is done through `Event Configurator` model in Contentful.

  As all (not future-proof claim!) models, it is connected to event through `path` descriptor.
  In addition it has the following fields to set publication dates which affect the programmability state:
  - publishDatetime
  - startDatetime
  - endDatetime
  - unpublishDatetime

  Event goes through states `none`, `pre`, `live` and `post` in that order unless incorrectly configured.
  The dates should be configured correspondingly in time. Here's what the states mean:

  - `none`: Before `publishDatetime` setting or when no publication settings is configured for the event
  - `pre`: After `publishDatetime` but before `startDatetime`, this is when the event hype happens
  - `live`: After `startDatetime` but before `endDatetime`, event is now in effect and being played
  - `post`: After `endDatetime`, event is finished

  Here's some ASCII art to further visualize how the dates affect the state:

  v
  v
  v
  |  state = 'none'
  |
  +- publishDatetime begins
  |
  |  state = 'pre'
  |
  +- startDatetime begins
  |
  |  state = 'live'
  |
  +- endDatetime begins
  |
  |  state = 'post'
  |
  +- unpublishDatetime
  |
  |  state = 'post' (unpublishDatetime has no effect to event state, only to tour state (@see TourProgrammability))
  v
  v
  v
*/
import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {ContentTypes, getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {Loading, LoadingStateOperationFunction} from 'fiba/wt/ui/loading/Loading';
import {
  EventProgrammabilityStateProvider,
  EventConfiguration,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';

interface EventProgrammabilityOwnProps {
  eventId: string;
  season: string;
  renderDefault: () => React.ReactElement<any>;
  renderPre?: () => React.ReactElement<any>;
  renderLive?: () => React.ReactElement<any>;
  renderPost?: () => React.ReactElement<any>;
  loadingPlaceholder?: LoadingStateOperationFunction | 'none';
}

interface EventProgrammabilityStateProps {
  metaPaths: string[];
  eventConfiguration?: EventConfiguration;
  overrideNow?: string;
  overrideState?: string;
}

type EventProgrammabilityProps = EventProgrammabilityOwnProps & EventProgrammabilityStateProps;

const mapStateToProps = (
  state,
  {eventId}: EventProgrammabilityOwnProps,
): EventProgrammabilityStateProps => {
  const eventConfiguration = getEventConfiguration(state, eventId);
  const metaPaths = [`content/__meta/${ContentTypes.EventConfigurator}/events/${eventId}`];
  // Yay, more internal secret query params \_(-_-)_/
  const overrideNow = state.getIn(['route', 'payload', '_now']);
  const overrideState = state.getIn(['route', 'payload', '_eventState']);

  return {metaPaths, eventConfiguration, overrideNow, overrideState};
};

const _EventProgrammability: React.FC<EventProgrammabilityProps> = ({
  renderDefault,
  renderPre,
  renderLive,
  renderPost,
  eventConfiguration,
  metaPaths,
  overrideNow,
  overrideState,
  loadingPlaceholder,
}) => (
  <Loading metaPaths={metaPaths} onLoading={loadingPlaceholder || 'none'}>
    {() => (
      <EventProgrammabilityStateProvider
        eventConfiguration={eventConfiguration}
        overrideNow={overrideNow}
        overrideState={overrideState as any}
      >
        {state => {
          switch (state) {
            case 'pre':
              return renderPre ? renderPre() : renderDefault();
            case 'live':
              return renderLive ? renderLive() : renderDefault();
            case 'post':
              return renderPost ? renderPost() : renderDefault();
            default:
              return renderDefault();
          }
        }}
      </EventProgrammabilityStateProvider>
    )}
  </Loading>
);

export const EventProgrammability = connect<
  EventProgrammabilityStateProps,
  {},
  EventProgrammabilityOwnProps
>(mapStateToProps)(_EventProgrammability);
