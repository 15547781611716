import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';

export interface EventGuideProps {
  tourId: string;
  season: string;
  eventId: string;
}

export const EventGuide: React.FunctionComponent<EventGuideProps> = ({eventId}) => (
  <div className="EventGuide">
    <ContentfulArticle contentPath={`events/${eventId}`} articleType={ArticleTypes.EventGuide} />
  </div>
);
