import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {Box} from 'fiba/wt/ui/box/Box';
import {ThemeContextProvider} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {NewsArticle} from 'fiba/wt/ui/newsArticle/NewsArticle';

interface OwnProps {
  newsId: string;
}

interface ReduxProps {
  newsItem: NewsItem;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {newsId}: OwnProps): ReduxProps => {
  const metaPaths = [`news/__meta/full/${newsId}`];
  const newsItem = state.getIn(['news', 'full', newsId]);

  return {
    newsItem,
    metaPaths,
  };
};

const NewsArticlePageImpl: React.FC<Props> = ({newsItem, metaPaths}: Props) => (
  <ThemeContextProvider theme="light">
    <Box debugClassName="NewsArticlePage">
      <Loading metaPaths={metaPaths}>
        {() => (
          <Box ph={['0', '3']} pv={['0', '3']}>
            <NewsArticle newsItem={newsItem} />
          </Box>
        )}
      </Loading>
    </Box>
  </ThemeContextProvider>
);

export const NewsArticlePage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  NewsArticlePageImpl,
);
