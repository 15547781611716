import React, {useContext} from 'react';
import {List} from 'immutable';
import ResultsGroup from 'fiba/common/core/models/api/results/ResultsGroup';
import {getPoolNameLong} from 'fiba/common/core/results';
import {ServicesContext} from 'fiba/common/services/services';
import {areStandingsFinal} from 'fiba/wt/ui/standingsTable/standingsTableHelpers';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {PoolStandings} from 'fiba/wt/ui/poolStandings/PoolStandings';
import {Schedule} from 'fiba/wt/ui/schedule/Schedule';
import {PoolCrossTable} from 'fiba/wt/ui/poolCrossTable/PoolCrossTable';
import {eventTeamLinkProvider, gameLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

interface OwnProps {
  season: string;
  eventId: string;
  eventType: EventType;
  pool: ResultsGroup;
}

type Props = OwnProps;

export const PoolStandingsFullSubTab: React.FC<Props> = ({season, eventId, eventType, pool}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);
  return (
    <Box debugClassName="PoolStandingsFullSubTab">
      <Spacer vSpace="4">
        <Box>
          <PoolStandings
            captionId={`${eventId}-pool-standings`}
            caption={
              <Heading fontSize="3">
                {getPoolNameLong(pool.roundCode, pool.groupPoolCode, pool.roundNumber, localizer)}
                {' - '}
                {areStandingsFinal(pool.groupStandings) ? 'Final standings' : 'Standings'}
              </Heading>
            }
            standings={pool.groupStandings || List()}
            columns={[
              'rank',
              'name',
              'gamesPlayed',
              'gamesWon',
              'winRatio',
              'averagePoints',
              'totalPoints',
            ]}
            createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
          />
        </Box>

        <Spacer vSpace="4" debugClassName="PoolGames">
          <Heading fontSize="3">Games</Heading>
          <Section>
            <Box pb="4">
              <Schedule
                eventId={eventId}
                games={pool.groupGames}
                createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
                createGameHref={gameLinkProvider(seasonConfig, season, eventId, eventType)}
              />
            </Box>
          </Section>
        </Spacer>

        <Box>
          <PoolCrossTable
            pool={pool}
            createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
            caption={
              <Heading textAlign="left" fontSize="3">
                Pool games breakdown
              </Heading>
            }
          />
        </Box>
      </Spacer>
    </Box>
  );
};
