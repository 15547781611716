import React from 'react';
import {Map} from 'immutable';
import {isFunction} from 'lodash';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';
import {connect} from 'fiba/common/utils/reactUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';

export type LoadingStateOperations = 'children' | 'loading' | 'error' | 'none';
export type LoadingStateOperationFunction = (children: () => React.ReactNode) => React.ReactNode;

export interface OwnProps {
  metaPaths: string[];
  fillColor?: string;
  onLoading?: LoadingStateOperations | LoadingStateOperationFunction;
  onLoaded?: LoadingStateOperations | LoadingStateOperationFunction;
  onError?: LoadingStateOperations | LoadingStateOperationFunction;
  onRefreshing?: LoadingStateOperations | LoadingStateOperationFunction;
  onRecovering?: LoadingStateOperations | LoadingStateOperationFunction;
  children: () => React.ReactNode;
}

interface ReduxProps {
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
}

type Props = OwnProps & ReduxProps;
// TODO: Do the MODERN / LEGACY split here as well
const mapStateToProps = (state: Map<string, any>, {metaPaths}: OwnProps): ReduxProps => {
  // Accumulate metadata from the store for each path
  return metaPaths.reduce(
    (acc, metaPath) => {
      // NOTE: We have two code paths here, MODERN and LEGACY
      // MODERN is for those stores that are WebData-based,
      // while LEGACY is for meta-based events. We do a similar split
      // in cacheService.tsx

      // MODERN PATH
      // 'events/__meta/123' -> ['events', '__meta', '123'] -> check if 'events' exists and is WebData based
      // TODO: We currently only check for the 'base' meta being WebData
      const metaPathArr = metaPath.split('/');
      // Destructure the array such that '__meta' is skipped
      const [storeName, , ...remainingPath] = metaPathArr;
      const isWebDataBased = state.getIn([storeName, '__isWebDataBased'], false);

      if (isWebDataBased) {
        // We look in the path minus the meta
        const pathMinusMeta = [storeName, ...remainingPath];
        // Finally, we can get the requestedResource!
        // Get the requested resource, with "NotAsked" as the undefined value
        const requestedResource: RemoteData.WebData<any> = state.getIn(
          pathMinusMeta,
          RemoteData.NotAsked(),
        );

        // If the RemoteData is NotAsked, then
        // this might indicate the controllers were not kicked off
        // or that we have missed something when migrating.
        // We log a console warning for this case
        if (process.env.NODE_ENV !== 'production' && RemoteData.isNotAsked(requestedResource)) {
          /* eslint-disable no-console */
          console.warn(
            `RemoteData is notAsked for path ${pathMinusMeta}.\n` +
              'This can happen if you are loading from a WebData-based store.\n' +
              'First, check whether a Loading with metaPaths is provided for this path. It is likely that you will have to add "__meta" to it, or move to RemoteData.match.\n' +
              'Alternatively, check the cache() calls for this route, and the access in connect().',
          );
        }

        // TODO: Eventually, model this as RemoteData as well, or phase out altogether
        return {
          isLoading: acc.isLoading || RemoteData.isLoading(requestedResource),
          isLoaded: acc.isLoaded || RemoteData.isSuccess(requestedResource),
          isError: acc.isError || RemoteData.isFailure(requestedResource),
        };
      }

      // LEGACY PATH
      const meta = state.getIn(metaPath.split('/'), Map());

      return {
        isLoading: acc.isLoading || meta.get('isLoading'),
        isLoaded: acc.isLoaded && meta.get('isLoaded'),
        isError: acc.isError || meta.get('isError'),
      };
    },
    {
      isLoading: false,
      isLoaded: true,
      isError: false,
    },
  );
};

// Component that will transform loading state to UI state and allows to
// specify the view or operation for each state.
const LoadingImpl: React.FunctionComponent<Props> = ({
  isLoading,
  isLoaded,
  isError,
  fillColor,
  onLoading = 'loading',
  onLoaded = 'children',
  onError = 'error',
  onRefreshing = 'loading',
  onRecovering = 'loading',
  children,
}) => {
  const isRefreshing = isLoaded && isLoading;
  const isRecovering = isError && isLoading;
  let operation;

  // Order of tests here is important so that we can catch `isRefreshing`
  // before either `isLoading` or `isLoaded` of what it is composed of.
  if (isRefreshing) {
    operation = onRefreshing;
  } else if (isRecovering) {
    operation = onRecovering;
  } else if (isLoading) {
    operation = onLoading;
  } else if (isLoaded) {
    operation = onLoaded;
  } else if (isError) {
    operation = onError;
  } else {
    operation = 'none';
  }

  if (isFunction(operation)) {
    return operation(children);
  } else if (operation === 'children') {
    return children();
  } else if (operation === 'loading') {
    return (
      <div className="LoadingIndicator pv5 tc">
        <div className="dib h4 w4 LoadingIndicator-Indicator">
          <LoadingSVG fillColor={fillColor} purpose="standalone" aria-label="Loading" />
        </div>
      </div>
    );
  } else {
    // TODO: Handle 'error'
    return null;
  }
};

export const Loading = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(LoadingImpl);
