import React, {useContext} from 'react';
import {getTeamDisplayName} from 'fiba/wt/core/results';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {Flag} from 'fiba/wt/ui/flag/Flag';
import {UNDEFINED_IOC} from 'fiba/common/utils/Locations';

interface Props {
  team?: Team;
  standing?: ResultsStanding;
  isFlagVisible?: boolean;
  isIocVisible?: boolean;
  isSeedVisible?: boolean;
  isSuffixVisible?: boolean;
  playInRoundCode?: string;
}

interface AssemblyInProps {
  isSeedVisible?: boolean;
  isSuffixVisible?: boolean;
}

interface AssemblyOutProps {
  name?: string;
  seed?: number;
  suffix?: string;
  nationality?: string;
}

const assemblyPropsFromTeam = (team: Team, props: AssemblyInProps): AssemblyOutProps => ({
  name: team && team.name,
  suffix: team && props.isSuffixVisible && team.nameSuffix,
  nationality: team && team.nationality,
});

const assemblyPropsFromStanding = (
  standing: ResultsStanding,
  props: AssemblyInProps,
  localizer: LocalizerService,
  playInRoundCode: string,
): AssemblyOutProps => ({
  name: getTeamDisplayName(standing, localizer, playInRoundCode),
  seed: standing && props.isSeedVisible && standing.teamSeed,
  suffix: standing && props.isSuffixVisible && standing.teamNameSuffix,
  nationality: standing && standing.teamNationality,
});

export const TeamNameAssembly: React.FunctionComponent<Props> = ({
  team,
  standing,
  isFlagVisible,
  isIocVisible = true,
  playInRoundCode,
  ...rest
}) => {
  const {localizer} = useContext(ServicesContext);
  const {name, seed, suffix, nationality} =
    standing !== undefined
      ? assemblyPropsFromStanding(standing, rest, localizer, playInRoundCode)
      : assemblyPropsFromTeam(team, rest);

  return (
    <React.Fragment>
      {!!name && <span>{name}&nbsp;</span>}
      {!!seed && <span className="normal">({seed})&nbsp;</span>}
      {/* NOTE: Make sure suffix doesn't wrap */}
      {!!suffix && <span className="normal i ws-nowrap">{suffix}&nbsp;</span>}
      {/* TODO: When we have theme through context, set the color to pale fg color instead of using opacity */}

      <span className="nowrap">
        {!!nationality && nationality !== UNDEFINED_IOC && isIocVisible && (
          <span className="normal">{nationality}</span>
        )}
        {!!nationality && isFlagVisible && (
          <span className="ml3">
            {/* Include alt text if the suffix is not visible */}
            <Flag ioc={nationality} width={24} includeAlt={!suffix} />
          </span>
        )}
      </span>
    </React.Fragment>
  );
};
