import React from 'react';
import {StyledAPI, StyleProps, createStyleProvider} from 'fiba/wt/ui/stylingAPI/makeStyled';

export type SelectStyleProps = StyleProps<
  StyledAPI<
    | 'color'
    | 'bgColor'
    | 'width'
    | 'border'
    | 'borderRadius'
    | 'borderWidth'
    | 'borderColor'
    | 'ma'
    | 'mv'
    | 'mh'
    | 'ml'
    | 'mt'
    | 'mr'
    | 'mb'
    | 'pa'
    | 'pv'
    | 'ph'
    | 'pl'
    | 'pt'
    | 'pr'
    | 'pb'
  >
>;

// namespace the style props for easier destructuring
interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  styleProps?: SelectStyleProps;
}

const defaultSelectStyleProps: SelectStyleProps = {
  ma: '0',
  pa: '2',
  width: '100',
  borderWidth: '1',
  borderRadius: '1',
  // TODO: think about these
  color: 'inherit',
  bgColor: 'silver-30',
  borderColor: 'silver-40',
};

// Base classes + some not available via the stylingAPI yet
const selectBaseClass = 'Select-Base Select-Pseudo ba hover-bg-silver-40';

const StyleProvider = createStyleProvider<SelectStyleProps>(
  defaultSelectStyleProps,
  selectBaseClass,
);

export const Select: React.FunctionComponent<SelectProps> = ({styleProps, children, ...rest}) => {
  return (
    <StyleProvider {...styleProps}>
      {({className}) => (
        <select className={className} {...rest}>
          {children}
        </select>
      )}
    </StyleProvider>
  );
};
