import React from 'react';
import cx from 'classnames';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Link} from 'fiba/wt/ui/link/Link';
import {Box} from 'fiba/wt/ui/box/Box';
import {ratioToPair} from 'fiba/wt/ui/photoGallery/PhotoGalleryItem';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {LazyImgixImage} from 'fiba/wt/ui/imgix/LazyImgixImage';

export type PhotoGallerySummaryVariant = 'standalone' | 'subgrid';

interface IPhotoGalleryContent {
  title?: string;
  imageUrl?: string;
  galleryUrl?: string;
  index?: number;
  total?: number;
}

interface IPhotoGallerySummary extends IPhotoGalleryContent {
  variant: PhotoGallerySummaryVariant;
}

// Image patterns
interface ImagePattern {
  aspectRatio: StyleProp<'aspectRatio'>;
  className: string;
}
const regularImage: ImagePattern = {aspectRatio: '6x4', className: null};
const tallImage: ImagePattern = {aspectRatio: '4x6', className: 'row-span-2'};
const pattern = [regularImage, tallImage, regularImage, regularImage, tallImage];
const patternLength = pattern.length;

/** Summary of a photo gallery, as unwrapped list items
 *
 * NOTE: Exporting unwrapped (React.Fragment) is important to allow the caller to wrap
 * the contents in the same parent grid, if desired. This is because CSS Grid (and
 * flexbox, and many new CSS things) really want things to be flat. On the other hand,
 * we want them to be semantic, so this is a middle ground.
 */
export const PhotoGallerySummary: React.FC<IPhotoGallerySummary> = ({variant, ...rest}) => {
  switch (variant) {
    case 'standalone':
      return (
        <ul className="grid gt-gallery">
          <PhotoGallerySummaryContent {...rest} />
        </ul>
      );

    case 'subgrid':
      return <PhotoGallerySummaryContent {...rest} />;
  }
};

const PhotoGallerySummaryContent: React.FC<IPhotoGalleryContent> = ({
  title,
  imageUrl,
  galleryUrl,
  total,
  index,
}) => {
  const imagePattern = index ? pattern[index % patternLength] : pattern[0];
  return (
    <React.Fragment>
      {!!title && !!imageUrl && (
        <li className={cx('db', imagePattern.className, galleryUrl)}>
          <Box extraClassName="fill-cell">
            {!!galleryUrl && (
              <Link
                colorTheme="bare"
                extraClassName="relative fill-cell o-fit-cover fullwhite text-shadow-1 hover-b--blue-electric"
                display="block"
                href={galleryUrl}
              >
                <LazyImgixImage
                  // Normally, fill-cell would be undesirable, but in the case of Grid, things are constrained already,
                  // thus fill-cell/ht-100 is good to fill gaps under images
                  containerClassName="fill-cell"
                  className="o-fit-cover"
                  baseUrl={imageUrl}
                  aspectRatio={ratioToPair(imagePattern.aspectRatio)}
                  sizes={['100vh', '50vh', 'calc((96rem - 16rem) / 3)']}
                  // TODO: most of these descriptions do not actually describe these image :(
                  alt=""
                />
                <Box
                  position="absolute"
                  height="4"
                  extraClassName={cx('left-0 bottom-0 right-0 bottom-gradient')}
                />
                <Box position="absolute" extraClassName="bottom-1 left-1">
                  <SubHeading>{title}</SubHeading>
                </Box>
                <Box position="absolute" extraClassName="top-1 left-1">
                  <p>{total}</p>
                </Box>
              </Link>
            )}
          </Box>
        </li>
      )}
    </React.Fragment>
  );
};
