import React, {useContext} from 'react';
import {SidebarSectionHeading} from 'fiba/wt/ui/sidebarSection/SidebarSection';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';
import {EventRightSidebarWrapper} from 'fiba/wt/ui/eventLayout/EventLayout';
import {Box} from 'fiba/wt/ui/box/Box';
import {EventSeedings} from 'fiba/wt/ui/eventSeedings/EventSeedings';
import {connect} from 'fiba/common/utils/reactUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import Team from 'fiba/common/core/models/api/teams/Team';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {StyledLink} from '../link/Link';
import {List, Map} from 'immutable';
import ResultsStanding from 'fiba/common/core/models/api/results/ResultsStanding';
import {eventTeamLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from '../siteConfigContext/SiteConfigContext';
import {getEventType} from 'fiba/wt/stores/eventStore';

interface Props {
  season: string;
  eventId: string;
}

/** Sidebar that is typically shown in the Overview page, and in any case depends on the Event State */
export const EventSidebarRight: React.FC<Props> = ({eventId, season}) => {
  return (
    <EventProgrammability
      eventId={eventId}
      season={season}
      renderDefault={() => null}
      renderPre={() => (
        <EventRightSidebarWrapper>
          <aside className="w-100">
            <Box
              shadow="1"
              width="100"
              bgColor="fullwhite"
              color="dark-50"
              debugClassName="EventSidebarRight"
            >
              <Box ph="3" pt="3">
                <SidebarSectionHeading>Teams</SidebarSectionHeading>
              </Box>
              <EventSeedingsOrTeamList eventId={eventId} season={season} />
            </Box>
          </aside>
        </EventRightSidebarWrapper>
      )}
    />
  );
};

//
// Event Seedings / Team List view

interface EventSeedingsOrTeamListReduxProps {
  standingsData: RemoteData.WebData<List<ResultsStanding>>;
  eventTeamsRelatedData: RemoteData.WebData<{eventTeams: List<Team>; eventType: EventType}>;
}

/**
 * Show either the event seedings or team list, depending on whether
 * we have standings (seed) data.
 *
 * So, the loading here sucks:
 * We only show them eventTeams in case we do not have standings,
 * so we must wait until standings are loaded before we judge them to be empty.
 * If they are, we wait until the even teams have loaded.
 * So yeah, kind of implicit, and assumes that we load an event's teams in pre- state
 * If you come here from a future error, check that the controller for this route
 * has loaded the event teams data.
 */
const EventSeedingsOrTeamList = connect<EventSeedingsOrTeamListReduxProps, {}, Props>(
  (state, {eventId}) => {
    // NOTE: We assume that if categories exists in the event's meta,
    // then they are loaded. This is ok, because that is how the store works.
    // To make it right, we should move this to RemoteData once the category
    // store is RemoteData- based
    const firstCategoryIdData = RemoteData.map(
      state.getIn(
        ['events', '__meta', eventId, 'categories'],
        RemoteData.NotAsked(),
      ) as RemoteData.WebData<List<string>>,
      categoryIds => categoryIds.first(),
    );

    // Sequence the data: we need to load the category data (to get the id)
    // then we need to get the standings
    // We could have written a RemoteData.andThen helper for this
    const standingsData = RemoteData.match(firstCategoryIdData, {
      Success: firstCategoryId => {
        // Get the loading state of standings
        return RemoteData.map(
          RemoteData.fromMetaObject(
            state.getIn(['results', '__meta', firstCategoryId], Map()).toJS(),
          ),
          // Get the standings themselves
          () => {
            return state.getIn(['results', firstCategoryId, 'standings'], List());
          },
        ) as RemoteData.WebData<List<ResultsStanding>>;
      },
      default: () => firstCategoryIdData as RemoteData.WebData<List<ResultsStanding>>,
    });

    // Get the teams in case we have to show them
    const eventTeamsData = RemoteData.map(
      state.getIn(
        ['events', '__meta', eventId, 'teams'],
        RemoteData.NotAsked(),
      ) as RemoteData.WebData<List<string>>,
      teamIds =>
        teamIds
          // NOTE: We get from the state directly because we assume/know they are loaded
          // if the refs in __meta are loaded. This could be wrong! We should change this once
          // teamStore is WebData- based as well
          .map(teamId => state.getIn(['teams', teamId]) as Team | null)
          .filter(team => !!team)
          .toList(),
    );

    // We need to know whether the event is a challenger, to show the correct link
    const eventTypeData = getEventType(state.get('events'), eventId);

    // Merge data related to event teams into one
    const eventTeamsRelatedData = RemoteData.map2(
      eventTeamsData,
      eventTypeData,
      (eventTeams, eventType) => ({
        eventTeams,
        eventType,
      }),
    );

    return {standingsData, eventTeamsRelatedData};
  },
)(({eventTeamsRelatedData, standingsData, season, eventId}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <ViewWebData data={standingsData}>
      {standings => {
        if (standings.size === 0) {
          return (
            <ViewWebData data={eventTeamsRelatedData}>
              {({eventTeams, eventType}) => (
                <Box pa="3">
                  <ul className="vs3">
                    {eventTeams.map(team => (
                      <li key={team.id}>
                        <StyledLink
                          href={eventTeamLinkProvider(
                            seasonConfig,
                            season,
                            eventId,
                            eventType,
                          )(team.id)}
                        >
                          <TeamNameAssembly team={team} isSuffixVisible={true} />
                        </StyledLink>
                      </li>
                    ))}
                  </ul>
                </Box>
              )}
            </ViewWebData>
          );
        }
        return (
          <EventSeedings
            season={season}
            eventId={eventId}
            columns={['seed', 'name']}
            cellSpacing="2"
            hideHeading={true}
          />
        );
      }}
    </ViewWebData>
  );
});
