import {fromJS, List, Map} from 'immutable';
import {mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {FastestGame, FastestGameLike} from 'fiba/common/core/models/api/fastestGame/FastestGame';
import EventStatsSummary, {
  EventStatsSummaryLike,
} from 'fiba/common/core/models/api/stats/EventStatsSummary';
import TourStatsSummary, {
  TourStatsSummaryLike,
} from 'fiba/common/core/models/api/stats/TourStatsSummary';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';

export interface StatsStoreState extends Map<string, Map<string, List<FastestGame>>> {
  /*
    Not really a Map, rather a Record.
    Here is the full structure for when we type this:

    tourFastestGames: Map<TourId, List<FastestGame>>;
    tourStatsSummary: Map<TourId, TourStatsSummary>;
    proCircuitStatsSummary: Map<TourId, TourStatsSummary>;
    eventFastestGames: Map<EventId, List<FastestGame>> OR Map<EventId, Map<Gender, List<FastestGame>>>
    eventStatsSummary: Map<EventId, EventStatsSummary>;
    __meta: {
      tourFastestGames: MetaStore<TourId>;
      tourStatsSummary: MetaStore<TourId>;
      proCircuitStatsSummary: MetaStore<TourId>;
      eventFastestGames: MetaStore<EventId>;
      eventStatsSummary: MetaStore<EventId>;
    };

    And some helpers:
    type TourId = string;
    type EventId = string;

    type MetaStore<Key> = Map<Key, MetaState>;

    interface MetaState {
      isLoaded: boolean;
      isLoading: boolean;
      isError: boolean;
    }
  */
}

//
// Reducers
const reducers = {
  loadEventStatsSummary: (state: StatsStoreState, eventId: string): StatsStoreState =>
    state.updateIn(['__meta', 'eventStatsSummary', eventId], setMeta.isLoading),
  // TODO: these success & error reducers and actions can be more generic
  loadEventStatsSummarySuccess: (
    state: StatsStoreState,
    eventId: string,
    statsSummary: EventStatsSummaryLike,
  ): StatsStoreState =>
    state
      .setIn(['eventStatsSummary', eventId], EventStatsSummary.fromJS(statsSummary))
      .updateIn(['__meta', 'eventStatsSummary', eventId], setMeta.isLoaded),
  loadEventStatsSummaryError: (state: StatsStoreState, eventId: string, error): StatsStoreState =>
    state.updateIn(['__meta', 'eventStatsSummary', eventId], setMeta.isError),
  //
  // Tour Stats Summary
  loadTourStatsSummary: (state: StatsStoreState, tourId: string): StatsStoreState =>
    state.updateIn(['__meta', 'tourStatsSummary', tourId], setMeta.isLoading),
  // TODO: these success & error reducers and actions can be more generic
  loadTourStatsSummarySuccess: (
    state: StatsStoreState,
    tourId: string,
    statsSummary: TourStatsSummaryLike,
  ): StatsStoreState =>
    state
      .setIn(['tourStatsSummary', tourId], TourStatsSummary.fromJS(statsSummary))
      .updateIn(['__meta', 'tourStatsSummary', tourId], setMeta.isLoaded),
  loadTourStatsSummaryError: (state: StatsStoreState, tourId: string, error): StatsStoreState =>
    state.updateIn(['__meta', 'tourStatsSummary', tourId], setMeta.isError),
  //
  // ProCircuit Stats Summary
  loadProCircuitStatsSummary: (state: StatsStoreState, tourId: string): StatsStoreState =>
    state.updateIn(['__meta', 'proCircuitStatsSummary', tourId], setMeta.isLoading),
  // TODO: these success & error reducers and actions can be more generic
  loadProCircuitStatsSummarySuccess: (
    state: StatsStoreState,
    tourId: string,
    statsSummary: TourStatsSummaryLike,
  ): StatsStoreState =>
    state
      .setIn(['proCircuitStatsSummary', tourId], TourStatsSummary.fromJS(statsSummary))
      .updateIn(['__meta', 'proCircuitStatsSummary', tourId], setMeta.isLoaded),
  loadProCircuitStatsSummaryError: (
    state: StatsStoreState,
    tourId: string,
    error,
  ): StatsStoreState =>
    state.updateIn(['__meta', 'proCircuitStatsSummary', tourId], setMeta.isError),
  //
  // Tour Fastest Games
  loadTourFastestGames: (state: StatsStoreState, season: string, tourId: string): StatsStoreState =>
    // NOTE: We need season here, because this thing is called with (season, tourId) in the actions,
    // but we only want tourId. An alternative would be to accept {season, tourId}, to disambiguate.
    // This bug (argument order) is very sneaky otherwise.
    state.updateIn(['__meta', 'tourFastestGames', tourId], setMeta.isLoading),

  loadTourFastestGamesSuccess: (
    state: StatsStoreState,
    tourId: string,
    fastestGames: FastestGameLike[],
  ): StatsStoreState =>
    state
      .setIn(['tourFastestGames', tourId], List(fastestGames.map(FastestGame.fromJS)))
      .updateIn(['__meta', 'tourFastestGames', tourId], setMeta.isLoaded),

  loadTourFastestGamesError: (state: StatsStoreState, tourId: string, error): StatsStoreState =>
    state.updateIn(['__meta', 'tourFastestGames', tourId], setMeta.isError),

  //
  // Event Fastest Games
  loadEventFastestGames: (state: StatsStoreState, eventId: string): StatsStoreState =>
    state.updateIn(['__meta', 'eventFastestGames', eventId], setMeta.isLoading),

  loadEventFastestGamesSuccess: (
    state: StatsStoreState,
    eventId: string,
    fastestGames: FastestGameLike[],
    gender: CategoryGenderTab,
    eventCategoriesByGender,
  ): StatsStoreState => {
    if (!gender) {
      return state
        .setIn(['eventFastestGames', eventId], List(fastestGames.map(FastestGame.fromJS)))
        .updateIn(['__meta', 'eventFastestGames', eventId], setMeta.isLoaded);
    }

    const [menGames, womenGames] = fastestGames.reduce(
      ([menGames, womenGames], game) => {
        if (game.categoryId === eventCategoriesByGender.Female) {
          return [menGames, [...womenGames, game]];
        } else if (game.categoryId === eventCategoriesByGender.Male) {
          return [[...menGames, game], womenGames];
        }
      },
      [[], []],
    );
    return state
      .setIn(['eventFastestGames', eventId, 'men'], List(menGames))
      .setIn(['eventFastestGames', eventId, 'women'], List(womenGames))
      .updateIn(['__meta', 'eventFastestGames', eventId], setMeta.isLoaded);
  },

  loadEventFastestGamesError: (state: StatsStoreState, eventId: string, error): StatsStoreState =>
    state.updateIn(['__meta', 'eventFastestGames', eventId], setMeta.isError),
};

export const reducer = createReducer<StatsStoreState>(__filename, fromJS({}), reducers);

//
// Actions
const actions = createActions(__filename, reducers, {
  loadEventStatsSummary: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadEventStatsSummary(eventId)
      .then(res => actions.loadEventStatsSummarySuccess(eventId, res.data))
      .catch(rethrow(err => actions.loadEventStatsSummaryError(eventId, err))),

  loadTourStatsSummary: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTourStatsSummary(tourId)
      .then(res => actions.loadTourStatsSummarySuccess(tourId, res.data))
      .catch(rethrow(err => actions.loadTourStatsSummaryError(tourId, err))),

  loadProCircuitStatsSummary: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadProCircuitStatsSummary(tourId)
      .then(res => actions.loadProCircuitStatsSummarySuccess(tourId, res.data))
      .catch(rethrow(err => actions.loadProCircuitStatsSummaryError(tourId, err))),

  loadSeasonFastestGames: (season: string, tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadFastestGamesBySeason(season)
      .then(res => actions.loadTourFastestGamesSuccess(tourId, res.data.games))
      .catch(rethrow(err => actions.loadTourFastestGamesError(tourId, err))),

  loadEventFastestGames: (
    eventId: string,
    gender?: CategoryGenderTab,
    eventCategoriesByGender?,
  ) => ({apiClient}: CommonServices) =>
    apiClient
      .loadFastestGamesByEvent(eventId)
      .then(res =>
        actions.loadEventFastestGamesSuccess(
          eventId,
          res.data.games,
          gender,
          eventCategoriesByGender,
        ),
      )
      .catch(rethrow(err => actions.loadEventFastestGamesError(eventId, err))),

  loadTourFastestGames: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadFastestGamesByTour(tourId)
      .then(res => actions.loadTourFastestGamesSuccess(tourId, res.data.games))
      .catch(rethrow(err => actions.loadEventFastestGamesError(tourId, err))),
});

export default actions;

/** JSON -> StatsStoreState
  tourFastestGames: {
    1234: [FastestGameLike, FastestGameLike] -> List<FastestGame>
  } -> Map<TourId, List<FastestGame>>;
  tourStatsSummary: {
    1357: TourStatsSummaryLike -> TourStatsSummary
  } -> Map<TourId, TourStatsSummary>;
  proCircuitStatsSummary: {
    1357: TourStatsSummaryLike -> TourStatsSummary
  } -> Map<TourId, TourStatsSummary>;
  eventFastestGames: {
    3456: [FastestGameLike, FastestGameLike] -> List<FastestGame>
  } -> Map<EventId, List<FastestGame>>;

  --> Map<string, Map<string, List<FastestGame>>>

  __meta: {
    tourFastestGames: {
      1234: MetaState
    } -> MetaStore<TourId>;
    tourStatsSummary: {
      1357: MetaState
    } -> MetaStore<TourId>
    proCircuitStatsSummary: {
      1357: MetaState
    } -> MetaStore<TourId>
    eventFastestGames: {
      3456: MetaState
    } -> MetaStore<EventId>
    eventStatsSummary: {
      7890: MetaState
    } -> MetaStore<EventId>
  } -> ???

  --> StatsStoreState
*/
export function deserializeState(state): StatsStoreState {
  const {
    __meta,
    eventStatsSummary,
    eventFastestGames,
    tourStatsSummary,
    proCircuitStatsSummary,
    tourFastestGames,
  } = state;

  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail on chrome
    __meta: __meta || {},
    eventStatsSummary: mapValues(eventStatsSummary, EventStatsSummary.fromJS),
    tourStatsSummary: mapValues(tourStatsSummary, TourStatsSummary.fromJS),
    proCircuitStatsSummary: mapValues(proCircuitStatsSummary, TourStatsSummary.fromJS),
    tourFastestGames: mapValues(tourFastestGames, tfgs => tfgs.map(FastestGame.fromJS)),
    eventFastestGames: mapValues(eventFastestGames, efgs => {
      if (Array.isArray(efgs)) {
        return efgs.map(FastestGame.fromJS);
      }
      // For games categorized by gender
      return Object.entries(efgs).reduce((acc, [key, value]: [string, FastestGame]) => {
        acc[key] = value.map(FastestGame.fromJS);
        return acc;
      }, {});
    }),
  });
}
