import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {eventRootLink, eventScheduleLink, EventType} from 'fiba/wt/utils/linkUtils';
import {EventCountdown} from 'fiba/wt/ui/eventCountdown/EventCountdown';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {GhostLink} from 'fiba/wt/ui/link/Link';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';
import {getEventType} from 'fiba/wt/stores/eventStore';

// Props from caller
interface OwnProps {
  eventId: string;
  season: string;
}

// Derived props from Redux
interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
}

// Connect to Redux
const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  return {eventTypeData: getEventType(state.get('events'), eventId)};
};

/** Implementation with all props */
const _EventPortalPre: React.SFC<OwnProps & ReduxProps> = ({eventId, season, eventTypeData}) => {
  const {seasonConfig, theme} = useContext(SiteConfigContext);
  return (
    <React.Fragment>
      {RemoteData.match(eventTypeData, {
        Success: eventType => (
          <>
            <EventCountdown eventId={eventId} season={season} />
            <Flex
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
              maxWidth="6"
              align="center"
              pt={['4', '4', '1']}
            >
              <GhostLink
                variant={theme.ghostlinkTheme}
                extraClassName="flex-auto ma1"
                href={eventRootLink(seasonConfig, season, eventId, eventType)}
              >
                Go to <span className="db fw7">Event Overview</span>
              </GhostLink>
              <GhostLink
                variant={theme.ghostlinkTheme}
                extraClassName="flex-auto ma1"
                href={eventScheduleLink(seasonConfig, season, eventId, eventType)}
              >
                Go to <span className="db fw7">Event Schedule</span>
              </GhostLink>
            </Flex>
          </>
        ),
        Loading: () => (
          <div className="LoadingIndicator pv5 tc">
            <div className="dib h4 w4 LoadingIndicator-Indicator">
              <LoadingSVG purpose="standalone" />
            </div>
          </div>
        ),
        Failure: () => null,
        NotAsked: () => null,
      })}
    </React.Fragment>
  );
};

/** Event Portal in Pre state */
export const EventPortalPre = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventPortalPre);
