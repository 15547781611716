import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {connect} from 'react-redux';
import {Map} from 'immutable';
import {preventDefault} from 'fiba/common/utils/reactUtils';
import {getWinPercentage, getAveragePoints} from 'fiba/common/core/results';
import SimpleLink from 'fiba/common/ui/simpleLink/SimpleLink';
import {isEmbed} from 'fiba/common/services/routerService';
import uiConfigActions from 'fiba/common/stores/uiConfigStore';
import {ServicesContext} from 'fiba/common/services/services';

const COLLAPSED_TABLE_LENGTH = 10;

const getTourStop = (event, standing) =>
  standing
    .get('tourStops')
    .filter(stop => stop.get('eventId') === event.get('eventId'))
    .first();

const getTourStopStanding = (tourStop, localizer) => {
  return tourStop && tourStop.get('standing')
    ? localizer.formatKey('POSITION_ORDINAL', {position: tourStop.get('standing')})
    : null;
};

const getTourStopStandingPoints = tourStop => {
  return tourStop && tourStop.get('standingPoints')
    ? tourStop.get('standingPoints') + ' pts'
    : null;
};

const getTourStandingQualified = (standing, localizer) => {
  const qualification = standing.get('qualification');
  if (qualification === 'Qualified') {
    return localizer.formatKey('TOUR_QUALIFIED_SHORT');
  } else if (qualification === 'Shortlisted') {
    return localizer.formatKey('TOUR_SHORTLISTED_SHORT');
  }
};

export const TourPlayerStandings = ({
  category,
  isExpanded,
  isEmbed,
  toggleExpand,
  translations,
}) => {
  const {localizer} = useContext(ServicesContext);
  const events = category.get('events');
  const standings = isExpanded
    ? category.get('playerStandings')
    : category.get('playerStandings').take(COLLAPSED_TABLE_LENGTH);

  const tourEvents = events.filter(event => !event.get('eventIsFinal'));
  const finalEvent = events.filter(event => event.get('eventIsFinal')).first();

  return (
    <div className="TourPlayerStandings">
      <div className="TourPlayerStandings-Table Fiba-HorizontalScrollWrapper">
        <table className="Fiba-ResultsTable">
          <thead>
            <tr>
              <th className="Fiba-ResultsTable-minimal" />
              <th>{translations.get('PLAYER')}</th>
              {!!finalEvent && (
                <th className="Fiba-ResultsTable-centered" key={finalEvent.get('eventId')}>
                  {finalEvent.get('externalUrl') ? (
                    <SimpleLink isExternal href={finalEvent.get('externalUrl')}>
                      {finalEvent.get('eventName')}
                    </SimpleLink>
                  ) : (
                    finalEvent.get('eventName')
                  )}
                </th>
              )}
              <th className="Fiba-ResultsTable-centered">
                {translations.get('RESULTS_HEADER_TOUR_POINTS')}
              </th>
              {tourEvents.map(event => (
                <th className="Fiba-ResultsTable-centered" key={event.get('eventId')}>
                  {event.get('externalUrl') ? (
                    <SimpleLink isExternal href={event.get('externalUrl')}>
                      {event.get('eventName')}
                    </SimpleLink>
                  ) : (
                    event.get('eventName')
                  )}
                </th>
              ))}
              <th className="Fiba-ResultsTable-centered">
                {translations.get('RESULTS_HEADER_GAMES_PLAYED')}
              </th>
              <th className="Fiba-ResultsTable-centered">
                {translations.get('RESULTS_HEADER_GAMES_WON')}
              </th>
              <th className="Fiba-ResultsTable-centered">
                {translations.get('RESULTS_HEADER_WIN_PERCENTAGE')}
              </th>
              <th className="Fiba-ResultsTable-centered">
                {translations.get('RESULTS_HEADER_AVERAGE_POINTS')}
              </th>
            </tr>
          </thead>
          <tbody>
            {standings.map(
              standing =>
                standing.get('gamesPlayed') > 0 && (
                  <tr key={standing.get('playerId')}>
                    <td className="Fiba-ResultsTable-numeric">
                      {!!standing.get('playerRank') && `${standing.get('playerRank')}.`}
                    </td>
                    <td>
                      <SimpleLink
                        isExternal={isEmbed}
                        href={`/players/${standing.get('playerId')}`}
                      >
                        {`${standing.get('playerFirstName')} ${standing.get('playerLastName')}`}
                      </SimpleLink>
                    </td>
                    {!!finalEvent && (
                      <td className="Fiba-ResultsTable-centered" key={finalEvent.get('eventId')}>
                        <div>
                          {getTourStopStanding(getTourStop(finalEvent, standing), localizer) ||
                            getTourStandingQualified(standing, localizer)}
                        </div>
                        <div className="Fiba-ResultsTable-faint">
                          {getTourStopStandingPoints(getTourStop(finalEvent, standing))}
                        </div>
                      </td>
                    )}
                    <td className="Fiba-ResultsTable-centered">
                      {standing.get('totalStandingPoints')}
                    </td>
                    {tourEvents.map(event => {
                      const tourStop = getTourStop(event, standing);
                      return (
                        <td className="Fiba-ResultsTable-centered" key={event.get('eventId')}>
                          <div>
                            {getTourStopStanding(tourStop, localizer)}
                            {tourStop &&
                              tourStop.get('isQualified') &&
                              ' (' + localizer.formatKey('TOUR_QUALIFIED_SHORT') + ')'}
                          </div>
                          <div className="Fiba-ResultsTable-faint">
                            {getTourStopStandingPoints(tourStop)}
                          </div>
                        </td>
                      );
                    })}
                    <td className="Fiba-ResultsTable-centered">{standing.get('gamesPlayed')}</td>
                    <td className="Fiba-ResultsTable-centered">{standing.get('gamesWon')}</td>
                    <td className="Fiba-ResultsTable-centered">
                      {getWinPercentage(standing, localizer)}
                    </td>
                    <td className="Fiba-ResultsTable-centered">
                      {getAveragePoints(standing, localizer)}
                    </td>
                  </tr>
                ),
            )}
          </tbody>
        </table>
      </div>

      <p className="TourPlayerStandings-Expand">
        <button onClick={preventDefault(() => toggleExpand(!isExpanded))}>
          {translations.get(isExpanded ? 'COLLAPSE_ACTION_LABEL' : 'EXPAND_ACTION_LABEL')}
        </button>
      </p>

      {!!finalEvent && (
        <p className="TourPlayerStandings-Legend">
          {localizer.formatKey('TOUR_QUALIFIED_LEGEND')},
          {localizer.formatKey('TOUR_SHORTLISTED_LEGEND')}
        </p>
      )}
    </div>
  );
};

TourPlayerStandings['propTypes'] = {
  tourId: PropTypes.string.isRequired,
  category: PropTypes.instanceOf(Map as any).isRequired,

  isExpanded: PropTypes.bool,
  isEmbed: PropTypes.bool,
  translations: PropTypes.instanceOf(Map as any).isRequired,

  toggleExpand: PropTypes.func.isRequired,
};

export const mapProps = (state, {tourId, category}) => ({
  isExpanded: state.getIn([
    'uiConfig',
    'isTourPlayerStandingsExpanded',
    tourId,
    category.get('parentCategoryId'),
  ]),
  isEmbed: isEmbed(state.getIn(['route', 'pathname'])),
  translations: state.get('translations'),
});

export const mapDispatch = (dispatch, {tourId, category}) => ({
  toggleExpand: isExpanded =>
    dispatch(
      uiConfigActions.setUiConfigValue(
        ['isTourPlayerStandingsExpanded', tourId, category.get('parentCategoryId')],
        isExpanded,
      ),
    ),
});

export default connect(mapProps, mapDispatch)(TourPlayerStandings);
