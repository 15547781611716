import FastestGame from 'fiba/common/core/models/api/fastestGame/FastestGame';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {isNumber} from 'lodash';

// TODO: we could localize this if FastestGames had GameNumber
// In some cases we want to retain the game number when displaying Play-In games
// This function retains the game number in Play-in games' names when the game number is not available in the data itself
export function formatGameName(game: ResultsGameDetails | FastestGame) {
  if (game.gameIsPlayIn) {
    // Get the game number from the game name if it exists
    // Example Pool A game 1 => 1
    const r = /\s(\d*)$/;
    const gameNumberString = r.exec(game.gameName)[1];
    return isNumber(parseInt(gameNumberString)) ? `Play-In game ${gameNumberString}` : 'Play-In';
  }
  return game.gameName;
}
