import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {eventTeamLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {SeedingsTableColumns, CategorySeedings} from 'fiba/wt/ui/categorySeedings/CategorySeedings';
import {Category} from 'fiba/common/core/models/api/events/Category';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';

interface OwnProps {
  season: string;
  eventId: string;
  columns?: SeedingsTableColumns[];
  cellSpacing?: StyleProp<'pv'>;
  hideHeading?: boolean;
  categoryIdFilter?: List<string>;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    eventType: EventType;
    categories: List<Category>;
  }>;
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {eventId, categoryIdFilter}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);

  // NOTE: We assume that if categories exists in the event's meta,
  // then they are loaded. This is ok, because that is how the store works.
  // To make it right, we should move this to RemoteData once the category
  // store is RemoteData- based
  const categoriesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds
        .filter(categoryId => !categoryIdFilter || categoryIdFilter.includes(categoryId))
        .map(categoryId => state.getIn(['categories', categoryId]))
        .filter(category => !!category) as List<Category>,
  );

  // Merge the data so that they succeed or fail together
  const data = RemoteData.map2(eventTypeData, categoriesData, (eventType, categories) => ({
    eventType,
    categories,
  }));

  return {data};
};

export const _EventSeedings: React.FC<Props> = ({
  season,
  eventId,
  columns,
  cellSpacing,
  hideHeading,
  data,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <Spacer vSpace="3" debugClassName="EventSeedings">
      {/* TODO: consider whether this should be the caption for CategorySeedingsTable */}
      {!hideHeading && <Heading fontSize="3">Teams</Heading>}

      <ViewWebData data={data}>
        {({eventType, categories}) =>
          categories.map(category => (
            <Box key={category.id}>
              <CategorySeedings
                categoryId={category.id}
                columns={columns}
                showCategoryName={categories.size > 1}
                createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
                cellSpacing={cellSpacing}
              />
            </Box>
          ))
        }
      </ViewWebData>
    </Spacer>
  );
};

export const EventSeedings = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventSeedings);
