import React from 'react';
import ReactDOM from 'react-dom';

import App from 'fiba/common/ui/App';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {routerServerRenderHydrated, RouteParams} from 'fiba/common/services/routerService';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

function runClient(
  services: CommonServices,
  siteConfig?: ISiteConfig,
  initialPayload: RouteParams['extraPayload'] = {},
) {
  const {store, router} = services;

  const state = store.getState();
  const location = state.has('route') && state.get('route').toJS();
  const el = document.querySelector('.FibaPlayApp');

  return router
    .browser(location, initialPayload, siteConfig)
    .then(route =>
      ReactDOM.hydrate(<App store={store} services={services} siteConfig={siteConfig} />, el, () =>
        // After hydrating, let the store know that we have hydrated server markup
        // This is useful for focus funtionality
        store.dispatch(routerServerRenderHydrated()),
      ),
    )
    .then(() => el);
}

export default runClient;
