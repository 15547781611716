import React from 'react';
import {List, Map} from 'immutable';
import {Category} from 'fiba/common/core/models/api/events/Category';
import {Court} from 'fiba/common/core/models/api/events/Court';
import {ScheduleFilterType, mapGamesToTeams} from 'fiba/wt/ui/schedule/scheduleUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import {Select} from 'fiba/wt/ui/select/Select';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';

interface ScheduleFiltersProps {
  id: string;
  filterState: Map<string, string>;
  games: List<ResultsGameSummary>;
  categories: List<Category>;
  courts: List<Court>;
  filters: ScheduleFilterType[];
  setFilterValue: (id: string, filterType: string, value: string) => any;
}

export const ScheduleFilters: React.FunctionComponent<ScheduleFiltersProps> = ({
  id,
  games,
  filterState,
  categories,
  courts,
  filters,
  setFilterValue,
}) => {
  const teams = mapGamesToTeams(games);

  return (
    <Box color="dark-50" extraClassName="ScheduleFilters vs3">
      {filters.indexOf('category') >= 0 && categories.size > 1 && (
        <div className="ScheduleFilters-Category dib miw5 mr4">
          <label>
            <span className="db mb2 fw7">Filter by category:</span>
            <Select
              value={filterState.get('category') || ''}
              onChange={ev => {
                const selectedTeamsCategory = filterState.get('teamsCategory') || '';
                setFilterValue(id, 'category', ev.target.value);
                if (ev.target.value !== '' && ev.target.value !== selectedTeamsCategory) {
                  setFilterValue(id, 'teamsCategory', '');
                  setFilterValue(id, 'team', '');
                }
              }}
            >
              <option value="">All categories</option>

              {categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </Select>
          </label>
        </div>
      )}

      {filters.indexOf('court') >= 0 && courts.size > 1 && (
        <div className="ScheduleFilters-Court dib pa2 miw5 mr4">
          <Select
            value={filterState.get('court') || ''}
            onChange={ev => setFilterValue(id, 'court', ev.target.value)}
          >
            <option value="">All courts</option>

            {courts.map(court => (
              <option key={court.id} value={court.id}>
                {court.name}
              </option>
            ))}
          </Select>
        </div>
      )}

      {filters.indexOf('team') >= 0 && (
        <div className="ScheduleFilters-Team dib miw5 mr4">
          <label>
            <span className="db mb2 fw7">Filter by team:</span>
            <Select
              value={filterState.get('team') || ''}
              data-category-id={filterState.get('teamsCategory') || ''}
              onChange={ev => {
                setFilterValue(id, 'team', ev.target.value);
                setFilterValue(id, 'teamsCategory', ev.target.getAttribute('data-category-id'));
              }}
            >
              <option value="">All teams</option>

              {teams.map(team => {
                const text =
                  categories.size > 1 ? `${team.teamName} ${team.categoryName}` : team.teamName;
                const isDisabled =
                  team.categoryId !== filterState.get('category') &&
                  filterState.get('category') !== '' &&
                  filterState.get('category') !== undefined;

                if (!isDisabled) {
                  return (
                    <option
                      key={team.teamId}
                      value={team.teamId}
                      data-category-id={team.categoryId}
                    >
                      {text}
                    </option>
                  );
                }
              })}
            </Select>
          </label>
        </div>
      )}
    </Box>
  );
};
