import PlayerActions from 'fiba/wt/stores/playerStore';
import CommonServices from 'fiba/wt/core/models/CommonServices';

function playerController(playerId: string) {
  return ({dispatch, cache}: CommonServices) => {
    return cache(['players', playerId], () => dispatch(PlayerActions.loadPlayer(playerId)));
  };
}

export default playerController;
