import React from 'react';
import cx from 'classnames';
import {connect, assetPath} from 'fiba/common/utils/reactUtils';
import {getTourEvents, getTourChallengers} from 'fiba/wt/stores/tourStore';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Nav} from 'fiba/wt/ui/elements/Nav';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {
  Navigation,
  NavigationContentRow,
  Tab,
  defaultNavLinkStyle,
} from 'fiba/wt/ui/navigation/Navigation';
import {SeasonSelect} from 'fiba/wt/ui/tourLayout/SeasonSelect';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {List} from 'immutable';
import {getEventConfigurationForTour} from 'fiba/wt/stores/contentStore';
import {
  TourProgrammabilityState,
  getTourProgrammabilityState,
} from 'fiba/wt/ui/tourProgrammability/TourProgrammabilityStateProvider';
import {eventRootLink} from 'fiba/wt/utils/linkUtils';
import {ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {
  isLegacySeason,
  getTourStatsVisibility,
  findActiveEvent,
  getEventIdFromConfiguration,
} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import Event from 'fiba/common/core/models/api/events/Event';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {isEmpty} from 'lodash';
import {RootState} from 'fiba/wt/stores/rootStore';
import moment from 'moment';

interface OwnProps {
  tourId: string;
  season: string;
  siteConfig: ISiteConfig;
  eventId?: string;
  tourTab?: string;
  extraClassName?: string;
}

interface ReduxProps {
  eventTabsData: RemoteData.WebData<Tab[]>;
  events: RootState['events'];
  eventConfiguration?: EventConfiguration[];
  overrideNow?: string;
}

// Route utils
const makeEventTabs = (
  seasonConfig: ISeasonConfig,
  season: string,
  currentEventId: string,
  events: List<Event>,
  upcomingEventId: string,
): Tab[] => {
  return events
    .map(({name, shortName, id}) => ({
      name: shortName || name,
      // We only have Masters here
      href: eventRootLink(seasonConfig, season, id, 'WorldTour'),
      active: currentEventId && currentEventId === id,
      shouldBeScrolledTo: upcomingEventId === id,
    }))
    .toJS();
};

export const mapStateToProps = (
  state,
  {tourId, season, eventId, siteConfig}: OwnProps,
): ReduxProps => {
  // Yay, more internal secret query params \_(-_-)_/
  const overrideNow = state.getIn(['route', 'payload', '_now']);
  const eventConfiguration = getEventConfigurationForTour(state, tourId);

  return {
    events: state.getIn(['events']),
    eventConfiguration,
    overrideNow,
    eventTabsData: RemoteData.map2(
      getTourEvents(tourId, state.get('tours'), state.get('events')),
      getTourChallengers(tourId, state.get('tours'), state.get('events')),
      (masters, challengers) => {
        const mastersEventIds = masters
          .map(event => event.id)
          .valueSeq()
          .toArray();

        // get the active or upcoming event so we can scroll the event strip to have that event in view
        const activeEvent = findActiveEvent(
          eventConfiguration,
          mastersEventIds,
          moment.utc(overrideNow),
        );

        // there might not be an active event if e.g. tour is finished
        const activeEventId = activeEvent
          ? getEventIdFromConfiguration(activeEvent.configuration)
          : null;

        const eventTabs = makeEventTabs(
          siteConfig.seasonConfig,
          season,
          eventId,
          masters,
          activeEventId,
        );

        if (siteConfig.features.show3x3ChallengersLink && challengers.size >= 1) {
          eventTabs.push({
            href: `/${season}/challengers`,
            name: 'Challengers',
          });
        }

        if (!isEmpty(siteConfig.seasonConfig.seasons[season].superQuests)) {
          eventTabs.push({
            href: `/${season}/superquests`,
            name: 'Super Quests',
          });
        }

        return eventTabs;
      },
    ),
  };
};

export const makeTabs = (
  state: TourProgrammabilityState,
  season: string,
  tourTab: string,
  showCalendar: ISiteConfig['features']['showTourCalendar'],
  showStats: boolean,
): Tab[] => {
  switch (state) {
    case 'after-season':
    case 'between-events':
    case 'event-in-pre':
    case 'event-in-live':
    case 'event-in-post':
      const tabs = [
        {name: 'Teams', href: `/${season}/teams`, active: tourTab === 'teams'},
        {name: 'Standings', href: `/${season}/standings`, active: tourTab === 'standings'},
        showStats
          ? {name: 'Stats', href: `/${season}/stats`, active: tourTab === 'stats'}
          : undefined,
        {name: 'News', href: `/${season}/news`, active: tourTab === 'news'},
        {name: 'Photos', href: `/${season}/photos`, active: tourTab === 'photos'},
        {name: 'Videos', href: `/${season}/videos`, active: tourTab === 'videos'},
        showCalendar
          ? {name: 'Calendar', href: `/${season}/calendar`, active: tourTab === 'calendar'}
          : undefined,
        {
          name: 'How to Qualify',
          href: `/${season}/how-to-qualify`,
          active: tourTab === 'how-to-qualify',
        },
        {
          name: 'Where to Watch',
          href: `/${season}/where-to-watch`,
          active: tourTab === 'where-to-watch',
        },
        {name: 'More', href: `/${season}/more`, active: tourTab === 'more'},
      ].filter(item => item);

      const legacyTabs = tabs.filter(
        item => item.name !== 'Calendar' && item.name !== 'Where to Watch',
      );

      // In order to get rid of this hard-coding, we should have another mechanism to
      // hide above-mentioned Calendar and Way to Watch tabs.
      return isLegacySeason(season) ? legacyTabs : tabs;

    case 'none':
    case 'before-season':
      return [
        {name: 'Teams', href: `/${season}/teams`, active: tourTab === 'teams'},
        {name: 'News', href: `/${season}/news`, active: tourTab === 'news'},
        {name: 'Photos', href: `/${season}/photos`, active: tourTab === 'photos'},
        {name: 'Videos', href: `/${season}/videos`, active: tourTab === 'videos'},
        {
          name: 'How to Qualify',
          href: `/${season}/how-to-qualify`,
          active: tourTab === 'how-to-qualify',
        },
        {
          name: 'Where to Watch',
          href: `/${season}/where-to-watch`,
          active: tourTab === 'where-to-watch',
        },
        {name: 'More', href: `/${season}/more`, active: tourTab === 'more'},
      ];
  }
};

interface ITourTabs {
  eventConfiguration: any;
  overrideNow: string;
  season: string;
  tourTab: string;
  siteFeatures: ISiteConfig['features'];
  events: RootState['events'];
  showStats: boolean;
}

const TourTabs: React.FC<ITourTabs> = ({
  eventConfiguration,
  overrideNow,
  season,
  tourTab,
  siteFeatures,
  events,
  showStats,
}) => {
  const {state: programmabilityState} = getTourProgrammabilityState(
    eventConfiguration,
    events,
    overrideNow,
  );

  const tabs = makeTabs(
    programmabilityState,
    season,
    tourTab,
    siteFeatures.showTourCalendar,
    showStats,
  );

  return (
    <ul className="ht-100 flex flex-row items-center overflow-x-auto">
      {tabs.map(({name, href, active}) => (
        <li key={`${tourTab}-${name}-${href}`} className="ws-nowrap">
          <a
            href={href}
            className={cx(
              'Navigation-Tab db pv2 ph3 f6 lh-title link link--bare mr2',
              {[defaultNavLinkStyle.light.idle]: !active},
              {[defaultNavLinkStyle.light.active]: active},
            )}
          >
            {name}
          </a>
        </li>
      ))}
    </ul>
  );
};

type Props = OwnProps & ReduxProps;

// TODO: Add a custom "Loading..." to the provider
// NOTE: Some duplication with SidebarNavigation.tsx
export const _TourNavigation: React.FC<Props> = ({
  season,
  eventTabsData,
  tourTab,
  eventConfiguration,
  overrideNow,
  siteConfig,
  events,
  extraClassName,
}) => {
  const {features, theme} = siteConfig;
  const showStats = getTourStatsVisibility(siteConfig, season);

  return (
    <div className={cx('TourNavigation', extraClassName)}>
      <Navigation season={season}>
        <Flex bgColor={theme.headerColor} height="3">
          {/* Large logo */}
          <a
            className="dn db-l flex justify-start ht3"
            href="https://fiba3x3.com"
            style={{minWidth: '13rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/3x3-logo-cut.svg')} alt="3x3" />
          </a>
          {/* Small logo */}
          <a
            className="db dn-l flex justify-start ht3"
            href="https://fiba3x3.com"
            style={{minWidth: '8rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/3x3-logo-cut-mobile.svg')} alt="3x3" />
          </a>
          {/* The width of the WT/WS logo is pure pixel-tweaking via the width style, not an exact science :) */}
          <a
            className="flex justify-start flex-shrink-0"
            style={{width: '6rem'}}
            href={`/${season}`}
          >
            {features.siteId === 'worldtour' ? (
              <img className="db ht-100" src={assetPath('img/logo-wt-v2.svg')} alt="3x3 WT" />
            ) : (
              <img className="db ht-100" src={assetPath('img/logo-ws-v2.svg')} alt="3x3 WS" />
            )}
          </a>
          <Nav
            flexDirection="row"
            justifyContent="end"
            width="100"
            height="100"
            alignItems="center"
            aria-label="Tour content"
            extraClassName="overflow-x-auto"
          >
            <TourTabs
              eventConfiguration={eventConfiguration}
              overrideNow={overrideNow}
              season={season}
              tourTab={tourTab}
              events={events}
              siteFeatures={siteConfig.features}
              showStats={showStats}
            />
          </Nav>
          {!!features.showSeasonSelect && (
            <SeasonSelect
              containerStyleProps={{
                bgColor: 'fullwhite',
                ph: '3',
                height: '100',
                alignItems: 'center',
              }}
              buttonStyleProps={{color: 'dark-50'}}
            />
          )}{' '}
        </Flex>
        {!!features.showEventsInNavigation && (
          <Box bgColor="fullwhite" mb="2">
            <Box
              pv="3"
              ph={['3', '3', '4']}
              maxWidth="9"
              align="center"
              extraClassName="overflow-x-auto"
            >
              {RemoteData.match(eventTabsData, {
                Success: eventTabs => (
                  <NavigationContentRow
                    tabs={eventTabs}
                    label="Event selection"
                    navLinkStyle={defaultNavLinkStyle.dark}
                  />
                ),
                Loading: () => 'Loading...',
                default: () => null,
              })}
            </Box>
          </Box>
        )}
      </Navigation>
    </div>
  );
};

export const TourNavigation = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_TourNavigation);
