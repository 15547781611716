import React, {useContext} from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {connect} from 'fiba/common/utils/reactUtils';
import {Section, SubHeading} from 'fiba/wt/ui/heading/Heading';
import Dates from 'fiba/common/utils/Dates';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import Qualifications, {
  QualificationTeam,
  SourceEvent,
} from 'fiba/common/core/models/api/qualifications/Qualifications';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {QualificationPath} from 'contrib/types/interfaces/api/qualifications/Qualifications';
import {List, Map} from 'immutable';
import {getHumanQualificationType} from 'fiba/wt/utils/results/resultsUtils';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import Team from 'fiba/common/core/models/api/teams/Team';
import {eventRootLink, eventTeamLinkProvider} from 'fiba/wt/utils/linkUtils';
import {ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {MaybeExternalLink} from 'fiba/wt/ui/maybeExternalLink/MaybeExternalLink';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';
import {Link} from 'fiba/wt/ui/link/Link';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import {Box} from 'fiba/wt/ui/box/Box';

interface OwnProps {
  tourId: string;
  season: string;
  eventId: string;
}

type ReduxProps = {data: RemoteData.RemoteData<LoadedProps, any>};

interface LoadedProps {
  mastersIds: Set<string>;
  challengerIds: Set<string>;
  superQuestIds: Set<string>;
  qualificationPaths: List<QualificationPath>;
  qualificationTeamsLookup: Map<string, QualificationTeam>;
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {tourId, eventId}: OwnProps): ReduxProps => {
  return {
    // Combine the sources (empty as they are), and access the data once all are loaded
    data: RemoteData.map3(
      RemoteData.fromMetaObject(state.getIn(['tours', '__meta', tourId, 'events'], Map()).toJS()),
      RemoteData.fromMetaObject(
        state.getIn(['tours', '__meta', tourId, 'challengers'], Map()).toJS(),
      ),
      RemoteData.fromMetaObject(
        state.getIn(['qualifications', '__meta', 'eventQualifications', eventId], Map()).toJS(),
      ),
      () => {
        // All things have loaded here, so transform the `Success` value now
        // We have a lot of derived data, which means we cannot rely on simply passing it down,
        // because we might try to access undefined things. We could try to use defaults everywhere,
        // but that gets ugly. Instead, we use a union type of Loading/Loaded, and only manipulate data once we have it.0
        const qualifications = state.getIn([
          'qualifications',
          'eventQualifications',
          eventId,
        ]) as Qualifications;

        const qualificationPaths = qualifications.get('qualifications', List()) as List<
          QualificationPath
        >;

        // List<QualificationTeam> -> Map<string, QualificationTeam>
        const qualificationTeamsLookup = Map(
          qualifications.teams.map(team => [team.teamId, team]),
        ) as Map<string, QualificationTeam>;

        const mastersIds = new Set<string>(
          state.getIn(['tours', '__meta', tourId, 'events', 'refs']),
        );
        const challengerIds = new Set<string>(
          state.getIn(['tours', '__meta', tourId, 'challengers', 'refs']),
        );
        const superQuestIds = new Set<string>(
          state.getIn(['tours', '__meta', tourId, 'superQuests', 'refs']),
        );

        return {
          mastersIds,
          challengerIds,
          superQuestIds,
          qualificationPaths,
          qualificationTeamsLookup,
        };
      },
    ),
  };
};

const EventHowToQualifyPageImpl: React.FunctionComponent<Props> = ({season, eventId, data}) => {
  return (
    <Spacer ph={['3', '3', '1']}>
      <section>
        <EventPageHeading eventId={eventId} page="How to Qualify" />
        <Section>
          {RemoteData.match(data, {
            Success: ({
              mastersIds,
              challengerIds,
              superQuestIds,
              qualificationPaths,
              qualificationTeamsLookup,
            }) => (
              <React.Fragment>
                <QualificationsTable
                  season={season}
                  mastersIds={mastersIds}
                  challengerIds={challengerIds}
                  superQuestIds={superQuestIds}
                  qualificationPaths={qualificationPaths}
                  qualificationTeams={qualificationTeamsLookup}
                  eventId={eventId}
                />
                <Box ph="2" pv="4">
                  <ContentfulArticle
                    contentPath={`events/${eventId}`}
                    articleType={ArticleTypes.EventHowToQualify}
                  />
                </Box>
              </React.Fragment>
            ),
            Loading: () => (
              <div className="LoadingIndicator pv5 tc">
                <div className="dib h4 w4 LoadingIndicator-Indicator">
                  <LoadingSVG purpose="standalone" aria-label="Loading..." />
                </div>
              </div>
            ),
            Failure: err => <div>We could not fetch the data for this page</div>,
            NotAsked: () => null,
          })}
        </Section>
      </section>
    </Spacer>
  );
};

//
// QUALIFICATION PATH TABLE

interface TableProps {
  season: string;
  mastersIds: Set<string>;
  challengerIds: Set<string>;
  superQuestIds: Set<string>;
  qualificationPaths: List<QualificationPath>;
  qualificationTeams: Map<string, QualificationTeam>;
  eventId: string;
}

type TableHeaders = 'qualificationType' | 'qualificationPath' | 'date' | 'teams';

const getColumnsSpec = (
  localizer: LocalizerService,
  seasonConfig: ISeasonConfig,
  season: string,
  mastersIds: Set<string>,
  challengerIds: Set<string>,
  superQuestIds: Set<string>,
  qualificationTeams: Map<string, QualificationTeam>,
  eventId: string,
): Record<TableHeaders, IColumnSpec<QualificationPath>> => {
  return {
    qualificationType: {
      dataType: 'text',
      name: 'Qualification Type',
      renderColumn: ({Td, getTdProps, rowData}) => (
        <Td {...getTdProps()}>{getHumanQualificationType(rowData.type)}</Td>
      ),
    },

    qualificationPath: {
      dataType: 'text',
      name: 'Qualification Path',
      renderColumn: ({Td, getTdProps, rowData}) => (
        <Td {...getTdProps()}>
          {rowData.sourceEvent ? (
            <MaybeExternalLink
              colorTheme="dark"
              href={getHrefForSourceEvent(
                seasonConfig,
                season,
                mastersIds,
                challengerIds,
                superQuestIds,
                rowData.sourceEvent as SourceEvent,
              )}
            >
              {Icon => (
                <>
                  {/* TODO: Show 'xyz' challenger here if challenger */}
                  {rowData.sourceEvent.name} <Icon size={18} />
                </>
              )}
            </MaybeExternalLink>
          ) : (
            'N/A'
          )}
        </Td>
      ),
    },
    date: {
      dataType: 'text',
      name: 'Date',
      renderColumn: ({Td, getTdProps, rowData}) => (
        <Td {...getTdProps()}>
          {rowData.sourceEvent
            ? Dates.formatDateRange(localizer, rowData.sourceEvent.start, rowData.sourceEvent.end)
            : 'N/A'}
        </Td>
      ),
    },
    teams: {
      dataType: 'text',
      name: 'Teams',
      renderColumn: ({Td, getTdProps, rowData}) => (
        <Td {...getTdProps()}>
          <Spacer vSpace="2">
            {rowData.qualifiedTeams
              .sort((teamAId, teamBId) => {
                const teamA = qualificationTeams.get(teamAId);
                const teamB = qualificationTeams.get(teamBId);
                return (teamA.get('teamName') as string).localeCompare(teamB.get('teamName'));
              })
              .map(teamId => {
                const teamInEventId = qualificationTeams.get(teamId).get('teamInEventId');
                const href = eventTeamLinkProvider(
                  seasonConfig,
                  season,
                  eventId,
                  // TODO: Figure out whether to handle SuperQuests here
                  challengerIds.has(eventId) ? 'Challenger' : 'WorldTour',
                )(teamInEventId);
                return (
                  <div key={teamId}>
                    <Link display={'block'} colorTheme={'dark'} href={href} isExternal={false}>
                      <TeamNameAssembly
                        isSuffixVisible={true}
                        isFlagVisible={false}
                        isSeedVisible={false}
                        team={qualificationTeamToTeam(qualificationTeams.get(teamId, Map()))}
                      />
                    </Link>
                  </div>
                );
              })}
          </Spacer>
        </Td>
      ),
    },
  };
};

const QualificationsTable: React.FunctionComponent<TableProps> = ({
  season,
  mastersIds,
  challengerIds,
  superQuestIds,
  qualificationPaths,
  qualificationTeams,
  eventId,
}) => {
  // Quick perf optimisation: memoize the columnsSpec on localizer and teams
  const {localizer} = useContext(ServicesContext);
  const {seasonConfig} = useContext(SiteConfigContext);
  const columnsSpec = getColumnsSpec(
    localizer,
    seasonConfig,
    season,
    mastersIds,
    challengerIds,
    superQuestIds,
    qualificationTeams,
    eventId,
  );

  return (
    <DataTable<TableHeaders, QualificationPath>
      captionId={`${eventId}-qualification-paths`}
      caption={<SubHeading>Qualification paths</SubHeading>}
      rows={qualificationPaths.sortBy(row => getHumanQualificationType(row.type)).toList()}
      columns={['qualificationType', 'qualificationPath', 'date', 'teams']}
      columnsSpec={columnsSpec}
      headerStyleProps={{color: 'dark-30', bgColor: 'fullwhite', fontWeight: '6'}}
      rowExtraClassName="striped--light-even bb bw1 b--silver-20 striped--fullwhite"
    />
  );
};

/** Qualification team has slightly different field names, so we must transform to Team */
function qualificationTeamToTeam(qTeam: QualificationTeam): Team {
  return Team.fromJS({
    name: qTeam.teamName,
    nameSuffix: qTeam.teamNameSuffix,
    nationality: qTeam.teamNationality,
    isPlaceholder: qTeam.isPlaceholder,
  });
}

function getHrefForSourceEvent(
  seasonConfig: ISeasonConfig,
  season: string,
  mastersIds: Set<string>,
  challengerIds: Set<string>,
  superQuestIds: Set<string>,
  sourceEvent: SourceEvent,
): string {
  // First, determine if the event is internal (i.e. we know about it and have it in our id refs for this tour)
  const isMasters = mastersIds.has(sourceEvent.id);
  const isChallenger = challengerIds.has(sourceEvent.id);
  const isSuperQuest = superQuestIds.has(sourceEvent.id);
  const eventType = (isChallenger && 'Challenger') || (isSuperQuest && 'SuperQuest') || 'WorldTour';

  if (isMasters || isChallenger || isSuperQuest) {
    const href = eventRootLink(seasonConfig, season, sourceEvent.id, eventType);

    return href;
  }

  // Otherwise, external with two cases: event or tour
  // We link to play for those
  if (sourceEvent.type === 'TOUR') {
    return `https://play.fiba3x3.com/tours/${sourceEvent.id}`;
  }
  if (sourceEvent.type === 'EVENT') {
    return `https://play.fiba3x3.com/events/${sourceEvent.id}`;
  }

  // Leave blank if not true
  // This really should not happen though :)
  return '';
}

export const EventHowToQualifyPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  EventHowToQualifyPageImpl,
);
