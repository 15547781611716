/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultTeamGameStats = {
  teamId: null,
  gameId: null,
  totalPoints: null,
  onePointsAttempted: null,
  onePointsMade: null,
  onePointsMissed: null,
  onePointsMadeRatio: null,
  twoPointsMade: null,
  twoPointsMissed: null,
  freeThrowsAttempted: null,
  freeThrowsMade: null,
  freeThrowsMissed: null,
  freeThrowsMadeRatio: null,
  extraFreeThrows: null,
  dunks: null,
  assists: null,
  keyAssists: null,
  steals: null,
  buzzerBeaters: null,
  drives: null,
  turnovers: null,
  highlights: null,
  challenges: null,
  teamFouls: null,
  teamFouls7at: null,
  teamFouls10at: null,
  technicalFouls: null,
  unsportsmanlikeFouls: null,
  timeoutTakenAt: null,
  twoPointsAttempted: null,
  twoPointsMadeRatio: null,
  blockedShots: null,
  totalRebounds: null,
  offensiveRebounds: null,
  defensiveRebounds: null,
  teamRebounds: null,
  teamOffensiveRebounds: null,
  teamDefensiveRebounds: null,
  teamTurnovers: null,
  totalMadeShots: null,
  totalShots: null,
  totalPossessions: null,
  totalPointsPerPossession: null,
  onePointPossessions: null,
  onePointPointsPerPossession: null,
  freeThrowPossessions: null,
  freeThrowPointsPerPossession: null,
  twoPointPossessions: null,
  twoPointPointsPerPossession: null,
  shootingEfficiency: null,
  shootingValue: null,
  pointsDifference: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TeamGameStatsLike = {
  [K in keyof typeof defaultTeamGameStats]?: TeamGameStats[K];
};

export class TeamGameStats extends Record(defaultTeamGameStats) {
  teamId?: string;
  gameId?: string;
  totalPoints?: number;
  onePointsAttempted?: number;
  onePointsMade?: number;
  onePointsMissed?: number;
  onePointsMadeRatio?: number;
  twoPointsMade?: number;
  twoPointsMissed?: number;
  freeThrowsAttempted?: number;
  freeThrowsMade?: number;
  freeThrowsMissed?: number;
  freeThrowsMadeRatio?: number;
  extraFreeThrows?: number;
  dunks?: number;
  assists?: number;
  keyAssists?: number;
  steals?: number;
  buzzerBeaters?: number;
  drives?: number;
  turnovers?: number;
  highlights?: number;
  challenges?: number;
  teamFouls?: number;
  teamFouls7at?: number;
  teamFouls10at?: number;
  technicalFouls?: number;
  unsportsmanlikeFouls?: number;
  timeoutTakenAt?: number;
  twoPointsAttempted?: number;
  twoPointsMadeRatio?: number;
  blockedShots?: number;
  totalRebounds?: number;
  offensiveRebounds?: number;
  defensiveRebounds?: number;
  teamRebounds?: number;
  teamOffensiveRebounds?: number;
  teamDefensiveRebounds?: number;
  teamTurnovers?: number;
  totalMadeShots?: number;
  totalShots?: number;
  totalPossessions?: number;
  totalPointsPerPossession?: number;
  onePointPossessions?: number;
  onePointPointsPerPossession?: number;
  freeThrowPossessions?: number;
  freeThrowPointsPerPossession?: number;
  twoPointPossessions?: number;
  twoPointPointsPerPossession?: number;
  shootingEfficiency?: number;
  shootingValue?: number;
  pointsDifference?: number;
  static fromJS(o: TeamGameStatsLike): TeamGameStats {
    return (
      o &&
      new TeamGameStats({
        teamId: o.teamId,
        gameId: o.gameId,
        totalPoints: o.totalPoints,
        onePointsAttempted: o.onePointsAttempted,
        onePointsMade: o.onePointsMade,
        onePointsMissed: o.onePointsMissed,
        onePointsMadeRatio: o.onePointsMadeRatio,
        twoPointsMade: o.twoPointsMade,
        twoPointsMissed: o.twoPointsMissed,
        freeThrowsAttempted: o.freeThrowsAttempted,
        freeThrowsMade: o.freeThrowsMade,
        freeThrowsMissed: o.freeThrowsMissed,
        freeThrowsMadeRatio: o.freeThrowsMadeRatio,
        extraFreeThrows: o.extraFreeThrows,
        dunks: o.dunks,
        assists: o.assists,
        keyAssists: o.keyAssists,
        steals: o.steals,
        buzzerBeaters: o.buzzerBeaters,
        drives: o.drives,
        turnovers: o.turnovers,
        highlights: o.highlights,
        challenges: o.challenges,
        teamFouls: o.teamFouls,
        teamFouls7at: o.teamFouls7at,
        teamFouls10at: o.teamFouls10at,
        technicalFouls: o.technicalFouls,
        unsportsmanlikeFouls: o.unsportsmanlikeFouls,
        timeoutTakenAt: o.timeoutTakenAt,
        twoPointsAttempted: o.twoPointsAttempted,
        twoPointsMadeRatio: o.twoPointsMadeRatio,
        blockedShots: o.blockedShots,
        totalRebounds: o.totalRebounds,
        offensiveRebounds: o.offensiveRebounds,
        defensiveRebounds: o.defensiveRebounds,
        teamRebounds: o.teamRebounds,
        teamOffensiveRebounds: o.teamOffensiveRebounds,
        teamDefensiveRebounds: o.teamDefensiveRebounds,
        teamTurnovers: o.teamTurnovers,
        totalMadeShots: o.totalMadeShots,
        totalShots: o.totalShots,
        totalPossessions: o.totalPossessions,
        totalPointsPerPossession: o.totalPointsPerPossession,
        onePointPossessions: o.onePointPossessions,
        onePointPointsPerPossession: o.onePointPointsPerPossession,
        freeThrowPossessions: o.freeThrowPossessions,
        freeThrowPointsPerPossession: o.freeThrowPointsPerPossession,
        twoPointPossessions: o.twoPointPossessions,
        twoPointPointsPerPossession: o.twoPointPointsPerPossession,
        shootingEfficiency: o.shootingEfficiency,
        shootingValue: o.shootingValue,
        pointsDifference: o.pointsDifference,
      })
    );
  }
}

export default TeamGameStats;
