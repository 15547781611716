import React, {useContext} from 'react';
import {List} from 'immutable';
import {FieldSort, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {FilteredDataTable} from 'fiba/wt/ui/filteredDataTable/FilteredDataTable';
import {Legend} from 'fiba/wt/ui/legend/Legend';
import {Box} from 'fiba/wt/ui/box/Box';
import {PlayerStatRow} from 'fiba/wt/stores/playerStatsStore';
import {decimalColumn, integerColumn} from 'fiba/wt/ui/dataTable/DataTableHelpers';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {getLocalDateFromString, getLocalTimeFromString} from 'fiba/common/utils/schedule';
import {ServicesContext} from 'fiba/common/services/services';
import {Th} from 'fiba/wt/ui/table/Table';

type TableColumns =
  | 'player'
  | 'team'
  | 'eventsPlayed'
  | 'mainDrawsPlayed'
  | 'gamesPlayed'
  | 'totalPointsMade'
  | 'pointsPerGame'
  | 'playerValue'
  | 'playerValuePerGame'
  | 'shootingEfficiency'
  | 'shootingValue'
  | 'shootingValuePerGame'
  | 'highlights'
  | 'highlightsPerGame'
  | 'playerDoubleFivesCount'
  | 'playerTripleFivesCount'
  | 'onePointsMade'
  | 'onePointsAttempted'
  | 'onePointsPercentage'
  | 'twoPointsMade'
  | 'twoPointsAttempted'
  | 'twoPointsPercentage'
  | 'freeThrowsMade'
  | 'freeThrowsAttempted'
  | 'freeThrowsPercentage'
  | 'keyAssists'
  | 'drives'
  | 'dunks'
  | 'blockedShots'
  | 'buzzerBeaters'
  | 'totalRebounds'
  | 'reboundsPerGame'
  | 'offensiveRebounds'
  | 'defensiveRebounds'
  | 'turnovers'
  | 'turnoversPerGame';

type ColumnSpec = IColumnSpec<PlayerStatRow>;
type ColumnSpecRecord = Record<TableColumns, ColumnSpec>;
export type TeamPlayerHrefSpec = (teamId: string) => (playerId: string) => string;

// Similar to TeamStatsTable, there are different cases for rendering a team name.
// We defer to the caller to avoid magic ternaries / implicit information.
export type RenderTeamName = (playerStatRow: PlayerStatRow) => React.ReactNode;

const createColumnsSpec = (
  createPlayerHref: TeamPlayerHrefSpec,
  renderTeamName: RenderTeamName,
  eventId: string,
): ColumnSpecRecord => {
  return {
    player: {
      name: 'Player',
      dataType: 'text',
      comparator: (a, b) => getPlayerName(a).localeCompare(getPlayerName(b)),
      // We render the row index, to keep a visible indication of
      // order, regardless of what value we sort by
      renderColumn: ({rowData, rowIndex, getTdProps}) => (
        <Th {...getTdProps({})} scope="row">
          {rowIndex + 1}.&nbsp;
          <StyledLink href={createPlayerHref(rowData.team.teamId)(rowData.player.id)}>
            {getPlayerName(rowData['player'])}
          </StyledLink>
        </Th>
      ),
    },
    team: {
      name: 'Team',
      dataType: 'text',
      comparator: (a, b) => a['teamName'].localeCompare(b['teamName']),
      renderColumn: ({rowData, Td, getTdProps}) => (
        <Td {...getTdProps({})} extraClassName="ws-nowrap">
          {renderTeamName(rowData)}
        </Td>
      ),
    },
    eventsPlayed: integerColumn('EP', 'Events played'),
    mainDrawsPlayed: integerColumn('MD', 'Maindraws played'),
    gamesPlayed: integerColumn('GP', 'Games played'),
    totalPointsMade: integerColumn('PTS', 'Points scored'),
    pointsPerGame: decimalColumn('PPG', 'Points per game', 1),
    playerValue: decimalColumn('P-VAL', 'Player value', 1),
    playerValuePerGame: decimalColumn('P-VALPG', 'Player value per game', 1),
    shootingEfficiency: decimalColumn('S-EFF', 'Shooting efficiency', 2),
    shootingValue: decimalColumn('S-VAL', 'Shooting value', 1),
    shootingValuePerGame: decimalColumn('S-VALPG', 'Shooting value per game', 1),
    highlights: integerColumn('HGL', 'Highlights'),
    highlightsPerGame: decimalColumn('HGLPG', 'Highlights per game', 1),
    playerDoubleFivesCount: integerColumn('D5', 'Double-fives'),
    playerTripleFivesCount: integerColumn('T5', 'Triple-fives'),
    onePointsMade: integerColumn('1PTM', 'One point shots made'),
    onePointsAttempted: integerColumn('1PTA', 'One point shot attempts'),
    onePointsPercentage: decimalColumn('1PT%', 'One point shot percentage', 0, true),
    twoPointsMade: integerColumn('2PTM', 'Two point shot made'),
    twoPointsAttempted: integerColumn('2PTA', 'Two point shot attempts'),
    twoPointsPercentage: decimalColumn('2PT%', 'Two point shot percentage', 0, true),
    freeThrowsMade: integerColumn('FTM', 'Free throws made'),
    freeThrowsAttempted: integerColumn('FTA', 'Free throw attempts'),
    freeThrowsPercentage: decimalColumn('FT%', 'Free throw percentage', 0, true),
    keyAssists: integerColumn('KAS', 'Key assists'),
    drives: integerColumn('DRV', 'Drives'),
    dunks: integerColumn('DNK', 'Dunks'),
    blockedShots: integerColumn('BS', 'Blocked shots'),
    buzzerBeaters: integerColumn('BZR', 'Buzzerbeaters'),
    totalRebounds: integerColumn('REB', 'Rebounds'),
    reboundsPerGame: decimalColumn('REBPG', 'Rebounds per game', 1),
    offensiveRebounds: integerColumn('OREB', 'Offensive rebounds'),
    defensiveRebounds: integerColumn('DREB', 'Defensive rebounds'),
    turnovers: integerColumn('TO', 'Turnovers'),
    turnoversPerGame: decimalColumn('TOPG', 'Turnovers per game', 1),
  };
};

interface OwnProps {
  stats: List<PlayerStatRow>;
  sortedBy: FieldSort;
  updatedAt?: string;
  filteredData: List<PlayerStatRow>;
  sortAction: (key, columnsSpec) => any;
  filterAction: (filterBy: FilterOptions) => any;
  captionId: string;
  season: string;
  createPlayerHref: TeamPlayerHrefSpec;
  renderTeamName: RenderTeamName;
  eventId: string;
  showEventsPlayed?: boolean;
}

export const PlayerStatsTable: React.FC<OwnProps> = ({
  captionId,
  stats,
  sortedBy,
  updatedAt,
  filteredData,
  sortAction,
  filterAction,
  createPlayerHref,
  renderTeamName,
  eventId,
  showEventsPlayed = true,
}) => {
  const displayedStats = filteredData.isEmpty() ? stats : filteredData;
  const {localizer} = useContext(ServicesContext);
  const columnsSpec = createColumnsSpec(createPlayerHref, renderTeamName, eventId);

  const filterField = 'gamesPlayed';

  const max = stats.reduce(
    (acc, player) => (player[filterField] > acc ? player[filterField] : acc),
    0,
  );
  return stats.isEmpty() ? (
    <span>Stats not available.</span>
  ) : (
    <Spacer vSpace="4">
      <Box>
        <FilteredDataTable<TableColumns, PlayerStatRow>
          captionId={captionId}
          skipMaxWidth={true}
          caption={
            <div className="flex flex-wrap justify-between">
              <SubHeading>Player Stats</SubHeading>
              {!!updatedAt ? (
                <div className="f6 fw4">
                  Updated at: {getLocalDateFromString(updatedAt, localizer)},{' '}
                  {getLocalTimeFromString(updatedAt, localizer)} (UTC)
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          columns={[
            'player',
            'team',
            ...((showEventsPlayed ? ['eventsPlayed'] : []) as TableColumns[]), // events played column is hidden on event specific stats page
            'mainDrawsPlayed',
            'gamesPlayed',
            'totalPointsMade',
            'pointsPerGame',
            'playerValue',
            'playerValuePerGame',
            'shootingEfficiency',
            'shootingValue',
            'shootingValuePerGame',
            'highlights',
            'highlightsPerGame',
            'playerDoubleFivesCount',
            'playerTripleFivesCount',
            'onePointsMade',
            'onePointsAttempted',
            'onePointsPercentage',
            'twoPointsMade',
            'twoPointsAttempted',
            'twoPointsPercentage',
            'freeThrowsMade',
            'freeThrowsAttempted',
            'freeThrowsPercentage',
            'keyAssists',
            'drives',
            'dunks',
            'blockedShots',
            'buzzerBeaters',
            'totalRebounds',
            'reboundsPerGame',
            'offensiveRebounds',
            'defensiveRebounds',
            'turnovers',
            'turnoversPerGame',
          ]}
          columnsSpec={columnsSpec}
          sortAction={sortAction}
          sortedBy={sortedBy}
          rows={displayedStats}
          headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
          headerExtraClassName="dark-30"
          rowExtraClassName="striped--light-even bb bw1 b--silver-20 striped--fullwhite"
          min={0}
          max={max}
          field={filterField}
          filterAction={filterAction}
          fieldDisplayName={'Games played'}
          frozenHeaders={true}
        />
      </Box>
      <Legend definition={legendDefinition} />
    </Spacer>
  );
};

const legendDefinition = [
  ['EP', 'Events played'],
  ['MD', 'Maindraws played'],
  ['GP', 'Games played'],
  ['PTS', 'Points scored'],
  ['PPG', 'Points per game'],
  ['P-VAL', 'Player value', '(S-EFF * PTS) + KAS + DRV + DNK + BS + BZR + (REB/2) - TO'],
  ['P-VALPG', 'Player value per game'],
  ['S-EFF', 'Shooting efficiency', 'PTS / (1PTA + 2PTA + FTA)'],
  ['S-VAL', 'Shooting value', 'S-EFF * PTS'],
  ['S-VALPG', 'Shooting value per game'],
  ['HGL', 'Highlights', 'KAS + DRV + DNK + BS + BZR'],
  ['HGLPG', 'Highlights per game'],
  ['D5', 'Double-fives', '5 or more in 2 of these 3 categories: PTS-REB-HGL'],
  ['T5', 'Triple-fives', '5 or more in all 3 categories: PTS-REB-HGL'],
  ['1PTM', 'One-point field goals made'],
  ['1PTA', 'One-point field goal attempts'],
  ['1PT%', 'One-point field goal percentage'],
  ['2PTM', 'Two-point field goals made'],
  ['2PTA', 'Two-point field goal attempts'],
  ['2PT%', 'Two-point field goal percentage'],
  ['FTM', 'Free throws made'],
  ['FTA', 'Free throw attempts'],
  ['FT%', 'Free throw percentage'],
  ['KAS', 'Key assists'],
  ['DRV', 'Drives'],
  ['DNK', 'Dunks'],
  ['BS', 'Blocked shots'],
  ['BZR', 'Buzzerbeaters'],
  ['REB', 'Rebounds'],
  ['REBPG', 'Rebounds per game'],
  ['OREB', 'Offensive rebounds'],
  ['DREB', 'Defensive rebounds'],
  ['TO', 'Turnovers'],
  ['TOPG', 'Turnovers per game'],
];
