import 'details-element-polyfill';
import runClient from 'fiba/common/client/client';

import {createBrowserServices} from 'fiba/wt/client/services/clientServices';
import siteConfig from 'fiba/wt/config/siteConfig';
import {initialiseSentry} from 'fiba/common/client/services/sentrySetup';

initialiseSentry('wt');

const services = createBrowserServices();
window['__fiba'].services = services;

runClient(services, siteConfig).then(root => services.router.createAnchorListener(root));

// Accept hot updates if available
if (module.hot) {
  module.hot.accept();
}
