import {Tab} from 'fiba/wt/ui/navigation/Navigation';
import React, {useRef, useState} from 'react';
import cx from 'classnames';
import {NavigationList, NavigationListProps} from 'fiba/wt/ui/sidebarNavigation/SidebarNavigation';
import {Link} from 'fiba/wt/ui/link/Link';
import {ChevronDown, ChevronLeft, ChevronRight} from '@fpapado/react-feather';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {useOnClickOutside} from 'fiba/wt/ui/hooks/useClickOutside';
import {ARROW_CLS} from 'fiba/wt/ui/eventLayout/EventNavigationMobile';
import {useInView} from 'react-intersection-observer';

interface SubMenuOwnProps {
  parent: Tab;
}

type SubMenuProps = Omit<NavigationListProps, 'firstItemRef' | 'lastItemRef'> & SubMenuOwnProps;

export const SubMenu: React.FunctionComponent<SubMenuProps> = ({
  parent,
  linkExtraClassName,
  navLinkStyle,
  ...props
}) => {
  const containerRef = useRef();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useOnClickOutside(containerRef.current, () => setMenuOpen(false));

  const [firstItemRef, firstInView] = useInView();
  const [lastItemRef, lastInView] = useInView();

  if (!parent || !parent.children || parent.children.length === 0) {
    return null;
  }
  const {name, children, active} = parent;

  return (
    <div className="SubMenu link link--bare" ref={containerRef}>
      <Link
        isExternal={false}
        onClick={() => setMenuOpen(!menuOpen)}
        extraClassName={cx(
          'db link link--bare',
          linkExtraClassName,
          {[navLinkStyle.idle]: !active},
          {[navLinkStyle.active]: active},
        )}
      >
        <Flex flexDirection="row">
          <Flex alignSelf="center" flexGrow="1">
            {name}
          </Flex>
          <ChevronDown className="dn-m db" purpose="decorative" />
          <ChevronRight className="dn db-m" purpose="decorative" />
        </Flex>
      </Link>
      <div
        className="SubMenu-Container bg-silver-10 br1"
        style={{display: menuOpen ? 'inline-block' : 'none'}}
      >
        <div
          className={`EventLayout-FadeLeft pr1 left-0 db ${ARROW_CLS} ${
            !firstInView ? 'db' : 'dn'
          } dn-m`}
        >
          <ChevronLeft width="20" height="20" purpose="decorative" />
        </div>
        <NavigationList
          tabs={children}
          navLinkStyle={navLinkStyle}
          linkExtraClassName={linkExtraClassName}
          firstItemRef={firstItemRef}
          lastItemRef={lastItemRef}
          {...props}
        />
        <div
          className={`EventLayout-FadeRight pr1 right-0 db ${ARROW_CLS} ${
            !lastInView ? 'db' : 'dn'
          } dn-m`}
        >
          <ChevronRight width="20" height="20" purpose="decorative" />
        </div>
      </div>
    </div>
  );
};
