import React from 'react';
import {Heading} from 'fiba/wt/ui/heading/Heading';

export interface TourPageProps {
  tourId: string;
  season: string;
  showTwitterFeed: boolean;
}

export const SectionHeading: React.SFC<{}> = ({children}) => (
  <Heading fontSize={['3', '3', '2']} pb="3" ph={['3', '3', '0']}>
    {children}
  </Heading>
);

export const TourHeading: React.SFC<{}> = ({children}) => (
  <Heading fontSize={['2', '2', '1']} ph={['3', '3', '0']}>
    {children}
  </Heading>
);
