import {fromJS, List, Map, Record as defineRecord} from 'immutable';

const DEFAULT_ERROR_MESSAGE = 'Unknown error occurred';

export default class FibaError extends Error {
  fiba: any;

  constructor(fiba) {
    super(fiba ? fiba.get('message', DEFAULT_ERROR_MESSAGE) : DEFAULT_ERROR_MESSAGE);
    this.fiba = fiba;
    this.name = 'FibaError';
    // @see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, FibaError.prototype);
  }
}

export class ValidationError extends FibaError {
  constructor(props) {
    super(props);
    this.name = 'ValidationError';
    // @see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    // This is needed for the instanceof check to work.
    Object.setPrototypeOf(this, ValidationError.prototype);
  }
}

export const createMessage = message => {
  return new FibaError(errorJsonForMessage(message));
};

/**
 * Simple validation error message not coupled to any field. Will be shown as error banner.
 */
export const createValidationError = message => {
  return new ValidationError(errorJsonForMessage(message));
};

/**
 * Validation error with FieldErrors coupled to input fields. Errors will be shown next to
 * the input fields.
 */
export const createFieldValidationError = fieldErrors => {
  // TODO: Map fieldErrors to FieldError objects, or assert their validity
  return new ValidationError(
    ErrorJson({
      // Error is not really shown anywhere?
      error: 'Validation error',
      // Sentry groups errors based on the message, more explicit the better. Not shown for the user.
      message: `Validation failed: ${fieldErrors.map(it => it.errorMessage).join(',')}`,
      timeStamp: Date(),
      // Each validation error is shown for the user
      validationErrors: fromJS(fieldErrors),
    }),
  );
};

export const createFieldError = (field, errorMessage, errorCode = 'missing') =>
  FieldError({
    errorCode,
    errorMessage,
    field,
  });

const errorJsonForMessage = message => {
  const error = message;
  const timeStamp = Date();
  return ErrorJson({error, message, timeStamp});
};

export class NotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'NotFoundError';
    // @see https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, NotFoundError.prototype);
  }
}

// Error schemas as defined in fiba-3x3-app-backend
// @see https://github.com/futurice/fiba-3x3-app-backend/tree/master/src/main/java/com/fiba/fiba3x3/model/api/errors

export const ErrorJSON = defineRecord({
  status: 0,
  error: '',
  message: '',
  timeStamp: '',
  validationErrors: undefined,
});

const ApiError = {
  errorCode: '',
  errorMessage: '',
  errorType: '',
};

export const ObjectError = defineRecord({
  ...ApiError,
  errorType: 'ObjectError',
});
export const FieldError = defineRecord({
  ...ApiError,
  errorType: 'FieldError',
  field: '',
});
export const UpstreamFailure = defineRecord({
  ...ApiError,
  errorType: 'UpstreamFailure',
  additionalInfo: Map(/* {<string>: <object>} */),
});

export const ErrorJson = defineRecord({
  status: 0,
  error: '',
  message: '',
  timeStamp: '',
  trace: '',
  validationErrors: List(/* ApiError */),
});
