/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {PlayerSubRanking} from './PlayerSubRanking';
import {PlayerSocialMediaEntry} from './PlayerSocialMediaEntry';

export const defaultPlayer = {
  isFull: null,
  publicWebsiteHref: null,
  id: null,
  firstName: null,
  lastName: null,
  nickname: null,
  gender: null,
  age: null,
  bio: null,
  nationality: null,
  homeLocation: null,
  homeCountry: null,
  rankingPoints: null,
  rankingPosition: null,
  worldRankingPosition: null,
  countryRankingPosition: null,
  nationalityRankingPosition: null,
  gamesUsedForRanking: null,
  subRankings: null,
  gamesPlayed: null,
  gamesWon: null,
  gamesLost: null,
  winRatio: null,
  followingHref: null,
  followingCountHref: null,
  followersHref: null,
  followersCountHref: null,
  heightInCm: null,
  weightInKg: null,
  imageTinyUrl: null,
  imageSmallUrl: null,
  imageMediumUrl: null,
  coverImageUrl: null,
  coverImageSmallUrl: null,
  coverImageFallbackUrl: null,
  wearsHeadgear: null,
  phoneNumber: null,
  email: null,
  emailVerified: null,
  dateOfBirth: null,
  username: null,
  friendlyUrl: null,
  homeCity: null,
  hasAcceptedTermsAndConditions: null,
  hasAcceptedPrivacyPolicy: null,
  invitationsHref: null,
  statusInTeam: null,
  isCaptain: null,
  cannotRegisterToCategory: null,
  cannotRegisterToCategoryReason: null,
  isYou: null,
  isExternal: null,
  playerSocialMedia: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type PlayerLike = {
  [K in keyof typeof defaultPlayer]?: Player[K];
};

export class Player extends Record(defaultPlayer) {
  isFull?: boolean;
  publicWebsiteHref?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  nickname?: string;
  gender?: string;
  age?: number;
  bio?: string;
  nationality?: string;
  homeLocation?: string;
  homeCountry?: string;
  rankingPoints?: number;
  rankingPosition?: number;
  worldRankingPosition?: number;
  countryRankingPosition?: number;
  nationalityRankingPosition?: number;
  gamesUsedForRanking?: number;
  subRankings?: List<PlayerSubRanking>;
  gamesPlayed?: number;
  gamesWon?: number;
  gamesLost?: number;
  winRatio?: number;
  followingHref?: string;
  followingCountHref?: string;
  followersHref?: string;
  followersCountHref?: string;
  heightInCm?: number;
  weightInKg?: number;
  imageTinyUrl?: string;
  imageSmallUrl?: string;
  imageMediumUrl?: string;
  coverImageUrl?: string;
  coverImageSmallUrl?: string;
  coverImageFallbackUrl?: string;
  wearsHeadgear?: boolean;
  phoneNumber?: string;
  email?: string;
  emailVerified?: boolean;
  dateOfBirth?: string;
  username?: string;
  friendlyUrl?: string;
  homeCity?: string;
  hasAcceptedTermsAndConditions?: boolean;
  hasAcceptedPrivacyPolicy?: boolean;
  invitationsHref?: string;
  statusInTeam?: string;
  isCaptain?: boolean;
  cannotRegisterToCategory?: boolean;
  cannotRegisterToCategoryReason?: string;
  isYou?: boolean;
  isExternal?: boolean;
  playerSocialMedia?: List<PlayerSocialMediaEntry>;
  static fromJS(o: PlayerLike): Player {
    return (
      o &&
      new Player({
        isFull: o.isFull,
        publicWebsiteHref: o.publicWebsiteHref,
        id: o.id,
        firstName: o.firstName,
        lastName: o.lastName,
        nickname: o.nickname,
        gender: o.gender,
        age: o.age,
        bio: o.bio,
        nationality: o.nationality,
        homeLocation: o.homeLocation,
        homeCountry: o.homeCountry,
        rankingPoints: o.rankingPoints,
        rankingPosition: o.rankingPosition,
        worldRankingPosition: o.worldRankingPosition,
        countryRankingPosition: o.countryRankingPosition,
        nationalityRankingPosition: o.nationalityRankingPosition,
        gamesUsedForRanking: o.gamesUsedForRanking,
        subRankings: o.subRankings && List(o.subRankings).map(i => i && PlayerSubRanking.fromJS(i)),
        gamesPlayed: o.gamesPlayed,
        gamesWon: o.gamesWon,
        gamesLost: o.gamesLost,
        winRatio: o.winRatio,
        followingHref: o.followingHref,
        followingCountHref: o.followingCountHref,
        followersHref: o.followersHref,
        followersCountHref: o.followersCountHref,
        heightInCm: o.heightInCm,
        weightInKg: o.weightInKg,
        imageTinyUrl: o.imageTinyUrl,
        imageSmallUrl: o.imageSmallUrl,
        imageMediumUrl: o.imageMediumUrl,
        coverImageUrl: o.coverImageUrl,
        coverImageSmallUrl: o.coverImageSmallUrl,
        coverImageFallbackUrl: o.coverImageFallbackUrl,
        wearsHeadgear: o.wearsHeadgear,
        phoneNumber: o.phoneNumber,
        email: o.email,
        emailVerified: o.emailVerified,
        dateOfBirth: o.dateOfBirth,
        username: o.username,
        friendlyUrl: o.friendlyUrl,
        homeCity: o.homeCity,
        hasAcceptedTermsAndConditions: o.hasAcceptedTermsAndConditions,
        hasAcceptedPrivacyPolicy: o.hasAcceptedPrivacyPolicy,
        invitationsHref: o.invitationsHref,
        statusInTeam: o.statusInTeam,
        isCaptain: o.isCaptain,
        cannotRegisterToCategory: o.cannotRegisterToCategory,
        cannotRegisterToCategoryReason: o.cannotRegisterToCategoryReason,
        isYou: o.isYou,
        isExternal: o.isExternal,
        playerSocialMedia:
          o.playerSocialMedia &&
          List(o.playerSocialMedia).map(i => i && PlayerSocialMediaEntry.fromJS(i)),
      })
    );
  }
}

export default Player;
