import React, {ErrorInfo} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {P} from 'fiba/wt/ui/text/Text';
import {Link} from 'fiba/wt/ui/link/Link';
import {connect} from 'fiba/common/utils/reactUtils';
import * as Sentry from '@sentry/react';

interface Props {}

interface State {
  error: any;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    // Initialise state
    this.state = {error: null};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {error};
  }

  componentDidCatch(error, info: ErrorInfo) {
    // We could also log the error to an error reporting service
    /* eslint-disable no-console */
    console.error(`Caught error in ErrorBoundary:\n${error}\n${info.componentStack}`);
  }

  render() {
    const {error} = this.state;

    if (error) {
      return (
        <Box
          pv="5"
          ph="3"
          color="dark-50"
          bgColor="clay-20"
          border="all"
          borderWidth="2"
          borderColor="red-20"
        >
          <Spacer vSpace="4">
            <Spacer vSpace="3">
              <SubHeading>Something went wrong when showing this page</SubHeading>

              <P>Sorry about that...</P>

              <Box>
                <Link href="/">Go back to the home page</Link>
              </Box>
            </Spacer>

            <details style={{whiteSpace: 'pre-wrap'}}>
              <summary>Details</summary>
              {error && error.toString()}
            </details>
          </Spacer>
        </Box>
      );
    }

    return this.props.children;
  }
}

//
// Route Error Boundary

interface RouteErrorBoundaryState {
  pathname: string;
}

type RouteErrorBoundaryProps = RouteErrorBoundaryState;

const mapStateToProps = (state): RouteErrorBoundaryState => {
  const pathname = state.get('route').get('pathname');
  return {
    pathname,
  };
};

/** Route-aware error boundary, that resets the boundary on route change.
 * This allows for re-renders if the route changes, making other pages available if the user navigates away.
 *
 * NOTE: An alternative would be to read document.location.pathname on a useEffect(() => ..., []).
 * It would require less ceremony, but this one seems better for notifying of changes.
 */
const RouteErrorBoundaryImpl: React.FunctionComponent<RouteErrorBoundaryProps> = ({
  pathname,
  children,
}) => {
  // Set the key as {pathname}, which will cause a re-render and nuke the state if it changes
  return <ErrorBoundary key={pathname}>{children}</ErrorBoundary>;
};

export const RouteErrorBoundary = connect<RouteErrorBoundaryState, {}, Props>(mapStateToProps)(
  RouteErrorBoundaryImpl,
);

interface SentryOwnProps {
  componentNameForContext: string;
  children: React.ReactNode;
}

type SentryErrorBoundaryProps = RouteErrorBoundaryState & SentryOwnProps;

/** Error boundary that wraps Sentry's Error Boundary, with logging context,
 * dev-time stack, and a message to the user.
 */
const SentryErrorBoundaryImpl: React.FC<SentryErrorBoundaryProps> = ({
  componentNameForContext,
  pathname,
  children,
}) => {
  return (
    <Sentry.ErrorBoundary
      key={pathname}
      beforeCapture={scope => {
        // A bit of minor context, about the boundary handling the error
        scope.setContext('Error Boundary', {component: componentNameForContext});
      }}
      fallback={({error, componentStack}) => {
        return (
          <Box
            pv="5"
            ph="3"
            color="dark-50"
            bgColor="clay-20"
            border="all"
            borderWidth="2"
            borderColor="red-20"
          >
            <Spacer vSpace="4">
              <Spacer vSpace="3">
                <SubHeading>Sentry: Something went wrong when showing this page</SubHeading>

                <Box>
                  <Link href="/">Go back to the home page</Link>
                </Box>
              </Spacer>

              {/* In development, we show the stack and error, otherwise we minimise it */}
              {process.env.NODE_ENV !== 'production' ? (
                <Spacer vSpace="4">
                  <div>
                    <SubHeading>Error</SubHeading>
                    <div style={{whiteSpace: 'pre-wrap'}}>{error && error.toString()}</div>
                  </div>
                  <div>
                    <SubHeading>Component Stack</SubHeading>
                    <div style={{whiteSpace: 'pre-wrap'}}>{componentStack}</div>
                  </div>
                </Spacer>
              ) : (
                <details style={{whiteSpace: 'pre-wrap'}}>
                  <summary>Details</summary>
                  {error && error.toString()}
                </details>
              )}
            </Spacer>
          </Box>
        );
      }}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

export const SentryErrorBoundary = connect<RouteErrorBoundaryState, {}, SentryOwnProps>(
  mapStateToProps,
)(SentryErrorBoundaryImpl);
