import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

interface Props {
  season: string;
  tourId: string;
  eventId: string;
}

export const EventMediaServicesPage: React.SFC<Props> = ({eventId}) => (
  <Spacer ph={['3', '3', '1']} debugClassName="EventMediaServicesPage">
    <section>
      <EventPageHeading eventId={eventId} page="Media Services" />
      <Section>
        <Box measure="very-wide">
          <ContentfulArticle
            contentPath={`events/${eventId}`}
            articleType={ArticleTypes.EventMediaServices}
          />
        </Box>
      </Section>
    </section>
  </Spacer>
);
