import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {FastestGame} from 'fiba/common/core/models/api/fastestGame/FastestGame';
import {List} from 'immutable';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {FastestGamesTable} from 'fiba/wt/ui/fastestGamesTable/FastestGamesTable';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {resolvePathWithGender} from 'fiba/wt/utils/storeUtils';

type Props = OwnProps & ReduxProps;

interface OwnProps {
  season: string;
  eventId: string;
  genderTab?: CategoryGenderTab;
}

interface ReduxProps {
  fastestGames: FastestGame[];
  metaPaths: string[];
}

export const mapStateToProps = (state, {eventId, genderTab}: OwnProps): ReduxProps => {
  const fastestGamesList: List<FastestGame> = state.getIn(
    ['stats', 'eventFastestGames', ...resolvePathWithGender(eventId, genderTab)],
    List(),
  );

  const fastestGames: FastestGame[] = fastestGamesList.toJS();
  const metaPaths = [`stats/__meta/eventFastestGames/${eventId}`]; // MetaPath isn't gender-specific

  return {fastestGames, metaPaths};
};

export const _EventFastestGames: React.FunctionComponent<Props> = ({
  season,
  eventId,
  fastestGames,
  metaPaths,
}) => (
  <Loading metaPaths={metaPaths}>
    {() => (
      <FastestGamesTable
        season={season}
        captionId={`${eventId}-fastest-games`}
        caption={<SubHeading>Fastest Games</SubHeading>}
        columns={['Number', 'Game', 'Round', 'Score', 'PlayTime', 'Link']}
        // TODO: Could make these named
        rows={fastestGames}
      />
    )}
  </Loading>
);

//
// Connected export
export const EventFastestGames = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventFastestGames,
);
