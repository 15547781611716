import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {
  tourTeamPlayerLinkProvider,
  gameLinkProvider,
  tourTeamLinkProvider,
  eventTeamLinkProvider,
} from 'fiba/wt/utils/linkUtils';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {ServicesContext} from 'fiba/common/services/services';
import {arePlayerStats, areTeamStats, areNotables} from 'fiba/wt/ui/statistics/statsSummary/utils';
import TourStatsSummary from 'fiba/common/core/models/api/stats/TourStatsSummary';
import EmptyStats from 'fiba/wt/ui/statistics/statsSummary/EmptyStats';
import PlayerStats from 'fiba/wt/ui/statistics/statsSummary/PlayerStats';
import TeamStats from 'fiba/wt/ui/statistics/statsSummary/TeamStats';
import {NotablePerformanceStats} from 'fiba/wt/ui/statistics/statsSummary/NotablePerformanceStats';
import {StatsMinimaTable} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsSummary/StatsMinimaTable';
import StatsSummaryFooter from 'fiba/wt/ui/statistics/statsSummary/StatsSummaryFooter';
import {CategoryHeader} from 'fiba/wt/ui/statistics/statsSummary/CategoryHeader';

const EVENT_TYPE_MASTERS = 'WorldTour'; // Currently only showing masters events

interface OwnProps {
  tourId: string;
  season: string;
  eventId: string;
  isProCircuit?: boolean;
}
interface ReduxProps {
  tourStatsSummary: TourStatsSummary;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {tourId, isProCircuit}: OwnProps): ReduxProps => {
  const key = isProCircuit ? 'proCircuitStatsSummary' : 'tourStatsSummary';
  const tourStatsSummary: TourStatsSummary = state.getIn(['stats', key, tourId]);
  return {
    tourStatsSummary,
    metaPaths: [`stats/__meta/${key}/${tourId}`],
  };
};

// Page for both Tour & Pro-Circuit stats summary
export const _TourStatisticsSummary: React.SFC<Props> = props => {
  const {season, tourStatsSummary, metaPaths} = props;
  const {seasonConfig} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);

  // Curry TourTeamPlayerLinkProvider since we need to have  teamId and playerId from rowData in playerStatisticsTable
  const curriedTourTeamPlayerLinkProvider = (season: string) => (teamId: string) => (
    playerId: string,
  ) => tourTeamPlayerLinkProvider(season, teamId)(playerId);

  const curriedTourTeamLinkProvider = (season: string) => (teamId: string) =>
    tourTeamLinkProvider(season)(teamId);

  const curriedEventTeamLinkProvider = (teamInEventId: string, eventId: string) =>
    eventTeamLinkProvider(seasonConfig, season, eventId, EVENT_TYPE_MASTERS)(teamInEventId);
  // Currently only showing masters events
  const curriedEventGameLinkProvider = (gameId: string, eventId: string) =>
    gameLinkProvider(seasonConfig, season, eventId, EVENT_TYPE_MASTERS)(gameId);

  return (
    <Loading
      metaPaths={metaPaths}
      onError={() => <BlockText>There was a problem loading the tour stats</BlockText>}
    >
      {() => (
        <>
          <div className="stats-container">
            <CategoryHeader icon="player" />
            {!arePlayerStats(tourStatsSummary) ? (
              <EmptyStats />
            ) : (
              <div
                className="player-stats-list"
                style={{'--player-list-size': 15} as React.CSSProperties}
              >
                <PlayerStats
                  title={'Top player value'}
                  stats={tourStatsSummary.topPlayerPlayerValue}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <PlayerStats
                  title={'Top scorer'}
                  stats={tourStatsSummary.topPlayerScorer}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most highlights'}
                  stats={tourStatsSummary.topPlayerHighlights}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most key assists'}
                  stats={tourStatsSummary.topPlayerKeyAssists}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most rebounds'}
                  stats={tourStatsSummary.topPlayerRebounds}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most buzzerbeaters'}
                  stats={tourStatsSummary.topPlayerBuzzerBeaters}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most triple-fives'}
                  stats={tourStatsSummary.topPlayerTripleFives}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most double-fives'}
                  stats={tourStatsSummary.topPlayerDoubleFives}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most drives'}
                  stats={tourStatsSummary.topPlayerDrives}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most blocks'}
                  stats={tourStatsSummary.topPlayerBlocks}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Most two-pointers'}
                  stats={tourStatsSummary.topPlayerTwoPointers}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <PlayerStats
                  title={'Best free-throw %age'}
                  stats={tourStatsSummary.topPlayerFTPercentage}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <PlayerStats
                  title={'Best one-point %age'}
                  stats={tourStatsSummary.topPlayerOnePointPercentage}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <PlayerStats
                  title={'Best two-point %age'}
                  stats={tourStatsSummary.topPlayerTwoPointPercentage}
                  createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
              </div>
            )}
            <hr className="hr" />
            <CategoryHeader icon="team" />
            {!areTeamStats(tourStatsSummary) ? (
              <EmptyStats />
            ) : (
              <div
                className="team-stats-list"
                style={{'--team-list-size': 15} as React.CSSProperties}
              >
                <TeamStats
                  title={'Best winning %age'}
                  stats={tourStatsSummary.topTeamWinningPercentage}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Win before the limit %age'}
                  stats={tourStatsSummary.topTeamWinsBeforeLimit}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Most points scored'}
                  stats={tourStatsSummary.topTeamPointsScored}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Best shooting value'}
                  stats={tourStatsSummary.topTeamShootingValue}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Fewest points allowed'}
                  stats={tourStatsSummary.topTeamFewestPointsAllowed}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Most highlights'}
                  stats={tourStatsSummary.topTeamHighlights}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Most key assists'}
                  stats={tourStatsSummary.topTeamKeyAssists}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Most drives'}
                  stats={tourStatsSummary.topTeamDrives}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Most rebounds'}
                  stats={tourStatsSummary.topTeamRebounds}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Most buzzerbeaters'}
                  stats={tourStatsSummary.topTeamBuzzerBeaters}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                />
                <TeamStats
                  title={'Most blocks'}
                  stats={tourStatsSummary.topTeamBlocks}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Least turnovers'}
                  stats={tourStatsSummary.topTeamFewestTurnOvers}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Best free-throw %age'}
                  stats={tourStatsSummary.topTeamFTPrecentage}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Best one-point %age'}
                  stats={tourStatsSummary.topTeamOnePointPercentage}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
                <TeamStats
                  title={'Best two-point %age'}
                  stats={tourStatsSummary.topTeamTwoPointPercentage}
                  createTeamHref={curriedTourTeamLinkProvider(season)}
                  numberOfDecimals={1}
                />
              </div>
            )}
          </div>
          {areNotables(tourStatsSummary) && (
            <>
              <hr className="hr" />
              <CategoryHeader icon="singleGame" />
              <div className="stats-container">
                <div className="notable-stats-list">
                  <NotablePerformanceStats
                    title={'Top player value'}
                    stats={tourStatsSummary.playerValueInSingleGame}
                    createPlayerHref={curriedTourTeamPlayerLinkProvider(season)}
                    createTeamHref={curriedEventTeamLinkProvider}
                    createGameHref={curriedEventGameLinkProvider}
                    localizer={localizer}
                  />
                </div>
              </div>
            </>
          )}
          <hr className="hr" />
          <SubHeading extraClassName="mb2">Statistical minima</SubHeading>
          <StatsMinimaTable />
          <StatsSummaryFooter updatedAt={tourStatsSummary.updatedAt} localizer={localizer} />
        </>
      )}
    </Loading>
  );
};

export const TourStatisticsSummary = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _TourStatisticsSummary,
);
