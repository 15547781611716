import {fromJS, Map, List} from 'immutable';
import {isObject} from 'lodash';
import {createActions, createReducer} from 'fiba/common/stores/storeUtils';
import {RootState} from 'fiba/play/stores/rootStore';

const actions = createActions(__filename, {
  setFields: (id, fields) => ({id, fields}),

  clearFields: id => id,

  setField: (id, key, value) => ({id, key, value}),

  clearField: (id, key) => ({id, key}),

  setMetaField: (id, key, value) => ({id, key, value}),

  clearMetaField: (id, key) => ({id, key}),

  showAutocompletion: (id, key, showAutocompletion) => ({id, key, showAutocompletion}),

  showPassword: (id, key, showPassword) =>
    actions.setFields(id, createField(key, Map({showPassword}))),

  createValidationError: (id, validationError) => ({id, validationError}),

  clearFieldError: (id, key) => ({id, key}),

  setEditable: (id, key, isEditable) => actions.setFields(id, createField(key, Map({isEditable}))),

  // TODO v2: Promise interface for this: `submitForm(id, promise)`
  submitForm: id => id,

  submitFormSuccess: id => id,

  submitFormError: (id, errors) => ({id, errors}),

  clearAll: () => [undefined],
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    setFields: (state, {id, fields}) =>
      fields.reduce(
        (state, value, key) =>
          state.mergeIn([id, 'fields', key], isObject(value) ? fromJS(value) : fromJS({value})),
        state,
      ),

    clearFields: (state, id) => state.delete(id),

    clearAll: state => state.clear(),

    setField: (state, {id, key, value}) =>
      state.mergeIn([id, 'fields', key], isObject(value) ? fromJS(value) : fromJS({value})),

    clearField: (state, {id, key}) => state.deleteIn([id, 'fields', key]),

    setMetaField: (state, {id, key, value}) => state.setIn(['__meta', id, key], value),

    clearMetaField: (state, {id, key}) => state.deleteIn(['__meta', id, key]),

    showAutocompletion: (state, {id, key, showAutocompletion}) =>
      state.setIn([id, 'fields', key, 'showAutocompletion'], showAutocompletion),

    submitForm: (state, id) =>
      state.setIn([id, 'submitting'], true).updateIn([id, 'fields'], clearFieldErrors),

    submitFormSuccess: (state, id) => state.delete(id).setIn([id, 'submitting'], false),

    submitFormError: (state, {id, errors}) =>
      state.setIn([id, 'submitting'], false).mergeDeepIn([id, 'fields'], getFieldErrors(errors)),

    createValidationError: (state, {id, validationError}) =>
      state.mergeDeepIn([id, 'fields'], getFieldErrors(validationError)),

    clearFieldError: (state, {id, key}) => state.deleteIn([id, 'fields', key, 'error']),
  },
  fromJS({}),
);

//
// Utilities

// Simplify `{ key: { value: value } }` fields object into `{ key: value }`
export const simplifyFields = fields =>
  fields ? fields.map(field => field.get('actualValue', field.get('value'))) : Map();

// @example const validateLogin = _.partial(validateFormPromise, loginValidator)
export function validateFormPromise<T = any>(validator, form: T, ...args) {
  const error = validator(form, ...args);
  return error ? Promise.reject(error) : Promise.resolve(form);
}

export const getFormFields = (state: RootState, formId: string) =>
  state.getIn(['forms', formId, 'fields'], Map());

const createField = (key, value) => {
  if (isObject(value)) {
    return fromJS({[key]: value});
  }

  return fromJS({[key]: {value}});
};

const getFieldErrors = error => {
  if (!error || typeof error.fiba === 'undefined') {
    return Map();
  }

  const errors = getFieldErrorsFromError(error).toArray();

  return Map(errors);
};

const getFieldErrorsFromError = error =>
  error.fiba
    .get('validationErrors', List())
    .map(fieldError => [fieldError.get('field'), Map({error: fieldError.get('errorMessage')})]);

const clearFieldErrors = fields => fields && fields.map(field => field.delete('error'));
