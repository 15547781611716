import {superstyled, SuperStyled, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';

export type TableLayoutStyleProps = SuperStyled<
  | 'fontSize'
  | 'fontWeight'
  | 'textAlign'
  | 'verticalAlign'
  | 'textTransform'
  | 'lineHeight'
  | 'bgColor'
  | 'display'
  | 'align'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'measure'
  | 'shadow'
  | 'border'
  | 'borderWidth'
  | 'borderColor'
  | 'ma'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'color'
>;

//
// Table elements
// NOTE: We add tabular numerics to tables by default
export const Table = superstyled<TableLayoutStyleProps, StyledHTML<'table'>>('table', {}, 'tnum');
Table.displayName = 'Table';

export const Tr = superstyled<TableLayoutStyleProps, StyledHTML<'tr'>>('tr');
Tr.displayName = 'Tr';

export const Th = superstyled<TableLayoutStyleProps, StyledHTML<'th'>>('th');
Th.displayName = 'Th';

export const Td = superstyled<TableLayoutStyleProps, StyledHTML<'td'>>('td');
Td.displayName = 'Th';

export const Caption = superstyled<TableLayoutStyleProps, StyledHTML<'caption'>>('caption');
Caption.displayName = 'Caption';
