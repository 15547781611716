import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List, Set} from 'immutable';
import {NewsList} from 'fiba/wt/ui/newsList/NewsList';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {Player} from 'fiba/common/core/models/api/players/Player';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventTeamNewsLink, EventType} from 'fiba/wt/utils/linkUtils';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {getTeam, getTeamNewsMetaPath, getTeamNewsReferences} from 'fiba/wt/stores/teamStore';
import {getPlayerNewsReferences} from 'fiba/wt/stores/playerStore';
import {Loading} from '../loading/Loading';

interface OwnProps {
  eventId: string;
  teamId: string;
  season: string;
  siteConfig: ISiteConfig;
  children: (el: JSX.Element) => JSX.Element;
}

interface ReduxProps {
  news: List<NewsItem>;
  eventTypeData: WebData<EventType>;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {teamId, eventId}: OwnProps): ReduxProps => {
  const teamMembers: List<Player> = getTeam(state, teamId).get('teamMembers', List());
  const teamNews = getTeamNewsReferences(state, teamId, eventId).take(10);
  const playerNews = teamMembers.flatMap((player: Player) =>
    getPlayerNewsReferences(state, player.id, eventId).take(10),
  );
  // Same news item can be linked to player and team, let's remove duplicates
  const uniqueSlugs = Set(teamNews.concat(playerNews));
  const news: List<NewsItem> = uniqueSlugs
    .map((slug: string) => state.getIn(['news', 'full', slug]))
    .toList()
    .sortBy((newsItem: NewsItem) => newsItem.date)
    .take(10) as List<NewsItem>;
  const eventTypeData = getEventType(state.get('events'), eventId);

  return {
    news,
    eventTypeData,
    metaPaths: [`events/__meta/${eventId}`, getTeamNewsMetaPath(teamId, eventId)],
  };
};

/* TODO: Link to see more news */
const _TeamNews: React.FunctionComponent<Props> = ({
  season,
  news,
  teamId,
  eventId,
  eventTypeData,
  metaPaths,
  siteConfig,
  children,
}: Props) => (
  <div className="TeamNews">
    <Loading metaPaths={metaPaths}>
      {() => (
        <ViewWebData data={eventTypeData}>
          {eventType =>
            !news.isEmpty() &&
            children(
              <NewsList
                news={news}
                baseUrl={eventTeamNewsLink(
                  siteConfig.seasonConfig,
                  season,
                  eventId,
                  eventType,
                  teamId,
                )}
                columnStyle="auto"
              />,
            )
          }
        </ViewWebData>
      )}
    </Loading>
  </div>
);

export const TeamNews = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_TeamNews);
