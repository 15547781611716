/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {QualificationPath} from 'contrib/types/interfaces/api/qualifications/Qualifications';

export const defaultResultsStanding = {
  teamId: null,
  teamName: null,
  teamNameSuffix: null,
  teamNationality: null,
  teamRank: null,
  teamPoolSeed: null,
  teamSeed: null,
  seedingPoints: null,
  totalPoints: null,
  averagePoints: null,
  gamesPlayed: null,
  gamesWon: null,
  winRatio: null,
  qualificationType: null,
  useNewPointCalculation: null,
  setupType: null,
  setupGroupId: null,
  setupGroupName: null,
  setupGroupPoolCode: null,
  setupRoundCode: null,
  setupRoundNumber: null,
  setupRank: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type ResultsStandingLike = {
  [K in keyof typeof defaultResultsStanding]?: ResultsStanding[K];
};

export class ResultsStanding extends Record(defaultResultsStanding) {
  teamId?: string;
  teamName?: string;
  teamNameSuffix?: string;
  teamNationality?: string;
  teamRank?: number;
  teamPoolSeed?: number;
  teamSeed?: number;
  seedingPoints?: number;
  totalPoints?: number;
  averagePoints?: number;
  gamesPlayed?: number;
  gamesWon?: number;
  winRatio?: number;
  qualificationType?: QualificationPath['type'];
  useNewPointCalculation?: boolean;
  setupType?: string;
  setupGroupId?: string;
  setupGroupName?: string;
  setupGroupPoolCode?: string;
  setupRoundCode?: string;
  setupRoundNumber?: number;
  setupRank?: number;
  static fromJS(o: ResultsStandingLike): ResultsStanding {
    return (
      o &&
      new ResultsStanding({
        teamId: o.teamId,
        teamName: o.teamName,
        teamNameSuffix: o.teamNameSuffix,
        teamNationality: o.teamNationality,
        teamRank: o.teamRank,
        teamPoolSeed: o.teamPoolSeed,
        teamSeed: o.teamSeed,
        seedingPoints: o.seedingPoints,
        totalPoints: o.totalPoints,
        averagePoints: o.averagePoints,
        gamesPlayed: o.gamesPlayed,
        gamesWon: o.gamesWon,
        winRatio: o.winRatio,
        qualificationType: o.qualificationType,
        useNewPointCalculation: o.useNewPointCalculation,
        setupType: o.setupType,
        setupGroupId: o.setupGroupId,
        setupGroupName: o.setupGroupName,
        setupGroupPoolCode: o.setupGroupPoolCode,
        setupRoundCode: o.setupRoundCode,
        setupRoundNumber: o.setupRoundNumber,
        setupRank: o.setupRank,
      })
    );
  }
}

export default ResultsStanding;
