import React, {useContext, useRef} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {FocusElement} from 'fiba/wt/ui/focusElement/FocusElement';
import {
  ThemeContextProvider,
  lightTheme,
  darkTheme,
  ThemeContextConsumer,
} from 'fiba/wt/ui/themeContext/ThemeContext';
import {TourLayoutFooter, ISponsorSection} from 'fiba/wt/ui/tourLayout/TourLayoutFooter';
import {EventSidebarLeft} from 'fiba/wt/ui/eventLayout/EventSidebarLeft';
import {EventNavigationMobile} from 'fiba/wt/ui/eventLayout/EventNavigationMobile';
import {LevelContext, Section} from 'fiba/wt/ui/heading/HeadingContext';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {LayoutHeader} from 'fiba/wt/ui/tourLayout/TourLayout';
import {skipLinkTargetId} from 'fiba/wt/ui/skipLink/SkipLink';
import {SentryErrorBoundary} from 'fiba/wt/ui/errorBoundary/ErrorBoundary';
import {EventTabs} from 'fiba/wt/ui/eventLayout/EventLayoutUtils';
import {
  EventColorTheme,
  EventConfiguration,
} from '../eventProgrammability/EventProgrammabilityStateProvider';
import {get} from 'lodash';
import {RootState} from 'fiba/wt/stores/rootStore';
import {getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {connect} from 'fiba/common/utils/reactUtils';
import {getConferenceIdForEvent} from 'fiba/nationsleague/store';

interface IEventLayout {
  eventId: string;
  tourId: string;
  season: string;
  eventTab: EventTabs;
  components: {[key: string]: React.ReactNode};
  sponsorSections: ISponsorSection[];
}

interface EventLayoutStateProps {
  eventConfiguration: EventConfiguration;
  conferenceId: string;
  isSingleEventConference: boolean;
}

/******************/
/*** Components ***/
/******************/

/**
 * Wrapper for components used in the right sidebar.
 * Sets a fixed width and handles responsiveness
 */
export const EventRightSidebarWrapper: React.FC<{}> = ({children}) => (
  <ThemeContextProvider theme={{...lightTheme, fontSize: '7'}}>
    <Flex
      borderRadius="1"
      width="100"
      flexDirection={['column', 'column', 'row']}
      justifyContent="center"
      height="auto"
      flexGrow="0"
      flexShrink="0"
      extraClassName="EventLayout-SidebarContainer"
    >
      {children}
    </Flex>
  </ThemeContextProvider>
);

function mapStateToProps(state: RootState, {eventId, season}: IEventLayout): EventLayoutStateProps {
  const conferenceId = getConferenceIdForEvent(state, season, eventId);
  return {
    eventConfiguration: getEventConfiguration(state, eventId),
    conferenceId,
    isSingleEventConference: conferenceId === eventId,
  };
}

const _EventLayout: React.FC<IEventLayout & EventLayoutStateProps> = ({
  components,
  eventTab,
  eventConfiguration,
  isSingleEventConference,
  ...props
}) => {
  const skipLinkTargetRef = useRef<HTMLElement>(null);
  const siteConfig = useContext(SiteConfigContext);

  const bgColor = get(eventConfiguration, ['backgroundColor', 'value'], '');

  const backgroundImageUrl = get(eventConfiguration, ['backgroundImage', 'fields', 'file', 'url']);

  const logoImageUrl = get(eventConfiguration, ['eventLogo', 'fields', 'file', 'url']);

  const theme =
    get(eventConfiguration, 'colorTheme') === EventColorTheme.dark ? darkTheme : lightTheme;

  return (
    <ThemeContextProvider theme={theme}>
      <LevelContext.Provider value={{level: 1}}>
        <ThemeContextConsumer>
          {theme => (
            <Box
              bgColor={theme.bgColor}
              style={{
                backgroundColor: bgColor || 'inherit',
                backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
              }}
              color={theme.color}
              extraClassName="EventLayout min-vh-100"
            >
              <LayoutHeader
                {...props}
                siteConfig={siteConfig}
                skipLinkTargetRef={skipLinkTargetRef}
                eventConfiguration={eventConfiguration}
              >
                {/* Extra: mobile, horizontal Navigation */}
                <Box display={['block', 'block', 'none']}>
                  <EventNavigationMobile
                    siteConfig={siteConfig}
                    season={props.season}
                    eventId={props.eventId}
                    eventTab={eventTab}
                    colorTheme={eventConfiguration && eventConfiguration.colorTheme}
                    isSingleEventConference={isSingleEventConference}
                  />
                </Box>
              </LayoutHeader>

              {/* Main container */}
              <main
                id={skipLinkTargetId}
                ref={skipLinkTargetRef}
                className="pv2-m ph3-m mw9 center"
              >
                {logoImageUrl && (
                  <Box display={['block', 'block', 'block']} className={'EventLogo'}>
                    <img src={logoImageUrl}></img>
                  </Box>
                )}

                {/* Content + Sidebars (if present) */}
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  fontSize={theme.fontSize}
                  extraClassName="EventLayout-PrimaryContainer min-h-container"
                >
                  {/* Hide the sidebar on mobile, because it needs to be at the bottom and using
                   * flex order would mess up tab order
                   */}
                  <Box display={['none', 'none', 'block']}>
                    <Section>
                      {/* Desktop Sidebar */}
                      <EventSidebarLeft
                        season={props.season}
                        eventId={props.eventId}
                        eventTab={eventTab}
                        colorTheme={eventConfiguration && eventConfiguration.colorTheme}
                        isSingleEventConference={isSingleEventConference}
                      >
                        {components['secondarySidebar']}
                      </EventSidebarLeft>
                    </Section>
                  </Box>

                  {/* Content Container */}
                  {/* NOTE: The FocusElement for event pages is past the Event Navigation.
                   * This is to signal "hey, this is the content that changed" */}
                  <FocusElement is="div" className="flex-auto">
                    <Flex
                      flexDirection="column"
                      alignItems="start"
                      extraClassName="EventLayout-ContentContainer overflow-y-visible"
                    >
                      <ThemeContextConsumer>
                        {theme => (
                          <Box
                            pb="3"
                            width="100"
                            bgColor={theme.bgColor}
                            color={theme.color}
                            borderRadius="1"
                            extraClassName="min-h-container overflow-x-auto"
                          >
                            {/* Wrap errors that happen inside this route's main content components.
                             * Errors are sent to Sentry, and a simplified view show to the user.
                             */}
                            <SentryErrorBoundary componentNameForContext="EventLayout">
                              <Spacer
                                vSpace="4"
                                pt="3"
                                ph="4"
                                debugClassName="EventLayoutContentWrapper"
                              >
                                {components['content']}
                              </Spacer>
                            </SentryErrorBoundary>
                          </Box>
                        )}
                      </ThemeContextConsumer>

                      {/* Widget Sidebar (the one on the right) */}
                      <Section>{components['sidebar']}</Section>

                      {/* Secondary Sidebar is on the bottom (source-order as well) on mobile.
                       * It typically has sponsor links, so this makes the tab order match
                       * the visuals.
                       */}
                      <Box display={['block', 'block', 'none']} width="100" ph="3" pt="3">
                        <Section>
                          <ThemeContextProvider theme={{...theme, fontSize: '7'}}>
                            {components['secondarySidebar']}
                          </ThemeContextProvider>
                        </Section>
                      </Box>
                    </Flex>
                  </FocusElement>
                </Flex>
              </main>
              {/* TODO: This might need to change? */}
              <Section>
                <TourLayoutFooter {...props} />
              </Section>
            </Box>
          )}
        </ThemeContextConsumer>
      </LevelContext.Provider>
    </ThemeContextProvider>
  );
};

export const EventLayout = connect<EventLayoutStateProps, {}, IEventLayout>(mapStateToProps)(
  _EventLayout,
);
