import React, {useContext} from 'react';
import {List, Map} from 'immutable';

import Player from 'fiba/common/core/models/api/players/Player';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import TeamGameStats from 'fiba/common/core/models/api/stats/TeamGameStats';
import PlayerGameStats from 'fiba/common/core/models/api/stats/PlayerGameStats';
import {getTeamDisplayName} from 'fiba/common/core/results';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import {getPlayerName, PlayerStoreState} from 'fiba/wt/stores/playerStore';

import {Box} from 'fiba/wt/ui/box/Box';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {valueOrPlaceholder, decimalOrPlaceholder} from 'fiba/wt/ui/gameBoxScore/gameBoxScoreUtils';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {VisuallyHidden} from '../visuallyHidden/VisuallyHidden';

interface IIndividualStats {
  identifier: string;
  totalPointsMade?: number;
  shootingEfficiency?: number;
  shootingValue?: number;
  playerValue?: number;
  highlights?: number;
  onePointsMade?: number;
  onePointsAttempted?: number;
  onePointsMadeRatio?: number;
  twoPointsMade?: number;
  twoPointsAttempted?: number;
  twoPointsMadeRatio?: number;
  freeThrowsMade?: number;
  freeThrowsAttempted?: number;
  freeThrowsMadeRatio?: number;
  extraFreeThrows?: number;
  dunks?: number;
  keyAssists?: number;
  buzzerBeaters?: number;
  drives?: number;
  turnovers?: number;
  blockedShots?: number;
  totalRebounds?: number;
  offensiveRebounds?: number;
  defensiveRebounds?: number;
  jerseyNumber?: string;
  name: string;
  hgl: number;
}

// Column spec
type ColumnSpec = IColumnSpec<IIndividualStats>;

type PlayerColumnsSpec = Record<TableColumn, ColumnSpec>;

type getPlayerColumnsSpec = () => PlayerColumnsSpec;

interface Props {
  captionId: string;
  gameResult: ResultsGameDetails;
  teamStats: TeamGameStats;
  playerStats: List<PlayerGameStats>;
  reducedColumns: boolean; // less data exists for some games, i.e. legacy and qualifier games
  players: PlayerStoreState;
}

/*
 * Exported component
 */
const GameStatsPlayerTable: React.FunctionComponent<Props> = ({
  captionId,
  teamStats,
  gameResult,
  playerStats,
  reducedColumns,
  players,
}) => {
  const {localizer} = useContext(ServicesContext);
  const playerMap = getPlayerMap(playerStats, players);
  const columns = reducedColumns ? reducedTableColumns : allColumns;

  return (
    <Box extraClassName="GameStatsPlayerTable">
      {/* Horizontal table layout */}
      <Box display={['block']} extraClassName="GameStatsPlayerTable-Horizontal">
        <DataTable<TableColumn, IIndividualStats>
          rows={createStatistics(playerStats, teamStats, gameResult, playerMap, localizer)}
          captionId={`${captionId}-horizontal`}
          caption={
            /* NOTE: We want this header for a better document outline (heading hierarchy).
             * However, due to the tabs being there, and discussions internally, we have decided
             * to not have them present visually. Ideally we would, but we don't.
             */
            <VisuallyHidden>
              <SubHeading fontSize="3">
                Box Score {teamName(teamStats.teamId, gameResult, localizer)}
              </SubHeading>
            </VisuallyHidden>
          }
          columns={columns.slice()} // create a shallow copy make array immutable so typescript is happy
          columnsSpec={getPlayerColumnsSpec()}
          headerStyleProps={{bgColor: 'fullwhite', fontSize: '7', fontWeight: '4', ph: '1'}}
          headerExtraClassName="dark-20"
          rowExtraClassName="GameStatsPlayerTable-TableRow striped--light-even bb bw1 b--silver-20 striped--fullwhite"
          frozenHeaders={true}
        />
      </Box>
    </Box>
  );
};

/*
 * Helper functions
 */
const teamName = (teamId: string, gameResult: ResultsGameDetails, localizer: LocalizerService) => {
  if (gameResult.homeTeam.teamInEventId === teamId) {
    return getTeamDisplayName(gameResult.homeTeam, localizer);
  }
  if (gameResult.awayTeam.teamInEventId === teamId) {
    return getTeamDisplayName(gameResult.awayTeam, localizer);
  }
  return 'N/A';
};

const createStatistics = (
  playerStats: List<PlayerGameStats>,
  teamStats: TeamGameStats,
  gameResult: ResultsGameDetails,
  playerMap: Map<string, Player>,
  localizer: LocalizerService,
): List<IIndividualStats> => {
  const players = createAllPlayersStatistics(playerStats, teamStats, playerMap);
  // Also add the team and total stats as last rows in the player table
  return players.push(createTeamStatistics(teamStats)).push(createTeamTotalStatistics(teamStats));
};

const createPlayerStatistics = (
  pStat: PlayerGameStats,
  playerMap: Map<string, Player>,
): IIndividualStats => ({
  identifier: pStat.jerseyNumber,
  totalPointsMade: pStat.totalPointsMade,
  totalRebounds: pStat.totalRebounds,
  shootingEfficiency: pStat.shootingEfficiency,
  shootingValue: pStat.shootingValue,
  playerValue: pStat.playerValue,
  highlights: pStat.highlights,
  onePointsMade: pStat.onePointsMade,
  onePointsAttempted: pStat.onePointsAttempted,
  twoPointsMade: pStat.twoPointsMade,
  twoPointsAttempted: pStat.twoPointsAttempted,
  freeThrowsMade: pStat.freeThrowsMade,
  freeThrowsAttempted: pStat.freeThrowsAttempted,
  dunks: pStat.dunks,
  keyAssists: pStat.keyAssists,
  buzzerBeaters: pStat.buzzerBeaters,
  drives: pStat.drives,
  turnovers: pStat.turnovers,
  blockedShots: pStat.blockedShots,
  offensiveRebounds: pStat.offensiveRebounds,
  defensiveRebounds: pStat.defensiveRebounds,
  name: getPlayerName(playerMap.get(pStat.playerId)),
  hgl: pStat.highlights,
});

const createAllPlayersStatistics = (
  playerStats: List<PlayerGameStats>,
  teamStats: TeamGameStats,
  playerMap: Map<string, Player>,
): List<IIndividualStats> => {
  const players = filterPlayersByTeamId(playerStats, teamStats.teamId).map(pStat =>
    createPlayerStatistics(pStat, playerMap),
  ) as List<IIndividualStats>;
  return players;
};

const createTeamTotalStatistics = (teamStat: TeamGameStats): IIndividualStats => ({
  identifier: '',
  totalPointsMade: teamStat.totalPoints,
  totalRebounds: teamStat.totalRebounds,
  shootingEfficiency: teamStat.shootingEfficiency,
  shootingValue: teamStat.shootingValue,
  highlights: teamStat.highlights,
  onePointsMade: teamStat.onePointsMade,
  onePointsAttempted: teamStat.onePointsAttempted,
  onePointsMadeRatio: teamStat.onePointsMadeRatio,
  twoPointsMade: teamStat.twoPointsMade,
  twoPointsAttempted: teamStat.twoPointsAttempted,
  twoPointsMadeRatio: teamStat.twoPointsMadeRatio,
  freeThrowsMade: teamStat.freeThrowsMade,
  freeThrowsAttempted: teamStat.freeThrowsAttempted,
  freeThrowsMadeRatio: teamStat.freeThrowsMadeRatio,
  extraFreeThrows: teamStat.extraFreeThrows,
  dunks: teamStat.dunks,
  keyAssists: teamStat.keyAssists,
  buzzerBeaters: teamStat.buzzerBeaters,
  drives: teamStat.drives,
  turnovers: teamStat.turnovers,
  blockedShots: teamStat.blockedShots,
  offensiveRebounds: teamStat.offensiveRebounds,
  defensiveRebounds: teamStat.defensiveRebounds,
  name: 'TEAM TOTAL',
  hgl: teamStat.highlights,
});

const createTeamStatistics = (teamStat: TeamGameStats): IIndividualStats => ({
  identifier: '',
  totalPointsMade: null,
  totalRebounds: teamStat.teamRebounds,
  defensiveRebounds: teamStat.teamDefensiveRebounds,
  shootingEfficiency: null,
  shootingValue: null,
  highlights: null,
  onePointsMade: null,
  onePointsAttempted: null,
  onePointsMadeRatio: null,
  twoPointsMade: null,
  twoPointsAttempted: null,
  twoPointsMadeRatio: null,
  freeThrowsMade: null,
  freeThrowsAttempted: null,
  freeThrowsMadeRatio: null,
  extraFreeThrows: null,
  dunks: null,
  keyAssists: null,
  buzzerBeaters: null,
  drives: null,
  turnovers: teamStat.teamTurnovers,
  blockedShots: null,
  offensiveRebounds: teamStat.teamOffensiveRebounds,
  name: 'TEAM',
  hgl: null,
});

const filterPlayersByTeamId = (
  playerStats: List<PlayerGameStats>,
  teamId: string,
): List<PlayerGameStats> => {
  const foo = playerStats
    .filter(p => p.teamId === teamId)
    .sort((a, b) => (parseInt(a.jerseyNumber, 10) || 0) - (parseInt(b.jerseyNumber, 10) || 0));
  // TODO: How to make these types to work properly?
  return foo as List<PlayerGameStats>;
};

/*
 *  Column spec functions
 */
const getPlayerColumnsSpec: getPlayerColumnsSpec = () => ({
  NUMBER: createSpec('No.', 'Number', 'identifier', 'number'),
  NAME: createSpec('Name', 'Name', 'name', 'text'),
  PTS: createSpec('PTS', 'Total points', 'totalPointsMade', 'number'),
  REB: createSpec('REB', 'Rebounds', 'totalRebounds', 'number'),
  HGL: createSpec('HGL', 'Highlights', 'highlights', 'number'),
  PVAL: createSpec('P-VAL', 'Player value', 'playerValue', 'number', v =>
    decimalOrPlaceholder(v, 1),
  ),
  SEFF: createSpec(
    'S-EFF',
    'Shooting efficiency',
    'shootingEfficiency',
    'number',
    decimalOrPlaceholder,
  ),
  SVAL: createSpec('S-VAL', 'Shooting value', 'shootingValue', 'number', v =>
    decimalOrPlaceholder(v, 1),
  ),
  PT1: createAttemptsSpec(
    '1PT',
    'One points made of attempts',
    'onePointsMade',
    'onePointsAttempted',
    'onePointsMadeRatio',
  ),
  PT2: createAttemptsSpec(
    '2PT',
    'Two points made of attempts',
    'twoPointsMade',
    'twoPointsAttempted',
    'twoPointsMadeRatio',
  ),
  FT: createAttemptsSpec(
    'FT',
    'Free throws made of attempts',
    'freeThrowsMade',
    'freeThrowsAttempted',
    'freeThrowsMadeRatio',
  ),
  DNK: createSpec('DNK', 'Dunks', 'dunks', 'number'),
  BS: createSpec('BS', 'Blocked shots', 'blockedShots', 'number'),
  KAS: createSpec('KAS', 'Key assists', 'keyAssists', 'number'),
  BZR: createSpec('BZR', 'Buzz beaters', 'buzzerBeaters', 'number'),
  DRV: createSpec('DRV', 'Drives', 'drives', 'number'),
  OREB: createSpec('OREB', 'Offensive rebounds', 'offensiveRebounds', 'number'),
  DREB: createSpec('DREB', 'Defensive rebounds', 'defensiveRebounds', 'number'),
  TO: createSpec('TO', 'Turnovers', 'turnovers', 'number'),
});

const createSpec = (
  displayName: string,
  readerName: string,
  field: string,
  dataType,
  formatter?,
) => ({
  name: displayName,
  srName: readerName,
  dataType,
  renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
    <Td {...getTdProps()}>
      {formatter ? formatter(rowData[field]) : valueOrPlaceholder(rowData[field])}
    </Td>
  ),
});

const attemptsValue = (made, attempts) => {
  if (made != null || attempts != null) {
    return (
      <span className="ws-nowrap">
        {attempts == null
          ? valueOrPlaceholder(made)
          : `${valueOrPlaceholder(made)} / ${valueOrPlaceholder(attempts)}`}
      </span>
    );
  }
};

const createAttemptsSpec = (displayName: string, readerName: string, made, attempts, ratio) => ({
  name: displayName,
  srName: readerName,
  dataType: 'number' as const,
  renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
    <Td {...getTdProps()}>{attemptsValue(rowData[made], rowData[attempts])}</Td>
  ),
});

const getPlayerMap = (playerStats: List<PlayerGameStats>, playerStore: PlayerStoreState): Player =>
  playerStats.reduce(
    (map, player) => map.set(player.playerId, playerStore.get(player.playerId)),
    Map<string, Player>(),
  );

/*

/*
 * Table columns and legendary stuff
 */
const allColumns = [
  'NUMBER',
  'NAME',
  'PTS',
  'REB',
  'HGL',
  'PVAL',
  'SEFF',
  'PT1',
  'PT2',
  'FT',
  'KAS',
  'DRV',
  'DNK',
  'BS',
  'BZR',
  'OREB',
  'DREB',
  'TO',
] as const;

type TableColumn = typeof allColumns[number];

const reducedTableColumns = ['NUMBER', 'NAME', 'PTS', 'PT1', 'PT2', 'FT'] as const;

export default GameStatsPlayerTable;
