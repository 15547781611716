import * as React from 'react';
import {List} from 'immutable';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {P} from 'fiba/wt/ui/text/Text';
import {ServicesContext} from 'fiba/common/services/services';
import {parseLocalDatetime} from 'fiba/common/utils/schedule';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';

interface NewsListCondensedOwnProps {
  baseUrl: string;
}

interface NewsListCondensedStateProps {
  news: List<NewsItem>;
}

type NewsListCondensedProps = NewsListCondensedStateProps & NewsListCondensedOwnProps;

// TODO: tags/listTags?
export const NewsListCondensed: React.FunctionComponent<NewsListCondensedProps> = ({
  news,
  baseUrl,
}: NewsListCondensedProps) => (
  <CardContainer pa="0">
    <ul className="NewsListCondensed w-100 flex flex-row flex-wrap justify-center items-start">
      {news.map(newsItem => (
        <li className="w-100 pa3 bb b--silver-20" key={newsItem.id}>
          <a className="db underline-focus link--bare dark-50" href={`${baseUrl}/${newsItem.slug}`}>
            <P mt="0" mb="2" fontWeight="6" fontSize="7">
              {newsItem.title}
            </P>
          </a>
          <P mt="0" mb="0" fontSize="7">
            <ServicesContext.Consumer>
              {({localizer}) => (
                <time dateTime={newsItem.date}>
                  {localizer.format('{date, date, full}', {
                    date: parseLocalDatetime(newsItem.date),
                  })}
                </time>
              )}
            </ServicesContext.Consumer>
          </P>
        </li>
      ))}
    </ul>
  </CardContainer>
);
