import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {ServicesContext} from 'fiba/common/services/services';
import {getLocalDateFromString} from 'fiba/common/utils/schedule';

import * as Media from 'fiba/wt/stores/mediaStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';

import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Video} from 'contrib/types/interfaces/api/media/Video';
import {List} from 'immutable';
import {YoutubeEmbed} from 'fiba/wt/ui/youtubeEmbed/YoutubeEmbed';
import {sortedUniqBy} from 'lodash';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {Flex} from 'fiba/wt/ui/flex/Flex';

interface OwnProps {
  url: string;
  width: string;
  height: string;
  aspectRatio: StyleProp<'aspectRatio'>;
  limit?: number;
  highlightFirstVideo?: boolean;
}

interface ReduxProps {
  videos: List<Video>;
  metaPath?: string;
}

interface DispatchProps {}

type Props = OwnProps & ReduxProps & DispatchProps;

export const mapStateToProps = (state, {url}: OwnProps): ReduxProps => ({
  // Note that in the store, the Playlist URL is used a key after being run through encodeURIComponent.
  // The reason behind this is that the Loading component splits metaPaths by / characters, which does not
  // work if you have / characters in the key (e.g. in a YouTube URL)
  videos: state.getIn(['media', 'videos', 'playlists', encodeURIComponent(url)], List()),
  metaPath: `media/__meta/videos/playlists/${encodeURIComponent(url)}`,
});

// Conenct this component to the Content API so that when passed a PlayList URL from Contentful it fires off a request to load the complete list of YouTube videos.
const mapDispatchToProps = (dispatch, {url}: OwnProps): DispatchProps => {
  dispatch(Media.actions.loadVideosByYouTubePlaylistUrl(url));
  return {};
};

const _YoutubeVideoGrid: React.FC<Props> = ({
  width,
  height,
  aspectRatio,
  metaPath,
  videos,
  limit,
  highlightFirstVideo,
}) => {
  const {localizer} = useContext(ServicesContext);
  return (
    <Loading
      metaPaths={[metaPath]}
      onError={() => <BlockText>There was a problem loading the videos</BlockText>}
    >
      {() => {
        // YouTube playlists can contain duplicates. Remove this as it keeps the page cleaner (we display all videos as a grid and duplicates may look weired)
        const uniqueVideos = sortedUniqBy<Video>(videos.toArray(), video => video.url);

        // If a limit for videos has been set, make sure to only render that many videos (used for summaries)
        const selectedVideos = limit ? uniqueVideos.slice(0, limit) : uniqueVideos;

        // If have a large number of videos, we should make sure the users are actually looking at the embed
        // before trying to load it. If a user scrolls the a large list very quickly, we end up trying to load
        // all embeds at once which can slow down the browser.
        // Let's arbitrarily consider a large list to be more than 8 videos and just wait a little before
        // attempting to load them.
        const delayShouldRenderCheck = selectedVideos.length > 8;

        return (
          <Flex flexDirection="row" flexWrap="wrap" justifyContent="start">
            {selectedVideos.map(video => {
              return (
                <Flex
                  mt="1"
                  mb="3"
                  ph="2"
                  flexDirection="column"
                  flex="auto"
                  flexGrow="0"
                  flexOrder="1"
                  width={['100', '100', '50', '25']}
                  justifyContent="start"
                  extraClassName={highlightFirstVideo ? 'hightlight-first-video' : ''}
                  key={video.url}
                >
                  <YoutubeEmbed
                    key={video.url}
                    aspectRatio={aspectRatio}
                    width={width}
                    height={height}
                    url={video.url}
                    delayShouldRenderCheck={delayShouldRenderCheck}
                  />
                  <Heading mt="2" mb="1" fontSize="4">
                    {video.title}
                  </Heading>
                  <Flex flexDirection="row" flex="auto" justifyContent="start">
                    <BlockText fontSize="6">
                      {getLocalDateFromString(video.uploadedOn, localizer)}
                    </BlockText>
                    <BlockText ml="1" mr="1" fontSize="6">
                      &bull;
                    </BlockText>
                    <BlockText fontSize="6">{video.views} views</BlockText>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        );
      }}
    </Loading>
  );
};

export const YoutubeVideoGrid = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(_YoutubeVideoGrid);
