import React, {useContext} from 'react';
import cx from 'classnames';
import {superstyled, SuperStyled, StyledHTML} from 'fiba/wt/ui/stylingAPI/makeStyled';

//
// STYLE PROPS

// Tuning knobs for this component
export type StyleProps = SuperStyled<
  | 'color'
  | 'bgColor'
  | 'display'
  | 'align'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'measure'
  | 'shadow'
  | 'ma'
  | 'position'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'borderRadius'
>;

const StyledArticle = superstyled<StyleProps, StyledHTML<'div'>>('div', {}, 'Article');

//
// THEME CONTEXT

// External interface
export interface ArticleThemeProps {
  articleSize: ArticleSize;
}

export enum ArticleSize {
  Regular = 'Article--regular',
  Small = 'Article--small',
}

const defaultSize = {articleSize: ArticleSize.Regular};
export const ArticleThemeContext = React.createContext<ArticleThemeProps>(defaultSize);

//
// THEMED ARTICLE

type Props = StyleProps & {extraClassName?: string};

/** Display an editorial article with default styles. */
export const Article: React.FC<Props> = ({extraClassName, ...styleProps}) => {
  const {articleSize} = useContext(ArticleThemeContext);
  return <StyledArticle {...styleProps} extraClassName={cx(articleSize, extraClassName)} />;
};
