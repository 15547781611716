import {List, Map} from 'immutable';
import {assetPath} from 'fiba/common/utils/reactUtils';
import {Player} from 'fiba/common/core/models/api/players/Player';

export const UPLOADABLE_IMAGE_MIME_TYPES = List([
  'image/png',
  'image/jpeg',
  'image/pjpeg',
  'image/gif',
  'image/tiff',
  'image/x-tiff',
  'image/bmp',
  'image/x-windows-bmp',
]);

const MB_AS_BYTES = 1048576;
export const UPLOADABLE_IMAGE_MAX_SIZE_MB = 3;
export const UPLOADABLE_IMAGE_MAX_SIZE_BYTES = UPLOADABLE_IMAGE_MAX_SIZE_MB * MB_AS_BYTES;

export const isUploadableImageMimeTypeValid = (mime: string) =>
  UPLOADABLE_IMAGE_MIME_TYPES.indexOf(mime) >= 0;

export const isUploadableImageSizeValid = (bytes: number) =>
  bytes <= UPLOADABLE_IMAGE_MAX_SIZE_BYTES;

type ProfileImageSize = 'tiny' | 'small' | 'medium' | 'large';

// Even though players do have large images available in the media API,  we do not use them currently because
// 1. medium size has been enough so far
// 2. you have to fetch the urls from media API which starts accumulating requests on multiplayer-pages, like /teams on WT
type PlayerProfileImageSize = Exclude<ProfileImageSize, 'large'>;

// Maps image sizes to OEM width
const PROFILE_IMAGE_SIZE_MAP: Record<ProfileImageSize, number> = {
  tiny: 80,
  small: 160,
  medium: 480,
  large: 640,
};

//
// Profile and default Image
type DefaultImageSite = 'play' | 'wt';

/**
 * Get a player's profile image, with the default being one for Play
 */
export const getProfileImagePlay = (player: Player, size: PlayerProfileImageSize = 'medium') =>
  getProfileImage('play', player, size);

/**
 * Get a player's profile image, with the default being one for WT
 */
export const getProfileImageWt = (player: Player, size: PlayerProfileImageSize = 'medium') =>
  getProfileImage('wt', player, size);

/**
 * Get a player's profile image, with the a default being one for the site
 */
export const getProfileImage = (
  site: DefaultImageSite,
  player: Player,
  size: ProfileImageSize = 'medium',
) => {
  switch (size) {
    case 'tiny':
      return player.get('imageTinyUrl') || getDefaultImage(site, player);
    case 'small':
      return player.get('imageSmallUrl') || getDefaultImage(site, player);
    case 'medium':
    default:
      return player.get('imageMediumUrl') || getDefaultImage(site, player);
  }
};

/**
 * Get the default profile image for the corresponding site
 */
const getDefaultImage = (defaultImageSite: DefaultImageSite, player: Player) => {
  switch (defaultImageSite) {
    case 'play': {
      // Play has separate male / female images
      return assetPath(
        `img/default-icons/profile-${(player.get('gender') || 'male').toLowerCase()}.svg`,
      );
    }
    case 'wt':
      // WT only has one image
      return assetPath(`img/default-icons/profile-male.svg`);
  }
};

/**
 * Build a srcSet-compatible string from al ist of player image sizes
 */
export const getProfileImageSrcSet = (
  site: DefaultImageSite,
  player: Player,
  sizes: PlayerProfileImageSize[],
) =>
  sizes
    .map(size => `${getProfileImage(site, player, size)} ${PROFILE_IMAGE_SIZE_MAP[size]}w`)
    .join(', ');

//
// Cover Image

/**
 * Get the profile cover image a player, or the default (specified by the API)
 */
export const getProfileCoverImage = (player: Player) =>
  player.get('coverImageUrl') || player.get('coverImageFallbackUrl');

/**
 * Get the logo image for a tour, or the default, specified hardcoded
 */
export const getTourLogoImage = (tour, size = 'medium') => {
  switch (size) {
    case 'tiny':
      return tour.get('imageTinyUrl') || assetPath('img/default-icons/tour.png');
    case 'small':
      return tour.get('imageSmallUrl') || assetPath('img/default-icons/tour.png');
    case 'large':
      return tour.get('imageLargeUrl') || assetPath('img/default-icons/tour.png');
    case 'medium':
    default:
      return tour.get('imageMediumUrl') || assetPath('img/default-icons/tour.png');
  }
};

/**
 * Get the logo image for an event, or the default, specified hardcoded
 */
export const getEventLogoImage = (event, size = 'medium') => {
  switch (size) {
    case 'tiny':
      return event.get('imageTinyUrl') || assetPath('img/default-icons/event.png');
    case 'small':
      return event.get('imageSmallUrl') || assetPath('img/default-icons/event.png');
    case 'large':
      return event.get('imageLargeUrl') || assetPath('img/default-icons/event.png');
    case 'medium':
    default:
      return event.get('imageMediumUrl') || assetPath('img/default-icons/event.png');
  }
};

/**
 * Get the logo image for an organiser, or the default, specified hardcoded
 */
export const getOrganizerLogoImage = (organizer: Map<string, any>) => {
  return organizer.get('imageMediumUrl') || assetPath('img/default-icons/event.png');
};
