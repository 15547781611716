import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import loadable from 'loadable-components';
import {TweetProps} from 'react-twitter-widgets';

// Asynchronously-loaded Tweet component
const Tweet = loadable<TweetProps>(() =>
  import(/* webpackChunkName: 'react-twitter-widgets' */ 'react-twitter-widgets').then(
    module => module.Tweet,
  ),
);

interface ITwitterFeed {
  season: string;
}

interface ITwitterFeedState {
  tweets: List<string>;
  metaPaths?: string[];
}

export const mapStateToProps = (state, {season}: ITwitterFeed): ITwitterFeedState => {
  const tweets: List<string> = state.getIn(['media', 'tweets'], List());
  const metaPaths = [`media/__meta/tweets`];

  return {
    tweets,
    metaPaths,
  };
};

const _TwitterFeed: React.SFC<ITwitterFeed & ITwitterFeedState> = ({metaPaths, tweets}) => (
  <Flex flexWrap="wrap" justifyContent="center" alignItems="stretch" debugClassName="TwitterFeed">
    <Loading metaPaths={metaPaths}>
      {() => (
        <React.Fragment>
          {tweets.map((id, index) => (
            <Box key={index} pa="1">
              <Tweet tweetId={id} options={{width: '300'}} />
            </Box>
          ))}
        </React.Fragment>
      )}
    </Loading>
  </Flex>
);

export const TwitterFeed = connect<ITwitterFeedState, {}, ITwitterFeed>(mapStateToProps)(
  _TwitterFeed,
);
