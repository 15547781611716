import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';

export interface EventGuideSnippetProps {
  tourId: string;
  season: string;
  eventId: string;
}

export const EventGuideSnippet: React.FunctionComponent<EventGuideSnippetProps> = ({
  tourId,
  season,
  eventId,
}) => (
  <div className="EventGuideSnippet">
    <ContentfulArticle
      contentPath={`events/${eventId}`}
      articleType={ArticleTypes.EventGuideSnippet}
    />
  </div>
);
