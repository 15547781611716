import {Map, List} from 'immutable';
import {
  isFullyConfirmedRegistrationType,
  isOrganizerConfirmedRegistrationType,
  hasValidRegistrationType,
  isEditableRegistrationType,
} from 'fiba/common/core/category';
import {Team, TeamLike} from 'fiba/common/core/models/api/teams/Team';
import Player from 'fiba/common/core/models/api/players/Player';
import {
  getValidUserEmail,
  getUserId,
  getTokenPlayerId,
  isEditTeamSession,
  isEditMultipleTeamsSession,
  isTeamEmailVerifiedSession,
} from 'fiba/common/core/user';
import {PlayerStatusInTeam, isPlayerStatusAccepted} from 'fiba/common/core/player';
import Category from 'fiba/common/core/models/api/events/Category';
import {RegistrationStage} from 'fiba/common/core/registration';

export const MIN_NUMBER_OF_REQUIRED_PLAYERS = 3;

export enum TeamStatus {
  'AwaitingRegistration' = 'AwaitingRegistration',
  'Cancelled' = 'Cancelled',
  'CategoryWaitList' = 'CategoryWaitList',
  'CheckedIn' = 'CheckedIn',
  'Deleted' = 'Deleted',
  'EmptySpot' = 'EmptySpot',
  'Forfeit' = 'Forfeit',
  'Imported' = 'Imported',
  'Invited' = 'Invited',
  'Official' = 'Official',
  'Registered' = 'Registered',
  'RegisteredUnpaid' = 'RegisteredUnpaid',
  'Unknown' = 'Unknown',
  'Unofficial' = 'Unofficial',
  'Unregistered' = 'Unregistered',
  'Validated' = 'Validated',
}

export const mapTeamStatusOrganizerPov = (team: Team, translations: Map<string, string>) => {
  switch (team.get('teamStatus')) {
    case TeamStatus.Deleted:
      return translations.get('TEAM_STATUS_REGISTRATION_DELETED');
    case TeamStatus.Unregistered:
      return translations.get('TEAM_STATUS_REGISTRATION_UNREGISTERED');
    case TeamStatus.Cancelled:
      return translations.get('TEAM_STATUS_REGISTRATION_CANCELLED');
    case TeamStatus.Unknown:
      return translations.get('TEAM_STATUS_REGISTRATION_UNKNOWN');
    case TeamStatus.Forfeit:
      return translations.get('TEAM_STATUS_REGISTRATION_FORFEIT');
    case TeamStatus.EmptySpot:
      return translations.get('TEAM_STATUS_REGISTRATION_EMPTY_SPOT');
    case TeamStatus.AwaitingRegistration:
      return ''; // This is the "standard" status for a team pre-event. Many organizers never update it to anything else, so it was decided to not show it at all to the user as it is a false promise something still needs to happen
    case TeamStatus.CategoryWaitList:
      return translations.get('TEAM_STATUS_REGISTRATION_WAITLIST');
    case TeamStatus.Invited:
      return translations.get('TEAM_STATUS_REGISTRATION_INVITED');
    case TeamStatus.Imported:
      return translations.get('TEAM_STATUS_REGISTRATION_IMPORTED');
    case TeamStatus.RegisteredUnpaid:
      return translations.get('TEAM_STATUS_REGISTRATION_REGISTERED_UNPAID');
    case TeamStatus.Registered:
      return translations.get('TEAM_STATUS_REGISTRATION_REGISTERED');
    case TeamStatus.CheckedIn:
      return translations.get('TEAM_STATUS_REGISTRATION_CHECKED_IN');
    case TeamStatus.Validated:
      return translations.get('TEAM_STATUS_REGISTRATION_VALIDATED');
    case TeamStatus.Unofficial:
      return translations.get('TEAM_STATUS_REGISTRATION_UNOFFICIAL');
    case TeamStatus.Official:
      return translations.get('TEAM_STATUS_REGISTRATION_OFFICIAL');
    default:
      return translations.get('TEAM_STATUS_REGISTRATION_UNKNOWN');
  }
};

export const numberOfAcceptedPlayers = (team: Team) => {
  return team.teamMembers ? team.teamMembers.filter(isPlayerStatusAccepted).size : 0;
};

export const hasEnoughPlayers = (team: Team) =>
  numberOfAcceptedPlayers(team) >= MIN_NUMBER_OF_REQUIRED_PLAYERS;

export const isGoodStatus = (team: Team) => {
  switch (team.get('teamStatus')) {
    case TeamStatus.Deleted:
    case TeamStatus.Unregistered:
    case TeamStatus.Cancelled:
    case TeamStatus.Unknown:
    case TeamStatus.Forfeit:
      return false;
    case TeamStatus.EmptySpot:
    case TeamStatus.AwaitingRegistration:
    case TeamStatus.CategoryWaitList:
    case TeamStatus.Invited:
    case TeamStatus.Imported:
    case TeamStatus.RegisteredUnpaid:
    case TeamStatus.Registered:
    case TeamStatus.CheckedIn:
    case TeamStatus.Validated:
    case TeamStatus.Unofficial:
    case TeamStatus.Official:
      return true;
    default:
      return false;
  }
};

export const isOrganizerConfirmed = (team: Team) => {
  return team.get('teamStatus') === 'Registered';
};

export const mapPlayerTeamStatus = (player: Player, translations: Map<string, string>) => {
  switch (player.get('statusInTeam')) {
    case PlayerStatusInTeam.Accepted:
      return translations.get('PLAYER_STATUS_ACCEPTED');
    case PlayerStatusInTeam.Pending:
      return translations.get('PLAYER_STATUS_PENDING');
    case PlayerStatusInTeam.WaitList:
      return translations.get('PLAYER_STATUS_WAITLIST');
    case PlayerStatusInTeam.Rejected:
      return translations.get('PLAYER_STATUS_REJECTED');
    // TODO: More statuses
    default:
      return translations.get('PLAYER_STATUS_UNKNOWN');
  }
};

export function findPlayerInTeamById(team: Team, playerId: string) {
  const teamMembers = team.teamMembers || List();
  return teamMembers.findEntry(other => other.id === playerId);
}

export function isPlayerInTeamById(team: Team, playerId: string) {
  const teamMembers = team.teamMembers || List();
  return teamMembers.findIndex(other => other.id === playerId) > -1;
}

export function findPlayerEntryInTeam(team: Team, player: Player) {
  if (!team.teamMembers) {
    // Since instantiating a Team object results in a teamMembers property set to undefined
    // lets catch this early to save some work
    return null;
  } else if (player.id) {
    return findPlayerInTeamById(team, player.id);
  }
  return null;
}

export function removePlayerFromTeam(team: Team, player: Player) {
  const entry = findPlayerEntryInTeam(team, player);

  if (!entry) {
    return team;
  }

  return team.update('teamMembers', (teamMembers = List()) => teamMembers.delete(entry[0])) as Team;
}

export const isTeamLead = (user, team: Team) => {
  return team.email && team.email === getValidUserEmail(user);
};

export const isTeamLeadByToken = (user, team: Team) => {
  const editTeamSession = isEditTeamSession(user) || isEditMultipleTeamsSession(user);
  const teamEmailVerifiedSession = isTeamEmailVerifiedSession(user);

  const editTeamSessionRights =
    editTeamSession &&
    (user.getIn(['fibaState', 'teamId']) === team.id ||
      !!user
        .getIn(['fibaState', 'teams'], List())
        .find(teamItem => teamItem.get('id') === team.id)); // Tricky: a team loaded from a 'fibaState' cookie is not a Team but a Map<string, any>

  const verifiedTeamEmailRights =
    teamEmailVerifiedSession &&
    user.getIn(['fibaState', 'teamEmail']) === team.email &&
    user.getIn(['fibaState', 'divisionId']) === team.categoryId;

  return editTeamSessionRights || verifiedTeamEmailRights;
};

export const getTokenTeams = (user, eventId, categories) => {
  if (!eventId || eventId !== user.getIn(['fibaState', 'eventId'])) {
    return List();
  }

  const teams = user.getIn(['fibaState', 'teams'], List());
  const category = categories.find(
    category => category.get('id') === user.getIn(['fibaState', 'divisionId']),
  );
  return teams.map(team => team.set('categoryId', category.get('id')));
};

export const hasTeamAccess = (user, team: Team) =>
  isTeamLead(user, team) ||
  isTeamLeadByToken(user, team) ||
  isPlayerInTeamById(team, getUserId(user)) ||
  isPlayerInTeamById(team, getTokenPlayerId(user));

export const getUserTeams = (
  teams: List<Team>,
  user: Map<any, any>,
  eventId: string,
  categories: List<Category>,
): List<Team> => {
  const tokenTeams = getTokenTeams(user, eventId, categories);
  const allUserTeams = teams
    .filter(team => team.eventId === eventId)
    .filter(team => hasTeamAccess(user, team))
    .toList()
    .concat(tokenTeams);

  // We may have duplicate team objects in the list, so let's filter by 'id' to get uniques only
  // Tricky: a team loaded from a 'fibaState' cookie is not a Team but a Map<string, any>
  return allUserTeams
    .filter((team, index, self) => self.findKey(t => t.get('id') === team.get('id')) === index)
    .toList();
};

// We use true as the not set value as a safety measure, as team cancellations with scheduled games are problematic
export const canTeamCancelParticipation = (team: Team, category: Category) =>
  team.hasGames === false && isEditableRegistrationType(category);

export const convertTeamLikeMapToTeam = (team: Map<any, any>) => {
  const teamObj = team.toJS();
  // As backward compatibility lets map eventId and categoryId from eventDetails and categoryDetails in case they are missing from the data
  return teamObj.categoryDetails && teamObj.eventDetails
    ? Team.fromJS({
        ...teamObj,
        categoryId: teamObj.categoryDetails.id,
        eventId: teamObj.eventDetails.id,
        eventDetails: undefined, // This should disappear from the appbackend response
        categoryDetails: undefined, // This should disappear from the appbackend response
      })
    : Team.fromJS(teamObj);
};

export const convertTeamLikeToTeam = (team: TeamLike) => {
  // As backward compatibility lets map eventId and categoryId from eventDetails and categoryDetails in case they are missing from the data
  return team.categoryDetails && team.eventDetails
    ? Team.fromJS({
        ...team,
        categoryId: team.categoryDetails.id,
        eventId: team.eventDetails.id,
        eventDetails: undefined, // This should disappear from the appbackend response
        categoryDetails: undefined, // This should disappear from the appbackend response
      })
    : Team.fromJS(team);
};

export function isTeam(team): team is Team {
  return team instanceof Team;
}

export const getTeamRegistrationStage = (team: Team, category: Category) => {
  if (!category || !hasValidRegistrationType(category)) {
    return RegistrationStage.Unknown;
  }

  // Is a fully confirmed registration type and is awaiting required players in the team
  const fullyConfirmedTeamEditStage =
    isFullyConfirmedRegistrationType(category) && !hasEnoughPlayers(team);

  // Is awaiting confirmation from the organizer
  const organizerConfirmedTeamEditStage =
    !fullyConfirmedTeamEditStage &&
    isOrganizerConfirmedRegistrationType(category) &&
    !isOrganizerConfirmed(team);

  if (fullyConfirmedTeamEditStage) {
    return RegistrationStage.TeamEdit;
  } else if (organizerConfirmedTeamEditStage) {
    return RegistrationStage.AwaitingOrganizerConfirmation;
  } else {
    return RegistrationStage.Done;
  }
};

export const findPlayerInTeamMemberList = (player: Map<string, any>, teamMembers: List<Player>) =>
  teamMembers.find(
    teamMember =>
      teamMember.firstName === player.get('firstName') &&
      teamMember.lastName === player.get('lastName') &&
      teamMember.gender === player.get('gender') &&
      teamMember.dateOfBirth === player.get('dateOfBirth'),
  );
