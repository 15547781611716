export type TourStandingsColumnType =
  | 'rank'
  | 'name'
  | 'finalEvent'
  | 'totalPoints'
  | 'events'
  | 'gamesPlayed'
  | 'gamesWon'
  | 'winPercentage'
  | 'averagePoints'
  // Placeholder empty column in cases where the real last column is something
  // which is not desirable to have right align text. In thoses cases you can
  // put an empty column as last column that "steals" the right-aligned rule
  | 'lastEmptyColumn';

export const hasColumn = (column: TourStandingsColumnType, columns) => columns.indexOf(column) >= 0;
