/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {Event} from '../events/Event';
import {Category} from '../events/Category';
import {Player} from '../players/Player';

export const defaultTeam = {
  eventId: null,
  categoryId: null,
  eventDetails: null,
  categoryDetails: null,
  id: null,
  name: null,
  nameSuffix: null,
  nationality: null,
  email: null,
  phoneNumber: null,
  category: null,
  finalStanding: null,
  wonGames: null,
  lostGames: null,
  winRatio: null,
  averagePoints: null,
  totalPoints: null,
  seed: null,
  status: null,
  canModify: null,
  additionalInfo: null,
  teamMembers: null,
  teamStatus: null,
  isPlaceholder: null,
  hasGames: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TeamLike = {
  [K in keyof typeof defaultTeam]?: Team[K];
};

export class Team extends Record(defaultTeam) {
  eventId?: string;
  categoryId?: string;
  eventDetails?: Event;
  categoryDetails?: Category;
  id?: string;
  name?: string;
  nameSuffix?: string;
  nationality?: string;
  email?: string;
  phoneNumber?: string;
  category?: string;
  finalStanding?: number;
  wonGames?: number;
  lostGames?: number;
  winRatio?: number;
  averagePoints?: number;
  totalPoints?: number;
  seed?: number;
  status?: string;
  canModify?: boolean;
  additionalInfo?: string;
  teamMembers?: List<Player>;
  teamStatus?: string;
  isPlaceholder?: boolean;
  hasGames?: boolean;
  static fromJS(o: TeamLike): Team {
    return (
      o &&
      new Team({
        eventId: o.eventId,
        categoryId: o.categoryId,
        eventDetails: o.eventDetails && Event.fromJS(o.eventDetails),
        categoryDetails: o.categoryDetails && Category.fromJS(o.categoryDetails),
        id: o.id,
        name: o.name,
        nameSuffix: o.nameSuffix,
        nationality: o.nationality,
        email: o.email,
        phoneNumber: o.phoneNumber,
        category: o.category,
        finalStanding: o.finalStanding,
        wonGames: o.wonGames,
        lostGames: o.lostGames,
        winRatio: o.winRatio,
        averagePoints: o.averagePoints,
        totalPoints: o.totalPoints,
        seed: o.seed,
        status: o.status,
        canModify: o.canModify,
        additionalInfo: o.additionalInfo,
        teamMembers: o.teamMembers && List(o.teamMembers).map(i => i && Player.fromJS(i)),
        teamStatus: o.teamStatus,
        isPlaceholder: o.isPlaceholder,
        hasGames: o.hasGames,
      })
    );
  }
}

export default Team;
