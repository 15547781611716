import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Box, BoxProvider} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Link} from 'fiba/wt/ui/link/Link';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {LocalizerService, ServicesContext, UIServices} from 'fiba/common/services/services';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import Dates from 'fiba/common/utils/Dates';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {CollapsingPanel} from 'fiba/wt/ui/collapsingPanel/CollapsingPanel';
import {EventTeamPlayers} from 'fiba/wt/ui/eventTeamPlayers/EventTeamPlayers';
import createCacheService from 'fiba/common/services/cacheService';
import teamActions from 'fiba/wt/stores/teamStore';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {getHumanQualificationType} from 'fiba/wt/utils/results/resultsUtils';
import {SpaceScale} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {eventTeamLinkProvider, eventRootLink} from 'fiba/wt/utils/linkUtils';
import {TourTeamEventResult} from 'fiba/common/core/models/api/results/TourTeamResults';
import CommonServices from 'fiba/common/core/models/CommonServices';

interface OwnProps {
  season: string;
  teamStops: List<TourTeamEventResult>;
}

interface DispatchProps {
  loadTeamIfNeeded: (services: UIServices, teamId: string) => any;
}

interface ITourTeamStop {
  season: string;
  teamStop: TourTeamEventResult;
  localizer: LocalizerService;
  active: boolean;
  onClick: (event: any) => void;
}

const qualificationSourceHref = (result: TourTeamEventResult, seasonConfig, season) => {
  if (result.qualifiedFrom.type === 'EVENT') {
    // NOTE: We might need to handle SuperQuests here, eventually
    if (result.qualifiedAs === 'Challenger') {
      return eventRootLink(seasonConfig, season, result.qualifiedFrom.id, 'Challenger');
    } else {
      return `https://play.fiba3x3.com/events/${result.qualifiedFrom.id}`;
    }
  } else {
    return `https://play.fiba3x3.com/tours/${result.qualifiedFrom.id}`;
  }
};

const qualificationSourceLink = (result: TourTeamEventResult, theme, seasonConfig, season) => (
  <Link
    colorTheme={theme.linkTheme}
    href={qualificationSourceHref(result, seasonConfig, season)}
    extraClassName="align-center b f4"
  >
    {result.qualifiedFrom.name}
  </Link>
);

const definitionListRow = (title, data: number | string = '-') => (
  <React.Fragment>
    <dt>{title}</dt>
    <dd className="f4 b">{data}</dd>
  </React.Fragment>
);

const SeeTeamButton: React.SFC<{active: boolean; onClick: (ev: any) => void}> = ({
  active,
  onClick,
}) => (
  <BoxProvider
    borderColor="dark-50"
    color={active ? 'fullwhite' : 'dark-50'}
    bgColor={active ? 'dark-50' : 'transparent'}
    display="block"
    pv="3"
    width="100"
    borderRadius="1"
    borderWidth="1"
    border="all"
    textAlign="center"
    extraClassName="tdn"
  >
    {styleProps => (
      <button {...styleProps} onClick={onClick}>
        See Team
      </button>
    )}
  </BoxProvider>
);

const TourTeamStop: React.FunctionComponent<ITourTeamStop> = ({
  season,
  teamStop,
  localizer,
  active,
  onClick,
}) => {
  // TODO: Once `gap` is more widely supported in Flexbox, consider using that one :)
  const GRID_GAP: SpaceScale = '2';
  const {seasonConfig} = useContext(SiteConfigContext);
  const theme = useContext(ThemeContext);

  const eventTitleAlignment = teamStop.qualifiedFrom ? 'end' : 'baseline';
  const qualifiedFromLabel = teamStop => (
    <React.Fragment>
      <dt>{'Qualified from'}</dt>
      <dd>
        {qualificationSourceLink(teamStop, theme, seasonConfig, season)}
        <br />
        <span>({getHumanQualificationType(teamStop.qualifiedAs)})</span>
      </dd>
    </React.Fragment>
  );

  const qualifiedTypeLabel = teamStop => (
    <React.Fragment>
      <dt>{'Qualified as'}</dt>
      <dd>
        <span className="b f4 lh-title">{getHumanQualificationType(teamStop.qualifiedAs)}</span>
        <br />
        <span>&nbsp;</span>
      </dd>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      {/* Solve two things:
              - Wrapping automatically and not manually
              - Gap between items */}
      <Flex
        flexDirection="row"
        flexWrap="wrap"
        mn={GRID_GAP}
        pv="3"
        ph={['3', '3', '4', '4']}
        alignItems="center"
      >
        <Flex ma={GRID_GAP} flexGrow="1" alignItems="center" flexWrap="wrap">
          {/* Events */}
          <Box
            ma={GRID_GAP}
            width={['100', '100', '25']}
            alignSelf={eventTitleAlignment}
            flexGrow="1"
          >
            {/* TODO: Link to event team, currently we don't get this information from OEM */}
            <BlockText ma="0" fontSize={['5', '4']} fontWeight="6">
              <Link
                colorTheme={theme.linkTheme}
                href={eventRootLink(
                  seasonConfig,
                  season,
                  teamStop.eventId,
                  // Currently no challengers on this list
                  'WorldTour',
                )}
                extraClassName="align-center"
              >
                {teamStop.isMaster ? teamStop.eventShortName : teamStop.eventName}
              </Link>
            </BlockText>
            <BlockText>{Dates.formatDateRange(localizer, teamStop.start, teamStop.end)}</BlockText>
          </Box>

          {/* Stats + "See Team" */}

          {/* Stats
           * NOTE: flexGrow: 999 allows the stats to take up all the space, and
           * keep the sibling button at w4. But, when wrapping, the button will
           * take 100%. This is for very small viewports.
           */}
          <Box mn={GRID_GAP} style={{flexGrow: 999}}>
            <dl className="ma2 TourTeamStop-StatsTable flex flex-row">
              <Flex ma={GRID_GAP} width="2" flexGrow="1" flexDirection="column-reverse">
                {definitionListRow(
                  'Standing',
                  `${
                    teamStop.standing
                      ? localizer.formatKey('POSITION_ORDINAL', {
                          position: teamStop.standing,
                        })
                      : '-'
                  }
                  ${
                    teamStop.qualifiedToFinal
                      ? ' (' + localizer.formatKey('TOUR_QUALIFIED_SHORT') + ')'
                      : ''
                  }`,
                )}
              </Flex>
              <Flex ma={GRID_GAP} width="2" flexGrow="1" flexDirection="column-reverse">
                {definitionListRow(
                  'Tour points',
                  teamStop.points > 0 ? teamStop.points : undefined,
                )}
              </Flex>
              <Flex ma={GRID_GAP} width="33" flexGrow="1" flexDirection="column">
                {teamStop.qualifiedFrom
                  ? qualifiedFromLabel(teamStop)
                  : qualifiedTypeLabel(teamStop)}
              </Flex>
            </dl>
          </Box>

          {/* "See Team" */}
          <Box ma={GRID_GAP} width="4" flexGrow="1">
            <SeeTeamButton active={active} onClick={onClick} />
          </Box>
        </Flex>
      </Flex>
    </React.Fragment>
  );
};

const _TourTeamStops: React.FunctionComponent<OwnProps & DispatchProps> = ({
  season,
  teamStops,
  loadTeamIfNeeded,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  const services = useContext(ServicesContext);
  return (
    <CardContainer debugClassName="TourTeamStops">
      <SubHeading fontSize="3" pb={['3', '3', '4', '4']}>
        Events
      </SubHeading>
      <Box mnh={['3', '3', '4', '4']} mnb={['3', '3', '4', '4']}>
        <ul>
          {teamStops.map(teamStop => (
            <li key={teamStop.eventId} className="TourTeamStop pv2 pv0-s striped--light">
              <CollapsingPanel
                renderToggle={({open, toggle}) => (
                  <TourTeamStop
                    season={season}
                    teamStop={teamStop}
                    localizer={services.localizer}
                    active={open}
                    onClick={() => {
                      !open && loadTeamIfNeeded(services, teamStop.teamId);
                      toggle();
                    }}
                  />
                )}
              >
                <Box
                  pb={['2', '3', '4', '3']}
                  pt={['0', '0', '3']}
                  ph={['3', '3', '4', '4']}
                  mr="auto"
                  ml="auto"
                  maxWidth="8"
                >
                  <EventTeamPlayers
                    season={season}
                    eventId={teamStop.eventId}
                    teamId={teamStop.teamId}
                  />
                  {/* Add an explicit link for the team in event */}
                  <Box textAlign="center" pt="3">
                    <Link
                      href={eventTeamLinkProvider(
                        seasonConfig,
                        season,
                        teamStop.eventId,
                        // NOTE: These are never challengers atm
                        'WorldTour',
                      )(teamStop.teamId)}
                    >
                      Go to event team page
                    </Link>
                  </Box>
                </Box>
              </CollapsingPanel>
            </li>
          ))}
        </ul>
      </Box>
    </CardContainer>
  );
};

const mapDispatchToProps = dispatch => ({
  loadTeamIfNeeded: (services: CommonServices, teamId: string) => {
    const cache = createCacheService(services);
    return cache(['teams', teamId], () => dispatch(teamActions.loadTeam(teamId)));
  },
});

export const TourTeamStops = connect<{}, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps,
)(_TourTeamStops);
