import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import Dates from 'fiba/common/utils/Dates';
import {ServicesContext} from 'fiba/common/services/services';
import {Box} from 'fiba/wt/ui/box/Box';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {Link} from 'fiba/wt/ui/link/Link';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventRootLink} from 'fiba/wt/utils/linkUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import Event from 'fiba/common/core/models/api/events/Event';
import {deduceEventType} from 'fiba/wt/stores/eventStore';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

interface OwnProps {
  season: string;
  eventId: string;
}

interface ReduxProps {
  eventData: RemoteData.WebData<{
    event: Event;
    info: EventInfo;
  }>;
}

/* Information about an event's type and the event it is a qualifier for (if any) */
type EventInfo =
  | {type: 'WorldTour'}
  | {type: 'WomensSeries'}
  | {type: 'Cup'}
  | {type: 'NationsLeague'}
  | {type: 'Challenger'; forEvent: RemoteData.WebData<Event> | undefined}
  | {type: 'SuperQuest'; forEvent: RemoteData.WebData<Event> | undefined};

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  // Transform the "Success" case of loading data, while preserving NotAsked, Loading, Error
  const eventData = RemoteData.map(
    state.getIn(['events', eventId]) as RemoteData.WebData<Event>,
    event => {
      // Note: It is possible that an event is a challenger, but we do not have loaded the Masters they are the challenger for
      // This is the case with one of the 2019 Masters
      // We must thus differentiate being a challenger and having a challengerForName
      const eventType = deduceEventType(event);
      let forEvent: RemoteData.WebData<Event> | undefined;

      switch (eventType) {
        case 'WorldTour':
        case 'WomensSeries':
        case 'Cup':
          forEvent = undefined;
          break;
        case 'NationsLeague':
          forEvent = undefined;
          break;
        case 'Challenger':
          forEvent = state.getIn(['events', event.challengerFor]) as RemoteData.WebData<Event>;
          break;
        case 'SuperQuest':
          forEvent = state.getIn(['events', event.superQuestFor]) as RemoteData.WebData<
            Event | undefined
          >;
          break;
        default:
          forEvent = undefined;
          break;
      }

      return {
        event,
        info: {
          type: eventType,
          forEvent: forEvent,
        },
      };
    },
  );

  return {eventData};
};

const _EventOverViewHeadingHeading: React.FC<Props> = ({season, eventData}) => {
  const {localizer} = useContext(ServicesContext);
  const {seasonConfig} = useContext(SiteConfigContext);
  const challengerLabel = (
    <Box pt="1" pl="2" mb="2" border="left" borderWidth="3" borderColor="blue-electric">
      <BlockText
        fontSize={['5', '5', '4']}
        fontWeight="6"
        textTransform="uppercase"
        color="blue-electric"
        lineHeight="title"
      >
        Challenger
      </BlockText>
    </Box>
  );

  return RemoteData.match(eventData, {
    NotAsked: () => null,
    Loading: () => 'Loading...',
    Failure: _anomaly => 'There was an error fetching the data.',
    Success: ({event, info}) => (
      <Box debugClassName="EventOverviewHeading">
        <EventPageHeading
          eventId={event.id}
          season={season}
          headingProps={{fontSize: '2'}}
          containerProps={{pb: '2'}}
          topSection={info.type === 'Challenger' ? challengerLabel : null}
        >
          <p className="f5 lh-title steel-40 pv1">
            {Dates.formatDateRange(localizer, event.startDate, event.endDate)}
            {/* Ugly eventType/challengerForName conditional. As above, this can happen due to Masters being published or not. */}
            {/* TODO: Handle SuperQuest here some day */}
            {(info.type === 'Challenger' || info.type === 'SuperQuest') &&
              info.forEvent &&
              RemoteData.match(info.forEvent, {
                Success: qualifierForEvent => (
                  <>
                    {' '}
                    | Qualifier for{' '}
                    <Link
                      colorTheme="dark"
                      // The linked event is always a masters event
                      href={eventRootLink(seasonConfig, season, qualifierForEvent.id, 'WorldTour')}
                    >
                      {qualifierForEvent.shortName} Masters
                    </Link>
                  </>
                ),
                default: () => null,
              })}
          </p>
        </EventPageHeading>
      </Box>
    ),
  });
};

export const EventOverviewHeading = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventOverViewHeadingHeading,
);
