import React from 'react';
import {Asset} from 'contentful';
import {IMAGE_SIZES} from 'fiba/common/utils/constants';

interface ContentfulPictureProps {
  asset: Asset;
  alt: string;
}

interface SrcSetOptions {
  type: string;
  url: string;
}

const applyFormat = (options: SrcSetOptions) => {
  const url = `${options.url}?fm=${options.type}`;

  return {
    srcSet: IMAGE_SIZES.map(x => `${url}&w=${x} ${x}w`).join(', '),
    type: `image/${options.type}`,
  };
};

export const ContentfulPicture: React.FunctionComponent<
  ContentfulPictureProps & React.HTMLAttributes<HTMLPictureElement>
> = ({asset, alt, ...props}) => {
  const url = asset.fields.file.url;

  const types = ['avif', 'webp', 'png'];

  return (
    <picture {...props}>
      {types.map(type => {
        return <source key={type} {...applyFormat({type, url})} />;
      })}
      <img src={`${url}&w=1440`} alt={alt} />
    </picture>
  );
};
