import {Map, fromJS} from 'immutable';
import {mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import CommonServices from 'fiba/wt/core/models/CommonServices';
// TODO: Implement these
import {
  TeamRankingsResponse,
  TeamRankingsResponseLike,
} from 'fiba/common/core/models/api/rankings/TeamRankingsResponse';

// This does nothing for type-safety, but is good for readability
type TourId = string;

export interface State extends Map<TourId, TeamRankingsResponse> {
  // TODO: `__meta`
}

export const statePath = (tourId: TourId) => [tourId];
const metaPath = (tourId: TourId) => ['__meta', ...statePath(tourId)];
export const storePath = 'teamRankings';
export const globalStatePath = (tourId: TourId) => [storePath, ...statePath(tourId)];
export const globalMetaPath = (tourId: TourId) => [storePath, ...metaPath(tourId)];

const reducers = {
  loadRankings: (state: State, tourId: TourId): State => {
    return state.updateIn(metaPath(tourId), setMeta.isLoading);
  },

  loadRankingsSuccess: (
    state: State,
    tourId,
    rankingsResponse: TeamRankingsResponseLike,
  ): State => {
    return state
      .set(tourId, TeamRankingsResponse.fromJS(rankingsResponse))
      .updateIn(metaPath(tourId), setMeta.isLoaded);
  },

  loadRankingsError: (state: State, tourId: TourId, error: Error): State =>
    state.updateIn(metaPath(tourId), setMeta.isError),
};

export const reducer = createReducer<State>(__filename, fromJS({}), reducers);

const actions = createActions(__filename, reducers, {
  loadRankings: (tourId: TourId) => ({apiClient}: CommonServices) => {
    return apiClient
      .loadTeamRankings(10)
      .then(res => actions.loadRankingsSuccess(tourId, res.data))
      .catch(rethrow((err: Error) => actions.loadRankingsError(tourId, err)));
  },

  loadRankingsError: (tourId: TourId, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = (state: any) => {
  const {__meta, ...items} = state;
  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail
    __meta: __meta || {},
    ...mapValues(items, item => TeamRankingsResponse.fromJS(item)),
  });
};
