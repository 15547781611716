import {Map, fromJS, List} from 'immutable';
import {createActions, createReducer, stripNamespace} from 'fiba/common/stores/storeUtils';
import {ROUTER_ROUTE_CHANGED} from 'fiba/common/services/routerService';
import reportCrash from 'fiba/common/client/services/crashReportingService';
import {ValidationError} from 'fiba/common/core/fibaError';

export const DEFAULT_NOTIFICATION_LIFESPAN_MS = 5000;

export const ACTION_PATH = stripNamespace(__filename); // A cheap way to help validate action types

const actions = createActions(__filename, {
  addNotification: (notificationType, message, lifespan = DEFAULT_NOTIFICATION_LIFESPAN_MS) =>
    createMessage(notificationType, message, lifespan),

  addUnexpectedNotification: (notificationType, message, error, lifespan) => {
    reportCrash('addUnexpectedNotification', error);
    return actions.addNotification(notificationType, message, lifespan);
  },

  addNotificationFromError: (error, lifespan = DEFAULT_NOTIFICATION_LIFESPAN_MS) =>
    isValidationError(error)
      ? // Validation errors are handled elsewhere
        actions.addNotificationFromValidationError(error, lifespan)
      : // Other errors (e.g. data loading failed) are unexpected, so we notify the user, with loggin
        actions.addUnexpectedNotification('error', error.message, error, lifespan),

  addNotificationFromValidationError: (error, lifespan = DEFAULT_NOTIFICATION_LIFESPAN_MS) => {
    // If we have any field validation errors, let's skip showing the notification (fields will have errors)
    const validationErrors = error.fiba.get('validationErrors');
    if (validationErrors.size !== 0) {
      // There can be other then fieldErrors apparently, so for those we create notifications
      return withoutFieldErrors(validationErrors)
        .map((validationError: Map<string, any>) =>
          actions.addNotification('error', validationError.get('errorMessage'), lifespan),
        )
        .toArray();
    }

    // Show notification with given message
    return actions.addNotification('error', error.message, lifespan);
  },

  removeNotifications: ids => fromJS(ids),
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    addNotification: (state, notification) => insertUniqueNotification(state, notification),

    removeNotifications: (state, ids) => state.filter(item => ids.indexOf(item.get('id')) < 0),

    [ROUTER_ROUTE_CHANGED]: state => state.filter(item => item.get('type') !== 'error'),
  },
  List(),
);

//
// Utilities

// @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/MAX_SAFE_INTEGER
const MAX_SAFE_INTEGER = 9007199254740991;

const generateId = () => `${Date.now()}-${Math.floor(Math.random() * MAX_SAFE_INTEGER)}`;

const createMessage = (notificationType, message, lifespan) => {
  return Map({
    ts: Date.now(),
    id: generateId(),
    lifespan: (notificationType === 'error' && 1) || lifespan,
    notificationType,
    message,
  });
};

const notificationEqual = (a, b) =>
  a.get('message') === b.get('message') && a.get('notificationType') === b.get('notificationType');

const insertUniqueNotification = (notifications, notification) =>
  notifications.find(x => notificationEqual(x, notification))
    ? notifications
    : notifications.concat([notification]);

export const isValidationError = error => error instanceof ValidationError;

export const withoutFieldErrors = validationErrors =>
  validationErrors.filter(validationError => validationError.get('errorType') !== 'FieldError');
