import moment from 'moment';
import {LocalizerService} from 'fiba/common/services/services';

export const parseLocalDatetime = (str: string) => {
  const mobj = moment(str, 'YYYY-MM-DDTHH:mm:SS');
  return mobj.isValid() && mobj.toDate();
};

export const getLocalTimeFromString = (str: string, localizer: LocalizerService): string => {
  const dateobj = parseLocalDatetime(str);
  return (dateobj && localizer.format('{dateobj, time, short}', {dateobj})) || 'N/A';
};
export const getLocalDateFromString = (str: string, localizer: LocalizerService): string => {
  const dateobj = parseLocalDatetime(str);
  return (dateobj && localizer.format('{dateobj, date, medium}', {dateobj})) || 'N/A';
};

export const parseFullDatetime = (date: string | Date) => {
  const mobj = moment(date);
  return mobj.isValid() && mobj.toDate();
};

export const getUserTimeFromString = (str: string, localizer: LocalizerService): string => {
  const dateobj = parseFullDatetime(str);
  return (dateobj && localizer.format('{dateobj, time, short}', {dateobj})) || 'N/A';
};

export const getUserDateFromString = (str: string, localizer: LocalizerService): string => {
  const dateobj = parseFullDatetime(str);
  return (dateobj && localizer.format('{dateobj, date, medium}', {dateobj})) || 'N/A';
};

export const groupGamesByDate = (games, localizer: LocalizerService, isEventTime: boolean) =>
  games.groupBy(game => getDateFromString(game.get('gameStartDatetime'), isEventTime, localizer));

export const winnerClass = team => (team.getIn(['teamIsWinner']) ? 'GameSchedule--winner' : '');

export const getTimeFromString = (
  time: string,
  isEventTime: boolean,
  localizer: LocalizerService,
): string => {
  return isEventTime
    ? getLocalTimeFromString(time, localizer)
    : getUserTimeFromString(time, localizer);
};

export const getDateFromString = (
  time: string,
  isEventTime: boolean,
  localizer: LocalizerService,
): string => {
  return isEventTime
    ? getLocalDateFromString(time, localizer)
    : getUserDateFromString(time, localizer);
};
