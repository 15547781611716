import React, {useContext} from 'react';
import {List} from 'immutable';
import {Player} from 'fiba/common/core/models/api/players/Player';
import Team from 'fiba/common/core/models/api/teams/Team';
import {formatTeamName, mapRoundCode} from 'fiba/wt/ui/statistics/statsSummary/utils';
import {P} from 'fiba/wt/ui/text/Text';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {LazyImageWithPlaceholder} from 'fiba/wt/ui/image/LazyImageWithPlaceholder';
import {getProfileImageWt} from 'fiba/common/core/images';
import {
  GameReference as EventGameReference,
  NotablePlayerPerformanceStats as EventNotable,
} from 'fiba/common/core/models/api/stats/EventStatsSummary';
import {
  GameReference as TourGameReference,
  NotablePlayerPerformanceStats as TourNotable,
} from 'fiba/common/core/models/api/stats/TourStatsSummary';
import {LocalizerService} from 'fiba/common/services/localizerService';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

interface Props {
  title: string;
  stats: List<EventNotable | TourNotable>;
  showValue?: boolean;
  showBreakdown?: boolean;
  showRank?: boolean;
  createGameHref: Function;
  createPlayerHref: Function;
  createTeamHref: Function;
  localizer: LocalizerService;
}

export const NotablePerformanceStats = ({
  title,
  stats,
  showValue = true,
  showBreakdown = true,
  showRank = true,
  createGameHref,
  createPlayerHref,
  createTeamHref,
  localizer,
}: Props) => {
  const {features} = useContext(SiteConfigContext);
  const classNames = `${showRank ? 'ranked' : 'unranked'} ${showValue ? 'valued' : 'unvalued'}`;
  const shouldShowImage = isFirstItem => (isFirstItem && showRank) || !showRank;
  return (
    !stats.isEmpty() && (
      <div className={'notable-stats-item shadow-1 ' + classNames}>
        <P fontWeight="6" mb="3" ml="3">
          {title}
        </P>
        <ul>
          {stats.map((statsItem, index) => {
            const game: EventGameReference | TourGameReference = statsItem.game;
            // game.eventId is `undefined` in case this component is called from
            // the Event parent because the eventId is already set from the parent
            const gameEventId = game instanceof TourGameReference ? game.eventId : undefined;

            const playerLike = Player.fromJS({
              firstName: statsItem.playerFirstName,
              lastName: statsItem.playerLastName,
              imageTinyUrl: statsItem.imageUrl,
            });
            const playerFullName = getPlayerName(playerLike);

            const isFirstItem = index === 0;
            const itemClassNames = `${features.siteId} ${!showRank ? 'unranked' : ''} ${
              showBreakdown ? 'breakdown' : ''
            }`;
            return (
              <li key={statsItem.playerId + statsItem.points} className={itemClassNames}>
                {showRank && <div className="rank">{index + 1}.</div>}
                <div className="names">
                  <a href={createPlayerHref(statsItem.teamId)(statsItem.playerId)}>
                    {playerFullName}
                  </a>
                  {/* game.eventId is `undefined` in case this component is called 
                    from the Event parent because the eventId is already set from the parent */}
                  <a href={createTeamHref(statsItem.teamInEventId, gameEventId)}>
                    <TeamNameAssembly
                      team={Team.fromJS({
                        id: statsItem.teamId,
                        name: statsItem.teamName,
                        nameSuffix: statsItem.teamNameSuffix,
                      })}
                      isSuffixVisible
                    />
                  </a>
                  <p className="f7 mt1">
                    {game instanceof TourGameReference ? `${game.eventName}, ` : ''}
                    {mapRoundCode(game.gameRoundCode, localizer)}
                    {': '}
                    <a href={createGameHref(game.gameId, gameEventId)}>
                      {formatTeamName(game.gameHomeTeamName, game.gameHomeTeamSuffix)} vs{' '}
                      {formatTeamName(game.gameAwayTeamName, game.gameAwayTeamSuffix)}
                    </a>
                  </p>
                </div>
                {shouldShowImage(isFirstItem) && (
                  <div className="player-image">
                    <LazyImageWithPlaceholder
                      aspectRatio="1x1"
                      src={getProfileImageWt(playerLike, 'tiny')}
                      alt={`Profile of ${playerFullName}`}
                      className="o-fit-contain o-pos-b"
                      containerStyle={{bgColor: 'silver-30'}}
                    />
                  </div>
                )}
                {showBreakdown && (
                  <p className="breakdown f7">
                    {statsItem.points} PTS <br />
                    {statsItem.rebounds} REB <br />
                    {statsItem.highlights} HGL
                  </p>
                )}
                {showValue && <p className="value">{statsItem.value.toFixed(1)}</p>}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};
