import React from 'react';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {ChevronLeft} from '@fpapado/react-feather';

interface IBackLink {
  href: string;
  content: React.ReactNode;
}

export const BackLink: React.SFC<IBackLink> = ({href, content}) => (
  <StyledLink href={href}>
    <ChevronLeft width="16" height="16" className="v-mid" purpose="decorative" />
    {content}
  </StyledLink>
);
