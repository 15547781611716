import {Map, fromJS, List} from 'immutable';
import {createActions, createReducer} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/wt/core/models/CommonServices';
import {ArticleTypes, ContentTypes} from 'fiba/wt/services/contentfulService';
import {RootState} from 'fiba/wt/stores/rootStore';
import {DomainType} from 'fiba/common/utils/domainUtils';
import {Season} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {
  ContentfulEventConfigurationMap,
  EventConfiguration,
  GlobalConfiguration,
} from '../ui/eventProgrammability/EventProgrammabilityStateProvider';
import {Entry} from 'contentful';

export {ContentTypes};

export interface ContentStoreState extends Map<string, any> {
  // TODO: Typings
  /*
    $contentType: {
      tours: {
        $tourId: items[],
      },
      events: {
        $eventId: items[]
      },
      tourEvents: {
        $tourId: items[];
      }
    },
    __meta {
      $contentType: {
        tours: {
          $tourId: {...},
        },
        events: {
          $eventId: {...}
        },
      },
    },
  */
}

const reducers = {
  //
  // Tour content
  loadTourContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    tourId: string,
    _season: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'tours', tourId], setMeta.isLoading),

  loadContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    items: any[],
  ): ContentStoreState => {
    return state
      .setIn([contentType], fromJS(items))
      .updateIn(['__meta', contentType], setMeta.isLoaded);
  },

  loadTourContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    tourId: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'tours', tourId], fromJS(items))
      .updateIn(['__meta', contentType, 'tours', tourId], setMeta.isLoaded),

  loadTourContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    tourId: string,
    _season: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'tours', tourId], setMeta.isError)
      .setIn(['__meta', contentType, 'tours', tourId, 'error'], error.message),

  // Event content
  loadEventContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    _tourId: string,
    _season: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'events', eventId], setMeta.isLoading),

  loadEventContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    _tourId: string,
    _season: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'events', eventId], fromJS(items))
      .updateIn(['__meta', contentType, 'events', eventId], setMeta.isLoaded),

  loadEventContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    _tourId: string,
    _season: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'events', eventId], setMeta.isError)
      .setIn(['__meta', contentType, 'events', eventId, 'error'], error.message),

  // Season content. At the moment, this is only used on the landing page
  loadSeasonContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    season: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'seasons', season], setMeta.isLoading),

  loadSeasonContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    season: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'seasons', season], fromJS(items))
      .updateIn(['__meta', contentType, 'seasons', season], setMeta.isLoaded),

  loadSeasonContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    season: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'seasons', season], setMeta.isError)
      .setIn(['__meta', contentType, 'seasons', season, 'error'], error.message),

  loadTourEventsContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    tourId: string,
    _season: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'tourEvents', tourId], setMeta.isLoading),

  loadTourEventsContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    tourId: string,
    _season: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'tourEvents', tourId], fromJS(items))
      .updateIn(['__meta', contentType, 'tourEvents', tourId], setMeta.isLoaded),

  loadTourEventsContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    tourId: string,
    _season: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'tourEvents', tourId], setMeta.isError)
      .setIn(['__meta', contentType, 'tourEvents', tourId, 'error'], error.message),

  // Season content. At the moment, this is only used on the landing page
  loadNationsLeagueSeasonContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    season: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'seasons', season], setMeta.isLoading),

  loadNationsLeagueSeasonContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    season: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'seasons', season], fromJS(items))
      .updateIn(['__meta', contentType, 'seasons', season], setMeta.isLoaded),

  loadNationsLeagueSeasonContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    season: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'seasons', season], setMeta.isError)
      .setIn(['__meta', contentType, 'seasons', season, 'error'], error.message),

  loadNationsLeagueEventContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    _conferenceId: string,
    _season: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'events', eventId], setMeta.isLoading),

  loadNationsLeagueEventContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    _conferenceId: string,
    _season: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'events', eventId], fromJS(items))
      .updateIn(['__meta', contentType, 'events', eventId], setMeta.isLoaded),

  loadNationsLeagueEventContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    _conferenceId: string,
    _season: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'events', eventId], setMeta.isError)
      .setIn(['__meta', contentType, 'events', eventId, 'error'], error.message),

  loadConferenceContent: (
    state: ContentStoreState,
    contentType: ContentTypes,
    conferenceId: string,
  ): ContentStoreState =>
    state.updateIn(['__meta', contentType, 'conferences', conferenceId], setMeta.isLoading),

  loadConferenceContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    conferenceId: string,
    items: any[],
  ): ContentStoreState =>
    state
      .setIn([contentType, 'conferences', conferenceId], fromJS(items))
      .updateIn(['__meta', contentType, 'conferences', conferenceId], setMeta.isLoaded),

  loadConferenceContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    conferenceId: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'conferences', conferenceId], setMeta.isError)
      .setIn(['__meta', contentType, 'conferences', conferenceId, 'error'], error.message),

  loadConferenceAndEventContentSuccess: (
    state: ContentStoreState,
    contentType: ContentTypes,
    eventId: string,
    conferenceItems: any[],
    eventItems: any[],
  ): ContentStoreState => {
    let items = [];

    // Articles can be loaded by several different routes, so we need to make sure we
    // merge different article types together. Event content takes precence over conference content.
    if (contentType === ContentTypes.Article) {
      const nonDuplicateConfItems = conferenceItems.filter(
        confItem =>
          !eventItems.some(
            eventItem => eventItem.fields.articleType === confItem.fields.articleType,
          ),
      );

      items = [...nonDuplicateConfItems, ...eventItems];
    } else {
      items = eventItems.length ? eventItems : conferenceItems;
    }

    return state
      .setIn([contentType, 'events', eventId], fromJS(items))
      .updateIn(['__meta', contentType, 'events', eventId], setMeta.isLoaded);
  },

  loadConferenceAndEventContentError: (
    state: ContentStoreState,
    contentType: ContentTypes,
    conferenceId: string,
    error: Error,
  ): ContentStoreState =>
    state
      .updateIn(['__meta', contentType, 'conferences', conferenceId], setMeta.isError)
      .setIn(['__meta', contentType, 'conferences', conferenceId, 'error'], error.message),
};

export const reducer = createReducer<ContentStoreState | ContentStoreState[]>(
  __filename,
  fromJS({}),
  reducers,
);

const actions = createActions(__filename, reducers, {
  loadTourContent: (
    contentType: ContentTypes,
    tourId: string,
    season: string,
    include?: number,
  ) => ({contentful}: CommonServices) =>
    contentful
      .getContent(contentType, getContentfulPathForTour(tourId, season), include)
      .then(res => actions.loadTourContentSuccess(contentType, tourId, res))
      .catch(
        rethrow((err: Error) => actions.loadTourContentError(contentType, tourId, season, err)),
      ),

  loadGlobalConfig: () => ({contentful}: CommonServices) =>
    contentful
      .getContent(ContentTypes.GlobalConfig, undefined)
      .then(res => {
        return actions.loadContentSuccess(
          ContentTypes.GlobalConfig,
          transformGlobalConfig(res as Array<Entry<ContentfulGlobalConfig>>),
        );
      })
      .catch(
        rethrow((err: Error) =>
          actions.loadTourContentError(ContentTypes.GlobalConfig, '', '', err),
        ),
      ),

  loadAllSeasonConfigs: () => ({contentful}: CommonServices) =>
    contentful
      .getContent(ContentTypes.SeasonConfig, undefined)
      .then(res => {
        return actions.loadContentSuccess(ContentTypes.SeasonConfig, res);
      })
      .catch(
        rethrow((err: Error) =>
          actions.loadTourContentError(ContentTypes.SeasonConfig, '', '', err),
        ),
      ),

  loadEventContent: (
    contentType: ContentTypes,
    eventId: string,
    tourId?: string,
    season?: string,
    include?: number,
  ) => ({contentful}: CommonServices) =>
    contentful
      .getContent(contentType, getContentfulPathForEvent(eventId, tourId, season), include)
      .then(res => actions.loadEventContentSuccess(contentType, eventId, tourId, season, res))
      .catch(
        rethrow((err: Error) =>
          actions.loadEventContentError(contentType, eventId, tourId, season, err),
        ),
      ),

  loadSeasonContent: (contentType: ContentTypes, season?: string, include?: number) => ({
    contentful,
  }: CommonServices) =>
    contentful
      .getContent(contentType, getContentfulPathForSeason(season), include)
      .then(res => actions.loadSeasonContentSuccess(contentType, season, res))
      .catch(rethrow((err: Error) => actions.loadSeasonContentError(contentType, season, err))),

  loadTourEventsContent: (contentType: ContentTypes, tourId: string, season: string) => ({
    contentful,
  }: CommonServices) =>
    contentful
      .queryContent(contentType, getContentfulQueryForTour(tourId, season))
      .then(res => actions.loadTourEventsContentSuccess(contentType, tourId, season, res))
      .catch(rethrow((err: Error) => actions.loadTourEventsContentError(contentType, tourId, err))),

  loadNationsLeagueSeasonContent: (
    contentType: ContentTypes,
    season?: string,
    include?: number,
  ) => ({contentful}: CommonServices) =>
    contentful
      .getContent(contentType, getContentfulPathForNationsLeagueSeason(season), include)
      .then(res => actions.loadNationsLeagueSeasonContentSuccess(contentType, season, res))
      .catch(
        rethrow((err: Error) =>
          actions.loadNationsLeagueSeasonContentError(contentType, season, err),
        ),
      ),

  loadNationsLeagueEventContent: (
    contentType: ContentTypes,
    eventId: string,
    conferenceId: string,
    season: string,
    include?: number,
  ) => ({contentful}: CommonServices) =>
    contentful
      .getContent(
        contentType,
        getContentfulPathForNationsLeagueEvent(eventId, conferenceId, season),
        include,
      )
      .then(res =>
        actions.loadNationsLeagueEventContentSuccess(
          contentType,
          eventId,
          conferenceId,
          season,
          res,
        ),
      )
      .catch(
        rethrow((err: Error) =>
          actions.loadNationsLeagueEventContentError(
            contentType,
            eventId,
            conferenceId,
            season,
            err,
          ),
        ),
      ),

  loadConferenceContent: (
    contentType: ContentTypes,
    season: string,
    conferenceId: string,
    include?: number,
  ) => ({contentful}: CommonServices) =>
    contentful
      .getContent(contentType, getContentfulPathForConference(season, conferenceId), include)
      .then(res => actions.loadConferenceContentSuccess(contentType, conferenceId, res))
      .catch(
        rethrow((err: Error) => actions.loadConferenceContentError(contentType, conferenceId, err)),
      ),

  loadConferenceAndEventContent: (
    contentType: ContentTypes,
    eventId: string,
    conferenceId: string,
    season: string,
    include?: number,
  ) => ({contentful}: CommonServices) => {
    return Promise.all([
      contentful.getContent(
        contentType,
        getContentfulPathForConference(season, conferenceId),
        include,
      ),
      contentful.getContent(
        contentType,
        getContentfulPathForNationsLeagueEvent(eventId, conferenceId, season),
        include,
      ),
    ])
      .then(([conferenceContent, eventContent]) => {
        return actions.loadConferenceAndEventContentSuccess(
          contentType,
          eventId,
          conferenceContent,
          eventContent,
        );
      })
      .catch(
        rethrow((err: Error) =>
          actions.loadConferenceAndEventContentError(contentType, conferenceId, err),
        ),
      );
  },
});

export default actions;

// Utils
// These functions get the path pattern that has been set by the Contentful extension.
// These paths are used to look up data from Contentful.
export const getContentfulPathForTour = (tourId: string, season: string) =>
  !tourId ? `cups/${season}` : `tour/${tourId}`;

export const getContentfulPathForEvent = (eventId: string, tourId: string, season: string) =>
  !tourId ? `cups/${season}/event/${eventId}` : `tour/${tourId}/event/${eventId}`;

export const getContentfulQueryForTour = (tourId: string, season: string) => ({
  'fields.path[match]': !tourId ? `cups/${season}/` : `tour/${tourId}/`,
});

export const getContentfulPathForSeason = (season: string) => `cups/${season}`;

const getContentfulPathForNationsLeagueSeason = (season: string) => `nationsleague/${season}`;

const getContentfulPathForNationsLeagueEvent = (
  eventId: string,
  conferenceId: string,
  season: string,
) => `nationsleague/${season}/conference/${conferenceId}/event/${eventId}`;

export const getContentfulPathForConference = (season: string, conferenceId: string) =>
  `nationsleague/${season}/conference/${conferenceId}`;

// SELECTORS

interface ContentfulField<T> {
  fields: T;
}

interface ShortUrl {
  eventId: string;
  shortName: string;
}

export type ContentfulGlobalConfig = {
  name: string;
  description: string;
  defaultSeasonWt: ContentfulSingleSeasonConfig;
  defaultSeasonWs: ContentfulSingleSeasonConfig;
  defaultSeasonCups: ContentfulSingleSeasonConfig;
  defaultSeasonNl: ContentfulSingleSeasonConfig;
};

export type ContentfulSingleSeasonConfig = ContentfulField<{
  title: string;
  tourId: string;
  site: DomainType;
  season: Season;
  showStatsForSeason: boolean;
  challengerShortUrls?: Array<ContentfulField<ShortUrl>>;
  mainShortUrls?: Array<ContentfulField<ShortUrl>>;
  superQuestShortUrls?: Array<ContentfulField<ShortUrl>>;
}>;

export type ContentfulSeasonConfigs = Record<string, ContentfulSingleSeasonConfig>;

export function getGlobalConfig(state: RootState): GlobalConfiguration {
  return state.getIn(['content', ContentTypes.GlobalConfig], Map()).toJS();
}

export function getSeasonConfigs(state: RootState): ContentfulSingleSeasonConfig[] {
  return state.getIn(['content', ContentTypes.SeasonConfig], Map()).toJS();
}

export function getEventConfiguration(
  state: RootState,
  eventId: string,
): EventConfiguration | undefined {
  const settings: List<ContentfulEventConfigurationMap> = state.getIn(
    ['content', ContentTypes.EventConfigurator, 'events', eventId],
    List(),
  );

  return settings.size > 0
    ? transformEventConfigurationMapToEventConfiguration(settings.first())
    : undefined;
}

export function getEventConfigurationForTour(
  state: RootState,
  tourId: string,
): EventConfiguration[] {
  const settings: List<ContentfulEventConfigurationMap> = state.getIn(
    ['content', ContentTypes.EventConfigurator, 'tourEvents', tourId],
    List(),
  );

  return settings.size > 0
    ? settings.map(transformEventConfigurationMapToEventConfiguration).toJS()
    : [];
}

const transformGlobalConfig = (res: Array<Entry<ContentfulGlobalConfig>>): GlobalConfiguration => {
  // There should only ever be one global config
  const {
    fields: {
      defaultSeasonWt: wt,
      defaultSeasonWs: ws,
      defaultSeasonCups: cups,
      // NL season config might not exist yet, give it a default setting
      // TODO: Remove the default value when
      defaultSeasonNl: nl = {fields: {season: '2024'}},
    },
  } = res[0];

  return {
    defaultSeasons: {
      worldtour: wt.fields.season,
      womensseries: ws.fields.season,
      cups: cups.fields.season,
      nationsleague: nl.fields.season,
    },
  };
};

export const transformEventConfigurationMapToEventConfiguration = (
  settings: ContentfulEventConfigurationMap,
): EventConfiguration => settings.toJS().fields;

interface GetArticleFromStateProps {
  state: Map<string, any>;
  contentPathArr: string[];
  articleType: ArticleTypes;
}

export const getArticleFromState = ({
  state,
  contentPathArr,
  articleType,
}: GetArticleFromStateProps): Map<string, any> | undefined => {
  return state
    .getIn(['content', ContentTypes.Article, ...contentPathArr], List())
    .filter(article => article.getIn(['fields', 'articleType']) === articleType)
    .first();
};

export const getCustomPageName = customPage =>
  customPage.getIn(['fields', 'shortName']) || customPage.getIn(['fields', 'title']);
