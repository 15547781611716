import React, {useContext} from 'react';
import GalleryPreview from 'fiba/common/core/models/api/media/GalleryPreview';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {PhotoGallerySummary} from 'fiba/wt/ui/photoGallery/PhotoGallerySummary';
import {List} from 'immutable';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventPhotosLink} from 'fiba/wt/utils/linkUtils';
import {BlockText} from 'fiba/wt/ui/text/Text';

interface ITourPhotoGallery {
  tourId: string;
  season: string;
}

interface ITourPhotoGalleryState {
  galleries: List<GalleryPreview>;
  metaPaths?: string[];
}

export const mapStateToProps = (state, {tourId}: ITourPhotoGallery): ITourPhotoGalleryState => {
  const metaPaths = [`media/__meta/gallerypreviews/tours/${tourId}`];
  const galleries: List<GalleryPreview> = state.getIn(
    ['media', 'gallerypreviews', 'tours', tourId],
    List(),
  );

  return {
    galleries,
    metaPaths,
  };
};

/** Overview of a Tour's galleries, as a single list/grid */
const _TourPhotoGallery: React.FC<ITourPhotoGallery & ITourPhotoGalleryState> = ({
  season,
  metaPaths,
  galleries,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <div className="TourPhotoGallery">
      <Loading metaPaths={metaPaths}>
        {() =>
          galleries.size === 0 ? (
            <BlockText extraClassName="ph3 ph0-m">There are no photos to show.</BlockText>
          ) : (
            <ul className="grid gt-gallery">
              {galleries.map((photoGallery, index) => (
                <PhotoGallerySummary
                  key={photoGallery.galleryId}
                  title={photoGallery.title}
                  // The Tour photo galleries only point to masters
                  galleryUrl={eventPhotosLink(
                    seasonConfig,
                    season,
                    photoGallery.eventId,
                    'WorldTour',
                  )}
                  imageUrl={photoGallery.imageUrl}
                  total={photoGallery.total}
                  variant="subgrid"
                  index={index}
                />
              ))}
            </ul>
          )
        }
      </Loading>
    </div>
  );
};

export const TourPhotoGallery = connect<ITourPhotoGalleryState, {}, ITourPhotoGallery>(
  mapStateToProps,
)(_TourPhotoGallery);
TourPhotoGallery.displayName = 'TourPhotoGallery';
