import React from 'react';
import cx from 'classnames';
import {map, has, join, isArray} from 'lodash';
import hash from '@emotion/hash';

import * as StyleMaps from 'fiba/wt/ui/stylingAPI/styleMaps';

/** TODO: To make this a configurable library, consider a constructor function
 * Then, users can consume this however they like, and re-export to build more things
 *
 * const {superstyled, createStyleProvider, styledAPItoClassNames} = initialiseSuperstyled(Stylemap)
 *
 * TODO: Rename styledAPItoClassNames to superstyledClassNames
 */

/* The main object which styles derive from */
export const StyleMap = {
  fontSize: StyleMaps.TypeScale,
  fontFamily: StyleMaps.FontFamily,
  fontWeight: StyleMaps.FontWeight,
  display: StyleMaps.Display,
  height: StyleMaps.Height,
  width: StyleMaps.Width,
  maxWidth: StyleMaps.MaxWidth,
  minWidth: StyleMaps.MinWidth,
  measure: StyleMaps.Measure,
  lineHeight: StyleMaps.LineHeight,
  align: StyleMaps.Centering,
  bgColor: StyleMaps.BgColor,
  color: StyleMaps.FgColor,
  hoverColor: StyleMaps.HoverColor,
  hoverBgColor: StyleMaps.HoverBgColor,
  focusColor: StyleMaps.FocusColor,
  focusBgColor: StyleMaps.FocusBgColor,
  borderColor: StyleMaps.BorderColor,
  opacity: StyleMaps.Opacity,
  border: StyleMaps.Border,
  borderRadius: StyleMaps.BorderRadius,
  borderWidth: StyleMaps.BorderWidth,
  shadow: StyleMaps.BoxShadow,
  textAlign: StyleMaps.TextAlign,
  verticalAlign: StyleMaps.VerticalAlign,
  textTransform: StyleMaps.TextTransform,
  position: StyleMaps.Position,
  aspectRatio: StyleMaps.AspectRatio,
  flex: StyleMaps.Flex,
  flexGrow: StyleMaps.FlexGrow,
  flexShrink: StyleMaps.FlexShrink,
  flexOrder: StyleMaps.FlexOrder,
  alignSelf: StyleMaps.AlignSelf,
  flexDirection: StyleMaps.FlexDirection,
  flexWrap: StyleMaps.FlexWrap,
  alignItems: StyleMaps.AlignItems,
  alignContent: StyleMaps.AlignContent,
  justifyContent: StyleMaps.JustifyContent,
  vSpace: StyleMaps.VSpace,
  hSpace: StyleMaps.HSpace,
  letterSpacing: StyleMaps.LetterSpacing,
  ...StyleMaps.Margin,
  ...StyleMaps.Padding,
};

///// Types /////
export type StyleMapType = typeof StyleMap;
export type StyledAPI<K extends keyof StyleMapType> = Pick<StyleMapType, K>;

/** For each Property P in (the constrainted) StyleMap T [P in keyof T],
 *  assign the type of P to be a valid value for P (keyof T[P] or array of keyof T[P])
 */
export type StyleProps<T> = {[P in keyof T]?: keyof T[P] | Array<keyof T[P]>};

/** Get a type for single property, useful if you want one or more props aliased */
export type StyleProp<K extends keyof StyleMapType> = keyof StyleMapType[K];

/** Composed type of StyleProps<StyledAPI<...>>. Use it to define components ad-hoc. */
export type SuperStyled<K extends keyof StyleMapType> = StyleProps<StyledAPI<K>>;

/** Type with keys K removed from Type T, if K extends T */
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

/** Type with keys K removed from Type T */
export type OmitOpen<T, K> = Pick<T, Exclude<keyof T, K>>;

/** Helper type that re-exports HTML props and removes those shared with StyledProps
 * NOTE: these might allow illegal combinations
 */
export type StyledHTML<El extends keyof JSX.IntrinsicElements> = OmitOpen<
  JSX.IntrinsicElements[El],
  keyof StyleMapType
>;

/* Construct responsive class list or give single class for prop-value pair
 * TODO: Warn/prevent setting classes without responsive styles to avoid confusion
 */
const getClass = (prop, value, styleMap) =>
  isArray(value)
    ? join(
        map(value, (v, i) => `${styleMap[prop][v]}${StyleMaps.responsiveSuffixes[i]}`),
        ' ',
      )
    : styleMap[prop][value];

// TODO: write a test case for non-styleProps props being passed here, and verify that it is correct
export function styledAPItoClassNames<T extends {}>(
  styleProps: T,
  styleMap = StyleMap,
  debugPrefix?: string,
) {
  // Resolve classes, responsive or otherwise for prop-value pairs
  // Only keep prop-value pairs for props defined in StyleMap
  const classNames = map(styleProps, (value, prop) =>
    has(StyleMap, prop) ? getClass(prop, value, styleMap) : '',
  );

  const superstyledClassName = cx(classNames);

  // Debugging in developent
  let debugClassName = '';

  // TODO: Verify that the hash is not imported if in production
  if (process.env.NODE_ENV !== 'production') {
    if (debugPrefix) {
      debugClassName = `${debugPrefix}--${hash(superstyledClassName)}`;
    }
  }

  // Run through classnames to combine
  return cx(superstyledClassName, debugClassName);
}

/* Components that provide the API */
interface StyleProviderChildProps {
  className: string;
}

interface StyleProviderProps extends StyledComponentProps {
  children: (styleProps: StyleProviderChildProps) => React.ReactNode;
}

/* Renderless component that compiles passed-in StyledAPI props into a className.
 * Passes on the className to a children as a render callback.
 * FIXME: We should be constraining on T extending StyleProps<StyledAPI<K extends keyof StyleMap>>
 * but the types are ugly af and seem quite repetitive. Constraining could allow us to remove the
 * extra 'has' check on the styledAPItoClassNames.
 */
export function createStyleProvider<S>(
  defaultProps?: S,
  baseClass?: string,
): React.FunctionComponent<S & StyleProviderProps> {
  return props => {
    const {children, extraClassName, debugClassName, ...rest} = props as any;
    const styleProps = {...(defaultProps as any), ...rest};

    // NOTE:
    // Using the same StyleMap as the stylingAPI is safe/constrained, since everything is typechecked on T,
    // and keeps the implementation simple. If we want to make illegal styles
    // at runtime (or vanilla JS), then we should have a (constrainedAPI?: StyledAPI<K>)
    // argument to createStyleProvider.
    const className = cx(
      baseClass,
      styledAPItoClassNames(styleProps, StyleMap, debugClassName),
      extraClassName,
    );

    // TODO: consider passing styleProps as well, for further composition
    return children({className});
  };
}

interface StyledComponentProps {
  forwardedRef?: React.Ref<{}>;
  extraClassName?: string;
  debugClassName?: string;
}

/* Higher-order Component (HoC) that takes in a set of StyledAPI props T and other props P.
 * Compiles the StyledAPI props using a StyleProvider, and passes them in to the component
 * specified at init.
 * TODO: Could handle class statics better @see styled-components
 * TODO: Make curried version instead
 * TODO: Handle extending components better
 * TODO: Consider whether defaultProps can be any of the subset or not
 */
export function superstyled<
  S extends StyleProps<StyledAPI<keyof StyleMapType>>,
  P extends {className?: string} = {}
>(
  WrappedComponent: React.ComponentType<P> | string,
  defaultStyleProps?: S,
  baseClassName?: string,
) {
  const Superstyled: React.FunctionComponent<(S & StyledComponentProps) & P> = props => {
    // Separate style props (T) and extraClassName from component own props (P)
    /* eslint-disable prefer-const */
    let styleProps = ({} as unknown) as S & StyledComponentProps;
    let ownProps = ({} as unknown) as P;

    for (let prop of Object.keys(props)) {
      if (has(StyleMap, prop) || prop === 'extraClassName' || prop === 'debugClassName') {
        styleProps[prop] = props[prop];
      } else if (prop !== 'forwardedRef') {
        ownProps[prop] = props[prop];
      }
    }
    /* eslint-enable prefer-const */

    // Compile className, same as for StyleProvider
    const className = cx(
      baseClassName,
      styledAPItoClassNames(
        {...(defaultStyleProps as any), ...(styleProps as any)},
        StyleMap,
        props.debugClassName,
      ),
      props.extraClassName,
    );

    // Pass own props and the compiled className straight to Component.
    return <WrappedComponent className={className} ref={props.forwardedRef} {...ownProps} />;
  };
  const wrappedName = `Superstyled(${getDisplayName(WrappedComponent)})`;
  Superstyled.displayName = wrappedName;

  // Forward ref automatically
  // @see https://reactjs.org/docs/forwarding-refs.html
  const SuperstyledWithForward: React.RefForwardingComponent<{}, (S & StyledComponentProps) & P> = (
    props,
    ref,
  ) => {
    // avoid creating a new object if there is no ref
    return <Superstyled {...(ref === null ? props : {forwardedRef: ref, ...(props as any)})} />;
  };

  // Give the inner component the same name as the Superstyled above
  SuperstyledWithForward.displayName = wrappedName;

  const FinalSuperstyled = React.forwardRef(SuperstyledWithForward);
  return FinalSuperstyled;
}

/** Wrap the component's name for HoC
 * @see https://reactjs.org/docs/higher-order-components.html
 */
function getDisplayName(WrappedComponent: string | React.ComponentType<any>) {
  return typeof WrappedComponent === 'string'
    ? WrappedComponent
    : WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
