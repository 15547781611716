import React from 'react';
import {generateSizes, SizesSpec} from 'fiba/wt/utils/imageUtils';
import {ImgixConfig, imgixSrc, scaledImageParams, generateSrcsets} from 'fiba/wt/utils/imgixUtils';

//
// CONFIG

const defaultImageConfig: ImgixConfig = {};

//
// GENERAL QUERY TYPE

export interface ImgixQuery {
  baseUrl: string;
  imgixParams?: ImgixConfig;
  aspectRatio?: [number, number];
  autoSrcset?: boolean;
  width?: number;
  height?: number;
  sizes?: SizesSpec;
}

interface ImageProps {
  src: string;
  srcSet?: string;
  sizes?: string;
}

/** Take a set of props and Imgix Query, and return props for an image. */
export const getImgixImageProps = (props: ImgixQuery): ImageProps => {
  const {baseUrl, imgixParams, width, height, sizes, aspectRatio, autoSrcset = true} = props;

  // Merge default config and params
  const imgixConfig = {...defaultImageConfig, ...imgixParams};

  // Generate the srcSet values as an array, pick default and pass the rest to attribute
  if (autoSrcset) {
    const imgixSrcsets = generateSrcsets({
      baseConfig: imgixConfig,
      baseUrl,
      aspectRatio,
    });

    // If srcSet is not supported, use low-resoluttion image from the set
    // Split the srcset on space (where the width descriptor would be), keep first part
    // @example: 'https://fiba-3x3-imgix.com/awesome?w=320 320w' -> 'https://fiba-3x3-...'
    const defaultImageSrc = imgixSrcsets[0].split(' ')[0];
    return {
      src: defaultImageSrc,
      srcSet: imgixSrcsets.join(', '),
      sizes: generateSizes(sizes),
    };
  } else {
    // If we don't care about automatic srcSet, then just set src
    const sizedImgixConfig = {
      ...imgixConfig,
      ...scaledImageParams({width, height}, aspectRatio),
    };
    return {src: imgixSrc(sizedImgixConfig, baseUrl), sizes: generateSizes(sizes)};
  }
};

//
// IMGIX QUERY COMPONENT

// Imgix component passes on the query in a render callback
export type Imgix = ImgixQuery & {
  children: (props: ImageProps) => React.ReactElement<{}>;
};

/** Component that compiles the Imgix sources, generates sizes,
 * and calls back with image props.
 *
 * A render-prop version of getImagixImageProps.
 * Consider using the function version instead.
 */
export const Imgix: React.SFC<Imgix> = ({children, ...imgixQuery}) => {
  const imageProps = getImgixImageProps(imgixQuery);
  return children(imageProps);
};
