import React, {useContext} from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {PlayerStatistics} from 'fiba/wt/ui/statistics/PlayerStatistics';
import {
  curriedEventTeamPlayerLinkProvider,
  eventTeamLinkProvider,
  EventType,
} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

export interface Props {
  eventId: string;
  season: string;
  eventType: EventType;
  genderTab?: CategoryGenderTab;
}

export const EventStatisticsPlayersTab: React.FunctionComponent<Props> = props => {
  const {eventId, season, eventType, genderTab} = props;
  const {seasonConfig} = useContext(SiteConfigContext);

  return (
    <Spacer vSpace="4">
      <PlayerStatistics
        tourOrEventId={eventId}
        season={season}
        eventId={eventId}
        genderTab={genderTab}
        showEventsPlayed={false}
        // At the event level use teamInEventId
        renderTeamName={rowData => (
          <StyledLink
            href={eventTeamLinkProvider(
              seasonConfig,
              season,
              eventId,
              eventType,
            )(rowData.team.teamInEventId)}
          >
            <TeamNameAssembly standing={rowData['team']} isSuffixVisible isIocVisible={false} />
          </StyledLink>
        )}
        createPlayerHref={curriedEventTeamPlayerLinkProvider(
          seasonConfig,
          season,
          eventId,
          eventType,
        )}
      />
    </Spacer>
  );
};
