import React, {useContext} from 'react';
import {Map, List} from 'immutable';
import cx from 'classnames';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {getWinPercentage, getAveragePoints, getTeamDisplayScore} from 'fiba/common/core/results';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import ResultsStanding from 'fiba/common/core/models/api/results/ResultsStanding';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {sortPoolStandings} from 'fiba/wt/ui/poolStandings/PoolStandings';
import {Omit} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Link} from 'fiba/wt/ui/link/Link';

interface PoolCrossTableProps {
  pool: ResultsGroup;
  caption?: string | React.ReactNode;
  createTeamHref?: ResourceLinkProvider;
}

interface PoolCrossTableInnerProps extends PoolCrossTableProps {
  localizer: LocalizerService;
  useNewPointCalculation: boolean;
}

//
// Utility components
const HCell: React.FunctionComponent<{className?: string; scope?: string}> = ({
  scope = 'col',
  className,
  children,
}) => (
  <th scope={scope} className={cx(className, 'ph3 pv2 tl v-mid')}>
    {children}
  </th>
);

const Cell: React.FunctionComponent<{className?: string}> = ({className, children}) => (
  <td className={cx(className, 'pa3 tl v-mid')}>{children}</td>
);

type ScoreMap = Map<[string, string], string>;

function getGameReducer(localizer: LocalizerService) {
  return function (acc: ScoreMap, game: ResultsGameDetails) {
    const {homeTeam, awayTeam} = game;
    if (!homeTeam || !awayTeam || !homeTeam.teamId || !awayTeam.teamId) {
      return acc;
    }

    const homeTeamId = homeTeam.teamId;
    const awayTeamId = awayTeam.teamId;

    return acc
      .setIn([homeTeamId, awayTeamId], getTeamDisplayScore(homeTeam, game, localizer))
      .setIn([awayTeamId, homeTeamId], getTeamDisplayScore(awayTeam, game, localizer));
  };
}

function getScoreStr(
  standing1: ResultsStanding,
  standing2: ResultsStanding,
  scoreMap: ScoreMap,
  useNewPointCalculation: boolean,
) {
  const teamId1 = standing1.teamId;
  const teamId2 = standing2.teamId;
  let score1 = scoreMap.getIn([teamId1, teamId2]);
  let score2 = scoreMap.getIn([teamId2, teamId1]);
  if (score1 && score2) {
    if (useNewPointCalculation) {
      score1 = score1 > 21 ? score1 + '*' : score1;
      score2 = score2 > 21 ? score2 + '*' : score2;
    }
    return `${score1} - ${score2}`;
  } else {
    return 'N/A';
  }
}

interface IPoolCrossTableHead extends Omit<PoolCrossTableInnerProps, 'pool'> {
  poolStandings: List<ResultsStanding>;
}

const PoolCrossTableHead: React.FunctionComponent<IPoolCrossTableHead> = ({
  poolStandings,
  createTeamHref,
  localizer,
  useNewPointCalculation,
}) => (
  <tr className="bb bw1 b--silver-20 dark-30 fw4 striped--light-even">
    <HCell className="pl3 pl4-m">TEAM</HCell>

    {!!poolStandings &&
      poolStandings.map(standing => (
        <HCell className="tr" key={standing.teamId}>
          <p>Vs.</p>
          {createTeamHref && standing.teamId ? (
            <Link extraClassName="fw7" href={createTeamHref(standing.teamId)}>
              <TeamNameAssembly standing={standing.delete('teamNationality')} isSuffixVisible />
            </Link>
          ) : (
            <TeamNameAssembly standing={standing.delete('teamNationality')} isSuffixVisible />
          )}
        </HCell>
      ))}

    <HCell className="tr">{localizer.formatKey('RESULTS_HEADER_GAMES_PLAYED')}</HCell>
    {/* TODO: consider splitting these up as separate columns; discuss with JP. */}
    <HCell className="tr">
      <div className="pb2">{localizer.formatKey('RESULTS_HEADER_GAMES_WON')}</div>
      <div>{localizer.formatKey('RESULTS_HEADER_WIN_PERCENTAGE')}</div>
    </HCell>
    <HCell className="pr3 pr4-m tr">
      <div className="pb2">{localizer.formatKey('RESULTS_HEADER_TOTAL_POINTS')}</div>
      <div>
        {useNewPointCalculation
          ? localizer.formatKey('RESULTS_HEADER_ADJUSTED_POINTS')
          : localizer.formatKey('RESULTS_HEADER_AVERAGE_POINTS')}
      </div>
    </HCell>
  </tr>
);

const PoolCrossTableBody: React.FunctionComponent<PoolCrossTableInnerProps> = ({
  pool,
  createTeamHref,
  localizer,
  useNewPointCalculation,
}) => {
  const scoreMap = pool.groupGames.reduce(getGameReducer(localizer), Map() as ScoreMap);
  const groupStandings = pool.groupStandings && sortPoolStandings(pool.groupStandings);

  return (
    <tbody>
      <PoolCrossTableHead
        poolStandings={groupStandings}
        createTeamHref={createTeamHref}
        localizer={localizer}
        useNewPointCalculation={useNewPointCalculation}
      />
      {!!groupStandings &&
        groupStandings.map((standing, i) => (
          <tr
            key={i}
            className="bb bw1 b--silver-20 striped--light-even hover-bg-blue-10 focusw-bg-blue-10"
          >
            <HCell scope="row" className="pl3 pl4-m">
              {createTeamHref && standing.teamId ? (
                <Link extraClassName="fw7" href={createTeamHref(standing.teamId)}>
                  <TeamNameAssembly standing={standing} isSuffixVisible />
                </Link>
              ) : (
                <TeamNameAssembly standing={standing} isSuffixVisible />
              )}
            </HCell>

            {groupStandings.map((opponentStanding, j) => (
              <Cell key={j} className="tr">
                {getScoreStr(standing, opponentStanding, scoreMap, useNewPointCalculation)}
              </Cell>
            ))}

            <Cell className="tr">{standing.gamesPlayed}</Cell>
            <Cell className="tr">
              <div className="pb2">{standing.gamesWon}</div>
              <div>{getWinPercentage(standing, localizer)}</div>
            </Cell>
            <Cell className="tr pr3 pr4-m">
              <div className="pb2">{standing.totalPoints}</div>
              <div>{getAveragePoints(standing, localizer)}</div>
            </Cell>
          </tr>
        ))}
    </tbody>
  );
};

/* NOTE: This table is similar but not 100% the same as DataTable. We do not
 * have the feature to generate rows atm, so it would take a while to implement.
 * Thus, the pragmatic solution was to move over the classes, semantics and styling
 * but not try to use DataTable directly. This table thus lacks nice navigation,
 * the scroll indicator, and the more declarative API. If the DataTable keeps
 * improving, consider using it or porting things over.
 */
export const PoolCrossTable: React.FunctionComponent<PoolCrossTableProps> = ({
  caption,
  ...rest
}) => {
  const {localizer} = useContext(ServicesContext);
  const useNewPointCalculation = rest.pool?.groupStandings?.some(
    standing => standing.get('useNewPointCalculation') === true,
  );
  return (
    <div className="PoolCrossTable">
      <div className="overflow-x-auto focus-shadow">
        <table className="w-100 tnum br2">
          {!!caption && <caption>{caption}</caption>}
          <PoolCrossTableBody
            {...rest}
            localizer={localizer}
            useNewPointCalculation={useNewPointCalculation}
          />
        </table>
        {useNewPointCalculation ? (
          <div className="PoolCrossTable-Note">
            {'* max 21pts are taken into account for standing calculations.'}
          </div>
        ) : null}
      </div>
    </div>
  );
};
