import React from 'react';
import {Map} from 'immutable';
import Routes, {RouteFunction} from 'fiba/common/core/models/Routes';
import {composeControllers} from 'fiba/common/controllers/controllerUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import * as MetaTags from 'fiba/common/utils/metaTags';

import {TourLayout} from 'fiba/wt/ui/tourLayout/TourLayout';
import {EventLayout} from 'fiba/wt/ui/eventLayout/EventLayout';
import {EventSidebarRight} from 'fiba/wt/ui/eventLayout/EventSidebarRight';

import {HumanError} from 'fiba/wt/ui/humanError/HumanError';
import {TourPage} from 'fiba/wt/ui/tourPage/TourPage';
import {TourMacroTeamsPage} from 'fiba/wt/ui/tourTeamsPage/TourMacroTeamsPage';
import {TourTeamsPageTab} from 'fiba/wt/ui/tourTeamsPage/TourTeamsPage';
import {TourTeamPage} from 'fiba/wt/ui/tourTeamPage/TourTeamPage';
import {TourStandingsPage} from 'fiba/wt/ui/tourStandingsPage/TourStandingsPage';
import {TourMacroStatisticsPage} from 'fiba/wt/ui/tourStatisticsPage/TourMacroStatisticsPage';
import {TourPhotosPage} from 'fiba/wt/ui/tourPhotosPage/TourPhotosPage';
import {TourVideosPage} from 'fiba/wt/ui/tourVideosPage/TourVideosPage';
import {TourMorePage} from 'fiba/wt/ui/tourMorePage/TourMorePage';
import {TourWhereToWatchPage} from 'fiba/wt/ui/tourWhereToWatchPage/TourWhereToWatchPage';

import {EventPage} from 'fiba/wt/ui/eventPage/EventPage';
import {EventAboutPage} from 'fiba/wt/ui/eventAboutPage/EventAboutPage';
import {EventStandingsPage} from 'fiba/wt/ui/eventStandingsPage/EventStandingsPage';
import {EventTeamsPage} from 'fiba/wt/ui/eventTeamsPage/EventTeamsPage';
import {EventTeamPage} from 'fiba/wt/ui/eventTeamPage/EventTeamPage';
import {EventTeamPhotosPage} from 'fiba/wt/ui/eventTeamPhotosPage/EventTeamPhotosPage';
import {EventSchedulePage} from 'fiba/wt/ui/eventSchedulePage/EventSchedulePage';
import {EventPhotosPage} from 'fiba/wt/ui/eventPhotosPage/EventPhotosPage';
import {EventVideosPage} from 'fiba/wt/ui/eventVideosPage/EventVideosPage';
import {EventDunkPage} from 'fiba/wt/ui/eventDunkPage/EventDunkPage';
import {EventShootoutPage} from 'fiba/wt/ui/eventShootoutPage/EventShootoutPage';
import {EventMediaServicesPage} from 'fiba/wt/ui/eventMediaServicesPage/EventMediaServicesPage';
import {EventHowToQualifyPage} from 'fiba/wt/ui/eventHowToQualifyPage/EventHowToQualifyPage';
import {PhotoGalleryPage} from 'fiba/wt/ui/photoGalleryPage/PhotoGalleryPage';
import {NewsArticlePage} from 'fiba/wt/ui/newsArticlePage/NewsArticlePage';
import {EventNewsPage} from 'fiba/wt/ui/eventNewsPage/EventNewsPage';
import {TourNewsPage} from 'fiba/wt/ui/tourNewsPage/TourNewsPage';
import {PlayerPage} from 'fiba/wt/ui/playerPage/PlayerPage';
import {GameCardWidget} from 'fiba/wt/ui/gameCardWidget/GameCardWidget';
import {
  EventStatisticsPage,
  EventStatisticsTab,
} from 'fiba/wt/ui/eventStatisticsPage/EventStatisticsPage';
import {NotFoundPage} from 'fiba/wt/ui/notFoundPage/NotFoundPage';

import {EventSidebarSecondary} from 'fiba/wt/ui/eventLayout/EventSidebarSecondary';
import {TourSidebar} from 'fiba/wt/ui/tourSidebar/TourSidebar';
import {TourNewsSidebar} from 'fiba/wt/ui/tourLayout/TourNewsSidebar';
import {EventNewsSidebar} from 'fiba/wt/ui/eventLayout/EventNewsSidebar';

import contentController from 'fiba/wt/controllers/contentController';
import tourController from 'fiba/wt/controllers/tourController';

import eventController from 'fiba/wt/controllers/eventController';
import teamController from 'fiba/wt/controllers/teamController';
import playerController from 'fiba/wt/controllers/playerController';
import newsController from 'fiba/wt/controllers/newsController';
import mediaController from 'fiba/wt/controllers/mediaController';
import gameController from 'fiba/wt/controllers/gameController';
import tourProgrammabilityController from 'fiba/wt/controllers/tourProgrammabilityController';
import {
  eventStatTopScorersController,
  eventPlayerStatsController,
  eventStatsController,
  eventTeamStatsController,
  tourPlayerStatsController,
  tourStatsController,
  tourTeamStatsController,
  tourProCircuitTeamStatsController,
  tourProCircuitPlayerStatsController,
  eventStatSummaryController,
  tourStatSummaryController,
  proCircuitStatSummaryController,
} from 'fiba/wt/controllers/statsController';
import {ChallengersPage} from 'fiba/wt/ui/challengersPage/ChallengersPage';
import {SuperQuestsPage} from 'fiba/wt/ui/superQuestsPage/SuperQuestsPage';
import {TourHowToQualifyPage} from 'fiba/wt/ui/tourHowToQualifyPage/TourHowToQualifyPage';
import {TourCalendarPage} from 'fiba/wt/ui/tourCalendarPage/TourCalendarPage';
import {EventAwardsPage} from 'fiba/wt/ui/eventAwardsPage/EventAwardsPage';
import {EventPrizesPage} from 'fiba/wt/ui/eventPrizesPage/EventPrizesPage';
import {eventQualificationsController} from 'fiba/wt/controllers/qualificationsController';
import {GamePage, GamePageTab} from 'fiba/wt/ui/gamePage/GamePage';
import tourTeamResultsController from 'fiba/wt/controllers/tourTeamResultsController';
import {teamRankingsController} from 'fiba/wt/controllers/teamRankingsController';
import Event from 'fiba/common/core/models/api/events/Event';
import NewsItem from 'fiba/common/core/models/api/feed/NewsItem';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {getImgixImageProps} from 'fiba/wt/ui/imgix/Imgix';
import {DownPage} from 'fiba/common/ui/downPage/DownPage';
import {CustomPage} from 'fiba/wt/ui/customPage/CustomPage';
import {ContentTypes} from 'fiba/wt/services/contentfulService';
import {getCustomPageName} from 'fiba/wt/stores/contentStore';

const routes: Routes = {
  '/': createSeasonRoute(),

  // This is the top-most error,which also appears in cases where something outside
  // the React tree (e.g. event handler, request for data in Redux) happened.
  // There is no useful context atm; the status/error/message are very broad.
  '/error': () => ({
    // TODO: Should we provide the basic tour layout here? It may not be feasible
    // since if we encountered an error, it may be that we can't recover to even
    // run the necessary controllers, which could lead to further errors.
    components: {
      content: ({status, error, message}) => (
        <HumanError status={status} message={message} error={error} />
      ),
    },
    layout: props => <TourLayout tourTab="" {...props} />,
    title: 'Error',
  }),

  '/down': ({}) => ({
    controllers: [],
    components: {
      content: () => <DownPage />,
    },
    layout: ({components}) => components.content,
  }),

  '/404': ({}) => ({
    controllers: [],
    components: {
      content: () => <NotFoundPage />,
    },
    layout: props => <TourLayout tourTab="" {...props} />,
  }),

  //
  // Tour

  '/:season': createSeasonRoute(),

  '/:season/more': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourArticles: {tourId, season},
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourMorePage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="more" {...props} />,
    title: ({cache}) => `More | ${cache(['tours', tourId, 'name'])}`,
  }),

  //
  // Tour How to Qualify
  '/:season/how-to-qualify': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourArticles: {tourId, season},
        tourConfiguration: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourHowToQualifyPage {...(props as any)} />,
    },
    // FIXME: Layout for plain contentful article
    layout: props => <TourLayout tourTab="how-to-qualify" {...props} />,
    title: ({cache}) => `How to Qualify | ${cache(['tours', tourId, 'name'])}`,
  }),
  // Tour Calendar
  '/:season/calendar': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourArticles: {tourId, season},
        tourConfiguration: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourCalendarPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="calendar" {...props} />,
    title: ({cache}) => `Event Calendar | ${cache(['tours', tourId, 'name'])}`,
  }),

  //
  // Tour Where to Watch
  '/:season/where-to-watch': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourArticles: {tourId, season},
        tourConfiguration: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourWhereToWatchPage {...(props as any)} />,
    },
    // FIXME: Layout for plain contentful article
    layout: props => <TourLayout tourTab="where-to-watch" {...props} />,
    title: ({cache}) => `Where to Watch | ${cache(['tours', tourId, 'name'])}`,
  }),

  //
  // Tour photos
  '/:season/photos': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      mediaController({
        tourGalleryPreview: tourId,
      }),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourPhotosPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="photos" {...props} />,
    title: ({cache}) => `Photos | ${cache(['tours', tourId, 'name'])}`,
  }),

  //
  // Tour videos
  '/:season/videos': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourEmbeddedMedia: {tourId, season},
        tourSponsors: {tourId, season},
        tourConfiguration: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourVideosPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="videos" {...props} />,
    title: ({cache}) => `Videos | ${cache(['tours', tourId, 'name'])}`,
  }),

  //
  // Tour teams
  '/:season/teams': createTourTeamsRoute('root'),
  '/:season/teams/world-tour': createTourTeamsRoute('root'),
  '/:season/teams/world-tour/topten': createTourTeamsRoute('topten'),
  '/:season/teams/world-tour/all': createTourTeamsRoute('all'),
  '/:season/teams/pro-circuit': createProCircuitTourTeamsRoute(),

  '/:season/teams/:teamId': ({tourId, season, teamId}) => ({
    controllers: [
      tourController(tourId, {
        events: true,
        teams: true,
      }),
      tourTeamResultsController(tourId, teamId),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourTeamPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="teams" {...props} />,
    title: ({cache}) =>
      `${cache(['tourTeams', teamId, 'name'])} | ${cache(['tours', tourId, 'name'])}`,
  }),

  //
  // Tour Team Player

  '/:season/teams/:teamId/players/:playerId': ({tourId, season, teamId, playerId}) => ({
    // NOTE: not using tourTeamController atm, since no data references that.
    // The URL is mostly for future extension, layout, and breadcrumbs.
    // If the breadcrumb needs the team name, we can look into that.
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      playerController(playerId),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => (
        <PlayerPage
          {...(props as any)}
          // Add some padding top because the TourLayout has none
          pt={3}
        />
      ),
    },
    title: ({cache}) =>
      `${cache(['players', playerId, 'lastName'])} | ${cache(['tours', tourId, 'name'])}`,
    layout: props => <TourLayout tourTab="teams" {...props} />,
  }),

  //
  // Tour standings

  '/:season/standings': createTourStandingsRoute('latest'),
  '/:season/standings/latest': createTourStandingsRoute('latest'),
  '/:season/standings/intermediate': createTourStandingsRoute('intermediate'),

  //
  // Tour statistics

  '/:season/stats': getTourStatsSummaryRoute(),

  // Tour statistics - World Tour
  '/:season/stats/world-tour': getTourStatsSummaryRoute(),
  '/:season/stats/world-tour/summary': getTourStatsSummaryRoute(),
  '/:season/stats/world-tour/teams': getTourStatsTeamsRoute(),
  '/:season/stats/world-tour/players': getTourStatsPlayersRoute(),
  '/:season/stats/world-tour/fastest-games': getTourStatsFastestGamesRoute(),
  '/:season/stats/world-tour/downloads': getTourStatsDownloadsRoute(),

  // Tour statistics - Pro Circuit
  '/:season/stats/pro-circuit': getProCircuitTourStatsSummaryRoute(),
  '/:season/stats/pro-circuit/summary': getProCircuitTourStatsSummaryRoute(),
  '/:season/stats/pro-circuit/teams': getProCircuitTourStatsTeamsRoute(),
  '/:season/stats/pro-circuit/players': getProCircuitTourStatsPlayersRoute(),
  '/:season/stats/pro-circuit/downloads': getProCircuitTourStatsDownloadsRoute(),

  // Tour News
  '/:season/news': ({season, tourId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      newsController({tourNewsPreviews: tourId}),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourNewsPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="news" {...props} />,
    title: ({cache}) => `News | ${cache(['tours', tourId, 'name'])}`,
  }),

  '/:season/news/:newsId': ({season, tourId, newsId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      newsController({tourNewsPreviews: tourId, newsId}),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <NewsArticlePage {...(props as any)} />,
      // TODO: some way to limit to related tags?
      sidebar: props => <TourNewsSidebar {...(props as any)} />,
    },
    renderMetaTags: renderMetaTagsForNews({newsId, season}),
    layout: props => <TourLayout tourTab="news" {...props} />,
    title: ({cache}) =>
      `${
        cache(['news', 'full', newsId]) !== undefined ? cache(['news', 'full', newsId]).title : ''
      }`,
  }),

  //
  // Challengers

  '/:season/challengers': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <ChallengersPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="" {...props} />,
    title: ({cache}) => `Challengers ${cache(['tours', tourId, 'name'])}`,
  }),

  '/:season/challengers/:challengerEventSlug': getEventOverviewRoute(),
  '/:season/challengers/:challengerEventSlug/about': getEventAboutRoute(),
  '/:season/challengers/:challengerEventSlug/how-to-qualify': getEventHowToQualifyRoute(),
  '/:season/challengers/:challengerEventSlug/awards': getEventAwardsRoute(),
  '/:season/challengers/:challengerEventSlug/prizes': getEventPrizesRoute(),

  // Challenger Media Services
  '/:season/challengers/:challengerEventSlug/media-services': getEventMediaServicesRoute(),

  // Challenger standings
  '/:season/challengers/:challengerEventSlug/standings': createEventStandingsRoute(),
  '/:season/challengers/:challengerEventSlug/standings/brackets': createEventStandingsRoute(),
  '/:season/challengers/:challengerEventSlug/standings/pools/': createEventStandingsRoute(),
  '/:season/challengers/:challengerEventSlug/standings/pools/:poolId': createEventStandingsRoute(),
  '/:season/challengers/:challengerEventSlug/standings/qd': createEventStandingsRoute(),
  '/:season/challengers/:challengerEventSlug/standings/qd/:poolId': createEventStandingsRoute(),
  '/:season/challengers/:challengerEventSlug/standings/preview': createEventStandingsRoute(),

  // Challenger games
  '/:season/challengers/:challengerEventSlug/games': getEventGamesRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId': getGameBoxScoreRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/info': getGameInfoRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/box-score': getGameBoxScoreRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/box-score/home-team': getEventGameTeamHomeRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/box-score/away-team': getEventGameTeamAwayRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/play-by-play': getPlayByPlayRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/play-by-play/table-officials-data': getPlayByPlayTableOfficialsRoute(),
  '/:season/challengers/:challengerEventSlug/games/:gameId/play-by-play/statistics-data': getPlayByPlayStatisticsDataRoute(),

  // Challenger stats
  '/:season/challengers/:challengerEventSlug/stats': getEventStatsDefaultTabRoute(),
  '/:season/challengers/:challengerEventSlug/stats/summary': getEventStatsSummaryRoute(),
  '/:season/challengers/:challengerEventSlug/stats/fastest-games': getEventStatsFastestGamesRoute(),
  '/:season/challengers/:challengerEventSlug/stats/players': getEventStatsPlayersRoute(),
  '/:season/challengers/:challengerEventSlug/stats/teams': getEventStatsTeamsRoute(),
  '/:season/challengers/:challengerEventSlug/stats/top-scorers': getTopScorerStatsRoute(),

  // Challenger teams
  '/:season/challengers/:challengerEventSlug/teams': getEventTeamsRoute(),
  '/:season/challengers/:challengerEventSlug/teams/:teamId': getEventTeamRoute(),
  '/:season/challengers/:challengerEventSlug/teams/:teamId/photos': getEventTeamPhotosRoute(),

  // Challenger team Player
  '/:season/challengers/:challengerEventSlug/teams/:teamId/players/:playerId': getEventTeamPlayerRoute(),

  // Challenger photos
  '/:season/challengers/:challengerEventSlug/photos': getEventPhotosRoute(),
  '/:season/challengers/:challengerEventSlug/photos/:galleryId': getEventGalleryRoute(),

  // Challenger videos
  '/:season/challengers/:challengerEventSlug/videos': getEventVideosRoute(),

  // Challenger dunk
  '/:season/challengers/:challengerEventSlug/dunk': getEventDunkRoute(),

  // Challenger shootout
  '/:season/challengers/:challengerEventSlug/shootout': getEventShootoutRoute(),

  // Challenger News
  '/:season/challengers/:challengerEventSlug/news': getEventNewsRoute(),
  '/:season/challengers/:challengerEventSlug/news/:newsId': getEventNewsItemRoute(),

  // Challenger Team News
  '/:season/challengers/:challengerEventSlug/teams/:teamId/news/:newsId': getEventTeamNewsRoute(),

  '/:season/challengers/:challengerEventSlug/:customPageSlug': getEventCustomPageRoute(),

  //
  // SuperQuests
  // Yes, these repeat almost all that Challengers have.
  // This is fine; we'd rather have extra explicit route entries,
  // the logic is in functions anyway! We might tweak "hey SuperQuests
  // does not have X Y Z", and this makes it simpler than inventing
  // a cascading system.

  '/:season/superquests': ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, superQuests: true}),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <SuperQuestsPage {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="" {...props} />,
    title: ({cache}) => `SuperQuests ${cache(['tours', tourId, 'name'])}`,
  }),

  '/:season/superquests/:superQuestEventSlug': getEventOverviewRoute(),
  '/:season/superquests/:superQuestEventSlug/about': getEventAboutRoute(),
  '/:season/superquests/:superQuestEventSlug/how-to-qualify': getEventHowToQualifyRoute(),
  '/:season/superquests/:superQuestEventSlug/awards': getEventAwardsRoute(),
  '/:season/superquests/:superQuestEventSlug/prizes': getEventPrizesRoute(),

  // SuperQuest Media Services
  '/:season/superquests/:superQuestEventSlug/media-services': getEventMediaServicesRoute(),

  // SuperQuest standings
  '/:season/superquests/:superQuestEventSlug/standings': createEventStandingsRoute(),
  '/:season/superquests/:superQuestEventSlug/standings/brackets': createEventStandingsRoute(),
  '/:season/superquests/:superQuestEventSlug/standings/pools/': createEventStandingsRoute(),
  '/:season/superquests/:superQuestEventSlug/standings/pools/:poolId': createEventStandingsRoute(),
  '/:season/superquests/:superQuestEventSlug/standings/qd': createEventStandingsRoute(),
  '/:season/superquests/:superQuestEventSlug/standings/qd/:poolId': createEventStandingsRoute(),
  '/:season/superquests/:superQuestEventSlug/standings/preview': createEventStandingsRoute(),

  // SuperQuest games
  '/:season/superquests/:superQuestEventSlug/games': getEventGamesRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId': getGameBoxScoreRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/info': getGameInfoRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/box-score': getGameBoxScoreRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/box-score/both-teams': getEventGameBothTeamsRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/box-score/home-team': getEventGameTeamHomeRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/box-score/away-team': getEventGameTeamAwayRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/play-by-play': getPlayByPlayRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/play-by-play/table-officials-data': getPlayByPlayTableOfficialsRoute(),
  '/:season/superquests/:superQuestEventSlug/games/:gameId/play-by-play/statistics-data': getPlayByPlayStatisticsDataRoute(),

  // SuperQuest stats
  '/:season/superquests/:superQuestEventSlug/stats': getEventStatsDefaultTabRoute(),
  '/:season/superquests/:superQuestEventSlug/stats/summary': getEventStatsSummaryRoute(),
  '/:season/superquests/:superQuestEventSlug/stats/fastest-games': getEventStatsFastestGamesRoute(),
  '/:season/superquests/:superQuestEventSlug/stats/players': getEventStatsPlayersRoute(),
  '/:season/superquests/:superQuestEventSlug/stats/teams': getEventStatsTeamsRoute(),
  '/:season/superquests/:superQuestEventSlug/stats/top-scorers': getTopScorerStatsRoute(),

  // SuperQuest teams
  '/:season/superquests/:superQuestEventSlug/teams': getEventTeamsRoute(),
  '/:season/superquests/:superQuestEventSlug/teams/:teamId': getEventTeamRoute(),
  '/:season/superquests/:superQuestEventSlug/teams/:teamId/photos': getEventTeamPhotosRoute(),

  // SuperQuest team Player
  '/:season/superquests/:superQuestEventSlug/teams/:teamId/players/:playerId': getEventTeamPlayerRoute(),

  // SuperQuest photos
  '/:season/superquests/:superQuestEventSlug/photos': getEventPhotosRoute(),
  '/:season/superquests/:superQuestEventSlug/photos/:galleryId': getEventGalleryRoute(),

  // SuperQuest videos
  '/:season/superquests/:superQuestEventSlug/videos': getEventVideosRoute(),

  // SuperQuest dunk
  '/:season/superquests/:superQuestEventSlug/dunk': getEventDunkRoute(),

  // SuperQuest shootout
  '/:season/superquests/:superQuestEventSlug/shootout': getEventShootoutRoute(),

  // SuperQuest News
  '/:season/superquests/:superQuestEventSlug/news': getEventNewsRoute(),
  '/:season/superquests/:superQuestEventSlug/news/:newsId': getEventNewsItemRoute(),

  // SuperQuest Team News
  '/:season/superquests/:superQuestEventSlug/teams/:teamId/news/:newsId': getEventTeamNewsRoute(),

  '/:season/superquests/:superQuestEventSlug/:customPageSlug': getEventCustomPageRoute(),

  //
  // Masters

  // TODO: Make sure tourId, season and eventId match
  '/:season/:eventSlug': getEventOverviewRoute(),
  '/:season/:eventSlug/about': getEventAboutRoute(),
  '/:season/:eventSlug/how-to-qualify': getEventHowToQualifyRoute(),
  '/:season/:eventSlug/awards': getEventAwardsRoute(),
  '/:season/:eventSlug/prizes': getEventPrizesRoute(),

  // Event Media Services
  '/:season/:eventSlug/media-services': getEventMediaServicesRoute(),

  // Event standings
  '/:season/:eventSlug/standings': createEventStandingsRoute(),
  '/:season/:eventSlug/standings/brackets': createEventStandingsRoute(),
  '/:season/:eventSlug/standings/pools/': createEventStandingsRoute(),
  '/:season/:eventSlug/standings/pools/:poolId': createEventStandingsRoute(),
  '/:season/:eventSlug/standings/qd': createEventStandingsRoute(),
  '/:season/:eventSlug/standings/qd/:poolId': createEventStandingsRoute(),
  '/:season/:eventSlug/standings/preview': createEventStandingsRoute(),

  // Event games
  '/:season/:eventSlug/games': getEventGamesRoute(),
  '/:season/:eventSlug/games/:gameId': getGameBoxScoreRoute(),
  '/:season/:eventSlug/games/:gameId/info': getGameInfoRoute(),
  '/:season/:eventSlug/games/:gameId/box-score': getGameBoxScoreRoute(),
  '/:season/:eventSlug/games/:gameId/box-score/home-team': getEventGameTeamHomeRoute(),
  '/:season/:eventSlug/games/:gameId/box-score/away-team': getEventGameTeamAwayRoute(),
  '/:season/:eventSlug/games/:gameId/box-score/both-teams': getEventGameBothTeamsRoute(),
  '/:season/:eventSlug/games/:gameId/play-by-play': getPlayByPlayRoute(),
  '/:season/:eventSlug/games/:gameId/play-by-play/table-officials-data': getPlayByPlayTableOfficialsRoute(),
  '/:season/:eventSlug/games/:gameId/play-by-play/statistics-data': getPlayByPlayStatisticsDataRoute(),

  // Event stats
  '/:season/:eventSlug/stats': getEventStatsDefaultTabRoute(),
  '/:season/:eventSlug/stats/summary': getEventStatsSummaryRoute(),
  '/:season/:eventSlug/stats/fastest-games': getEventStatsFastestGamesRoute(),
  '/:season/:eventSlug/stats/players': getEventStatsPlayersRoute(),
  '/:season/:eventSlug/stats/teams': getEventStatsTeamsRoute(),
  '/:season/:eventSlug/stats/top-scorers': getTopScorerStatsRoute(),

  '/:season/:eventSlug/teams': getEventTeamsRoute(),
  '/:season/:eventSlug/teams/:teamId': getEventTeamRoute(),
  '/:season/:eventSlug/teams/:teamId/photos': getEventTeamPhotosRoute(),

  // Event team Player
  '/:season/:eventSlug/teams/:teamId/players/:playerId': getEventTeamPlayerRoute(),

  // Event photos
  '/:season/:eventSlug/photos': getEventPhotosRoute(),
  '/:season/:eventSlug/photos/:galleryId': getEventGalleryRoute(),

  // Event videos
  '/:season/:eventSlug/videos': getEventVideosRoute(),

  // Event dunk
  '/:season/:eventSlug/dunk': getEventDunkRoute(),

  // Event shootout
  '/:season/:eventSlug/shootout': getEventShootoutRoute(),

  // Event News
  '/:season/:eventSlug/news': getEventNewsRoute(),
  '/:season/:eventSlug/news/:newsId': getEventNewsItemRoute(),

  // Event Team News
  '/:season/:eventSlug/teams/:teamId/news/:newsId': getEventTeamNewsRoute(),

  '/:season/:eventSlug/:customPageSlug': getEventCustomPageRoute(),

  //
  // Dev

  // NOTE: Use `?layout=vertical` to force vertical game card
  '/:season/:eventSlug/live-scores-test': ({season, eventId, layout, tourId}) => ({
    developmentOnly: true,
    controllers: [
      eventController(eventId, {worldTourGames: true}),
      contentController({tourConfiguration: {tourId, season}}),
    ],
    components: {
      content: props => <GameCardWidget season={season} eventId={eventId} layout={layout as any} />,
    },
    layout: ({components}) => <div className="LiveScoresTestPage">{components['content']}</div>,
    title: 'Live scores test page',
  }),
};

//
// Route Creators
function createSeasonRoute(): RouteFunction {
  return ({tourId, season}) => ({
    controllers: [
      // run in sequence
      composeControllers(
        // NOTE: We must run the results feature after the main tourController
        tourController(tourId, {events: true, challengers: true, superQuests: true}),
        tourController(tourId, {results: {stage: 'latest'}}),

        mediaController({
          tourGalleryPreview: tourId,
        }),
      ),
      composeControllers(
        contentController({
          tourArticles: {tourId, season},
          tourEmbeddedMedia: {tourId, season},
          tourImageLinks: {tourId, season},
          tourConfiguration: {tourId, season},
          tourSponsors: {tourId, season},
        }),
        // TODO: tourProg is contingent only on tourConfigurationn, but this implies it depends on all of them
        tourProgrammabilityController(tourId, season, {deriveFromTourState: true}),
      ),
      newsController({tourNewsPreviews: tourId}),
    ],
    components: {
      content: props => <TourPage {...(props as any)} />,
      sidebar: props => <TourSidebar {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="" {...props} />,
    title: ({cache}) => cache(['tours', tourId, 'name']),
  });
}

// TODO: load only the necessary data for each tab. We are over-fetching atm
// this would mean chaining contentController, to figure out current season
function createTourTeamsRoute(tab: TourTeamsPageTab): RouteFunction {
  return ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {
        events: true,
        challengers: true,
        superQuests: true,
        // Needed for the 'all' tab (also for the root, when 'all' is root)
        teams: true,
      }),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
      // Needed for the 'top-10' tab (also for the root, when 'top-10' is root)
      teamRankingsController({tourId}),
    ],
    components: {
      content: props => (
        <TourMacroTeamsPage primaryTab="world-tour" teamTab={tab} {...(props as any)} />
      ),
    },
    layout: props => <TourLayout tourTab="teams" {...props} />,
    title: ({cache}) => `Teams | ${cache(['tours', tourId, 'name'])}`,
  });
}

function createProCircuitTourTeamsRoute(): RouteFunction {
  return ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {
        events: true,
        challengers: true,
        superQuests: true,
        proCircuitTeams: {
          season,
        },
      }),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourMacroTeamsPage primaryTab="pro-circuit" {...(props as any)} />,
    },
    layout: props => <TourLayout tourTab="teams" {...props} />,
    title: ({cache}) => `Pro Circuit Teams | ${cache(['tours', tourId, 'name'])}`,
  });
}

function createTourStandingsRoute(stage: string): RouteFunction {
  return ({tourId, season}) => ({
    controllers: [
      // NOTE: We must run the results feature after the main tourController
      composeControllers(
        tourController(tourId, {events: true, challengers: true, superQuests: true}),
        [
          tourController(tourId, {results: {stage: 'latest'}}),
          tourController(tourId, {results: {stage: 'intermediate'}}),
        ],
      ),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
      }),
    ],
    components: {
      content: props => <TourStandingsPage {...(props as any)} stage={stage} />,
    },
    layout: props => <TourLayout tourTab="standings" {...props} />,
    title: ({cache}) => `Standings | ${cache(['tours', tourId, 'name'])}`,
  });
}

function createEventStandingsRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      eventController(eventId, {categoriesByWorldTour: {results: true}, activities: true}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventStandingsPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="standings" {...props} />,
    title: ({cache}) =>
      `Standings | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventOverviewRoute(): RouteFunction {
  return ({tourId, season, eventId}) => {
    return {
      controllers: [
        tourController(tourId, {
          events: true,
          challengers: true,
          superQuests: true,
        }),
        contentController({
          eventArticles: {tourId, season, eventId},
          eventImageLinks: {tourId, season, eventId},
          eventConfiguration: {tourId, season, eventId},
          eventEmbeddedMedia: {tourId, season, eventId},
          tourConfiguration: {tourId, season},
          tourSponsors: {tourId, season},
          eventSponsors: {tourId, eventId, season},
        }),
        mediaController({
          eventGalleryPreview: eventId,
        }),
        eventController(eventId, {
          categoriesByWorldTour: {results: true},
          worldTourGames: true,
          teams: true,
        }),
        newsController({eventNewsPreviews: eventId}),
      ],
      components: {
        content: props => <EventPage {...(props as any)} />,
        sidebar: props => <EventSidebarRight {...(props as any)} />,
        secondarySidebar: props => <EventSidebarSecondary {...(props as any)} />,
      },
      layout: props => <EventLayout eventTab="overview" {...props} />,
      title: ({cache}) => {
        return `Overview ${mapWithDefaultEmpty<Event>(
          cache(['events', eventId]),
          event => `| ${event.name}`,
        )}`;
      },
    };
  };
}

function getEventAboutRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventArticles: {tourId, season, eventId},
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventAboutPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="about" {...props} />,
    title: ({cache}) =>
      `About | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventHowToQualifyRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventArticles: {tourId, season, eventId},
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
      eventQualificationsController({tourId, eventId}),
    ],
    components: {
      content: props => <EventHowToQualifyPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="how-to-qualify" {...props} />,
    title: ({cache}) =>
      `How to Qualify | ${mapWithDefaultEmpty<Event>(
        cache(['events', eventId]),
        event => event.name,
      )}`,
  });
}

function getEventAwardsRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventArticles: {tourId, season, eventId},
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
      }),
    ],
    components: {
      content: props => <EventAwardsPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="awards" {...props} />,
    title: ({cache}) =>
      `Awards | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventPrizesRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventArticles: {tourId, season, eventId},
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
      }),
    ],
    components: {
      content: props => <EventPrizesPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="prizes" {...props} />,
    title: ({cache}) =>
      `Prizes | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventCustomPageRoute(): RouteFunction {
  return ({tourId, season, eventId, customPageSlug}) => {
    return {
      controllers: [
        tourController(tourId, {events: true, challengers: true, superQuests: true}),
        contentController({
          eventConfiguration: {tourId, season, eventId},
          tourConfiguration: {tourId, season},
          eventImageLinks: {tourId, season, eventId},
          eventCustomPages: {tourId, season, eventId},
          eventArticles: {tourId, season, eventId},
        }),
      ],
      components: {
        content: () => (
          <CustomPage season={season} eventId={eventId} customPageSlug={customPageSlug} />
        ),
        secondarySidebar: props => <EventSidebarSecondary {...(props as any)} />,
      },
      layout: props => <EventLayout eventTab={customPageSlug} {...props} />,
      title: ({cache}) => {
        const customPage = cache<Map<string, any>>([
          'content',
          ContentTypes.CustomPage,
          'events',
          eventId,
        ]).find(storeCustomPage => storeCustomPage.getIn(['fields', 'slug']) === customPageSlug);
        const pageName = customPage ? getCustomPageName(customPage) : '';
        const eventName = mapWithDefaultEmpty<Event>(
          cache(['events', eventId]),
          event => event.name,
        );

        return pageName ? `${pageName} | ${eventName}` : eventName;
      },
    };
  };
}

function getEventMediaServicesRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventArticles: {tourId, season, eventId},
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventMediaServicesPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    // FIXME: Layout for plain contentful article
    layout: props => <EventLayout eventTab="media-services" {...props} />,
    title: ({cache}) =>
      `Media Services | ${mapWithDefaultEmpty<Event>(
        cache(['events', eventId]),
        event => event.name,
      )}`,
  });
}

function getEventGamesRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      eventController(eventId, {
        categoriesByWorldTour: {results: true},
        teams: true,
        worldTourGames: true,
        activities: true,
      }),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventSchedulePage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="games" {...props} />,
    title: ({cache}) =>
      `Games | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}
/** Event games
 *
 * GamePageTabProps has different mutually exclusive tabs,
 * for example Box-score has sub tabs `home-team` and `away-team` and Play-by-Play has `statics data` tab and `table officials data` tab
 *
 * We load different data for each of those pages
 */
function createEventGameTabRoute(tab: GamePageTab): RouteFunction {
  return ({tourId, season, eventId, gameId}) => {
    return {
      controllers: [
        tourController(tourId, {events: true, challengers: true, superQuests: true}),
        eventController(eventId, {
          categoriesByWorldTour: {results: true},
          teams: true,
          worldTourGames: true,
          activities: true,
        }),
        gameController(eventId, gameId, {
          summary: true,
          // Only load Game Stats in box-score tabs
          stats: tab[0] === 'box-score',

          // Only load PbP Table Officials Data in the specific tab
          playByPlayTableOfficialsData:
            tab[0] === 'play-by-play' &&
            // NOTE: The table officials is both the root and the tab
            (tab[1] === 'table-officials-data' || tab[1] === 'root'),

          // Only load PbP Stats Data in the specific tab
          playByPlayStatisticsData: tab[0] === 'play-by-play' && tab[1] === 'statistics-data',
        }),
        contentController({
          eventConfiguration: {tourId, season, eventId},
          tourConfiguration: {tourId, season},
          eventImageLinks: {tourId, season, eventId},
          tourSponsors: {tourId, season},
          eventSponsors: {tourId, eventId, season},
        }),
      ],
      components: {
        content: props => <GamePage tab={tab} {...(props as any)} />,
        secondarySidebar: props => <EventSidebarSecondary {...(props as any)} />,
      },
      layout: props => <EventLayout eventTab="games" {...props} />,
      // TODO: This should use the proper 'getTeamDisplayName' with a localizer
      title: ({cache}) =>
        `${cache(['games', 'details', gameId, 'homeTeam', 'teamName'])} vs ${cache([
          'games',
          'details',
          gameId,
          'awayTeam',
          'teamName',
        ])} | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
    };
  };
}

function getEventGameTeamHomeRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['box-score', 'home-team'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}

function getEventGameTeamAwayRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['box-score', 'away-team'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}
function getEventGameBothTeamsRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['box-score', 'both-teams'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}

function getGameBoxScoreRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['box-score', 'root'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}

function getGameInfoRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['info'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}

function getPlayByPlayRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['play-by-play', 'root'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}
function getPlayByPlayTableOfficialsRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['play-by-play', 'table-officials-data'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}

function getPlayByPlayStatisticsDataRoute(): RouteFunction {
  return ({tourId, season, eventId, gameId}) =>
    createEventGameTabRoute(['play-by-play', 'statistics-data'])({
      tourId,
      season,
      eventId,
      gameId,
    });
}

function getEventStatsDefaultTabRoute(): RouteFunction {
  return ({tourId, season, eventId}) =>
    createEventStatsTabRoute(
      'website-default-tab',
      'Stats',
    )({
      tourId,
      season,
      eventId,
    });
}

function getEventStatsSummaryRoute(): RouteFunction {
  return ({tourId, season, eventId}) =>
    createEventStatsTabRoute('summary', 'Summary', [eventStatSummaryController(eventId)])({
      tourId,
      season,
      eventId,
    });
}

function getEventStatsFastestGamesRoute(): RouteFunction {
  return ({tourId, season, eventId}) =>
    createEventStatsTabRoute('fastest-games', 'Fastest games', [eventStatsController(eventId)])({
      tourId,
      season,
      eventId,
    });
}

function getEventStatsPlayersRoute(): RouteFunction {
  return ({tourId, season, eventId}) =>
    createEventStatsTabRoute('players', 'Player stats', [
      composeControllers(
        eventController(eventId, {categoriesByWorldTour: true}),
        eventPlayerStatsController(eventId),
      ),
    ])({
      tourId,
      season,
      eventId,
    });
}
function getEventStatsTeamsRoute(): RouteFunction {
  return ({tourId, season, eventId}) =>
    createEventStatsTabRoute('teams', 'Team stats', [
      composeControllers(
        eventController(eventId, {categoriesByWorldTour: true}),
        eventTeamStatsController(eventId),
      ),
    ])({
      tourId,
      season,
      eventId,
    });
}

function getTopScorerStatsRoute(): RouteFunction {
  return ({tourId, season, eventId}) =>
    createEventStatsTabRoute('top-scorers', 'Top Scorers', [
      composeControllers(
        eventController(eventId, {categories: true}),
        eventStatTopScorersController(eventId),
      ),
    ])({
      tourId,
      season,
      eventId,
    });
}

function createEventStatsTabRoute(
  primaryTab: EventStatisticsTab,
  title,
  extraControllers = [],
  extraContentFeatures?,
): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
        ...extraContentFeatures,
      }),
      ...extraControllers,
    ],
    components: {
      content: props => <EventStatisticsPage primaryTab={primaryTab} {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="stats" {...props} />,
    title: ({cache}) =>
      `${title} | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

//
// Event teams

function getEventTeamsRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      eventController(eventId, {teams: true, categoriesByWorldTour: {results: true}}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventTeamsPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="teams" {...props} />,
    title: ({cache}) =>
      `Teams | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventTeamRoute(): RouteFunction {
  return ({tourId, season, eventId, teamId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      eventController(eventId, {worldTourGames: true, categoriesByWorldTour: {results: true}}),
      mediaController({teamPhotos: teamId}),
      teamController(teamId, {newsForEvent: eventId}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventTeamPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="teams" {...props} />,
    title: ({cache}) =>
      `${cache(['teams', teamId, 'name'])} | ${mapWithDefaultEmpty<Event>(
        cache(['events', eventId]),
        event => event.name,
      )}`,
  });
}

function getEventTeamPhotosRoute(): RouteFunction {
  return ({tourId, season, eventId, teamId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      eventController(eventId, {categoriesByWorldTour: {results: true}}),
      mediaController({teamPhotos: teamId}),
      teamController(teamId, {}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventTeamPhotosPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="teams" {...props} />,
    title: ({cache}) =>
      `${cache(['teams', teamId, 'name'])} photos | ${mapWithDefaultEmpty<Event>(
        cache(['events', eventId]),
        event => event.name,
      )}`,
  });
}

function getEventTeamPlayerRoute(): RouteFunction {
  return ({tourId, season, eventId, teamId, playerId}) => ({
    // NOTE: not using teamController atm, since no data references that.
    // The URL is mostly for future extension, layout, and breadcrumbs.
    // If the breadcrumb needs the team name, we can look into that.
    // This is probably even more specific than the Team Player
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      eventController(eventId, {}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
      playerController(playerId),
    ],
    components: {
      content: props => <PlayerPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    title: ({cache}) =>
      `${cache(['players', playerId, 'lastName'])} | ${mapWithDefaultEmpty<Event>(
        cache(['events', eventId]),
        event => event.name,
      )}`,
    layout: props => <EventLayout {...props} eventTab="teams" />,
  });
}

function getEventPhotosRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      mediaController({eventGalleryPreview: eventId}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventPhotosPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="photos" {...props} />,
    title: ({cache}) =>
      `Photos | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventGalleryRoute(): RouteFunction {
  return ({tourId, season, eventId, galleryId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      mediaController({gallery: {galleryId, loadPhotos: true}}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <PhotoGalleryPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="photos" {...props} />,
    title: ({cache}) =>
      `${cache([
        'media',
        '__meta',
        'photos',
        'galleries',
        galleryId,
        'title',
      ])} gallery | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventVideosRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        eventImageLinks: {tourId, season, eventId},
        eventEmbeddedMedia: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventVideosPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="videos" {...props} />,
    title: ({cache}) =>
      `Videos | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventDunkRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        eventImageLinks: {tourId, season, eventId},
        eventArticles: {tourId, season, eventId},
        eventEmbeddedMedia: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventDunkPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="dunk" {...props} />,
    title: ({cache}) =>
      `Dunk | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventShootoutRoute(): RouteFunction {
  return ({tourId, season, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        eventImageLinks: {tourId, season, eventId},
        eventArticles: {tourId, season, eventId},
        eventEmbeddedMedia: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventShootoutPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="shootout" {...props} />,
    title: ({cache}) =>
      `Shoot-Out | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventNewsRoute(): RouteFunction {
  return ({season, tourId, eventId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      newsController({eventNewsPreviews: eventId}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <EventNewsPage {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="news" {...props} />,
    title: ({cache}) =>
      `News | ${mapWithDefaultEmpty<Event>(cache(['events', eventId]), event => event.name)}`,
  });
}

function getEventNewsItemRoute(): RouteFunction {
  return ({season, tourId, eventId, newsId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      newsController({newsId, eventNewsPreviews: eventId}),
      contentController({
        eventConfiguration: {tourId, season, eventId},
        tourConfiguration: {tourId, season},
        eventImageLinks: {tourId, season, eventId},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <NewsArticlePage {...(props as any)} />,
      sidebar: props => <EventNewsSidebar {...(props as any)} />,
      secondarySidebar: () => (
        <EventSidebarSecondary tourId={tourId} season={season} eventId={eventId} />
      ),
    },
    layout: props => <EventLayout eventTab="news" {...props} />,
    title: ({cache}) =>
      `${
        cache(['news', 'full', newsId]) !== undefined ? cache(['news', 'full', newsId]).title : ''
      }`,
    renderMetaTags: renderMetaTagsForNews({newsId, season}),
  });
}

function getEventTeamNewsRoute(): RouteFunction {
  return ({season, tourId, eventId, teamId, newsId}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      teamController(teamId, {newsForEvent: eventId}),
      newsController({newsId}),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
        eventSponsors: {tourId, eventId, season},
      }),
    ],
    components: {
      content: props => <NewsArticlePage {...(props as any)} />,
    },
    layout: props => <EventLayout tourTab="" {...props} />,
    title: ({cache}) =>
      `${cache(['teams', teamId, 'name'])} news | ${mapWithDefaultEmpty<Event>(
        cache(['events', eventId]),
        event => event.name,
      )}`,
  });
}

function getTourStatsSummaryRoute(): RouteFunction {
  return ({tourId, season}) =>
    createTourStatsTabRoute('summary', 'Summary', [tourStatSummaryController(tourId)])({
      tourId,
      season,
    });
}

function getTourStatsTeamsRoute(): RouteFunction {
  return ({tourId, season}) =>
    createTourStatsTabRoute('teams', 'Teams', [tourTeamStatsController({tourId})])({
      tourId,
      season,
    });
}

function getTourStatsPlayersRoute(): RouteFunction {
  return ({tourId, season}) =>
    createTourStatsTabRoute('players', 'Players', [tourPlayerStatsController({tourId})])({
      tourId,
      season,
    });
}

function getTourStatsFastestGamesRoute(): RouteFunction {
  return ({tourId, season}) =>
    createTourStatsTabRoute('fastest-games', 'Fastest games', [
      tourStatsController({season, tourId}),
    ])({
      tourId,
      season,
    });
}

function getTourStatsDownloadsRoute(): RouteFunction {
  return ({tourId, season}) =>
    createTourStatsTabRoute('downloads', 'Downloads', [], {
      tourArticles: {tourId, season},
    })({
      tourId,
      season,
    });
}

function createTourStatsTabRoute(
  secondaryTab,
  title,
  extraControllers = [],
  extraContentFeatures?,
): RouteFunction {
  return ({tourId, season}) => ({
    controllers: [
      tourController(tourId, {events: true, challengers: true, superQuests: true}),
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
        ...extraContentFeatures,
      }),
      ...extraControllers,
    ],
    components: {
      content: props => (
        <TourMacroStatisticsPage
          primaryTab="world-tour"
          secondaryTab={secondaryTab}
          {...(props as any)}
        />
      ),
    },
    layout: props => <TourLayout tourTab="stats" {...props} />,
    title: ({cache}) => `${title} | ${cache(['tours', tourId, 'name'])}`,
  });
}
function getProCircuitTourStatsSummaryRoute(): RouteFunction {
  return ({tourId, season}) =>
    createProCircuitTourStatsTabRoute('summary', 'Summary', [
      proCircuitStatSummaryController(tourId),
    ])({tourId, season});
}

function getProCircuitTourStatsTeamsRoute(): RouteFunction {
  return ({tourId, season}) =>
    createProCircuitTourStatsTabRoute('teams', 'Teams', [
      tourProCircuitTeamStatsController({tourId}),
    ])({
      tourId,
      season,
    });
}

function getProCircuitTourStatsPlayersRoute(): RouteFunction {
  return ({tourId, season}) =>
    createProCircuitTourStatsTabRoute('players', 'Players', [
      tourProCircuitPlayerStatsController({tourId}),
    ])({
      tourId,
      season,
    });
}

function getProCircuitTourStatsDownloadsRoute(): RouteFunction {
  return ({tourId, season}) =>
    createProCircuitTourStatsTabRoute('downloads', 'Downloads', [], {
      tourArticles: {tourId, season},
    })({
      tourId,
      season,
    });
}

function createProCircuitTourStatsTabRoute(
  secondaryTab,
  title,
  extraControllers = [],
  extraContentFeatures?,
): RouteFunction {
  return ({tourId, season}) => ({
    controllers: [
      contentController({
        tourConfiguration: {tourId, season},
        tourSponsors: {tourId, season},
        ...extraContentFeatures,
      }),
      ...extraControllers,
    ],
    components: {
      content: props => (
        <TourMacroStatisticsPage
          primaryTab="pro-circuit"
          secondaryTab={secondaryTab}
          {...(props as any)}
        />
      ),
    },
    layout: props => <TourLayout tourTab="stats" {...props} />,
    title: ({cache}) => `Pro Circuit ${title} | ${cache(['tours', tourId, 'name'])}`,
  });
}

export default routes;

//
// Utils

/**
 * Take a RemoteData.WebData object, and either map to a string on success,
 * or give empty string otherwise.
 *
 * This function is useful for creating titles from cache data.
 *
 * @example
 * {
 *   title: ({cache}) => {
 *     return `Overview ${mapWithDefaultEmpty<Event>(
 *       cache(['events', eventId]),
 *         event => `| ${event.name}`,
 *       )}`;
 *     },
 * }
 *
 */
export function mapWithDefaultEmpty<Data>(
  data: RemoteData.WebData<Data>,
  onSuccess: (data: Data) => string,
): string {
  return RemoteData.match(data, {
    Success: onSuccess,
    default: () => '',
  });
}

export function renderMetaTagsForNews({newsId, season}: {newsId: string; season: string}) {
  return ({cache}: CommonServices) => {
    const newsItem = cache<NewsItem | undefined>(['news', 'full', newsId]);
    const hostname = cache<string | undefined>(['route', 'hostname']);
    if (newsItem === undefined) {
      return [];
    }
    // Construct an twitter summary OG-image compatible URL
    const {src: imageUrl} = getImgixImageProps({
      baseUrl: newsItem.imageBaseUrl,
      width: 2000,
      imgixParams: {},
      autoSrcset: false,
      // The aspect ratio is 2x1 for summary_large_image,
      // so we might as well do the cropping ourselves, and be in control of it
      // @see https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary-card-with-large-image
      aspectRatio: [2, 1],
    });
    // NOTE: Technically, we should be also propagating the protocol, but we don't have it in the Route store.
    // If we wanted to change it, we'd add it there, and get it from the cache here, like we do for hostname.
    const ogUrl = `https://${hostname}/${season}/news/${newsId}`;
    return [
      // NOTE: We set the season-level news as the canonical URL for news articles.
      // We do this becaues a news article can appear under many URLs (season, event, team), but the season one is always present. This helps consolidate the scraping
      // og:url - The canonical URL of your object that will be used as its permanent ID in the graph, e.g., "http://www.imdb.com/title/tt0117500/".
      MetaTags.OgTag('og:url', ogUrl),
      MetaTags.OgTag('og:type', 'article'),
      MetaTags.OgTag('og:title', newsItem.title),
      MetaTags.OgTag('og:description', newsItem.contentAbstract),
      // Facebook recommends specifying the dimensions, to enable better fetching
      // https://developers.facebook.com/docs/sharing/best-practices/#precaching
      MetaTags.OgTag('og:image', imageUrl),
      // TODO: Get these from getImgixImageProps :)
      MetaTags.OgTag('og:image:width', '2000'),
      MetaTags.OgTag('og:image:height', '1000'),
      MetaTags.GenericTag('twitter:card', 'summary_large_image'),
    ];
  };
}
