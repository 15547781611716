import React from 'react';
import {P} from 'fiba/wt/ui/text/Text';
import {getLocalDateFromString, getLocalTimeFromString} from 'fiba/common/utils/schedule';

export default function StatsSummaryFooter({updatedAt, localizer}) {
  return (
    <>
      <P className="mt4 ml2">
        *Qualifying Draw and Play-in Games are not included in the stats analysis. <br></br>
        All player tour stats are cumulated while all team tour stats are calculated per game
        (except most buzzer beaters).
      </P>
      {!!updatedAt && (
        <div className="f7 fw4 mb1 mt2">
          Updated at: {getLocalDateFromString(updatedAt, localizer)},{' '}
          {getLocalTimeFromString(updatedAt, localizer)} (UTC)
        </div>
      )}
    </>
  );
}
