import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {TourTeam} from 'fiba/common/core/models/api/tour/TourTeam';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Link} from 'fiba/wt/ui/link/Link';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {getTourTeams} from 'fiba/wt/stores/tourStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {RootState} from 'fiba/wt/stores/rootStore';
import {tourTeamPlayerLinkProvider} from 'fiba/wt/utils/linkUtils';
import {TeamList} from 'fiba/common/ui/teamList/TeamList';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';

interface OwnProps {
  season: string;
  tourId: string;
}

interface ReduxProps {
  teams: List<TourTeam>;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state: RootState, {tourId}: OwnProps): ReduxProps => {
  return {
    teams: getTourTeams(tourId, state.get('tours'), state.get('tourTeams'))
      .sortBy(team => team.name)
      .toList(),
    metaPaths: [`tours/__meta/${tourId}`, `tours/__meta/${tourId}/tourTeams`],
  };
};

const TourTeamsTableImpl: React.FC<Props> = ({season, teams, metaPaths}) => {
  const TeamTitle = ({team}: {team: TourTeam}) => (
    <SubHeading fontSize={['6', '5']}>
      <Link href={`/${season}/teams/${team.id}`}>
        <TeamNameAssembly team={team} isFlagVisible isSuffixVisible />
      </Link>
    </SubHeading>
  );

  return (
    <Loading metaPaths={metaPaths}>
      {() =>
        teams.size > 0 ? (
          <CardContainer pa="0" debugClassName="TourTeamsTable">
            <TeamList
              getPlayerLink={(teamId, playerId) =>
                tourTeamPlayerLinkProvider(season, teamId)(playerId)
              }
              teamTitle={TeamTitle}
              teams={teams}
            />
          </CardContainer>
        ) : (
          <p className="tc pa2">There are no teams yet.</p>
        )
      }
    </Loading>
  );
};

export const TourTeamsTable = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  TourTeamsTableImpl,
);
