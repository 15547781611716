import {Map, Record} from 'immutable';
import * as Anomaly from './Anomaly';

export {
  RemoteData,
  WebData,
  NotAsked,
  Loading,
  Failure,
  Success,
  match,
  map,
  map2,
  map3,
  map4,
  andMap,
  fromMetaObject,
  isSuccess,
  isFailure,
  isLoading,
  isNotAsked,
  toImmutableRecord,
};

/**
 * Module and data type that makes handling remote data sources
 * safe and predictable.
 *
 * The RemoteData type is a union type, with discrete states for:
 *  - We have not asked
 *  - We are loading
 *  - There was an error
 *  - We got the result
 *
 * This can be useful for cases where we need to handle error/loading states,
 * and would otherwise forget. The compiler will catch these for us!
 *
 * The main things to know are the `match` function, which allows pattern-matching
 * syntax to be used. You use this to access the data, and the compiler will force exhaustive
 * checks of all cases.
 *
 * The rest of the module is utilities for making life easier. For example:
 * - transforming the success value of a RemoteData (`map`)
 * - joining together two RemoteData sources (`map2`)
 * - creating RemoteData from a naive meta object ({isSuccess, isError, isLoading}),
 *   which avoids impossible states
 *
 * References:
 * - Inspired by Kris Jenkins' RemoteData package in Elm:
 *   https://package.elm-lang.org/packages/krisajenkins/remotedata/latest/RemoteData
 * - Their talk on "Slaying a UI antipattern" is a good illustration of the concepts:
 *   https://www.youtube.com/watch?v=NLcRzOyrH08
 *
 * Usage:
 * It is recommended that you `import * as RemoteData` to use this module, as it makes
 * it easier to track down where imports come from.
 *
 * @example
 * import * as RemoteData from 'fiba/wt/utils/RemoteData'
 *
 * function mapStateToProps(state, props) {
 *  // Create a RemoteData type
 *  return {
 *    data: RemoteData.fromMeta(state.getIn(['__meta', 'events', eventId]))
 *  }
 * }
 *
 * function EventListImpl({data}) {
 *  // Pattern match on the data
 *  return RemoteData.match(data, {
 *    NotAsked: () => <>Initialising</>,
 *    Loading: () => <>Loading...</>,
 *    Failure: (err) => <>There was an error: {err}</>,
 *    // You could probably split this out to a new function, that only needs concrete Event[]
 *    Success: (events) => <ul>{events.map(event => <li key={event.id}>{event.shortname}</li>)}</ul>
 *  })
 * }
 *
 * export const EventList = connect(mapStateToProps)(EventListImpl)
 *
 *
 */

// The main type
type RemoteData<Data, Error> = NotAsked | Loading | Failure<Error> | Success<Data>;

type NotAsked = {$: 'NotAsked'};
type Loading = {$: 'Loading'};
type Success<S> = {$: 'Success'; data: S};
type Failure<E> = {$: 'Failure'; err: E};

// Plain JS Type constructors
// const NotAsked = <Success = any, Error = any>(): RemoteData<Success, Error> => ({$: 'NotAsked'});
// const Loading = <Success = any, Error = any>(): RemoteData<Success, Error> => ({$: 'Loading'});
// const Failure = <Error, Success = any>(err: Error): RemoteData<Success, Error> => ({
//   $: 'Failure',
//   err: err,
// });
// const Success = <Success, Error = any>(data: Success): RemoteData<Success, Error> => ({
//   $: 'Success',
//   data: data,
// });

//
// ImmutableJS Type constructors
// Records designed to work with ImmutableJS
// These are really, really ugly, and kind of annoying.
// But, while we use ImmutableJS, we have to roll with it somehow.
// Note tehat we do not mark them as Record<> or anything of that sort.
// We are OK with them being treated as barebones / non-ImmutableJS collections
// for the purposes of typechecking.
const _NotAskedImmutable = Record({$: 'NotAsked'});
const _LoadingImmutable = Record({$: 'Loading'});
const _FailureImmutable = Record({$: 'Failure', err: null});
const _SuccessImmutable = Record({$: 'Success', data: null});

const NotAsked = <Success = any, Error = any>(): RemoteData<Success, Error> => {
  return new _NotAskedImmutable() as any;
};
const Loading = <Success = any, Error = any>(): RemoteData<Success, Error> => {
  return new _LoadingImmutable() as any;
};
const Failure = <Error, Success = any>(err: Error): RemoteData<Success, Error> => {
  return new _FailureImmutable({
    err: err,
  }) as any;
};
const Success = <Success, Error = any>(data: Success): RemoteData<Success, Error> => {
  return new _SuccessImmutable({
    data: data,
  }) as any;
};

//
// Helper type
/**
 * In HTTP Services, we can have a helper type where the error is already composed.
 * We do this to enable a common layer for errors.
 *
 * You should create RemoteData/WebData either directly, or using the `fromWebResource` function.
 */
type WebData<Data> = RemoteData<Data, Anomaly.Anomaly>;

// TODO: Consider `fromWebResource` function

//
// MATCHING

type MatchObj<Success, Error, Return> = {
  NotAsked: () => Return;
  Loading: () => Return;
  Failure: (err: Error) => Return;
  Success: (data: Success) => Return;
};

// A match case is either exhaustive, or partial with a 'default'
type MatchCases<Success, Error, Return> =
  | (MatchObj<Success, Error, Return> & NoDefaultProp)
  | (Partial<MatchObj<Success, Error, Return>> & {
      default: () => Return;
    });

// Forbid usage of default property; reserved for pattern matching.
interface NoDefaultProp {
  default?: never;
}

/**
 * Enables "unwrapping" the cases of RemoteData into another type
 * This is the bread and butter of the module.
 * For example, transforming RemoteData to JSX/HTML/React Elements
 * Note that the Return type must be the same for all branches, per matching rules.
 * This can be circumvented in TS/JS if you try, but it is not recommended :)
 */
function match<Success, Error, Return>(
  remoteData: RemoteData<Success, Error>,
  matchObj: MatchCases<Success, Error, Return>,
) {
  const {Loading, NotAsked, Failure, Success, default: def} = matchObj;
  // Call the handler with the appropriate data
  switch (remoteData.$) {
    case 'Loading':
      return Loading ? Loading() : def();
    case 'NotAsked':
      return NotAsked ? NotAsked() : def();
    case 'Failure':
      return Failure ? Failure(remoteData.err) : def();
    case 'Success':
      return Success ? Success(remoteData.data) : def();
    default:
      return assertNever(remoteData);
  }
}

//
// HELPERS

/**
 * Transform a RemoteData<A, E> to RemoteData<B, E>, by providing A -> B
 * This is useful if you want to transform from one success type to another,
 * for example when deriving data.
 */
function map<A, E, B>(data: RemoteData<A, E>, f: (success: A) => B): RemoteData<B, E> {
  return match(data, {
    Success: value => Success(f(value)),
    Loading: Loading,
    NotAsked: NotAsked,
    Failure: err => Failure(err),
  }) as RemoteData<B, E>;
}

/**
 * Combine two remote data sources with the given function. The
 * result will succeed when (and if) both sources succeed.
 */
function map2<E, A, B, C>(
  dataA: RemoteData<A, E>,
  dataB: RemoteData<B, E>,
  f: (a: A, b: B) => C,
): RemoteData<C, E> {
  // If this looks odd, think of PacMan chomping each argument in order
  // Alternatively, look at the pipeline version
  // https://github.com/krisajenkins/remotedata/blob/6.0.1/src/RemoteData.elm#L188
  // Anyway, this takes care of the nitty-gritty, so we don't have to :)
  return andMap(
    dataB,
    map(dataA, (a: A) => (b: B) => f(a, b)),
  );
}

/**
 * Combine three remote data sources with the given function. The
 * result will succeed when (and if) all three sources succeed.
 * If you need `map4`, `map5`, etc, see the documentation for `andMap`.
 */
function map3<E, A, B, C, D>(
  dataA: RemoteData<A, E>,
  dataB: RemoteData<B, E>,
  dataC: RemoteData<C, E>,
  f: (a: A, b: B, c: C) => D,
): RemoteData<D, E> {
  return andMap(
    dataC,
    andMap(
      dataB,
      map(dataA, (a: A) => (b: B) => (c: C) => f(a, b, c)),
    ),
  );
}

/**
 * Combine three remote data sources with the given function. The
 * result will succeed when (and if) all three sources succeed.
 * If you need `map4`, `map5`, etc, see the documentation for `andMap`.
 */
function map4<E, A, B, C, D, F>(
  dataA: RemoteData<A, E>,
  dataB: RemoteData<B, E>,
  dataC: RemoteData<C, E>,
  dataD: RemoteData<D, E>,
  f: (a: A, b: B, c: C, d: D) => F,
): RemoteData<F, E> {
  return andMap(
    dataD,
    andMap(
      dataC,
      andMap(
        dataB,
        map(dataA, (a: A) => (b: B) => (c: C) => (d: D) => f(a, b, c, d)),
      ),
    ),
  );
}

/**
 * Put the results of two RemoteData calls together.
 * For example, if you were fetching three datasets, `a`, `b` and `c`,
 * and wanted to end up with a tuple of all three.
 *
 * The final tuple succeeds only if all its children succeeded. It is
 * still `Loading` if _any_ of its children are still `Loading`. And if
 * any child fails, the error is the leftmost `Failure` value.
 *
 * This is used to create `map2`, `map2`, ... `mapN` functions
 */
function andMap<A, E, B>(
  wrappedValue: RemoteData<A, E>,
  wrappedFunction: RemoteData<(a: A) => B, E>,
): RemoteData<B, E> {
  // Ideally, we'd write this with paired matches (think case (wrappedFunction, wrappedValue))
  // In the absence of that, we unwrap early, because it makes the cases easier to follow
  if (isSuccess(wrappedFunction) && isSuccess(wrappedValue)) {
    return Success(wrappedFunction.data(wrappedValue.data));
  }

  if (isFailure(wrappedFunction)) {
    return Failure(wrappedFunction.err);
  }

  if (isFailure(wrappedValue)) {
    return Failure(wrappedValue.err);
  }

  if (isLoading(wrappedFunction) || isLoading(wrappedValue)) {
    return Loading();
  }

  if (isNotAsked(wrappedFunction) || isNotAsked(wrappedValue)) {
    return NotAsked();
  }
}

//
// State checking predicates

function isSuccess<A, E>(data: RemoteData<A, E>): data is Success<A> {
  return match(data, {
    Success: () => true,
    default: () => false,
  });
}

function isFailure<A, E>(data: RemoteData<A, E>): data is Failure<E> {
  return match(data, {
    Failure: () => true,
    default: () => false,
  });
}

function isLoading<A, E>(data: RemoteData<A, E>): data is Loading {
  return match(data, {
    Loading: () => true,
    default: () => false,
  });
}

function isNotAsked<A, E>(data: RemoteData<A, E>): data is NotAsked {
  return match(data, {
    NotAsked: () => true,
    default: () => false,
  });
}

//
// FROM META

type MetaObject = {
  isLoading: boolean;
  isLoaded: boolean;
  isError: boolean;
};

/**
 * Transform a naive meta object to RemoteData.
 * This is an attempt to bridge the existing architecture (where data is separate from loading),
 * with the integrated "Loading states as data" approach of RemoteData.
 *
 * TODO: In the future, consider a fromMetaIntegrated that requires callback getters for getSuccess/getError
 *
 * @example
 * // First, create an "empty" RemoteData
 * const myData = RemoteData.fromMetaObject(
 *  state.getIn(
 *    ['tours', '__meta', tourId, 'events'],
 *  ),
 * );
 *
 * // Use RemoteData.map to transform the success value
 * // RemoteData<{}, {}> -> RemoteData<Event, {}>
 * const myDataWithStuff = RemoteData.map(myData, () => state.getIn(['tours', tourId, 'events']))
 */
function fromMetaObject(metaObj: MetaObject): RemoteData<{}, {}> {
  if (process.env.NODE_ENV !== 'production') {
    // This has bit us a few times in the past
    // We don't want to call toJS here (because of more coupling), but it
    // has been the case that we forget to call it at the call site!
    if (metaObj instanceof Map) {
      // eslint-disable-next-line no-console
      console.warn(
        'You gave a Map to RemoteData.fromMetaObject. This will fail silently as NotAsked! Consider calling .toJS() at the call site to fix this.',
      );
    }
  }

  const {isLoading, isLoaded, isError} = metaObj;

  // Error takes precedence
  if (isError) {
    return Failure({});
  }

  // Success after error
  if (isLoaded) {
    return Success({});
  }

  // Loading after 'data' cases
  if (isLoading) {
    return Loading();
  }

  // None defined: we haven't asked
  return NotAsked();
}

//
// UTIL

/**
 * Helper to enable exhaustive checks with the compiler's help
 * @see https://www.typescriptlang.org/docs/handbook/advanced-types.html#exhaustiveness-checking
 */
function assertNever(x: never): never {
  throw new Error('Unexpected object: ' + x);
}

//
// ImmutableJS conversion

/**
 * Convert from plain JS to Immutable Record.
 * This is done for compatibility with our Immutable JS setup.
 * In the future, we might consider changing the internal representation
 * to plain JS, and removing this function altogether.
 *
 * This is similar to a map() on both success and failure, but with
 * the added property of changing the internal representation.
 */
function toImmutableRecord<DataIn, DataOut, ErrorIn, ErrorOut>(
  data: RemoteData<DataIn, ErrorIn>,
  failureToImmutable: (err: ErrorIn) => ErrorOut,
  successToImmutable: (data: DataIn) => DataOut,
): RemoteData<DataOut, ErrorOut> {
  return match(data, {
    NotAsked: () => NotAsked(),
    Loading: () => Loading(),
    Failure: err => Failure(failureToImmutable(err)),
    Success: ok => Success(successToImmutable(ok)),
  });
}
