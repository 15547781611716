import {QualificationPath} from 'fiba/common/core/models/api/qualifications/Qualifications';

type QualificationType = QualificationPath['type'];
export const getHumanQualificationType = (qualificationType: QualificationType): string => {
  switch (qualificationType) {
    case 'WildCardHost':
      return 'Wildcard Host';
    case 'WildCardFiba':
      return 'Wildcard FIBA';
    case 'HardSeed':
      return 'Ranking (Hard Seed)';
    case 'AutomaticAllocation':
      return 'Ranking (Automatic Allocation)';
    case 'SeasonStanding':
      return 'Season Standing';
    case 'WorldTourMaster':
      return 'World Tour Master';
    case 'LiteQuest':
      return 'Lite Quest';
    case 'PerformanceSlot':
      return 'Performance Slot';
    case 'Challenger':
      return 'Challenger';
    case 'Quest':
      return 'Quest';
    case 'SuperQuest':
      return 'Super Quest';
    case 'WildCardCrossHost':
      return 'Wildcard X-Host';
    case 'Standby':
      return 'Standby';
    case 'Replacement':
      return 'Replacement';
    case 'WomenSeriesQualifier':
      return "Women's Series Qualifier";
    case 'ToBeAnnounced':
      // Intended to be an empty string.
      return '';
    default:
      // NOTE: One could either return the enum string in `qualificationType`
      // or show empty string in case we have unknown qualification type.
      // Let's opt in for the empty string so we don't show machine readable
      // enums that might look like the ones above (`WildCardLocalOrganizingCommittee`)
      return '';
  }
};
