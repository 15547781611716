import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {_TeamPlayers, ITeamPlayersRedux} from 'fiba/wt/ui/teamPlayers/TeamPlayers';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';

interface ITourTeamPlayers {
  season: string;
  tourId: string;
  teamId: string;
  playerLinkProvider: ResourceLinkProvider;
}

// NOTE: Doing more than just mapping state to props; we map one set of props to another.
const mapStateToProps = (state, {season, tourId, teamId}: ITourTeamPlayers): ITeamPlayersRedux => ({
  players: state.getIn(['tourTeams', teamId, 'teamMembers'], List()),
  // NOTE: This assumes we are loading teams through loadTeamsByTour instead of singular
  // loadTourTeam which we don't yet have
  metaPaths: [`tours/__meta/${tourId}/tourTeams`],
});

export const TourTeamPlayers = connect<ITeamPlayersRedux, {}, ITourTeamPlayers>(mapStateToProps)(
  // NOTE: Cast to any due to `_TeamPlayers` having different external interface than
  // `TourTeamPlayers`. The differing property is `tourId` which is only used in
  // `mapStateToProps` in this module.
  _TeamPlayers as any,
);
