import React, {useContext} from 'react';
import cx from 'classnames';
import {assetPath, connect} from 'fiba/common/utils/reactUtils';
import {BoxProvider, Box} from 'fiba/wt/ui/box/Box';
import {Flex, FlexProvider} from 'fiba/wt/ui/flex/Flex';
import {SpacerProvider} from 'fiba/wt/ui/spacer/Spacer';
import {Link} from 'fiba/wt/ui/link/Link';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {SiteConfigContext, ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {List} from 'immutable';
import {values} from 'lodash';

interface ISocialMediaIcon {
  name: string;
  image: string;
  href: string;
}

interface ISponsor {
  name: string;
  image: string;
  href: string;
  isDangerousWideLogoHack?: boolean;
}

export interface ISponsorSection {
  title: string;
  sponsors: ISponsor[];
  extraClassName?: string;
}

interface StateProps {
  tourSponsorSections: ISponsorSection[];
  eventSponsorSections: ISponsorSection[];
  conferenceSponsorSections: ISponsorSection[];
  seasonSponsorSections: ISponsorSection[];
  path: string;
}

interface OwnProps {
  tourId: string;
  season: string;
  conferenceId?: string;
  eventId?: string;
}

const TourLayoutFooterComponent = ({
  tourSponsorSections,
  eventSponsorSections,
  conferenceSponsorSections,
  seasonSponsorSections,
  eventId,
  season,
  path,
}: StateProps & OwnProps) => {
  const {theme, seasonConfig} = useContext(SiteConfigContext);

  const sponsorSections = getSponsorSections({
    eventId,
    tourSponsorSections,
    eventSponsorSections,
    conferenceSponsorSections,
    seasonSponsorSections,
    path,
    season,
    seasonConfig,
  });

  return (
    <BoxProvider
      width="100"
      color="silver-10"
      bgColor={theme.footerColors.bgColor}
      debugClassName="TourSiteFooter"
    >
      {styleProps => (
        <footer {...styleProps}>
          {/* Top logo and social media icon area */}
          {/* Note the overall padding 3 is distributed*/}
          <Flex borderColor="dark-40" justifyContent="between" border="bottom" pa="3">
            <Box pa="2" flexShrink="0">
              <Link href="https://www.fiba3x3.com" isExternal={true}>
                <img
                  className="v-mid"
                  src={assetPath('img/logo-3x3-white.svg')}
                  alt="3x3"
                  width="60"
                  height="30"
                />
              </Link>
            </Box>

            <FlexProvider pa="1" flexWrap="wrap" justifyContent="end" alignContent="center">
              {styleProps => (
                <ul {...styleProps}>
                  <SocialMediaIcon
                    name="Facebook"
                    image="facebook"
                    href="https://facebook.com/fiba3x3"
                  />
                  <SocialMediaIcon
                    name="Twitter"
                    image="twitter"
                    href="https://twitter.com/fiba3x3"
                  />
                  <SocialMediaIcon
                    name="Instagram"
                    image="instagram"
                    href="https://instagram.com/fiba3x3"
                  />
                  <SocialMediaIcon
                    name="Youtube"
                    image="youtube"
                    href="https://youtube.com/fiba3x3"
                  />
                  <SocialMediaIcon
                    name="Youku"
                    image="youku"
                    href="http://i.youku.com/fiba3x3official"
                  />
                  <SocialMediaIcon
                    name="Tencent"
                    image="tencent"
                    href="http://v.qq.com/vplus/fiba3x3"
                  />
                  <SocialMediaIcon
                    name="Weibo"
                    image="weibo"
                    href="http://www.weibo.com/fiba3x3official"
                  />
                  <SocialMediaIcon name="Twitch" image="twitch" href="http://www.twitch.com/fiba" />
                </ul>
              )}
            </FlexProvider>
          </Flex>

          {/* Sitemap would be here */}

          {/* Sponsors and sitemap */}
          <Flex
            pb="3"
            justifyContent="between"
            flexDirection={['column', 'column', 'column', 'row']}
          >
            {sponsorSections ? (
              <FlexProvider ph="2" pt="3" flexWrap="wrap" justifyContent="center">
                {styleProps => (
                  <ul {...styleProps}>
                    {sponsorSections.map(section => (
                      <SponsorSection
                        title={section.title}
                        sponsors={section.sponsors}
                        extraClassName="w-100 w-auto-m"
                        key={section.title}
                      />
                    ))}
                  </ul>
                )}
              </FlexProvider>
            ) : null}

            {/* TODO: Give this line-height a sensible name and add to light-height atomics */}
            <SpacerProvider vSpace="2">
              {({className}) => (
                <ul className={cx('pa3 tc tr-l united ttu', className)}>
                  <li>
                    <Link colorTheme="light" href="http://help.fiba3x3.com" isExternal={true}>
                      Contact Support
                    </Link>
                  </li>
                  <li>
                    <Link
                      colorTheme="light"
                      href="https://fiba3x3.com/en/terms-and-conditions.html"
                      isExternal={true}
                    >
                      Terms and Conditions
                    </Link>
                  </li>
                  <li>
                    <Link
                      colorTheme="light"
                      href="https://fiba3x3.com/en/privacy-policy.html"
                      isExternal={true}
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              )}
            </SpacerProvider>
          </Flex>

          {/* Organization logo and copyright */}
          <Box border="top" borderWidth="1" borderColor={theme.footerColors.borderColor}>
            <Flex flexWrap="wrap" pa="2" alignItems="center">
              <Box ma="2">
                <Link href="https://www.fiba.basketball" isExternal={true}>
                  <img src={assetPath('img/fiba-logo.svg')} alt="FIBA" width="65" height="32" />
                </Link>
              </Box>

              <Box ma="2">
                <p className="f7 lh-copy silver-30 hyphens-auto" style={{maxWidth: '200ch'}}>
                  Copyright FIBA. All rights reserved. The 3x3 marks (including but not limited to
                  the FIBA 3x3 Endorsement Stamp, the FIBA 3x3 Infinity Logo and the 3x3 word mark)
                  are FIBA intellectual property, protected by trade mark and/or copyright laws
                  around the world. All use of the 3x3 marks must be with FIBA’s consent and under
                  FIBA’s control.
                </p>
              </Box>
            </Flex>
          </Box>
        </footer>
      )}
    </BoxProvider>
  );
};

const isEventOfType = (
  eventId: string,
  path: string,
  season: string,
  seasonConfig: ISeasonConfig,
  type: 'challengers' | 'superQuests',
) => {
  /*
   * The page is a challenger/superquest page if
   * 1) The path includes the word  (like /2018/challengers/)
   * 2) The eventId is included in challengers/superQuests list in seasonConfig
   *
   */

  const {seasons} = seasonConfig;
  const eventIdsForType = seasons[season] ? values(seasons[season][type]) : [];

  if (path.includes(type.toLowerCase())) {
    return true;
  } else if (eventId && eventIdsForType.includes(eventId)) {
    return true;
  } else {
    return false;
  }
};

const getSponsorSections = ({
  eventId,
  tourSponsorSections,
  eventSponsorSections,
  conferenceSponsorSections,
  seasonSponsorSections,
  path,
  season,
  seasonConfig,
}): List<ISponsorSection> => {
  let sponsorData: List<ISponsorSection> = List();

  const isSpecialEvent =
    isEventOfType(eventId, path, season, seasonConfig, 'challengers') ||
    isEventOfType(eventId, path, season, seasonConfig, 'superQuests');

  if (eventSponsorSections !== undefined && eventSponsorSections.size > 0) {
    sponsorData = eventSponsorSections;
  } else if (tourSponsorSections !== undefined && isSpecialEvent) {
    sponsorData = tourSponsorSections.filter(sponsors => sponsors.getIn(['fields', 'eventType']));
  } else if (tourSponsorSections !== undefined) {
    sponsorData = tourSponsorSections.filter(sponsors => !sponsors.getIn(['fields', 'eventType']));
  } else if (conferenceSponsorSections !== undefined && conferenceSponsorSections.size > 0) {
    sponsorData = conferenceSponsorSections;
  } else if (seasonSponsorSections !== undefined && seasonSponsorSections.size > 0) {
    sponsorData = seasonSponsorSections;
  }

  const sponsorItems = sponsorData.getIn([0, 'fields', 'sponsorItems'], List());

  return sponsorItems.map(item => ({
    title: item.getIn(['fields', 'sectionTitle']),
    sponsors: item.getIn(['fields', 'logos'], List()).map(logo => ({
      name: logo.getIn(['fields', 'sponsorName']),
      image: logo.getIn(['fields', 'image', 'fields', 'file', 'url']),
      href: logo.getIn(['fields', 'url']),
      isDangerousWideLogoHack: logo.getIn(['fields', 'dangerousWideLogoHack']),
    })),
  }));
};

const SponsorSection: React.FunctionComponent<ISponsorSection> = ({
  title,
  sponsors,
  extraClassName,
}) => (
  <li className={cx('pa2 tc', extraClassName)}>
    <Heading pb="3" fontSize="6" fontFamily="united" textTransform="uppercase">
      {title}
    </Heading>
    <ul>
      {sponsors.map(sponsor => (
        <li key={sponsor.name} className="mh2 dib">
          <Link href={sponsor.href} isExternal={true}>
            <img
              width={sponsor.isDangerousWideLogoHack ? '148' : '100'}
              height="48"
              alt={sponsor.name}
              src={sponsor.image}
            />
          </Link>
        </li>
      ))}
    </ul>
  </li>
);

const SocialMediaIcon: React.FunctionComponent<ISocialMediaIcon> = ({name, image, href}) => (
  <li className="dib pt2 pl1">
    <Link href={href} isExternal={true}>
      <img
        className="db pa1"
        width="24"
        height="24"
        alt={name}
        src={assetPath(`img/social/${image}.svg`)}
      />
    </Link>
  </li>
);

const mapStateToProps = (state, {tourId, eventId, conferenceId, season}: OwnProps) => {
  return {
    tourSponsorSections: state.getIn(['content', 'footerSponsorsSection', 'tours', tourId]),
    eventSponsorSections: state.getIn(['content', 'footerSponsorsSection', 'events', eventId]),
    conferenceSponsorSections: state.getIn([
      'content',
      'footerSponsorsSection',
      'conferences',
      conferenceId,
    ]),
    seasonSponsorSections: state.getIn(['content', 'footerSponsorsSection', 'seasons', season]),
    path: state.getIn(['route', 'pathname']),
  };
};

export const TourLayoutFooter = connect<StateProps, {}, OwnProps>(
  mapStateToProps,
  null,
)(TourLayoutFooterComponent);
