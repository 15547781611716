import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {TourTeamsTable} from 'fiba/wt/ui/tourTeams/TourTeamsTable';
import {Tabs, Tab} from 'fiba/wt/ui/tabs/Tabs';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {TourTeamTop10Ranking} from 'fiba/wt/ui/tourTeamTop10Ranking/TourTeamTop10Ranking';
import {
  TourProgrammabilityState,
  getTourProgrammabilityState,
} from 'fiba/wt/ui/tourProgrammability/TourProgrammabilityStateProvider';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {ContentTypes, getEventConfigurationForTour} from 'fiba/wt/stores/contentStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {connect} from 'fiba/common/utils/reactUtils';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {RootState} from 'fiba/wt/stores/rootStore';

export interface OwnProps {
  tourId: string;
  season: string;
  teamTab: TourTeamsPageTab;
}

interface ReduxProps {
  metaPaths: string[];
  eventConfiguration?: EventConfiguration[];
  overrideNow?: string;
  events: RootState['events'];
}

type Props = OwnProps & ReduxProps;

// Temporary switch to hide 10, see https://github.com/futurice/fiba-3x3-play/issues/2597

export type TourTeamsPageTab = 'root' | 'all' | 'topten';

const mapStateToProps = (state, {tourId}: Props): ReduxProps => ({
  events: state.getIn(['events']),
  eventConfiguration: getEventConfigurationForTour(state, tourId),
  metaPaths: [`content/__meta/${ContentTypes.EventConfigurator}/tourEvents/${tourId}`],
  // Yay, more internal secret query params \_(-_-)_/
  overrideNow: state.getIn(['route', 'payload', '_now']),
});

const TourTeamsPageImpl: React.FC<Props> = props => {
  const {tourId, season, teamTab, eventConfiguration, metaPaths, overrideNow, events} = props;
  const {state: tourState} = getTourProgrammabilityState(eventConfiguration, events, overrideNow);

  const headingId = 'tour-teams-heading';

  return (
    <Spacer vSpace="3">
      <VisuallyHidden>
        <Heading id={headingId}>World Tour Teams</Heading>
      </VisuallyHidden>
      <Loading metaPaths={metaPaths}>
        {() => (
          <Box width="100" ph={['0', '2', '0']}>
            <Tabs
              navigationLabeledBy={headingId}
              baseUrl={`/${season}/teams/world-tour`}
              baseUrlAlias={`/${season}/teams`}
              level="nested"
              extraUlClassName="justify-center pt3"
            >
              {/* Only show "top 10" ranking in current season.
               * The rankings are "running" and not historical, so we can't show them
               * for past seasons.
               * @see https://github.com/futurice/fiba-3x3-play/issues/1709
               */}
              {isCurrentSeason(tourState) && (
                <>
                  <Tab extraLiClassName="mw5" href="/" hrefAliases={['/topten']}>
                    Top 10 Ranked Teams
                  </Tab>
                  <Tab extraLiClassName="mw5" href="/all">
                    All Teams
                  </Tab>
                </>
              )}
            </Tabs>

            <Box pt="4" pb={['4', '4', '0']} debugClassName="TourTeamsPage-Tab">
              <Section>{getTab(teamTab, {tourId, season, tourState})}</Section>
            </Box>
          </Box>
        )}
      </Loading>
    </Spacer>
  );
};

export const TourTeamsPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(TourTeamsPageImpl);

//
// UTILS

function getTab(
  tab: TourTeamsPageTab,
  {
    tourId,
    season,
    tourState,
  }: {tourId: string; season: string; tourState: TourProgrammabilityState},
) {
  switch (tab) {
    // All teams
    case 'all':
      return <TourTeamsTable tourId={tourId} season={season} />;
    // Top ten, by current live ranking
    case 'topten':
      return isCurrentSeason(tourState) ? (
        <TourTeamTop10Ranking tourId={tourId} season={season} />
      ) : null;
    case 'root':
      return isCurrentSeason(tourState) ? (
        <TourTeamTop10Ranking tourId={tourId} season={season} />
      ) : (
        <TourTeamsTable tourId={tourId} season={season} />
      );
    default:
      return null;
  }
}

function isCurrentSeason(tourState: TourProgrammabilityState) {
  return tourState !== 'none' && tourState !== 'before-season' && tourState !== 'after-season';
}
