import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {
  StandingsTableColumns,
  CategoryStandings,
} from 'fiba/wt/ui/categoryStandings/CategoryStandings';
import Category from 'fiba/common/core/models/api/events/Category';
import {eventTeamLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';

interface OwnProps {
  season: string;
  eventId: string;
  columns?: StandingsTableColumns[];
  cellSpacing?: StyleProp<'pv'>;
  hideHeading?: boolean;
  categoryIdFilter?: List<string>;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    categories: List<Category>;
    eventType: EventType;
  }>;
}

export const mapStateToProps = (state, {eventId, categoryIdFilter}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);

  // NOTE: We assume that if events/__meta/eventId/categories is loaded,
  // then each category is loaded as well.
  // This is ok because we know the store works like that, but we should
  // use RemoteData once categoryStore is RemoteData- based as well
  const categoriesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds
        .filter(categoryId => !categoryIdFilter || categoryIdFilter.includes(categoryId))
        .map((categoryId: string) => state.getIn(['categories', categoryId]))
        .filter((category: Category) => !!category)
        .toList(),
  );

  // Merge the data so they either succeed or fail together
  const data = RemoteData.map2(eventTypeData, categoriesData, (eventType, categories) => ({
    eventType,
    categories,
  }));

  return {data};
};

type Props = OwnProps & ReduxProps;

const _EventStandings: React.FC<Props> = ({season, eventId, data, columns, cellSpacing}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <Spacer vSpace="3" debugClassName="EventStandings">
      <VisuallyHidden>
        <Heading>Final standings</Heading>
      </VisuallyHidden>

      <ViewWebData data={data}>
        {({categories, eventType}) => (
          <>
            {categories.map(category => (
              <Box key={category.id}>
                <CategoryStandings
                  categoryId={category.id}
                  columns={columns}
                  showCategoryName={categories.size > 1}
                  createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
                  cellSpacing={cellSpacing}
                />
              </Box>
            ))}
          </>
        )}
      </ViewWebData>
    </Spacer>
  );
};

export const EventStandings = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventStandings);
