import {fromJS} from 'immutable';
import FibaError, {createMessage, createFieldValidationError} from 'fiba/common/core/fibaError';

// FIXME: Response might be a system error in which case error looks totally different:
// {
//    [Error: getaddrinfo ENOENT localhost:3000]
//    code: 'ENOENT',
//    errno: 'ENOENT',
//    syscall: 'getaddrinfo',
//    hostname: 'localhost',
//    host: 'localhost',
//    port: '3000'
// }

// This whole thing is weirdly out of sync with the error response
// sent by app-backend
// TODO: Go over the whole error handling + logging
const createFibaError = data => {
  if (data.error) {
    if (data.validationErrors && data.validationErrors.length) {
      return createFieldValidationError(data.validationErrors);
    } else {
      const message = data.message || '';
      const error = data.error || '';
      return new FibaError(
        fromJS({
          // Remap HTTP error objects in API error objects
          ...data,
          // Sentry will use the error message for error grouping so more explicit the better
          // Also shown for the user by `notificationActions.addNotificationFromError()`
          message: `${message} (${error})`,
          // TODO: Used for what?
          error: error,
          // TODO: Used for what?
          errorCode: error,
          // TODO: Used for what?
          errorMessage: message,
          success: false,
        }),
      );
    }
  } else {
    // Error without payload (e.g. connect timeout)
    return createMessage(`Application error (${data})`);
  }
};

export default createFibaError;
