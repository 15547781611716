import React from 'react';
import cx from 'classnames';
import {List} from 'immutable';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {NewsHighlightItem} from 'fiba/wt/ui/newsHighlight/NewsHighlightItem';
import {Box} from 'fiba/wt/ui/box/Box';

interface INewsHighlight {
  news: List<NewsItem>;
  baseUrl: string;
  largeItemSizes: string;
  smallItemSizes: string;
}

export const NewsHighlight: React.FunctionComponent<INewsHighlight> = ({news, ...rest}) => {
  const hasSecondItem = news.size > 1;
  const hasThirdItem = news.size > 2;

  return (
    <Box extraClassName="NewsHighlight">
      <NewsHighlightItem className="ga-a" newsItem={news.get(0)} {...rest} isLargeItem />

      {hasSecondItem && (
        <NewsHighlightItem
          // If there is no third item, span two rows
          className={cx('ga-b', {'r-span-2': hasThirdItem})}
          newsItem={news.get(1)}
          {...rest}
        />
      )}
      {hasThirdItem && <NewsHighlightItem className="ga-c" newsItem={news.get(2)} {...rest} />}
    </Box>
  );
};
