import {
  createStyleProvider,
  superstyled,
  SuperStyled,
  StyledHTML,
} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {BoxStyleProps} from 'fiba/wt/ui/box/Box';

// Tuning knobs for this component
export type SpacerStyleProps = SuperStyled<'vSpace' | 'hSpace'> & BoxStyleProps;

export const Spacer = superstyled<SpacerStyleProps, StyledHTML<'div'>>('div');
Spacer.displayName = 'Spacer';

export const SpacerProvider = createStyleProvider<SpacerStyleProps>();
SpacerProvider.displayName = 'SpacerProvider';
