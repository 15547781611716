import React from 'react';
import cx from 'classnames';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {getProfileImageSrcSet, getProfileImageWt} from 'fiba/common/core/images';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {generateSizes} from 'fiba/wt/utils/imageUtils';
import {Player} from 'fiba/common/core/models/api/players/Player';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {ImageWithPlaceholder} from 'fiba/wt/ui/image/Image';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {getTeam} from 'fiba/wt/stores/teamStore';

interface ITeamPlayers {
  teamId: string;
  playerLinkProvider: ResourceLinkProvider;
}

export interface ITeamPlayersRedux {
  players: List<Player>;
  metaPaths: string[];
}

const mapStateToProps = (state, {teamId}: ITeamPlayers): ITeamPlayersRedux => ({
  players: getTeam(state, teamId).get('teamMembers', List()),
  metaPaths: [`teams/__meta/${teamId}`],
});

export const _TeamPlayers: React.FunctionComponent<ITeamPlayers & ITeamPlayersRedux> = ({
  players,
  metaPaths,
  playerLinkProvider,
}) => (
  <div className="TeamPlayers">
    <Loading metaPaths={metaPaths}>
      {() => <VariablePlayers players={players} playerLinkProvider={playerLinkProvider} />}
    </Loading>
  </div>
);

const VariablePlayers: React.FunctionComponent<{
  players: List<Player>;
  playerLinkProvider: ResourceLinkProvider;
}> = ({players, playerLinkProvider}) => {
  const totalPlayers = players.count();

  // If more than 4 players, let them collapse in two rows+
  // If less than four, it is desirable to have them all in one row
  const wrappingConstraint = totalPlayers > 4 ? 'flex-nowrap-l' : 'flex-nowrap-s';
  const widthConstraint = totalPlayers > 4 ? 'w-33-s w-100-l' : 'w-100-s';

  if (totalPlayers === 0) {
    return <BlockText mb="4">Event roster not yet confirmed.</BlockText>;
  }

  return (
    <ul className={cx('flex flex-wrap items-start justify-center mnh3 mnh2-s', wrappingConstraint)}>
      {players
        .sortBy(player => player.lastName)
        .map(player => (
          <li
            key={player.id}
            className={cx('mb3 w-50 pa0 ph2-s flex flex-column items-center', widthConstraint)}
          >
            <ImageWithPlaceholder
              aspectRatio="1x1"
              className="o-fit-contain o-pos-b"
              containerStyle={{bgColor: 'silver-20'}}
              alt=""
              // 2 columns, 3 columns and padding estimate, something, mw9 / total players (guesstimate, not accounting for space)
              sizes={generateSizes(['50vw', '30vw', null, `calc(96rem / ${totalPlayers})`])}
              srcSet={getProfileImageSrcSet('wt', player, ['tiny', 'small', 'medium'])}
              src={getProfileImageWt(player, 'small')}
            />

            <BlockText mt="2" fontSize="6" textAlign="center">
              <StyledLink href={playerLinkProvider(player.id)}>{getPlayerName(player)}</StyledLink>
            </BlockText>
          </li>
        ))}
    </ul>
  );
};

export const TeamPlayers = connect<ITeamPlayersRedux, {}, ITeamPlayers>(mapStateToProps)(
  _TeamPlayers,
);
