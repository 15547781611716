import {fromJS} from 'immutable';
import {createActions, createReducer, setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import Category from 'fiba/common/core/models/api/events/Category';
import {RootState} from 'fiba/play/stores/rootStore';

export const storePath = 'categories';

const actions = createActions(__filename, {
  loadCategory: (categoryId: string) => ({apiClient}: CommonServices) => [
    categoryId,
    apiClient
      .loadCategory(categoryId)
      .then(actions.loadCategorySuccess)
      .catch(rethrow(err => actions.loadCategoryError(categoryId, err))),
  ],

  loadCategorySuccess: category => category,

  loadCategoryError: (categoryId: string, error) => [
    {categoryId, error},
    notificationActions.addNotificationFromError(error),
  ],

  loadCategoriesByEvent: (eventId: string) => ({apiClient}) => {
    return [
      eventId,
      apiClient
        .loadCategoriesByEvent(eventId)
        .then(res => actions.loadCategoriesByEventSuccess(eventId, res.get('categories')))
        .catch(err => actions.loadCategoriesByEventError(eventId, err)),
    ];
  },

  loadCategoriesByEventSuccess: (eventId: string, categories) => ({eventId, categories}),

  loadCategoriesByEventError: (eventId: string, error) => [
    {eventId, error},
    notificationActions.addNotificationFromError(error),
  ],
  loadWorldTourCategoriesByEvent: (eventId: string) => ({apiClient}) => [
    eventId,
    apiClient
      .loadWorldTourCategoriesByEvent(eventId)
      .then(res => actions.loadWorldTourCategoriesByEventSuccess(eventId, res.get('categories')))
      .catch(err => actions.loadWorldTourCategoriesByEventError(eventId, err)),
  ],

  loadWorldTourCategoriesByEventSuccess: (eventId: string, categories) => ({eventId, categories}),

  loadWorldTourCategoriesByEventError: (eventId: string, error) => [
    {eventId, error},
    notificationActions.addNotificationFromError(error),
  ],
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    loadCategory: (state, categoryId: string) =>
      state.updateIn(['__meta', categoryId], setMeta.isLoading),

    loadCategorySuccess: (state, category) =>
      state
        .set(category.get('id'), category)
        .updateIn(['__meta', category.get('id')], setMeta.isLoaded),

    loadCategoryError: (state, {categoryId}) =>
      state.updateIn(['__meta', categoryId], setMeta.isError),

    loadCategoriesByEventSuccess: (state, {categories}) =>
      categories.reduce(reducer.loadCategorySuccess, state),

    loadWorldTourCategoriesByEventSuccess: (state, {categories}) =>
      categories.reduce(reducer.loadCategorySuccess, state),

    loadTeamsByCategory: (state, categoryId) =>
      state.updateIn(['__meta', categoryId, 'teams'], setMeta.isLoading),

    loadTeamCategorySuccess: (state, {categoryId, teams}) =>
      state
        .updateIn(['__meta', categoryId, 'teams'], setMeta.isLoaded)
        .setIn(
          ['__meta', categoryId, 'teams', 'refs'],
          teams.map(team => team.get('id')),
        )
        .setIn(['teams', categoryId], teams), // FIXME: Deprecate this field

    loadTeamsByCategoryError: (state, {categoryId}) =>
      state.updateIn(['__meta', categoryId, 'teams'], setMeta.isError),

    // TODO: What metadata/refs do we store here? ref for results is just the categoryId,
    // but we do have groups and games
    loadResultsByCategory: (state, categoryId) =>
      state.updateIn(['__meta', categoryId, 'results'], setMeta.isLoading),

    loadResultsByCategorySuccess: (state, {categoryId, results}) =>
      state
        .updateIn(['__meta', categoryId, 'results'], setMeta.isLoaded)
        .setIn(['results', categoryId], results), // FIXME: Deprecate this field

    loadResultsByCategoryError: (state, {categoryId}) =>
      state.updateIn(['__meta', categoryId, 'results'], setMeta.isError),
  },
  fromJS({}),
);

// Selectors
export const getCategory = (state: RootState, categoryId: string): Category | undefined =>
  state.getIn([storePath, categoryId]) as Category;

//
// Utils

export const getCategoriesByEvent = (categories, eventId) =>
  categories.filter(category => category.get('eventId') === eventId).toList();
