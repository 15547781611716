import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {EventOverviewHeading} from 'fiba/wt/ui/eventPage/EventOverviewHeading';
import {EventNewsSummary} from 'fiba/wt/ui/eventNews/EventNewsSummary';
import {
  ContentfulEmbeddedMediaSummary,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {EventPageProps} from 'fiba/wt/ui/eventPage/EventPageCommon';
import {Box} from 'fiba/wt/ui/box/Box';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {EventFinalResults} from 'fiba/wt/ui/eventFinalResults/EventFinalResults';
import {EventResultsPodium} from 'fiba/wt/ui/eventResultsPodium/EventResultsPodium';

import {EventPhotoGallery} from 'fiba/wt/ui/eventPhotoGallery/EventPhotoGallery';

type Props = EventPageProps;

export const EventPagePost: React.FC<Props> = ({eventId, season, siteConfig, usePodium}) => (
  <Spacer debugClassName="EventPagePost">
    <EventOverviewHeading season={season} eventId={eventId} />

    <Section>
      {usePodium ? (
        <EventResultsPodium season={season} eventId={eventId} labelId="event-page-final-results" />
      ) : (
        <EventFinalResults season={season} eventId={eventId} labelId="event-page-final-results" />
      )}
      <EventNewsSummary season={season} eventId={eventId} siteConfig={siteConfig} />
      <ContentfulEmbeddedMediaSummary
        title="Videos"
        contentPath={`events/${eventId}`}
        mediaType={EmbeddedMediaTypes.YoutubeMainPlaylist}
        widthHint={720} // TODO: discuss
        season={season}
        eventId={eventId}
        siteConfig={siteConfig}
      />

      <Box>
        <EventPhotoGallery heading="Photos" season={season} eventId={eventId} />
      </Box>
    </Section>
  </Spacer>
);
