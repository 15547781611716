import React, {useContext} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {TourNewsSummary} from 'fiba/wt/ui/tourNews/TourNewsSummary';
import {
  ContentfulEmbeddedMediaSummary,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {TourPhotoGallery} from 'fiba/wt/ui/tourPhotoGallery/TourPhotoGallery';
import {TourPageProps, SectionHeading, TourHeading} from 'fiba/wt/ui/tourPage/TourPageCommon';
import {EventPortalLive} from 'fiba/wt/ui/eventPortal/EventPortalLive';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {TwitterFeed} from 'fiba/wt/ui/twitterFeed/TwitterFeed';
import {VisuallyHidden} from '../visuallyHidden/VisuallyHidden';

type TourPageLiveEventProps = TourPageProps & {eventId: string};

export const TourPageLiveEvent: React.SFC<TourPageLiveEventProps> = ({
  tourId,
  season,
  eventId,
  showTwitterFeed,
}) => {
  const siteConfig = useContext(SiteConfigContext);
  return (
    <div className="TourPageLiveEvent pt3 pt0-m">
      <Spacer vSpace="4">
        <VisuallyHidden>
          <TourHeading>Season {season}</TourHeading>
        </VisuallyHidden>

        <Section>
          <EventPortalLive
            season={season}
            tourId={tourId}
            eventId={eventId}
            siteConfig={siteConfig}
          />

          <Box debugClassName="TourPageLiveEvent-News">
            <SectionHeading>News</SectionHeading>
            <Section>
              <TourNewsSummary tourId={tourId} season={season} />
            </Section>
          </Box>

          <Box debugClassName="TourPageLiveEvent-Media">
            <ContentfulEmbeddedMediaSummary
              title={<SectionHeading>Videos</SectionHeading>}
              contentPath={`tours/${tourId}`}
              mediaType={EmbeddedMediaTypes.YoutubeMainPlaylist}
              widthHint={720} // TODO: discuss
              season={season}
              siteConfig={siteConfig}
            />
          </Box>

          <Box debugClassName="TourPageLiveEvent-PhotoGallery">
            <SectionHeading>Photos</SectionHeading>
            <Section>
              <TourPhotoGallery tourId={tourId} season={season} />
            </Section>
          </Box>
          {showTwitterFeed && (
            <Box debugClassName="TourPageLiveEvent-Twitter">
              <SectionHeading>Tweets</SectionHeading>
              <Section>
                <TwitterFeed season={season} />
              </Section>
            </Box>
          )}
        </Section>
      </Spacer>
    </div>
  );
};
