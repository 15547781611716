import React, {useContext} from 'react';
// import HighlightedEvent from 'fiba/wt/ui/highlightedEvent/HighlightedEvent';
// import TourEvents from 'fiba/wt/ui/tourEvents/TourEvents';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {TourPageProps, TourHeading, SectionHeading} from 'fiba/wt/ui/tourPage/TourPageCommon';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Box} from 'fiba/wt/ui/box/Box';
import {TwitterFeed} from 'fiba/wt/ui/twitterFeed/TwitterFeed';
import {
  ContentfulEmbeddedMediaSummary,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {TourPhotoGallery} from 'fiba/wt/ui/tourPhotoGallery/TourPhotoGallery';
import {TourPageHeroImage} from 'fiba/wt/ui/tourPage/TourPageHeroImage';
import {VisuallyHidden} from '../visuallyHidden/VisuallyHidden';
import {TourNewsSummary} from '../tourNews/TourNewsSummary';

export const TourPageDefault: React.SFC<TourPageProps> = ({tourId, season, showTwitterFeed}) => {
  const siteConfig = useContext(SiteConfigContext);
  return (
    <div className="TourPageDefault">
      <Spacer vSpace="4">
        <VisuallyHidden>
          <TourHeading>Season {season}</TourHeading>
        </VisuallyHidden>

        <Section>
          <Box>
            <TourPageHeroImage tourId={tourId} season={season} />
          </Box>

          <Box>
            <SectionHeading>News</SectionHeading>
            <Section>
              <TourNewsSummary tourId={tourId} season={season} />
            </Section>
          </Box>

          <Box>
            <ContentfulEmbeddedMediaSummary
              title={<SectionHeading>Videos</SectionHeading>}
              contentPath={`tours/${tourId}`}
              mediaType={EmbeddedMediaTypes.YoutubeMainPlaylist}
              widthHint={720} // TODO: discuss
              season={season}
              siteConfig={siteConfig}
            />
          </Box>

          <Box>
            <SectionHeading>Photos</SectionHeading>
            <Section>
              <TourPhotoGallery tourId={tourId} season={season} />
            </Section>
          </Box>

          {showTwitterFeed && (
            <Box debugClassName="TourPageDefault-Twitter">
              <SectionHeading>Tweets</SectionHeading>
              <Section>
                <TwitterFeed season={season} />
              </Section>
            </Box>
          )}
        </Section>
      </Spacer>
    </div>
  );
};
