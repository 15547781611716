import React, {useContext} from 'react';
import {List} from 'immutable';
import {P} from 'fiba/wt/ui/text/Text';
import {areStats, getTeamIdForLink} from 'fiba/wt/ui/statistics/statsSummary/utils';
import {LazyImageWithPlaceholder} from 'fiba/wt/ui/image/LazyImageWithPlaceholder';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {Player} from 'fiba/common/core/models/api/players/Player';
import {TopPlayerStats as EventTopPlayerStats} from 'fiba/common/core/models/api/stats/EventStatsSummary';
import {TopPlayerStats as TourTopPlayerStats} from 'fiba/common/core/models/api/stats/TourStatsSummary';
import {getProfileImageWt} from 'fiba/common/core/images';
import EmptyStats from 'fiba/wt/ui/statistics/statsSummary/EmptyStats';
import Team from 'fiba/common/core/models/api/teams/Team';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

interface Props {
  title: string;
  stats: List<EventTopPlayerStats | TourTopPlayerStats>;
  createPlayerHref: Function;
  createTeamHref: Function;
  numberOfDecimals?: number;
}

export default function PlayerStats({
  title,
  stats,
  createPlayerHref,
  createTeamHref,
  numberOfDecimals = 0,
}: Props) {
  const {features} = useContext(SiteConfigContext);
  return (
    <div className="player-stats-item shadow-1">
      <P fontWeight="6" mb="3" ml="3">
        {title}
      </P>
      {!areStats(stats) ? (
        <EmptyStats />
      ) : (
        <ul>
          {stats.map((statsItem, index) => {
            const playerLike = Player.fromJS({
              firstName: statsItem.playerFirstName,
              lastName: statsItem.playerLastName,
              imageTinyUrl: statsItem.imageUrl,
            });
            const playerFullName = getPlayerName(playerLike);
            const isFirstItem = index === 0;
            const teamIdForLink = getTeamIdForLink(statsItem);
            return (
              <li key={statsItem.playerId} className={features.siteId}>
                <div className="rank">{index + 1}.</div>
                <div className="names">
                  <a href={createPlayerHref(statsItem.teamId)(statsItem.playerId)} className="f5">
                    {playerFullName}
                  </a>
                  {/* In Pro-Circuit level, we only render links to teams that played in WT */}
                  {teamIdForLink ? (
                    <a href={createTeamHref(teamIdForLink)}>
                      <TeamNameAssembly
                        team={Team.fromJS({
                          id: statsItem.teamId,
                          name: statsItem.teamName,
                          nameSuffix: statsItem.teamNameSuffix,
                        })}
                        isSuffixVisible
                      />
                    </a>
                  ) : (
                    <div>
                      <TeamNameAssembly
                        team={Team.fromJS({
                          id: statsItem.teamId,
                          name: statsItem.teamName,
                          nameSuffix: statsItem.teamNameSuffix,
                        })}
                        isSuffixVisible
                      />
                    </div>
                  )}
                </div>
                <div className="player-image">
                  {isFirstItem && (
                    <LazyImageWithPlaceholder
                      aspectRatio="1x1"
                      src={getProfileImageWt(playerLike, 'tiny')}
                      alt={`Profile of ${playerFullName}`}
                      className="o-fit-contain o-pos-b"
                      containerStyle={{bgColor: 'silver-30'}}
                    />
                  )}
                </div>
                <p className="value">{statsItem.value.toFixed(numberOfDecimals)}</p>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
