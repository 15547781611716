import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import {camelCase, isArray, isObject, isString, map, mapKeys, mapValues} from 'lodash';

import url from 'fiba/common/utils/url';

//
// API client interface

type AxiosMiddleware = (axios: AxiosInstance) => AxiosInstance;

const DEFAULT_TIMEOUT = 28000;

export class ContentApiClientService {
  axios: AxiosInstance;
  options: AxiosRequestConfig;

  constructor(options: string | AxiosRequestConfig, ...middlewares: AxiosMiddleware[]) {
    this.options = (isString(options) ? {baseURL: options} : options) as AxiosRequestConfig;
    this.axios = middlewares.reduce(
      (axios, middleware) => middleware(axios),
      axios.create(this.options),
    );
  }

  loadNewsItemById(newsId: string) {
    return this.axios.get(url`/v1/News/${newsId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadNewsItemBySlug(newsSlug: string) {
    return this.axios.get(url`/v1/News/Slug/${newsSlug}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadNewsPreviewsByTour(tourId: string) {
    return this.axios.get(url`/v1/News/Tour/${tourId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadNewsPreviewsForNL(conferenceIds: string[]) {
    const allConferenceNews = [];
    return Promise.all(
      conferenceIds.map(conferenceId => {
        return this.axios
          .get(url`/v1/News/Tour/${conferenceId}`, {timeout: DEFAULT_TIMEOUT})
          .then(res => allConferenceNews.push(res.data));
      }),
    ).then(() => allConferenceNews.flat());
  }

  loadNewsPreviewsByEvent(eventId: string) {
    return this.axios.get(url`/v1/News/Event/${eventId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGalleryPreviewsByEvent(eventId: string) {
    return this.axios.get(url`/v1/Image/Galleries/Event/${eventId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadPhotosByGallery(galleryId: string) {
    return this.axios.get(url`/v1/Image/Gallery/${galleryId}/Images`, {timeout: DEFAULT_TIMEOUT});
  }

  loadGalleryPreviewsByEventIds(eventIds: string[]) {
    const galleriesWithEvents = [];
    const loadGalleryPreviewsByEvent = eventId => {
      return this.loadGalleryPreviewsByEvent(eventId).then(res => {
        Object.keys(res.data).forEach(categoryId => {
          if (res.data[categoryId].length > 0) {
            res.data[categoryId].forEach(gallery => {
              galleriesWithEvents.push({
                ...gallery,
                eventId,
              });
            });
          }
        });
      });
    };

    const promises = eventIds.map(eventId => loadGalleryPreviewsByEvent(eventId));
    return Promise.all(promises).then(() => galleriesWithEvents);
  }

  loadGalleryPreviewsByTour(tourId: string) {
    return this.axios.get(url`/v1/Image/Galleries/Tour/${tourId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadNewsByTeam(teamId: string) {
    return this.axios.get(url`/v1/News/TeamInEvent/${teamId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadPhotosByTeam(teamId: string) {
    return this.axios.get(url`/v1/Image/Galleries/Team/${teamId}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadVideosByYouTubePlaylistUrl(playlistUrl: string) {
    return this.axios.get(url`/v1/video/playlist?url=${playlistUrl}`, {timeout: DEFAULT_TIMEOUT});
  }

  loadVideosByEventId(eventId: string) {
    return this.axios.get(url`/v1/video/event/${eventId}`, {timeout: DEFAULT_TIMEOUT});
  }
}

/**
 * Factory funtion for a client service with middleware.
 */
export function createContentApiClientService(
  options: string | AxiosRequestConfig,
  ...middlewares: AxiosMiddleware[]
) {
  return new ContentApiClientService(options, ...middlewares);
}

export default createContentApiClientService;

/**
 * This functions takes an object and formats all keys to be in lowerCamelCase (using lodash)
 * This is required as the Content API returns keys as UpperCamelCase, which we don't use in
 * the rest of this codebase. If this function seems useful for other applications it can be
 * split out to a dedicated utils module, however at the moment it's only use is for the
 * Content API.
 *
 * e.g.
 * {
 *    SomeId: "123"
 *    MetaData: {
 *       CreatedAt: "2023-02-23"
 *    }
 * }
 *
 * gets converted to
 * {
 *    someId: "123"
 *    metaData: {
 *       createdAt: "2023-02-23"
 *    }
 * }
 */
export function formatKeysToCamelCase<T>(obj: T): any {
  if (isArray(obj)) {
    return map(obj, formatKeysToCamelCase);
  } else if (isObject(obj)) {
    return mapValues(
      mapKeys(obj, (_value, key) => camelCase(key)),
      formatKeysToCamelCase,
    );
  } else {
    return obj;
  }
}
