import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {TeamStatistics} from 'fiba/wt/ui/common/statistics/TeamStatistics';
import {tourTeamLinkProvider} from 'fiba/wt/utils/linkUtils';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import Team from 'fiba/common/core/models/api/teams/Team';

export interface Props {
  season: string;
  tourId: string;
}

export const TourStatisticsTeamsTab: React.FC<Props> = ({season, tourId}) => (
  <Spacer vSpace="4">
    <TeamStatistics
      // In WT level, we always render a link to the team (every team played in WT)
      renderTeamName={teamStats => {
        const teamLike = Team.fromJS({
          id: teamStats.teamId,
          name: teamStats.teamName,
          nameSuffix: teamStats.teamNameSuffix,
        });
        return (
          <StyledLink href={tourTeamLinkProvider(season)(teamStats.teamId)}>
            <TeamNameAssembly team={teamLike} isSuffixVisible isIocVisible={false} />
          </StyledLink>
        );
      }}
      tourOrEventId={tourId}
    />
  </Spacer>
);
