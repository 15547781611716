import React from 'react';
import {SpaceScale} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {SuperStyled} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';

/** Cluster Props */
type Props = {
  gap: SpaceScale;
  /** Extra class name for the topmost wrapper */
  extraClassName?: string;
  /** Style props for the topmost wrapper (Box) */
  wrapperStyle?: WrapperStylePropsSubset;
  /** Style props for the flex container (Flex) */
  flexStyle?: FlexStylePropsSubset;
};

// A subset of Flex's Style Props
type FlexStylePropsSubset = SuperStyled<
  'flexDirection' | 'flexWrap' | 'alignItems' | 'alignContent' | 'justifyContent'
>;

// A subset of Box's style props
type WrapperStylePropsSubset = SuperStyled<
  | 'fontSize'
  | 'fontWeight'
  | 'color'
  | 'bgColor'
  | 'align'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'measure'
  | 'shadow'
  | 'position'
  | 'border'
  | 'borderRadius'
  | 'borderWidth'
  | 'borderColor'
  | 'ma'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'mn'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'flex'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexOrder'
  | 'alignSelf'
>;

/**
 * A Layout component that arranges its children with a specific spacing, while leaving no extra space around itself.
 * Wraps automatically to accomodate content, using Flexbox and flex-wrap.
 */
export const Cluster: React.FC<Props> = React.forwardRef(
  (
    {gap, extraClassName, wrapperStyle, flexStyle, children},
    ref: React.RefObject<HTMLDivElement>,
  ) => {
    /**
     * Layout notes:
     * - Use flex with flexWrap
     * - Give each child a margin all
     * - Set a negative margin on the flex, to clear the side margins
     * - Wrap the whole thing in a div, to allow nesting (i.e. the wrapper can have a margin)
     */
    const clusterCls = `Cluster Cluster--${gap} ${extraClassName ? extraClassName : ''}`;
    return (
      <Box ref={ref} {...wrapperStyle} extraClassName={clusterCls}>
        <Flex {...flexStyle}>{children}</Flex>
      </Box>
    );
  },
);
