import React, {useContext} from 'react';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Box} from 'fiba/wt/ui/box/Box';
import {GameStatsTeamTables} from 'fiba/wt/ui/gameBoxScore/GameStatsTeamTables';
import {Section, HeadingStyleProps} from 'fiba/wt/ui/heading/Heading';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {gameLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {BoxScoreBothTeams} from 'fiba/wt/ui/gameBoxScore/BoxScoreBothTeams';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

export type GameBoxScoreTab = 'root' | 'home-team' | 'away-team' | 'both-teams';

interface OwnProps {
  season: string;
  eventId: string;
  gameId: string;
  tab: GameBoxScoreTab;
}

interface ReduxProps {
  metaPaths: string[];
  gameResult: ResultsGameDetails;
  eventTypeData: WebData<EventType>;
}

type Props = ReduxProps & OwnProps;

const mapStateToProps = (state, {gameId, eventId}: OwnProps): ReduxProps => {
  return {
    metaPaths: [`gameStats/__meta/${gameId}`, `games/__meta/details/${gameId}`],
    gameResult: state.getIn(['games', 'details', gameId]),
    eventTypeData: getEventType(state.get('events'), eventId),
  };
};

function getGameTeamTab({tab, ...props}: Props) {
  switch (tab) {
    case 'away-team':
      return (
        <GameStatsTeamTables
          headingStyles={HEADING_STYLES}
          team="away"
          eventId={props.eventId}
          gameId={props.gameId}
        />
      );
    case 'home-team':
      return (
        <GameStatsTeamTables
          headingStyles={HEADING_STYLES}
          team="home"
          eventId={props.eventId}
          gameId={props.gameId}
        />
      );
    case 'both-teams':
    case 'root':
    default:
      return (
        <BoxScoreBothTeams
          eventId={props.eventId}
          gameId={props.gameId}
          gameResult={props.gameResult}
          season={props.season}
          headingStyles={HEADING_STYLES}
        />
      );
  }
}

const _GamePage: React.FunctionComponent<Props> = props => {
  const {gameId, gameResult, metaPaths, season, eventId, eventTypeData} = props;
  const {seasonConfig} = useContext(SiteConfigContext);

  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <ViewWebData data={eventTypeData}>
          {eventType => {
            const gameLink = gameLinkProvider(seasonConfig, season, eventId, eventType)(gameId);

            return (
              <Spacer bgColor="fullwhite">
                <Box pb="2" fontSize={['5', '5', '3']} fontWeight="6">
                  <Tabs
                    level="nested"
                    extraClassName="fw5 f6"
                    navigationLabel="Game Teams"
                    // NOTE: We use CSS grid here via className, to set an equal width and 100% height
                    extraUlClassName="GameBoxScore-TabsUl"
                    baseUrl={`${gameLink}/box-score`}
                    baseUrlAlias={gameLink}
                  >
                    <Tab hrefAliases={['/']} href="/">
                      Both Teams
                    </Tab>
                    <Tab href="/home-team">
                      <TeamNameAssembly
                        standing={gameResult.homeTeam}
                        isSuffixVisible
                        isIocVisible={false}
                      />
                    </Tab>
                    <Tab href="/away-team">
                      <TeamNameAssembly
                        standing={gameResult.awayTeam}
                        isSuffixVisible
                        isIocVisible={false}
                      />
                    </Tab>
                  </Tabs>
                </Box>
                <Section>{getGameTeamTab(props)}</Section>
              </Spacer>
            );
          }}
        </ViewWebData>
      )}
    </Loading>
  );
};

const HEADING_STYLES: HeadingStyleProps = {
  borderColor: 'silver-30',
  border: 'bottom',
  fontWeight: '6',
  fontSize: '6',
  pb: '1',
  pt: '3',
};
export const GameBoxScore = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_GamePage);
