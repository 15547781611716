import React from 'react';
import cx from 'classnames';
import {connect, assetPath} from 'fiba/common/utils/reactUtils';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {MenuIcon, CloseIcon} from 'fiba/wt/ui/svg/svg';
import {Navigation, defaultNavLinkStyle} from 'fiba/wt/ui/navigation/Navigation';
import {NavigationList} from 'fiba/wt/ui/sidebarNavigation/SidebarNavigation';
import {EventSelect} from 'fiba/wt/ui/tourLayout/EventSelect';
import {SeasonSelectMobile} from 'fiba/wt/ui/tourLayout/SeasonSelectMobile';
import {makeTabs} from 'fiba/wt/ui/tourLayout/TourNavigation';
import {Nav} from 'fiba/wt/ui/elements';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {BlurHandler} from 'fiba/wt/ui/blurHandler/BlurHandler';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {ContentTypes, getEventConfigurationForTour} from 'fiba/wt/stores/contentStore';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {TourProgrammabilityStateProvider} from 'fiba/wt/ui/tourProgrammability/TourProgrammabilityStateProvider';
import {getTourStatsVisibility} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {SpaceScale, TypeScale} from 'fiba/wt/ui/stylingAPI/styleMaps';

interface ITourNavigationMobile {
  tourId: string;
  season: string;
  tourTab?: string;
  siteConfig: ISiteConfig;
}

interface ITourNavigationMobileState {
  title: string;
  season: string;
  logoPath: string;
  alt: string;
  eventConfiguration?: EventConfiguration[];
  overrideNow?: string;
  metaPaths: string[];
}

const mapStateToProps = (
  state,
  {season, siteConfig, tourId}: ITourNavigationMobile,
): ITourNavigationMobileState => {
  const currentEventId = state.getIn(['route', 'payload', 'eventId']);
  const isWorldTour = siteConfig.features.siteId === 'worldtour';
  const currentEvent = currentEventId ? state.getIn(['events', currentEventId]) : null;
  const title = currentEvent
    ? currentEvent.shortName
      ? `${currentEvent.shortName} ${season}`
      : currentEvent.name
    : isWorldTour
    ? `World Tour ${season}`
    : `Women's Series`;

  const logoPath = isWorldTour ? assetPath('img/logo-wt-v2.svg') : assetPath('img/logo-ws-v2.svg');

  const alt = isWorldTour ? '3x3 WT' : '3x3 WS';

  return {
    title,
    season,
    logoPath,
    alt,
    eventConfiguration: getEventConfigurationForTour(state, tourId),
    // Yay, more internal secret query params \_(-_-)_/
    overrideNow: state.getIn(['route', 'payload', '_now']),
    metaPaths: [
      `tours/__meta/${tourId}/events`,
      `content/__meta/${ContentTypes.EventConfigurator}/tourEvents/${tourId}`,
    ],
  };
};

type TourNavigationMobileProps = ITourNavigationMobile & ITourNavigationMobileState;

/**
 * Important spaces for the hierarchy
 */
const TITLE_CONTENT_SPACE: SpaceScale = '3';
const BLOCK_PADDING: SpaceScale = '3';
const TITLE_SIZE: TypeScale = '6';
const ITEM_SIZE: TypeScale = '5';

/* The navigation main row; composed of the open/closed state and configuration from above */
const _TourNavigationMobile = ({
  tourTab,
  tourId,
  season,
  siteConfig,
  logoPath,
  alt,
  title,
  eventConfiguration,
  overrideNow,
}: TourNavigationMobileProps) => (
  <div className="TourNavigationMobile">
    <Navigation extraClassName="z-1" season={season}>
      <Nav
        pr="3"
        height="2-half"
        bgColor="dark-50"
        flex="auto"
        flexDirection="row"
        alignItems="center"
        justifyContent="between"
        debugClassName="TourNavigationMobileMain"
      >
        <Flex alignItems="center" ph="3">
          <Box pr={['3', '4']} flexShrink="0">
            <a className="db silver-10" href={`/${season}`}>
              {/* NOTE: The width here must be set, because the image has viewBox
              We pick 5rem to match visually against the title. It is not scientific :) */}
              <img className="db" style={{width: '5rem'}} src={logoPath} alt={alt} />
            </a>
          </Box>
          <Box width={['4', 'auto']} alignSelf="center" extraClassName="overflow-x-auto ws-nowrap">
            <span className="silver-10 f6 f5-s fw7">{title}</span>
          </Box>
        </Flex>

        <BlurHandler>
          {({isActive: isMenuOpen, onClick, onFocus, onBlur}) => (
            <div onFocus={onFocus} onBlur={onBlur}>
              <MenuButton onClick={onClick} isOpen={isMenuOpen} extraClassName="z-1 relative">
                <span>Menu</span>
                {isMenuOpen ? (
                  <CloseIcon className="ht1 w2 pl2" purpose="decorative" />
                ) : (
                  <MenuIcon className="ht1 w2 pl2" purpose="decorative" />
                )}
              </MenuButton>

              <TourProgrammabilityStateProvider
                eventConfiguration={eventConfiguration}
                overrideNow={overrideNow}
              >
                {({state}) => {
                  const showStats = getTourStatsVisibility(siteConfig, season);

                  const tabs = makeTabs(
                    state,
                    season,
                    tourTab,
                    siteConfig.features.showTourCalendar,
                    showStats,
                  );

                  return (
                    // TODO: Add a key and see if it fixes it
                    <TourNavigationMobilePopout
                      onClick={onClick}
                      season={season}
                      tourId={tourId}
                      tourTabs={tabs}
                      isOpen={isMenuOpen}
                      siteConfig={siteConfig}
                    />
                  );
                }}
              </TourProgrammabilityStateProvider>
            </div>
          )}
        </BlurHandler>
      </Nav>
    </Navigation>
  </div>
);

export const TourNavigationMobile = connect<ITourNavigationMobileState, {}, ITourNavigationMobile>(
  mapStateToProps,
)(_TourNavigationMobile);

// Utilities //

/* Button with resets */
// TODO: localise label
const MenuButton = ({onClick, isOpen, children, extraClassName}) => (
  <button
    aria-expanded={isOpen}
    onClick={onClick}
    className={cx({
      'f7 pa2 flex justify-center items-end ba br2 b--silver-10 pointer appearance-none focus-shadow transition-bg-c': true,
      [extraClassName]: true,
      'bg-dark-50 silver-10 hover-bg-silver-10 hover-dark-50': !isOpen,
      'bg-silver-10 dark-50 hover-bg-dark-50 hover-silver-10': isOpen,
    })}
  >
    {children}
  </button>
);

interface ExternalLink {
  color: StyleProp<'color'>;
  content: React.ReactNode;
  href: string;
}

const ExternalLink: React.FC<ExternalLink> = ({color, content, href}) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={cx('link link--bare ttu f8 fw7 dim', color)}
  >
    {content}
  </a>
);

const getExternalLinks = (siteConfig: ISiteConfig) => [
  ['fiba3x3.com', 'https://fiba3x3.com', 'silver-10'],
  ['fiba.basketball', 'https://fiba.basketball', 'sand'],
];

const ExternalLinks: React.FC<{siteConfig: ISiteConfig}> = ({siteConfig}) => (
  <ul className="vs2">
    {getExternalLinks(siteConfig)
      .filter(x => x)
      .map(([title, href, color]) => (
        <li key={title}>
          <ExternalLink href={href} content={title} color={color as any} />
        </li>
      ))}
  </ul>
);

const TourNavigationMobilePopout = ({onClick, season, tourId, tourTabs, isOpen, siteConfig}) => (
  <Flex
    width="100"
    bgColor="dark-50"
    color="fullwhite"
    shadow="3"
    flexDirection="column"
    extraClassName="scroll-touch overscroll-contain animated fade-in TourNavigationMobile-Popout"
    style={{overflow: 'auto'}}
    // NOTE: If 'hidden' is not present, it should not be on the DOM element at all
    // The values "true" and "false" are not allowed on boolean attributes.
    // To represent a false value, the attribute has to be omitted altogether.
    // React handles this by setting `hidden=""` and not including otherwise.
    // This is correct, but might as well note for future reference.
    hidden={!isOpen}
  >
    <Spacer vSpace="3">
      {!!siteConfig.features.showSeasonSelect && (
        <Box mt="3" mh="3" extraClassName="overflow-x-auto scroll-touch">
          <SeasonSelectMobile />
        </Box>
      )}
      <Flex flexDirection={['column', 'row']}>
        {!!siteConfig.features.showEventsInNavigation && (
          <Spacer flex="auto" ma={BLOCK_PADDING} vSpace={TITLE_CONTENT_SPACE} fontSize={ITEM_SIZE}>
            <BlockText fontSize={TITLE_SIZE} extraClassName="TourNavigationMobile-Title">
              Events
            </BlockText>

            <EventSelect
              onSelect={onClick}
              season={season}
              tourId={tourId}
              siteConfig={siteConfig}
            />
          </Spacer>
        )}

        {/* Tour Content */}
        <Spacer flex="auto" ma={BLOCK_PADDING} vSpace={TITLE_CONTENT_SPACE} fontSize={ITEM_SIZE}>
          <BlockText fontSize={TITLE_SIZE} extraClassName="TourNavigationMobile-Title">
            Tour
          </BlockText>
          <NavigationList
            onClick={onClick}
            label="Tour content"
            tabs={tourTabs}
            linkExtraClassName="pv1 ph2 fw7"
            listItemExtraClassName="pv1"
            colorTheme="light"
            navLinkStyle={defaultNavLinkStyle.light}
          />
        </Spacer>
      </Flex>

      {/* List of external links */}
      <Box pa="3" bgColor="dark-40">
        <ExternalLinks siteConfig={siteConfig} />
      </Box>
    </Spacer>
  </Flex>
);
