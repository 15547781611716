import {Map} from 'immutable';
import PlayByPlayPlayer from 'fiba/common/core/models/api/playByPlay/PlayByPlayPlayer';

// Convert to player full name
export const getPlayerName = (players: Map<string, PlayByPlayPlayer>, playerId: string): string => {
  const player = players.get(playerId);

  if (player) {
    const jerseyNumberIfAvailable = player.jerseyNumber ? ` (#${player.jerseyNumber})` : '';

    return `${player.firstName} ${player.lastName}${jerseyNumberIfAvailable}`;
  } else {
    return ``;
  }
};
