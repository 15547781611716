import React from 'react';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';

interface Props<Data> {
  data: RemoteData.WebData<Data>;
  children: (loadedData: Data) => React.ReactNode;
}

/**
 * Standard component for viewing RemoteData.WebData data.
 * This allows us to have a good-by-default, centralised way
 * of showing data in our application.
 *
 * Use this component if you are unsure about the display needs
 * of a component.
 *
 * In cases where you need more custom behaviour, use `RemoteData.match`
 * directly on the data, and do things ad-hoc.
 *
 * This component takes the data to display, and passes the loaded
 * data behaviour in the children.
 *
 * @example
 * function MyDataComponent(props) {
 *  const {eventData} = props;
 *
 *  return (
 *    <ViewWebData data={eventData}>
 *      {(event) => (
 *        <div>
 *          <h2>{event.shortName}</h2>
 *        </div>
 *      )}
 *    </ViewWebData>
 *  )
 * }
 * @param props
 */
export function ViewWebData<Data>(props: Props<Data>) {
  const {data, children} = props;

  return RemoteData.match(data, {
    NotAsked: () => null,
    Loading: () => (
      <div className="LoadingIndicator pv5 tc">
        <div className="dib h4 w4 LoadingIndicator-Indicator">
          <LoadingSVG purpose="standalone" aria-label="Loading..." />
        </div>
      </div>
    ),
    Failure: err => <BlockText>There was an error fetching the data</BlockText>,
    Success: children,
  });
}
