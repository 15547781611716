import React, {useContext} from 'react';
import cx from 'classnames';
import {LocalizerService, ServicesContext} from 'fiba/common/services/services';
import {getWinPercentage, getAveragePoints} from 'fiba/common/core/results';
import {TourResultsEvent} from 'fiba/common/core/models/api/results/TourResultsEvent';
import {TourResultsCategory} from 'fiba/common/core/models/api/results/TourResultsCategory';
import {TourResultsStop} from 'fiba/common/core/models/api/results/TourResultsStop';
import {TourResultsTeamStanding} from 'fiba/common/core/models/api/results/TourResultsTeamStanding';
import {TourStandingsColumnType, hasColumn} from 'fiba/wt/ui/tourStandings/TourStandingsColumnType';
import {eventTeamLinkProvider, tourTeamLinkProvider, eventRootLink} from 'fiba/wt/utils/linkUtils';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {useHorizontalScrollRegion} from 'fiba/wt/ui/hooks/useHorizontalScrollRegion';
import {Box} from 'fiba/wt/ui/box/Box';
import {Table as StyledTable} from 'fiba/wt/ui/table/Table';

const EMPTY_CELL_MARKER = '—';
// NOTE: Default tour team standings don't have `gamesPlayed` nor ´gamesWon` columns
const defaultColumns: TourStandingsColumnType[] = [
  'rank',
  'name',
  'finalEvent',
  'totalPoints',
  'events',
  'winPercentage',
  'averagePoints',
];

interface Props {
  season: string;
  /** A unique id for the table/region's label */
  ariaLabelledBy: string;
  category: TourResultsCategory;
  numRows?: number;
  columns?: TourStandingsColumnType[];
  disableTeamLinks?: boolean;
}

//
// Utility components
const HCell: React.FunctionComponent<{className?: string}> = ({className, children}) => (
  <th role="columnheader" scope="col" className={cx(className, 'ph2 pv3 fw4 v-mid')}>
    {children}
  </th>
);

const Cell: React.FunctionComponent<{className?: string}> = ({className, children}) => (
  <td className={cx(className, 'pa2 v-mid')}>{children}</td>
);

//
// Utility functions
const getTourStop = (event: TourResultsEvent, standing: TourResultsTeamStanding): TourResultsStop =>
  standing.tourStops.filter(stop => stop.eventId === event.eventId).first();

const getTourStopStanding = (tourStop: TourResultsStop, localizer: LocalizerService) => {
  return tourStop && tourStop.standing
    ? localizer.formatKey('POSITION_ORDINAL', {position: tourStop.get('standing')})
    : null;
};

const getTourStopStandingPoints = (tourStop: TourResultsStop) => {
  return tourStop && tourStop.standingPoints ? `${tourStop.standingPoints} pts` : null;
};

const getTourStandingQualified = (
  standing: TourResultsTeamStanding,
  localizer: LocalizerService,
) => {
  const qualification = standing.qualification;
  if (qualification === 'Qualified') {
    return localizer.formatKey('TOUR_QUALIFIED_SHORT');
  } else if (qualification === 'Shortlisted') {
    return localizer.formatKey('TOUR_SHORTLISTED_SHORT');
  }
};

// TODO: Check with @MRavimoF about exposing eventShortName in results
// TODO: Expose eventShortName in the Results API, if possible
// This Regex match is silly and not forward-looking.
// Could memoize if concerned about performance (not really)
// Matches name of events and then shows short version in standigns
// Index depends on which case has match, others would be undefined

const eventNameToShortName = (season: string) => {
  const eventRe = new RegExp(
    `(?:Women's Series|W[TS]) (.*) ${season}|${season} (?:Women's Series|W[TS]) (.*)|3[xX]3 (?:Women's Series|W[TS]) (.*) Stop`,
  );
  return (eventName: string) => {
    const matches = eventRe.exec(eventName.replace(/\s+/g, ' ').trim());
    return matches ? (matches[1] ? matches[1] : matches[2] ? matches[2] : matches[3]) : eventName;
  };
};

const rowClass = 'striped--light-even bb bw1 b--silver-20 hover-bg-blue-10 focusw-bg-blue-10';

export const TourTeamStandings: React.FC<Props> = ({
  season,
  category,
  // Wat
  numRows = Infinity,
  columns = defaultColumns,
  ariaLabelledBy,
  disableTeamLinks = false,
}) => {
  const {seasonConfig, theme} = useContext(SiteConfigContext);
  const {localizer} = useContext(ServicesContext);
  const {containerProps} = useHorizontalScrollRegion({
    'aria-labelledby': ariaLabelledBy,
  });

  // NOTE: TourTeamStandings only show Masters events, therefore the link type is masters for all links here
  const createTeamInEventHref = (tourStop: TourResultsStop) =>
    tourStop &&
    eventTeamLinkProvider(
      seasonConfig,
      season,
      tourStop.eventId,
      'WorldTour',
    )(tourStop.teamInEventId);

  const createTeamInTourHref = (standing: TourResultsTeamStanding) =>
    standing && tourTeamLinkProvider(season)(standing.parentTeamId);

  const events = category.events;
  const standings = category.teamStandings;

  const tourEvents = events.filter(event => !event.eventIsFinal);
  const finalEvent = events.filter(event => event.eventIsFinal).first();

  // Matches names like "WT (Kuala Lumpur) 2018"
  const eventNameToShort = eventNameToShortName(season);

  const renderTeamName = (standing: TourResultsTeamStanding) => {
    return (
      <Cell>
        {disableTeamLinks ? (
          <span>
            {standing.parentTeamName} <span className="i">{standing.parentTeamNameSuffix}</span>
          </span>
        ) : (
          <StyledLink href={createTeamInTourHref(standing)}>
            {standing.parentTeamName} <span className="i">{standing.parentTeamNameSuffix}</span>
          </StyledLink>
        )}
      </Cell>
    );
  };

  return (
    <Box debugClassName="TourTeamStandings">
      <div {...containerProps}>
        <StyledTable
          width="100"
          extraClassName={cx({
            'w-100 f7 lh-copy tnum br2': true,
            'DataTable-TwoStickyColumns': true,
          })}
        >
          <tbody>
            <tr className="dark-20 bb bw1 b--silver-20">
              {hasColumn('rank', columns) && <HCell className="tr">#</HCell>}

              {hasColumn('name', columns) && (
                <HCell className="tl">{localizer.formatKey('RESULTS_HEADER_TEAM')}</HCell>
              )}

              {/* Put the final event first */}
              {hasColumn('finalEvent', columns) && !!finalEvent && (
                <HCell className="tl" key={finalEvent.eventId}>
                  <StyledLink
                    href={eventRootLink(seasonConfig, season, finalEvent.eventId, 'WorldTour')}
                  >
                    {eventNameToShort(finalEvent.eventName)}
                  </StyledLink>
                </HCell>
              )}

              {hasColumn('totalPoints', columns) && (
                <HCell className="tr">{localizer.formatKey('RESULTS_HEADER_TOUR_POINTS')}</HCell>
              )}

              {hasColumn('events', columns) &&
                tourEvents.map(event => (
                  <HCell className="tl" key={event.eventId}>
                    <StyledLink
                      href={eventRootLink(seasonConfig, season, event.eventId, 'WorldTour')}
                    >
                      {eventNameToShort(event.eventName)}
                    </StyledLink>
                  </HCell>
                ))}

              {hasColumn('gamesPlayed', columns) && (
                <HCell className="tr">{localizer.formatKey('RESULTS_HEADER_GAMES_PLAYED')}</HCell>
              )}

              {hasColumn('gamesWon', columns) && (
                <HCell className="tr">{localizer.formatKey('RESULTS_HEADER_GAMES_WON')}</HCell>
              )}

              {hasColumn('winPercentage', columns) && (
                <HCell className="tr">{localizer.formatKey('RESULTS_HEADER_WIN_PERCENTAGE')}</HCell>
              )}

              {/* TODO: Specify that these are average points in a game */}
              {hasColumn('averagePoints', columns) && (
                <HCell className="tr">{localizer.formatKey('RESULTS_HEADER_AVERAGE_POINTS')}</HCell>
              )}

              {hasColumn('lastEmptyColumn', columns) && <th />}
            </tr>

            {standings.slice(0, numRows).map(
              standing =>
                standing.totalStandingPoints > 0 && (
                  <tr key={standing.parentTeamId} className={rowClass}>
                    {hasColumn('rank', columns) && (
                      <Cell className="tr">
                        {!!standing.parentTeamRank && `${standing.parentTeamRank}.`}
                      </Cell>
                    )}

                    {hasColumn('name', columns) && renderTeamName(standing)}

                    {hasColumn('finalEvent', columns) && !!finalEvent && (
                      <Cell className="tc" key={finalEvent.eventId}>
                        <div>
                          {(() => {
                            const tourStop = getTourStop(finalEvent, standing);
                            const href = tourStop && createTeamInEventHref(tourStop);
                            const content =
                              getTourStopStanding(tourStop, localizer) ||
                              getTourStandingQualified(standing, localizer);
                            return href ? (
                              <StyledLink href={createTeamInEventHref(tourStop)}>
                                {content}
                              </StyledLink>
                            ) : (
                              content || EMPTY_CELL_MARKER
                            );
                          })()}
                        </div>
                        <div className={theme.faintColor}>
                          {getTourStopStandingPoints(getTourStop(finalEvent, standing))}
                        </div>
                      </Cell>
                    )}

                    {hasColumn('totalPoints', columns) && (
                      <Cell className="tr">{standing.totalStandingPoints}</Cell>
                    )}

                    {hasColumn('events', columns) &&
                      tourEvents.map(event => {
                        const tourStop = getTourStop(event, standing);
                        const tourStanding = getTourStopStanding(tourStop, localizer);
                        const tourStandingPoints = getTourStopStandingPoints(tourStop);

                        return (
                          <Cell className="tc" key={event.eventId}>
                            <div>
                              {tourStanding && (
                                <StyledLink href={createTeamInEventHref(tourStop)}>
                                  {tourStanding}
                                </StyledLink>
                              )}
                              {tourStop &&
                                tourStop.isQualified &&
                                ` (${localizer.formatKey('TOUR_QUALIFIED_SHORT')})`}
                            </div>
                            <div className={`ws-nowrap ${theme.faintColor}`}>
                              {tourStandingPoints}
                              {!tourStandingPoints && !tourStanding && EMPTY_CELL_MARKER}
                            </div>
                          </Cell>
                        );
                      })}

                    {hasColumn('gamesPlayed', columns) && (
                      <Cell className="tr">{standing.gamesPlayed}</Cell>
                    )}

                    {hasColumn('gamesWon', columns) && (
                      <Cell className="tr">{standing.gamesWon}</Cell>
                    )}

                    {hasColumn('winPercentage', columns) && (
                      <Cell className="tr">{getWinPercentage(standing, localizer)}</Cell>
                    )}

                    {hasColumn('averagePoints', columns) && (
                      <Cell className="tr">{getAveragePoints(standing, localizer)}</Cell>
                    )}

                    {hasColumn('lastEmptyColumn', columns) && <td />}
                  </tr>
                ),
            )}
          </tbody>
        </StyledTable>
      </div>

      {hasColumn('finalEvent', columns) && !!finalEvent && (
        <p className={cx('pa3', theme.faintColor)}>
          {localizer.formatKey('TOUR_QUALIFIED_LEGEND')},
          {localizer.formatKey('TOUR_SHORTLISTED_LEGEND')}
        </p>
      )}
    </Box>
  );
};
