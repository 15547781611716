/** Module responsible for type-checked environment variables.
 * Some variables are shared between the client and server, while
 * others (ServerEnvironment) are secret to the server.
 * The types help authoring, while the implementation / whitelisting
 * in webpack.config.js enforce the variables for the client.
 * For the server, the variables are injected at runtime
 * (Heroku environment variables + node).
 */
export {CommonEnvironment, ServerEnvironment, commonEnv, serverEnv};

const WEB_ENVS = ['live', 'qa', 'uat', 'review', 'dev-live', 'dev-uat', 'local'] as const;
type WebEnv = typeof WEB_ENVS[number];
/** Shared environment that both the client and server can access */
interface CommonEnvironment {
  PORT?: string | number;
  WEB_ENV?: WebEnv;
  FIBA_BUILD_ID?: string;
  ERS_BACKEND_URL?: string;
  GA_GOOGLE_TAG?: string;
  GA_GOOGLE_TAG_EMBED?: string;
  GA_GOOGLE_TAG_WT?: string;
  GA_GOOGLE_TAG_WOMENSSERIES?: string;
  GA_GOOGLE_TAG_CUPS?: string;
  GA_GOOGLE_TAG_NATIONSLEAGUE?: string;
  SCORES_BACKEND_URL?: string;
  COMPETITION_CALENDAR_URL?: string;
  DOMAIN?: string;
  ALT_DOMAIN?: string;
  SECURE?: boolean;
  DEBUG?: boolean;
  ENABLE_CACHE_CONTROL?: boolean;
  CONTENTFUL_SPACE?: string;
  CONTENTFUL_ENVIRONMENT?: string;
  CONTENTFUL_ACCESS_TOKEN?: string;
  CONTENTFUL_HOST_URL?: string;
  SCRIPT_SRC_CSP_DOMAINS?: string;
  CAPTCHA_SITEKEY?: string;
}

/** Environment variables specific to the server,
 * that the client cannot rely on. They are used
 * in our proxy to talk to the API, to show the
 * UAT basic auth etc.
 */
interface ServerEnvironment extends CommonEnvironment {
  // Secret for the client
  APP_BACKEND_USERNAME?: string;
  APP_BACKEND_PASSWORD?: string;
  // Not secret, but the client should not rely on them (it should call our own proxy server)
  APP_BACKEND_URL?: string;
  APP_BACKEND_FALLBACK?: string;
  // Content API that we proxy
  CONTENT_API_URL?: string;
  CONTENT_API_KEY?: string;
  // Not secret (will not see UAT if not authed), but good to have server-only
  SITE_USERNAME?: string;
  SITE_PASSWORD?: string;
  // Not secret, but no need on the client
  ALLOW_ORIGIN?: string[];
}

// On the server, all of these are defined at runtime (Heroku)
// On the client, some of these (ComomnEnvironnment) are bundled
// into the app, selectively, via webpack.config.js.
const commonEnv: CommonEnvironment = {};

commonEnv.PORT = process.env.PORT || 3000;
commonEnv.DEBUG = process.env.DEBUG === 'true';
commonEnv.WEB_ENV = process.env.WEB_ENV as WebEnv;
commonEnv.SECURE = process.env.SECURE === 'true';
commonEnv.FIBA_BUILD_ID = process.env.FIBA_BUILD_ID;
commonEnv.ERS_BACKEND_URL = process.env.ERS_BACKEND_URL;
commonEnv.COMPETITION_CALENDAR_URL = process.env.COMPETITION_CALENDAR_URL;
commonEnv.SCORES_BACKEND_URL = process.env.SCORES_BACKEND_URL;
commonEnv.GA_GOOGLE_TAG = process.env.GA_GOOGLE_TAG;
commonEnv.GA_GOOGLE_TAG_EMBED = process.env.GA_GOOGLE_TAG_EMBED;
commonEnv.GA_GOOGLE_TAG_WT = process.env.GA_GOOGLE_TAG_WT;
commonEnv.GA_GOOGLE_TAG_WOMENSSERIES = process.env.GA_GOOGLE_TAG_WOMENSSERIES;
commonEnv.GA_GOOGLE_TAG_CUPS = process.env.GA_GOOGLE_TAG_CUPS;
commonEnv.GA_GOOGLE_TAG_NATIONSLEAGUE = process.env.GA_GOOGLE_TAG_NATIONSLEAGUE;
commonEnv.CONTENTFUL_SPACE = process.env.CONTENTFUL_SPACE;
commonEnv.CONTENTFUL_ENVIRONMENT = process.env.CONTENTFUL_ENVIRONMENT;
commonEnv.CONTENTFUL_ACCESS_TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN;
commonEnv.CONTENTFUL_HOST_URL = process.env.CONTENTFUL_HOST_URL;
commonEnv.ENABLE_CACHE_CONTROL = process.env.ENABLE_CACHE_CONTROL === 'true';
commonEnv.DOMAIN = process.env.DOMAIN;
commonEnv.SCRIPT_SRC_CSP_DOMAINS = process.env.SCRIPT_SRC_CSP_DOMAINS;
commonEnv.CAPTCHA_SITEKEY = process.env.CAPTCHA_SITEKEY;

switch (commonEnv.WEB_ENV) {
  case 'live':
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores.fiba3x3.com/api/ws';
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-competition-calendar.herokuapp.com';
    commonEnv.DOMAIN = 'fiba3x3.com';
    commonEnv.ALT_DOMAIN = 'fiba3x3.basketball';
    commonEnv.SECURE = true;
    break;
  case 'qa':
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores.fiba3x3.com/api/ws';
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-competition-calendar.herokuapp.com';
    commonEnv.DOMAIN = 'qa.fiba3x3.com';
    commonEnv.SECURE = true;
    break;
  case 'uat':
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-calendar-uat.herokuapp.com';
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores-uat.fiba3x3.com/api/ws';
    commonEnv.DOMAIN = 'uat.fiba3x3.com';
    commonEnv.SECURE = true;
    commonEnv.DEBUG = true;
    break;
  case 'local':
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-calendar-uat.herokuapp.com';
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores-uat.fiba3x3.com/api/ws';
    commonEnv.DEBUG = true;
    break;
  case 'dev-uat':
    commonEnv.ERS_BACKEND_URL = 'https://fiba-3x3-ers-uat.herokuapp.com';
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-calendar-uat.herokuapp.com';
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores-uat.fiba3x3.com/api/ws';
    commonEnv.DEBUG = true;
    break;
  case 'dev-live':
    commonEnv.ERS_BACKEND_URL = 'https://fiba-3x3-ers.herokuapp.com';
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-competition-calendar.herokuapp.com';
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores.fiba3x3.com/api/ws';
    commonEnv.DEBUG = true;
    break;
  case 'review':
    commonEnv.COMPETITION_CALENDAR_URL = 'https://fiba-3x3-calendar-uat.herokuapp.com';
    commonEnv.SCORES_BACKEND_URL = 'wss://live-scores-widget.scores-uat.fiba3x3.com/api/ws';
    commonEnv.SECURE = true;
    commonEnv.DEBUG = true;
  default:
    throw new Error(`WEB_ENV is not among ${WEB_ENVS}.`);
}

//
// Specific config for the ServerEnvironment

const serverEnv: ServerEnvironment = {
  ...commonEnv,
  APP_BACKEND_USERNAME: process.env.APP_BACKEND_USERNAME,
  APP_BACKEND_PASSWORD: process.env.APP_BACKEND_PASSWORD,
  APP_BACKEND_URL: process.env.APP_BACKEND_URL,
  APP_BACKEND_FALLBACK: process.env.APP_BACKEND_FALLBACK,
  CONTENT_API_URL: process.env.CONTENT_API_URL,
  CONTENT_API_KEY: process.env.CONTENT_API_KEY,
  SITE_USERNAME: process.env.SITE_USERNAME,
  SITE_PASSWORD: process.env.SITE_PASSWORD,
  // Deconstruct `ALLOW_ORIGIN` into an array if it is a string and contains comma-separated strings
  ALLOW_ORIGIN:
    process.env.ALLOW_ORIGIN && process.env.ALLOW_ORIGIN.indexOf(',') >= 0
      ? process.env.ALLOW_ORIGIN.split(',')
      : [process.env.ALLOW_ORIGIN],
};

switch (process.env.WEB_ENV) {
  case 'local':
    serverEnv.APP_BACKEND_URL = 'http://localhost:8080';
    serverEnv.APP_BACKEND_FALLBACK = 'http://localhost:8080';
    break;
  case 'dev-uat':
    serverEnv.APP_BACKEND_URL = 'https://fiba-app-backend-uat.herokuapp.com';
    serverEnv.APP_BACKEND_FALLBACK = 'https://play.uat.fiba3x3.com/api';
    break;
  case 'dev-live':
    serverEnv.APP_BACKEND_URL = 'https://fiba-app-backend.herokuapp.com';
    serverEnv.APP_BACKEND_FALLBACK = 'https://play.fiba3x3.com/api';
    break;
  default:
    break;
}
