import {fromJS, Map} from 'immutable';
import {mapValues} from 'lodash';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {
  Qualifications,
  QualificationsLike,
} from 'fiba/common/core/models/api/qualifications/Qualifications';

export interface State extends Map<string, Map<string, Qualifications>> {
  /*
    The top level is not really a Map, rather a Record.
    Here is the full structure for when we type this:

    {
      eventQualifications: Map<EventId, Qualifications>,
      __meta: {
        eventQualifications: MetaStore<TourId>;
      }
    }
  */
}

//
// Reducers
const reducers = {
  //
  // EventQualifications
  loadEventQualifications: (state: State, tourId: string, eventId: string): State =>
    state.updateIn(['__meta', 'eventQualifications', eventId], setMeta.isLoading),

  loadEventQualificationsSuccess: (
    state: State,
    eventId: string,
    qualifications: QualificationsLike,
  ): State =>
    state
      .setIn(['eventQualifications', eventId], Qualifications.fromJS(qualifications))
      .updateIn(['__meta', 'eventQualifications', eventId], setMeta.isLoaded),

  loadEventQualificationsError: (state: State, eventId: string, error): State =>
    state.updateIn(['__meta', 'eventQualifications', eventId], setMeta.isError),
};

export const reducer = createReducer<State>(__filename, fromJS({}), reducers);

//
// Actions
const actions = createActions(__filename, reducers, {
  loadEventQualifications: (tourId: string, eventId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadQualificationsByTourAndEvent(tourId, eventId)
      .then(res => actions.loadEventQualificationsSuccess(eventId, res.data))
      .catch(rethrow(err => actions.loadEventQualificationsError(eventId, err))),
});

export default actions;

/** JSON -> StatsStoreState
  eventQualifications: {
    1234: QualificationsLike -> Qualifications
  } -> Map<EventId, Qualifications>;

  __meta: {
    eventQualifications: {
      1234: MetaState
    } -> MetaStore<EventId>;
  }

  --> StatsStoreState
*/
export function deserializeState(state): State {
  const {__meta, eventQualifications} = state;

  return fromJS({
    // NOTE: Make sure `__meta` isn't undefined, in which case `updateIn` in `__meta` will fail
    __meta: __meta || {},
    eventQualifications: mapValues(eventQualifications, Qualifications.fromJS),
  });
}
