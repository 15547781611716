import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {CompetitionCalendar} from 'fiba/wt/ui/competitionCalendar/CompetitionCalendar';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

interface Props {
  season: string;
  tourId: string;
}

export const TourCalendarPage: React.FunctionComponent<Props> = ({season, tourId}) => {
  return (
    <Spacer pa={['2', '2', '0']}>
      <PageHeading containerProps={{mh: '1'}} text="Calendar" />
      <CompetitionCalendar
        hideFilter
        type="calendar"
        hideSeasonSelector
        displayMode="stripped"
        defaultFilter="ProCircuit"
        id="competitionCalendarEmbed"
        defaultSeason={parseInt(season)}
        iframeTitle="Competition Calendar"
      />
    </Spacer>
  );
};
