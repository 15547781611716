import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

interface Props {
  tourId: string;
  season: string;
  eventId: string;
}

export const EventAwardsPage: React.FunctionComponent<Props> = ({eventId}) => {
  return (
    <Spacer ph={['3', '3', '1']}>
      <section>
        <EventPageHeading eventId={eventId} page="Awards" />
        <Section>
          <ContentfulArticle
            contentPath={`events/${eventId}`}
            articleType={ArticleTypes.EventAwards}
          />
        </Section>
      </section>
    </Spacer>
  );
};
