import React from 'react';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {StatsSummaryIcon, STATS_SUMMARY_ICON} from 'fiba/wt/ui/svg/svg';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';

const TITLES = {
  player: 'Players',
  team: 'Teams',
  singleGame: 'Single game stats',
};

export function CategoryHeader({icon}: {icon: STATS_SUMMARY_ICON}) {
  return (
    <Flex alignItems={'center'}>
      <StatsSummaryIcon icon={icon} purpose={'decorative'} />
      <SubHeading fontSize={'5'} fontWeight={'4'} pl={'2'}>
        {TITLES[icon]}
      </SubHeading>
    </Flex>
  );
}
