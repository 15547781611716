import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Loading} from 'fiba/wt/ui/loading/Loading';
// @ts-ignore Import and re-exporting type
import {StandingsTable, StandingsTableColumns} from 'fiba/wt/ui/standingsTable/StandingsTable';
import {Category} from 'fiba/common/core/models/api/events/Category';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';

export type StandingsTableColumns = StandingsTableColumns;

const defaultColumns: StandingsTableColumns[] = [
  'rank',
  'name',
  'gamesPlayed',
  'gamesWon',
  'winRatio',
  'averagePoints',
  'totalPoints',
];

export interface ICategoryStandings {
  categoryId: string;
  columns?: StandingsTableColumns[];
  showCategoryName?: boolean;
  createTeamHref?: ResourceLinkProvider;
  cellSpacing?: StyleProp<'pv'>;
}

export interface ICategoryStandingsState {
  category?: Category;
  standings: List<ResultsStanding>;
  metaPaths: string[];
}

export const mapStateToProps = (
  state,
  {categoryId, columns}: ICategoryStandings,
): ICategoryStandingsState => {
  const standings = state.getIn(['results', categoryId, 'standings'], List());

  return {
    category: state.getIn(['categories', categoryId]),
    standings,
    metaPaths: [`categories/__meta/${categoryId}`, `results/__meta/${categoryId}`],
  };
};

type _CategoryStandings = ICategoryStandings & ICategoryStandingsState;

const _CategoryStandings: React.FunctionComponent<_CategoryStandings> = ({
  category,
  categoryId,
  standings,
  columns = defaultColumns,
  showCategoryName = true,
  createTeamHref,
  metaPaths,
  cellSpacing,
}) => {
  /** Unique caption if one table per categoryId */
  const captionId = `category-standings-${categoryId}`;
  /** Visually hide the category name if specified, but keep accessible */
  const caption =
    !!category &&
    (showCategoryName ? (
      <SubHeading>{category.name}</SubHeading>
    ) : (
      <VisuallyHidden>
        <SubHeading>{category.name}</SubHeading>
      </VisuallyHidden>
    ));
  return (
    <div>
      <Loading metaPaths={metaPaths}>
        {() => (
          <React.Fragment>
            <StandingsTable
              captionId={captionId}
              caption={caption}
              cellSpacing={cellSpacing}
              standings={standings}
              showQualifierDrawDropouts
              showOnlyFinalStandings={false}
              columns={columns || defaultColumns}
              createTeamHref={createTeamHref}
            />
          </React.Fragment>
        )}
      </Loading>
    </div>
  );
};

export const CategoryStandings = connect<ICategoryStandingsState, {}, ICategoryStandings>(
  mapStateToProps,
)(_CategoryStandings);
