import {fromJS, Map, List} from 'immutable';
import nationalitiesTemp from 'fiba/common/config/nationalities.json';

const nationalities = nationalitiesTemp.nationalities;

const countries = fromJS(nationalities);
const countriesByIoc = Map(countries.map(country => List([country.get('iocCode'), country])));

export const UNDEFINED_IOC = 'ZZZ';
export const UNDEFINED_ISO2 = 'ZZZ';

export const getCountries = () => countries;

export const getLocationByIocCode = (iocCode: string) =>
  countries.find(country => country.get('iocCode') === iocCode);

export const getLocationByIso2Code = (iso2Code: string) =>
  countries.find(country => country.get('iso2Code') === iso2Code);

export const iocCodeToiso2Code = (iocCode: string) => {
  const match = getLocationByIocCode(iocCode);

  return match ? match.get('iso2Code') : UNDEFINED_ISO2;
};

export const iso2CodeToIocCode = (iso2Code: string) => {
  const match = getLocationByIso2Code(iso2Code);

  return match ? match.get('iocCode') : UNDEFINED_IOC;
};

export const getCountryIocCode = (countryName: string) => {
  const match = countries.find(country => country.get('name') === countryName);
  return match ? match.get('iocCode') : UNDEFINED_IOC;
};

const countryAutocompletions = countries.map(country =>
  country.update('name', (name: string) => name.toLowerCase()),
);

export const autocompleteCountry = (text: string) => {
  const _text = (text || '').toLowerCase();
  return countryAutocompletions
    .filter(country => country.get('name').indexOf(_text) >= 0)
    .map(country => countriesByIoc.get(country.get('iocCode')));
};

export const getCountryByIocCode = (iocCode: string, defaultValue = 'N/A') => {
  const match = getLocationByIocCode(iocCode);
  return match ? match.get('name') : defaultValue;
};

export const getCountryByIso2Code = (iso2Code: string, defaultValue = 'N/A') => {
  const match = getLocationByIso2Code(iso2Code);
  return match ? match.get('name') : defaultValue;
};
