import React, {useContext} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {TourFastestGames} from 'fiba/wt/ui/fastestGames/TourFastestGames';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {FastestGamesSponsorLogo} from 'fiba/wt/ui/svg/FastestGamesSponsorLogo';

export interface ITourStatisticsFastestGames {
  tourId: string;
  season: string;
}

//https://github.com/futurice/fiba-3x3-play/issues/2601
const SHOW_FASTEST_GAMES_SPONSOR = false;

export const TourStatisticsFastestGames: React.FunctionComponent<ITourStatisticsFastestGames> = props => {
  const siteConfig = useContext(SiteConfigContext);
  return (
    <Spacer vSpace="4" debugClassName="TourStatisticsFastestGames">
      {SHOW_FASTEST_GAMES_SPONSOR && siteConfig.features.showFastestGamesSponsor ? (
        <Box textAlign="center">
          {/* NOTE: P and BlockText are both not fully centerable, hence regular p here */}
          <p>Presented by</p>
          <Box pa="3">
            <FastestGamesSponsorLogo width="225px" />
          </Box>
        </Box>
      ) : null}

      <TourFastestGames {...props} />
    </Spacer>
  );
};
