/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {TourResultsStop} from './TourResultsStop';

export const defaultTourResultsPlayerStanding = {
  playerId: null,
  playerFirstName: null,
  playerLastName: null,
  playerRankingPoints: null,
  playerRank: null,
  playerTeamNames: null,
  totalStandingPoints: null,
  totalSeedingPoints: null,
  totalPoints: null,
  averagePoints: null,
  gamesPlayed: null,
  gamesWon: null,
  winRatio: null,
  qualification: null,
  tourStops: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TourResultsPlayerStandingLike = {
  [K in keyof typeof defaultTourResultsPlayerStanding]?: TourResultsPlayerStanding[K];
};

export class TourResultsPlayerStanding extends Record(defaultTourResultsPlayerStanding) {
  playerId?: string;
  playerFirstName?: string;
  playerLastName?: string;
  playerRankingPoints?: number;
  playerRank?: number;
  playerTeamNames?: List<string>;
  totalStandingPoints?: number;
  totalSeedingPoints?: number;
  totalPoints?: number;
  averagePoints?: number;
  gamesPlayed?: number;
  gamesWon?: number;
  winRatio?: number;
  qualification?: string;
  tourStops?: List<TourResultsStop>;
  static fromJS(o: TourResultsPlayerStandingLike): TourResultsPlayerStanding {
    return (
      o &&
      new TourResultsPlayerStanding({
        playerId: o.playerId,
        playerFirstName: o.playerFirstName,
        playerLastName: o.playerLastName,
        playerRankingPoints: o.playerRankingPoints,
        playerRank: o.playerRank,
        playerTeamNames: o.playerTeamNames && List(o.playerTeamNames),
        totalStandingPoints: o.totalStandingPoints,
        totalSeedingPoints: o.totalSeedingPoints,
        totalPoints: o.totalPoints,
        averagePoints: o.averagePoints,
        gamesPlayed: o.gamesPlayed,
        gamesWon: o.gamesWon,
        winRatio: o.winRatio,
        qualification: o.qualification,
        tourStops: o.tourStops && List(o.tourStops).map(i => i && TourResultsStop.fromJS(i)),
      })
    );
  }
}

export default TourResultsPlayerStanding;
