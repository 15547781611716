/**
 * A tagged template literal function for generating URL-compatible substitutions
 *
 * @example
 * let tourId = 'hello world'
 * url`/v2/api/tour/${tourId}`)
 * // '/v2/api/tours/hello%20world'
 */
function url(literals: TemplateStringsArray, ...placeholders: string[]) {
  return placeholders
    .map((placeholder, i) => [literals[i], encodeURIComponent(placeholder)].join(''))
    .concat([literals[literals.length - 1]])
    .join('');
}

export default url;
