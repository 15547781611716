import React from 'react';
import {EventNews} from 'fiba/wt/ui/eventNews/EventNews';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {NoticeText} from 'fiba/wt/ui/NoticeText/NoticeText';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

export interface EventNewsPageProps {
  eventId: string;
  season: string;
}

// TODO: Pagination? It would help with store size as well
export const EventNewsPage: React.FunctionComponent<EventNewsPageProps> = ({
  eventId,
  season,
}: EventNewsPageProps) => (
  <Spacer ph={['3', '3', '1']}>
    <section>
      <EventPageHeading eventId={eventId} page="News" />

      <Section>
        <EventNews
          season={season}
          eventId={eventId}
          emptyStateContent={
            <NoticeText mt="4">
              <p>There are no news yet.</p>
            </NoticeText>
          }
        />
      </Section>
    </section>
  </Spacer>
);
