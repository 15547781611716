import IntlMessageFormat from 'intl-messageformat';
import moment from 'moment';
import {LocalizerService} from 'fiba/common/services/services';

// TODO: Use named exports and import selectively
const Dates = {
  getArrayOfMonths(localizer: LocalizerService): string[] {
    // We only use the language from the service, and skip the MessageFormat parsing
    // This operation can be expensive, so going for the Date directly seems better
    const language = localizer.lang;
    const months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => {
      const dateobj = new Date(1970, month, 15);
      return dateobj.toLocaleString(language, {month: 'long'});
    });
    return months;
  },

  /** Translate from 0..11 monthIndex to 1..12.
   *
   * Moment.month, as well as the Date constructor take a "month index", i.e. a 0..11 representation.
   * However, the server accepts a 1..12 range in requests.
   * Thus, we need to:
   *  - When sending (form <option> value), have 1..12
   *  - When displaying, map the monthIndex to 1..12, to set as `defaultValue` in <select>
   * To do this, we translate from index to absolute month.
   *
   * @note Yes, this is just an addition, but it is sneaky :)
   */
  monthIndexToAbsolute(monthIndex: number) {
    return monthIndex + 1;
  },

  formatDateRange(
    localizer: LocalizerService,
    startDateStr: string,
    endDateStr: string,
    format = 'medium',
  ) {
    if (!startDateStr && !endDateStr) {
      return '';
    }

    // Make sure both startDateStr and endDateStr are defined
    if (!startDateStr) {
      startDateStr = endDateStr;
    }
    if (!endDateStr) {
      endDateStr = startDateStr;
    }

    // Make sure the dates are actually just dates for string comparison
    startDateStr = startDateStr.slice(0, 10);
    endDateStr = endDateStr.slice(0, 10);

    const formatUTC = IntlMessageFormat.formats['date'][format] || {};
    formatUTC.timeZone = 'UTC';

    if (startDateStr === endDateStr) {
      return Dates.formatDate(localizer, startDateStr, format);
    } else {
      /* TODO: Better logic here, collapse same day, same month or same year */
      const start = Dates.formatDate(localizer, startDateStr, format);
      const end = Dates.formatDate(localizer, endDateStr, format);
      return `${start} - ${end}`;
    }
  },

  formatDate(localizer: LocalizerService, dateStr: string, format = 'medium') {
    if (!dateStr) {
      return '';
    }

    // Make sure the dates are actually just dates for string comparison
    dateStr = dateStr.slice(0, 10);

    /* We need to use UTC here and also explicitly set timeZone to UTC when
     * formatting the date. This is because it turns out that even though
     * Intl.DateTimeFormat says it uses "the runtime's default time zone", it
     * turns out that it actually means the runtime's default CURRENT time zone.
     * Meaning in spring if it's winter time in Finland and we have a Date
     * object for a date in the summer with time 00:00:00, it is formatted as
     * the day before at 23:00:00 according to the current offset, instead of
     * the offset on that day. In my opinion this is a major flaw in the API,
     * but we can work around it reliably by using UTC in parsing and
     * formatting. */
    const date = moment.utc(dateStr);

    const formatUTC = IntlMessageFormat.formats['date'][format] || {};
    formatUTC.timeZone = 'UTC';

    const options = {date: {formatUTC}};

    return localizer.format(`{date, date, formatUTC}`, {date}, options);
  },
};

export default Dates;
