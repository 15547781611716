/*
  This implements horizontal scrollable game card composer.

  +-------------------------------------------------------------------+
  | | Team A     10 | Team A     10 | Team A     10 | Team A     10 | |
  |<| Team B      8 | Team B      8 | Team B      8 | Team B      8 |>|
  | |               |               |               |               | |
  +-------------------------------------------------------------------+
*/
import React from 'react';
import {List, Map} from 'immutable';
import {ChevronLeft, ChevronRight} from '@fpapado/react-feather';
import cx from 'classnames';
import {connect} from 'fiba/common/utils/reactUtils';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {
  IGameCardComposer,
  findInitialGameIndex,
  mergeResultsWithScores,
} from 'fiba/wt/ui/gameCardWidget/gameCardUtils';
import {GameCard} from 'fiba/wt/ui/gameCardWidget/GameCard';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {ScheduleTimezoneSwitcher} from 'fiba/wt/ui/schedule/ScheduleTimezoneSwitcher';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';

// This is derived from `w5` class in gamecards, which according
// to current scale is 16rem, @see fiba/wt/ui/stylingAPI/widths.scss
const GAME_CARD_WIDTH_REMS = 16;

interface ReduxProps {
  data: RemoteData.WebData<{
    games: List<ResultsGameSummary>;
    initialGameIndex: number;
    isScheduleEventTime: boolean;
    showCategory: boolean;
  }>;
}

type Props = IGameCardComposer & ReduxProps;

// TODO: Make this use RemoteData
const mapStateToProps = (state, {eventId, now}: IGameCardComposer): ReduxProps => {
  // NOTE: We assume that if games summary is loaded in the event's meta,
  // then they are also loaded in the gamesStore. We should verify this
  // once gameStore is RemoteData-based
  const resultGamesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'games', 'summary'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    gameIds =>
      gameIds.map(gameId => state.getIn(['games', 'summary', gameId])) as List<ResultsGameSummary>,
  );

  // NOTE: This is not RemoteData based atm!
  const ongoingGames = state.getIn(['events', '__meta', eventId, 'ongoingGames']) || Map();

  // Merge the two games data sources, and derive more stuff from them
  const data = RemoteData.map(resultGamesData, resultGames => {
    const games = mergeResultsWithScores(resultGames, ongoingGames);

    const isMultiCategory =
      games
        .map(game => game.categoryId)
        .toSet()
        .toArray().length > 1;

    const initialGameIndex = findInitialGameIndex(games, now);
    const isScheduleEventTime: boolean = state.getIn(['uiConfig', 'isScheduleEventTime'], true);

    return {
      games,
      initialGameIndex,
      isScheduleEventTime,
      showCategory: isMultiCategory,
    };
  });

  return {data};
};

const buttonCls =
  'absolute top-0 w2 ht-100 bg-dark-30 silver-10 br0 ba b--dark-30 enhanced-outline outline--inner';

class GameCardHorizontalComposerImpl extends React.Component<Props> {
  scrollAreaRef = React.createRef<HTMLDivElement>();
  remRef = React.createRef<HTMLDivElement>();

  onClickScrollPage = (direction: -1 | 1) => (ev: React.MouseEvent<HTMLButtonElement>) => {
    const scrollArea = this.scrollAreaRef.current;
    const rem = this.remRef.current;

    if (!scrollArea || !rem) {
      // eslint-disable-next-line no-console
      console.warn('GameCardHorizontalComposer: Invalid ref');
      return;
    }

    const {clientWidth, scrollWidth, scrollLeft} = scrollArea;
    // Calculate the width for a gamecard, which is `w5` times the size of single `rem` unit
    const gameCardWidth = rem.scrollWidth * GAME_CARD_WIDTH_REMS;
    // Always round down to count cards that fit in as fully visible
    const numCardsInPage = Math.floor(clientWidth / gameCardWidth);

    // Calculate offset from leftmost edge of leftmost fully visible card to the left edge of scrolling area
    const remainder = scrollLeft % gameCardWidth;
    // FIXME: Stepping once right and once left wont get you back to the same point
    // but one card to the right, need to account for something when stepping left
    // but this is just fine for now
    const offset =
      direction > 0
        ? // Step full page to the right sans the offset calculated above
          numCardsInPage * gameCardWidth - remainder
        : // Step full page to the left with the offset calculated above, sans 1 card width
          -(numCardsInPage - 1) * gameCardWidth - remainder;

    scrollArea.scrollLeft = Math.max(0, Math.min(scrollWidth - clientWidth, scrollLeft + offset));
  };

  scrollToGameIndex(gameIndex: number) {
    const scrollArea = this.scrollAreaRef.current;
    const rem = this.remRef.current;

    if (!scrollArea || !rem) {
      // eslint-disable-next-line no-console
      console.warn('GameCardHorizontalComposer: Invalid ref');
      return;
    }

    const {clientWidth, scrollWidth} = scrollArea;
    const gameCardWidth = rem.scrollWidth * GAME_CARD_WIDTH_REMS;
    const offset = gameIndex * gameCardWidth;

    // Scroll the view so that the card of `initialGameIndex` sits on the left edge
    scrollArea.scrollLeft = Math.max(0, Math.min(scrollWidth - clientWidth, offset));
  }

  componentDidMount() {
    RemoteData.match(this.props.data, {
      Success: ({initialGameIndex}) => this.scrollToGameIndex(initialGameIndex),
      default: () => null,
    });
  }

  componentDidUpdate(prevProps: Props) {
    RemoteData.match(this.props.data, {
      Success: ({initialGameIndex}) => {
        RemoteData.match(prevProps.data, {
          Success: ({initialGameIndex: prevInitialGameIndex}) => {
            if (prevInitialGameIndex !== initialGameIndex) {
              this.scrollToGameIndex(initialGameIndex);
            }
          },
          default: () => null,
        });
      },
      default: () => null,
    });
  }

  render() {
    const {data, now, createTeamHref, createGameHref} = this.props;

    return (
      <div className="GameCardHorizontalComposer">
        <ViewWebData data={data}>
          {({games, isScheduleEventTime, showCategory}) => (
            <React.Fragment>
              <Flex justifyContent="end" mb="1">
                <ScheduleTimezoneSwitcher games={games} hideLegend={true} hideGroupStyle={true} />
              </Flex>
              <div className="relative ph4">
                <div
                  role="region"
                  aria-label="Game Standings cards"
                  // FIXME: Aria correctness after adding timezone switcher
                  tabIndex={0}
                  className="ba bw1 b--dark-30 enhanced-outline overflow-x-auto overflow-y-hidden"
                  ref={this.scrollAreaRef}
                  // TODO: consider aria-describedby and instructions
                  // @see https://s.codepen.io/heydon/debug/xPWOLp
                >
                  <ul className="flex">
                    {games.map(game => (
                      <li key={game.gameId} className="w5 ph1 flex-shrink-0 br0 bl br">
                        <GameCard
                          game={game}
                          now={now}
                          isScheduleEventTime={isScheduleEventTime}
                          createTeamHref={createTeamHref}
                          createGameHref={createGameHref}
                          showCategory={showCategory}
                        />
                      </li>
                    ))}
                  </ul>
                </div>

                <ul aria-label="Game Standings controls">
                  <li className="db">
                    <button
                      aria-label="previous"
                      className={cx(buttonCls, 'left-0')}
                      onClick={this.onClickScrollPage(-1)}
                    >
                      <ChevronLeft className="w1" purpose="decorative" />
                    </button>
                  </li>

                  <li className="db">
                    <button
                      aria-label="next"
                      className={cx(buttonCls, 'right-0')}
                      onClick={this.onClickScrollPage(1)}
                    >
                      <ChevronRight className="w1" purpose="decorative" />
                    </button>
                  </li>
                </ul>
              </div>

              {/* Need a sentinel marker to derive the pixel width of 1rem */}
              <div className="absolute vis-hidden w1 h1" ref={this.remRef} />
            </React.Fragment>
          )}
        </ViewWebData>
      </div>
    );
  }
}

export const GameCardHorizontalComposer = connect<ReduxProps, {}, IGameCardComposer>(
  mapStateToProps,
)(GameCardHorizontalComposerImpl);
