import {dangerousViewportValues} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {map, filter, join, reverse, isArray} from 'lodash';

// Same syntax as our StylingAPI
export type SizesSpec = Array<string | null> | string;

// Helper to generate sizes based on system media queries
export const generateSizes = (sizes: SizesSpec | string) =>
  isArray(sizes)
    ? join(
        reverse(
          filter(
            map(sizes, (v, i) => (v ? sizeDecl(v, i) : null)),
            a => !!a,
          ),
        ),
        ', ',
      )
    : sizes;

const sizeDecl = (size, mediaIndex) =>
  mediaIndex === 0 ? `${size}` : `(min-width: ${dangerousViewportValues[mediaIndex]}) ${size}`;

// TODO: make nicer with TS2.8 ? type operator
export type WidthHeight =
  | {
      width: number;
      height: number;
    }
  | {
      width?: number;
      height: number;
    }
  | {width: number; height?: number};

export const calculateDimensions = (
  {width, height}: WidthHeight,
  aspectRatio?: [number, number],
) => {
  // If both width and height are defined, do nothing
  if (!!width && !!height) {
    return {width, height};
  }

  // If height is defined, (but width isn't)
  if (!!height && !width) {
    // If we have an aspect ratio, constrain the width
    if (aspectRatio) {
      return {
        width: Math.round((height * aspectRatio[0]) / aspectRatio[1]),
        height,
      };
    }

    // If we don't have an aspect ratio, let the image be free
    return {
      height,
    };
  }

  if (!height && !!width) {
    // If we have an aspect ratio, constrain the height
    if (aspectRatio) {
      return {
        width,
        height: Math.round((width * aspectRatio[1]) / aspectRatio[0]),
      };
    }

    // If we don't have an aspect ratio, let the image be fre
    return {
      width,
    };
  }
};

interface generateParams {
  url: string;
  sizes?: number[];
  xSizes?: number[];
  format?: string;
  defaultSize: number;
}

// create a prefixed (and a bit hacky) image-set background image style to be used as an inline style
// has a normal image fall back as the support is still quite limited: https://caniuse.com/css-image-set
export const generateContentfulImageSetStyle = ({
  url,
  sizes,
  xSizes,
  format = 'webp',
  defaultSize,
}: generateParams) => {
  // Width based image-sets supported by firefox and chrome in future
  const wSrcSets = (sizes || [])
    .map(
      size =>
        // postfix the url with format and size, then add size as the width parameter
        // produces a singular image-set url like "url('image.url?fm=webp&w=1440') 1440w" as a result
        `url("${url}?fm=${format}&w=${size}") ${size}w`,
    )
    .join(', ');

  // Chrome supports only 1x, 2x, 3x etc size notation so supply these as separate sizes
  const xSrcSets = (xSizes || [])
    // produces a singular image-set url like "url('image.url?fm=webp&w=1440') 1x" as a result
    .map((size, index) => `url("${url}?fm=${format}&w=${size}") ${index + 1}x`)
    .join(', ');

  const webkitImageSet = xSrcSets ? `background-image: -webkit-image-set(${xSrcSets});` : '';
  const xImageSet = xSrcSets ? `background-image: image-set(${xSrcSets});` : '';
  const wImageSet = wSrcSets ? `background-image: image-set(${wSrcSets});` : '';

  // The hacky way of defining the url sets is needed as with react you can't supply multiple entries
  // per one css attribute and we need the prefix values in this case for chrome
  return {
    backgroundImage: `url("${url}?fm=webp&w=${defaultSize}")${
      (webkitImageSet || xImageSet || wImageSet) && ';'
    }${webkitImageSet}${xImageSet}${wImageSet}`,
  };
};
