/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultResultsTeam = {
  teamId: null,
  teamName: null,
  teamNameSuffix: null,
  teamNationality: null,
  teamScore: null,
  teamIsWinner: null,
  setupType: null,
  setupGameId: null,
  setupGameName: null,
  setupGameNumberInGroup: null,
  setupGroupId: null,
  setupGroupName: null,
  setupGroupPoolCode: null,
  setupRoundCode: null,
  setupRank: null,
  isTourTeam: null,
  isPlaceholder: null,
  teamInEventId: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type ResultsTeamLike = {
  [K in keyof typeof defaultResultsTeam]?: ResultsTeam[K];
};

export class ResultsTeam extends Record(defaultResultsTeam) {
  teamId?: string;
  teamName?: string;
  teamNameSuffix?: string;
  teamNationality?: string;
  teamScore?: number;
  teamIsWinner?: boolean;
  setupType?: string;
  setupGameId?: string;
  setupGameName?: string;
  setupGameNumberInGroup?: number;
  setupGroupId?: string;
  setupGroupName?: string;
  setupGroupPoolCode?: string;
  setupRoundCode?: string;
  setupRank?: number;
  isTourTeam?: boolean;
  isPlaceholder?: boolean;
  teamInEventId?: string;
  static fromJS(o: ResultsTeamLike): ResultsTeam {
    return (
      o &&
      new ResultsTeam({
        teamId: o.teamId,
        teamName: o.teamName,
        teamNameSuffix: o.teamNameSuffix,
        teamNationality: o.teamNationality,
        teamScore: o.teamScore,
        teamIsWinner: o.teamIsWinner,
        setupType: o.setupType,
        setupGameId: o.setupGameId,
        setupGameName: o.setupGameName,
        setupGameNumberInGroup: o.setupGameNumberInGroup,
        setupGroupId: o.setupGroupId,
        setupGroupName: o.setupGroupName,
        setupGroupPoolCode: o.setupGroupPoolCode,
        setupRoundCode: o.setupRoundCode,
        setupRank: o.setupRank,
        isTourTeam: o.isTourTeam,
        isPlaceholder: o.isPlaceholder,
        teamInEventId: o.teamInEventId,
      })
    );
  }
}

export default ResultsTeam;
