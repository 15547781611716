import React, {useContext} from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {TeamStatistics} from '../common/statistics/TeamStatistics';
import {eventTeamLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Team} from 'fiba/common/core/models/api/teams/Team';

export interface Props {
  season: string;
  eventId: string;
  eventType: EventType;
  genderTab?: CategoryGenderTab;
}

export const EventStatisticsTeamsTab: React.FC<Props> = ({
  season,
  eventId,
  eventType,
  genderTab,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <Spacer vSpace="4">
      <TeamStatistics
        showEventsPlayed={false}
        // In events, we always render a link to the team
        renderTeamName={teamStats => {
          const teamLike = Team.fromJS({
            id: teamStats.teamId,
            name: teamStats.teamName,
            nameSuffix: teamStats.teamNameSuffix,
          });
          return (
            <StyledLink
              href={eventTeamLinkProvider(
                seasonConfig,
                season,
                eventId,
                eventType,
              )(teamStats.teamInEventId)}
            >
              <TeamNameAssembly team={teamLike} isSuffixVisible isIocVisible={false} />
            </StyledLink>
          );
        }}
        tourOrEventId={eventId}
        genderTab={genderTab}
      />
    </Spacer>
  );
};
