import React from 'react';
import {EventPhotoGallery} from 'fiba/wt/ui/eventPhotoGallery/EventPhotoGallery';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {NoticeText} from 'fiba/wt/ui/NoticeText/NoticeText';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import {Section} from 'fiba/wt/ui/heading/Heading';

export interface EventPhotosPageProps {
  season: string;
  eventId: string;
}

export const EventPhotosPage: React.FunctionComponent<EventPhotosPageProps> = ({
  season,
  eventId,
}) => (
  <Spacer ph={['3', '3', '1']}>
    <section>
      <EventPageHeading eventId={eventId} page="Photos" />
      <Section>
        <EventPhotoGallery
          season={season}
          eventId={eventId}
          emptyStateContent={
            <NoticeText mt="4">
              The photos for this event will appear here once they are published.
            </NoticeText>
          }
        />
      </Section>
    </section>
  </Spacer>
);
