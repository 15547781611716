import React from 'react';
import {List} from 'immutable';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {Box} from 'fiba/wt/ui/box/Box';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {BracketsView} from 'fiba/wt/ui/eventStandingsPage/BracketsView';

interface IBracketsTab {
  season: string;
  eventId: string;
  knockoutBrackets: List<List<ResultsGameSummary>>;
}

export const BracketsTab: React.FunctionComponent<IBracketsTab> = ({knockoutBrackets}) => (
  <Box debugClassName="BracketsTab">
    <Heading pb="3" fontSize="3">
      Brackets
    </Heading>

    <Section>
      <BracketsView knockoutBrackets={knockoutBrackets} />
    </Section>
  </Box>
);
