import React, {Reducer, useReducer, useEffect, useMemo, useCallback} from 'react';
import {ChevronLeft, ChevronRight, X} from '@fpapado/react-feather';
// TODO: consider react-a11y-dialog, or a more minimal implementation of it on our end, with hooks
import AriaModal from 'react-aria-modal';

// LIGHTBOX
// The lightbox is a modal + sliding gallery inside of it
type AriaModalProps = {
  onClose: () => void;
  /** We need this so that we can render as a sibling to the top-level node, with createPortal.
   * This makes modals great!
   */
  getApplicationNode: () => HTMLElement;
  isOpen: boolean;
};

type LightboxProps = AriaModalProps & GalleryProps;

/**
 * The lightbox is a modal + sliding gallery inside of it
 *
 * Expected behaviour:
 *  - when clicking to open the lightbox, move focus to the first interactive element on it
 *  - The user can navigate by tapping, or pressing Enter or Space on the arrows,
 *    or by pressing the <- and -> keyboard buttons.
 *  - When clicking to close the lightbox, focus is returned to the item that opened it
 */
export const Lightbox: React.FunctionComponent<LightboxProps> = ({
  children,
  onClose,
  getApplicationNode,
  initialSlide,
  isOpen,
}) => {
  return (
    <AriaModal
      titleText="Gallery"
      mounted={isOpen}
      onExit={onClose}
      getApplicationNode={getApplicationNode}
      underlayStyle={{background: 'rgba(0, 0, 0, 0.8) none repeat scroll 0% 0%'}}
    >
      <SlideGallery onClose={onClose} initialSlide={initialSlide}>
        {children}
      </SlideGallery>
    </AriaModal>
  );
};

// SLIDE GALLERY
// A SlideGallery takes a list of images (or children nodes), and offers controls to advance each one

export type GalleryProps = {
  initialSlide?: number;
  onClose: () => void;
};

type GalleryState = {
  currentSlide: number;
};

type GalleryAction = 'Next' | 'Previous';

/** Initialises the reducer with the total slides */
const getReducer = (totalSlides: number) => {
  const reducer: Reducer<GalleryState, GalleryAction> = (prevState, action) => {
    switch (action) {
      case 'Next':
        if (prevState.currentSlide === totalSlides - 1) {
          return {currentSlide: 0};
        }
        return {currentSlide: prevState.currentSlide + 1};

      case 'Previous':
        if (prevState.currentSlide === 0) {
          return {currentSlide: totalSlides - 1};
        }
        return {currentSlide: prevState.currentSlide - 1};
    }
  };
  return reducer;
};

/** Initialises the state with the initial slide */
function init(initialSlide: number): GalleryState {
  return {currentSlide: initialSlide};
}

const SlideGallery: React.FunctionComponent<GalleryProps> = ({
  initialSlide = 0,
  onClose,
  children,
}) => {
  const totalSlides = useMemo(() => React.Children.count(children), [children]);
  const [state, dispatch] = useReducer(getReducer(totalSlides), initialSlide, init);

  // Memoize the handleKeyDown so we can remove the listener correctly
  const handleKeyDown = useCallback(ev => {
    switch (ev.key) {
      case 'ArrowLeft':
        ev.preventDefault();
        dispatch('Previous');
        break;
      case 'ArrowRight':
        ev.preventDefault();
        dispatch('Next');
        break;
    }
  }, []);

  // Add a left/right listener on mount
  // Note that we add these to the document. This is normally a bad idea, but in this
  // case we are rendering in a root and "taking over" while the Lightbox is open.
  // An alrenative would be to pass a modalRootRef, and attach to that, but it
  // was undefined in many of my tests. containerRef also works, and is safe, but
  // it does not account for clicking on the contents and then using the arrows.
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    // Remove the listener on unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="Lightbox">
      <div className="Lightbox-Inner">
        <button onClick={onClose} className="button-reset focus-shadow fullwhite Lightbox-Close">
          <X purpose="standalone" aria-label="Close modal" width="32" height="32" />
        </button>
        {/* TODO: Add a loading spinner (such as the one from tenon-ui)? But when do we hide it? */}
        <div className="Lightbox-Panel">{React.Children.toArray(children)[state.currentSlide]}</div>
        <ul aria-label="Gallery controls" className="Lightbox-Controls">
          <li>
            <button
              onClick={() => dispatch('Previous')}
              className="button-reset focus-shadow fullwhite Lightbox-Left"
            >
              <ChevronLeft
                purpose="standalone"
                aria-label="Previous image"
                width="32"
                height="32"
              />
            </button>
          </li>
          <li>
            <button
              onClick={() => dispatch('Next')}
              className="button-reset focus-shadow fullwhite Lightbox-Right"
            >
              <ChevronRight purpose="standalone" aria-label="Next image" width="32" height="32" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};
