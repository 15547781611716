import React from 'react';
import {NewsItemPreview} from 'fiba/common/core/models/api/feed/NewsItemPreview';
import {ImgixImageProps} from 'fiba/wt/ui/imgix/ImgixImage';
import {LazyImgixImage} from 'fiba/wt/ui/imgix/LazyImgixImage';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {P} from 'fiba/wt/ui/text/Text';
import {PublishedDate} from 'fiba/wt/ui/publishedDate/PublishedDate';
import {Link} from 'fiba/wt/ui/link/Link';

type ForwardImageProps = Partial<Pick<ImgixImageProps, 'sizes'>>;

export interface NewsListItemProps extends ForwardImageProps {
  newsItem: NewsItemPreview;
  baseUrl: string;
}

export const NewsListItem: React.FunctionComponent<NewsListItemProps> = ({
  newsItem,
  baseUrl,
  sizes,
}) => {
  return (
    <Link
      colorTheme="dark"
      href={`${baseUrl}/${newsItem.slug}`}
      extraClassName="card-top-level flex ht-100 pa3 bg-fullwhite br1 shadow-1"
    >
      {/* Place the description after the image in the display; helps screen readers */}
      <Flex flexDirection="column" flex="auto" flexOrder="1" measure="normal">
        <Heading mb="2" fontSize="5">
          {newsItem.title}
        </Heading>
        <P mt="auto" mb="0" fontSize="6">
          <PublishedDate date={newsItem.date} />
        </P>
      </Flex>

      {/* NOTE: "sizes" is w4, i.e. 8rem. Change sizes if width changes! */}
      <Box mr="2" width="4" flexShrink="0" flexOrder="0">
        {/* The Image's alt is "" on purpose, to mark it as decorative, in
         * absence of a more informative one that differs from the news title.
         */}
        <LazyImgixImage
          baseUrl={newsItem.imageBaseUrl}
          aspectRatio={[6, 4]}
          sizes={sizes ? sizes : '8rem'}
          alt=""
        />
      </Box>
    </Link>
  );
};
