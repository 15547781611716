import React, {useContext} from 'react';
import {List} from 'immutable';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {IColumnSpec, DataTable} from 'fiba/wt/ui/dataTable/DataTable';
import {LocalizerService, ServicesContext} from 'fiba/common/services/services';
import {TableLayoutStyleProps} from 'fiba/wt/ui/table/Table';
import {Link} from 'fiba/wt/ui/link/Link';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {getHumanQualificationType} from 'fiba/wt/utils/results/resultsUtils';

interface Props {
  columns: Columns[];
  standings: List<ResultsStanding>;
  // Pools is needed to collect the appropriate pool for each standing
  poolGroups: List<ResultsGroup>;
  qualifyingDrawGroups: List<ResultsGroup>;
  captionId: string;
  // NOTE: if caption is not provided, make sure the table is preceded by a heading
  caption?: string | React.ReactNode;
  createTeamHref?: ResourceLinkProvider;
  cellSpacing?: StyleProp<'pv'>;
}

export type Columns = 'seed' | 'name' | 'rankingPoints' | 'pool' | 'qualificationType';

type SeedingsColumnSpec = IColumnSpec<ResultsStanding>;

const tdStyle: TableLayoutStyleProps = {
  fontWeight: '4',
};

function findPoolName(standing: ResultsStanding, pools: List<ResultsGroup>) {
  if (!standing || !standing.teamId) {
    return '';
  }

  const {teamId} = standing;
  const matchingPool = pools.find(
    pool =>
      pool.groupStandings &&
      pool.groupStandings.some(groupStanding => groupStanding.teamId === teamId),
  );

  return matchingPool ? matchingPool.groupName : '';
}

interface IGetSeedingsColumnsSpec {
  pools: List<ResultsGroup>;
  createTeamHref?: ResourceLinkProvider;
  localizer: LocalizerService;
  cellSpacing?: StyleProp<'pv'>;
}

const getSeedingsColumnsSpec = ({
  pools,
  createTeamHref,
  localizer,
  cellSpacing,
}: IGetSeedingsColumnsSpec): Record<Columns, SeedingsColumnSpec> => ({
  seed: {
    name: 'Seed',
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})} extraClassName="column-fit">
        {rowData.teamSeed != null ? rowData.teamSeed : ''}
      </Td>
    ),
  },
  name: {
    name: 'Name',
    dataType: 'text',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {createTeamHref && rowData.teamId ? (
          <Link colorTheme="dark" href={createTeamHref(rowData.teamId)}>
            <TeamNameAssembly standing={rowData} isSuffixVisible />
          </Link>
        ) : (
          <TeamNameAssembly standing={rowData} isSuffixVisible />
        )}
      </Td>
    ),
  },
  rankingPoints: {
    name: 'Ranking Points', // TODO: "Ranking points (as of ...)"
    dataType: 'number',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {rowData.seedingPoints != null
          ? localizer.format('{points, number}', {points: rowData.seedingPoints})
          : ''}
      </Td>
    ),
  },
  pool: {
    name: 'Pool',
    dataType: 'text',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>{findPoolName(rowData, pools)}</Td>
    ),
  },
  qualificationType: {
    name: 'Qualified',
    dataType: 'text',
    renderColumn: ({rowData, columnIndex, Td, getTdProps}) => (
      <Td {...getTdProps({...tdStyle, pv: cellSpacing})}>
        {getHumanQualificationType(rowData.qualificationType) || ''}
      </Td>
    ),
  },
});

// Specialisation

function sortSeedings(standings: List<ResultsStanding>) {
  // From spec:
  // Note that the Pool and Seeding information will only be available when the teams are seeded.
  // Prior to that the aforementioned columns shall be shown but empty (empty, no “-“, or N/A)
  // and teams are to be sorted based on ranking points.

  const areTeamsSeeded = standings.every(standing => standing.teamSeed != null);
  const comparatorValueMapper = areTeamsSeeded
    ? (standing: ResultsStanding) => standing.teamSeed || Infinity
    : (standing: ResultsStanding) => -standing.seedingPoints || Infinity;
  return standings.sortBy(comparatorValueMapper).toList();
}

export const SeedingsTable: React.FunctionComponent<Props> = ({
  columns,
  standings,
  poolGroups,
  qualifyingDrawGroups,
  caption,
  captionId,
  createTeamHref,
  cellSpacing = '3',
}) => {
  const {localizer} = useContext(ServicesContext);
  return (
    <DataTable<Columns, ResultsStanding>
      captionId={captionId}
      columnsSpec={getSeedingsColumnsSpec({
        // NOTE: Put poolGroups before qualifyingDrawGroups, so that teams that
        // qualified up to regular pools will find their corresponding regular
        // pool before the qd. Also this is why we take both poolGroups and
        // qualifyingDrawGroups as separate lists as props so that we can affirm
        // that we do the right thing here.
        pools: poolGroups.concat(qualifyingDrawGroups) as List<ResultsGroup>,
        localizer,
        createTeamHref,
        cellSpacing,
      })}
      caption={caption}
      columns={columns}
      rows={sortSeedings(standings)}
      headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
      headerExtraClassName="dark-20"
      rowExtraClassName="SeedingsTable-TableRow striped--light-even bb bw1 b--silver-20"
    />
  );
};
