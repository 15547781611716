import React, {useContext} from 'react';
import {Map, List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import * as teamRankingsStore from 'fiba/wt/stores/teamRankingsStore';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import TeamRankingsResponse, {
  TeamRanking,
} from 'fiba/common/core/models/api/rankings/TeamRankingsResponse';
import {getLocalDateFromString, getLocalTimeFromString} from 'fiba/common/utils/schedule';
import {ServicesContext} from 'fiba/common/services/services';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {LazyImageWithPlaceholder} from 'fiba/wt/ui/image/LazyImageWithPlaceholder';
import {Box} from 'fiba/wt/ui/box/Box';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Link} from '../link/Link';
import {getProfileImageWt} from 'fiba/common/core/images';

type OwnProps = {
  season: string;
  tourId: string;
};

type ReduxProps = {
  rankingsData: RemoteData.RemoteData<TeamRankingsResponse, unknown>;
};

type Props = OwnProps & ReduxProps;

/**
 * Component that displays the rankings for the current season.
 * Should only be used with the current season, not past seasons, because the rankings are current, not historical!
 * If you want to display the top teams for a current season, use TourTeamsTable instead.
 */
const TourTeamTop10RankingImpl: React.FC<Props> = props => {
  const {rankingsData} = props;
  const {localizer} = useContext(ServicesContext);

  return (
    <Spacer vSpace="3">
      {RemoteData.match(rankingsData, {
        NotAsked: () => null,
        // TODO: Add LoadingSVG here
        Loading: () => <BlockText>Loading...</BlockText>,
        Success: ({rankings, updatedAt}) => (
          <Spacer vSpace="3" pt="3" bgColor="fullwhite" shadow="1">
            <Spacer ph="3">
              {updatedAt && (
                <BlockText fontSize="7">
                  Updated at: {getLocalDateFromString(updatedAt, localizer)},{' '}
                  {getLocalTimeFromString(updatedAt, localizer)} (UTC)
                </BlockText>
              )}
              <BlockText fontSize="7">
                <Link isExternal={true} href="https://fiba3x3.com/en/rankings/team.html">
                  See the full rankings on fiba3x3.com
                </Link>
                .
              </BlockText>
            </Spacer>
            <RankingsTable rankings={rankings} />
          </Spacer>
        ),
        Failure: () => (
          <BlockText>
            There was an error getting the ranking data. Please try again later.
          </BlockText>
        ),
      })}
    </Spacer>
  );
};

export const TourTeamTop10Ranking = connect<ReduxProps, {}, OwnProps>((state, {tourId}) => {
  const rankingsData = RemoteData.map(
    RemoteData.fromMetaObject(state.getIn(teamRankingsStore.globalMetaPath(tourId), Map()).toJS()),
    () => {
      return state.getIn(teamRankingsStore.globalStatePath(tourId)) as TeamRankingsResponse;
    },
  );
  return {rankingsData};
})(TourTeamTop10RankingImpl);

//
// RankingsTable

type RankingsTableColumns = 'rank' | 'team' | 'players' | 'points';

const rankingsTableSpec: Record<RankingsTableColumns, IColumnSpec<TeamRanking>> = {
  rank: {
    dataType: 'number',
    name: 'Rank',
    renderColumn: ({rowIndex, Td, getTdProps}) => (
      <Td {...getTdProps({fontSize: '6', fontWeight: '6', pa: '0', textAlign: 'center'})}>
        {rowIndex + 1}
      </Td>
    ),
  },
  team: {
    dataType: 'text',
    name: 'Team',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps({fontSize: '6'})}>
        <TeamNameAssembly
          isFlagVisible={true}
          isSuffixVisible={true}
          team={
            {
              name: rowData.teamName,
              nameSuffix: rowData.teamNameSuffix,
              nationality: rowData.countryIoc,
            } as any
          }
        />
      </Td>
    ),
  },
  players: {
    dataType: 'text',
    name: 'Players',
    renderHeader: ({Th, getThProps}) => (
      <Th
        {...getThProps({
          color: 'dark-30',
          bgColor: 'fullwhite',
          display: ['none', 'none', 'block'],
        })}
      >
        Players
      </Th>
    ),
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps({display: ['none', 'none', 'block']})}>
        <ul className="flex hs3">
          {rowData.teamMembers.map(player => (
            <li key={player.id} className="vs2 w4">
              <Box width="3" align="center">
                <LazyImageWithPlaceholder
                  containerClassName="br-100"
                  className="br-100"
                  alt=""
                  // We only have the tiny URL in this endpoint
                  src={getProfileImageWt(player, 'tiny')}
                  aspectRatio="1x1"
                />
              </Box>
              {/* TODO: Where do we get the team id? */}
              <BlockText textAlign="center">{player.lastName}</BlockText>
            </li>
          ))}
        </ul>
      </Td>
    ),
  },
  points: {
    dataType: 'number',
    name: 'Points',
    renderColumn: ({rowData, Td, getTdProps}) => (
      <Td {...getTdProps({fontSize: '6', fontWeight: '6'})}>
        {rowData.rankingPoints.toLocaleString()}
      </Td>
    ),
  },
};

const RankingsTable: React.FC<{rankings: List<TeamRanking>}> = props => {
  const {rankings} = props;

  return (
    <DataTable<RankingsTableColumns, TeamRanking>
      striped={false}
      caption={<VisuallyHidden>Top 10 tour teams by ranking</VisuallyHidden>}
      captionId="top-10-rankings-table-caption"
      columnsSpec={rankingsTableSpec}
      columns={['rank', 'team', 'players', 'points']}
      rows={rankings}
      // TODO: Make these the default?
      headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
      headerExtraClassName="dark-30 fw7"
      rowExtraClassName="bb bw1 b--silver-20"
    />
  );
};
