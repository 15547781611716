/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {TourResultsStop} from './TourResultsStop';

export const defaultTourResultsTeamStanding = {
  parentTeamId: null,
  parentTeamName: null,
  parentTeamNameSuffix: null,
  parentTeamRank: null,
  parentTeamExternalUrl: null,
  totalStandingPoints: null,
  totalSeedingPoints: null,
  totalPoints: null,
  averagePoints: null,
  gamesPlayed: null,
  gamesWon: null,
  winRatio: null,
  qualification: null,
  tourStops: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TourResultsTeamStandingLike = {
  [K in keyof typeof defaultTourResultsTeamStanding]?: TourResultsTeamStanding[K];
};

export class TourResultsTeamStanding extends Record(defaultTourResultsTeamStanding) {
  parentTeamId?: string;
  parentTeamName?: string;
  parentTeamNameSuffix?: string;
  parentTeamRank?: number;
  parentTeamExternalUrl?: string;
  totalStandingPoints?: number;
  totalSeedingPoints?: number;
  totalPoints?: number;
  averagePoints?: number;
  gamesPlayed?: number;
  gamesWon?: number;
  winRatio?: number;
  qualification?: string;
  tourStops?: List<TourResultsStop>;
  static fromJS(o: TourResultsTeamStandingLike): TourResultsTeamStanding {
    return (
      o &&
      new TourResultsTeamStanding({
        parentTeamId: o.parentTeamId,
        parentTeamName: o.parentTeamName,
        parentTeamNameSuffix: o.parentTeamNameSuffix,
        parentTeamRank: o.parentTeamRank,
        parentTeamExternalUrl: o.parentTeamExternalUrl,
        totalStandingPoints: o.totalStandingPoints,
        totalSeedingPoints: o.totalSeedingPoints,
        totalPoints: o.totalPoints,
        averagePoints: o.averagePoints,
        gamesPlayed: o.gamesPlayed,
        gamesWon: o.gamesWon,
        winRatio: o.winRatio,
        qualification: o.qualification,
        tourStops: o.tourStops && List(o.tourStops).map(i => i && TourResultsStop.fromJS(i)),
      })
    );
  }
}

export default TourResultsTeamStanding;
