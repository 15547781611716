import React, {isValidElement} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {SubHeading, Heading} from 'fiba/wt/ui/heading/Heading';
import {TourStandingsColumnType} from 'fiba/wt/ui/tourStandings/TourStandingsColumnType';
import {TourTeamStandings} from 'fiba/wt/ui/tourStandings/TourTeamStandings';
import TourPlayerStandings from 'fiba/play/ui/tourStandings/TourPlayerStandings';
import {TourResultsCategory} from 'fiba/common/core/models/api/results/TourResultsCategory';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';

interface OwnProps {
  season: string;
  tourId: string;
  stage: string;
  heading?: React.ReactNode | string;
  numRows?: number;
  columns?: TourStandingsColumnType[];
  disableTeamLinks?: boolean;
  children?: React.ReactNode;
}

interface ReduxProps {
  categories: List<TourResultsCategory>;
  metaPath?: string;
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {tourId, stage}: OwnProps): ReduxProps => ({
  categories: state.getIn(['tourResults', tourId, stage, 'categories'], List()),
  metaPath: `tourResults/__meta/${tourId}/${stage}`,
});

const _TourStandings: React.FC<Props> = ({
  season,
  numRows,
  columns,
  categories,
  metaPath,
  children,
  heading,
  disableTeamLinks = false,
}: Props) => {
  // The categories are sorted by name to ensure a consistent order
  const sortedCategories = categories.sortBy(cat => cat.parentCategoryName);

  return (
    <Loading metaPaths={[metaPath]}>
      {() => {
        const noStandingsExist = categories.every(
          cat =>
            (!cat.teamStandings || cat.teamStandings.size === 0) &&
            (!cat.playerStandings || cat.playerStandings.size === 0),
        );

        if (noStandingsExist) {
          return null;
        }
        return (
          <Spacer vSpace="2" debugClassName="TourStandings">
            <section>
              {typeof heading === 'string' && (
                <Heading fontSize={['3', '3', '2']} pb="3">
                  {heading}
                </Heading>
              )}
              {isValidElement(heading) && heading}
              {sortedCategories.map(category => {
                const headingId = `standing-${category.parentCategoryId}`;
                return (
                  <div key={category.parentCategoryId}>
                    {categories.count() > 1 ? (
                      <SubHeading ph={['3', '3', '4']} mt="3" id={headingId}>
                        {category.parentCategoryName}
                      </SubHeading>
                    ) : (
                      <VisuallyHidden>
                        <SubHeading ph={['3', '3', '4']} id={headingId}>
                          {category.parentCategoryName}
                        </SubHeading>
                      </VisuallyHidden>
                    )}

                    {!!category.teamStandings && (
                      <TourTeamStandings
                        season={season}
                        ariaLabelledBy={headingId}
                        category={category}
                        columns={columns}
                        numRows={numRows}
                        disableTeamLinks={disableTeamLinks}
                      />
                    )}

                    {!!category.playerStandings && <TourPlayerStandings category={category} />}

                    {children}
                  </div>
                );
              })}
            </section>
          </Spacer>
        );
      }}
    </Loading>
  );
};

export const TourStandings = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_TourStandings);
