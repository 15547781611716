import React from 'react';
import {ImageWithPlaceholder} from 'fiba/wt/ui/image/Image';
import {
  ContentfulImageLink,
  ImageLinkTypes,
} from 'fiba/wt/ui/contentfulImageLink/ContentfulImageLink';

export enum SponsorType {
  Sponsor1 = ImageLinkTypes.Sponsor1,
  Sponsor2 = ImageLinkTypes.Sponsor2,
  Sponsor3 = ImageLinkTypes.Sponsor3,
}

interface ISponsorBox {
  // Giving (tourId, eventId) will fetch event sponsors,
  // giving only (tourId) will fetch tour sponsors
  tourId: string;
  eventId?: string;
  type: SponsorType;
}

interface IMakeContentPath {
  tourId: string;
  eventId?: string;
}

function makeContentPath({tourId, eventId}: IMakeContentPath): string {
  if (!eventId) {
    return `tours/${tourId}`;
  } else {
    return `events/${eventId}`;
  }
}

export const SponsorBox: React.FunctionComponent<ISponsorBox> = ({type, ...props}) => (
  <div className="SponsorBox">
    <ContentfulImageLink contentPath={makeContentPath(props)} imageLinkType={type as any}>
      {([imageLink]) => {
        // No imageLink or an image hasn't been set for it - let's not crash and burn
        if (!imageLink || !imageLink.image) {
          return null;
        }

        return (
          <a className="db" href={imageLink.link} target="_blank" rel="noopener noreferrer">
            {/* TODO: srcSet */}
            <ImageWithPlaceholder
              aspectRatio="4x3"
              alt={imageLink.title}
              src={`${imageLink.image.fields.file.url}`}
              containerStyle={{bgColor: 'transparent'}}
              className="o-fit-contain"
            />
          </a>
        );
      }}
    </ContentfulImageLink>
  </div>
);
