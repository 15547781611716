import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {FastestGame} from 'fiba/common/core/models/api/fastestGame/FastestGame';
import {List} from 'immutable';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {FastestGamesTable} from 'fiba/wt/ui/fastestGamesTable/FastestGamesTable';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';

type Props = OwnProps & ReduxProps;

interface OwnProps {
  season: string;
  tourId: string;
}

interface ReduxProps {
  fastestGames: FastestGame[];
  metaPaths: string[];
}

export const mapStateToProps = (state, {tourId}: OwnProps): ReduxProps => {
  const fastestGamesList: List<FastestGame> = state.getIn(
    ['stats', 'tourFastestGames', tourId],
    List(),
  );

  const fastestGames: FastestGame[] = fastestGamesList.toJS();

  const metaPaths = [`stats/__meta/tourFastestGames/${tourId}`];

  return {fastestGames, metaPaths};
};

export const _TourFastestGames: React.SFC<Props> = ({season, fastestGames, metaPaths}) => {
  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <FastestGamesTable
          season={season}
          captionId={`${season}-fastest-games`}
          caption={<SubHeading>Fastest Games</SubHeading>}
          columns={['Number', 'Game', 'Event', 'Round', 'Score', 'PlayTime', 'Link']}
          rows={fastestGames}
        />
      )}
    </Loading>
  );
};

//
// Connected export
export const TourFastestGames = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _TourFastestGames,
);
