import React from 'react';
import {Map, List} from 'immutable';
import {RootState} from 'fiba/wt/stores/rootStore';
import {connect} from 'fiba/common/utils/reactUtils';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import {ContentTypes} from 'fiba/wt/services/contentfulService';
import {routerReplaceLocation} from 'fiba/common/services/routerService';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Article} from 'fiba/wt/ui/article/Article';
import {DangerousContentfulMarkdown} from 'fiba/wt/ui/contentfulMarkdown/ContentfulMarkdown';
import {getCustomPageName} from 'fiba/wt/stores/contentStore';

interface OwnProps {
  tourId?: string;
  season: string;
  eventId: string;
  customPageSlug: string;
}

interface ReduxProps {
  pageName: string;
  customPage: Map<string, any>;
  metaPaths: string[];
}

interface DispatchProps {
  routeTo404NotFoundPage: () => any;
}

type CustomPageProps = OwnProps & ReduxProps & DispatchProps;

const mapDispatchToProps = (dispatch): DispatchProps => ({
  routeTo404NotFoundPage: () => {
    dispatch(routerReplaceLocation('/404'));
    return null;
  },
});

const mapStateToProps = (state: RootState, {eventId, customPageSlug}: OwnProps): ReduxProps => {
  const customPage = state
    .getIn(['content', ContentTypes.CustomPage, 'events', eventId], List())
    .find(storeCustomPage => storeCustomPage.getIn(['fields', 'slug']) === customPageSlug);
  return {
    customPage,
    pageName: customPage ? getCustomPageName(customPage) : customPageSlug,
    metaPaths: [`content/__meta/${ContentTypes.CustomPage}/events/${eventId}`],
  };
};

export const _CustomPage: React.FunctionComponent<CustomPageProps> = ({
  eventId,
  pageName,
  customPage,
  routeTo404NotFoundPage,
  metaPaths,
}) => {
  return (
    <Spacer ph={['3', '3', '1']}>
      <Loading metaPaths={metaPaths}>
        {() =>
          !customPage ? (
            routeTo404NotFoundPage()
          ) : (
            <section>
              <EventPageHeading eventId={eventId} page={pageName} />
              <Section>
                <div className="ContentfulArticle">
                  <Article>
                    <DangerousContentfulMarkdown
                      content={customPage.getIn(['fields', 'content'])}
                    />
                  </Article>
                </div>
              </Section>
            </section>
          )
        }
      </Loading>
    </Spacer>
  );
};

export const CustomPage = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(_CustomPage);
