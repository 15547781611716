import React from 'react';
import InputRange from 'react-input-range';

interface RangeType {
  min: number;
  max: number;
}

export interface IFilterInputState {
  value: RangeType;
}

export interface IFilterInputProps {
  filterAction: any;
  max: number;
  min: number;
  field: string;
}

export class FilterInput extends React.Component<IFilterInputProps, IFilterInputState> {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        min: props.min,
        max: props.max,
      },
    };
  }

  filterAction() {
    this.props.filterAction({
      min: this.state.value.min,
      max: this.state.value.max,
      field: this.props.field,
    });
  }

  render() {
    const {value} = this.state;
    const {min, max} = this.props;

    return (
      <InputRange
        minValue={min}
        maxValue={max}
        value={value}
        onChange={value => {
          this.setState({value: value as RangeType});
        }}
        onChangeComplete={value => {
          this.setState({value: value as RangeType});
          this.filterAction();
        }}
        allowSameValues={true}
      />
    );
  }
}
