import React from 'react';
import {getLocationByIocCode, getLocationByIso2Code} from 'fiba/common/utils/Locations';
import {assetPath} from 'fiba/common/utils/reactUtils';
import {Placeholder} from 'fiba/wt/ui/placeholder/Placeholder';
import cx from 'classnames';

type Props = {
  includeAlt: boolean;
  width: number;
  extraClassName?: string;
} & IocOrIso2;

type IocOrIso2 =
  | {
      ioc: string;
      iso2?: never;
    }
  | {
      ioc?: never;
      iso2: string;
    };

export const Flag: React.FC<Props> = ({ioc, iso2, width, includeAlt, extraClassName}) => {
  const location = ioc ? getLocationByIocCode(ioc) : getLocationByIso2Code(iso2);

  if (location) {
    return (
      <span className={cx('dib Flag v-mid', extraClassName)} style={{width: `${width}px`}}>
        <Placeholder
          aspectRatio="4x3"
          image={cls => (
            <img
              // NOTE: It is up to the caller whether to treat the flag as content in decoration
              // We often include the country code, e.g. SRB, as standalone text, in which case no need
              // to also include the alt.
              alt={includeAlt ? location.name : ''}
              width={width}
              // Add light border to the flag.
              // This is good for flags with white background, such as Japan:
              style={{boxShadow: '0 0 0 1px #e5e8ed'}}
              // 4x3 ratio
              height={width * 0.75}
              className={cls}
              src={assetPath(`/flags/4x3/${location.get('iso2Code').toLowerCase()}.svg`)}
            />
          )}
        />
      </span>
    );
  }

  return null;
};
