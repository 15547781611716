import React, {useContext} from 'react';
import {Heading, SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flag} from 'fiba/wt/ui/flag/Flag';
import DateFormatter from 'fiba/common/utils/Dates';
import {ServicesContext} from 'fiba/common/services/services';
import {ResultsGameDetails} from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {Event} from 'fiba/common/core/models/api/events/Event';
import {abbreviateGroupName} from 'fiba/wt/utils/games/groups';

interface Props {
  id: string;
  gameData: {
    gameDetails: ResultsGameDetails;
    gameSummary: ResultsGameSummary;
    event: Event;
  };
}

export const GameHeading: React.FC<Props> = ({gameData, id}) => {
  const {gameDetails, gameSummary} = gameData;
  const {homeTeam, awayTeam, gameIsOvertime} = gameDetails;
  const {localizer} = useContext(ServicesContext);

  return (
    <Box pt="3" id={id}>
      <SubHeading color="steel-40" pb="1" fontSize={['7', '7', '6']} textAlign="center">
        {gameData.event.name}&nbsp;
        {abbreviateGroupName(gameSummary)}
        &nbsp;&nbsp;|&nbsp;&nbsp;
        {DateFormatter.formatDate(localizer, gameDetails.gameStartDatetime)}
        {gameIsOvertime && ' | OT'}
      </SubHeading>
      <Heading
        pt={['0', '0', '1']}
        color="steel-50"
        textAlign="center"
        fontSize={['5', '4', '4', '3']}
      >
        {renderFlag(homeTeam.teamNationality)} {homeTeam.teamName} {homeTeam.teamScore} –{' '}
        {awayTeam.teamScore} {awayTeam.teamName} {renderFlag(awayTeam.teamNationality)}
      </Heading>
    </Box>
  );
};

const renderFlag = (teamNationality: string) => {
  return (
    teamNationality && (
      <Flag width={24} ioc={teamNationality} includeAlt={true} extraClassName="GameBoxScore-Flag" />
    )
  );
};
