import React from 'react';

/**
 * Renderless component that tracks focus, click, blur events.
 * Signals whether the element should be active depending on where
 * the focus/blur has landed (inside vs outside the wrapped element).
 *
 * This is a render-prop version of the one in the React docs
 * @see https://reactjs.org/docs/accessibility.html#mouse-and-pointer-events
 *
 * @example
 * <BlurHandler>
 *  {({isActive, onFocus, onBlur, onClick}) =>
 *    <>
 *      <button onClick={onClick} aria-expanded={this.state.isOpen} />
 *      <div onBlur={onBlur} onFocus={onFocus}>
 *        <span>More things here?</span>
 *      </div>
 *    </>
 *  }
 * </BlurHandler>
 */

interface IBlurHandler {
  children: (props: IRenderCallbackProps) => React.ReactElement<any>;
}

// Delegation happens here
interface IRenderCallbackProps {
  isActive: boolean;
  onFocus: () => void;
  onBlur: () => void;
  onClick: () => void;
}

interface IBlurHandlerState {
  isActive: boolean;
}

const initialState: IBlurHandlerState = {
  isActive: false,
};

export class BlurHandler extends React.Component<IBlurHandler, IBlurHandlerState> {
  timeoutId: NodeJS.Timeout;

  constructor(props) {
    super(props);

    this.state = initialState;
    this.timeoutId = null;

    // Bind methods
    this.onClickHandler = this.onClickHandler.bind(this);
    this.onBlurHandler = this.onBlurHandler.bind(this);
    this.onFocusHandler = this.onFocusHandler.bind(this);
  }

  // Clean up on unmount
  componentWillUnmount() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  onClickHandler() {
    this.setState(prevState => ({
      isActive: !prevState.isActive,
    }));
  }

  // We close the popover on the next tick by using setTimeout.
  // This is necessary because we need to first check if
  // another child of the element has received focus as
  // the blur event fires prior to the new focus event.
  onBlurHandler() {
    this.timeoutId = setTimeout(() => {
      this.setState({
        isActive: false,
      });
    });
  }

  // If a child receives focus, do not close
  onFocusHandler() {
    clearTimeout(this.timeoutId);
  }

  render() {
    // This works because React bubbles the blur and focus events to the parent
    return this.props.children({
      isActive: this.state.isActive,
      onBlur: this.onBlurHandler,
      onFocus: this.onFocusHandler,
      onClick: this.onClickHandler,
    });
  }
}
