import React from 'react';
import {StyleProps, StyledAPI, styledAPItoClassNames} from 'fiba/wt/ui/stylingAPI/makeStyled';

type FastestGamesLogoStylingAPI = StyledAPI<'pa' | 'pv' | 'ph' | 'ma' | 'mv' | 'mh' | 'mb' | 'mt'>;

export type Props = StyleProps<FastestGamesLogoStylingAPI> & {
  extraClassName?: string;
  width?: string;
};

export const FastestGamesSponsorLogo = ({extraClassName = '', width, ...rest}: Props) => {
  const styleClass = styledAPItoClassNames(rest);

  return (
    <svg
      className={`${extraClassName} ${styleClass}`}
      viewBox="0 0 251 46"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_3)" fill="black">
        <path d="M196.189 7.87476H201.513C204.434 7.87476 206.489 9.69312 206.489 12.7009C206.489 14.5878 205.343 16.2816 203.792 17.1783L207.852 24.7756H204.82L201.363 17.9318H198.885V24.7756H196.195V7.87476H196.189ZM198.879 15.8581H200.697C203.132 15.8581 203.773 13.9214 203.773 12.7756C203.773 10.814 202.509 9.95467 201.152 9.95467H198.873V15.8581" />
        <path d="M151.595 3.60907H154.609V22.4652H162.02V24.7756H151.595V3.60907Z" />
        <path d="M229.996 24.7756V7.88098H232.892V24.7756H229.996Z" />
        <path d="M247.009 24.7756L242.887 18.0065L238.951 24.7756H235.912L241.386 15.8581L236.367 7.88098H239.736L243.167 13.554L246.449 7.88098H249.301L244.587 15.6028L250.59 24.7756H247.009Z" />
        <path d="M173.241 7.88098L179.257 24.7756H176.193L174.748 20.2795H168.471L166.939 24.7756H163.981L170.352 7.88098H173.241ZM174.119 18.1996C173.422 15.9951 172.525 13.1493 171.784 10.8078L171.753 10.6957L171.722 10.8078C170.806 13.5229 170.022 15.7709 169.187 18.1996H174.119Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.361 22.7704C184.818 22.7704 183.167 19.2893 183.167 16.0636C183.167 12.9687 184.979 9.81766 188.361 9.81766C189.525 9.81766 190.478 10.2224 191.257 10.8265L192.739 9.05793C191.549 8.1363 190.067 7.66302 188.355 7.66302C183.784 7.66302 180.085 11.3932 180.085 16.0698C180.085 21.0641 183.423 24.9873 188.355 24.9873C190.441 24.9873 192.172 24.24 193.424 22.9447L191.798 21.3381C190.983 22.1912 189.793 22.7704 188.361 22.7704Z"
        />
        <path d="M209.689 16.0761C209.689 11.3994 213.401 7.6817 217.972 7.6817C222.536 7.6817 226.248 11.3932 226.248 16.0761C226.248 21.0703 222.854 24.9811 217.972 24.9811C213.083 24.9811 209.689 21.0703 209.689 16.0761ZM223.146 16.0823C223.146 12.9998 221.384 9.86747 217.972 9.86747C214.559 9.86747 212.79 12.9998 212.79 16.0823C212.79 19.2893 214.397 22.7953 217.972 22.7953C221.546 22.7953 223.146 19.2893 223.146 16.0823Z" />
        <path d="M44.293 25.0558C48.3407 25.0558 50.595 22.5773 50.595 18.1995V7.87473H47.7304V17.7512C47.7304 20.6406 46.9583 22.9385 44.293 22.9385C41.6277 22.9385 41.0299 20.6406 41.0299 17.7512V7.87473H38.1342V18.1995C38.1342 22.5773 40.2453 25.0558 44.293 25.0558Z" />
        <path d="M54.8171 7.87473H60.0604C62.981 7.87473 64.9488 9.69309 64.9488 12.7009C64.9488 14.5877 63.803 16.2815 62.2524 17.1783L66.3126 24.7755H63.2862L59.911 17.9318H57.5135V24.7755H54.8233V7.87473H54.8171ZM57.5073 15.8581H59.2447C61.6733 15.8581 62.2338 13.9214 62.2338 12.7756C62.2338 10.814 61.0568 9.95464 59.7055 9.95464H57.5135V15.8581" />
        <path d="M69.1772 24.7756V7.88098H72.0729V24.7756H69.1772Z" />
        <path d="M99.5787 9.95464H94.0115V14.8617H98.7629V16.9354H94.0115V22.6956H99.8652V24.7755H91.1158V7.87473H99.5787V9.95464Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M83.4189 22.7704C79.8756 22.7704 78.406 19.2893 78.406 16.0636C78.406 12.9687 80.0438 9.81766 83.4189 9.81766C84.5834 9.81766 85.5424 10.2224 86.3208 10.8265L87.8029 9.05793C86.6198 8.1363 85.1377 7.66302 83.4252 7.66302C78.8543 7.66302 75.3297 11.3932 75.3297 16.0698C75.3297 21.0641 78.4932 24.9873 83.4252 24.9873C85.5051 24.9873 87.2425 24.24 88.4942 22.9447L86.8688 21.3381C86.0406 22.1912 84.8574 22.7704 83.4189 22.7704Z"
        />
        <path d="M30.1633 7.88098L36.1726 24.7756H33.1088L31.6641 20.2795H25.4742L23.9423 24.7756H20.9905L27.361 7.88098H30.1633ZM31.0476 18.1996C30.3501 15.9951 29.4534 13.1493 28.7124 10.8078L28.6812 10.6957L28.6501 10.8078C27.7347 13.5229 27.0372 15.7709 26.2028 18.1996H31.0476Z" />
        <path d="M9.42652 16.6676L9.38916 16.5182C8.23712 13.4979 6.31289 7.81245 4.64398 3.59659H0.590027V24.7693H3.36739V6.89704V6.567C3.36739 6.567 3.41721 6.80363 3.45457 6.89704C4.18939 8.73409 6.44989 15.8954 8.23089 20.2794H10.5225C12.3035 15.8892 14.564 8.73409 15.2988 6.89704C15.3362 6.80363 15.386 6.567 15.386 6.567V6.89704V24.7693H18.1945V3.59659H14.1717C12.5028 7.80622 10.6097 13.4917 9.45766 16.5182L9.42652 16.6676Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M128.635 24.8191V3.62151C130.821 4.06988 133.057 6.62306 133.057 12.4767V24.8191H143.275V21.5996L139.116 20.5223C139.116 20.5223 139.116 15.7211 139.116 12.4705C139.116 5.04133 134.258 0.950012 125.708 0.950012C117.152 0.950012 112.301 5.04133 112.301 12.4705C112.301 15.7211 112.301 20.5223 112.301 20.5223L108.135 21.5996V24.8191H118.354V12.4767C118.354 6.62306 120.583 4.06988 122.775 3.62151V24.8191H128.635Z"
        />
        <path d="M47.5001 34.3718H48.9697L51.3983 41.969L53.8394 34.3718H55.3091V43.0339H54.375V35.3619L51.8965 43.0339H50.9251L48.4342 35.3619V43.0339H47.5125V34.3718H47.5001Z" />
        <path d="M58.1487 38.488H57.2395C57.3703 37.3297 58.1736 36.707 59.5498 36.707C60.5524 36.707 61.2748 37.0806 61.5114 37.722C61.6297 38.0459 61.6546 38.3323 61.6546 39.1107V41.4086C61.6546 42.4236 61.6671 42.6042 61.8228 43.0339H60.951C60.8575 42.7101 60.8451 42.6042 60.8202 42.2057C60.459 42.8658 59.8612 43.1896 59.0143 43.1896C57.8062 43.1896 57.0278 42.5046 57.0278 41.4273C57.0278 40.5555 57.5197 39.9203 58.4538 39.5715C59.0143 39.366 59.6495 39.2228 60.5586 39.0671L60.7517 39.0422V38.7682C60.7517 37.8466 60.4154 37.4854 59.5062 37.4854C58.7091 37.4792 58.2795 37.803 58.1487 38.488ZM60.7579 39.6961C58.6905 40.0697 57.9681 40.4994 57.9681 41.3588C57.9681 41.9939 58.4476 42.4361 59.1637 42.4361C59.8487 42.4361 60.4216 42.0438 60.6334 41.4459C60.7268 41.1595 60.7641 40.8108 60.7641 40.3188V39.6961H60.7579Z" />
        <path d="M63.3921 36.8814H64.2639C64.3698 37.1803 64.3822 37.3484 64.3822 37.7781C64.7994 37.062 65.4346 36.7132 66.2629 36.7132C67.5768 36.7132 68.2244 37.4418 68.2244 38.9052V39.503V43.0339H67.3153V39.5529V39.1107C67.3153 38.021 66.9229 37.5165 66.0823 37.5165C65.0423 37.5165 64.4445 38.2825 64.4445 39.5965V43.0401H63.5353V38.9737V38.5191C63.5353 37.5165 63.5229 37.4107 63.3921 36.8814Z" />
        <path d="M70.0677 36.8814H70.9644V40.6489C70.9644 41.9192 71.3131 42.3863 72.2348 42.3863C72.8326 42.3863 73.337 42.0625 73.5736 41.502C73.692 41.228 73.7418 40.8917 73.7418 40.3749V36.8814H74.651V41.1657V41.6826C74.651 42.4859 74.6634 42.6167 74.7942 43.0339H73.9099C73.804 42.7475 73.7791 42.5669 73.7791 42.2181C73.418 42.9094 72.9198 43.1896 72.1289 43.1896C71.1948 43.1896 70.4289 42.7101 70.1922 41.9566C70.0864 41.6203 70.0615 41.3463 70.0615 40.6427V36.8814H70.0677Z" />
        <path d="M77.6089 36.8814H79.1284V37.6224H77.6338V43.0339H76.6748V37.6224H75.803V36.8814H76.6748V36.4891C76.6748 35.5674 76.7246 35.2561 76.9364 34.9073C77.2104 34.4652 77.7148 34.2036 78.3126 34.2036C78.624 34.2036 78.9104 34.2535 79.2965 34.3842V35.2311C78.9353 35.063 78.7111 35.007 78.4247 35.007C77.802 35.007 77.6089 35.3557 77.6089 36.4641V36.8814Z" />
        <path d="M80.7786 38.488H79.8694C80.0002 37.3297 80.8035 36.707 82.1797 36.707C83.1823 36.707 83.9047 37.0806 84.1413 37.722C84.2596 38.0459 84.2846 38.3323 84.2846 39.1107V41.4086C84.2846 42.4236 84.297 42.6042 84.4527 43.0339H83.5809C83.4875 42.7101 83.475 42.6042 83.4501 42.2057C83.0889 42.8658 82.4911 43.1896 81.6442 43.1896C80.4361 43.1896 79.6577 42.5046 79.6577 41.4273C79.6577 40.5555 80.1496 39.9203 81.0837 39.5715C81.6442 39.366 82.2794 39.2228 83.1885 39.0671L83.3816 39.0422V38.7682C83.3816 37.8466 83.0453 37.4854 82.1361 37.4854C81.339 37.4792 80.9094 37.803 80.7786 38.488ZM83.3878 39.6961C81.3204 40.0697 80.598 40.4994 80.598 41.3588C80.598 41.9939 81.0775 42.4361 81.7936 42.4361C82.4786 42.4361 83.0516 42.0438 83.2633 41.4459C83.3567 41.1595 83.394 40.8108 83.394 40.3188V39.6961H83.3878Z" />
        <path d="M90.6364 38.8803H89.7396C89.5715 37.8777 89.1917 37.4916 88.4008 37.4916C87.3733 37.4916 86.7506 38.4257 86.7506 39.9576C86.7506 41.502 87.3733 42.4236 88.4257 42.4236C89.2415 42.4236 89.6711 41.9441 89.777 40.9166H90.6862C90.5679 42.3987 89.777 43.1896 88.4257 43.1896C86.844 43.1896 85.8165 41.9192 85.8165 39.9701C85.8165 37.9711 86.8315 36.7132 88.4257 36.7132C89.7272 36.7132 90.5056 37.4792 90.6364 38.8803Z" />
        <path d="M93.4635 36.8814H94.9332L94.9207 37.6224H93.4511L93.3888 40.736V41.2031V41.3463C93.3888 42.1496 93.532 42.3614 94.08 42.3614C94.354 42.3614 94.6654 42.2991 94.9768 42.1559L94.9643 42.9592C94.572 43.1024 94.2606 43.1647 93.8745 43.1647C92.8595 43.1647 92.4485 42.7475 92.4485 41.6951C92.4485 41.5394 92.4609 41.3712 92.4609 41.1906C92.4734 40.9976 92.4734 40.8419 92.4734 40.7111L92.5481 37.6224H91.6265L91.639 36.8814H92.573L92.6104 35.1004H93.4947L93.4635 36.8814Z" />
        <path d="M96.272 36.8814H97.1688V40.6489C97.1688 41.9192 97.5175 42.3863 98.4391 42.3863C99.037 42.3863 99.5414 42.0625 99.778 41.502C99.8963 41.228 99.9461 40.8917 99.9461 40.3749V36.8814H100.855V41.1657V41.6826C100.855 42.4859 100.868 42.6167 100.999 43.0339H100.114C100.008 42.7475 99.9835 42.5669 99.9835 42.2181C99.6223 42.9094 99.1241 43.1896 98.3333 43.1896C97.3992 43.1896 96.6332 42.7101 96.3966 41.9566C96.2907 41.6203 96.2658 41.3463 96.2658 40.6427V36.8814H96.272Z" />
        <path d="M102.661 36.8814H103.533C103.608 37.1927 103.651 37.5165 103.651 37.8279C103.651 37.9213 103.639 38.0334 103.626 38.2015C104.069 37.174 104.561 36.707 105.221 36.707C105.457 36.707 105.713 36.7693 105.974 36.9V37.8092C105.557 37.6162 105.389 37.5601 105.158 37.5601C104.666 37.5601 104.237 37.8964 103.963 38.5067C103.77 38.9364 103.689 39.4532 103.689 40.269V43.0214H102.767V38.9862V38.569C102.761 37.5975 102.748 37.4418 102.661 36.8814Z" />
        <path d="M111.728 40.1133H107.537C107.575 41.6203 108.197 42.4112 109.318 42.4112C109.991 42.4112 110.395 42.1496 110.744 41.4771L111.591 41.5829C111.217 42.6478 110.433 43.1896 109.306 43.1896C107.655 43.1896 106.603 41.9566 106.603 40.0199C106.603 38.0085 107.643 36.707 109.25 36.707C110.757 36.707 111.741 37.94 111.741 39.8518C111.741 39.9078 111.741 39.995 111.728 40.1133ZM110.788 39.3972C110.682 38.1517 110.128 37.473 109.206 37.473C108.309 37.473 107.736 38.133 107.556 39.3972H110.788Z" />
        <path d="M117.644 38.1019H121.723V34.378H122.694V43.0401H121.723V38.9114H117.644V43.0401H116.685V34.378H117.644V38.1019Z" />
        <path d="M124.382 39.9576C124.382 38.0334 125.484 36.7132 127.066 36.7132C128.648 36.7132 129.756 38.0396 129.756 39.9576C129.756 41.8694 128.654 43.1896 127.066 43.1896C125.49 43.1896 124.382 41.8694 124.382 39.9576ZM125.322 39.9576C125.322 41.4273 126.026 42.4112 127.072 42.4112C128.125 42.4112 128.828 41.4273 128.828 39.9576C128.828 38.4755 128.125 37.4916 127.072 37.4916C126.02 37.4916 125.322 38.4755 125.322 39.9576Z" />
        <path d="M131.201 36.8814H132.073C132.147 37.1927 132.191 37.5165 132.191 37.8279C132.191 37.9213 132.178 38.0334 132.166 38.2015C132.608 37.174 133.1 36.707 133.76 36.707C133.997 36.707 134.252 36.7693 134.514 36.9V37.8092C134.096 37.6162 133.928 37.5601 133.698 37.5601C133.206 37.5601 132.776 37.8964 132.502 38.5067C132.309 38.9364 132.228 39.4532 132.228 40.269V43.0214H131.307V38.9862V38.569C131.307 37.5975 131.294 37.4418 131.201 36.8814Z" />
        <path d="M135.535 34.3344H136.494V43.0339H135.535V34.3344Z" />
        <path d="M138.045 39.9576C138.045 38.0334 139.147 36.7132 140.729 36.7132C142.31 36.7132 143.419 38.0396 143.419 39.9576C143.419 41.8694 142.316 43.1896 140.729 43.1896C139.147 43.1896 138.045 41.8694 138.045 39.9576ZM138.979 39.9576C138.979 41.4273 139.682 42.4112 140.729 42.4112C141.781 42.4112 142.485 41.4273 142.485 39.9576C142.485 38.4755 141.781 37.4916 140.729 37.4916C139.682 37.4916 138.979 38.4755 138.979 39.9576Z" />
        <path d="M149.988 36.8814V37.5664L148.731 37.5415C149.067 37.8528 149.26 38.3884 149.26 38.9426C149.26 39.6463 148.973 40.2005 148.481 40.4621C148.183 40.6302 147.834 40.7485 147.261 40.8668C146.221 41.0848 145.947 41.228 145.947 41.5394C145.947 41.8009 146.078 41.8507 147.18 41.9566C148.531 42.0874 148.93 42.1746 149.335 42.4859C149.715 42.7724 149.945 43.2519 149.945 43.7687C149.945 44.9519 148.88 45.6743 147.155 45.6743C145.468 45.6743 144.378 44.9768 144.378 43.8933C144.378 43.1522 144.77 42.6976 145.679 42.3738C145.212 42.1932 145.019 41.9815 145.019 41.6577C145.019 41.2405 145.318 40.9727 146.121 40.6676C145.293 40.3313 144.901 39.7584 144.901 38.8866C144.901 37.6162 145.798 36.7817 147.161 36.7817C147.498 36.7817 147.759 36.8191 148.12 36.925L149.988 36.8814ZM147.08 42.7911C145.91 42.7911 145.212 43.1772 145.212 43.8559C145.212 44.5036 145.991 44.9332 147.174 44.9332C148.345 44.9332 149.054 44.516 149.054 43.8559C149.054 43.1522 148.363 42.7911 147.08 42.7911ZM147.093 37.529C146.327 37.529 145.773 38.1019 145.773 38.8803C145.773 39.6463 146.302 40.1507 147.093 40.1507C147.884 40.1507 148.407 39.6463 148.407 38.8803C148.407 38.0895 147.871 37.529 147.093 37.529Z" />
        <path d="M155.985 40.1133H151.794C151.832 41.6203 152.454 42.4112 153.575 42.4112C154.248 42.4112 154.653 42.1496 155.001 41.4771L155.842 41.5829C155.468 42.6478 154.684 43.1896 153.557 43.1896C151.906 43.1896 150.854 41.9566 150.854 40.0199C150.854 38.0085 151.894 36.707 153.501 36.707C155.008 36.707 155.992 37.94 155.992 39.8518C155.998 39.9078 155.998 39.995 155.985 40.1133ZM154.167 35.9348H153.401L151.52 34.1538H152.816L154.167 35.9348ZM155.051 39.3972C154.945 38.1517 154.391 37.4729 153.469 37.4729C152.573 37.4729 152 38.133 151.819 39.3972H155.051Z" />
        <path d="M157.443 36.8814H158.314C158.389 37.1927 158.433 37.5165 158.433 37.8279C158.433 37.9213 158.42 38.0334 158.408 38.2015C158.85 37.174 159.342 36.707 160.002 36.707C160.239 36.707 160.494 36.7693 160.755 36.9V37.8092C160.338 37.6162 160.17 37.5601 159.94 37.5601C159.448 37.5601 159.018 37.8964 158.744 38.5067C158.551 38.9364 158.47 39.4532 158.47 40.269V43.0214H157.548V38.9862V38.569C157.542 37.5975 157.53 37.4418 157.443 36.8814Z" />
        <path d="M166.509 40.1133H162.318C162.356 41.6203 162.979 42.4112 164.099 42.4112C164.772 42.4112 165.177 42.1496 165.526 41.4771L166.366 41.5829C165.993 42.6478 165.208 43.1896 164.081 43.1896C162.431 43.1896 161.378 41.9566 161.378 40.0199C161.378 38.0085 162.418 36.707 164.025 36.707C165.532 36.707 166.516 37.94 166.516 39.8518C166.522 39.9078 166.522 39.995 166.509 40.1133ZM165.575 39.3972C165.469 38.1517 164.915 37.473 163.994 37.473C163.097 37.473 162.524 38.133 162.343 39.3972H165.575Z" />
        <path d="M176.479 36.6198H175.533C175.439 35.6359 174.842 35.0879 173.858 35.0879C172.948 35.0879 172.276 35.6484 172.276 36.4019C172.276 36.763 172.456 37.0869 172.793 37.3235C172.961 37.4418 173.166 37.5477 173.403 37.6598C173.472 37.6847 173.783 37.8279 174.337 38.077C175.62 38.6624 176.062 38.9488 176.386 39.4532C176.604 39.8019 176.735 40.2565 176.735 40.7236C176.735 42.1932 175.595 43.1771 173.907 43.1771C172.114 43.1771 171.105 42.2181 171.012 40.3998H171.958C172.039 41.6452 172.737 42.3365 173.932 42.3365C174.985 42.3365 175.726 41.7137 175.726 40.8419C175.726 40.4122 175.558 40.051 175.209 39.7646C174.922 39.5279 174.729 39.4283 173.87 39.036C172.724 38.5316 172.195 38.2327 171.871 37.9089C171.491 37.5165 171.298 37.0246 171.298 36.4828C171.298 35.1689 172.388 34.2348 173.895 34.2348C175.452 34.2348 176.398 35.1004 176.479 36.6198Z" />
        <path d="M178.372 36.8814H179.269V40.6489C179.269 41.9192 179.618 42.3863 180.539 42.3863C181.137 42.3863 181.642 42.0625 181.878 41.502C181.997 41.228 182.046 40.8917 182.046 40.3749V36.8814H182.956V41.1657V41.6826C182.956 42.4859 182.968 42.6167 183.099 43.0339H182.215C182.109 42.7475 182.084 42.5669 182.084 42.2181C181.723 42.9094 181.224 43.1896 180.434 43.1896C179.5 43.1896 178.734 42.7101 178.497 41.9566C178.391 41.6203 178.366 41.3463 178.366 40.6427V36.8814H178.372Z" />
        <path d="M185.31 34.2597H185.347C185.721 34.2597 185.995 34.5461 185.995 34.9322C185.995 35.3121 185.721 35.5799 185.334 35.5799C184.955 35.5799 184.674 35.3059 184.674 34.9322C184.674 34.571 184.942 34.2846 185.31 34.2597ZM184.855 36.8814H185.814V43.0339H184.855V36.8814Z" />
        <path d="M191.798 38.569H190.914C190.821 37.8155 190.435 37.4667 189.656 37.4667C188.953 37.4667 188.473 37.8404 188.473 38.3884C188.473 38.8928 188.735 39.0858 189.912 39.4283C191.014 39.7646 191.381 39.9452 191.636 40.3251C191.805 40.5866 191.898 40.9104 191.898 41.2716C191.898 42.4548 191.039 43.1896 189.662 43.1896C188.18 43.1896 187.34 42.4486 187.34 41.1408V41.1284H188.224C188.299 42.0126 188.772 42.4423 189.65 42.4423C190.453 42.4423 190.964 42.0126 190.964 41.3526C190.964 40.7423 190.808 40.6364 189.195 40.1445C188 39.7646 187.545 39.3162 187.545 38.4569C187.545 37.4169 188.405 36.7195 189.662 36.7195C190.983 36.7132 191.736 37.3733 191.798 38.569Z" />
        <path d="M197.49 38.569H196.606C196.512 37.8155 196.126 37.4667 195.348 37.4667C194.65 37.4667 194.165 37.8404 194.165 38.3884C194.165 38.8928 194.426 39.0858 195.603 39.4283C196.705 39.7646 197.073 39.9452 197.328 40.3251C197.496 40.5866 197.59 40.9104 197.59 41.2716C197.59 42.4548 196.73 43.1896 195.354 43.1896C193.872 43.1896 193.031 42.4486 193.031 41.1408V41.1284H193.916C193.99 42.0126 194.464 42.4423 195.342 42.4423C196.145 42.4423 196.656 42.0126 196.656 41.3526C196.656 40.7423 196.5 40.6364 194.893 40.1445C193.698 39.7646 193.243 39.3162 193.243 38.4569C193.243 37.4169 194.102 36.7195 195.36 36.7195C196.668 36.7132 197.422 37.3733 197.49 38.569Z" />
        <path d="M203.861 40.1133H199.67C199.707 41.6203 200.33 42.4112 201.451 42.4112C202.123 42.4112 202.528 42.1496 202.877 41.4771L203.717 41.5829C203.344 42.6478 202.559 43.1896 201.432 43.1896C199.782 43.1896 198.729 41.9566 198.729 40.0199C198.729 38.0085 199.769 36.707 201.376 36.707C202.883 36.707 203.867 37.94 203.867 39.8518C203.873 39.9078 203.873 39.995 203.861 40.1133ZM202.927 39.3972C202.821 38.1517 202.266 37.473 201.345 37.473C200.448 37.473 199.875 38.133 199.695 39.3972H202.927Z" />
      </g>
    </svg>
  );
};
