import React, {useContext} from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {eventTeamPlayerLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {getProfileImageWt, getProfileImageSrcSet} from 'fiba/common/core/images';
import {getPlayerName} from 'fiba/wt/stores/playerStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {FlexProvider} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {LazyImageWithPlaceholder} from 'fiba/wt/ui/image/LazyImageWithPlaceholder';
import {getEventType} from 'fiba/wt/stores/eventStore';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {getTeam} from 'fiba/wt/stores/teamStore';

interface OwnProps {
  season: string;
  eventId: string;
  teamId: string;
}

type InnerProps = Pick<OwnProps & ReduxProps, 'season' | 'team' | 'eventId'> & {
  eventType: EventType;
};

interface ReduxProps {
  team: Team;
  eventTypeData: RemoteData.WebData<EventType>;
  metaPaths: string[];
}

const mapStateToProps = (state, {eventId, teamId}: OwnProps): ReduxProps => {
  // TODO: Make a helper for this :)
  const eventTypeData = getEventType(state.get('events'), eventId);
  return {
    eventTypeData,
    team: getTeam(state, teamId),
    metaPaths: [`events/__meta/${eventId}`, `teams/__meta/${teamId}`],
  };
};

export const EventTeamPlayersView: React.SFC<InnerProps> = ({season, eventId, team, eventType}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <FlexProvider
      flexDirection="row"
      flexGrow="1"
      alignItems={['center', 'center', 'center', 'start']}
      alignSelf="stretch"
      mnh={['2', '2', '2', '3']}
    >
      {styleProps =>
        team.teamMembers.count() !== 0 ? (
          <React.Fragment>
            <FlexProvider
              width="25"
              ph={['2', '2', '2', '3']}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {itemStyle => (
                <ul {...styleProps}>
                  {(team.teamMembers || List())
                    .sortBy(player => player.lastName)
                    .map(player => (
                      <li key={player.id} {...itemStyle}>
                        <Box
                          height="auto"
                          width="100"
                          mb="2"
                          fontSize="6"
                          textAlign="center"
                          fontWeight="4"
                        >
                          <LazyImageWithPlaceholder
                            aspectRatio="1x1"
                            className="o-fit-contain o-pos-b"
                            containerStyle={{bgColor: 'silver-20'}}
                            alt={`Profile of ${getPlayerName(player)}`}
                            srcSet={getProfileImageSrcSet('wt', player, [
                              'tiny',
                              'small',
                              'medium',
                            ])}
                            src={getProfileImageWt(player, 'small')}
                          />
                        </Box>
                        <StyledLink
                          extraClassName="dn db-l"
                          href={eventTeamPlayerLinkProvider(
                            seasonConfig,
                            season,
                            eventId,
                            eventType,
                            team.id,
                          )(player.id)}
                        >
                          {player.lastName}
                        </StyledLink>
                      </li>
                    ))}
                </ul>
              )}
            </FlexProvider>
          </React.Fragment>
        ) : (
          <Box pv="4" textAlign="center">
            Event roster not yet confirmed
          </Box>
        )
      }
    </FlexProvider>
  );
};

const _EventTeamPlayers: React.SFC<OwnProps & ReduxProps> = ({
  season,
  eventId,
  eventTypeData,
  team,
  metaPaths,
}) => (
  // NOTE: We add both Loading and ViewWebData, while in compatibility mode
  // In practice, the eventType data is always resolved already, so we prioritise Loading
  <div className="EventTeamPlayers">
    <Loading metaPaths={metaPaths}>
      {() => (
        <ViewWebData data={eventTypeData}>
          {eventType => (
            <EventTeamPlayersView
              season={season}
              eventId={eventId}
              team={team}
              eventType={eventType}
            />
          )}
        </ViewWebData>
      )}
    </Loading>
  </div>
);

export const EventTeamPlayers = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventTeamPlayers,
);
