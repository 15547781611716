import {NewsPreviewList} from 'contrib/types/interfaces/api/feed/NewsPreviewList';
import {NewsItemLike} from '../core/models/api/feed/NewsItem';
import {GalleryPreviewLike} from '../core/models/api/media/GalleryPreview';
import {PhotoLike} from '../core/models/api/media/Photo';

const changeImageUrlDomain = (url: string) => {
  if (url.includes('https://content-api.fiba3x3.com')) {
    return url.replace(
      'https://content-api.fiba3x3.com',
      'https://website-content-api.fiba3x3.com',
    );
  }
  return url;
};

export const newsArrayToNewsItemPreviewList = (contentApiNewsArray): NewsPreviewList => {
  const newsPreviewItems = {
    items: contentApiNewsArray.map(newsItem => {
      return {
        id: newsItem['news-id'],
        date: newsItem['date'].split('T')[0],
        title: newsItem['title'],
        slug: newsItem['slug'],
        language: 'en',
        imageBaseUrl: changeImageUrlDomain(newsItem['image-url']),
      };
    }),
  };
  return newsPreviewItems;
};

export const apiNewsToNewsItem = (contentApiNewsItem): NewsItemLike => {
  const modifiedNewsItem = {
    type: 'News',
    id: contentApiNewsItem['news-id'],
    date: contentApiNewsItem['date'].split('T')[0],
    title: contentApiNewsItem['title'],
    slug: contentApiNewsItem['slug'],
    language: 'en',
    contentAbstract: contentApiNewsItem['abstract'],
    content: contentApiNewsItem['body'],
    shareHref: 'https://www.fiba.basketball/',
    imageBaseUrl: changeImageUrlDomain(contentApiNewsItem['image-url']),
  };
  return modifiedNewsItem;
};

export const eventGalleriesToGalleryPreviews = (
  contentApiGalleries,
  eventId,
): {galleryPreviews: GalleryPreviewLike[]} => {
  const photoGalleries = [];
  const keys = Object.keys(contentApiGalleries);
  keys.forEach(key => {
    photoGalleries.push(...contentApiGalleries[key]);
  });
  const galleryPreviews: GalleryPreviewLike[] = photoGalleries.map(gallery => {
    return {
      title: gallery['Title'],
      eventId,
      galleryId: gallery['Id'],
      imageUrl: changeImageUrlDomain(gallery['DefaultImage']['ImageUrl']),
      total: gallery['ImageCount'],
    };
  });
  return {galleryPreviews};
};

export const tourGalleriesToGalleryPreviews = (
  contentApiGalleries,
): {galleryPreviews: GalleryPreviewLike[]} => {
  const galleryPreviews: GalleryPreviewLike[] = contentApiGalleries.map(gallery => {
    return {
      title: gallery['Title'],
      eventId: gallery['eventId'],
      galleryId: gallery['Id'],
      imageUrl: changeImageUrlDomain(gallery['DefaultImage']['ImageUrl']),
      total: gallery['ImageCount'],
    };
  });
  return {galleryPreviews};
};

export const apiPhotosArrayToPhotoList = (contentApiPhotosArray): {photos: PhotoLike[]} => {
  const filteredArray = contentApiPhotosArray.filter(photo => Object.keys(photo).length > 0);
  return {
    photos: filteredArray.map(photo => {
      const splitArray = photo['ImageUrl'].split('/');
      const id = splitArray[splitArray.length - 1];
      return {
        id,
        imageBaseUrl: changeImageUrlDomain(photo['ImageUrl']),
      };
    }),
  };
};
