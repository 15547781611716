import React from 'react';
import unified from 'unified';
import markdown from 'remark-parse';
import html from 'remark-html';
import normalize from 'mdurl/encode';
import {sanitizationSchema} from 'fiba/common/ui/markdown/Markdown';

/**
 * Render a block of Markdown safely, with optimisations
 * for Contentful images.
 */
export const ContentfulMarkdown = ({content, ...props}) => (
  <div
    {...props}
    dangerouslySetInnerHTML={{
      __html: unified()
        .use(markdown)
        .use(html, {
          sanitize: sanitizationSchema,
          handlers: {image: contentfulImageHandler},
        })
        .processSync(content)
        .toString(),
    }}
  />
);

/**
 * Render a block of Markdown unsafely, with optimisations
 * for Contentful images.
 *
 * WARNING: This allows iframes, scripts, and other bad thigns through.
 * Use on a case-by-case basis!
 */
export const DangerousContentfulMarkdown = ({content, ...props}) => (
  <div
    {...props}
    dangerouslySetInnerHTML={{
      __html: unified()
        .use(markdown)
        .use(html, {handlers: {image: contentfulImageHandler}})
        .processSync(content)
        .toString(),
    }}
  />
);

//
// Image Optimisations

interface ImageNode {
  type: string;
  url?: string;
  alt?: string;
  title?: string;
  value?: string;
  [key: string]: unknown;
}

/**
 * Transform Contentful `image` references in a Markdown AST (mdast)
 * into specific `img` elements, that have responsive and performant
 * source options.
 *
 * NOTE: We do this as part of the mapping from the mdast to the HTML AST (hast)
 * The reason for that is we need to change the emission of HTML, and doing it
 * in the mdast would be unsafe (raw html).
 *
 * This will only work for markdown syntax image tags.
 * We do not handle raw HTML tags, both as an escape hatch
 * and also to avoid having to transform HTML on the client.
 *
 * @todo: Include `sizes` attribute
 */
const contentfulImageHandler = (h, node: ImageNode) => {
  // If the image is hosted on contentful
  // 1. Find the image file
  // 2. Create the responsive images from a set of widths.
  // 3. Add WebP and Jpeg sources
  // 4. Set the html, as a hast node

  const generateSourcesAndGetHastNode = function (node: ImageNode): string | undefined {
    let originalImg = node.url;
    if (!/^(http|https)?:\/\//i.test(node.url)) {
      originalImg = `https:${node.url}`;
    }

    const responsiveSizesResult = buildResponsiveSizes({
      imageUrl: originalImg,
    });

    const fallbackSrc = originalImg;
    const srcSet = responsiveSizesResult.srcSet;

    return h(node, 'picture', {}, [
      h({}, 'source', {srcset: responsiveSizesResult.webpSrcSet, type: 'image/webp'}),
      h({}, 'source', {srcset: srcSet}),
      imgHandler({}, h, {
        url: fallbackSrc,
        alt: node.alt ? node.alt : '',
        loading: 'lazy',
        class: 'ContentfulMarkdown-Image',
      }),
    ]);
  };

  // Images that are from Contentful will get a custom type
  if (node.url.includes(`images.ctfassets.net`)) {
    const hastNode = generateSourcesAndGetHastNode(node);

    // Replace the image node with an inline HTML node.
    return hastNode;
  }

  // If the asset is an pdf linked from contentful, use a pdf attachment html
  else if (node.url.includes('assets.ctfassets.net') && node.url.includes('.pdf')) {
    return contentfulPdfLinkHandler(h, node);
  }

  // Image isn't relative so we use a default `img`
  return imgHandler(node, h, {url: node.url, alt: node.alt});
};

function imgHandler(node, h, {url, alt, ...rest}) {
  const props = {src: normalize(url), alt: alt};

  return h(node, 'img', {...rest, ...props});
}

/**
 * A hardcoded list of widths that we keep for our images.
 * These are arbitrary, but seem to work OK for both Contentful
 * and Imgix (@see Imgix.tsx).
 */
const WIDTHS = [320, 480, 768, 992, 1280, 1600];
const QUALITY = 80;

/**
 * Generate a set of src and srcSet, at a set of widths,
 * with a web quality and in both WebP and Jpeg formats.
 */
export const buildResponsiveSizes = ({imageUrl, options = {}}) => {
  const srcSet = WIDTHS.map(
    size => `${imageUrl}?w=${Math.round(size)}&q=${QUALITY} ${Math.round(size)}w`,
  ).join(`,\n`);

  const webpSrcSet = WIDTHS.map(
    size => `${imageUrl}?fm=webp&w=${Math.round(size)}&q=${QUALITY} ${Math.round(size)}w`,
  ).join(`,\n`);

  return {
    src: imageUrl,
    srcSet,
    webpSrcSet,
  };
};

const contentfulPdfLinkHandler = (h, node: ImageNode) => {
  return {
    type: 'element',
    tagName: 'a',
    properties: {href: node.url, download: true, className: ['PdfLink']},
    children: [{type: 'text', value: node.alt}, getDownloadSvg(h)],
  };
};

// This is the download svg from @fpapado/react-feather but in plain svg format so we can style the color in css
// as we cant use the react components here
const getDownloadSvg = h => {
  return h(
    {},
    'svg',
    {
      xmlns: 'http://www.w3.org/2000/svg',
      width: '24',
      height: '24',
      viewBox: '0 0 24 24',
      stroke: 'currentColor',
      'stroke-width': '2',
      fill: 'none',
      'stroke-linecap': 'round',
      'stroke-linejoin': 'round',
    },
    [
      h({}, 'path', {d: 'M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4'}),
      h({}, 'polyline', {points: '7 10 12 15 17 10'}),
      h({}, 'line', {x1: '12', y1: '15', x2: '12', y2: '3'}),
    ],
  );
};
