import {map, join} from 'lodash';
import {WidthHeight, calculateDimensions} from 'fiba/wt/utils/imageUtils';

// TODO: WIP utilities to form the image parameters by imgix URL API
// To add new params, just add them to ImgixConfig and then to paramSpec
// @see https://docs.imgix.com/apis/url

// Spec utilities
type MapFn<P> = (prop: string) => (value: P) => string;

const param: MapFn<number | string> = prop => value => (value ? `${prop}=${value}` : '');

const arrayParam: MapFn<string[]> = prop => values => param(prop)(values.join(','));

const concatString = (a: string, b: string) => `${a}${b}`;

// Options that Imgix functions accept
// @see https://github.com/imgix/imgix-url-params/blob/master/dist/parameters.json
export interface ImgixConfig {
  width?: number;
  height?: number;
  crop?: Array<'CenterTop' | 'Center' | 'CenterBottom'>;
}

// Map from imgix config props to functions which transform their values to URL params
type ImgixParamSpec = {[P in keyof ImgixConfig]: (value: ImgixConfig[P]) => string};

// Maps ImgixConfig props to their URL param and value
const paramSpec: ImgixParamSpec = {
  width: param('w'),
  height: param('h'),
  crop: arrayParam('crop'),
};

// Compute one of the dimensions if it is missing and the aspect ratio is known
export const scaledImageParams = ({width, height}: WidthHeight, aspectRatio?: [number, number]) => {
  return aspectRatio ? {...calculateDimensions({width, height}, aspectRatio)} : {width, height};
};

/* Generate srcSet values
 * Currently, these include just the DPR (device pixel ratio) variants,
 */
export const generateSrcsets = ({baseConfig, baseUrl, aspectRatio}): string[] =>
  [320, 480, 768, 992, 1280].map(
    width =>
      `${imgixSrc(
        {...baseConfig, ...calculateDimensions({width}, aspectRatio)},
        baseUrl,
      )} ${width}w`,
  );

/* Curried function that allows specifying config and then accepts baseUrl */
export const imgixSrc = (config: ImgixConfig, baseUrl: string) =>
  concatString(
    `${baseUrl}?`,
    join(
      // Transform each prop in config to a URL param, using the coresponding function in ParamSpec
      map(config as any, (propValue, propName) => paramSpec[propName](propValue)),
      '&',
    ),
  );
