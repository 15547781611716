import React from 'react';
import {List} from 'immutable';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Box} from 'fiba/wt/ui/box/Box';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {EventStandings} from 'fiba/wt/ui/eventStandings/EventStandings';
import {PoolsOverview} from 'fiba/wt/ui/poolsOverview/PoolsOverview';
import {BracketsView} from 'fiba/wt/ui/eventStandingsPage/BracketsView';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';
import {ResourceLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';

interface EventStandingsTabProps {
  season: string;
  eventId: string;
  eventType: EventType;
  qualifyingDrawGroups: List<ResultsGroup>;
  poolGroups: List<ResultsGroup>;
  knockoutBrackets: List<List<ResultsGameSummary>>;
  createPoolHref: ResourceLinkProvider;
  createQDHref: ResourceLinkProvider;
  categoryIdFilter?: List<string>;
}

export const EventStandingsTab: React.FunctionComponent<EventStandingsTabProps> = ({
  season,
  eventId,
  eventType,
  qualifyingDrawGroups,
  poolGroups,
  knockoutBrackets,
  categoryIdFilter,
  createPoolHref,
  createQDHref,
}) => (
  <Spacer vSpace="4" extraClassName="EventStandingsTab">
    <EventStandings categoryIdFilter={categoryIdFilter} season={season} eventId={eventId} />

    <EventProgrammability
      season={season}
      eventId={eventId}
      renderDefault={() => null}
      renderPost={() => (
        <React.Fragment>
          <Spacer vSpace="3">
            <Heading fontSize="3">Brackets</Heading>

            <Section>
              <BracketsView knockoutBrackets={knockoutBrackets} />
            </Section>
          </Spacer>

          <Box>
            <Heading fontSize="3">Pool standings overview</Heading>

            <Section>
              <PoolsOverview
                season={season}
                eventId={eventId}
                eventType={eventType}
                pools={poolGroups}
                createPoolHref={createPoolHref}
              />
            </Section>
          </Box>

          {qualifyingDrawGroups.size > 0 && (
            <Box>
              <Heading fontSize="3">Qualification draw overview</Heading>

              <Section>
                <PoolsOverview
                  season={season}
                  eventId={eventId}
                  eventType={eventType}
                  pools={qualifyingDrawGroups}
                  createPoolHref={createQDHref}
                />
              </Section>
            </Box>
          )}
        </React.Fragment>
      )}
    />
  </Spacer>
);
