import React from 'react';
import {Image, IImage} from 'fiba/wt/ui/image/Image';
import {ImgixQuery, getImgixImageProps} from 'fiba/wt/ui/imgix/Imgix';

//
// GENERAL IMGIX IMAGE

export type ImgixImageProps = ImgixQuery & IImage;

export const ImgixImage: React.FunctionComponent<ImgixImageProps> = ({
  baseUrl,
  imgixParams,
  width,
  height,
  aspectRatio,
  sizes,
  autoSrcset = true,
  // NOTE: Putting ref out here because TS complains about it being LegacyRef when Image expects RefObject
  // Ref is not even a prop! So we won't be passing it down anyway...
  // @see also: Image.tsx
  ref,
  ...rest
}) => {
  const imageProps = getImgixImageProps({
    baseUrl,
    imgixParams,
    width,
    height,
    aspectRatio,
    sizes,
    autoSrcset,
  });
  return <Image {...{...rest, ...imageProps}} />;
};
