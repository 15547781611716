import {fromJS, Map} from 'immutable';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {createActions, createReducer, resolvePathWithGender} from 'fiba/wt/utils/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import TeamTourStatsResponse, {
  TeamTourStatsResponseLike,
} from 'fiba/common/core/models/api/stats/TeamTourStatsResponse';
import {rethrow, setMeta} from 'fiba/common/stores/storeUtils';
import ResultsTeam from 'fiba/common/core/models/api/results/ResultsTeam';
import {mapValues} from 'lodash';
import {
  SortableStore,
  SortableStoreItem,
  sortingReducers,
} from 'fiba/wt/stores/reducers/sortableStore';
import {TeamStats} from 'contrib/types/interfaces/api/stats/TeamStats';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';

export const statePath = (tourOrEventId: string, category?: string) => {
  if (category) {
    return [tourOrEventId, category];
  }
  return [tourOrEventId];
};
const metaPath = (tourOrEventId: string, category?: string) => [
  '__meta',
  ...statePath(tourOrEventId, category),
];
export const storePath = 'teamStats';
export const globalStatePath = (tourOrEventId: string, category?: string) => [
  storePath,
  ...statePath(tourOrEventId, category),
];
export const globalMetaPath = (tourOrEventId: string, category?: string) => [
  storePath,
  ...metaPath(tourOrEventId, category),
];

export interface TeamStatRow extends TeamStats {
  teamName: string;
  teamNameSuffix: string;
  isTourTeam: boolean;
  teamInEventId?: string;
}

export interface TeamStatsStore extends SortableStore<TeamStatRow> {}

const mapResponse = (response: TeamTourStatsResponse): SortableStoreItem<TeamStatRow> => {
  const teamsById: Map<string, ResultsTeam> = response.teams.reduce(
    (map, value) => map.set(value.teamId, value),
    Map(),
  );

  const value: SortableStoreItem<TeamStatRow> = {
    sortedBy: {
      fieldName: null,
      sortOrder: 'none',
    },
    updatedAt: response.updatedAt,
    data: response.teamStatistics
      .map(stat =>
        // We merge the data from the teams list, with the team stats
        // This is a bit annoying, and not sure whether we should rework it
        // TODO: Use merge here
        fromJS({
          teamName: teamsById.get(stat.teamId).teamName,
          teamNameSuffix: teamsById.get(stat.teamId).teamNameSuffix,
          teamInEventId: teamsById.get(stat.teamId).teamInEventId,
          isTourTeam: teamsById.get(stat.teamId).isTourTeam,
          ...stat.toJS(),
        }),
      )
      .toList(),
  };

  return fromJS(value);
};

const reducers = {
  ...sortingReducers,

  loadTourTeamStats: (state: TeamStatsStore, tourOrEventId: string): TeamStatsStore => {
    return state.updateIn(metaPath(tourOrEventId), setMeta.isLoading);
  },

  loadEventTeamStats: (
    state: TeamStatsStore,
    eventId: string,
    _categoryId: string,
    gender: CategoryGenderTab,
  ): TeamStatsStore =>
    state.updateIn(['__meta', ...resolvePathWithGender(eventId, gender)], setMeta.isLoading),

  loadTeamStatsSuccess: (
    state: TeamStatsStore,
    tourOrEventId: string,
    response: TeamTourStatsResponseLike,
    gender?: CategoryGenderTab,
  ): TeamStatsStore => {
    /* If stats have totalPointsMade, let's default to sorting with that */
    response.teamStatistics.sort((a, b) => b.totalPointsMade - a.totalPointsMade);
    const value = mapResponse(TeamTourStatsResponse.fromJS(response));
    const tourOrEventPath = resolvePathWithGender(tourOrEventId, gender);
    return state
      .setIn(tourOrEventPath, value)
      .updateIn(['__meta', ...tourOrEventPath], setMeta.isLoaded);
  },

  loadStatsError: (
    state: TeamStatsStore,
    tourOrEventId: string,
    _error: Error,
    gender?: CategoryGenderTab,
  ) => {
    return state.updateIn(
      ['__meta', ...resolvePathWithGender(tourOrEventId, gender)],
      setMeta.isError,
    );
  },
};

const externalReducers = {};

export const reducer = createReducer<TeamStatsStore>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

export const actions = createActions(__filename, reducers, {
  loadTourTeamStats: (tourId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadTeamTourStats(tourId)
      .then(response => actions.loadTeamStatsSuccess(tourId, response.data))
      .catch(rethrow((err: Error) => actions.loadStatsError(tourId, err))),

  loadEventTeamStats: (eventId: string, categoryId: string, gender: CategoryGenderTab) => ({
    apiClient,
  }: CommonServices) =>
    apiClient
      .loadTeamEventStats(eventId, categoryId)
      .then(response => actions.loadTeamStatsSuccess(eventId, response.data, gender))
      .catch(rethrow((err: Error) => actions.loadStatsError(eventId, err, gender))),

  loadStatsError: (tourOrEventId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

// Arbitrary JSON -> State
// State as JSON -> State (which is an Immutable map)
export const deserializeState = ({__meta, ...items}) => {
  // Make sure known things exist
  return fromJS({
    __meta: __meta || {},
    ...mapValues(items, item => fromJS(item)),
  });
};
