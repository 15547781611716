import {isBoolean} from 'lodash';
import {loadFeatures} from 'fiba/common/controllers/controllerUtils';
import {bind} from 'fiba/common/stores/storeUtils';
import categoryController, {
  CategoryControllerFeatures,
} from 'fiba/wt/controllers/categoryController';
import eventActions from 'fiba/wt/stores/eventStore';
import categoryActions from 'fiba/wt/stores/categoryStore';
import teamActions from 'fiba/wt/stores/teamStore';
import gameActions from 'fiba/wt/stores/gameStore';
import eventActivityActions from 'fiba/wt/stores/eventActivityStore';
import tourActions from 'fiba/wt/stores/tourStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {List} from 'immutable';

export interface EventControllerFeatures {
  organizer?: boolean;
  tour?: boolean;
  categories?: boolean | CategoryControllerFeatures;
  teams?: boolean;
  games?: boolean;
  activities?: boolean;
  worldTourGames?: boolean;
  categoriesByWorldTour?: boolean | CategoryControllerFeatures;
}

function eventController(eventId: string, features: EventControllerFeatures) {
  return (services: CommonServices) => {
    const {dispatch, cache} = services;

    // Extra features that can be loaded with event
    const featureLoaders = {
      // FIXME: Should `categories` be loaded instead through `categoryController`?
      // @example `[categoryController({eventId})]`
      // Reason for this would be that we could then load categories in parallel to the event
      // since loading categories doesn't really depend on event data.
      // Or alternatively do load them in parallel to event separate from other features.
      // Same for `teams` and `games`

      categories: (features: boolean | CategoryControllerFeatures) =>
        cache(['events', '__meta', eventId, 'categories'], () => {
          if (isBoolean(features)) {
            return dispatch(categoryActions.loadCategoriesByEvent(eventId));
          }

          // Load the categories by event, then get the list of refs from where they're stored,
          // and then load information about each category.
          // TODO: This is kind of ugly / requires implicit knowledge about where
          // data is stored. Is there a better way to do this?
          return bind(dispatch(categoryActions.loadCategoriesByEvent(eventId))).then(() =>
            bind(
              RemoteData.match(
                cache(['events', '__meta', eventId, 'categories']) as RemoteData.WebData<
                  List<string>
                >,
                {
                  Success: categoryIds =>
                    categoryIds
                      .map((categoryId: string) =>
                        categoryController(categoryId, features)(services),
                      )
                      .toArray(),
                  default: () => [],
                },
              ),
            ),
          );
        }),

      categoriesByWorldTour: (features: boolean | CategoryControllerFeatures) =>
        cache(['events', '__meta', eventId, 'categories'], () => {
          if (isBoolean(features)) {
            return dispatch(categoryActions.loadWorldTourCategoriesByEvent(eventId));
          }

          return bind(dispatch(categoryActions.loadWorldTourCategoriesByEvent(eventId))).then(() =>
            bind(
              RemoteData.match(
                cache(['events', '__meta', eventId, 'categories']) as RemoteData.WebData<
                  List<string>
                >,
                {
                  Success: categoryIds =>
                    categoryIds
                      .map((categoryId: string) =>
                        categoryController(categoryId, features)(services),
                      )
                      .toArray(),
                  default: () => [],
                },
              ),
            ),
          );
        }),

      teams: () =>
        cache(['events', '__meta', eventId, 'teams'], () =>
          dispatch(teamActions.loadTeamsByEvent(eventId)),
        ),

      games: () =>
        cache(['events', '__meta', eventId, 'games', 'summary'], () =>
          dispatch(gameActions.loadGamesSummaryByEvent(eventId)),
        ),

      worldTourGames: () =>
        cache(['events', '__meta', eventId, 'games', 'summary'], () =>
          dispatch(gameActions.loadWorldTourGamesSummary(eventId)),
        ),

      activities: () =>
        cache(['events', '__meta', eventId, 'activities'], () =>
          dispatch(eventActivityActions.loadActivitiesByEvent(eventId)),
        ),

      tour: () => {
        const tourId = cache(['events', eventId, 'tourId']);
        return tourId && cache(['tours', tourId], () => dispatch(tourActions.loadTour(tourId)));
      },
    };

    return cache(['events', eventId], () => dispatch(eventActions.loadEvent(eventId))).then(() =>
      loadFeatures(featureLoaders, features, cache(['events', eventId])),
    );
    // Don't set a catch handler, but let the errors ripple back to router
  };
}

export default eventController;
