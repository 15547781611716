import React from 'react';
import {SponsorBox, SponsorType} from 'fiba/wt/ui/sponsorBox/SponsorBox';

export {SponsorType};

interface Props {
  // Giving (tourId, eventId) will fetch event sponsors,
  // giving only (tourId) will fetch tour sponsors
  tourId: string;
  eventId?: string;
  types: SponsorType[];
}

export const SponsorBoxList: React.FunctionComponent<Props> = ({types, ...props}) => (
  <ul className="vs2">
    {types.map(type => (
      <li key={type} className="db">
        <SponsorBox {...props} type={type} />
      </li>
    ))}
  </ul>
);
