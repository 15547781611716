import {fromJS, List, Map} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import CommonServices from 'fiba/common/core/models/CommonServices';
import notificationActions from 'fiba/common/stores/notificationStore';
import gameStatsActions from 'fiba/wt/stores/gameStatsStore';
import {Player, PlayerLike} from 'fiba/common/core/models/api/players/Player';
import {GameStatsLike} from 'fiba/common/core/models/api/stats/GameStats';
import {RootState} from 'fiba/wt/stores/rootStore';

export interface PlayerStoreState extends Map<string, Player> {
  // TODO: `__meta`
}

const reducers = {
  loadPlayer: (state: PlayerStoreState, playerId: string): PlayerStoreState =>
    state.updateIn(['__meta', playerId], setMeta.isLoading),

  loadPlayerSuccess: (
    state: PlayerStoreState,
    playerId: string,
    player: PlayerLike,
  ): PlayerStoreState =>
    state.set(playerId, Player.fromJS(player)).updateIn(['__meta', playerId], setMeta.isLoaded),

  loadPlayerError: (state: PlayerStoreState, playerId: string, error: Error): PlayerStoreState =>
    state.updateIn(['__meta', playerId], setMeta.isError),
};

const externalReducers = {
  [gameStatsActions.types.loadGameStatsSuccess]: (
    state: PlayerStoreState,
    gameId: string,
    gameStats: GameStatsLike,
    players: PlayerLike[],
  ): PlayerStoreState =>
    players.reduce((acc, player) => reducers.loadPlayerSuccess(acc, player.id, player), state),
};

export const reducer = createReducer<PlayerStoreState>(
  __filename,
  fromJS({}),
  reducers,
  externalReducers,
);

const actions = createActions(__filename, reducers, {
  loadPlayer: (playerId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadPlayerProfile(playerId)
      .then(res => actions.loadPlayerSuccess(playerId, res.data))
      .catch(rethrow((err: Error) => actions.loadPlayerError(playerId, err))),

  loadPlayerError: (playerId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<PlayerStoreState>(Player);

//
// Utils
export const getPlayerName = (player: Player) => {
  const firstName = player && player.firstName;
  const lastName = player && player.lastName;
  const name = [];

  if (firstName) {
    name.push(firstName);
  }
  if (lastName) {
    name.push(lastName);
  }

  if (!name.length) {
    return null;
  }

  return name.join(' ');
};

export const getPlayerNewsReferences = (state: RootState, playerId: string, eventId: string) =>
  state.getIn([...getPlayerNewsMetaPath(playerId, eventId), 'refs'], List());

export const getPlayerNewsMetaPath = (playerId: string, eventId: string): string[] => [
  'players',
  ...newsInternalMetaPath(playerId, eventId),
];

export const getPlayerNewsPath = (playerId: string, eventId: string): string[] => [
  'players',
  playerId,
  'eventNews',
  eventId,
];

function newsInternalMetaPath(playerId: string, eventId: string) {
  return ['__meta', playerId, 'eventNews', eventId];
}
