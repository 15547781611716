import React from 'react';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {P} from 'fiba/wt/ui/text/Text';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

interface ITopHeader {
  extraClassName?: string;
  display?: Array<StyleProp<'display'>>;
}

export const TopHeader: React.FunctionComponent<ITopHeader> = ({
  extraClassName,
  display,
  children,
}) => (
  <SiteConfigContext.Consumer>
    {({features, theme}) => (
      <Box
        display={display}
        position="relative"
        extraClassName={extraClassName}
        debugClassName="TopHeader"
      >
        <Box bgColor={theme.headerColor}>
          <Flex
            ph="3"
            pv="2"
            display="flex"
            justifyContent="between"
            extraClassName="hs3 overflow-x-auto"
          >
            <HeaderSmallText
              color="sand"
              content="fiba.basketball"
              linkTo="https://www.fiba.basketball/"
            />
          </Flex>
        </Box>
        {children}
      </Box>
    )}
  </SiteConfigContext.Consumer>
);

//
// Helper view

interface IHeaderSmallText {
  color: StyleProp<'color'>;
  content: React.ReactNode;
  linkTo: string;
}

const HeaderSmallText: React.SFC<IHeaderSmallText> = ({color, content, linkTo}) => (
  <a href={linkTo} target="_blank" rel="noopener noreferrer" className="link link--bare">
    <P
      mt="0"
      mb="0"
      fontSize="8"
      fontWeight="6"
      textTransform="uppercase"
      color={color}
      extraClassName="ws-nowrap"
    >
      {content}
    </P>
  </a>
);
