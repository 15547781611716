import React from 'react';
import cx from 'classnames';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {Placeholder, PlaceholderStyleProps} from 'fiba/wt/ui/placeholder/Placeholder';
import {ImgixImage, ImgixImageProps} from 'fiba/wt/ui/imgix/ImgixImage';

//
// WITH PLACEHOLDER

/** Unsafe cast to aspectRatio */
export const toAspectRatio = (ar: [number, number]): StyleProp<'aspectRatio'> =>
  `${ar[0]}x${ar[1]}` as StyleProp<'aspectRatio'>;

export type IImgixImageWithPlaceholder = ImgixImageProps & {
  containerStyle?: PlaceholderStyleProps;
  containerClassName?: string;
};

/** Image that holds its place based on an aspectRatio.
 * A pre-composed version of Placeholder and ImgixImage.
 */
export const ImgixImageWithPlaceholder: React.SFC<IImgixImageWithPlaceholder> = ({
  containerClassName,
  containerStyle,
  className,
  ...imageProps
}) => (
  <Placeholder
    aspectRatio={toAspectRatio(imageProps.aspectRatio)}
    extraClassName={containerClassName}
    {...containerStyle}
    image={placeholderItemCls => (
      <ImgixImage className={cx(placeholderItemCls, className)} {...imageProps} />
    )}
  />
);

ImgixImageWithPlaceholder.displayName = 'ImgixImageWithPlaceholder';
