import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {eventRootLink, eventScheduleLink, EventType} from 'fiba/wt/utils/linkUtils';
import {
  ContentfulEmbeddedMediaSummary,
  EmbeddedMediaTypes,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {GameCardWidget} from 'fiba/wt/ui/gameCardWidget/GameCardWidget';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Box} from 'fiba/wt/ui/box/Box';
import {GhostLink} from 'fiba/wt/ui/link/Link';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import Event from 'fiba/common/core/models/api/events/Event';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {deduceEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';

// Props from caller
interface OwnProps {
  eventId: string;
  season: string;
  tourId: string;
  siteConfig: ISiteConfig;
}

// Derived props from Redux
interface ReduxProps {
  data: RemoteData.WebData<{
    eventName: string;
    eventType: EventType;
  }>;
}

// Connect to Redux
const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  const eventData: RemoteData.WebData<Event> = state.getIn(
    ['events', eventId],
    RemoteData.NotAsked(),
  );

  return {
    data: RemoteData.map(eventData, event => {
      return {
        // Show the shortName, or full name if not available
        eventName: event.shortName || event.name,
        eventType: deduceEventType(event),
      };
    }),
  };
};

/** Implementation with all props */
const _EventPortalLive: React.SFC<OwnProps & ReduxProps> = ({
  eventId,
  season,
  siteConfig,
  data,
}) => (
  <ViewWebData data={data}>
    {({eventName, eventType}) => (
      <>
        <Heading pl={['3', '3', '0']}>{eventName}</Heading>
        <Box>
          <GameCardWidget season={season} eventId={eventId} />
        </Box>

        <Box>
          <ContentfulEmbeddedMediaSummary
            contentPath={`events/${eventId}`}
            mediaType={EmbeddedMediaTypes.YoutubeMainLivestream}
            widthHint={720} // TODO: discuss
            season={season}
            eventId={eventId}
            siteConfig={siteConfig}
          />
        </Box>

        <Flex
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          maxWidth="6"
          align="center"
        >
          <GhostLink
            variant={siteConfig.theme.ghostlinkTheme}
            extraClassName="flex-auto ma1"
            href={eventRootLink(siteConfig.seasonConfig, season, eventId, eventType)}
          >
            Go to <span className="db fw7">Event Overview</span>
          </GhostLink>
          <GhostLink
            variant={siteConfig.theme.ghostlinkTheme}
            extraClassName="flex-auto ma1"
            href={eventScheduleLink(siteConfig.seasonConfig, season, eventId, eventType)}
          >
            Go to <span className="db fw7">Event Schedule</span>
          </GhostLink>
        </Flex>
      </>
    )}
  </ViewWebData>
);

/** Event Portal in Live state */
export const EventPortalLive = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(_EventPortalLive);
