import {ISeasonConfig, Season} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {get} from 'lodash';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import Event from 'fiba/common/core/models/api/events/Event';

export type EventType = Event['eventType'];
export type ResourceLinkProvider = (resourceId: string) => string;

export const eventRootLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  useShortName = true,
) => {
  const shortName = getEventShortName(seasonConfig, season, eventType, eventId);
  const event = shortName && useShortName ? shortName : eventId;

  switch (eventType) {
    case 'Challenger': {
      return `/${season}/challengers/${event}`;
    }
    case 'SuperQuest': {
      return `/${season}/superquests/${event}`;
    }
    case 'WorldTour':
    case 'WomensSeries':
    case 'Cup':
    case 'NationsLeague':
    default: {
      return `/${season}/${event}`;
    }
  }
};

const eventResource = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  resourceType: string,
) => (resourceId: string) =>
  `${eventRootLink(seasonConfig, season, eventId, eventType)}/${resourceType}/${resourceId}`;

export const eventTeamsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/teams`;

export const eventTeamLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
): ResourceLinkProvider => eventResource(seasonConfig, season, eventId, eventType, 'teams');

export const eventTeamPlayerLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  teamId: string,
) => (playerId: string) =>
  `${eventTeamLinkProvider(seasonConfig, season, eventId, eventType)(teamId)}/players/${playerId}`;

export const eventScheduleLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/games`;

export const gameLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
): ResourceLinkProvider => eventResource(seasonConfig, season, eventId, eventType, 'games');

export const eventConferenceStandingsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => {
  return `${eventRootLink(seasonConfig, season, eventId, eventType)}/conference/standings`;
};

export const eventStandingsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  genderTab: CategoryGenderTab,
  useShortName = true,
) => {
  const genderPart = genderTab ? `/${genderTab}` : '';
  return `${eventRootLink(
    seasonConfig,
    season,
    eventId,
    eventType,
    useShortName,
  )}/standings${genderPart}`;
};

export const eventStandingsLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  genderTab: CategoryGenderTab = '',
): ResourceLinkProvider => {
  const genderPart = genderTab ? `/${genderTab}` : '';
  return eventResource(seasonConfig, season, eventId, eventType, `standings${genderPart}`);
};

export const eventNewsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/news`;

export const eventNewsItemLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
): ResourceLinkProvider => eventResource(seasonConfig, season, eventId, eventType, 'news');

export const eventPhotosLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/photos`;

export const eventTeamNewsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  teamId: string,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/teams/${teamId}/news`;

export const eventPhotoGalleryLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
): ResourceLinkProvider => eventResource(seasonConfig, season, eventId, eventType, 'photos');

export const eventStatisticsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
  genderTab: CategoryGenderTab = '',
  useShortName = true,
) => {
  const genderPart = genderTab ? `/${genderTab}` : '';
  return `${eventRootLink(
    seasonConfig,
    season,
    eventId,
    eventType,
    useShortName,
  )}/stats${genderPart}`;
};

export const eventVideosLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/videos`;

export const eventDunkLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/dunk`;

export const eventShootoutLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/shootout`;

export const eventHowToQualifyLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/how-to-qualify`;

export const eventHistoryLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/history`;

export const eventMediaServicesLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/media-services`;

export const eventAwardsLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/awards`;

export const eventPrizesLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/prizes`;

export const eventAboutLink = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => `${eventRootLink(seasonConfig, season, eventId, eventType)}/about`;
//
// Tour

export const tourTeamLinkProvider = (season: string) => tourResource(season, 'teams');

export const tourTeamPlayerLinkProvider = (season: string, teamId: string) => (playerId: string) =>
  `${tourTeamLinkProvider(season)(teamId)}/players/${playerId}`;

export const tourStatisticsLinkProvider = (season: string) => `/${season}/stats`;

const tourResource = (season: string, resourceType: string) => (resourceId: string) =>
  `/${season}/${resourceType}/${resourceId}`;

type EventTypeSlug = Extract<
  keyof ISeasonConfig['seasons'][Season],
  'events' | 'superQuests' | 'challengers'
>;

function mapEventTypeToSeasonConfigSlug(eventType: EventType): EventTypeSlug {
  switch (eventType) {
    case 'Challenger': {
      return 'challengers';
    }
    case 'SuperQuest': {
      return 'superQuests';
    }
    default: {
      return 'events';
    }
  }
}
/**
 * Try to find matching short name for the eventId (e.g. 'poitiers' or 'constanta-final').
 */
const getEventShortName = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventType: EventType,
  eventId: string,
): string => {
  const eventTypeSlug = mapEventTypeToSeasonConfigSlug(eventType);
  const events = get(seasonConfig.seasons, [season, eventTypeSlug], {});
  const shortName = Object.keys(events).find(shortName => events[shortName] === eventId);
  return shortName;
};

export const curriedEventTeamPlayerLinkProvider = (
  seasonConfig: ISeasonConfig,
  season: string,
  eventId: string,
  eventType: EventType,
) => (teamId: string) => (playerId: string) =>
  eventTeamPlayerLinkProvider(seasonConfig, season, eventId, eventType, teamId)(playerId);
