import React from 'react';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {ProCircuitTeamStatistics} from 'fiba/wt/ui/common/statistics/ProCircuitTeamStatistics';

export interface OwnProps {
  season: string;
  tourId: string;
}

export const ProCircuitTourStatisticsTeamsTab: React.FC<OwnProps> = ({season, tourId}) => (
  <Spacer vSpace="4">
    <ProCircuitTeamStatistics season={season} tourId={tourId} />
  </Spacer>
);
