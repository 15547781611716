import {AxiosError, AxiosInstance, InternalAxiosRequestConfig} from 'axios';

export const cacheControlMiddleware = (url: string) => (axios: AxiosInstance) => {
  if (urlWithCacheControlDirective(url)) {
    axios.interceptors.request.use(cacheControlHandler(url), (error: AxiosError) =>
      Promise.reject(error),
    );
  }
  return axios;
};

const urlWithCacheControlDirective = (url: string): boolean => {
  const parsedURL = new URL(url);
  return parsedURL.searchParams.get('_x-cache-control') ? true : false;
};

const cacheControlHandler = (url: string) => (config: InternalAxiosRequestConfig) => {
  const parsedURL = new URL(url);
  config.headers['X-Cache-Control'] = parsedURL.searchParams.get('_x-cache-control');
  return config;
};

export default cacheControlMiddleware;
