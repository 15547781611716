import {
  StyledAPI,
  StyleProps,
  createStyleProvider,
  superstyled,
  StyledHTML,
} from 'fiba/wt/ui/stylingAPI/makeStyled';

// Tuning knobs for this component
type BoxStylingAPI = StyledAPI<
  | 'fontSize'
  | 'fontWeight'
  | 'color'
  | 'bgColor'
  | 'opacity'
  | 'display'
  | 'align'
  | 'textAlign'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'measure'
  | 'shadow'
  | 'position'
  | 'border'
  | 'borderRadius'
  | 'borderWidth'
  | 'borderColor'
  | 'ma'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'mn'
  | 'mnv'
  | 'mnh'
  | 'mnl'
  | 'mnt'
  | 'mnr'
  | 'mnb'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'flex'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexOrder'
  | 'alignSelf'
  | 'lineHeight'
  | 'justifyContent'
  | 'flexWrap'
>;

// Export this for convenience
export type BoxStyleProps = StyleProps<BoxStylingAPI>;

export const Box = superstyled<BoxStyleProps, StyledHTML<'div'>>('div');
Box.displayName = 'Box';

export const BoxProvider = createStyleProvider<BoxStyleProps>();
BoxProvider.displayName = 'BoxProvider';
