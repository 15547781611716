import {fromJS, List, Map} from 'immutable';
import {FieldSort, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';

export type ComparatorFunction = (a: any, b: any) => number;
export interface FilterOptions {
  min: number;
  max: number;
  field: string;
}

// This is a lie, these are actually List<Map>
export interface SortableStoreItem<T> {
  sortedBy: FieldSort;
  data: List<T>;
  updatedAt?: string;
  filteredData?: List<T>;
}

export interface SortableStore<T> extends Map<string, SortableStoreItem<T>> {}

const toggleSortKey = (currentSort: FieldSort, newKey: string): FieldSort => {
  if (currentSort.fieldName !== newKey) {
    return {
      fieldName: newKey,
      sortOrder: 'descending',
    };
  } else {
    return {
      ...currentSort,
      sortOrder: switchOrder(currentSort),
    };
  }
};

const switchOrder = fieldSort => {
  switch (fieldSort.sortOrder) {
    case 'none':
      return 'descending';
    case 'descending':
      return 'ascending';
    case 'ascending':
      return 'descending';
  }
};

export const sortingReducers = {
  sortStats: (state: SortableStore<any>, statePath: string[], key: string, columnsSpec) => {
    const currentState = state.getIn(statePath);
    const updatedSortOrder = toggleSortKey(currentState.get('sortedBy'), key);
    const stateKey = currentState.get('filteredData') !== undefined ? 'filteredData' : 'data';
    const stats = currentState.get(stateKey).map(item => item.toJS());
    const sortedStats = sortStats(stats, updatedSortOrder, columnsSpec).map(item => fromJS(item));
    return state.setIn(
      statePath,
      currentState.set('sortedBy', updatedSortOrder).set(stateKey, sortedStats),
    );
  },
  filterStats: (state: SortableStore<any>, statePath: string[], filterBy: FilterOptions) => {
    const currentState = state.getIn(statePath);
    const stats = currentState.get('data').map(item => item.toJS());

    const filteredStats = filterStats(stats, filterBy).map(item => fromJS(item));

    return state.setIn(statePath, currentState.set('filteredData', filteredStats));
  },
};

const sortStats = (
  stats: List<any>,
  sortBy: FieldSort,
  columnSpec: Record<string, IColumnSpec<any>>,
) => {
  const compare = (value1, value2, sortingOrder, comparator) => {
    switch (sortingOrder) {
      case 'descending':
        return comparator(value1, value2);
      case 'ascending':
        return comparator(value2, value1);
      case 'none':
        return 0;
    }
  };

  const determineComparator = (
    field,
    columnsSpec: Record<string, IColumnSpec<any>>,
  ): ComparatorFunction => {
    const columnSpec = columnsSpec[field.fieldName];
    if (columnSpec.comparator) {
      return columnSpec.comparator;
    }

    if (columnSpec.dataType === 'number') {
      return (a, b) => (a || 0) - (b || 0);
    }

    if (columnSpec.dataType === 'text') {
      return (a, b) => (a || '').localeCompare(b || '');
    }
  };

  return stats
    .sort((b, a) => {
      const comparator = determineComparator(sortBy, columnSpec);
      return compare(a[sortBy.fieldName], b[sortBy.fieldName], sortBy.sortOrder, comparator);
    })
    .toList();
};

const filterStats = (stats: List<any>, filterBy: FilterOptions) => {
  const {min, max, field} = filterBy;
  return stats.filter(stat => stat[field] >= min && stat[field] <= max).toList();
};
