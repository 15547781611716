import {List, Map} from 'immutable';
import {LocalizerService} from 'fiba/common/services/localizerService';
import {
  ResultsGameSummary,
  ResultsGameSummaryLike,
} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {ResultsTeamLike} from 'fiba/common/core/models/api/results/ResultsTeam';
import {EventActivity} from 'fiba/common/core/models/api/events/EventActivity';
import uiConfigActions from 'fiba/common/stores/uiConfigStore';
import {
  getLocalTimeFromString,
  getUserTimeFromString,
  getLocalDateFromString,
  getUserDateFromString,
} from 'fiba/common/utils/schedule';
import {uniqBy, sortBy} from 'lodash';

export type ScheduleFilterType = 'team' | 'category' | 'court' | 'teamsCategory';
export type ScheduleFilterState = Map<ScheduleFilterType, string | undefined>;

type TeamWithResults = ResultsTeamLike &
  Pick<ResultsGameSummaryLike, 'categoryName'> &
  Pick<ResultsGameSummaryLike, 'categoryId'>;

export function mapGamesToTeams(games: List<ResultsGameSummary>): TeamWithResults[] {
  const teams: TeamWithResults[] = games
    .toJS()
    .flatMap((game: ResultsGameSummaryLike) => {
      return [
        {...game.homeTeam, categoryName: game.categoryName, categoryId: game.categoryId},
        {...game.awayTeam, categoryName: game.categoryName, categoryId: game.categoryId},
      ];
    })
    .filter((team: TeamWithResults) => !!(team && team.teamId));
  const uniqueTeams = uniqBy(teams, team => team.teamId);

  return sortBy(uniqueTeams, ['teamName']);
}

export function getFilterState(state, id: string): ScheduleFilterState {
  return state.getIn(['uiConfig', 'schedule', id, 'filters'], Map());
}

export function getFilterValue(state, id: string, filterType: ScheduleFilterType): string {
  return state.getIn(['uiConfig', 'schedule', id, 'filters', filterType]);
}

export function setFilterValue(id: string, filterType: ScheduleFilterType, value: string): any {
  return uiConfigActions.setUiConfigValue(['schedule', id, 'filters', filterType], value);
}

export function applyFiltersToGames(
  games: List<ResultsGameSummary>,
  filterState: ScheduleFilterState,
): List<ResultsGameSummary> {
  const {team, category, court} = filterState.toJS();
  const teamFiltered = team
    ? games.filter(
        game =>
          (game.homeTeam && game.homeTeam.teamId === team) ||
          (game.awayTeam && game.awayTeam.teamId === team),
      )
    : games;
  const categoryFiltered = category
    ? teamFiltered.filter(game => game.categoryId === category)
    : teamFiltered;
  const courtFiltered = court
    ? categoryFiltered.filter(game => game.courtId === court)
    : categoryFiltered;

  return courtFiltered.toList();
}

export function applyFiltersToActivities(
  activities: List<EventActivity>,
  filterState: ScheduleFilterState,
): List<EventActivity> {
  const {team, category, court} = filterState.toJS();

  // If either of these filters are on, let's hide the activities
  if (team || category) {
    return List();
  }

  const courtFiltered = court
    ? activities.filter(activity => activity.courtId === court)
    : activities;

  return courtFiltered.toList();
}

export function groupGamesAndActivities(
  games: List<ResultsGameSummary>,
  activities: List<EventActivity>,
  isScheduleEventTime: boolean,
  localizer: LocalizerService,
): Map<string, List<ResultsGameSummary | EventActivity>> {
  let items: List<ResultsGameSummary | EventActivity>;

  // Micro-optimization: If either of `games` or `activities` is empty,
  // return the other since they are already sorted by date
  if (!activities.size) {
    items = games;
  } else if (!games.size) {
    items = activities;
  } else {
    // Insertion sort could be faster since both lists are already sorted by date
    items = games
      .concat(activities)
      .sortBy(
        item =>
          (item as ResultsGameSummary).gameStartDatetime ||
          (item as EventActivity).activityStartDatetime,
      ) as List<ResultsGameSummary | EventActivity>;
  }

  return items
    .groupBy(item => {
      const dateStr =
        (item as ResultsGameSummary).gameStartDatetime ||
        (item as EventActivity).activityStartDatetime;
      return isScheduleEventTime
        ? getLocalDateFromString(dateStr, localizer)
        : getUserDateFromString(dateStr, localizer);
    })
    .toMap() as Map<string, List<ResultsGameSummary | EventActivity>>;
}

// Used to display the timestamp for each game,
// based on timezone selected by user
export const getDisplayTimeFromString = (
  startDatetime: string,
  isScheduleEventTime: boolean,
  localizer: LocalizerService,
): string =>
  isScheduleEventTime
    ? getLocalTimeFromString(startDatetime, localizer)
    : getUserTimeFromString(startDatetime, localizer);

// To complement `getDisplayTimeFromString`
export const getDisplayDateFromString = (
  startDatetime: string,
  isScheduleEventTime: boolean,
  localizer: LocalizerService,
): string =>
  isScheduleEventTime
    ? getLocalDateFromString(startDatetime, localizer)
    : getUserDateFromString(startDatetime, localizer);
