import React, {useContext} from 'react';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Box} from 'fiba/wt/ui/box/Box';
import {EventNewsCondensed} from 'fiba/wt/ui/eventNews/EventNews';
import {EventRightSidebarWrapper} from 'fiba/wt/ui/eventLayout/EventLayout';

interface IEventNewsSidebar {
  season: string;
  eventId: string;
}

export const EventNewsSidebar: React.FunctionComponent<IEventNewsSidebar> = ({season, eventId}) => {
  const theme = useContext(ThemeContext);
  return (
    <EventRightSidebarWrapper>
      <Box
        width="100"
        ph={['0', '3']}
        pt={['0', '0', '0', '3']}
        pb="3"
        color={theme.color}
        bgColor={theme.bgColor}
        extraClassName="EventNewsSidebar"
      >
        <EventNewsCondensed season={season} eventId={eventId} />
      </Box>
    </EventRightSidebarWrapper>
  );
};
