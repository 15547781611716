import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {TourProCircuitTeamsTable} from 'fiba/wt/ui/tourTeams/TourProCircuitTeamsTable';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {ContentTypes, getEventConfigurationForTour} from 'fiba/wt/stores/contentStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {connect} from 'fiba/common/utils/reactUtils';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';

export interface OwnProps {
  tourId: string;
  season: string;
}

interface ReduxProps {
  metaPaths: string[];
  eventConfiguration?: EventConfiguration[];
}

type Props = OwnProps & ReduxProps;

const mapStateToProps = (state, {tourId}: Props): ReduxProps => ({
  eventConfiguration: getEventConfigurationForTour(state, tourId),
  metaPaths: [`content/__meta/${ContentTypes.EventConfigurator}/tourEvents/${tourId}`],
});

const TourProCircuitTeamsPageImpl: React.FC<Props> = props => {
  const {tourId, season, metaPaths} = props;

  return (
    <Spacer vSpace="3">
      <VisuallyHidden>
        <Heading>Pro Circuit Tour Teams</Heading>
      </VisuallyHidden>
      <Loading metaPaths={metaPaths}>
        {() => (
          <Box width="100" ph={['0', '2', '0']}>
            <Box pb={['4', '4', '0']} debugClassName="TourProCircuitTeamsPage-Table">
              <Section>
                <TourProCircuitTeamsTable tourId={tourId} season={season} />
              </Section>
            </Box>
          </Box>
        )}
      </Loading>
    </Spacer>
  );
};

export const TourProCircuitTeamsPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  TourProCircuitTeamsPageImpl,
);
