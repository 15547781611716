import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {ThemeContextProvider} from 'fiba/wt/ui/themeContext/ThemeContext';
import {TourTeamsNav} from 'fiba/wt/ui/tourTeams/TourTeamsNav';

export interface ITourTeamLayout {
  tourId: string;
  season: string;
  teamNavBehavior: 'scroll' | 'navigate';
  children?: any;
}

export const TourTeamLayout: React.FunctionComponent<ITourTeamLayout> = ({
  tourId,
  season,
  teamNavBehavior,
  children,
}) => (
  <ThemeContextProvider theme="light">
    <Flex
      flexDirection={['column', 'column', 'row', 'row']}
      bgColor="silver-10"
      debugClassName="TourTeamsLayout"
    >
      <Flex
        flexDirection={['row', 'row', 'column', 'column']}
        justifyContent={['between', 'between', 'start', 'start']}
        width={['100', '100', 'auto']}
        pt={['4', '4', '0', '0']}
      >
        <Box ph="3" display={['none', 'block']}>
          <Box border="bottom" borderWidth="1" borderColor="dark-50">
            <Heading pv={['0', '0', '1', '1']} pb={['3', '3', '1']} extraClassName="dib">
              Tour Teams
            </Heading>
          </Box>
        </Box>

        <Box
          position="sticky"
          ph={['3', '3', '0']}
          width={['100', '100', 'auto']}
          extraClassName="top-0 z-2"
          debugClassName="TourTeamsLayout-Navigation"
        >
          <Section>
            <TourTeamsNav tourId={tourId} season={season} navBehavior={teamNavBehavior} />
          </Section>
        </Box>
      </Flex>

      <Box flex="auto" debugClassName="TourTeamsLayout-Content">
        <Section>{children}</Section>
      </Box>
    </Flex>
  </ThemeContextProvider>
);
