import React from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {FlexProvider, FlexStyleProps} from 'fiba/wt/ui/flex/Flex';
import cx from 'classnames';

export interface Tab {
  name: string;
  href?: string;
  children?: Tab[];
  opensInNewTab?: boolean;
  active?: boolean;
  shouldBeScrolledTo?: boolean;
}

// TODO: Transitions?
export interface NavLinkStyle {
  idle: string;
  active: string;
}
export const defaultNavLinkStyle: Record<string, NavLinkStyle> = {
  light: {
    idle: 'silver-10 hover-silver-30 br2 focus-shadow',
    active: 'fw7 dark-50 bg-silver-10 br2 focus-shadow',
  },
  dark: {
    idle: 'dark-40 hover-dark-50 hover-bg-silver-20 br2 focus-shadow',
    active: 'fw7 bg-silver-20 dark-50 br2 focus-shadow',
  },
};

export interface NavigationProps {
  season: string;
  extraClassName?: string;
}

export interface INavigationContentRow {
  tabs: Tab[];
  label: string;
  navLinkStyle: NavLinkStyle;
  containerStyle?: FlexStyleProps;
}

export const NavigationContentRow: React.FunctionComponent<INavigationContentRow> = ({
  tabs,
  label,
  containerStyle,
  navLinkStyle,
}) => {
  const fieldRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (fieldRef.current) {
      fieldRef.current.scrollIntoView({behavior: 'smooth', inline: 'start'});
    }
  }, []);

  return (
    <FlexProvider
      {...containerStyle}
      alignItems="center"
      flexDirection="row"
      debugClassName="Navigation-ContentRow"
    >
      {({className}) => (
        <nav aria-label={label}>
          <ul className={className}>
            {tabs.map(({name, href, active, shouldBeScrolledTo = false}) => {
              let ref: React.RefObject<HTMLLIElement> | null = null;

              if (shouldBeScrolledTo) {
                ref = fieldRef;
              }

              return (
                <li key={`${name}-${href}`} ref={ref}>
                  <a
                    href={href}
                    className={cx(
                      'db pv2 ph3 f6 lh-title link link--bare ws-nowrap',
                      {[navLinkStyle.idle]: !active},
                      {[navLinkStyle.active]: active},
                    )}
                  >
                    {name}
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </FlexProvider>
  );
};

// -- Main component --

export const Navigation: React.FunctionComponent<NavigationProps> = ({
  season,
  extraClassName,
  children,
}) => {
  return (
    <Box position="relative" extraClassName={extraClassName}>
      {children}
    </Box>
  );
};
