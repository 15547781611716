import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {NewsHighlight} from 'fiba/wt/ui/newsHighlight/NewsHighlight';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {GhostLink} from 'fiba/wt/ui/link/Link';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';

const TOUR_NEWS_SUMMARY_COUNT = 10;

interface ITourNewsSummary {
  tourId: string;
  season: string;
}

interface ITourNewsSummaryState {
  news: List<NewsItem>;
  metaPaths: string[];
}

type TourNewsSummaryProps = ITourNewsSummary & ITourNewsSummaryState;

export const mapStateToProps = (state, {tourId}: ITourNewsSummary): ITourNewsSummaryState => {
  const metaPath = `tours/__meta/${tourId}/news/previews`;

  return {
    news: state
      .getIn([...metaPath.split('/'), 'refs'], List())
      .map((newsSlug: string) => state.getIn(['news', 'previews', newsSlug]))
      .sort((ev1, ev2) => {
        // https://github.com/futurice/fiba-3x3-play/issues/3081 reported inconsistenicies in the news item photos when the publish date is the same.
        // Force a set order when the publish date is the same (currently it doesn't matter what the order is, as long as it is consistent).
        if (ev1.date !== ev2.date) {
          return new Date(ev1.date) < new Date(ev2.date) ? 1 : -1;
        } else {
          // Just use the uuid as that will always have a set order.
          return ev1.id < ev2.id ? 1 : -1;
        }
      })
      .take(TOUR_NEWS_SUMMARY_COUNT),
    metaPaths: [metaPath],
  };
};

const _TourNewsSummary: React.FunctionComponent<TourNewsSummaryProps> = ({
  season,
  news,
  metaPaths,
}: TourNewsSummaryProps) => {
  const theme = useContext(ThemeContext);
  return (
    <div>
      <Loading metaPaths={metaPaths}>
        {() =>
          news.size > 0 && (
            <React.Fragment>
              <NewsHighlight
                news={news}
                baseUrl={`/${season}/news`}
                // NOTE: Extrinsic from ResponsiveImageLint
                // This is because they are important for loading, so we need the best estimates,
                // even if they are more brittle.
                largeItemSizes="(min-width: 1620px) 793px, (min-width: 1040px) calc(58.93vw - 150px), (min-width: 780px) calc(100vw - 304px), 100vw"
                smallItemSizes="(min-width: 1620px) 423px, (min-width: 1040px) calc(29.64vw - 51px), (min-width: 780px) calc(50vw - 161px), 100vw"
              />

              <div className="pv3 flex justify-center">
                <GhostLink
                  variant={theme.ghostlinkTheme}
                  extraClassName="pv3 w5"
                  href={`/${season}/news`}
                >
                  See all tour news
                </GhostLink>
              </div>
            </React.Fragment>
          )
        }
      </Loading>
    </div>
  );
};

export const TourNewsSummary = connect<ITourNewsSummaryState, {}, ITourNewsSummary>(
  mapStateToProps,
)(_TourNewsSummary);
