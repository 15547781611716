import React, {useContext} from 'react';
import ResultsGameDetails from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {connect} from 'fiba/common/utils/reactUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';
import {Section, Heading, SubHeading, HeadingStyleProps} from 'fiba/wt/ui/heading/Heading';
import GameStatsTeamFoulsTable from './GameStatsTeamFoulsTable';
import {GameStats} from 'fiba/common/core/models/api/stats/GameStats';
import {isLegacySeason} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {PlayerStoreState} from 'fiba/wt/stores/playerStore';
import {RootState} from 'fiba/wt/stores/rootStore';
import GameStatsPlayerTable from './GameStatsPlayerTable';
import {P} from 'fiba/wt/ui/text/Text';
import {BoxScoreLegend} from 'fiba/wt/ui/gameBoxScore/BoxScoreLegend';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {
  EventConfiguration,
  getEventStatsVisibility,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';

interface OwnProps {
  season: string;
  eventId: string;
  gameId: string;
  gameResult: ResultsGameDetails;
  headingStyles: HeadingStyleProps;
}

interface ReduxProps {
  eventConfig: EventConfiguration;
  gameStats: GameStats;
  players: PlayerStoreState;
  gameDetails: ResultsGameDetails;
}

type Props = ReduxProps & OwnProps;

const mapStateToProps = (state: RootState, {gameId, eventId}: OwnProps): ReduxProps => {
  return {
    eventConfig: getEventConfiguration(state, eventId),
    gameStats: state.getIn(['gameStats', gameId]),
    players: state.get('players'),
    gameDetails: state.getIn(['games', 'details', gameId]),
  };
};

const _BoxScoreBothTeams: React.FC<Props> = ({
  eventConfig,
  gameId,
  gameResult,
  gameStats,
  season,
  players,
  gameDetails,
  headingStyles,
}) => {
  const siteConfig = useContext(SiteConfigContext);
  const eventConfigHasReducedStats = !getEventStatsVisibility(siteConfig, eventConfig, season);
  const showReducedStats = eventConfigHasReducedStats || gameStats.hasReducedStatistics;

  if (!(gameResult && gameStats)) {
    return null;
  }

  const {referee1, referee2} = gameDetails;

  return (
    <>
      <Section>
        <Heading pt="3" {...headingStyles}>
          <TeamNameAssembly standing={gameResult.homeTeam} isSuffixVisible />
        </Heading>
        <GameStatsPlayerTable
          players={players}
          captionId={`${gameId}-$-player-stats`}
          gameResult={gameResult}
          teamStats={gameStats.homeTeamStatistics}
          playerStats={gameStats.playerStatistics}
          reducedColumns={showReducedStats}
        />
      </Section>

      <Section>
        <Heading pt="3" fontSize="5" {...headingStyles}>
          <TeamNameAssembly standing={gameResult.awayTeam} isSuffixVisible />
        </Heading>
        <GameStatsPlayerTable
          players={players}
          captionId={`${gameId}-$-player-stats`}
          gameResult={gameResult}
          teamStats={gameStats.awayTeamStatistics}
          playerStats={gameStats.playerStatistics}
          reducedColumns={showReducedStats}
        />
      </Section>
      {(referee1 || referee2) && (
        <Section>
          <Heading {...headingStyles} mb="2">
            Referees
          </Heading>
          <P mt="0" mb="0" fontSize="7">
            {referee1 && referee1.firstName && (
              <span>
                {referee1.firstName} {referee1.lastName}
              </span>
            )}
            {referee1 && referee2 && ', '}
            {referee2 && referee2.firstName && (
              <span>
                {referee2.firstName} {referee2.lastName}
              </span>
            )}
          </P>
        </Section>
      )}

      {!isLegacySeason(season) && (
        <>
          <Box
            display="inline_block"
            pr={['0', '2']}
            width={['100', '50']}
            maxWidth={[null, null, '5']}
          >
            <GameStatsTeamFoulsTable
              captionId="hometeam-fouls"
              teamStats={gameStats.homeTeamStatistics}
              caption={<SubHeading {...headingStyles}>{gameResult.homeTeam.teamName}</SubHeading>}
            />
          </Box>

          <Box
            display="inline_block"
            pl={['0', '2']}
            width={['100', '50']}
            maxWidth={[null, null, '5']}
          >
            <GameStatsTeamFoulsTable
              captionId="awayteam-fouls"
              teamStats={gameStats.awayTeamStatistics}
              caption={
                <SubHeading {...headingStyles} pb="2">
                  {gameResult.awayTeam.teamName}
                </SubHeading>
              }
            />
          </Box>
        </>
      )}
      <BoxScoreLegend headingStyles={headingStyles} reduced={showReducedStats} />
    </>
  );
};

export const BoxScoreBothTeams = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _BoxScoreBothTeams,
);
