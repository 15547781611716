import React, {useContext} from 'react';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {Box} from 'fiba/wt/ui/box/Box';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {TourTeamsPage, TourTeamsPageTab} from 'fiba/wt/ui/tourTeamsPage/TourTeamsPage';
import {TourProCircuitTeamsPage} from 'fiba/wt/ui/tourTeamsPage/TourProCircuitTeamsPage';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

type TourMacroTeamsTab = 'world-tour' | 'pro-circuit';

export interface ITourMacroTeamsPage {
  tourId: string;
  season: string;
  eventId: string;
  primaryTab: TourMacroTeamsTab;
  secondaryTab: TourTeamsPageTab;
}

function getTourMacroTeamsTab({primaryTab, secondaryTab, ...props}: ITourMacroTeamsPage) {
  switch (primaryTab) {
    case 'world-tour':
      return <TourTeamsPage teamTab={secondaryTab} {...props} />;
    case 'pro-circuit':
      return <TourProCircuitTeamsPage {...props} />;
    default:
      return null;
  }
}

export const TourMacroTeamsPage: React.FC<ITourMacroTeamsPage> = (props: ITourMacroTeamsPage) => {
  const headingId = 'tour-teams-heading';
  const {seasonConfig} = useContext(SiteConfigContext);
  const hasOnlyWorldTourStats = seasonConfig.seasons[props.season].hasOnlyWorldTourStats
    ? true
    : false;
  return (
    <section>
      <PageHeading containerProps={{border: 'none'}} text="Tour Teams" id={headingId} />

      <Section>
        <CardContainer pa="0" height="100" debugClassName="TourMacroTeamsPage">
          <Tabs
            level="base"
            navigationLabeledBy={headingId}
            baseUrl={`/${props.season}/teams`}
            extraClassName="TourMacroTeamsPage-Tabs"
          >
            <Tab href="/world-tour" hrefAliases={['/']}>
              World Tour
            </Tab>
            {hasOnlyWorldTourStats ? '' : <Tab href="/pro-circuit">Pro Circuit</Tab>}
          </Tabs>
          <Box ph="3" pb="3" pt="2" debugClassName="TourMacroTeamsPage-Content">
            {getTourMacroTeamsTab(props)}
          </Box>
        </CardContainer>
      </Section>
    </section>
  );
};
