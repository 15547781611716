/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultQualifierForEvents = {id: null, slots: null};
export const defaultNationalTeamEvent = {
  id: null,
  name: null,
  qualifierForEvents: null,
  startDate: null,
  endDate: null,
  shortName: null,
  eventCityName: null,
  eventCountryIoc: null,
  eventCountryIso2: null,
  eventCountryName: null,
};
export const defaultNationalTeamEventsCategory = {conferenceId: null, label: null, events: null};
export const defaultNationalTeamEvents = {data: null};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type QualifierForEventsLike = {
  [K in keyof typeof defaultQualifierForEvents]?: QualifierForEvents[K];
};
export type NationalTeamEventLike = {
  [K in keyof typeof defaultNationalTeamEvent]?: NationalTeamEvent[K];
};
export type NationalTeamEventsCategoryLike = {
  [K in keyof typeof defaultNationalTeamEventsCategory]?: NationalTeamEventsCategory[K];
};
export type NationalTeamEventsLike = {
  [K in keyof typeof defaultNationalTeamEvents]?: NationalTeamEvents[K];
};

export class QualifierForEvents extends Record(defaultQualifierForEvents) {
  id?: string;
  slots?: number;
  static fromJS(o: QualifierForEventsLike): QualifierForEvents {
    return o && new QualifierForEvents({id: o.id, slots: o.slots});
  }
}
export class NationalTeamEvent extends Record(defaultNationalTeamEvent) {
  id?: string;
  name?: string;
  qualifierForEvents?: List<QualifierForEvents>;
  startDate?: string;
  endDate?: string;
  shortName?: string | null;
  eventCityName?: string;
  eventCountryIoc?: string;
  eventCountryIso2?: string;
  eventCountryName?: string;
  static fromJS(o: NationalTeamEventLike): NationalTeamEvent {
    return (
      o &&
      new NationalTeamEvent({
        id: o.id,
        name: o.name,
        qualifierForEvents:
          o.qualifierForEvents &&
          List(o.qualifierForEvents).map(i => i && QualifierForEvents.fromJS(i)),
        startDate: o.startDate,
        endDate: o.endDate,
        shortName: o.shortName,
        eventCityName: o.eventCityName,
        eventCountryIoc: o.eventCountryIoc,
        eventCountryIso2: o.eventCountryIso2,
        eventCountryName: o.eventCountryName,
      })
    );
  }
}
export class NationalTeamEventsCategory extends Record(defaultNationalTeamEventsCategory) {
  conferenceId?: string;
  label?: string;
  events?: List<NationalTeamEvent>;
  static fromJS(o: NationalTeamEventsCategoryLike): NationalTeamEventsCategory {
    return (
      o &&
      new NationalTeamEventsCategory({
        conferenceId: o.conferenceId,
        label: o.label,
        events: o.events && List(o.events).map(i => i && NationalTeamEvent.fromJS(i)),
      })
    );
  }
}
export class NationalTeamEvents extends Record(defaultNationalTeamEvents) {
  data?: List<NationalTeamEventsCategory>;
  static fromJS(o: NationalTeamEventsLike): NationalTeamEvents {
    return (
      o &&
      new NationalTeamEvents({
        data: o.data && List(o.data).map(i => i && NationalTeamEventsCategory.fromJS(i)),
      })
    );
  }
}

export default NationalTeamEvents;
