import {Map, fromJS} from 'immutable';
import {createReducer, createActions, createStoreReviver} from 'fiba/wt/utils/storeUtils';
import {setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Results, ResultsLike} from 'fiba/common/core/models/api/results/Results';

export interface ResultStoreState extends Map<string, Results> {
  // TODO: `__meta`
}

const reducers = {
  loadResultsByCategory: (state: ResultStoreState, categoryId: string): ResultStoreState =>
    state.updateIn(['__meta', categoryId], setMeta.isLoading),

  // TODO: Normalize `poolGroups`, `knockoutGroups` to `groupId`s (and `knockoutBrackets` to `gameÌd`s ?)
  // Should they be put to refs or what?
  loadResultsByCategorySuccess: (
    state: ResultStoreState,
    categoryId: string,
    results: ResultsLike,
  ): ResultStoreState =>
    state
      .set(categoryId, Results.fromJS(results))
      .updateIn(['__meta', categoryId], setMeta.isLoaded),

  loadResultsByCategoryError: (
    state: ResultStoreState,
    categoryId: string,
    error: Error,
  ): ResultStoreState => state.updateIn(['__meta', categoryId], setMeta.isError),
};

export const reducer = createReducer<ResultStoreState>(__filename, fromJS({}), reducers);

export const actions = createActions(__filename, reducers, {
  loadResultsByCategory: (categoryId: string) => ({apiClient}: CommonServices) =>
    apiClient
      .loadResultsByCategory(categoryId)
      .then(results => actions.loadResultsByCategorySuccess(categoryId, results.data))
      .catch(rethrow((error: Error) => actions.loadResultsByCategoryError(categoryId, error))),

  loadResultsByCategoryError: (categoryId: string, error: Error) =>
    notificationActions.addNotificationFromError(error),
});

export default actions;

export const deserializeState = createStoreReviver<ResultStoreState>(Results);
