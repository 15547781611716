import React, {useContext, useMemo} from 'react';
import {List} from 'immutable';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {getGameNameLong, getRoundNameLong, getTeamDisplayScore} from 'fiba/wt/core/results';
import {ServicesContext, LocalizerService} from 'fiba/common/services/services';
import {Box} from 'fiba/wt/ui/box/Box';
import {KoBracket} from 'fiba/play/ui/categoryBrackets/KoBracket';
import {Heading, SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import ResultsTeam from 'fiba/common/core/models/api/results/ResultsTeam';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

interface BracketsViewProps {
  knockoutBrackets: List<List<ResultsGameSummary>>;
}

interface BracketHeadingProps {
  title: string;
}

interface BracketNodeProps {
  listGame: ResultsGameSummary;
}

const BracketHeading: React.FunctionComponent<BracketHeadingProps> = ({title}) => (
  <SubHeading>{title}</SubHeading>
);

function localizeGames(
  games: List<ResultsGameSummary>,
  localizer: LocalizerService,
): List<ResultsGameSummary | null> {
  // By operator mistake some games might be forgotten to be marked as PI, check if all of them are
  const playInGame = games.find(game => !!game && !!game.gameIsPlayIn);
  // as mentioned above the round is PI only if all of the games in the round are marked as PI
  const allGamesOnRoundPlayIn =
    !!playInGame &&
    games
      .filter(g => !!g && g.roundCode === playInGame.roundCode)
      .reduce((acc, val) => acc && val.gameIsPlayIn, true);
  return games
    .map(game => {
      if (!game) {
        return game;
      }
      const thisRoundIsPlayIn = allGamesOnRoundPlayIn && game.roundCode === playInGame.roundCode;
      const groupName = getRoundNameLong(thisRoundIsPlayIn ? 'PI' : game.roundCode, localizer);
      const gameName = getGameNameLong(game.roundCode, game.gameNumberInGroup, localizer);
      return game.set('groupName', groupName).set('gameName', gameName);
    })
    .toList();
}

const bracketTeam = (team: ResultsTeam, game: ResultsGameSummary, localizer: LocalizerService) => (
  <Flex pa="1" flexWrap="nowrap" fontWeight={team.teamIsWinner ? '6' : '4'}>
    <Box flexGrow="1" pr="1" extraClassName="ws-nowrap overflow-x-hidden ellipsis">
      <TeamNameAssembly standing={team} isSuffixVisible isIocVisible={false} />
    </Box>
    <Box flexShrink="1" extraClassName="tnum">
      {getTeamDisplayScore(team, game, localizer)}
    </Box>
  </Flex>
);

const BracketNode = (localizer: LocalizerService): React.FunctionComponent<BracketNodeProps> => ({
  listGame,
}) => (
  <Flex
    flexDirection="column"
    justifyContent="around"
    height="100"
    bgColor="silver-30"
    extraClassName="BracketNode"
  >
    {bracketTeam(listGame.homeTeam, listGame, localizer)}
    {bracketTeam(listGame.awayTeam, listGame, localizer)}
  </Flex>
);

export const BracketsView: React.FunctionComponent<BracketsViewProps> = ({knockoutBrackets}) => {
  const {localizer} = useContext(ServicesContext);

  // TODO: useMemo?
  const largestKoGamesListSize = useMemo(
    () => Math.max(...knockoutBrackets.map(koGamesList => koGamesList.size).toArray()),
    [knockoutBrackets],
  );

  return (
    <Box debugClassName="BracketsView" extraClassName="overflow-x-auto">
      {knockoutBrackets.map((games, idx) => {
        const localizedGames = localizeGames(games, localizer);

        if (idx === 0) {
          return (
            <Box key={idx} mb="4">
              <KoBracket
                koGamesList={localizedGames}
                KoHeadingImplementation={BracketHeading}
                KoNodeImplementation={BracketNode(localizer)}
                treeNodeHeight={63}
                treeNodeMinWidth={145}
                treeNodeMaxWidth={250}
                verticalTreeSpacing={10}
                horizontalTreeSpacing={20}
                largestKoGamesListSize={largestKoGamesListSize}
                showKoHeadings
              />
            </Box>
          );
        } else {
          return (
            <Box key={idx}>
              <Heading pb="3" fontSize="3">
                {localizer.formatKey('CLASSIFICATION_GAMES_TITLE')} -{' '}
                {localizedGames.first().groupName}
              </Heading>

              {localizedGames.size > 1 && <Box pv="3" />}

              <Box mb="4">
                <KoBracket
                  koGamesList={localizedGames}
                  KoHeadingImplementation={BracketHeading}
                  KoNodeImplementation={BracketNode(localizer)}
                  treeNodeHeight={63}
                  treeNodeMinWidth={145}
                  treeNodeMaxWidth={250}
                  verticalTreeSpacing={10}
                  horizontalTreeSpacing={20}
                  largestKoGamesListSize={largestKoGamesListSize}
                  showKoHeadings={localizedGames.size > 1}
                />
              </Box>
            </Box>
          );
        }
      })}
    </Box>
  );
};
