import React, {useContext} from 'react';
import {LevelContext} from 'fiba/wt/ui/heading/HeadingContext';

/** Context-connected Heading component that automatically gets
 * the correct heading level, using Context.
 */
export const H: React.SFC<React.HTMLAttributes<HTMLHeadingElement>> = props => {
  const {level} = useContext(LevelContext);
  // Use the appropriate heading
  const Hx = 'h' + Math.min(level, 6);

  return <Hx {...props} />;
};
