import IntlMessageFormat from 'intl-messageformat';
import {Map} from 'immutable';
import {merge} from 'lodash';

if (typeof window !== 'undefined') {
  // NOTE: Consider typing custom `window` object
  // @ts-ignore
  window['IntlMessageFormat'] = IntlMessageFormat;
}

// Overriding full-optionformat from intl-messageformat to match with those on Intl-package
// This removes issue with server-side rendering not matching the client on EventCategories & EventResult -pages.
const customFormats = {
  number: {
    '1-decimal': {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    },
  },
  date: {
    custom: {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    full: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
    short: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
    medium: {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
    weekDate: {
      weekday: 'short',
      day: 'numeric',
    },
    monthAndYear: {
      month: 'long',
      year: 'numeric',
    },
    timeOnly: {
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    },
  },
};

export class LocalizerService {
  // TODO: Be a hipster and use a WeakMap for this
  messageFormatCache = {};

  // NOTE: Translations are loaded only in initial page load. We are relying
  // on the fact that changing language will do a full reload of the page.
  // TODO: Move properties from params to class
  constructor(public lang: string, public translations: Map<string, string>) {}

  formatKey(keyword: string, value?: any, options?: any) {
    const {translations} = this;

    const str = translations.get(keyword);
    if (typeof value === 'undefined') {
      return str;
    }

    return this.formatWithCache(keyword, str, value, options);
  }

  format(str: string, value: any, options?: any) {
    return this.formatWithCache(str, str, value, options);
  }

  private formatWithCache(key: string, str: string, value: any, options?: any) {
    const {messageFormatCache, lang} = this;
    // Check if we can use the cache
    if (typeof messageFormatCache[key] !== 'undefined' && typeof options === 'undefined') {
      return messageFormatCache[key].format(value);
    }

    const msg = new IntlMessageFormat(str, lang, merge({}, customFormats, options));
    if (typeof messageFormatCache[key] === 'undefined' && typeof options === 'undefined') {
      messageFormatCache[key] = msg;
    }

    return msg.format(value);
  }
}

function createLocalizerService(lang: string, translations: Map<string, string>) {
  return new LocalizerService(lang, translations);
}

export default createLocalizerService;
