/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {TourResultsEvent} from './TourResultsEvent';
import {TourResultsTeamStanding} from './TourResultsTeamStanding';
import {TourResultsPlayerStanding} from './TourResultsPlayerStanding';

export const defaultTourResultsCategory = {
  parentCategoryId: null,
  parentCategoryName: null,
  events: null,
  standings: null,
  teamStandings: null,
  playerStandings: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type TourResultsCategoryLike = {
  [K in keyof typeof defaultTourResultsCategory]?: TourResultsCategory[K];
};

export class TourResultsCategory extends Record(defaultTourResultsCategory) {
  parentCategoryId?: string;
  parentCategoryName?: string;
  events?: List<TourResultsEvent>;
  standings?: List<TourResultsTeamStanding>;
  teamStandings?: List<TourResultsTeamStanding>;
  playerStandings?: List<TourResultsPlayerStanding>;
  static fromJS(o: TourResultsCategoryLike): TourResultsCategory {
    return (
      o &&
      new TourResultsCategory({
        parentCategoryId: o.parentCategoryId,
        parentCategoryName: o.parentCategoryName,
        events: o.events && List(o.events).map(i => i && TourResultsEvent.fromJS(i)),
        standings:
          o.standings && List(o.standings).map(i => i && TourResultsTeamStanding.fromJS(i)),
        teamStandings:
          o.teamStandings && List(o.teamStandings).map(i => i && TourResultsTeamStanding.fromJS(i)),
        playerStandings:
          o.playerStandings &&
          List(o.playerStandings).map(i => i && TourResultsPlayerStanding.fromJS(i)),
      })
    );
  }
}

export default TourResultsCategory;
