import React, {useContext} from 'react';
import Countdown from 'react-countdown-now';
import {connect} from 'fiba/common/utils/reactUtils';
import {Event} from 'fiba/common/core/models/api/events/Event';
import {parseLocalDatetime, parseFullDatetime} from 'fiba/common/utils/schedule';
import {ContentTypes, getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Heading} from 'fiba/wt/ui/heading/Heading';
import {Loading} from 'fiba/wt/ui/loading/Loading';

import {eventCountdownRenderer} from 'fiba/wt/ui/eventCountdown/eventCountdownRenderer';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {EventConfiguration} from '../eventProgrammability/EventProgrammabilityStateProvider';
import {deduceEventType} from 'fiba/wt/stores/eventStore';
import {FastestGamesSponsorLogo} from 'fiba/wt/ui/svg/FastestGamesSponsorLogo';

interface OwnProps {
  season: string;
  eventId: string;
}

interface ReduxProps {
  eventData: RemoteData.WebData<Event>;
  eventConfiguration?: EventConfiguration;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

function getEventStartDatetime(event: Event, eventConfiguration?: EventConfiguration) {
  if (!eventConfiguration) {
    return parseLocalDatetime(event.startDate);
  }

  const configuredStartDatetime = eventConfiguration.startDatetime;

  if (!configuredStartDatetime) {
    return parseLocalDatetime(event.startDate);
  }

  return parseFullDatetime(configuredStartDatetime);
}

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => ({
  eventData: state.getIn(['events', eventId], RemoteData.NotAsked()) as RemoteData.WebData<Event>,
  eventConfiguration: getEventConfiguration(state, eventId),
  metaPaths: [`content/__meta/${ContentTypes.EventConfigurator}/events/${eventId}`],
});

const EventCountdownImpl: React.FunctionComponent<Props> = ({
  eventData,
  eventConfiguration,
  metaPaths,
}) => {
  const theme = useContext(ThemeContext);
  // NOTE: We compose the Loading and RemoteData here.
  // TODO: When we move eventConfiguration to be WebData-based,
  // we should change this as well
  return (
    <Box color={theme.color} extraClassName="EventCountdown">
      <Loading metaPaths={metaPaths} onLoading="none">
        {() => {
          return RemoteData.match(eventData, {
            Failure: err => 'There was an error fetching the countdown data' as React.ReactNode,
            Success: event => <ViewLoaded event={event} eventConfiguration={eventConfiguration} />,
            default: () => null,
          });
        }}
      </Loading>
    </Box>
  );
};

const ViewLoaded: React.FC<{
  event: Event;
  eventConfiguration: EventConfiguration;
}> = ({event, eventConfiguration}) => {
  const siteConfig = useContext(SiteConfigContext);
  const eventType = deduceEventType(event);
  const showFastestGamesSponsor =
    siteConfig.features.showFastestGamesSponsor &&
    ['WorldTour', 'WomensSeries', 'Cup'].includes(eventType);
  const displayName = event.shortName
    ? eventType === 'Challenger'
      ? `${event.shortName} Challenger`
      : event.shortName
    : event.name;

  return (
    <Box pt="4" pb={['0', '0', '4']}>
      <Flex
        flexDirection={['column', 'column', 'row']}
        alignItems={['center', 'center', 'end']}
        flexWrap="wrap"
        justifyContent="center"
      >
        {showFastestGamesSponsor && (
          <Box mb={['1', '1', '2', '4']} pr={['0', '3', '4']}>
            <FastestGamesSponsorLogo mb="1" width="200px" />
          </Box>
        )}

        <Spacer vSpace="2">
          <Box
            maxWidth="6"
            align="center"
            pv="1"
            ph="2"
            bgColor="red-20"
            color="silver-10"
            borderRadius="2"
            textAlign="center"
          >
            <Heading fontSize="6">
              Countdown: <span className="fw7">{displayName}</span>
            </Heading>
          </Box>
          <Countdown
            date={getEventStartDatetime(event, eventConfiguration)}
            renderer={eventCountdownRenderer}
          />
        </Spacer>
      </Flex>
    </Box>
  );
};

export const EventCountdown = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  EventCountdownImpl,
);
