import {Map, List, fromJS} from 'immutable';
import {createReducer, createActions} from 'fiba/wt/utils/storeUtils';
import {mapValues} from 'lodash';
import {
  SCORES_CLIENT_EVENT,
  IScoresClientEvent,
} from 'fiba/common/services/scoresClientV2Middleware';
import tourActions from 'fiba/wt/stores/tourStore';
import teamActions from 'fiba/wt/stores/teamStore';
import eventActivityActions from 'fiba/wt/stores/eventActivityStore';
import gameActions from 'fiba/wt/stores/gameStore';
import categoryActions from 'fiba/wt/stores/categoryStore';
import newsActions from 'fiba/wt/stores/newsStore';
import CommonServices from 'fiba/common/core/models/CommonServices';
import {Event, EventLike} from 'fiba/common/core/models/api/events/Event';
import {TeamLike} from 'fiba/common/core/models/api/teams/Team';
import {ResultsGameSummaryLike} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {CategoryLike} from 'fiba/common/core/models/api/events/Category';
import {EventActivityLike} from 'fiba/common/core/models/api/events/EventActivity';
import {NewsListLike} from 'fiba/common/core/models/api/feed/NewsList';
import {ScoresClientOngoingGame} from 'fiba/common/core/models/scores/ScoresClientOngoingGame';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import * as Anomaly from 'fiba/wt/utils/Anomaly';
import {EventType} from 'fiba/wt/utils/linkUtils';
import {omitBy, isUndefined} from 'lodash';
import {RootState} from 'fiba/wt/stores/rootStore';
export interface EventStoreState extends Map<string, RemoteData.WebData<Event>> {}

const reducers = {
  loadEvent: (state: EventStoreState, eventId: string): EventStoreState =>
    state.set(eventId, RemoteData.Loading()),

  loadEventResponse: (
    state: EventStoreState,
    eventId: string,
    newData: RemoteData.WebData<EventLike>,
  ): EventStoreState => {
    // This reducer might be called when existing data is loaded
    // In that case, we must merge them.
    // This can happen in cases where we load additional/extra data,
    // and it has been a bug in the past.

    return state.update(eventId, RemoteData.NotAsked(), prevData => {
      return RemoteData.match(newData, {
        // If the new loading went ok
        Success: newEvent => {
          // Filtering is done because there is a race condition for super quest event fetch action
          //  and this action. Both use the same pice of store. Whichever request finishes first
          // will overwrite the previous request data. By filtering, we make sure that data that is already
          // present doesn't get overwritten by undefined values.
          const newEventFiltered = fromJS(omitBy(newEvent, isUndefined));

          return RemoteData.match(prevData, {
            // If the data was set, then merge
            Success: (prevEvent: Event) => {
              const merged = fromJS(prevEvent.mergeDeep(newEventFiltered));
              return RemoteData.Success(Event.fromJS(merged));
            },
            // If the data was not set, create a new one
            default: () => RemoteData.Success(Event.fromJS(newEvent)),
          });
        },
        // Otherwise, keep the data as-is
        // TODO: Should we fail the whole thing if the new data fails?
        default: () => prevData,
      });
    });
  },
};

export const reducer = createReducer<EventStoreState>(
  __filename,
  fromJS({__isWebDataBased: true}),
  reducers,
  {
    // External reducers

    //
    // Load events when loaded by tour
    [tourActions.types.loadEventsByTourSuccess]: (
      state: EventStoreState,
      tourId: string,
      events: EventLike[],
    ): EventStoreState =>
      // NOTE: Since tourStore is not WebData- based, we have to adapt the response as Success
      events.reduce(
        (prevState, event) =>
          reducers.loadEventResponse(prevState, event.id, RemoteData.Success(event)),
        state,
      ),

    //
    // Load challengers when loaded by tour
    [tourActions.types.loadChallengerEventsByTourSuccess]: (
      state: EventStoreState,
      tourId: string,
      events: EventLike[],
    ): EventStoreState =>
      events.reduce(
        (prevState, event) =>
          reducers.loadEventResponse(prevState, event.id, RemoteData.Success(event)),
        state,
      ),

    //
    // Load superquests when loaded by tour
    [tourActions.types.loadSuperQuestEventsByTourSuccess]: (
      state: EventStoreState,
      tourId: string,
      events: EventLike[],
    ): EventStoreState =>
      events.reduce(
        (prevState, event) =>
          reducers.loadEventResponse(prevState, event.id, RemoteData.Success(event)),
        state,
      ),

    //
    // Teams
    [teamActions.types.loadTeamsByEvent]: (
      state: EventStoreState,
      eventId: string,
    ): EventStoreState => state.updateIn(['__meta', eventId, 'teams'], () => RemoteData.Loading()),

    [teamActions.types.loadTeamsByEventSuccess]: (
      state: EventStoreState,
      eventId: string,
      teams: TeamLike[],
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'teams'], () =>
        RemoteData.Success(List(teams.map(team => team.id))),
      ),

    [teamActions.types.loadTeamsByEventError]: (
      state: EventStoreState,
      eventId: string,
      error,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'teams'], () => RemoteData.Failure(error)),

    //
    // Event Activity
    [eventActivityActions.types.loadActivitiesByEvent]: (
      state: EventStoreState,
      eventId: string,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'activities'], () => RemoteData.Loading()),

    [eventActivityActions.types.loadActivitiesByEventSuccess]: (
      state: EventStoreState,
      eventId: string,
      activities: EventActivityLike[],
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'activities'], () =>
        RemoteData.Success(List(activities.map(activity => activity.activityId))),
      ),

    [eventActivityActions.types.loadActivitiesByEventError]: (
      state: EventStoreState,
      eventId: string,
      error,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'activities'], () => RemoteData.Failure(error)),

    //
    // Game (Summary, Details)
    [gameActions.types.loadGamesSummaryByEvent]: (
      state: EventStoreState,
      eventId,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'games', 'summary'], () => RemoteData.Loading()),

    [gameActions.types.loadWorldTourGamesSummary]: (
      state: EventStoreState,
      eventId,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'games', 'summary'], () => RemoteData.Loading()),

    [gameActions.types.loadGamesSummaryByEventSuccess]: (
      state: EventStoreState,
      eventId,
      games: ResultsGameSummaryLike[],
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'games', 'summary'], () =>
        RemoteData.Success(List(games.map(game => game.gameId))),
      ),

    [gameActions.types.loadGamesSummaryByEventError]: (
      state: EventStoreState,
      eventId,
      error,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'games', 'summary'], () => RemoteData.Failure(error)),

    [gameActions.types.loadWorldTourGamesSummaryError]: (
      state: EventStoreState,
      eventId,
      error,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'games', 'summary'], () => RemoteData.Failure(error)),

    //
    // Categories
    [categoryActions.types.loadCategoriesByEvent]: (
      state: EventStoreState,
      eventId: string,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'categories'], () => RemoteData.Loading()),

    // Almost identical with loadCategoriesByEvent but getting categories with classification type WorldTour
    [categoryActions.types.loadWorldTourCategoriesByEvent]: (
      state: EventStoreState,
      eventId: string,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'categories'], () => RemoteData.Loading()),

    [categoryActions.types.loadCategoriesByEventSuccess]: (
      state: EventStoreState,
      eventId: string,
      categories: CategoryLike[],
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'categories'], () =>
        RemoteData.Success(List(categories.map(category => category.id))),
      ),

    [categoryActions.types.loadCategoriesByEventError]: (
      state: EventStoreState,
      eventId: string,
      error,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'categories'], () => RemoteData.Failure(error)),

    //
    // Event news previews
    [newsActions.types.loadNewsByEvent]: (
      state: EventStoreState,
      eventId: string,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'news', 'previews'], () => RemoteData.Loading()),

    [newsActions.types.loadNewsPreviewsByEventSuccess]: (
      state: EventStoreState,
      eventId: string,
      news: NewsListLike,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'news', 'previews'], () =>
        RemoteData.Success(List(news.items.map(newsItem => newsItem.slug))),
      ),

    [newsActions.types.loadNewsPreviewsByEventError]: (
      state: EventStoreState,
      eventId: string,
      error,
    ): EventStoreState =>
      state.updateIn(['__meta', eventId, 'news', 'previews'], () => RemoteData.Failure(error)),

    // Scores client
    [SCORES_CLIENT_EVENT]: (state, {eventId, data}: IScoresClientEvent['payload']) => {
      // NOTE: Unlike in common eventStore, we store this under __meta
      return state.setIn(
        ['__meta', eventId, 'ongoingGames'],
        Map(data).map(value => ScoresClientOngoingGame.fromJS(value)),
      );
    },
  },
);

export const actions = createActions(__filename, reducers, {
  loadEvent: (eventId: string) => ({apiClient}: CommonServices) =>
    apiClient.loadEventWebData(eventId).then(data => actions.loadEventResponse(eventId, data)),
});

export default actions;

//
// Deserialization

interface MetaLike {
  teams?: RemoteData.WebData<string[]>;
  categories?: RemoteData.WebData<string[]>;
  activities?: RemoteData.WebData<string[]>;
  news?: {previews: RemoteData.WebData<string[]>};
  games?: {summary: RemoteData.WebData<string[]>};
}

interface StateLike {
  __isWebDataBased?: boolean;
  __meta?: Record<string, MetaLike>;
}

/**
 * Take a value and convert to RemoteData.NotAsked if undefined, otherwise to its record repersentation
 */
export function toRemoteDataWithList<Item>(
  val: RemoteData.WebData<Item[]> | undefined,
): RemoteData.WebData<List<Item>> {
  if (val === undefined) {
    return RemoteData.NotAsked();
  }
  return RemoteData.toImmutableRecord(
    val,
    failureData => Anomaly.toImmutableRecord(failureData),
    successData => List(successData),
  );
}

export const deserializeState = (state: StateLike) => {
  const {__meta, __isWebDataBased, ...eventsData} = state;

  // We must transform the meta keys to RemoteData values
  // This little piece of hell is to ensure that the Meta has all known keys
  // and values, and that they are RemoteData Records
  // On the upside, this lets us type things!
  let deserializedMeta: MetaLike;
  if (__meta === undefined) {
    deserializedMeta = {};
  } else {
    deserializedMeta = mapValues(__meta, metaValue => {
      const {teams, categories, activities, news, games} = metaValue;

      return {
        teams: toRemoteDataWithList(teams),
        categories: toRemoteDataWithList(categories),
        activities: toRemoteDataWithList(activities),
        news:
          news === undefined
            ? {previews: RemoteData.NotAsked()}
            : {previews: toRemoteDataWithList(news.previews)},
        games:
          games === undefined
            ? {summary: RemoteData.NotAsked()}
            : {summary: toRemoteDataWithList(games.summary)},
      };
    });
  }

  return fromJS({
    __isWebDataBased: __isWebDataBased || true,
    __meta: deserializedMeta,
    // Dictionary<string, WebData<EventLike>> -> Dictionary<string, WebData<Event>>
    ...mapValues(
      eventsData as Array<RemoteData.WebData<EventLike>>,
      (eventData: RemoteData.WebData<EventLike>) =>
        // TODO: WebData.toImmutableRecord
        // TODO: Immutable Anomaly
        RemoteData.toImmutableRecord(
          eventData,
          anomalyLike => Anomaly.toImmutableRecord(anomalyLike),
          eventLike => Event.fromJS(eventLike),
        ),
    ),
  });
};

//
// UTILS

/**
 * A standardised selector to pick an event type from the store.
 * To definitively say that an event is a Challenger or a SuperQuest,
 * we must have asked for that data. Those events will have challngerFor or superQuestFor.
 * Otherwise, it is a Masters.
 */
export function getEventType(
  state: EventStoreState,
  eventId: string,
): RemoteData.WebData<EventType> {
  return RemoteData.map(state.get(eventId, RemoteData.NotAsked()), deduceEventType);
}

/**
 * A standardised selector to pick an event name from the store.
 */
export function getEventName(state: RootState, eventId: string): RemoteData.WebData<string> {
  return RemoteData.map(
    state.getIn(['events', eventId], RemoteData.NotAsked()),
    (event: Event) => event.shortName || event.name,
  );
}

/**
 * Resolve an EventType for an Event.
 * This is a standardised check, because we rely on the existence
 * of specific properties, which is otherwise icky.
 */
export function deduceEventType(event: Event): EventType {
  // Challengers have the challengerFor field including non-null
  if (event.challengerFor !== undefined && event.challengerFor !== null) {
    return 'Challenger';
  }
  // Tricky part here: SuperQuests can have a superQuestFor field
  // of 'null', instead of undefined. This happens because some
  // SuperQuests in 2020, 2021 (extraordinary for coronavirus) are not
  // linked to a Masters (null). Thus, the distinction is important!
  // This is kind of icky, but hopefully keeping the check here helps.
  if (event.superQuestFor !== undefined) {
    return 'SuperQuest';
  }

  if (!event.tourId) {
    return 'Cup';
  }

  // This used to be "masters" to combine WS and WT, but to simplify our types
  // it now returns "WorldTour" for WS as well.
  return 'WorldTour';
}
