import {fromJS, Map} from 'immutable';
import {createActions, createReducer, setMeta, rethrow} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';

import {CustomForm} from 'fiba/common/core/models/api/customForms/CustomForm';
import {CustomFormType} from 'fiba/common/core/customForms';
import {RootState} from 'fiba/play/stores/rootStore';
import CommonServices from 'fiba/common/core/models/CommonServices';

export const storePath = 'customForms';

export interface CustomFormState extends Map<string, any> {
  // __meta: {
  //   [key: string]: {categories: {[categoryId]: {[CustomFormType]: {'isLoading' | 'isLoaded' | 'isError'}}}};
  // };
  // events: {
  //   [key: string]: {
  //     categories: {
  //       [key: string]: {
  //         team: CustomForm
  //         player: CustomForm
  //       }
  //     }
  //   }
  // };
}

const actions = createActions(__filename, {
  loadCustomForm: (eventId: string, categoryId: string, formType: CustomFormType) => ({
    apiClient,
  }: CommonServices) => [
    {eventId, categoryId, formType},
    apiClient
      .loadCustomForm(eventId, categoryId, formType)
      .then((form: Map<any, any>) => CustomForm.fromJS(form.toJS())) // FIXME: is there a cleaner way?
      .then(form => actions.loadCustomFormSuccess(eventId, form))
      .catch(rethrow(err => actions.loadCustomFormError(eventId, categoryId, formType, err))),
  ],
  loadCustomFormSuccess: (eventId: string, form: CustomForm) => [{eventId, form}],

  loadCustomFormError: (eventId: string, categoryId: string, formType: CustomFormType, error) => [
    {eventId, categoryId, formType},
    notificationActions.addNotificationFromError(error),
  ],
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    loadCustomForm: (state: CustomFormState, {eventId, categoryId, formType}): CustomFormState =>
      state.updateIn(['__meta', eventId, 'categories', categoryId, formType], setMeta.isLoading),

    loadCustomFormSuccess: (state: CustomFormState, {eventId, form}): CustomFormState =>
      state
        .updateIn(['__meta', eventId, 'categories', form.categoryId, form.type], setMeta.isLoaded)
        .setIn(['events', eventId, 'categories', form.categoryId, form.type], form),

    loadCustomFormError: (
      state: CustomFormState,
      {eventId, categoryId, formType},
    ): CustomFormState =>
      state.updateIn(['__meta', eventId, 'categories', categoryId, formType], setMeta.isError),
  },
  fromJS({}),
);

// Deserialize state
export function deserializeState({__meta = {}, events = {}} = {}) {
  const convertedForms = Object.keys(events).reduce((forms, eventId) => {
    forms[eventId] = {
      categories: {},
    };

    Object.keys(events[eventId]['categories']).map(categoryId => {
      forms[eventId]['categories'][categoryId] = Object.keys(
        events[eventId]['categories'][categoryId],
      ).reduce((categoryForms, type) => {
        categoryForms[type] = CustomForm.fromJS(events[eventId].categories[categoryId][type]);
        return categoryForms;
      }, {});
    });

    return forms;
  }, {});

  return fromJS({
    __meta,
    events: convertedForms,
  });
}

// Selectors
export const getForm = (
  state: RootState,
  eventId: string,
  categoryId: string,
  type: CustomFormType,
): CustomForm | undefined => {
  const form: CustomForm = state.getIn([
    storePath,
    'events',
    eventId,
    'categories',
    categoryId,
    type,
  ]);
  return form && form.segments.size > 0 ? form : undefined;
};
