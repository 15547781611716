/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {PlayByPlayTableOfficialsAction} from './PlayByPlayTableOfficialsAction';
import {PlayByPlayPlayer} from './PlayByPlayPlayer';
import {PlayByPlayTeam} from './PlayByPlayTeam';

export const defaultPlayByPlayTableOfficialsData = {
  actions: null,
  gameId: null,
  players: null,
  homeTeam: null,
  awayTeam: null,
};
export const defaultPlayByPlayTableOfficialsDataEmpty = {};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type PlayByPlayTableOfficialsDataLike = {
  [K in keyof typeof defaultPlayByPlayTableOfficialsData]?: PlayByPlayTableOfficialsData[K];
};
export type PlayByPlayTableOfficialsDataEmptyLike = {
  [K in keyof typeof defaultPlayByPlayTableOfficialsDataEmpty]?: PlayByPlayTableOfficialsDataEmpty[K];
};

export class PlayByPlayTableOfficialsData extends Record(defaultPlayByPlayTableOfficialsData) {
  actions?: List<PlayByPlayTableOfficialsAction>;
  gameId?: string;
  players?: List<PlayByPlayPlayer>;
  homeTeam?: PlayByPlayTeam;
  awayTeam?: PlayByPlayTeam;
  static fromJS(o: PlayByPlayTableOfficialsDataLike): PlayByPlayTableOfficialsData {
    return (
      o &&
      new PlayByPlayTableOfficialsData({
        actions:
          o.actions && List(o.actions).map(i => i && PlayByPlayTableOfficialsAction.fromJS(i)),
        gameId: o.gameId,
        players: o.players && List(o.players).map(i => i && PlayByPlayPlayer.fromJS(i)),
        homeTeam: o.homeTeam && PlayByPlayTeam.fromJS(o.homeTeam),
        awayTeam: o.awayTeam && PlayByPlayTeam.fromJS(o.awayTeam),
      })
    );
  }
}
export class PlayByPlayTableOfficialsDataEmpty extends Record(
  defaultPlayByPlayTableOfficialsDataEmpty,
) {
  static fromJS(o: PlayByPlayTableOfficialsDataEmptyLike): PlayByPlayTableOfficialsDataEmpty {
    return o && new PlayByPlayTableOfficialsDataEmpty({});
  }
}

export default PlayByPlayTableOfficialsData;
