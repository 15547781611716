import React from 'react';
import {List} from 'immutable';
import {ResultsGroup} from 'fiba/common/core/models/api/results/ResultsGroup';
import {PoolsOverview} from 'fiba/wt/ui/poolsOverview/PoolsOverview';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {ResourceLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';

interface IPoolStandingsSummarySubTab {
  season: string;
  eventId: string;
  eventType: EventType;
  pools: List<ResultsGroup>;
  title: string;
  createPoolHref: ResourceLinkProvider;
}

export const PoolStandingsSummarySubTab: React.FunctionComponent<IPoolStandingsSummarySubTab> = ({
  season,
  eventId,
  pools,
  title,
  createPoolHref,
  eventType,
}) => (
  <div className="PoolStandingsSummarySubTab">
    <Heading fontSize="3">{title}</Heading>

    <Section>
      <PoolsOverview
        season={season}
        eventId={eventId}
        eventType={eventType}
        pools={pools}
        createPoolHref={createPoolHref}
      />
    </Section>
  </div>
);
