import React from 'react';
import moment from 'moment';
import {Circle} from '@fpapado/react-feather';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {ResultsTeam} from 'fiba/common/core/models/api/results/ResultsTeam';
import {ServicesContext} from 'fiba/common/services/services';
import {LocalizerService} from 'fiba/common/services/localizerService';
import {getTeamDisplayScore, getTeamDisplayName} from 'fiba/common/core/results';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';
import {
  isLive,
  isFinal,
  isFuture,
  getGroupName,
  isOvertime,
} from 'fiba/wt/ui/gameCardWidget/gameCardUtils';
import {Box} from 'fiba/wt/ui/box/Box';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Link} from 'fiba/wt/ui/link/Link';
import {parseLocalDatetime, parseFullDatetime} from 'fiba/common/utils/schedule';
import {Flag} from 'fiba/wt/ui/flag/Flag';

interface IGameCard {
  game: ResultsGameSummary;
  now: number;
  isScheduleEventTime?: boolean;
  createTeamHref: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
  showCategory: boolean;
}

interface IGameCardTeam {
  game: ResultsGameSummary;
  team: ResultsTeam;
  localizer: LocalizerService;
  createTeamHref: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
}

function getGameStateLabel(game: ResultsGameSummary) {
  if (isLive(game)) {
    return <LiveStateLabel gameTime={isOvertime(game) ? 'OT' : game.remainingGameTime} />;
  }

  if (isFinal(game)) {
    return <Box extraClassName="ttu">Final</Box>;
  }

  return '';
}

export const LiveStateLabel = ({
  gameTime,
  extraClassName = '',
}: {
  gameTime?: string;
  extraClassName?: string;
}) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      bgColor="red-20"
      color="silver-10"
      ph="2"
      borderRadius="1"
      fontSize="7"
      shadow="1"
      extraClassName={`ttu ${extraClassName}`}
    >
      <Circle fill="#fff" className="pr2" purpose="decorative" />
      <span className="fw7 pr2">Live</span>
      {gameTime && <span className="tnum">{gameTime}</span>}
    </Flex>
  );
};

// Extracted function for testing momentjs
export const checkIsSameDate = (now: number, gameStartDatetime: Date) =>
  moment(now).startOf('date').isSame(moment(gameStartDatetime).startOf('date'));

function getGameStartTime(
  game: ResultsGameSummary,
  now: number,
  isScheduleEventTime: boolean,
  localizer: LocalizerService,
) {
  const customFormat = {
    date: {
      time: {
        hour: 'numeric',
        minute: 'numeric',
      },
      timeWithDate: {
        day: 'numeric',
        month: 'short',
        // Let's skip the year, since it's implicit for the tour season
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  };

  // Refigure this out to support `isScheduleEventTime`
  // const gameStartDatetime = moment.utc(game.gameStartDatetime, 'YYYY-MM-DDTHH:mm-Z');
  const gameStartDatetime = isScheduleEventTime
    ? parseLocalDatetime(game.gameStartDatetime)
    : parseFullDatetime(game.gameStartDatetime);
  // Hmm, does it make sense to track the same date between game and now if they are in different timezone?

  const isSameDate = checkIsSameDate(now, gameStartDatetime);

  // See if we should display the date in the case the game doesn't happen today
  const format = isSameDate
    ? '{gameStartDatetime, date, time}'
    : '{gameStartDatetime, date, timeWithDate}';

  // localizer formats date here
  return localizer.format(format, {gameStartDatetime}, customFormat);
}

const GameCardTeam: React.FC<IGameCardTeam> = ({
  game,
  team,
  localizer,
  createTeamHref,
  createGameHref,
}) => (
  // TODO: dl dd dt elements
  <Flex pv="1" fontWeight={team.teamIsWinner ? '6' : '4'} debugClassName="GameCardTeam">
    <Box flexGrow="1" debugClassName="GameCardTeam-Name">
      <Flag ioc={team.teamNationality} width={32} includeAlt={true} extraClassName="w2" />
      <Box display="inline_block" pa="1" extraClassName="v-top">
        {!!team.teamId ? (
          <Link href={createTeamHref(team.teamId)}>{getTeamDisplayName(team, localizer)}</Link>
        ) : (
          <span>{getTeamDisplayName(team, localizer)}</span>
        )}
      </Box>
    </Box>
    <Box flexShrink="0" pa="1" debugClassName="GameCardTeam-Score">
      {!!game.gameId && !!team.teamId ? (
        <Link href={createGameHref(game.gameId)}>
          {getTeamDisplayScore(team, game, localizer)}{' '}
        </Link>
      ) : (
        getTeamDisplayScore(team, game, localizer)
      )}
    </Box>
  </Flex>
);

export const GameCard: React.FC<IGameCard> = ({
  game,
  now,
  isScheduleEventTime,
  createTeamHref,
  createGameHref,
  showCategory,
}) => (
  <ServicesContext.Consumer>
    {({localizer}) => (
      <Box pv="1" debugClassName="GameCard">
        <Box pa="1">
          <GameCardTeam
            game={game}
            team={game.homeTeam}
            localizer={localizer}
            createTeamHref={createTeamHref}
            createGameHref={createGameHref}
          />
          <GameCardTeam
            game={game}
            team={game.awayTeam}
            localizer={localizer}
            createGameHref={createGameHref}
            createTeamHref={createTeamHref}
          />
        </Box>
        <Flex ph="1" pb="2">
          {/* TODO: Link to the gamepage */}
          <Box flex="auto" debugClassName="GameCard-Pool">
            {getGroupName(game, showCategory)}
          </Box>
          <Box flexShrink="0" debugClassName="GameCard-Status">
            {isFuture(game, now)
              ? getGameStartTime(game, now, isScheduleEventTime, localizer)
              : getGameStateLabel(game)}
          </Box>
        </Flex>
      </Box>
    )}
  </ServicesContext.Consumer>
);
