import {AxiosInstance} from 'axios';
import {createRequestId} from '../server/services/requestIdMiddleware';

const requestIdInsertingMiddleware = (prefix, koaContext?) => (axios: AxiosInstance) => {
  axios.interceptors.request.use(config => {
    config.headers['X-Request-ID'] =
      koaContext && koaContext.headers['x-request-id']
        ? `HEROKU/${koaContext.headers['x-request-id']}+${createRequestId(prefix)}`
        : createRequestId(prefix);
    return config;
  });
  return axios;
};

export default requestIdInsertingMiddleware;
