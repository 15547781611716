import React from 'react';
import cx from 'classnames';
import {assetPath} from 'fiba/common/utils/reactUtils';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {Nav} from 'fiba/wt/ui/elements/Nav';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Navigation, defaultNavLinkStyle} from 'fiba/wt/ui/navigation/Navigation';
import {SeasonSelect} from 'fiba/wt/ui/tourLayout/SeasonSelect';

interface Props {
  season: string;
  siteConfig: ISiteConfig;
  eventId?: string;
  tourTab: string;
  extraClassName?: string;
  bgColor?: string;
}

interface ISeasonTabs {
  season: string;
  tourTab: string;
}

export const makeNLSeasonTabs = (season, tourTab) => [
  {name: 'News', href: `/${season}/news`, active: tourTab === 'news'},
  {name: 'Events', href: `/${season}/events`, active: tourTab === 'events'},
  {name: 'Photos', href: `/${season}/photos`, active: tourTab === 'photos'},
  {name: 'Videos', href: `/${season}/videos`, active: tourTab === 'videos'},
];

const SeasonTabs: React.FC<ISeasonTabs> = ({season, tourTab}) => {
  const tabs = makeNLSeasonTabs(season, tourTab);

  return (
    <ul className="ht-100 flex flex-row items-center overflow-x-auto">
      {tabs.map(({name, href, active}) => (
        <li key={`${tourTab}-${name}-${href}`} className="ws-nowrap">
          <a
            href={href}
            className={cx(
              'Navigation-Tab db pv2 ph3 f6 lh-title link link--bare mr2',
              {[defaultNavLinkStyle.light.idle]: !active},
              {[defaultNavLinkStyle.light.active]: active},
            )}
          >
            {name}
          </a>
        </li>
      ))}
    </ul>
  );
};

export const SeasonNavigation: React.FC<Props> = ({
  season,
  siteConfig,
  extraClassName,
  bgColor,
  tourTab,
}) => {
  const {features, theme} = siteConfig;

  return (
    <div className={cx('NationsLeagueNavigation', extraClassName)}>
      <Navigation season={season}>
        <Flex bgColor={theme.headerColor} style={{backgroundColor: bgColor}} height="3">
          {/* 3x3 large logo */}
          <a
            className="dn db-l flex justify-start ht3"
            href="https://fiba3x3.com"
            style={{minWidth: '13rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/3x3-logo-cut.svg')} alt="3x3" />
          </a>
          {/* 3x3 small logo */}
          <a
            className="db dn-l flex justify-start ht3"
            href="https://fiba3x3.com"
            style={{minWidth: '8rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/3x3-logo-cut-mobile.svg')} alt="3x3" />
          </a>
          {/* NL large logo */}
          <a
            className="dn db-l flex justify-start ht3"
            href={`/${season}`}
            // marginLeft with negative value to align the logo parallelogram shapes
            style={{minWidth: '13rem', marginLeft: '-2.7rem'}}
          >
            <img className="db ht-100" src={assetPath('/img/logo-nl.svg')} alt="Nations League" />
          </a>
          {/* NL small logo */}
          <a
            className="db dn-l flex justify-start ht3"
            href={`/${season}`}
            // marginLeft with negative value to align the logo parallelogram shapes
            style={{marginLeft: '-1.3rem'}}
          >
            <img
              style={{maxWidth: 'none'}}
              src={assetPath('/img/logo-nl.svg')}
              alt="Nations League"
            />
          </a>
          <Nav
            flexDirection="row"
            justifyContent="end"
            width="100"
            height="100"
            alignItems="center"
            aria-label="Nations League content"
            extraClassName="overflow-x-auto"
          >
            <SeasonTabs season={season} tourTab={tourTab} />
          </Nav>
          {!!features.showSeasonSelect && (
            <SeasonSelect
              containerStyleProps={{
                bgColor: 'fullwhite',
                ph: '3',
                height: '100',
                alignItems: 'center',
              }}
              buttonStyleProps={{color: 'dark-50'}}
            />
          )}{' '}
        </Flex>
      </Navigation>
    </div>
  );
};
