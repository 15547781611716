import React, {useContext} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {List} from 'immutable';
import {NewsList} from 'fiba/wt/ui/newsList/NewsList';
import {NewsListCondensed} from 'fiba/wt/ui/newsList/NewsListCondensed';
import {NewsItem} from 'fiba/common/core/models/api/feed/NewsItem';
import {eventNewsLink, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {getConferenceIdForEvent} from 'fiba/nationsleague/store';

interface OwnProps {
  season: string;
  eventId: string;
  emptyStateContent?: React.ReactNode;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    news: List<NewsItem>;
    eventType: EventType;
  }>;
}

const EVENT_NEWS_CONDENSED_COUNT = 10;

type Props = OwnProps & ReduxProps;

export const mapStateToNewsProps = (state, {eventId, season}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);

  // If it's an NL event, fetch conference news instead
  const conferenceId = getConferenceIdForEvent(state, season, eventId);

  const newsData = RemoteData.map(
    state.getIn(
      conferenceId
        ? ['conferences', '__meta', conferenceId, 'news', 'previews']
        : ['events', '__meta', eventId, 'news', 'previews'],
    ) as RemoteData.WebData<List<string>>,
    newsItemIds =>
      newsItemIds.map(newsItemId => state.getIn(['news', 'previews', newsItemId])).toList(),
  );

  return {
    // We merge the data sources so that they either succeed together, or fail together #solidarity
    data: RemoteData.map2(newsData, eventTypeData, (news, eventType) => ({
      news,
      eventType,
    })),
  };
};

export const mapStateToCondensedProps = (state, ownProps: OwnProps): ReduxProps => {
  const {data} = mapStateToNewsProps(state, ownProps);
  return {
    data: RemoteData.map(data, loadedData => {
      return {
        ...loadedData,
        news: loadedData.news.take(EVENT_NEWS_CONDENSED_COUNT) as List<NewsItem>,
      };
    }),
  };
};

// Loaders

export const _EventNews: React.FC<Props> = ({
  season,
  eventId,
  data,
  emptyStateContent = null,
}: Props) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <div>
      <ViewWebData data={data}>
        {({news, eventType}) => {
          if (!news || news.size === 0) {
            return emptyStateContent;
          }
          return (
            <NewsList
              news={news}
              baseUrl={eventNewsLink(seasonConfig, season, eventId, eventType)}
              columnStyle="auto"
            />
          );
        }}
      </ViewWebData>
    </div>
  );
};

export const _EventNewsCondensed: React.FunctionComponent<Props> = ({
  season,
  eventId,
  data,
}: Props) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <div>
      <ViewWebData data={data}>
        {({news, eventType}) => (
          <NewsListCondensed
            news={news}
            baseUrl={eventNewsLink(seasonConfig, season, eventId, eventType)}
          />
        )}
      </ViewWebData>
    </div>
  );
};

// Store-connected full/condensed exports
export const EventNews = connect<ReduxProps, {}, OwnProps>(mapStateToNewsProps)(_EventNews);

export const EventNewsCondensed = connect<ReduxProps, {}, OwnProps>(mapStateToCondensedProps)(
  _EventNewsCondensed,
);
