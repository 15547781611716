/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultPlayerGameStats = {
  playerId: null,
  gameId: null,
  teamId: null,
  totalPointsMade: null,
  shootingEfficiency: null,
  shootingValue: null,
  highlights: null,
  playerValue: null,
  onePointsMade: null,
  onePointsAttempted: null,
  onePointsMissed: null,
  twoPointsMade: null,
  twoPointsAttempted: null,
  twoPointsMissed: null,
  freeThrowsMade: null,
  freeThrowsAttempted: null,
  freeThrowsMissed: null,
  dunks: null,
  assists: null,
  keyAssists: null,
  buzzerBeaters: null,
  drives: null,
  turnovers: null,
  blockedShots: null,
  jerseyNumber: null,
  pointDifference: null,
  secondsOnCourt: null,
  totalRebounds: null,
  offensiveRebounds: null,
  defensiveRebounds: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type PlayerGameStatsLike = {
  [K in keyof typeof defaultPlayerGameStats]?: PlayerGameStats[K];
};

export class PlayerGameStats extends Record(defaultPlayerGameStats) {
  playerId?: string;
  gameId?: string;
  teamId?: string;
  totalPointsMade?: number;
  shootingEfficiency?: number;
  shootingValue?: number;
  highlights?: number;
  playerValue?: number;
  onePointsMade?: number;
  onePointsAttempted?: number;
  onePointsMissed?: number;
  twoPointsMade?: number;
  twoPointsAttempted?: number;
  twoPointsMissed?: number;
  freeThrowsMade?: number;
  freeThrowsAttempted?: number;
  freeThrowsMissed?: number;
  dunks?: number;
  assists?: number;
  keyAssists?: number;
  buzzerBeaters?: number;
  drives?: number;
  turnovers?: number;
  blockedShots?: number;
  jerseyNumber?: string;
  pointDifference?: number;
  secondsOnCourt?: number;
  totalRebounds?: number;
  offensiveRebounds?: number;
  defensiveRebounds?: number;
  static fromJS(o: PlayerGameStatsLike): PlayerGameStats {
    return (
      o &&
      new PlayerGameStats({
        playerId: o.playerId,
        gameId: o.gameId,
        teamId: o.teamId,
        totalPointsMade: o.totalPointsMade,
        shootingEfficiency: o.shootingEfficiency,
        shootingValue: o.shootingValue,
        highlights: o.highlights,
        playerValue: o.playerValue,
        onePointsMade: o.onePointsMade,
        onePointsAttempted: o.onePointsAttempted,
        onePointsMissed: o.onePointsMissed,
        twoPointsMade: o.twoPointsMade,
        twoPointsAttempted: o.twoPointsAttempted,
        twoPointsMissed: o.twoPointsMissed,
        freeThrowsMade: o.freeThrowsMade,
        freeThrowsAttempted: o.freeThrowsAttempted,
        freeThrowsMissed: o.freeThrowsMissed,
        dunks: o.dunks,
        assists: o.assists,
        keyAssists: o.keyAssists,
        buzzerBeaters: o.buzzerBeaters,
        drives: o.drives,
        turnovers: o.turnovers,
        blockedShots: o.blockedShots,
        jerseyNumber: o.jerseyNumber,
        pointDifference: o.pointDifference,
        secondsOnCourt: o.secondsOnCourt,
        totalRebounds: o.totalRebounds,
        offensiveRebounds: o.offensiveRebounds,
        defensiveRebounds: o.defensiveRebounds,
      })
    );
  }
}

export default PlayerGameStats;
