import {toRoman} from 'roman-numerals';
import {LocalizerService} from 'fiba/common/services/services';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';

export function getTeamDisplayScore(team, game, localizer: LocalizerService) {
  if (!team || !game) {
    return;
  }
  const teamScore = team.get('teamScore');
  const teamIsWinner = team.get('teamIsWinner');
  const gameIsForfeit = game.get('gameIsForfeit');

  if (Number.isInteger(teamScore)) {
    return teamScore.toString();
  } else if (teamIsWinner !== undefined) {
    if (teamIsWinner) {
      return localizer.formatKey('TEAM_SCORE_WON');
    } else if (gameIsForfeit) {
      return localizer.formatKey('TEAM_SCORE_FORFEIT');
    } else {
      return localizer.formatKey('TEAM_SCORE_LOST');
    }
  }
}

export function getWinPercentage(standing, localizer: LocalizerService) {
  const winRatio = standing.get('winRatio');
  if (winRatio != null) {
    return localizer.format('{winRatio, number, percent}', {winRatio});
  } else {
    return localizer.formatKey('RESULTS_NOT_AVAILABLE');
  }
}

export function getAveragePoints(standing, localizer: LocalizerService) {
  const avgPoints = standing.get('averagePoints');
  if (avgPoints != null) {
    return localizer.format('{avgPoints, number, 1-decimal}', {avgPoints});
  } else {
    return localizer.formatKey('RESULTS_NOT_AVAILABLE');
  }
}

export function getTeamDisplayName(
  teamStanding: ResultsStanding,
  localizer: LocalizerService,
  playInRoundCode = '',
  seedVisible = false,
  defaultValue = localizer.formatKey('TEAM_SETUP_NONE'),
) {
  if (!teamStanding) {
    return defaultValue;
  }

  const teamName = teamStanding.teamName;
  const teamSeed = teamStanding.teamSeed;

  if (teamName && teamSeed && seedVisible) {
    return `${teamName} (${teamSeed})`;
  } else if (teamName) {
    return teamName;
  }

  const {setupRoundCode, setupGroupPoolCode, setupRoundNumber, setupRank} = teamStanding.toJS();
  switch (teamStanding.setupType) {
    case 'BestX':
      return localizer.formatKey('TEAM_SETUP_BEST_X', {
        romanRank: toRoman(setupRank),
        rank: setupRank,
      });
    case 'Standard':
      return setupRoundCode && setupGroupPoolCode && isNotNullOrUndefined(setupRank)
        ? localizer.formatKey('TEAM_SETUP_STANDARD', {
            poolCode: getPoolNameShort(
              localizer,
              setupRoundCode,
              setupGroupPoolCode,
              setupRoundNumber,
            ),
            romanRank: toRoman(setupRank),
            rank: setupRank,
          })
        : defaultValue;
    case 'Seeded':
    case 'Unseeded':
      // These are the unseeded placeholders that don't have a team or progression defined
      // We notate them here simply in style of `A1`, `A2`, `B1`, `B2`, etc..
      // This might change after we receive comments on this
      // TODO: How do we handle showing something when we do not have everything?
      // getPoolNameShort could/should/whatever handle missing data as much as possible
      // we have to intentionally design/decide what that looks like
      // possibly, this is setupRoundNumber not being available
      // Also in standard
      return setupRoundCode && setupGroupPoolCode && setupRank
        ? `${getPoolNameShort(
            localizer,
            setupRoundCode,
            setupGroupPoolCode,
            setupRoundNumber,
          )}${setupRank}`
        : defaultValue;
    case 'Winner':
    case 'Loser':
      const roundCode = teamStanding.setupRoundCode;
      // TODO: Where is this coming from?
      const gameNumber = teamStanding['setupGameNumberInGroup'];
      const gameName =
        roundCode && gameNumber
          ? getGameNameShort(roundCode, gameNumber, localizer)
          : // TODO: Where is this coming from?
            teamStanding['setupGameName'];
      const formatKey =
        teamStanding.setupType === 'Winner' ? 'TEAM_SETUP_WINNER' : 'TEAM_SETUP_LOSER';

      return localizer.formatKey(formatKey, {
        game: playInRoundCode ? gameName.replace(playInRoundCode, 'Play-In') : gameName,
      });
    default:
      return defaultValue;
  }
}

export const filterGroupGames = (predicate, groups) =>
  groups
    .map(group => group.update('groupGames', groupGames => groupGames.filter(predicate)))
    .filter(group => group.get('groupGames').size > 0);

export const filterGroupGamesByTeamId = (teamId: string, groups) =>
  filterGroupGames(
    game =>
      game.getIn(['homeTeam', 'teamId']) === teamId ||
      game.getIn(['awayTeam', 'teamId']) === teamId,
    groups,
  );

export const parseRoundCode = (roundCode: string) => {
  let matched = null;
  if ((matched = roundCode.match(/^L([0-9]+)$/))) {
    return {type: 'LX', last: parseInt(matched[1], 10)};
  } else if ((matched = roundCode.match(/^MSF([0-9]*)$/))) {
    // If the position is not defined, we default to 3rd place game as defined
    return {type: 'MSF', position: parseInt(matched[1], 10) || 3};
  } else if ((matched = roundCode.match(/^ML([0-9]+)C([0-9]+)$/))) {
    const last = parseInt(matched[1], 10) / 2;
    return {type: 'MLXCX', last, position: parseInt(matched[2], 10)};
  } else {
    return {type: roundCode};
  }
};

const getPoolNameShort = (
  localizer: LocalizerService,
  roundCode: string,
  poolCode: string,
  roundNumber?: number,
) => {
  const {type, ...params} = parseRoundCode(roundCode);
  // TODO: Check whether we should show '0', or not
  const fullCode = isNotNullOrUndefined(roundNumber) ? `R${roundNumber}-${poolCode}` : poolCode;
  return localizer.formatKey('POOL_NAME_SHORT_' + type, {poolCode: fullCode, ...params});
};

/**
 * Hi, uncomment if you need this
  const getPoolNameLong = (
    localizer: LocalizerService,
    roundCode: string,
    poolCode: string,
    roundNumber?: number,
  ) => {
    const {type, ...params} = parseRoundCode(roundCode);
    const fullCode = isNotNullOrUndefined(roundNumber) ? `R${roundNumber}-${poolCode}` : poolCode;
    return localizer.formatKey('POOL_NAME_LONG_' + type, {poolCode: fullCode, ...params});
  };
*/

export const getRoundNameShort = (roundCode: string, localizer: LocalizerService) => {
  const {type, ...params} = parseRoundCode(roundCode);
  return localizer.formatKey('ROUND_NAME_SHORT_' + type, params);
};

export const getRoundNameLong = (roundCode: string, localizer: LocalizerService) => {
  const {type, ...params} = parseRoundCode(roundCode);
  return localizer.formatKey('ROUND_NAME_LONG_' + type, params);
};

export const getGameNameShort = (
  roundCode: string,
  gameNumber: number,
  localizer: LocalizerService,
) => {
  const roundName = getRoundNameShort(roundCode, localizer);
  return localizer.formatKey('GAME_NAME_SHORT', {roundName, gameNumber});
};

export const getGameNameLong = (
  roundCode: string,
  gameNumber: number,
  localizer: LocalizerService,
) => {
  const roundName = getRoundNameLong(roundCode, localizer);
  return localizer.formatKey('GAME_NAME_LONG', {roundName, gameNumber});
};

export const calculateRankInStat = (teamScore, standings, statistic: string) =>
  standings.filter(team => teamScore.get(statistic) < team.get(statistic)).size > 0
    ? standings.filter(team => teamScore.get(statistic) < team.get(statistic)).size
    : 1;

function isNotNullOrUndefined(toTest: unknown) {
  return toTest !== null && toTest !== undefined;
}
