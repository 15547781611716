import TeamGameStats from 'fiba/common/core/models/api/stats/TeamGameStats';
import React from 'react';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {hcell, cell, asTimeStamp} from 'fiba/wt/ui/gameBoxScore/gameBoxScoreUtils';

interface IFoulStat {
  type: string;
  value: any;
}

type TableColumns = 'FOULTYPE' | 'COUNT';
type ColumnSpec = IColumnSpec<IFoulStat>;
type ColumnSpecRecord = () => Record<TableColumns, ColumnSpec>;

const createColumnsSpecs: ColumnSpecRecord = () => ({
  FOULTYPE: createSpec('Type', 'text', hcell('type')),
  COUNT: createSpec('Count', 'number', cell('value')),
});

const createSpec = (displayName: string, dataType, renderer) => ({
  name: displayName,
  srName: displayName,
  dataType,
  renderColumn: renderer,
});

const mapGameStatToFoulStat = (teamStats: TeamGameStats): IFoulStat[] => [
  {type: 'Team fouls', value: teamStats.teamFouls},
  {type: 'Time of 7th team foul', value: asTimeStamp(teamStats.teamFouls7at)},
  {
    type: 'Time of 10th team foul',
    value: asTimeStamp(teamStats.teamFouls10at),
  },
  {type: 'Unsportsmanlike fouls', value: teamStats.unsportsmanlikeFouls},
  {type: 'Disqualifying fouls', value: teamStats.technicalFouls},
];

interface Props {
  captionId: string;
  caption?: React.ReactNode;
  teamStats: TeamGameStats;
}

const GameStatsTeamFoulsTable: React.FunctionComponent<Props> = ({
  teamStats,
  caption,
  captionId,
}) => (
  <DataTable<TableColumns, IFoulStat>
    captionId={captionId}
    caption={caption}
    rows={mapGameStatToFoulStat(teamStats)}
    columns={['FOULTYPE', 'COUNT']}
    headerStyleProps={{display: 'none'}}
    headerExtraClassName="dark-20"
    rowExtraClassName="striped--light-even bb bw1 b--silver-20"
    columnsSpec={createColumnsSpecs()}
  />
);

export default GameStatsTeamFoulsTable;
