import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {List} from 'immutable';
import * as TeamStats from 'fiba/wt/stores/teamStatsStore';
import TeamStatsTable, {RenderTeamName} from 'fiba/wt/ui/common/statistics/TeamStatsTable';
import {FieldSort} from 'fiba/wt/ui/dataTable/DataTable';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {resolvePathWithGender} from 'fiba/wt/utils/storeUtils';

interface OwnProps {
  tourOrEventId: string;
  renderTeamName: RenderTeamName;
  genderTab?: CategoryGenderTab;
  showEventsPlayed?: boolean;
}

interface StateProps {
  teamStats: List<TeamStats.TeamStatRow>;
  filteredStats: List<TeamStats.TeamStatRow>;
  sortedBy: FieldSort;
  updatedAt?: string;
  metaPaths: string[];
}

interface DispatchProps {
  changeSorting: (field: string, columnsSpec) => any;
  changeFiltering: (filterBy: FilterOptions) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const fieldToList = (data, field) => {
  const fieldValue = data && field(data.toJS());
  return (data && fieldValue && List.of(...fieldValue)) || List();
};

const mapStateToProps = (state, {tourOrEventId, genderTab}: OwnProps): StateProps => {
  const tourOrEventPath = resolvePathWithGender(tourOrEventId, genderTab);
  const loaded = state.getIn(['teamStats', ...tourOrEventPath]);
  const metaPaths = [`teamStats/__meta/${tourOrEventPath.join('/')}`];

  return {
    teamStats: fieldToList(loaded, js => js.data),
    filteredStats: fieldToList(loaded, js => js.filteredData),
    sortedBy: loaded && loaded.get('sortedBy', undefined),
    updatedAt: loaded && loaded.get('updatedAt'),
    metaPaths,
  };
};

const mapDispatchToProps = (dispatch, {tourOrEventId, genderTab}: OwnProps): DispatchProps => ({
  changeSorting: (field: string, columnsSpec) => {
    dispatch(
      TeamStats.actions.sortStats(
        TeamStats.statePath(tourOrEventId, genderTab),
        field,
        columnsSpec,
      ),
    );
  },
  changeFiltering: (filterBy: FilterOptions) => {
    dispatch(
      TeamStats.actions.filterStats(TeamStats.statePath(tourOrEventId, genderTab), filterBy),
    );
  },
});

const TeamStatisticsImpl: React.FC<Props> = ({
  renderTeamName,
  teamStats,
  filteredStats,
  sortedBy,
  updatedAt,
  changeSorting,
  changeFiltering,
  tourOrEventId,
  metaPaths,
  showEventsPlayed = true,
}: Props) => {
  return (
    <div className="TourTeamsStatistics">
      <Loading
        metaPaths={metaPaths}
        onError={() => <BlockText>There was a problem loading the team stats</BlockText>}
      >
        {() => (
          <TeamStatsTable
            renderTeamName={renderTeamName}
            captionId={`${tourOrEventId}-team-stats`}
            teamStats={teamStats}
            filteredStats={filteredStats}
            sortedBy={sortedBy}
            filterAction={changeFiltering}
            updatedAt={updatedAt}
            sortAction={changeSorting}
            showEventsPlayed={showEventsPlayed}
          />
        )}
      </Loading>
    </div>
  );
};

export const TeamStatistics = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TeamStatisticsImpl);
