import React, {useMemo} from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {RootState} from 'fiba/wt/stores/rootStore';
import {Box} from 'fiba/wt/ui/box/Box';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {TypeScale} from 'fiba/wt/ui/stylingAPI/styleMaps';
import {styledAPItoClassNames} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {LoadingSVG} from 'fiba/wt/ui/svg/svg';
import {Map} from 'immutable';
import {Flex} from 'fiba/wt/ui/flex/Flex';
import {getPlayerName} from 'fiba/wt/ui/playByPlayFeed/PlayerName';
import PlayByPlayStatisticsData from 'fiba/common/core/models/api/playByPlay/PlayByPlayStatisticsData';
import PlayByPlayStatisticsAction from 'fiba/common/core/models/api/playByPlay/PlayByPlayStatisticsAction';
import PlayByPlayPlayer from 'fiba/common/core/models/api/playByPlay/PlayByPlayPlayer';
import PlayByPlayTeam from 'fiba/common/core/models/api/playByPlay/PlayByPlayTeam';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

interface OwnProps {
  gameId: string;
}

interface ReduxProps {
  data: RemoteData.RemoteData<PlayByPlayStatisticsData, {}>;
}

type Props = ReduxProps & OwnProps;

/**
 * The possible action types for PbP stats.
 * This is a convenience type-alias :)
 */
type StatActionDtoType = PlayByPlayStatisticsAction['type'];

const PlayByPlayStatisticsFeedImpl: React.FC<Props> = ({data}) => {
  return RemoteData.match(data, {
    Success: statisticsData => {
      return statisticsData.actions.isEmpty() ? (
        <BlockText>There are no Play-by-Play Statistics data for this game.</BlockText>
      ) : (
        <Feed tableData={statisticsData} />
      );
    },
    // The meta was an unexpected error
    Failure: () => <BlockText>An unexpected error occured.</BlockText>,
    // TODO: Make BareLoadingIndicator
    Loading: () => (
      <div className="LoadingIndicator pv5 tc">
        <div className="dib h4 w4 LoadingIndicator-Indicator">
          <LoadingSVG purpose="standalone" />
        </div>
      </div>
    ),
    NotAsked: () => null,
  });
};

export const PlayByPlayStatisticsFeed = connect<ReduxProps, {}, OwnProps>(
  (state: RootState, {gameId}: OwnProps): ReduxProps => {
    return {
      data: RemoteData.map(
        RemoteData.fromMetaObject(
          state.getIn(['games', '__meta', 'playByPlayStatsData', gameId], Map()).toJS(),
        ),
        () => state.getIn(['games', 'playByPlayStatsData', gameId]),
      ),
    };
  },
)(PlayByPlayStatisticsFeedImpl);

//
// Table

interface FeedProps {
  tableData: PlayByPlayStatisticsData;
}

// Font sizes for copy and title text
const TEXT_FONT_SIZE: TypeScale[] = ['6'];
const TITLE_FONT_SIZE: TypeScale[] = ['4'];
const LIST_CLS = styledAPItoClassNames({
  vSpace: '3',
  width: '100',
  fontSize: TEXT_FONT_SIZE,
  fontWeight: '4',
});

const Feed: React.FC<FeedProps> = ({tableData}) => {
  const playersLookup = useMemo<Map<string, PlayByPlayPlayer>>(() => {
    return Map(tableData.players.map(player => [player.id, player]));
  }, [tableData.players]);

  return (
    // NOTE: We use tabular numbers throughout
    <Box extraClassName="tnum PlayByPlayStatisticsFeed-Feed">
      <ul className={LIST_CLS}>
        {tableData.actions.map(
          (action, index) =>
            // We do not include a list item for finalisation
            action.type !== 'StatGameFinalizationActionDto' && (
              <li
                key={index}
                // NOTE: We use CSS to include the line linking items
                // If the action type is a key assist, mark it as such, so we can style it
                className={action.type === 'KeyAssistStatActionDto' ? 'KeyAssist' : ''}
              >
                <ViewAction
                  homeTeam={tableData.homeTeam}
                  awayTeam={tableData.awayTeam}
                  players={playersLookup}
                  action={action}
                />
              </li>
            ),
        )}
      </ul>
    </Box>
  );
};

// Action views

// TODO: All of these need cleaning up. Collapse ViewAction, and make the other ones return a {specificInfo, generalInfo} struct
// This would allow us to coalesce most of the ternaries/switch cases

/**
 * Display an action.
 * If the action belongs to a team, then assign it to the respective side of the screen.
 * NOTE: Actions should always belong to a team in the Stats feed.
 *
 * Layout Notes:
 *  - Display teams as different colors
 *  - Narrow screen: score board on the start, team action on the end
 *  - Wide screen: home team on the start, away team on the end
 */
const ViewAction: React.FC<{
  homeTeam: PlayByPlayTeam;
  awayTeam: PlayByPlayTeam;
  action: PlayByPlayStatisticsAction;
  players: Map<string, PlayByPlayPlayer>;
}> = ({homeTeam, awayTeam, action, players}) => {
  if (!homeTeam.teamId && !awayTeam.teamId) {
    return null;
  }

  // Decide the layout based on whether we have the home or away team
  const attributedTeam = action.teamId === homeTeam.teamId ? 'HomeTeam' : 'AwayTeam';
  const team = attributedTeam === 'HomeTeam' ? homeTeam : awayTeam;
  const flexDirection = attributedTeam === 'HomeTeam' ? 'row' : 'row-reverse';
  const bgcolor = attributedTeam === 'HomeTeam' ? 'blue-20' : 'purple-10';

  return (
    <Flex
      width="100"
      bgColor="silver-20"
      alignItems="center"
      // On narrow screens, always flip the items; otherwise, go with the one specified
      flexDirection={['row-reverse', flexDirection]}
      flexWrap="wrap"
    >
      <Flex
        pa="3"
        fontSize={TEXT_FONT_SIZE}
        width={['50', '33']}
        bgColor={bgcolor as any}
        color="dark-50"
        alignItems="center"
      >
        {renderActionDetailsBox(action, players, team)}
      </Flex>

      {/* For Scores, Fouls, render a general info box */}
      <Box pa="3" width={['50', '33']} textAlign="center">
        {renderGeneralInfoBox(action)}
      </Box>
    </Flex>
  );
};

/**
 * Render the details specific to each DTO
 */
const renderActionDetailsBox = (
  action: PlayByPlayStatisticsAction,
  players: Map<string, PlayByPlayPlayer>,
  team: PlayByPlayTeam,
) => {
  const humanActionName = translateActionType(action.type);

  switch (action.type) {
    // The action changed the score
    // The Score action is always attributed to a player
    case 'ScoreStatActionDto':
      return (
        <BlockText fontSize={TEXT_FONT_SIZE}>
          <span className="fw7">{getPlayerName(players, action.playerId)}</span>
          <br />
          {/*
              Show if it was a point made by a Free Throw or not,
              and whether the shot was made or missed 
          */}
          {action.isFreeThrow ? (
            <span>Free throw {action.isMade ? 'made' : 'missed'}</span>
          ) : (
            <span>
              {action.points} point shot {action.isMade ? 'made' : 'missed'}
            </span>
          )}
          {scoreStatSubActions(action)}
        </BlockText>
      );

    // Blocked shots are always assigned to a player
    case 'BlockStatActionDto':
      return (
        <BlockText fontSize={TEXT_FONT_SIZE}>
          <span className="fw7">{getPlayerName(players, action.playerId)}</span>
          <br />
          <span>{humanActionName}</span>
        </BlockText>
      );

    // Normal fouls are always assigned to a team
    case 'FoulStatActionDto':
      return (
        <BlockText fontSize={TEXT_FONT_SIZE}>
          <span className="fw7">
            <TeamNameAssembly standing={team} isSuffixVisible isIocVisible={false} />
          </span>
          <br />
          <span>{humanActionName}</span>
        </BlockText>
      );

    // Rebounds and turnovers can be assigned either to a team or a player
    case 'OffensiveReboundStatDto':
    case 'DefensiveReboundStatDto':
    case 'TurnOverStatActionDto':
      return (
        <BlockText fontSize={TEXT_FONT_SIZE}>
          <span className="fw7">
            {!!action.playerId ? (
              getPlayerName(players, action.playerId)
            ) : (
              <TeamNameAssembly standing={team} isSuffixVisible isIocVisible={false} />
            )}
          </span>
          <br />
          <span>{humanActionName}</span>
        </BlockText>
      );

    // Key Assists are special, in that they are meant to be followed by a score
    // Key assists are always assigned to a player
    // NOTE: Most of the styling is done in the parent container
    case 'KeyAssistStatActionDto':
      return (
        <BlockText fontSize={TEXT_FONT_SIZE}>
          <span className="fw7">{getPlayerName(players, action.playerId)}</span>
          <br />
          <span>{humanActionName}</span>
        </BlockText>
      );

    default:
      return <BlockText>{humanActionName}</BlockText>;
  }
};

const SCORE_BOX_STYLES = {
  border: 'all',
  borderColor: 'steel-20',
  borderRadius: '2',
  borderWidth: '1',
  pv: '1',
  ph: '2',
};

const renderGeneralInfoBox = (action: PlayByPlayStatisticsAction) => {
  switch (action.type) {
    case 'FoulStatActionDto':
      return (
        <Flex flexWrap={'nowrap'} justifyContent={'center'} alignItems="center">
          <Box {...(SCORE_BOX_STYLES as any)} mr={'2'}>
            {action.homeTeamFouls}
          </Box>
          <Box fontWeight={'6'} fontSize={TITLE_FONT_SIZE}>
            {' '}
            -{' '}
          </Box>
          <Box {...(SCORE_BOX_STYLES as any)} ml={'2'}>
            {action.awayTeamFouls}
          </Box>
        </Flex>
      );
    case 'ScoreStatActionDto':
      return (
        <Box fontWeight={'6'} textAlign={'center'} fontSize={TITLE_FONT_SIZE}>
          {action.homeTeamScore} - {action.awayTeamScore}
        </Box>
      );
    default:
      return null;
  }
};

// Utils

// Mapping actions, could just remove action word but this gives more flexibility
const translateActionType = (actionType: StatActionDtoType): string => {
  switch (actionType) {
    case 'ScoreStatActionDto':
      return 'Score';
    case 'KeyAssistStatActionDto':
      return 'Key assist';
    case 'TurnOverStatActionDto':
      return 'Turnover';
    case 'BlockStatActionDto':
      return 'Blocked shot';
    case 'FoulStatActionDto':
      return 'Foul';
    case 'OffensiveReboundStatDto':
      return 'Offensive rebound';
    case 'DefensiveReboundStatDto':
      return 'Defensive rebound';
    default:
      return actionType;
  }
};

const scoreStatSubActions = (action: PlayByPlayStatisticsAction) => {
  const subActions = [];
  if (action.isDrive) {
    subActions.push('Drive');
  }
  if (action.isDunk) {
    subActions.push('Dunk');
  }
  if (action.isBuzzerBeater) {
    subActions.push('Buzzerbeater');
  }

  return subActions.length > 0 ? ' (' + subActions.join(', ') + ')' : '';
};
