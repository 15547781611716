/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

import {ResultsTeam} from './ResultsTeam';

export const defaultResultsGameSummary = {
  gameId: null,
  gameName: null,
  gameLength: null,
  gameStartDatetime: null,
  gameIsForfeit: null,
  gameIsOvertime: null,
  gameIsPlayIn: null,
  gameNumberInGroup: null,
  groupId: null,
  groupName: null,
  groupPoolCode: null,
  roundCode: null,
  roundNumber: null,
  roundHasMultipleRounds: null,
  categoryId: null,
  categoryName: null,
  courtId: null,
  courtName: null,
  homeTeam: null,
  awayTeam: null,
  remainingGameTime: null,
  currentGameState: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type ResultsGameSummaryLike = {
  [K in keyof typeof defaultResultsGameSummary]?: ResultsGameSummary[K];
};

export class ResultsGameSummary extends Record(defaultResultsGameSummary) {
  gameId?: string;
  gameName?: string;
  gameLength?: number;
  gameStartDatetime?: string;
  gameIsForfeit?: boolean;
  gameIsOvertime?: boolean;
  gameIsPlayIn?: boolean;
  gameNumberInGroup?: number;
  groupId?: string;
  groupName?: string;
  groupPoolCode?: string;
  roundCode?: string;
  roundNumber?: number;
  roundHasMultipleRounds?: boolean;
  categoryId?: string;
  categoryName?: string;
  courtId?: string;
  courtName?: string;
  homeTeam?: ResultsTeam;
  awayTeam?: ResultsTeam;
  remainingGameTime?: string;
  currentGameState?: string;
  static fromJS(o: ResultsGameSummaryLike): ResultsGameSummary {
    return (
      o &&
      new ResultsGameSummary({
        gameId: o.gameId,
        gameName: o.gameName,
        gameLength: o.gameLength,
        gameStartDatetime: o.gameStartDatetime,
        gameIsForfeit: o.gameIsForfeit,
        gameIsOvertime: o.gameIsOvertime,
        gameIsPlayIn: o.gameIsPlayIn,
        gameNumberInGroup: o.gameNumberInGroup,
        groupId: o.groupId,
        groupName: o.groupName,
        groupPoolCode: o.groupPoolCode,
        roundCode: o.roundCode,
        roundNumber: o.roundNumber,
        roundHasMultipleRounds: o.roundHasMultipleRounds,
        categoryId: o.categoryId,
        categoryName: o.categoryName,
        courtId: o.courtId,
        courtName: o.courtName,
        homeTeam: o.homeTeam && ResultsTeam.fromJS(o.homeTeam),
        awayTeam: o.awayTeam && ResultsTeam.fromJS(o.awayTeam),
        remainingGameTime: o.remainingGameTime,
        currentGameState: o.currentGameState,
      })
    );
  }
}

export default ResultsGameSummary;
