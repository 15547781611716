import React from 'react';
import {
  ContentfulEmbeddedMediaProvider,
  EmbeddedMediaTypes,
  renderMediaComponent,
} from 'fiba/wt/ui/contentfulEmbeddedMedia/ContentfulEmbeddedMedia';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {NoticeText} from 'fiba/wt/ui/NoticeText/NoticeText';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';

export interface EventVideosPageProps {
  season: string;
  eventId: string;
}

export const EventVideosPage: React.FunctionComponent<EventVideosPageProps> = ({
  season,
  eventId,
}) => (
  <Spacer ph={['3', '3', '1']} debugClassName="EventVideosPage">
    <section>
      <EventPageHeading eventId={eventId} page="Videos" />
      <Section>
        <ContentfulEmbeddedMediaProvider
          contentPath={`events/${eventId}`}
          mediaType={EmbeddedMediaTypes.YoutubeMainPlaylist}
          emptyMediaContent={
            <NoticeText mt="4">
              The videos for this event will appear here once they are available.
            </NoticeText>
          }
        >
          {media => renderMediaComponent(media, 720)}
        </ContentfulEmbeddedMediaProvider>
      </Section>
    </section>
  </Spacer>
);
