import {ISeasonConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {DeepPartial} from 'fiba/common/core/typeUtils';

// This "DeepPartial" allows us to override or augment Contentful's SeasonConfig
//
// Moving 'hasOnlyWorldTourStats' to Contentful doesn't make sense since video footage of the seasons
//  below doesn't exist and never will so stats will never be available for those seasons.
const PartialSeasonConfig: DeepPartial<ISeasonConfig> = {
  seasons: {
    2015: {
      hasOnlyWorldTourStats: true,
    },
    2016: {
      hasOnlyWorldTourStats: true,
    },
    2017: {
      hasOnlyWorldTourStats: true,
    },
    2018: {
      hasOnlyWorldTourStats: true,
    },
  },
};

export default PartialSeasonConfig;
