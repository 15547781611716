import React from 'react';
import {List, Range} from 'immutable';
import {uniq} from 'lodash';

const CONNECTOR_THICKNESS = 3; // NOTE: Since there's currently no easy way of sharing this between SASS and JS, we keep it only here
const Z_INDEX_BASE = 10000; // this needs to be sufficiently large to accommodate largest possible bracket sizes

export interface KoHeadingProps {
  title: string;
}

interface KoBracketProps {
  koGamesList: List<any>;
  KoNodeImplementation: React.ComponentType; // Node implementation
  KoHeadingImplementation: React.ComponentType<KoHeadingProps>; // Heading implementation (e.g. Finals, Semi-Finals) (optional)
  koNodeStaticProps?: any; // Props for the Node implementation
  treeNodeHeight: number; // height reserved for a single game node in the tree; note that game names are rendered outside this box and don't affect the sizing logic
  treeNodeMinWidth: number; // smallest allowed game node width; after that we need to scroll
  treeNodeMaxWidth: number; // largest allowed game node width; after that we need to do centering
  verticalTreeSpacing: number; // amount of space between game nodes (this is where game names go)
  horizontalTreeSpacing: number; // amount of space reserved for the connectors to wiggle around
  largestKoGamesListSize?: number; // Size of the largest games list for calculating positions
  showKoHeadings?: boolean;
  withAnimations?: boolean;
}

export const KoBracket: React.FunctionComponent<KoBracketProps> = props => {
  const {
    koGamesList,
    KoNodeImplementation,
    KoHeadingImplementation,
    koNodeStaticProps,
    treeNodeHeight,
    treeNodeMinWidth,
    treeNodeMaxWidth,
    verticalTreeSpacing,
    horizontalTreeSpacing,
    largestKoGamesListSize,
    showKoHeadings,
  } = props;

  const maxTreeDepth = Math.log2(koGamesList.size + 1); // For finals this would be 1
  const tallestBracketDepth = !!largestKoGamesListSize
    ? Math.log2(largestKoGamesListSize + 1)
    : maxTreeDepth; // If a largest list size isn't given default to current bracket's size.
  const tallestColumnGameCount = Math.pow(2, maxTreeDepth - 1);
  const totalHeight = tallestColumnGameCount * (treeNodeHeight + verticalTreeSpacing);

  const rootStyles = {
    minWidth: (treeNodeMinWidth + horizontalTreeSpacing) * tallestBracketDepth,
    maxWidth: (treeNodeMaxWidth + horizontalTreeSpacing) * tallestBracketDepth,
  };

  return (
    <div className="KoBracket" style={rootStyles}>
      <div className="KoBracket-groups">
        {!!showKoHeadings &&
          maxTreeDepth < tallestBracketDepth &&
          getEmptyGroupHeadings(
            maxTreeDepth,
            tallestBracketDepth,
            horizontalTreeSpacing,
            KoHeadingImplementation,
          )}
        {!!showKoHeadings &&
          !!KoHeadingImplementation &&
          getGroupHeadings(koGamesList).map(heading => {
            if (!heading) {
              return null;
            } // Don't render anything if the heading isn't valid.

            return (
              <div
                key={heading}
                className="KoBracket-koheading"
                style={{maxWidth: treeNodeMaxWidth}}
              >
                <KoHeadingImplementation title={heading} />
              </div>
            );
          })}
      </div>

      <div
        className="KoBracket-games"
        style={{
          width: `calc(100% + ${horizontalTreeSpacing}px)`,
          height: totalHeight - verticalTreeSpacing,
        }}
      >
        {koGamesList.map((listGame, i) => {
          if (!listGame) {
            return;
          } // Don't render anything if this is an empty slot in the bracket

          const currentTreeDepth = Math.floor(Math.log2(i + 1)); // 0 based
          const row = i - Math.pow(2, currentTreeDepth) + 1;

          const gamesInThisColumn = Math.pow(2, currentTreeDepth);
          const gamesInNextColumn = Math.pow(2, currentTreeDepth + 1);
          const heightPerGame = totalHeight / gamesInThisColumn;
          const excessHeight =
            totalHeight - gamesInThisColumn * (treeNodeHeight + verticalTreeSpacing);
          const verticalCenteringAdjust = excessHeight / gamesInThisColumn / 2;

          const connectUp = row % 2 === 1;
          const hasUpstairsNeighbor = connectUp && row !== 0 && koGamesList.get(i - 1);
          const gameStyles = {
            width: `calc(${100 / tallestBracketDepth}% - ${horizontalTreeSpacing}px)`,
            height: treeNodeHeight,
            top: row * heightPerGame + verticalCenteringAdjust,
            left: ((tallestBracketDepth - currentTreeDepth - 1) / tallestBracketDepth) * 100 + '%',
            zIndex: Z_INDEX_BASE - i, // Root has the largest z-index
          };
          const connectorStyles = {
            width: horizontalTreeSpacing / 2,
            height: totalHeight / gamesInNextColumn + (connectUp ? CONNECTOR_THICKNESS : 0),
            right: -1 * horizontalTreeSpacing,
            [connectUp ? 'bottom' : 'top']:
              treeNodeHeight / 2 + CONNECTOR_THICKNESS / (connectUp ? -2 : 2),
            borderLeftWidth: CONNECTOR_THICKNESS,
            borderTopWidth: connectUp && !hasUpstairsNeighbor ? CONNECTOR_THICKNESS : 0,
            borderBottomWidth: !connectUp ? CONNECTOR_THICKNESS : 0,
          };
          const dividerStyles = {
            width: `calc(100% + ${horizontalTreeSpacing / 2 + CONNECTOR_THICKNESS}px)`,
            left: 0,
            top: `calc(50% - ${CONNECTOR_THICKNESS / 2}px)`,
            height: CONNECTOR_THICKNESS,
          };
          return (
            <div key={listGame.get('gameName')} className="KoBracket-game" style={gameStyles}>
              <div className="KoBracket-connector" style={connectorStyles} />
              <div>
                <div className="KoBracket-divider" style={dividerStyles} />
                {listGame.gameIsOvertime && renderOvertimeMarker()}
              </div>
              <KoNodeImplementation {...koNodeStaticProps} listGame={listGame} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

function renderOvertimeMarker() {
  return <span className="KoBracket-divider__overtime bg-silver-30">OT</span>;
}

// Render empty group headings in order to align headings correctly for smaller brackets.
function getEmptyGroupHeadings(
  currentTreeDepth,
  largestTreeDepth,
  horizontalTreeSpacing,
  KoHeadingImplementation,
) {
  return Range(0, largestTreeDepth - currentTreeDepth).map(() => (
    <div className="KoBracket-koheading" style={{marginRight: horizontalTreeSpacing}}>
      <KoHeadingImplementation title="" />
    </div>
  ));
}

// @example getGroupHeadings() => List.of('Quarter-Finals', 'Semi-Finals', 'Final')
function getGroupHeadings(koGamesList): List<string> {
  const allGroupHeadings: string[] = koGamesList
    .filter(listGame => !!listGame)
    .map(listGame => listGame.get('groupName'))
    .reverse()
    .toJS();
  return List(uniq(allGroupHeadings));
}
