import React from 'react';
import {Legend} from 'fiba/wt/ui/legend/Legend';
import {HeadingStyleProps} from '../heading/Heading';

interface Props {
  reduced: boolean;
  headingStyles?: HeadingStyleProps;
}

export const BoxScoreLegend: React.FC<Props> = ({reduced, headingStyles = {}}) => {
  if (reduced) {
    return <Legend headingStyles={headingStyles} definition={reducedLegendDefinition} />;
  }
  return <Legend headingStyles={headingStyles} definition={legendDefinition} />;
};

const legendDefinition = [
  ['No.', 'Jersey number'],
  ['PTS', 'Points scored'],
  ['REB', 'Rebounds'],
  ['HGL', 'Highlights', 'KAS + DRV + DNK + BS + BZR'],
  ['P-VAL', 'Player value', '(S-EFF * PTS) + KAS + DRV + DNK + BS + BZR + (REB/2) - TO'],
  ['S-EFF', 'Shooting efficiency', 'PTS / (1PTA + 2PTA + FTA)'],
  ['1PT', 'One-point field goals made / attempted'],
  ['2PT', 'Two-point field goals made / attempted'],
  ['FT', 'Free throws made / attempted'],
  ['KAS', 'Key assists'],
  ['DRV', 'Drives'],
  ['DNK', 'Dunks'],
  ['BS', 'Blocked shots'],
  ['BZR', 'Buzzerbeaters'],
  ['OREB', 'Offensive rebounds'],
  ['DREB', 'Defensive rebounds'],
  ['TO', 'Turnovers'],
  ['POS', 'Possessions'],
];

// Reduced Legends and Table columns for a legacy seasons
const reducedLegendDefinition = [
  ['No.', 'Jersey number'],
  ['PTS', 'Points scored'],
  ['1PT', 'One-point field goals made / attempted'],
  ['2PT', 'Two-point field goals made / attempted'],
  ['FT', 'Free throws made / attempted'],
];
