import React from 'react';
import {ChevronLeft, ChevronRight} from '@fpapado/react-feather';
import {useInView} from 'react-intersection-observer';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';
import {Box} from 'fiba/wt/ui/box/Box';
import {defaultNavLinkStyle, Tab} from 'fiba/wt/ui/navigation/Navigation';
import {NavigationList, NavigationListProps} from 'fiba/wt/ui/sidebarNavigation/SidebarNavigation';
import {
  getDefaultSecondaryTabs,
  getPreSecondaryTabs,
  getLiveAndPostSecondaryTabs,
  EventTabs,
  getContentfulNav,
  getTicketsPage,
} from 'fiba/wt/ui/eventLayout/EventLayoutUtils';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {connect} from 'fiba/common/utils/reactUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {EventType} from 'fiba/wt/utils/linkUtils';
import {
  EventColorTheme,
  EventConfiguration,
} from '../eventProgrammability/EventProgrammabilityStateProvider';
import {getArticleFromState, getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {ArticleTypes} from '../contentfulArticle/ContentfulArticle';
import {Map} from 'immutable';

interface OwnProps {
  siteConfig: ISiteConfig;
  season: string;
  eventId: string;
  eventTab: EventTabs;
  colorTheme: EventColorTheme;
  isSingleEventConference: boolean;
}

interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
  eventConfiguration: EventConfiguration;
  ticketsArticle: Map<string, any>;
}

type Props = OwnProps & ReduxProps;

type EventNavigation = OwnProps & {
  eventType: EventType;
  contentfulNavItems: Tab[];
  ticketsPageNav?: Tab;
} & Pick<NavigationListProps, 'lastItemRef'> &
  Pick<NavigationListProps, 'firstItemRef'>;

// Classname constants
const NAVIGATION_CLS =
  'flex dn-m flex-row pv2 items-center lh-title bg-silver-10 overflow-x-scroll scroll-touch EventLayout-SecondaryNavigation EventLayout-SecondaryNavigation--mobile';
const NAVIGATION_LI_CLS = 'ws-nowrap';
const NAVIGATION_LINK_CLS = 'f7 ph2 ph3-s pv2';
export const ARROW_CLS =
  'flex items-center justify-end dark-20 w2 absolute top-0 ht-100 animated animated--fast fade-in';

const NavigationDefault: React.FunctionComponent<EventNavigation> = ({
  siteConfig,
  season,
  eventId,
  eventTab,
  firstItemRef,
  lastItemRef,
  eventType,
  colorTheme,
  contentfulNavItems,
  ticketsPageNav,
  isSingleEventConference,
}) => (
  <NavigationList
    tabs={[
      ...getDefaultSecondaryTabs(
        siteConfig,
        season,
        eventId,
        eventTab,
        eventType,
        isSingleEventConference,
        ticketsPageNav,
      ),
      ...contentfulNavItems,
    ]}
    navLinkStyle={
      colorTheme === EventColorTheme.dark ? defaultNavLinkStyle.light : defaultNavLinkStyle.dark
    }
    extraClassName={NAVIGATION_CLS}
    listItemExtraClassName={NAVIGATION_LI_CLS}
    linkExtraClassName={NAVIGATION_LINK_CLS}
    firstItemRef={firstItemRef}
    lastItemRef={lastItemRef}
  />
);

const NavigationPre: React.FunctionComponent<EventNavigation> = ({
  siteConfig,
  season,
  eventId,
  eventTab,
  firstItemRef,
  lastItemRef,
  eventType,
  colorTheme,
  contentfulNavItems,
  ticketsPageNav,
  isSingleEventConference,
}) => (
  <NavigationList
    tabs={[
      ...getPreSecondaryTabs(
        siteConfig,
        season,
        eventId,
        eventTab,
        eventType,
        isSingleEventConference,
        ticketsPageNav,
      ),
      ...contentfulNavItems,
    ]}
    navLinkStyle={
      colorTheme === EventColorTheme.dark ? defaultNavLinkStyle.light : defaultNavLinkStyle.dark
    }
    extraClassName={NAVIGATION_CLS}
    listItemExtraClassName={NAVIGATION_LI_CLS}
    linkExtraClassName={NAVIGATION_LINK_CLS}
    firstItemRef={firstItemRef}
    lastItemRef={lastItemRef}
  />
);

const NavigationLiveAndPost: React.FunctionComponent<EventNavigation> = ({
  siteConfig,
  season,
  eventId,
  eventTab,
  firstItemRef,
  lastItemRef,
  eventType,
  colorTheme,
  contentfulNavItems,
  ticketsPageNav,
  isSingleEventConference,
}) => (
  <NavigationList
    tabs={[
      ...getLiveAndPostSecondaryTabs(
        siteConfig,
        season,
        eventId,
        eventTab,
        eventType,
        isSingleEventConference,
        ticketsPageNav,
      ),
      ...contentfulNavItems,
    ]}
    navLinkStyle={
      colorTheme === EventColorTheme.dark ? defaultNavLinkStyle.light : defaultNavLinkStyle.dark
    }
    extraClassName={NAVIGATION_CLS}
    listItemExtraClassName={NAVIGATION_LI_CLS}
    linkExtraClassName={NAVIGATION_LINK_CLS}
    firstItemRef={firstItemRef}
    lastItemRef={lastItemRef}
  />
);

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => ({
  eventTypeData: getEventType(state.get('events'), eventId),
  eventConfiguration: getEventConfiguration(state, eventId),
  ticketsArticle: getArticleFromState({
    state,
    contentPathArr: ['events', eventId],
    articleType: ArticleTypes.EventTickets,
  }),
});

export const EventNavigationMobileImpl: React.FunctionComponent<Props> = ({
  siteConfig,
  season,
  eventId,
  eventTab,
  eventTypeData,
  colorTheme,
  eventConfiguration,
  ticketsArticle,
  isSingleEventConference,
}) => {
  const [firstItemRef, firstInView] = useInView();
  const [lastItemRef, lastInView] = useInView();
  return (
    // TODO: localise label
    <nav className="relative" aria-label="Event content">
      {/* // Initialise observers for each item
            // This would be much nicer with hooks */}
      {/* Local state that tracks first/last item visibility */}
      <div className={`EventLayout-FadeLeft pl1 left-0 ${ARROW_CLS} ${!firstInView ? 'db' : 'dn'}`}>
        <ChevronLeft width="20" height="20" purpose="decorative" />
      </div>
      {RemoteData.match(eventTypeData, {
        NotAsked: () => null,
        Failure: () => null,
        Loading: () => (
          <Box bgColor="silver-10" pv="3" ph="4" fontSize="7" textAlign="left" fontWeight="6">
            <span className="animated lh-title fade-in delay">Loading...</span>
          </Box>
        ),
        Success: eventType => {
          const contentfulNavItems = getContentfulNav({
            eventConfiguration,
            eventTab,
            season,
            eventId,
            siteConfig,
            eventType,
          });
          const ticketsPageNav = getTicketsPage({
            ticketsArticle,
            season,
            eventId,
            eventTab,
          });

          return (
            <EventProgrammability
              season={season}
              eventId={eventId}
              renderDefault={() => (
                <NavigationDefault
                  siteConfig={siteConfig}
                  season={season}
                  eventId={eventId}
                  eventTab={eventTab}
                  firstItemRef={firstItemRef}
                  lastItemRef={lastItemRef}
                  eventType={eventType}
                  colorTheme={colorTheme}
                  contentfulNavItems={contentfulNavItems}
                  ticketsPageNav={ticketsPageNav}
                  isSingleEventConference={isSingleEventConference}
                />
              )}
              renderPre={() => (
                <NavigationPre
                  siteConfig={siteConfig}
                  season={season}
                  eventId={eventId}
                  eventTab={eventTab}
                  firstItemRef={firstItemRef}
                  lastItemRef={lastItemRef}
                  eventType={eventType}
                  colorTheme={colorTheme}
                  contentfulNavItems={contentfulNavItems}
                  ticketsPageNav={ticketsPageNav}
                  isSingleEventConference={isSingleEventConference}
                />
              )}
              renderLive={() => (
                <NavigationLiveAndPost
                  siteConfig={siteConfig}
                  season={season}
                  eventId={eventId}
                  eventTab={eventTab}
                  firstItemRef={firstItemRef}
                  lastItemRef={lastItemRef}
                  eventType={eventType}
                  colorTheme={colorTheme}
                  contentfulNavItems={contentfulNavItems}
                  ticketsPageNav={ticketsPageNav}
                  isSingleEventConference={isSingleEventConference}
                />
              )}
              renderPost={() => (
                <NavigationLiveAndPost
                  siteConfig={siteConfig}
                  season={season}
                  eventId={eventId}
                  eventTab={eventTab}
                  firstItemRef={firstItemRef}
                  lastItemRef={lastItemRef}
                  eventType={eventType}
                  colorTheme={colorTheme}
                  contentfulNavItems={contentfulNavItems}
                  ticketsPageNav={ticketsPageNav}
                  isSingleEventConference={isSingleEventConference}
                />
              )}
              loadingPlaceholder={() => (
                <Box bgColor="silver-10" pv="3" ph="4" fontSize="7" textAlign="left" fontWeight="6">
                  <span className="animated lh-title fade-in delay">Loading...</span>
                </Box>
              )}
            />
          );
        },
      })}
      <div
        className={`EventLayout-FadeRight pr1 right-0 ${ARROW_CLS} ${!lastInView ? 'db' : 'dn'}`}
      >
        <ChevronRight width="20" height="20" purpose="decorative" />
      </div>
    </nav>
  );
};

export const EventNavigationMobile = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  EventNavigationMobileImpl,
);
