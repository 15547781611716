import React from 'react';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {PhotoGallery} from 'fiba/wt/ui/photoGallery/PhotoGallery';
import {Photo} from 'fiba/common/core/models/api/media/Photo';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {Box} from 'fiba/wt/ui/box/Box';

interface OwnProps {
  season: string;
  eventId: string;
  teamId: string;
}

interface ReduxProps {
  photos: List<Photo>;
  metaPaths: string[];
}

type Props = OwnProps & ReduxProps;

export const mapStateToProps = (state, {teamId}: OwnProps): ReduxProps => {
  const photos = state.getIn(['media', 'photos', 'teams', teamId], List());
  const metaPaths = [`media/__meta/photos/teams/${teamId}`];

  return {
    photos,
    metaPaths,
  };
};

const _EventTeamPhotosPage: React.FunctionComponent<Props> = ({photos, metaPaths}) => {
  // TODO: We should probably have the name of the team and the event here!
  return (
    <Loading metaPaths={metaPaths}>
      {() => (
        <Box ph={['0', '3', '1']}>
          <PhotoGallery photos={photos} />
        </Box>
      )}
    </Loading>
  );
};

export const EventTeamPhotosPage = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventTeamPhotosPage,
);
