import React from 'react';
import moment from 'moment';
import {List} from 'immutable';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {
  findActiveEvent,
  isBeforeSeason,
  isAfterSeason,
  isInSeason,
} from 'fiba/wt/ui/tourProgrammability/tourProgrammabilityUtils';
import {connect} from 'fiba/common/utils/reactUtils';
import {RootState} from 'fiba/wt/stores/rootStore';

// TODO: Consider creating a `useTourProgrammabilityState`

export type TourProgrammabilityState =
  | 'none'
  | 'before-season'
  | 'between-events'
  | 'after-season'
  | 'event-in-pre'
  | 'event-in-live'
  | 'event-in-post';

interface OwnProps {
  eventConfiguration?: EventConfiguration[];
  // For testing purposes
  overrideNow?: moment.Moment | Date | string | number;
  children: (state: TourProgrammabilityRecord) => React.ReactElement<any>;
}

interface ReduxProps {
  events: RootState['events'];
}

interface TourProgrammabilityRecord {
  eventConfiguration?: EventConfiguration;
  state: TourProgrammabilityState;
}

export function getTourProgrammabilityState(
  eventConfiguration: OwnProps['eventConfiguration'],
  events: ReduxProps['events'],
  overrideNow?: OwnProps['overrideNow'],
): TourProgrammabilityRecord {
  const now = moment.utc(overrideNow);

  if (!eventConfiguration.length) {
    return {state: 'none'};
  }

  const eventIds = events.keySeq().toArray();
  const activeEvent = findActiveEvent(eventConfiguration, eventIds, now);

  // Ez-mode, derived straight from an event
  if (activeEvent) {
    switch (activeEvent.state) {
      case 'pre':
        return {state: 'event-in-pre', eventConfiguration: activeEvent.configuration};
      case 'live':
        return {state: 'event-in-live', eventConfiguration: activeEvent.configuration};
      case 'post':
        return {state: 'event-in-post', eventConfiguration: activeEvent.configuration};
    }
  }

  // No event is in pre, live or post state, so we must be either before or after season
  // or between events (a.k.a. in-season break)
  if (isBeforeSeason(eventConfiguration, now)) {
    return {state: 'before-season'};
  } else if (isAfterSeason(eventConfiguration, now)) {
    return {state: 'after-season'};
  } else if (isInSeason(eventConfiguration, now)) {
    return {state: 'between-events'};
  }

  // And back to square one
  return {state: 'none'};
}

const _TourProgrammabilityStateProvider: React.FC<OwnProps & ReduxProps> = ({
  children,
  eventConfiguration,
  overrideNow,
  events,
}) => {
  return children(getTourProgrammabilityState(eventConfiguration, events, overrideNow));
};

const mapStateToProps = (state: RootState, {}: OwnProps): ReduxProps => {
  return {
    events: state.getIn(['events'], List()),
  };
};

export const TourProgrammabilityStateProvider = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _TourProgrammabilityStateProvider,
);
