import React from 'react';
import {List} from 'immutable';
import {IStandingsTable, StandingsTable} from 'fiba/wt/ui/standingsTable/StandingsTable';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';

type IPoolStandings = IStandingsTable;

export function sortPoolStandings(standings: List<ResultsStanding>) {
  // NOTE: We introduced unseeded placeholders to pool standings that don't come from the OEM
  // These are the slots labeled `A1`, `A2`, `B1`, `R1-A1` etc..
  // Make sure we sort them first in the list, because if they exist,
  // the rest of standings should be progressions.
  // NOTE: Immutable `sortBy` is stable. If we convert to immer at one point, make sure to
  // use lodash `sortBy` since that is also stable unlike native browser implementations which
  // are not guaranteed to be stable
  return (
    standings
      // Initial sort by setupRank for placeholders
      .sortBy(standing => standing.setupRank)
      // Then make sure unseeded placeholders appear first
      .sortBy(standing => (standing.setupType === 'Unseeded' ? -1 : 1))
      // Finally, sort by team standing
      .sortBy(standing => standing.teamRank || Infinity) as List<ResultsStanding>
  );
}

export const PoolStandings: React.FunctionComponent<IPoolStandings> = ({standings, ...props}) => (
  <div className="PoolStandings">
    <StandingsTable standings={sortPoolStandings(standings)} {...props} />
  </div>
);
