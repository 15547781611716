import React from 'react';
import {Heading, HeadingStyleProps} from 'fiba/wt/ui/heading/Heading';
import {Box, BoxStyleProps} from 'fiba/wt/ui/box/Box';

interface Props {
  id?: string;
  text?: string;
  className?: string;
  children?: React.ReactNode;
  containerProps?: BoxStyleProps;
  headingProps?: HeadingStyleProps;
}

const DEFAULT_CONTAINER_PROPS: BoxStyleProps = {
  mt: ['3', '3', '3'],
  mh: ['2', '3', '0'],
  mb: '2',
  border: 'bottom',
  borderColor: 'silver-40',
  pb: '2',
};

const DEFAULT_HEADING_PROPS: HeadingStyleProps = {
  fontSize: ['3', '3', '3'],
  fontFamily: 'united',
  lineHeight: 'solid',
  letterSpacing: 'tiny',
  textAlign: 'left',
};

export function PageHeading({
  id,
  text,
  headingProps = {},
  containerProps = {},
  className,
  children,
}: Props) {
  return (
    <Box {...DEFAULT_CONTAINER_PROPS} {...containerProps}>
      <Heading extraClassName={className} {...DEFAULT_HEADING_PROPS} {...headingProps} id={id}>
        {text}
      </Heading>
      {children}
    </Box>
  );
}
