/*
  NOTE: This component is temporary MVP decision to have a hero image on the event page.
  Hence the component is located under ui/tourEventPage/. If this becomes a permanent solution,
  consider moving this under it's own folder.

  NOTE 2: This was changed to be a generic "hero" instead of an announcement for the event
  Allows manual tweaks of news
*/
import * as React from 'react';
import Event from 'fiba/common/core/models/api/events/Event';
import {connect} from 'fiba/common/utils/reactUtils';
import {ContentfulHeroImage} from 'fiba/wt/ui/tourPage/TourPageHeroImage';

interface EventPageHeroImageOwnProps {
  season: string;
  eventId: string;
}

interface EventPageHeroImageStateProps {
  event?: Event;
}

type EventPageHeroImageProps = EventPageHeroImageOwnProps & EventPageHeroImageStateProps;

const mapStateToProps = (
  state,
  {eventId}: EventPageHeroImageOwnProps,
): EventPageHeroImageStateProps => ({
  event: state.getIn(['events', eventId]),
});

const _EventPageHeroImage: React.FunctionComponent<EventPageHeroImageProps> = ({
  eventId,
  event,
}) => (
  <div className="EventPageHeroImage">
    <ContentfulHeroImage contentPath={`events/${eventId}`} />
  </div>
);

export const EventPageHeroImage = connect<
  EventPageHeroImageStateProps,
  {},
  EventPageHeroImageOwnProps
>(mapStateToProps)(_EventPageHeroImage);
