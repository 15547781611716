import React from 'react';
import {IThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {DomainType} from 'fiba/common/utils/domainUtils';
import {DeepPartial} from 'fiba/common/core/typeUtils';

export interface ISeasonConfig {
  seasons: {
    [season in Season]?: {
      showStatsForSeason?: boolean;
      tourId?: string;
      events: {
        [eventId: string]: string;
      };
      challengers: {
        [eventId: string]: string;
      };
      superQuests: {
        [eventId: string]: string;
      };
      hasOnlyWorldTourStats?: boolean;
    };
  };
}

export interface ISiteConfig {
  seasonConfig: ISeasonConfig;
  seasonConfigOverrides?: DeepPartial<ISeasonConfig>;
  features: {
    showFastestGamesSponsor: boolean;
    eventRegistrationEnabled: boolean;
    eventSocialMediaSharingEnabled: boolean;
    playerSocialMediaSharingEnabled: boolean;
    playerFollowEnabled: boolean;
    playerProfileEditingEnabled: boolean;
    tourSocialMediaSharingEnabled: boolean;
    wtInlineCriticalCss: boolean;
    wtLoadFontsAsync: boolean;
    showEventsInNavigation: boolean;
    showSeasonSelect: boolean;
    show3x3ChallengersLink: boolean;
    showTwitterFeed: boolean;
    showTourCalendar: boolean;
    siteId: DomainType;
  };
  theme: IThemeContext;
}

// NOTE: We write "as ISiteConfig" to avoid having to type this as Partial<SiteConfig>,
// and we hope/trust that there is a top-level provider with this.
const initConfig = {} as ISiteConfig;

export const SiteConfigContext = React.createContext<ISiteConfig>(initConfig);

export type Season =
  | '2010'
  | '2011'
  | '2012'
  | '2013'
  | '2014'
  | '2015'
  | '2016'
  | '2017'
  | '2018'
  | '2019'
  | '2020'
  | '2021'
  | '2022'
  | '2023'
  | '2024'
  | '2025'
  | '2026';
