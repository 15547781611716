import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {List} from 'immutable';
import {FieldSort} from 'fiba/wt/ui/dataTable/DataTable';
import * as PlayerStats from 'fiba/wt/stores/playerStatsStore';
import {
  PlayerStatsTable,
  TeamPlayerHrefSpec,
  RenderTeamName,
} from 'fiba/wt/ui/statistics/PlayerStatsTable';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';
import {BlockText} from 'fiba/wt/ui/text/Text';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';
import {resolvePathWithGender} from 'fiba/wt/utils/storeUtils';

interface OwnProps {
  tourOrEventId: string;
  season: string;
  eventId: string;
  createPlayerHref: TeamPlayerHrefSpec;
  renderTeamName: RenderTeamName;
  genderTab?: CategoryGenderTab;
  showEventsPlayed?: boolean;
}

interface StateProps {
  stats: List<PlayerStats.PlayerStatRow>;
  sortedBy: FieldSort;
  updatedAt?: string;
  filteredData: List<PlayerStats.PlayerStatRow>;
  metaPaths: string[];
}

interface DispatchProps {
  changeSorting: (field: string, columnsSpec) => any;
  changeFiltering: (filterBy: FilterOptions) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const fieldToList = (data, field) => {
  const fieldValue = data && field(data.toJS());
  return (data && fieldValue && List.of(...fieldValue)) || List();
};

const mapStateToProps = (state, {tourOrEventId, genderTab}: OwnProps): StateProps => {
  const tourOrEventPath = resolvePathWithGender(tourOrEventId, genderTab);
  const loaded = state.getIn(['playerStats', ...tourOrEventPath]);
  const metaPaths = [`playerStats/__meta/${tourOrEventPath.join('/')}`];
  return {
    stats: fieldToList(loaded, js => js.data),
    sortedBy: loaded && loaded.get('sortedBy', undefined),
    updatedAt: loaded && loaded.get('updatedAt'),
    filteredData: fieldToList(loaded, js => js.filteredData),
    metaPaths,
  };
};

const mapDispatchToProps = (dispatch, {tourOrEventId, genderTab}: OwnProps): DispatchProps => ({
  changeSorting: (field: string, columnsSpec) => {
    dispatch(
      PlayerStats.actions.sortStats(
        PlayerStats.statePath(tourOrEventId, genderTab),
        field,
        columnsSpec,
      ),
    );
  },
  changeFiltering: (filterBy: FilterOptions) => {
    dispatch(
      PlayerStats.actions.filterStats(PlayerStats.statePath(tourOrEventId, genderTab), filterBy),
    );
  },
});

const _PlayerStatistics: React.FunctionComponent<Props> = ({
  stats,
  sortedBy,
  updatedAt,
  changeSorting,
  changeFiltering,
  filteredData,
  tourOrEventId,
  metaPaths,
  season,
  createPlayerHref,
  renderTeamName,
  eventId,
  showEventsPlayed = true,
}) => {
  return (
    <div>
      <Loading
        metaPaths={metaPaths}
        onError={() => <BlockText>There was a problem loading the player stats</BlockText>}
      >
        {() => (
          <PlayerStatsTable
            stats={stats}
            captionId={`${tourOrEventId}-player-stats`}
            filteredData={filteredData}
            sortedBy={sortedBy}
            updatedAt={updatedAt}
            sortAction={changeSorting}
            filterAction={changeFiltering}
            season={season}
            createPlayerHref={createPlayerHref}
            renderTeamName={renderTeamName}
            eventId={eventId}
            showEventsPlayed={showEventsPlayed}
          />
        )}
      </Loading>
    </div>
  );
};

export const PlayerStatistics = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(_PlayerStatistics);
