import React from 'react';
import {EventTeams} from 'fiba/wt/ui/eventTeams/EventTeams';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {EventPageHeading} from 'fiba/wt/ui/eventPageHeading/EventPageHeading';
import Category from 'fiba/common/core/models/api/events/Category';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {capitalize} from 'lodash';
import {filterCategoryByGender, CategoryGenderTab} from 'fiba/wt/utils/categories';

export interface EventTeamsPageProps {
  season: string;
  eventId: string;
  tab?: EventTeamsPageTab;
}

interface ReduxProps {
  data: RemoteData.WebData<{
    categories: List<Category>;
  }>;
}

const _EventTeamsPage: React.FunctionComponent<Props> = ({tab, season, eventId, data}) => (
  <Spacer ph={['3', '3', '1']}>
    <section>
      <EventPageHeading eventId={eventId} page={tab ? capitalize(tab) : 'Teams'} />
      <Section>
        <ViewWebData data={data}>
          {({categories}) => {
            const shownCategoryIds = categories
              .filter(filterCategoryByGender(tab))
              .map(cat => cat.id)
              .toList();

            return (
              <EventTeams season={season} eventId={eventId} categoryIdFilter={shownCategoryIds} />
            );
          }}
        </ViewWebData>
      </Section>
    </section>
  </Spacer>
);

export const mapStateToProps = (state, {eventId}: EventTeamsPageProps): ReduxProps => {
  const categoriesData = RemoteData.map(
    state.getIn(
      ['events', '__meta', eventId, 'categories'],
      RemoteData.NotAsked(),
    ) as RemoteData.WebData<List<string>>,
    categoryIds =>
      categoryIds
        .map((categoryId: string) => state.getIn(['categories', categoryId]))
        .filter((category: Category) => !!category)
        .toList(),
  );

  const data = RemoteData.map(categoriesData, categories => ({
    categories,
  }));

  return {data};
};

export type EventTeamsPageTab = CategoryGenderTab;

type Props = EventTeamsPageProps & ReduxProps;

export const EventTeamsPage = connect<ReduxProps, {}, EventTeamsPageProps>(mapStateToProps)(
  _EventTeamsPage,
);
