import React from 'react';

/**
 * A component that hides contents visually, while keeping them accessible to screen readers.
 *
 * This is in contrast to display: none or visibility: hidden, which hide things from SRs.
 * It is the opposite of aria-hidden, which hides things from SRs, but does not affect visuals.
 *
 * This is backed by the visually-hidden class.
 *
 * Origin: https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 * Further reading: https://a11yproject.com/posts/how-to-hide-content/
 */
export const VisuallyHidden: React.SFC<{}> = ({children}) => {
  return <div className="visually-hidden">{children}</div>;
};
