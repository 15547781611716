/*
  GameCardWidget

  Will handle subscribing to scores API and will push a timestamp of each re-render
  to its children
*/
import React from 'react';
import {bindActionCreators, connect} from 'fiba/common/utils/reactUtils';
import {GameCardHorizontalComposer} from 'fiba/wt/ui/gameCardWidget/GameCardHorizontalComposer';
import {
  scoresClientConnect,
  scoresClientDisconnect,
} from 'fiba/common/services/scoresClientV2Middleware';
import gameActions from 'fiba/wt/stores/gameStore';
import {eventTeamLinkProvider, gameLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {getCategoriesByEvent} from 'fiba/common/stores/categoryStore';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {List} from 'immutable';

const POLL_BASELINE_INTERVAL_MS = 2 * 60 * 1000;
const ENABLE_SCORES_CLIENT = true;

interface OwnProps {
  season: string;
  eventId: string;
  layout?: keyof typeof gameCardComposers;
}

interface ReduxProps {
  eventTypeData: WebData<EventType>;
  showCategory: boolean;
}

interface DispatchProps {
  scoresClientConnect: typeof scoresClientConnect;
  scoresClientDisconnect: typeof scoresClientDisconnect;
  loadGamesSummaryByEvent: typeof gameActions.loadGamesSummaryByEvent;
}

type Props = OwnProps & ReduxProps & DispatchProps;

const mapDispatchToProps = (dispatch): DispatchProps =>
  bindActionCreators(
    {
      scoresClientConnect,
      scoresClientDisconnect,
      loadGamesSummaryByEvent: gameActions.loadGamesSummaryByEvent,
    },
    dispatch,
  );

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => {
  const eventTypeData = getEventType(state.get('events'), eventId);
  const eventCategories = getCategoriesByEvent(state.get('categories', List()), eventId);
  const isMultiCategory = eventCategories.size > 1;

  return {
    eventTypeData,
    showCategory: isMultiCategory,
  };
};

const gameCardComposers = {
  horizontal: GameCardHorizontalComposer,
};

class GameCardWidgetView extends React.Component<Props> {
  intervalID: any;
  now: number;

  onInterval() {
    const {eventId, loadGamesSummaryByEvent} = this.props;

    loadGamesSummaryByEvent(eventId);
    this.now = Date.now();
  }

  componentDidMount() {
    const {scoresClientConnect, eventId} = this.props;

    if (ENABLE_SCORES_CLIENT) {
      scoresClientConnect(eventId);
    }

    this.intervalID = setInterval(() => this.onInterval(), POLL_BASELINE_INTERVAL_MS);
  }

  componentWillUnmount() {
    const {scoresClientDisconnect, eventId} = this.props;

    if (ENABLE_SCORES_CLIENT) {
      scoresClientDisconnect(eventId);
    }

    if (this.intervalID) {
      clearInterval(this.intervalID);
      this.intervalID = null;
    }
  }

  render() {
    const {season, eventId, eventTypeData, layout = 'horizontal', showCategory} = this.props;
    const {now} = this;

    const Composer = gameCardComposers[layout];

    return (
      <SiteConfigContext.Consumer>
        {({seasonConfig}) => (
          <ViewWebData data={eventTypeData}>
            {eventType => (
              <Composer
                eventId={eventId}
                now={now}
                createTeamHref={eventTeamLinkProvider(seasonConfig, season, eventId, eventType)}
                createGameHref={gameLinkProvider(seasonConfig, season, eventId, eventType)}
                showCategory={showCategory}
              />
            )}
          </ViewWebData>
        )}
      </SiteConfigContext.Consumer>
    );
  }
}

export const GameCardWidget = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(GameCardWidgetView);
