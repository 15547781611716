import React, {useContext} from 'react';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';
import {EventPageRootProps} from 'fiba/wt/ui/eventPage/EventPageCommon';
import {EventPageDefault} from 'fiba/wt/ui/eventPage/EventPageDefault';
import {EventPagePre} from 'fiba/wt/ui/eventPage/EventPagePre';
import {EventPageLive} from 'fiba/wt/ui/eventPage/EventPageLive';
import {EventPagePost} from 'fiba/wt/ui/eventPage/EventPagePost';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

export const EventPage: React.FC<EventPageRootProps> = props => {
  const siteConfig = useContext(SiteConfigContext);
  return (
    <div className="EventPage">
      <EventProgrammability
        {...props}
        renderDefault={() => <EventPageDefault {...props} siteConfig={siteConfig} />}
        renderPre={() => <EventPagePre {...props} siteConfig={siteConfig} />}
        renderLive={() => <EventPageLive {...props} siteConfig={siteConfig} />}
        renderPost={() => <EventPagePost {...props} siteConfig={siteConfig} />}
      />
    </div>
  );
};
