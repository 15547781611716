import {fromJS, List} from 'immutable';
import {
  createActions,
  createReducer,
  setMeta,
  rethrow,
  stripNamespace,
} from 'fiba/common/stores/storeUtils';
import notificationActions from 'fiba/common/stores/notificationStore';
import {Map} from 'immutable';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {convertTeamLikeMapToTeam, isTeam, removePlayerFromTeam} from 'fiba/common/core/team';
import Player from 'fiba/common/core/models/api/players/Player';

export interface TeamStoreState extends Map<string, any> {
  // TODO: `__meta`
}

export const ACTION_PATH = stripNamespace(__filename); // A cheap way to help validate action types

export const storePath = 'teams';

const actions = createActions(__filename, {
  loadTeam: teamId => ({apiClient}) => [
    teamId,
    apiClient
      .loadTeam(teamId)
      .then(actions.loadTeamSuccess)
      .catch(rethrow(err => actions.loadTeamError(teamId, err))),
  ],

  loadTeamSuccess: team => team,

  loadTeamError: (teamId, error) => [
    {teamId, error},
    notificationActions.addNotificationFromError(error),
  ],

  loadTeamPayment: teamId => ({apiClient}) => [
    teamId,
    apiClient
      .loadTeamPayment(teamId)
      .then(actions.loadTeamPaymentSuccess)
      .catch(rethrow(err => actions.loadTeamPaymentError(teamId, err))),
  ],

  loadTeamPaymentSuccess: payment => payment,

  loadTeamPaymentError: (teamId, error) => ({teamId, error}),

  loadTeamsByEvent: eventId => ({apiClient}) => [
    eventId,
    apiClient
      .loadTeamsByEvent(eventId)
      .then(data => {
        return actions.loadTeamsByEventSuccess(eventId, data.get('teams'));
      })
      .catch(rethrow(error => actions.loadTeamsByEventError(eventId, error))),
  ],

  loadTeamsByEventSuccess: (eventId, teams) => ({eventId, teams}),

  loadTeamsByUserEventSuccess: teams => teams,

  loadTeamsByEventError: (eventId, error) => [
    {eventId, error},
    notificationActions.addNotificationFromError(error),
  ],

  loadTeamsByCategory: categoryId => ({apiClient}) => [
    categoryId,
    apiClient
      .loadTeamsByCategory(categoryId)
      .then(teams => actions.loadTeamsByCategorySuccess(categoryId, teams))
      .catch(rethrow(error => actions.loadTeamsByCategoryError(categoryId, error))),
  ],

  loadTeamsByCategorySuccess: (categoryId, teams) => ({categoryId, teams}),

  loadTeamsByCategoryError: (categoryId, error) => [
    {categoryId, error},
    notificationActions.addNotificationFromError(error),
  ],

  deleteTeam: (teamId: string) => teamId,

  // Player removal
  removePlayerFromTeam: (team: Team) => team.id,
  removePlayerFromTeamSuccess: (team: Team, player: Player) => ({team, player}),
  removePlayerFromTeamError: (team: Team) => team.id,
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    loadTeam: (state, teamId) => state.updateIn(['__meta', teamId], setMeta.isLoading),

    loadTeamSuccess: (state: TeamStoreState, teamAsMap: Map<any, any>) => {
      const newTeam = convertTeamLikeMapToTeam(teamAsMap);

      // When querying public endpoints supplying public teams lets merge with potentially existing private teams
      return state
        .update(newTeam.id, (teamFound: Team | undefined) => {
          if (teamFound) {
            return teamFound.mergeWith(
              (oldVal, newVal) => (newVal !== undefined ? newVal : oldVal),
              newTeam,
            );
          }

          return newTeam;
        })
        .updateIn(['__meta', newTeam.id], setMeta.isLoaded);
    },

    loadTeamError: (state, {teamId}) => state.updateIn(['__meta', teamId], setMeta.isError),

    loadTeamPayment: (state, teamId) =>
      state.updateIn(['__meta', 'payment', teamId], setMeta.isLoading),

    loadTeamPaymentSuccess: (state, payment) =>
      state
        .setIn(['payment', payment.get('teamId')], payment)
        .updateIn(['__meta', 'payment', payment.get('teamId')], setMeta.isLoaded),

    loadTeamPaymentError: (state, {teamId}) =>
      state.updateIn(['__meta', 'payment', teamId], setMeta.isError),

    loadTeamsByEventSuccess: (state: TeamStoreState, {teams}) =>
      teams.reduce(reducer.loadTeamSuccess, state),

    loadTeamsByCategorySuccess: (state: TeamStoreState, {teams}) =>
      teams.reduce(reducer.loadTeamSuccess, state),

    loadTeamsByUserEventSuccess: (state: TeamStoreState, teams) =>
      teams.reduce(reducer.loadTeamSuccess, state),

    deleteTeam: (state: TeamStoreState, teamId: string) =>
      state.delete(teamId).deleteIn(['__meta', teamId]).deleteIn(['payment', teamId]),

    // Player removal
    removePlayerFromTeam: (state: TeamStoreState, teamId: string) =>
      state.updateIn(['__meta', teamId], setMeta.isLoading),

    removePlayerFromTeamSuccess: (state: TeamStoreState, {team, player}) =>
      state
        .update(team.id, (team: Team) => removePlayerFromTeam(team, player))
        .updateIn(['__meta', team.id], setMeta.isLoaded),

    removePlayerFromTeamError: (state: TeamStoreState, teamId: string) =>
      state.updateIn(['__meta', teamId], setMeta.isError),
  },
  fromJS({}),
);

//
// Selectors
export const getTeamStore = (state: Map<any, any>) => state.get(storePath, Map()) as TeamStoreState;

export const getTeamsByEvent = (state: Map<any, any>, eventId: string): List<Team> => {
  return getTeams(state)
    .filter(team => team.eventId === eventId)
    .toList();
};

export const getTeamsByCategory = (state: Map<any, any>, categoryId: string): List<Team> => {
  return getTeams(state)
    .filter(team => team.categoryId === categoryId)
    .toList();
};

export const getTeamPayment = (state: Map<any, any>, teamId: string): Map<any, any> =>
  state.getIn([storePath, 'payment', teamId]);

export const getTeam = (state: Map<any, any>, teamId: string): Team | undefined =>
  getTeamStore(state).get(teamId);

export const getTeamMeta = (state: Map<any, any>, teamId: string): Map<any, any> =>
  state.getIn([storePath, '__meta', teamId], Map<any, any>());

export const getPaymentMeta = (state: Map<any, any>, teamId: string): Map<any, any> =>
  state.getIn([storePath, '__meta', 'payment', teamId], Map<any, any>());

export const getTeams = (state: Map<any, any>): List<Team> =>
  getTeamStore(state).filter(isTeam).toList();

export function deserializeState(state: any) {
  const {__meta, payment, ...teams} = state;

  const convertedTeams = Object.keys(teams).reduce((accum, key) => {
    accum[key] = Team.fromJS(teams[key]);
    return accum;
  }, {});

  return fromJS({
    __meta: __meta || {},
    payment: payment || {},
    ...convertedTeams,
  });
}
