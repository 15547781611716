import {List, Map} from 'immutable';
import moment from 'moment';
import {ResultsGameSummary} from 'fiba/common/core/models/api/results/ResultsGameSummary';
import {ResultsGameDetails} from 'fiba/common/core/models/api/results/ResultsGameDetails';
import {GameState} from 'fiba/common/services/scoresClientV2Middleware';
import {ScoresClientOngoingGame} from 'fiba/common/core/models/scores/ScoresClientOngoingGame';
import {ResourceLinkProvider} from 'fiba/wt/utils/linkUtils';

// Common interface for horizontal and vertical game card strips
export interface IGameCardComposer {
  eventId: string;
  now: number;
  createTeamHref: ResourceLinkProvider;
  createGameHref: ResourceLinkProvider;
  showCategory: boolean;
}

export const isFinal = (game: ResultsGameDetails) =>
  typeof game.homeTeam.teamIsWinner !== 'undefined' || game.currentGameState === GameState.FINISHED;

export const isLive = (game: ResultsGameDetails) => !!game.currentGameState && !isFinal(game);

export const isOvertime = (game: ResultsGameDetails) =>
  game.currentGameState === GameState.OVERTIME;

export const isFuture = (game: ResultsGameDetails, now: number) => {
  if (isFinal(game) || isLive(game)) {
    return false;
  }

  //show difference of starting vs now in utc format
  return moment.utc(game.gameStartDatetime).isAfter(now);
};

const isPoolGame = (game: ResultsGameDetails) => game.roundCode === 'RR';

const mapRoundCode = (roundCode: string) => (roundCode === 'L8' ? 'QF' : roundCode);

const getCategoryPrefix = (category: string) => {
  if (category.toUpperCase() === 'MEN') {
    return 'M - ';
  } else if (category.toUpperCase() === 'WOMEN') {
    return 'W - ';
  } else {
    return '';
  }
};

// WT doesn't have the category visible, but NT events do.
// @see fiba/embed/ui/liveScoresEmbedPage/LiveScoresGameCard#getGroupName
// This is simplification of that function (as currently all functions here are)
export const getGroupName = (game: ResultsGameDetails, showCategory: boolean) => {
  const categoryPrefix = showCategory ? getCategoryPrefix(game.categoryName) : '';

  return isPoolGame(game)
    ? `${categoryPrefix}${game.groupName}`
    : `${categoryPrefix}${mapRoundCode(game.roundCode)} ${game.groupPoolCode || ''}`;
};

export function mergeResultsWithScores(
  games: List<ResultsGameSummary>,
  ongoingGames: Map<string, ScoresClientOngoingGame>,
): List<ResultsGameSummary> {
  return ongoingGames.reduce((games, ongoingGame, ongoingGameId) => {
    const gameIndex = games.findIndex(game => game.gameId === ongoingGameId);
    if (gameIndex === -1) {
      return games;
    } else {
      return games
        .setIn([gameIndex, 'remainingGameTime'], ongoingGame.remainingGameTime)
        .setIn([gameIndex, 'currentGameState'], ongoingGame.currentGameState)
        .setIn([gameIndex, 'homeTeam', 'teamScore'], ongoingGame.homeTeamScore)
        .setIn([gameIndex, 'awayTeam', 'teamScore'], ongoingGame.awayTeamScore);
    }
  }, games);
}

export function findInitialGameIndex(games: List<ResultsGameSummary>, now: number): number {
  // First search for game that is now live
  const firstLiveIndex = games.findIndex(game => isLive(game));
  if (firstLiveIndex >= 0) {
    return firstLiveIndex;
  }

  // Now try to search for a game that isn't finished yet
  const firstNonFinalIndex = games.findIndex(game => !isFinal(game));
  if (firstNonFinalIndex >= 0) {
    return firstNonFinalIndex;
  }

  // All games are finished, tag the last game
  return games.size - 1;
}
