import React, {useContext} from 'react';

/** Context that tracks the document heading level */
export const LevelContext = React.createContext({level: 1});

/** Context consumer and provider that increments the heading level
 * for the sub-tree below it.
 */
export const Section: React.SFC<{}> = ({children}) => {
  const {level} = useContext(LevelContext);
  return <LevelContext.Provider value={{level: level + 1}}>{children}</LevelContext.Provider>;
};
