/*
  Tour programmability state is derived from the event programmability states of the tour's events.
  This shows how they relate to the tour programmability state, @see EventProgrammability for definitions of event programmability states.
  !! Only addition to event states on the tour level is that `post` state of event will end on the event's
  `unpublishDatetime` not continuing ever after unlike on event level !!

  Here's an explanation of the possible states a tour can be in:

  - `none`: No events are configured for the event or events are misconfigured so that no event state can be deduced
  - `before-season`: Before any event has entered `pre` state
  - `between-events`: After the first event and before the last event, but no event is in `pre`, `live` or `post`
  - `after-season`: After all events have exited the `post` state
  - `event-in-pre`: An event of the tour is in `pre` state
  - `event-in-live`: An event of the tour is in `live` state
  - `event-in-post`: An event of the tour is in `post` state, here we take into account the `unpublishDatetime`
     which marks the end of the `post` and `event-in-post` states as far as tour state is concerned

  Here is some ASCII art to better illustrate the state (consider `none` to be special case and thus is
  not included in the graph):

      v
      v
      v
      |  state = 'before-season'
      |
 *--->+- publishDatetime of event begins
 |    |
 |    |  state = 'event-in-pre'
 |    |
 |    +- startDatetime of event begins
 |    |
 |    |  state = 'event-in-live'
 |    |
 |    +- endDatetime of event begins
 |    |
 |    |  state = 'event-in-post'
 |    |
 |    +- unpublishDatetime of event begins
 |    |
 |    |  state = 'between-events'
 |    |
 *----+ LOOP BACK UNTIL FINAL EVENT
      |
      +- unpublishDatetime of last event begins
      |
      |  state = 'after-season
      v
      v
      v
*/
import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {ContentTypes, getEventConfigurationForTour} from 'fiba/wt/stores/contentStore';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {TourProgrammabilityStateProvider} from 'fiba/wt/ui/tourProgrammability/TourProgrammabilityStateProvider';

interface Props {
  tourId: string;
  season: string;
  renderDefault: () => React.ReactElement<any>;
  renderBeforeSeason: () => React.ReactElement<any>;
  renderAfterSeason: () => React.ReactElement<any>;
  renderBetweenEvents: () => React.ReactElement<any>;
  renderPreEvent: (eventPub: EventConfiguration) => React.ReactElement<any>;
  renderLiveEvent: (eventPub: EventConfiguration) => React.ReactElement<any>;
  renderPostEvent: (eventPub: EventConfiguration) => React.ReactElement<any>;
}

interface ReduxProps {
  metaPaths: string[];
  eventConfiguration?: EventConfiguration[];
  overrideNow?: string;
}

const mapStateToProps = (state, {tourId}: Props): ReduxProps => ({
  eventConfiguration: getEventConfigurationForTour(state, tourId),
  metaPaths: [`content/__meta/${ContentTypes.EventConfigurator}/tourEvents/${tourId}`],
  // Yay, more internal secret query params \_(-_-)_/
  overrideNow: state.getIn(['route', 'payload', '_now']),
});

const TourProgrammabilityImpl: React.FC<Props & ReduxProps> = ({
  renderDefault,
  renderBeforeSeason,
  renderAfterSeason,
  renderBetweenEvents,
  renderPreEvent,
  renderLiveEvent,
  renderPostEvent,
  eventConfiguration,
  metaPaths,
  overrideNow,
}) => (
  <Loading metaPaths={metaPaths}>
    {() => (
      <TourProgrammabilityStateProvider
        eventConfiguration={eventConfiguration}
        overrideNow={overrideNow}
      >
        {({state, eventConfiguration: eventConfiguration}) => {
          switch (state) {
            case 'before-season':
              return renderBeforeSeason();
            case 'after-season':
              return renderAfterSeason();
            case 'between-events':
              return renderBetweenEvents();
            case 'event-in-pre':
              return renderPreEvent(eventConfiguration);
            case 'event-in-live':
              return renderLiveEvent(eventConfiguration);
            case 'event-in-post':
              return renderPostEvent(eventConfiguration);
            case 'none':
              return renderDefault();
          }
        }}
      </TourProgrammabilityStateProvider>
    )}
  </Loading>
);

export const TourProgrammability = connect<ReduxProps, {}, Props>(mapStateToProps)(
  TourProgrammabilityImpl,
);
