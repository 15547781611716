import React from 'react';
import {LocalizerService} from 'fiba/common/services/localizerService';
import {StoreService} from 'fiba/common/services/storeService';

export {LocalizerService};

export interface UIServices {
  localizer: LocalizerService;
  store: StoreService<any>;
}

export const ServicesContext = React.createContext<UIServices>({
  localizer: null,
  store: null,
});
