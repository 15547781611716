import moment from 'moment';
import {
  EventColorTheme,
  EventProgrammabilityState,
  EventConfiguration,
  getEventProgrammabilityState,
  ImageAlignments,
} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {maxBy, minBy} from 'lodash';

interface IActiveEvent {
  state: EventProgrammabilityState;
  configuration: EventConfiguration;
}

const eventStatePriorities = ['live', 'pre', 'post', 'none'];
const eventStateDatetimeKeys = {
  pre: 'startDatetime',
  live: 'startDatetime',
  post: 'endDatetime',
};

export function findActiveEvent(
  eventConfiguration: EventConfiguration[],
  eventIds: string[],
  now: moment.Moment,
): IActiveEvent | undefined {
  // Warning: complex looking logic follows

  return eventConfiguration.reduce(
    (bestCandidate: IActiveEvent, configuration: EventConfiguration): IActiveEvent | null => {
      const state = getEventProgrammabilityState(configuration, now);
      const eventId = getEventIdFromConfiguration(configuration);

      if (state === 'none' || !eventIds.includes(eventId)) {
        // We don't care about 'none' state at this point
        // We also don't care about events which are not in the state (i.e. not found in EM)
        // because sometimes there are lingering configurations for canceled events

        return bestCandidate;
      } else if (!bestCandidate) {
        // No candidate exists yet, so figure out if this event should be taken into account

        // Since post state lasts forever for event, but a specific time for tour we need special handling for it
        if (state === 'post') {
          if (now.isBefore(configuration.unpublishDatetime)) {
            // Unpublish is not in effect yet, ok to take into account
            return {configuration, state};
          }
        } else {
          // State should thus be pre, live or bound post, ok to take into account
          return {configuration, state};
        }
      } else {
        // Special case:
        // if bestCandidate's state is pre but
        // our current event is in "post-but-not-unpublished", use current event
        if (
          bestCandidate.state === 'pre' &&
          state === 'post' &&
          now.isBefore(configuration.unpublishDatetime)
        ) {
          return {configuration, state};
        }

        // Special case:
        // If current state is pre but
        // our bestCandidate is in "post-but-not-unpublished", use bestCandidate
        if (
          state === 'pre' &&
          bestCandidate.state === 'post' &&
          now.isBefore(bestCandidate.configuration.unpublishDatetime)
        ) {
          return bestCandidate;
        }

        // Time to compare against the current candidate
        const priority = eventStatePriorities.indexOf(state);
        const bestPriority = eventStatePriorities.indexOf(bestCandidate.state);

        if (priority < bestPriority) {
          // ^^ .indexOf inverts priority here, less is more
          return {configuration, state};
        } else if (priority === bestPriority) {
          const datetime = moment.utc(configuration[eventStateDatetimeKeys[state]]);
          const bestDatetime = bestCandidate.configuration[eventStateDatetimeKeys[state]];

          if (state === 'pre') {
            // For multiple events in pre state,
            // use the one which is earliest
            if (datetime.isBefore(bestDatetime)) {
              return {configuration, state};
            }
          } else {
            // For other states, use the latest
            if (datetime.isAfter(bestDatetime)) {
              return {configuration, state};
            }
          }
        }
      }

      return bestCandidate;
    },
    null,
  );
}

export function isBeforeSeason(eventConfiguration: EventConfiguration[], now: moment.Moment) {
  return eventConfiguration.every(config => now.isBefore(config.publishDatetime));
}

export function isAfterSeason(eventConfiguration: EventConfiguration[], now: moment.Moment) {
  return eventConfiguration.every(config => now.isSameOrAfter(config.unpublishDatetime));
}

export function isInSeason(eventConfiguration: EventConfiguration[], now: moment.Moment) {
  const firstPublishDate = minBy(
    eventConfiguration
      .map(config => {
        const date = config.publishDatetime;
        return date && moment.utc(date);
      })
      .filter(date => date && date.isValid()),
    (a, b) => a.diff(b),
  );

  const lastUnpublishDate = maxBy(
    eventConfiguration
      .map(config => {
        const date = config.unpublishDatetime;
        return date && moment.utc(date);
      })
      .filter(date => date && date.isValid()),
    (a, b) => a.diff(b),
  );

  return now.isSameOrAfter(firstPublishDate) && now.isBefore(lastUnpublishDate);
}

export function getEventIdFromConfiguration(
  eventConfiguration: EventConfiguration = {
    path: '',
    colorTheme: EventColorTheme.light,
    headerBackgroundImageAlign: ImageAlignments.center,
  },
) {
  // Path to event looks like `tour/$tourId/event/$eventId`
  const {path} = eventConfiguration;
  const m = /^(tour|season)\/[^\/]+\/event\/([^\/]+)/.exec(path);

  if (!m) {
    return undefined;
  }

  // Pluck the eventId from the regex result
  return m[2];
}
// Check for older seasons which have reduced layout
export const isLegacySeason = (season: string): boolean => {
  switch (season) {
    case '2014':
    case '2013':
    case '2012':
      return true;
    default:
      return false;
  }
};

export const getTourStatsVisibility = (siteConfig: ISiteConfig, season: string) => {
  const {showStatsForSeason} = siteConfig?.seasonConfig?.seasons[season] ?? true;

  return showStatsForSeason;
};
