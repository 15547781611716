import React from 'react';
import {StyleProp} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {LazyIframe} from 'fiba/wt/ui/lazyIframe/LazyIframe';

interface YoutubeEmbedProps {
  url: string;
  width: string;
  height: string;
  aspectRatio: StyleProp<'aspectRatio'>;
  delayShouldRenderCheck?: boolean;
}

function getEmbedUrl(url: string): string {
  const parsed = new URL(url);

  // Check for `https://youtu.be/VIDEO_ID
  if (parsed.hostname === 'youtu.be') {
    return `https://www.youtube-nocookie.com/embed/${parsed.pathname}`;
  }

  if (parsed.hostname === 'youtube.com' || parsed.hostname === 'www.youtube.com') {
    // Check for already embedded URL `https://youtube.com/embed....`
    if (/^\/embed\/.+/.test(parsed.pathname)) {
      const copy = new URL(url);
      copy.protocol = 'https';
      return copy.toString();
    }

    const searchParams = parsed.searchParams;

    // Check if URL contains a playlist `&list=PLAYLIST_ID`
    if (!!searchParams.has('list')) {
      return `https://www.youtube-nocookie.com/embed?listType=playlist&list=${searchParams.get(
        'list',
      )}`;
    }

    // Check if URL contains a video `&v=VIDEO_ID
    if (!!searchParams.has('v')) {
      return `https://www.youtube-nocookie.com/embed/${searchParams.get('v')}`;
    }
  }
}

export const YoutubeEmbed: React.FunctionComponent<YoutubeEmbedProps> = ({
  url,
  width,
  height,
  aspectRatio,
  delayShouldRenderCheck,
}) => (
  <div className="YoutubeEmbed">
    <LazyIframe
      aspectRatio={aspectRatio}
      width={width}
      height={height}
      src={getEmbedUrl(url)}
      frameBorder="0"
      allowFullScreen
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      delayShouldRenderCheck={!!delayShouldRenderCheck}
    />
  </div>
);
