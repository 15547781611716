/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultPlayerStatsDoubleFives = {points: null, highlights: null, rebounds: null};
export const defaultPlayerStatsTripleFives = {points: null, highlights: null, rebounds: null};
export const defaultPlayerStats = {
  playerId: null,
  totalPointsMade: null,
  onePointsAttempted: null,
  onePointsMade: null,
  onePointsMissed: null,
  onePointsPercentage: null,
  twoPointsAttempted: null,
  twoPointsMade: null,
  twoPointsMissed: null,
  twoPointsPercentage: null,
  freeThrowsAttempted: null,
  freeThrowsMade: null,
  freeThrowsMissed: null,
  freeThrowsPercentage: null,
  shootingEfficiency: null,
  shootingValue: null,
  shootingValuePerGame: null,
  highlights: null,
  dunks: null,
  keyAssists: null,
  buzzerBeaters: null,
  drives: null,
  turnovers: null,
  blockedShots: null,
  totalRebounds: null,
  offensiveRebounds: null,
  defensiveRebounds: null,
  attemptsTotal: null,
  extraFreeThrows: null,
  eventsPlayed: null,
  gamesPlayed: null,
  pointsPerGame: null,
  highlightsPerGame: null,
  reboundsPerGame: null,
  turnoversPerGame: null,
  playerDoubleFives: null,
  playerTripleFives: null,
  playerDoubleFivesCount: null,
  playerTripleFivesCount: null,
  playerValue: null,
  playerValuePerGame: null,
  mainDrawsPlayed: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type PlayerStatsDoubleFivesLike = {
  [K in keyof typeof defaultPlayerStatsDoubleFives]?: PlayerStatsDoubleFives[K];
};
export type PlayerStatsTripleFivesLike = {
  [K in keyof typeof defaultPlayerStatsTripleFives]?: PlayerStatsTripleFives[K];
};
export type PlayerStatsLike = {
  [K in keyof typeof defaultPlayerStats]?: PlayerStats[K];
};

export class PlayerStatsDoubleFives extends Record(defaultPlayerStatsDoubleFives) {
  points?: number;
  highlights?: number;
  rebounds?: number;
  static fromJS(o: PlayerStatsDoubleFivesLike): PlayerStatsDoubleFives {
    return (
      o &&
      new PlayerStatsDoubleFives({points: o.points, highlights: o.highlights, rebounds: o.rebounds})
    );
  }
}
export class PlayerStatsTripleFives extends Record(defaultPlayerStatsTripleFives) {
  points?: number;
  highlights?: number;
  rebounds?: number;
  static fromJS(o: PlayerStatsTripleFivesLike): PlayerStatsTripleFives {
    return (
      o &&
      new PlayerStatsTripleFives({points: o.points, highlights: o.highlights, rebounds: o.rebounds})
    );
  }
}
export class PlayerStats extends Record(defaultPlayerStats) {
  playerId?: string;
  totalPointsMade?: number;
  onePointsAttempted?: number;
  onePointsMade?: number;
  onePointsMissed?: number;
  onePointsPercentage?: number;
  twoPointsAttempted?: number;
  twoPointsMade?: number;
  twoPointsMissed?: number;
  twoPointsPercentage?: number;
  freeThrowsAttempted?: number;
  freeThrowsMade?: number;
  freeThrowsMissed?: number;
  freeThrowsPercentage?: number;
  shootingEfficiency?: number;
  shootingValue?: number;
  shootingValuePerGame?: number;
  highlights?: number;
  dunks?: number;
  keyAssists?: number;
  buzzerBeaters?: number;
  drives?: number;
  turnovers?: number;
  blockedShots?: number;
  totalRebounds?: number;
  offensiveRebounds?: number;
  defensiveRebounds?: number;
  attemptsTotal?: number;
  extraFreeThrows?: number;
  eventsPlayed?: number;
  gamesPlayed?: number;
  pointsPerGame?: number;
  highlightsPerGame?: number;
  reboundsPerGame?: number;
  turnoversPerGame?: number;
  playerDoubleFives?: List<PlayerStatsDoubleFives>;
  playerTripleFives?: List<PlayerStatsTripleFives>;
  playerDoubleFivesCount?: number;
  playerTripleFivesCount?: number;
  playerValue?: number;
  playerValuePerGame?: number;
  mainDrawsPlayed?: number;
  static fromJS(o: PlayerStatsLike): PlayerStats {
    return (
      o &&
      new PlayerStats({
        playerId: o.playerId,
        totalPointsMade: o.totalPointsMade,
        onePointsAttempted: o.onePointsAttempted,
        onePointsMade: o.onePointsMade,
        onePointsMissed: o.onePointsMissed,
        onePointsPercentage: o.onePointsPercentage,
        twoPointsAttempted: o.twoPointsAttempted,
        twoPointsMade: o.twoPointsMade,
        twoPointsMissed: o.twoPointsMissed,
        twoPointsPercentage: o.twoPointsPercentage,
        freeThrowsAttempted: o.freeThrowsAttempted,
        freeThrowsMade: o.freeThrowsMade,
        freeThrowsMissed: o.freeThrowsMissed,
        freeThrowsPercentage: o.freeThrowsPercentage,
        shootingEfficiency: o.shootingEfficiency,
        shootingValue: o.shootingValue,
        shootingValuePerGame: o.shootingValuePerGame,
        highlights: o.highlights,
        dunks: o.dunks,
        keyAssists: o.keyAssists,
        buzzerBeaters: o.buzzerBeaters,
        drives: o.drives,
        turnovers: o.turnovers,
        blockedShots: o.blockedShots,
        totalRebounds: o.totalRebounds,
        offensiveRebounds: o.offensiveRebounds,
        defensiveRebounds: o.defensiveRebounds,
        attemptsTotal: o.attemptsTotal,
        extraFreeThrows: o.extraFreeThrows,
        eventsPlayed: o.eventsPlayed,
        gamesPlayed: o.gamesPlayed,
        pointsPerGame: o.pointsPerGame,
        highlightsPerGame: o.highlightsPerGame,
        reboundsPerGame: o.reboundsPerGame,
        turnoversPerGame: o.turnoversPerGame,
        playerDoubleFives:
          o.playerDoubleFives &&
          List(o.playerDoubleFives).map(i => i && PlayerStatsDoubleFives.fromJS(i)),
        playerTripleFives:
          o.playerTripleFives &&
          List(o.playerTripleFives).map(i => i && PlayerStatsTripleFives.fromJS(i)),
        playerDoubleFivesCount: o.playerDoubleFivesCount,
        playerTripleFivesCount: o.playerTripleFivesCount,
        playerValue: o.playerValue,
        playerValuePerGame: o.playerValuePerGame,
        mainDrawsPlayed: o.mainDrawsPlayed,
      })
    );
  }
}

export default PlayerStats;
