import React, {useContext} from 'react';
import {List} from 'immutable';
import {FieldSort, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {TeamStatRow} from 'fiba/wt/stores/teamStatsStore';
import {Legend} from 'fiba/wt/ui/legend/Legend';
import {Box} from 'fiba/wt/ui/box/Box';
import {decimalColumn, integerColumn} from 'fiba/wt/ui/dataTable/DataTableHelpers';
import {SubHeading} from 'fiba/wt/ui/heading/Heading';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {getLocalTimeFromString, getLocalDateFromString} from 'fiba/common/utils/schedule';
import {ServicesContext} from 'fiba/common/services/services';
import {Th} from 'fiba/wt/ui/table/Table';
import {FilteredDataTable} from 'fiba/wt/ui/filteredDataTable/FilteredDataTable';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';

type TableColumns =
  | 'teamName'
  | 'eventsPlayed'
  | 'gamesPlayed'
  | 'mainDrawsPlayed'
  | 'totalGamesWon'
  | 'totalGamesLost'
  | 'totalWinAverage'
  | 'totalPointsMade'
  | 'pointsPerGame'
  | 'winsBeforeLimit'
  | 'shootingEfficiency'
  | 'shootingValue'
  | 'shootingValuePerGame'
  | 'highlights'
  | 'highlightsPerGame'
  | 'onePointsMade'
  | 'onePointsAttempted'
  | 'onePointsPercentage'
  | 'twoPointsMade'
  | 'twoPointsAttempted'
  | 'twoPointsPercentage'
  | 'freeThrowsMade'
  | 'freeThrowsAttempted'
  | 'freeThrowsPercentage'
  | 'keyAssists'
  | 'drives'
  | 'dunks'
  | 'blockedShots'
  | 'buzzerBeaters'
  | 'totalRebounds'
  | 'reboundsPerGame'
  | 'offensiveRebounds'
  | 'defensiveRebounds'
  | 'turnovers'
  | 'turnoversPerGame'
  | 'teamPointsAgainstPerGame'
  | 'teamFouls'
  | 'teamFoulsPerGame'
  | 'teamFoulsAgainst'
  | 'teamFoulsAgainstPerGame'
  | 'possessionsPerGame'
  | 'twoPointShotShare';

type ColumnSpec = IColumnSpec<TeamStatRow>;
type ColumnSpecRecord = Record<TableColumns, ColumnSpec>;

// For a team link, we provide a more advanced callback,
// that gets the team row itself, and decides what to display.
// This might seem odd, but we need this for different levels:
//  Tour Level WT Team: Show link
//  Event Level Team: Show link
//  Tour Level, non-WT Team: Hide link / show name only
// These depend both on the level, and the specific team.
// Customising all that deep in the component tree (i.e. here) seemed
// too brittle.
export type RenderTeamName = (team: TeamStatRow) => React.ReactNode;

const createColumnsSpec = (renderTeamName: RenderTeamName): ColumnSpecRecord => {
  return {
    teamName: {
      name: 'Team',
      dataType: 'text',
      // We render the row index, to keep a visible indication of
      // order, regardless of what value we sort by
      renderColumn: ({rowData, rowIndex, getTdProps}) => (
        <Th {...getTdProps({})} scope="row">
          {rowIndex + 1}.&nbsp;
          {renderTeamName(rowData)}
        </Th>
      ),
    },
    eventsPlayed: integerColumn('EP', 'Events played'),
    mainDrawsPlayed: integerColumn('MD', 'Maindraws played'),
    gamesPlayed: integerColumn('GP', 'Games played'),
    totalPointsMade: integerColumn('PTS', 'Points scored'),
    totalGamesWon: integerColumn('W', 'Wins'),
    totalGamesLost: integerColumn('L', 'Losses'),
    totalWinAverage: {
      name: 'W%',
      dataType: 'number',
      renderColumn: ({rowData, Td, getTdProps}) => (
        <Td {...getTdProps({})}>{Math.round(rowData.totalWinAverage * 100)}%</Td>
      ),
    },
    pointsPerGame: decimalColumn('PPG', 'Points per game', 1),
    shootingEfficiency: decimalColumn('S-EFF', 'Shooting efficiency', 2),
    shootingValue: decimalColumn('S-VAL', 'Shooting value', 1),
    shootingValuePerGame: decimalColumn('S-VALPG', 'Shooting value per game', 1),
    winsBeforeLimit: integerColumn('WBL', 'Wins before limit'),
    highlights: integerColumn('HGL', 'Highlights'),
    highlightsPerGame: decimalColumn('HGLPG', 'Highlights per game', 1),
    onePointsMade: integerColumn('1PTM', 'One point shots made'),
    onePointsAttempted: integerColumn('1PTA', 'One point shot attempts'),
    onePointsPercentage: decimalColumn('1PT%', 'One point shot percentage', 0, true),
    twoPointsMade: integerColumn('2PTM', 'Two point shots made'),
    twoPointsAttempted: integerColumn('2PTA', 'Two point shot attempts'),
    twoPointsPercentage: decimalColumn('2PT%', 'Two point shot percentage', 0, true),
    freeThrowsMade: integerColumn('FTM', 'Free throws made'),
    freeThrowsAttempted: integerColumn('FTA', 'Free throw attempts'),
    freeThrowsPercentage: decimalColumn('FT%', 'Free throw percentage', 0, true),
    dunks: integerColumn('DNK', 'Dunks'),
    blockedShots: integerColumn('BS', 'Blocked shots'),
    keyAssists: integerColumn('KAS', 'Key assists'),
    buzzerBeaters: integerColumn('BZR', 'Buzzerbeaters'),
    drives: integerColumn('DRV', 'Drives'),
    totalRebounds: integerColumn('REB', 'Rebounds'),
    reboundsPerGame: decimalColumn('REBPG', 'Rebounds per game', 1),
    offensiveRebounds: integerColumn('OREB', 'Offensive rebounds'),
    defensiveRebounds: integerColumn('DREB', 'Defensive rebounds'),
    turnovers: integerColumn('TO', 'Turnovers'),
    turnoversPerGame: decimalColumn('TOPG', 'Turnovers per game', 1),
    teamPointsAgainstPerGame: decimalColumn('PAPG', 'Points allowed per game', 1),
    teamFouls: integerColumn('TF', 'Team fouls'),
    teamFoulsPerGame: decimalColumn('TFPG', 'Team fouls per game', 1),
    teamFoulsAgainst: integerColumn('TFA', 'Team fouls against'),
    teamFoulsAgainstPerGame: decimalColumn('TFAPG', 'Team fouls against per game', 1),
    possessionsPerGame: decimalColumn('POSPG', 'Possessions per game', 1),
    twoPointShotShare: decimalColumn(
      '2PTA/FGA',
      'Two point shot attempts / field goal attempts (1PTA+2PTA)',
      2,
    ),
  };
};

interface OwnProps {
  teamStats: List<TeamStatRow>;
  filteredStats: List<TeamStatRow>;
  sortedBy: FieldSort;
  updatedAt?: string;
  renderTeamName: RenderTeamName;
  captionId: string;
  sortAction: (key, columnsSpec) => any;
  filterAction: (filterBy: FilterOptions) => any;
  showEventsPlayed?: boolean;
}

const TeamStatsTable: React.SFC<OwnProps> = ({
  teamStats,
  filteredStats,
  sortedBy,
  updatedAt,
  renderTeamName,
  sortAction,
  filterAction,
  captionId,
  showEventsPlayed = true,
}) => {
  const displayedStats = filteredStats.isEmpty() ? teamStats : filteredStats;
  const {localizer} = useContext(ServicesContext);
  const columnSpec = createColumnsSpec(renderTeamName);

  const filterByField = 'gamesPlayed';
  const max = teamStats.reduce(
    (acc, team) => (team[filterByField] > acc ? team[filterByField] : acc),
    0,
  );
  return teamStats.isEmpty() ? (
    <span>Stats not available.</span>
  ) : (
    <Spacer vSpace="4">
      <Box>
        <FilteredDataTable<TableColumns, TeamStatRow>
          captionId={captionId}
          skipMaxWidth={true}
          caption={
            <div className="flex flex-wrap justify-between">
              <SubHeading>Team Stats</SubHeading>
              {!!updatedAt ? (
                <div className="f6 fw4">
                  Updated at: {getLocalDateFromString(updatedAt, localizer)},{' '}
                  {getLocalTimeFromString(updatedAt, localizer)} (UTC)
                </div>
              ) : (
                <></>
              )}
            </div>
          }
          columns={[
            'teamName',
            ...((showEventsPlayed ? ['eventsPlayed'] : []) as TableColumns[]), // events played column is hidden on event specific stats page
            'mainDrawsPlayed',
            'gamesPlayed',
            'totalGamesWon',
            'totalGamesLost',
            'totalWinAverage',
            'totalPointsMade',
            'pointsPerGame',
            'winsBeforeLimit',
            'shootingEfficiency',
            'shootingValue',
            'shootingValuePerGame',
            'highlights',
            'highlightsPerGame',
            'onePointsMade',
            'onePointsAttempted',
            'onePointsPercentage',
            'twoPointsMade',
            'twoPointsAttempted',
            'twoPointsPercentage',
            'freeThrowsMade',
            'freeThrowsAttempted',
            'freeThrowsPercentage',
            'keyAssists',
            'drives',
            'dunks',
            'blockedShots',
            'buzzerBeaters',
            'totalRebounds',
            'reboundsPerGame',
            'offensiveRebounds',
            'defensiveRebounds',
            'turnovers',
            'turnoversPerGame',
            'teamPointsAgainstPerGame',
            'teamFouls',
            'teamFoulsPerGame',
            'teamFoulsAgainst',
            'teamFoulsAgainstPerGame',
            'possessionsPerGame',
            'twoPointShotShare',
          ]}
          columnsSpec={columnSpec}
          sortAction={sortAction}
          sortedBy={sortedBy}
          rows={displayedStats}
          headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
          headerExtraClassName="dark-30"
          rowExtraClassName="striped--light-even bb bw1 b--silver-20 striped--fullwhite"
          min={0}
          max={max}
          field={filterByField}
          filterAction={filterAction}
          fieldDisplayName={'Games played'}
          frozenHeaders={true}
        />
      </Box>
      <Legend definition={legendDefinition} />
    </Spacer>
  );
};

const legendDefinition = [
  ['EP', 'Events played'],
  ['MD', 'Maindraws played'],
  ['GP', 'Games played'],
  ['W', 'Wins'],
  ['L', 'Losses'],
  ['W%', 'Win ratio'],
  ['PTS', 'Points scored'],
  ['PPG', 'Points per game'],
  ['WBL', 'Wins before limit'],
  ['S-EFF', 'Shooting efficiency', 'PTS / (1PTA + 2PTA + FTA)'],
  ['S-VAL', 'Shooting value', 'S-EFF * PTS'],
  ['S-VALPG', 'Shooting value per game'],
  ['HGL', 'Highlights', 'KAS + DRV + DNK + BS + BZR'],
  ['HGLPG', 'Highlights per game'],
  ['1PTM', 'One-point field goals made'],
  ['1PTA', 'One-point field goal attempts'],
  ['1PT%', 'One-point field goal percentage'],
  ['2PTM', 'Two-point field goals made'],
  ['2PTA', 'Two-point field goal attempts'],
  ['2PT%', 'Two-point field goal percentage'],
  ['FTM', 'Free throws made'],
  ['FTA', 'Free throw attempts'],
  ['FT%', 'Free throw percentage'],
  ['KAS', 'Key assists'],
  ['DRV', 'Drives'],
  ['DNK', 'Dunks'],
  ['BS', 'Blocked shots'],
  ['BZR', 'Buzzerbeaters'],
  ['REB', 'Rebounds'],
  ['REBPG', 'Rebounds per game'],
  ['OREB', 'Offensive rebounds'],
  ['DREB', 'Defensive rebounds'],
  ['TO', 'Turnovers'],
  ['TOPG', 'Turnovers per game'],
  ['PAPG', 'Points allowed per game'],
  ['TF', 'Team fouls'],
  ['TFPG', 'Team fouls per game'],
  ['TFA', 'Team fouls against'],
  ['TFAPG', 'Team fouls against per game'],
  ['POSPG', 'Possessions per game'],
  ['2PTA/FGA', 'Two-point field goal attempts (2PTA) / field goal attempts (1PTA+2PTA)'],
];

export default TeamStatsTable;
