import commonFeatures from 'fiba/common/config/features';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';

const features: ISiteConfig['features'] = {
  ...commonFeatures,
  showFastestGamesSponsor: true,
  showEventsInNavigation: true,
  showSeasonSelect: true,
  show3x3ChallengersLink: true,
  showTwitterFeed: false,
  siteId: 'worldtour',
  showTourCalendar: true,
};

export default features;
