import React from 'react';
import {List} from 'immutable';
import {NewsItemPreview} from 'fiba/common/core/models/api/feed/NewsItemPreview';
import {NewsListItem} from 'fiba/wt/ui/newsList/NewsListItem';
import cx from 'classnames';

interface INewsList {
  baseUrl: string;
  columnStyle: ColumnStyle;
  news: List<NewsItemPreview>;
}

type ColumnStyle = 'wide' | 'medium' | 'narrow' | 'auto';

export const NewsList: React.FunctionComponent<INewsList> = ({news, columnStyle, baseUrl}) => {
  const sortedNews = news.sort((ev1, ev2) => {
    // https://github.com/futurice/fiba-3x3-play/issues/3081 reported inconsistenicies in the news item photos when the publish date is the same.
    // Force a set order when the publish date is the same (currently it doesn't matter what the order is, as long as it is consistent).
    if (ev1.date !== ev2.date) {
      return new Date(ev1.date) < new Date(ev2.date) ? 1 : -1;
    } else {
      // Just use the uuid as that will always have a set order.
      return ev1.id < ev2.id ? 1 : -1;
    }
  });
  return (
    <div className="pb2 Cluster Cluster--2 content-center NewsList">
      <ul>
        {sortedNews.map((newsItem, i) => (
          <li
            key={`${newsItem.id}-${i}`}
            className={cx({
              'NewsList-Item--auto': columnStyle === 'auto',
              'w-100 w-50-m w-33-l': columnStyle === 'wide',
              'w-100 w-50-l': columnStyle === 'medium',
              'w-100': columnStyle === 'narrow',
              'flex-auto': true,
            })}
          >
            <NewsListItem newsItem={newsItem} baseUrl={baseUrl} />
          </li>
        ))}
      </ul>
    </div>
  );
};
