import React from 'react';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {List} from 'immutable';
import * as ProCircuitTeamStats from 'fiba/wt/stores/proCircuitTeamStatsStore';
import TeamStatsTable from 'fiba/wt/ui/common/statistics/TeamStatsTable';
import {FieldSort} from 'fiba/wt/ui/dataTable/DataTable';
import {tourTeamLinkProvider} from 'fiba/wt/utils/linkUtils';
import {StyledLink} from 'fiba/wt/ui/link/Link';
import {TeamStatRow} from 'fiba/wt/stores/proCircuitTeamStatsStore';
import {FilterOptions} from 'fiba/wt/stores/reducers/sortableStore';
import Team from 'fiba/common/core/models/api/teams/Team';
import {TeamNameAssembly} from 'fiba/wt/ui/teamNameAssembly/TeamNameAssembly';

interface OwnProps {
  season: string;
  tourId: string;
}

interface StateProps {
  teamStats: List<ProCircuitTeamStats.TeamStatRow>;
  filteredStats: List<TeamStatRow>;
  sortedBy: FieldSort;
  updatedAt?: string;
  metaPaths: string[];
}

interface DispatchProps {
  changeSorting: (field: string, columnsSpec) => any;
  changeFiltering: (filterBy: FilterOptions) => any;
}

type Props = OwnProps & StateProps & DispatchProps;

const fieldToList = (data, field) => {
  const fieldValue = data && field(data.toJS());
  return (data && fieldValue && List.of(...fieldValue)) || List();
};

const mapStateToProps = (state, {tourId: tourOrEventId}: OwnProps): StateProps => {
  const loaded = state.getIn(ProCircuitTeamStats.globalStatePath(tourOrEventId));
  const metaPaths = [ProCircuitTeamStats.globalMetaPath(tourOrEventId).join('/')];

  return {
    // TODO: Figure out wth
    teamStats: fieldToList(loaded, js => js.data),
    filteredStats: fieldToList(loaded, js => js.filteredData),
    sortedBy: loaded && loaded.get('sortedBy', undefined),
    updatedAt: loaded && loaded.get('updatedAt'),
    metaPaths,
  };
};

const mapDispatchToProps = (dispatch, {tourId: tourOrEventId}: OwnProps): DispatchProps => ({
  changeSorting: (field: string, columnsSpec) => {
    dispatch(
      ProCircuitTeamStats.actions.sortStats(
        ProCircuitTeamStats.statePath(tourOrEventId),
        field,
        columnsSpec,
      ),
    );
  },
  changeFiltering: (filterBy: FilterOptions) => {
    dispatch(
      ProCircuitTeamStats.actions.filterStats(
        ProCircuitTeamStats.statePath(tourOrEventId),
        filterBy,
      ),
    );
  },
});

const _ProCircuitTourTeamsStatistics: React.FC<Props> = ({
  season,
  tourId,
  teamStats,
  filteredStats,
  sortedBy,
  updatedAt,
  changeSorting,
  changeFiltering,
  metaPaths,
}: Props) => {
  return (
    <div className="ProCircuitTourTeamsStatistics">
      <Loading metaPaths={metaPaths}>
        {() => (
          <div>
            <TeamStatsTable
              renderTeamName={teamStats => {
                const teamLike = Team.fromJS({
                  id: teamStats.teamId,
                  name: teamStats.teamName,
                  nameSuffix: teamStats.teamNameSuffix,
                });
                // In Pro-Circuit level, we only render links to teams that played in WT
                return teamStats.isTourTeam ? (
                  <StyledLink href={tourTeamLinkProvider(season)(teamStats.teamId)}>
                    <TeamNameAssembly team={teamLike} isSuffixVisible isIocVisible={false} />
                  </StyledLink>
                ) : (
                  <TeamNameAssembly team={teamLike} isSuffixVisible isIocVisible={false} />
                );
              }}
              captionId={`${tourId}-pro-circuit-team-stats`}
              teamStats={teamStats}
              sortedBy={sortedBy}
              filterAction={changeFiltering}
              filteredStats={filteredStats}
              updatedAt={updatedAt}
              sortAction={changeSorting}
            />
          </div>
        )}
      </Loading>
    </div>
  );
};

export const ProCircuitTeamStatistics = connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(_ProCircuitTourTeamsStatistics);
