import React, {useContext} from 'react';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {Box} from 'fiba/wt/ui/box/Box';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {CardContainer} from 'fiba/wt/ui/cardContainer/CardContainer';
import {tourStatisticsLinkProvider} from 'fiba/wt/utils/linkUtils';
import {
  TourStatisticsPage,
  TourStatisticsTab,
} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsPage';
import {
  TourProCircuitStatisticsPage,
  TourProCircuitStatisticsTab,
} from 'fiba/wt/ui/tourStatisticsPage/TourProCircuitStatisticsPage';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';

type TourMacroStatisticsTab = 'world-tour' | 'pro-circuit';

export interface ITourMacroStatisticsPage {
  tourId: string;
  season: string;
  eventId: string;
  primaryTab: TourMacroStatisticsTab;
  secondaryTab: TourProCircuitStatisticsTab & TourStatisticsTab;
}

function getTourMacroStatisticsTab({primaryTab, secondaryTab, ...props}: ITourMacroStatisticsPage) {
  switch (primaryTab) {
    case 'world-tour':
      return <TourStatisticsPage primaryTab={secondaryTab} {...props} />;
    case 'pro-circuit':
      return <TourProCircuitStatisticsPage primaryTab={secondaryTab} {...props} />;
    default:
      return null;
  }
}

export const TourMacroStatisticsPage: React.FC<ITourMacroStatisticsPage> = (
  props: ITourMacroStatisticsPage,
) => {
  const headingId = 'tour-stats-heading';
  const {seasonConfig} = useContext(SiteConfigContext);
  const hasOnlyWorldTourStats = !!seasonConfig.seasons[props.season].hasOnlyWorldTourStats
    ? true
    : false;
  return (
    <section>
      <PageHeading containerProps={{border: 'none'}} text="Stats" id={headingId} />

      <Section>
        <CardContainer pa="0" height="100" debugClassName="TourStatisticsPage">
          <Tabs
            level="base"
            navigationLabeledBy={headingId}
            baseUrl={tourStatisticsLinkProvider(props.season)}
            extraClassName="TourStatisticsPage-Tabs"
          >
            <Tab href="/world-tour" hrefAliases={['/']}>
              World Tour
            </Tab>
            {hasOnlyWorldTourStats ? '' : <Tab href="/pro-circuit">Pro Circuit</Tab>}
          </Tabs>
          <Box ph="3" pb="3" pt="4" debugClassName="TourStatisticsPage-Content">
            {getTourMacroStatisticsTab(props)}
          </Box>
        </CardContainer>
      </Section>
    </section>
  );
};
