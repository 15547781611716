import * as Sentry from '@sentry/react';

/**
 * Manually report a crash (to Sentry)
 * Used for cases where top-level catching, or React Error Boundary, do not handle it
 */
export default function reportCrash(handler: string, error: Error) {
  // TODO(fpapado): Verify that Sentry is initialised here! If this runs on the server, it might not have been...
  Sentry.captureException(error, scope => {
    return scope.setContext('Handler', {handler});
  });
}
