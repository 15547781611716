/* eslint-disable */

/*
██╗    ██╗ █████╗ ██████╗ ███╗   ██╗██╗███╗   ██╗ ██████╗ ██╗
██║    ██║██╔══██╗██╔══██╗████╗  ██║██║████╗  ██║██╔════╝ ██║
██║ █╗ ██║███████║██████╔╝██╔██╗ ██║██║██╔██╗ ██║██║  ███╗██║
██║███╗██║██╔══██║██╔══██╗██║╚██╗██║██║██║╚██╗██║██║   ██║╚═╝
╚███╔███╔╝██║  ██║██║  ██║██║ ╚████║██║██║ ╚████║╚██████╔╝██╗
 ╚══╝╚══╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚═╝

████████╗██╗  ██╗██╗███████╗    ██╗███████╗     █████╗
╚══██╔══╝██║  ██║██║██╔════╝    ██║██╔════╝    ██╔══██╗
   ██║   ███████║██║███████╗    ██║███████╗    ███████║
   ██║   ██╔══██║██║╚════██║    ██║╚════██║    ██╔══██║
   ██║   ██║  ██║██║███████║    ██║███████║    ██║  ██║
   ╚═╝   ╚═╝  ╚═╝╚═╝╚══════╝    ╚═╝╚══════╝    ╚═╝  ╚═╝

 ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██████╗
██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██╔══██╗
██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║  ██║
██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║  ██║
╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗██████╔╝
 ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═════╝

███████╗██╗██╗     ███████╗██╗    ██████╗  ██████╗ ███╗   ██╗████████╗
██╔════╝██║██║     ██╔════╝██║    ██╔══██╗██╔═══██╗████╗  ██║╚══██╔══╝
█████╗  ██║██║     █████╗  ██║    ██║  ██║██║   ██║██╔██╗ ██║   ██║
██╔══╝  ██║██║     ██╔══╝  ╚═╝    ██║  ██║██║   ██║██║╚██╗██║   ██║
██║     ██║███████╗███████╗██╗    ██████╔╝╚██████╔╝██║ ╚████║   ██║
╚═╝     ╚═╝╚══════╝╚══════╝╚═╝    ╚═════╝  ╚═════╝ ╚═╝  ╚═══╝   ╚═╝

███╗   ███╗ ██████╗ ██████╗ ██╗███████╗██╗   ██╗██╗██╗
████╗ ████║██╔═══██╗██╔══██╗██║██╔════╝╚██╗ ██╔╝██║██║
██╔████╔██║██║   ██║██║  ██║██║█████╗   ╚████╔╝ ██║██║
██║╚██╔╝██║██║   ██║██║  ██║██║██╔══╝    ╚██╔╝  ╚═╝╚═╝
██║ ╚═╝ ██║╚██████╔╝██████╔╝██║██║        ██║   ██╗██╗
╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝╚═╝        ╚═╝   ╚═╝╚═╝

*/

// @ts-ignore: Unused locals error
import {Record, List, Map} from 'immutable';

export const defaultQualifications = {eventId: null, qualifications: null, teams: null};
export const defaultQualificationPath = {type: null, qualifiedTeams: null, sourceEvent: null};
export const defaultSourceEvent = {
  type: null,
  id: null,
  name: null,
  shortName: null,
  start: null,
  end: null,
};
export const defaultQualificationTeam = {
  teamId: null,
  teamName: null,
  teamNameSuffix: null,
  teamNationality: null,
  isPlaceholder: null,
  teamInEventId: null,
};

// NOTE: If the class Record implementation holds immutable collections, they will show up
// as immutable collections here instead of their native equivalents such as arrays or objects
export type QualificationsLike = {
  [K in keyof typeof defaultQualifications]?: Qualifications[K];
};
export type QualificationPathLike = {
  [K in keyof typeof defaultQualificationPath]?: QualificationPath[K];
};
export type SourceEventLike = {
  [K in keyof typeof defaultSourceEvent]?: SourceEvent[K];
};
export type QualificationTeamLike = {
  [K in keyof typeof defaultQualificationTeam]?: QualificationTeam[K];
};

export class Qualifications extends Record(defaultQualifications) {
  eventId?: string;
  qualifications?: List<QualificationPath>;
  teams?: List<QualificationTeam>;
  static fromJS(o: QualificationsLike): Qualifications {
    return (
      o &&
      new Qualifications({
        eventId: o.eventId,
        qualifications:
          o.qualifications && List(o.qualifications).map(i => i && QualificationPath.fromJS(i)),
        teams: o.teams && List(o.teams).map(i => i && QualificationTeam.fromJS(i)),
      })
    );
  }
}
export class QualificationPath extends Record(defaultQualificationPath) {
  type?:
    | 'WomenSeriesQualifier'
    | 'LiteQuest'
    | 'WildCardFiba'
    | 'HardSeed'
    | 'Challenger'
    | 'Quest'
    | 'WorldTourMaster'
    | 'SuperQuest'
    | 'PerformanceSlot'
    | 'AutomaticAllocation'
    | 'WildCardHost'
    | 'SeasonStanding'
    | 'NotProvided'
    | 'WildCardCrossHost'
    | 'Standby'
    | 'Replacement'
    | 'ToBeAnnounced';
  qualifiedTeams?: string[];
  sourceEvent?: SourceEvent;
  static fromJS(o: QualificationPathLike): QualificationPath {
    return (
      o &&
      new QualificationPath({
        type: o.type,
        qualifiedTeams: o.qualifiedTeams,
        sourceEvent: o.sourceEvent && SourceEvent.fromJS(o.sourceEvent),
      })
    );
  }
}
export class SourceEvent extends Record(defaultSourceEvent) {
  type?: 'EVENT' | 'TOUR';
  id?: string;
  name?: string;
  shortName?: string;
  start?: string;
  end?: string;
  static fromJS(o: SourceEventLike): SourceEvent {
    return (
      o &&
      new SourceEvent({
        type: o.type,
        id: o.id,
        name: o.name,
        shortName: o.shortName,
        start: o.start,
        end: o.end,
      })
    );
  }
}
export class QualificationTeam extends Record(defaultQualificationTeam) {
  teamId?: string;
  teamName?: string;
  teamNameSuffix?: string;
  teamNationality?: string;
  isPlaceholder?: false;
  teamInEventId?: string;
  static fromJS(o: QualificationTeamLike): QualificationTeam {
    return (
      o &&
      new QualificationTeam({
        teamId: o.teamId,
        teamName: o.teamName,
        teamNameSuffix: o.teamNameSuffix,
        teamNationality: o.teamNationality,
        isPlaceholder: o.isPlaceholder,
        teamInEventId: o.teamInEventId,
      })
    );
  }
}

export default Qualifications;
