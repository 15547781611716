import React from 'react';
import cx from 'classnames';
import {List} from 'immutable';
import {connect} from 'fiba/common/utils/reactUtils';
import {Event} from 'fiba/common/core/models/api/events/Event';
import {routerPushLocation} from 'fiba/common/services/routerService';
import {getTourEvents, getTourChallengers} from 'fiba/wt/stores/tourStore';
import {ISiteConfig} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventRootLink} from 'fiba/wt/utils/linkUtils';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {isEmpty} from 'lodash';

interface OwnProps {
  tourId: string;
  season: string;
  onSelect?: (eventId: string) => any;
  siteConfig: ISiteConfig;
}

interface ReduxProps {
  mastersData: RemoteData.WebData<List<Event>>;
  challengersData: RemoteData.WebData<List<Event>>;
  currentEventId?: string;
}

interface DispatchProps {
  onSelectInternal: (eventId: string) => any;
}

type Props = OwnProps & ReduxProps & DispatchProps;

const mapStateToProps = (state, {tourId}: OwnProps): ReduxProps => {
  const currentEventId = state.getIn(['route', 'payload', 'eventId']);

  return {
    mastersData: getTourEvents(tourId, state.get('tours'), state.get('events')),
    challengersData: getTourChallengers(tourId, state.get('tours'), state.get('events')),
    currentEventId,
  };
};

// TODO: Rename to MastersEventSelect?
const mapDispatchToProps = (dispatch, {season, onSelect, siteConfig}: OwnProps): DispatchProps => ({
  onSelectInternal: eventId => {
    // NOTE: EventSelect is used only for Masters
    dispatch(
      routerPushLocation(eventRootLink(siteConfig.seasonConfig, season, eventId, 'WorldTour')),
    );

    if (onSelect) {
      onSelect(eventId);
    }
  },
});

const linkBaseCls = 'link--bare dib pv1 ph2 br2 focus-shadow';

// TODO: localise / localize
// TODO: give nav role
const EventSelectInner: React.FunctionComponent<Props> = ({
  season,
  mastersData,
  challengersData,
  currentEventId,
  onSelectInternal,
  siteConfig,
}) => {
  // Combine the two remote data sources
  // This means they will fail as one, which might bite us in the future.
  // If it does, split them and RemoteData.match separately on mastersData/challengersData
  const eventsData = RemoteData.map2(mastersData, challengersData, (masters, challengers) => ({
    masters,
    challengers,
  }));
  return RemoteData.match(eventsData, {
    Loading: () => 'Loading...',
    Success: ({masters, challengers}) => (
      <ul className="vs2 fw7">
        {masters.map(event => (
          <li key={event.id}>
            {/* Only top-level / masters events here */}
            <a
              href={eventRootLink(siteConfig.seasonConfig, season, event.id, 'WorldTour')}
              onClick={() => onSelectInternal(event.id)}
              className={cx(
                linkBaseCls,
                currentEventId === event.id ? 'bg-fullwhite dark-50 b' : 'fullwhite',
              )}
            >
              {event.shortName || event.name}
            </a>
          </li>
        ))}
        {/* Only show 'Challengers' link if the site has challengers, and
         * we have at least one challenger.
         */}
        {!!siteConfig.features.show3x3ChallengersLink && challengers.size >= 1 && (
          <li>
            <a
              href={`/${season}/challengers`}
              onClick={() => onSelectInternal('challengers')}
              className={cx(linkBaseCls, 'fullwhite')}
            >
              Challengers
            </a>
          </li>
        )}
        {
          // NOTE: We deduce whether a season has SuperQuests by checking the slug map
          // in seasonConfig. This might be a bad assumption after 2020, 2021 where these events
          // were added exceptionally. It could become a mandatory feature {hasSuperQuests: boolean}.
          !isEmpty(siteConfig.seasonConfig.seasons[season]?.superQuests) && (
            <li>
              <a
                href={`/${season}/superquests`}
                onClick={() => onSelectInternal('superquests')}
                className={cx(linkBaseCls, 'fullwhite')}
              >
                Super Quests
              </a>
            </li>
          )
        }
      </ul>
    ),
    default: () => null,
  });
};

export const EventSelect = connect<ReduxProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(EventSelectInner);
