import React from 'react';
import {Tab, Tabs} from 'fiba/wt/ui/tabs/Tabs';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {Section} from 'fiba/wt/ui/heading/Heading';
import {tourStatisticsLinkProvider} from 'fiba/wt/utils/linkUtils';
import {ProCircuitTourStatisticsTeamsTab} from 'fiba/wt/ui/tourStatisticsPage/ProCircuitTourStatisticsTeamsTab';
import {ProCircuitTourStatisticsPlayersTab} from 'fiba/wt/ui/tourStatisticsPage/ProCircuitTourStatisticsPlayersTab';
import {ProCircuitTourStatisticsDownloads} from 'fiba/wt/ui/tourStatisticsPage/ProCircuitTourStatisticsDownloads';
import {TourStatisticsSummary} from 'fiba/wt/ui/tourStatisticsPage/TourStatisticsSummary';

export type TourProCircuitStatisticsTab = 'summary' | 'teams' | 'players' | 'downloads';

export interface ITourProCircuitStatisticsPage {
  tourId: string;
  season: string;
  eventId: string;
  primaryTab: TourProCircuitStatisticsTab;
}

function getTourProCircuitStatisticsTab({primaryTab, ...props}: ITourProCircuitStatisticsPage) {
  switch (primaryTab) {
    case 'summary':
      return <TourStatisticsSummary {...{...props, isProCircuit: true}} />;
    case 'teams':
      return <ProCircuitTourStatisticsTeamsTab {...props} />;
    case 'players':
      return <ProCircuitTourStatisticsPlayersTab {...props} />;
    case 'downloads':
      return <ProCircuitTourStatisticsDownloads {...props} />;
    default:
      return null;
  }
}

export const TourProCircuitStatisticsPage: React.FC<ITourProCircuitStatisticsPage> = (
  props: ITourProCircuitStatisticsPage,
) => {
  const headingId = 'pro-circuit-tour-stats-heading';
  return (
    <Spacer vSpace={['4', '4', '5']}>
      <Spacer vSpace="3">
        <Tabs
          level="nested"
          navigationLabeledBy={headingId}
          baseUrl={`${tourStatisticsLinkProvider(props.season)}/pro-circuit`}
          extraClassName="TourProCircuitStatisticsPage-Tabs"
        >
          <Tab href="/" hrefAliases={['/summary']}>
            Summary
          </Tab>
          <Tab href="/teams">Teams</Tab>
          <Tab href="/players">Players</Tab>
          <Tab href="/downloads">Downloads</Tab>
        </Tabs>
      </Spacer>
      <Section>
        <Box debugClassName="TourStatisticsPage-Content">
          {getTourProCircuitStatisticsTab(props)}
        </Box>
      </Section>
    </Spacer>
  );
};
