import {
  createStyleProvider,
  superstyled,
  StyledHTML,
  SuperStyled,
} from 'fiba/wt/ui/stylingAPI/makeStyled';

// Same as Box, but is a Flex container
// This is to make scanning through components easier
// and mostly a convenience. 'Box' can configure the self-flex properties
// and Flex can also configure the Container properties.
export type FlexStyleProps = SuperStyled<
  | 'fontSize'
  | 'fontWeight'
  | 'color'
  | 'bgColor'
  | 'display'
  | 'align'
  | 'height'
  | 'width'
  | 'maxWidth'
  | 'minWidth'
  | 'measure'
  | 'shadow'
  | 'position'
  | 'border'
  | 'borderRadius'
  | 'borderWidth'
  | 'borderColor'
  | 'ma'
  | 'mv'
  | 'mh'
  | 'ml'
  | 'mt'
  | 'mr'
  | 'mb'
  | 'mn'
  | 'mnv'
  | 'mnh'
  | 'mnl'
  | 'mnt'
  | 'mnr'
  | 'mnb'
  | 'pa'
  | 'pv'
  | 'ph'
  | 'pl'
  | 'pt'
  | 'pr'
  | 'pb'
  | 'flex'
  | 'flexGrow'
  | 'flexShrink'
  | 'flexOrder'
  | 'alignSelf'
  | 'flexDirection'
  | 'flexWrap'
  | 'alignItems'
  | 'alignContent'
  | 'justifyContent'
>;

// By default, the Flex is a display: flex (the direction is default by the browser)
const defaultFlexProps: FlexStyleProps = {
  display: 'flex',
};

export const Flex = superstyled<FlexStyleProps, StyledHTML<'div'>>('div', defaultFlexProps);
Flex.displayName = 'Flex';

export const FlexProvider = createStyleProvider<FlexStyleProps>(defaultFlexProps);
FlexProvider.displayName = 'FlexProvider';
