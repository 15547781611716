import {map, isArray, isFunction} from 'lodash';
import CommonServices from 'fiba/common/core/models/CommonServices';

/*
  `loadFeatures` is a function that controllers can use to load other relevant resources.

  It is given a map of feature loaders that is an object keyed by feature name and it's
  values are functions that will perform the loading returning a promise. This map should
  be configured by the controller itself.
  It is also given a map of features that should be loaded, this is an object keyed by
  the feature name and it's values are configurations for the feature loaders. This map
  comes from the caller of controllers.
  Controller can also send extra arguments as a spread for the last arguments. These will
  be passed as last arguments to the feature loaders.

  @example
  ```
  // Defined where the controller is constructed
  const features = {categories: true};
  const eventController = createEventController(eventId, features);

  // Defined in controller
  const featureLoaders = {
    // `categories` matches the one given in `features` above. The value of
    // that key in `features` is given as `categoryParams` here and the extra
    // parameters given in `loadFeatures` below would be given in `extraParams`.
    categories: (categoryParams, ...extraParams) =>
      categoryActions.loadCategoriesByEvent(eventId)
  };

  const promise = Promise.all([
    eventActions.loadEvent(eventId),
    loadFeatures(featureLoaders, features)
  ]);
  ```
*/
export function loadFeatures(featureLoaders, features, ...params) {
  const promises = map(features, (featureParams, feature) => {
    if (!(feature in featureLoaders)) {
      throw Error(`Feature ${feature} not supported!`);
    }

    if (!featureParams) {
      return;
    }

    const r = featureLoaders[feature](featureParams, ...params);
    if (isArray(r)) {
      return Promise.all(r);
    }
    return r && isFunction(r.then) ? r : Promise.resolve(r);
  });

  return Promise.all(promises);
}

type IController = (services: CommonServices) => Promise<any>;
type IControllerArray = IController[];

export function composeControllers(...controllers: Array<IController | IControllerArray>) {
  return services => {
    return controllers.reduce(
      (promise, controller) =>
        promise.then(() =>
          // Support running array of controllers in parallel
          isArray(controller)
            ? Promise.all(controller.map(_controller => _controller(services)))
            : controller(services),
        ),
      Promise.resolve(),
    );
  };
}
