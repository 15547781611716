import React, {useCallback, MouseEvent} from 'react';

// Global ID for skip link fragment navigation
export const skipLinkTargetId = 'skip-link-nav';

export type Props = {
  targetRef: React.RefObject<HTMLElement>;
};

/** Skip Link component that progressively enhances from a fragment link (no JS) to programmatic focus.
 *  The programmatic focus is there to ensure that the content gets announced on all browsers.
 *  @see https://axesslab.com/skip-links/
 *
 *  Gives you a ref to assign to the target, and a SkipLink to render
 */
export const SkipLink: React.FunctionComponent<Props> = ({targetRef, children}) => {
  // Here, we enhance the onClick for the skip link with JS
  const focusTarget = useCallback(
    (ev: MouseEvent) => {
      if (targetRef && targetRef.current) {
        const node = targetRef.current;
        ev.preventDefault();
        ev.stopPropagation();

        // This is similar to the dance we do for automatic focus; we must conditionally set/remove
        // the attribute to prevent a nasty focus capture bug
        // @see FocusElement.tsx
        node.setAttribute('tabindex', '-1');
        node.focus();
        node.addEventListener('blur', () => node.removeAttribute('tabindex'));
      }
      targetRef && targetRef.current && targetRef.current.focus();
    },
    [targetRef],
  );

  return (
    <a
      href={`#${skipLinkTargetId}`}
      onClick={focusTarget}
      data-external
      className="SkipLink pv3 ph4 bg-blue-electric silver-10 focus-silver-10 show-on-focus"
    >
      {children || 'Skip to content.'}
    </a>
  );
};
