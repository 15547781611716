import React, {useState} from 'react';
import {List, Map} from 'immutable';
import {Box} from 'fiba/wt/ui/box/Box';
import {Select} from 'fiba/wt/ui/select/Select';
import {TeamListItem} from 'fiba/wt/ui/teamList/TeamListItem';
import {TeamListCondensedItem} from 'fiba/wt/ui/teamList/TeamListCondensedItem';
import {Team} from 'fiba/common/core/models/api/teams/Team';
import {ResultsStanding} from 'fiba/common/core/models/api/results/ResultsStanding';
import {EventType} from 'fiba/wt/utils/linkUtils';

interface OwnProps {
  season: string;
  eventId: string;
  eventType: EventType;
  teams: List<Team>;
  standings: Map<string, ResultsStanding>;
  isFinalStandings: boolean;
}

type TeamSortOptions = 'topStanding' | 'seedingAsc' | 'alphabeticalAsc' | 'alphabeticalDesc';

// Use this type to make sure we have comparator functions for every sorting option
type ComparatorValueMappers = {
  [key in TeamSortOptions]: (a: ResultsStanding, b: ResultsStanding) => number;
};

const comparatorValueMappers: ComparatorValueMappers = {
  alphabeticalAsc: ({teamName: a}: ResultsStanding, {teamName: b}: ResultsStanding) =>
    a.localeCompare(b),
  alphabeticalDesc: ({teamName: a}: ResultsStanding, {teamName: b}: ResultsStanding) =>
    b.localeCompare(a),
  seedingAsc: ({teamSeed: a}: ResultsStanding, {teamSeed: b}: ResultsStanding) => (a > b ? 1 : -1),
  // This is a slightly more complex comparator function as teams without a final standing should appear first (in ascending seed order)
  // follwed by the teams _with_ a final standing (in ascending final standing order). This is important as teams start being issued final
  // standings once they are knocked out of the competition. The teams without a final standing are still active in the competition as so
  // appear at the top of the list.
  topStanding: ({teamRank: a}: ResultsStanding, {teamRank: b}: ResultsStanding) => {
    // Standing (or teamRank) will be 1 or greater if defined, or then undefined.
    // As it will never _actually_ be 0 if defined, we can get away with mixing the falsy & numeric evaluations below.
    if (a && b) {
      // Both teams have a final standing, sort in ascending order
      return a > b ? 1 : -1;
    } else if (!a && b) {
      // If a is the only one missing a final standing, a goes first
      return -1;
    } else if (a && !b) {
      // If b is the only one missing a final standing, b goes first
      return 1;
    } else {
      // Finally if neither team has a final standing, fall back to whatever the default sorting order is
      // (which is seedingAsc order).
      return 0;
    }
  },
};

export const TeamList: React.FC<OwnProps> = ({
  season,
  eventId,
  eventType,
  teams,
  standings,
  isFinalStandings,
}) => {
  // Once all Final Standings are ready, sort by topStanding by default.
  const defaultSortOption = isFinalStandings ? 'topStanding' : 'seedingAsc';

  const [sortState, setSortState] = useState<TeamSortOptions>(defaultSortOption);

  const teamSortFunction = comparatorValueMappers[sortState];
  const sortedTeams = teams
    // First sort ascending seed order in the event of a tie in the main sorting below.
    // This is important to presevere, as teams should be defined in seed order if the user defined sort order cannot decide
    // who goes first.
    .sort((teamA, teamB) =>
      comparatorValueMappers['seedingAsc'](standings.get(teamA.id), standings.get(teamB.id)),
    )
    // Then sort by the user chosen sort order
    .sort((teamA, teamB) => teamSortFunction(standings.get(teamA.id), standings.get(teamB.id)))
    .toList();

  // If at least one team has a roster defined, then the full list view can be used.
  // If no team rosters have been defined, use a condensed view for a cleaner page layout.
  const shouldUseFullView = teams.some(team => team.teamMembers.size > 0);

  return (
    <>
      <Box
        display="inline_block"
        pl={['0', '0', '0', '4']}
        pb={['3', '3', '3', '4']}
        maxWidth={['5']}
      >
        <label>
          <span className="mb2 fw7">Sort teams by:</span>
          <Select
            value={sortState}
            onChange={ev => setSortState(ev.target.value as TeamSortOptions)}
          >
            <option value={'topStanding'}>Top standing</option>
            <option value={'seedingAsc'}>Seeding</option>
            <option value={'alphabeticalAsc'}>Alphabetical</option>
            <option value={'alphabeticalDesc'}>Alphabetical descending</option>
          </Select>
        </label>
      </Box>
      <ul className="vs3 TeamList">
        {sortedTeams.map(team => {
          return (
            <li key={team.id}>
              {shouldUseFullView ? (
                <TeamListItem
                  season={season}
                  eventId={eventId}
                  eventType={eventType}
                  team={team}
                  standing={standings.get(team.id)}
                />
              ) : (
                <TeamListCondensedItem
                  season={season}
                  eventId={eventId}
                  eventType={eventType}
                  team={team}
                  standing={standings.get(team.id)}
                />
              )}
            </li>
          );
        })}
      </ul>
    </>
  );
};
