import React, {useContext} from 'react';
import cx from 'classnames';
import {StyleProp, styledAPItoClassNames} from 'fiba/wt/ui/stylingAPI/makeStyled';
import {ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';

type LinkDisplayStyle = StyleProp<'display'>;

// TODO: add black and white variants, if needed
export type LinkColorTheme = 'dark' | 'light' | 'bare' | 'purple' | 'blue';

interface LinkProps extends React.HTMLProps<HTMLAnchorElement> {
  display?: LinkDisplayStyle;
  colorTheme?: LinkColorTheme;
  isExternal?: boolean;
  extraClassName?: string;
}

// Converts color theme to classnames
// The themes are designed to be accessible on silver/dark
const linkStyle: Record<LinkColorTheme, string> = {
  dark: 'link--dark',
  blue: 'link--blue',
  light: 'link--light',
  bare: 'link--bare',
  purple: 'link--purple',
};

const linkColorThemeCls = (colorTheme: LinkColorTheme) => linkStyle[colorTheme];

const newTabProps = (isExternal: boolean) =>
  isExternal ? {target: '_blank', rel: 'noopener noreferrer'} : null;

/** Link element that keeps color styling consistent.
 * Links have more complex interactions (hover/active states), so we
 * isolate them here.
 */
export const Link: React.FC<LinkProps> = props => {
  const {display, colorTheme = 'dark', isExternal, extraClassName, children, ...rest} = props;
  const styledClassname = styledAPItoClassNames({display});

  return (
    <a
      {...newTabProps(isExternal)}
      {...rest}
      className={cx(styledClassname, 'link', linkColorThemeCls(colorTheme), extraClassName)}
    >
      {children}
    </a>
  );
};

interface GenericLinkProps {
  href: string;
  extraClassName?: string;
  isExternal?: boolean;
}

/** ThemeContext-connected link */
export const StyledLink: React.FC<GenericLinkProps> = props => {
  const theme = useContext(ThemeContext);
  return <Link {...props} colorTheme={theme.linkTheme} />;
};

/** Link that looks like a button, but is a link! */
type IGhostLink = GenericLinkProps & {
  variant?: LinkColorTheme;
};

export const GhostLink: React.FC<IGhostLink> = ({
  href,
  extraClassName,
  children,
  isExternal,
  variant = 'light',
}) => (
  <a
    href={href}
    {...newTabProps(isExternal)}
    className={cx(
      'GhostLink db f6 pv2 ph3 ba br2 bw1 tc link link--bare',
      {
        'dark-50 bg-fullwhite b--dark-50 hover-fullwhite hover-bg-dark-50 focus-fullwhite focus-bg-dark-50':
          variant === 'light',
      },
      {
        'fullwhite bg-dark-50 b--dark-50 hover-dark-50 hover-bg-fullwhite focus-dark-50 focus-bg-fullwhite':
          variant === 'dark',
      },
      {
        'purple-30 bg-fullwhite b--purple-30 hover-fullwhite hover-bg-purple-30 focus-fullwhite focus-bg-purple-30':
          variant === 'purple',
      },
      extraClassName,
    )}
  >
    {children}
  </a>
);
