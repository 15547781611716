import {createClient, ContentfulClientApi} from 'contentful';

// These reflect the content type names of contentful
export enum ContentTypes {
  Article = 'article',
  CustomPage = 'customPage',
  EmbeddedMedia = 'embeddedMedia',
  Link = 'link',
  ImageLink = 'imageLink',
  // Formerly called 'Event Publication Settings', hence the ID:
  EventConfigurator = 'eventPublicationSettings',
  FooterSponsorsSection = 'footerSponsorsSection',
  SeasonConfig = 'seasonConfig',
  NavigationSubMenu = 'navigationSubMenu',
  GlobalConfig = 'globalConfig',
}

// These reflect the `articleType`-field in `article` content type
// See https://app.contentful.com/spaces/lx594qjvck87/content_types/article/fields
export enum ArticleTypes {
  // Tour
  TourGuide = 'Tour Guide',
  TourGuideSnippet = 'Tour Guide Snippet',
  TourHowToQualify = 'Tour How To Qualify',
  TourWhereToWatch = 'Tour Where To Watch',
  TourDunk = 'Tour Dunk',
  TourShootout = 'Tour Shootout',
  TourStatsDownloads = 'Tour Stats Downloads',
  TourStatsProCircuitDownloads = 'Tour Stats Pro Circuit Downloads',
  TourStatsProCircuitSummary = 'Tour Stats Pro Circuit Summary',
  TourMediaServices = 'Tour Media Services',
  TourHandbook = 'Tour Handbook',
  TourCompetitionFormat = 'Tour Competition Format',
  TourAwards = 'Tour Awards',
  TourPrizes = 'Tour Prizes',
  TourHistory = 'Tour History',
  TourRanking = 'Tour Ranking',

  // Event
  EventGuide = 'Event Guide',
  EventGuideSnippet = 'Event Guide Snippet',
  EventSponsors = 'Event Sponsors',
  EventHistory = 'Event History',
  EventDunk = 'Event Dunk',
  EventShootout = 'Event Shootout',
  EventMediaServices = 'Event Media Services',
  EventHowToQualify = 'Event How To Qualify',
  EventAwards = 'Event Awards',
  EventPrizes = 'Event Prizes',
  EventTickets = 'Event Tickets',
}

// These reflect the `mediaType`-field in `embeddedMedia` content type
export enum EmbeddedMediaTypes {
  YoutubeMainPlaylist = 'Youtube Main Playlist',
  YoutubeMainLivestream = 'Youtube Main Livestream',
  YoutubeDunkPlaylist = 'Youtube Dunk Playlist',
  YoutubeShootoutPlaylist = 'Youtube Shootout Playlist',
}

// These reflect the `type`-field in `imageLink` content type
export enum ImageLinkTypes {
  HeroImage = 'Hero Image',
  Sponsor1 = 'Sponsor 1',
  Sponsor2 = 'Sponsor 2',
  Sponsor3 = 'Sponsor 3',
}

interface ContentfulServiceOptions {
  space: string;
  environment: string | undefined;
  accessToken: string;
  host: string;
  retryOnError?: boolean;
}

export class ContentfulService {
  client: ContentfulClientApi;

  constructor(options: ContentfulServiceOptions) {
    // If no environment is specified, use 'master'
    this.client = createClient({...options, ...{environment: options.environment || 'master'}});
  }

  async getContent(contentType: ContentTypes, path?: string, include?: number) {
    const options = {
      content_type: contentType,
      include,
    };

    if (path) {
      options['fields.path'] = path;
    }

    const entries = await this.client.getEntries(options);
    return entries.items;
  }

  async queryContent(contentType: ContentTypes, query: any) {
    const options = {
      content_type: contentType,
      ...query,
    };

    const entries = await this.client.getEntries(options);
    return entries.items;
  }
}

export default function createContentfulService(options: ContentfulServiceOptions) {
  return new ContentfulService(options);
}
