// NOTE: This makes a nice home for all sorts of random ui-specific states
import {fromJS, Map} from 'immutable';
import {isArray} from 'lodash';
import {createActions, createReducer} from 'fiba/common/stores/storeUtils';

const actions = createActions(__filename, {
  toggleLiveScoresLocalTime: (eventId, isEventTime) => Map({eventId, isEventTime}),
  addCourtFilter: (eventId, courtId) => ({eventId, courtId}),
  addCategoryFilter: (eventId, categoryId) => ({eventId, categoryId}),
  addCategoryTeamsFilter: (eventId, categoryId) => ({eventId, categoryId}),

  // Raw config value
  setUiConfigValue: (key, value) => ({key, value}),
});

export default actions;

export const reducer = createReducer(
  __filename,
  {
    toggleLiveScoresLocalTime: (state, payload) =>
      state.setIn(
        ['liveScores', 'isEventTime', payload.get('eventId')],
        payload.get('isEventTime'),
      ),

    addCourtFilter: (state, {eventId, courtId}) =>
      state.setIn(['eventSchedule', eventId, 'filterByCourt'], courtId),

    addCategoryFilter: (state, {eventId, categoryId}) =>
      state.setIn(['eventSchedule', eventId, 'filterByCategory'], categoryId),

    addCategoryTeamsFilter: (state, {eventId, categoryId}) =>
      state.setIn(['eventTeams', eventId, 'filterTeamsByCategory'], categoryId),

    // Raw config value
    setUiConfigValue: (state, {key, value}) =>
      isArray(key) ? state.setIn(key, value) : state.set(key, value),
  },
  fromJS({
    liveScores: {
      isEventTime: {
        /* $eventId: <bool> */
      },
    },
    eventSchedule: {
      /* $eventId: { .. } */
    },
    eventTeams: {},
    isScheduleEventTime: true,
  }),
);
