import React, {useContext} from 'react';
import {EventProgrammability} from 'fiba/wt/ui/eventProgrammability/EventProgrammability';
import {ThemeContextProvider, lightTheme, ThemeContext} from 'fiba/wt/ui/themeContext/ThemeContext';
import {Box} from 'fiba/wt/ui/box/Box';
import {defaultNavLinkStyle, Tab} from 'fiba/wt/ui/navigation/Navigation';
import {NavigationList} from 'fiba/wt/ui/sidebarNavigation/SidebarNavigation';
import {
  getDefaultSecondaryTabs,
  getPreSecondaryTabs,
  getLiveAndPostSecondaryTabs,
  EventTabs,
  getContentfulNav,
  getTicketsPage,
} from 'fiba/wt/ui/eventLayout/EventLayoutUtils';
import {ISiteConfig, SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {connect} from 'fiba/common/utils/reactUtils';
import {EventType} from 'fiba/wt/utils/linkUtils';
import {getEventType} from 'fiba/wt/stores/eventStore';
import * as RemoteData from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from 'fiba/wt/ui/viewWebData/ViewWebData';
import {getArticleFromState, getEventConfiguration} from 'fiba/wt/stores/contentStore';
import {EventConfiguration} from 'fiba/wt/ui/eventProgrammability/EventProgrammabilityStateProvider';
import {EventColorTheme} from '../eventProgrammability/EventProgrammabilityStateProvider';
import {ArticleTypes} from '../contentfulArticle/ContentfulArticle';
import {Map} from 'immutable';

interface OwnProps {
  season: string;
  eventId: string;
  eventTab: EventTabs;
  colorTheme: EventColorTheme;
  isSingleEventConference: boolean;
}

interface ReduxProps {
  eventTypeData: RemoteData.WebData<EventType>;
  eventConfiguration: EventConfiguration;
  ticketsArticle?: Map<string, any> | undefined;
}

type Props = OwnProps & ReduxProps;

const renderNavDefault = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
  contentfulNavItems: Tab[],
  colorTheme: EventColorTheme,
  isSingleEventConference: boolean,
  ticketsPageNav?: Tab,
) => (
  <NavigationList
    tabs={[
      ...getDefaultSecondaryTabs(
        siteConfig,
        season,
        eventId,
        eventTab,
        eventType,
        isSingleEventConference,
        ticketsPageNav,
      ),
      ...contentfulNavItems,
    ]}
    navLinkStyle={
      colorTheme === EventColorTheme.dark ? defaultNavLinkStyle.light : defaultNavLinkStyle.dark
    }
    linkExtraClassName="f6 ph3 pv2 mb2"
    extraClassName="EventLayout-SidebarNavigation"
  />
);

const renderNavPre = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
  contentfulNavItems: Tab[],
  colorTheme: EventColorTheme,
  isSingleEventConference: boolean,
  ticketsPageNav?: Tab,
) => (
  <NavigationList
    tabs={[
      ...getPreSecondaryTabs(
        siteConfig,
        season,
        eventId,
        eventTab,
        eventType,
        isSingleEventConference,
        ticketsPageNav,
      ),
      ...contentfulNavItems,
    ]}
    navLinkStyle={
      colorTheme === EventColorTheme.dark ? defaultNavLinkStyle.light : defaultNavLinkStyle.dark
    }
    linkExtraClassName="f6 ph3 pv2 mb2"
    extraClassName="EventLayout-SidebarNavigation"
  />
);

const renderNavLiveAndPost = (
  siteConfig: ISiteConfig,
  season: string,
  eventId: string,
  eventTab: EventTabs,
  eventType: EventType,
  contentfulNavItems: Tab[],
  colorTheme: EventColorTheme,
  isSingleEventConference: boolean,
  ticketsPageNav?: Tab,
) => (
  <NavigationList
    tabs={[
      ...getLiveAndPostSecondaryTabs(
        siteConfig,
        season,
        eventId,
        eventTab,
        eventType,
        isSingleEventConference,
        ticketsPageNav,
      ),
      ...contentfulNavItems,
    ]}
    navLinkStyle={
      colorTheme === EventColorTheme.dark ? defaultNavLinkStyle.light : defaultNavLinkStyle.dark
    }
    linkExtraClassName="f6 ph3 pv2 mb2"
    extraClassName="EventLayout-SidebarNavigation"
  />
);

const mapStateToProps = (state, {eventId}: OwnProps): ReduxProps => ({
  eventTypeData: getEventType(state.get('events'), eventId),
  eventConfiguration: getEventConfiguration(state, eventId),
  ticketsArticle: getArticleFromState({
    state,
    contentPathArr: ['events', eventId],
    articleType: ArticleTypes.EventTickets,
  }),
});

const EventSidebarLeftImpl: React.FunctionComponent<Props> = ({
  season,
  eventId,
  eventTab,
  eventTypeData,
  eventConfiguration,
  children,
  colorTheme,
  ticketsArticle,
  isSingleEventConference,
}) => {
  const siteConfig = useContext(SiteConfigContext);
  const theme = useContext(ThemeContext);

  return (
    <Spacer
      vSpace="3"
      ph="3"
      width={['100', '100', '5']}
      position="sticky"
      extraClassName="z-sidebar top-1"
    >
      <Box
        borderRadius="1"
        bgColor={theme.bgColor}
        display={['none', 'none', 'block']}
        extraClassName="EventLayout-SecondaryNavigation EventLayout-SecondaryNavigation--desktop overflow-y-visible"
      >
        <ViewWebData data={eventTypeData}>
          {eventType => {
            const contentfulNavItems = getContentfulNav({
              eventConfiguration,
              eventTab,
              season,
              eventId,
              siteConfig,
              eventType,
            });

            const ticketsPageNav = getTicketsPage({
              ticketsArticle,
              eventTab,
              season,
              eventId,
            });

            return (
              // TODO: localise label. Consider text node and aria-labelledby; would help sighted users as well
              <nav aria-label="Event content">
                <EventProgrammability
                  season={season}
                  eventId={eventId}
                  renderDefault={() =>
                    renderNavDefault(
                      siteConfig,
                      season,
                      eventId,
                      eventTab,
                      eventType,
                      contentfulNavItems,
                      colorTheme,
                      isSingleEventConference,
                      ticketsPageNav,
                    )
                  }
                  renderPre={() =>
                    renderNavPre(
                      siteConfig,
                      season,
                      eventId,
                      eventTab,
                      eventType,
                      contentfulNavItems,
                      colorTheme,
                      isSingleEventConference,
                      ticketsPageNav,
                    )
                  }
                  renderLive={() =>
                    renderNavLiveAndPost(
                      siteConfig,
                      season,
                      eventId,
                      eventTab,
                      eventType,
                      contentfulNavItems,
                      colorTheme,
                      isSingleEventConference,
                      ticketsPageNav,
                    )
                  }
                  renderPost={() =>
                    renderNavLiveAndPost(
                      siteConfig,
                      season,
                      eventId,
                      eventTab,
                      eventType,
                      contentfulNavItems,
                      colorTheme,
                      isSingleEventConference,
                      ticketsPageNav,
                    )
                  }
                />
              </nav>
            );
          }}
        </ViewWebData>
      </Box>

      {children && (
        // TODO: Lift this value up and `useMemo` for it
        <ThemeContextProvider theme={{...lightTheme, fontSize: '7'}}>
          {children}
        </ThemeContextProvider>
      )}
    </Spacer>
  );
};

export const EventSidebarLeft = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  EventSidebarLeftImpl,
);
