import {commonEnv} from 'fiba/common/config/env';
import routes from 'fiba/wt/config/routes';

import BrowserServices from 'fiba/wt/core/models/BrowserServices';
import * as rootStore from 'fiba/wt/stores/rootStore';
import {createHistoryService} from 'fiba/common/client/services/historyService';
import createRouterService, {createMiddleware} from 'fiba/common/services/routerService';
import createStoreService from 'fiba/common/services/storeService';
import createContentfulService from 'fiba/wt/services/contentfulService';
import createApiClientService, {oemResponseMiddleware} from 'fiba/common/services/apiClientService';
import createContentApiClientService from 'fiba/common/services/contentApiClientService';
import createCacheService from 'fiba/common/services/cacheService';
import createLocalizerService from 'fiba/common/services/localizerService';

import cacheControlMiddleware from 'fiba/common/services/cacheControlMiddleware';
import requestIdInsertingMiddleware from 'fiba/common/services/requestIdInsertingMiddleware';
import analyticsMiddleware from 'fiba/common/client/services/analyticsMiddleware';
import {createScoresClientV2Middleware} from 'fiba/common/services/scoresClientV2Middleware';

import {TourLayout} from 'fiba/wt/ui/tourLayout/TourLayout';

export function createBrowserServices() {
  const initialState = rootStore.deserializeState(window['__fiba'].initialState || {});
  const lang = initialState.lang;
  const translations = initialState.translations;
  const services = ({} as unknown) as BrowserServices;

  services.history = createHistoryService();
  services.apiClient = createApiClientService(
    {
      baseURL: `${window.location.protocol}//${window.location.host}/api`,
    },
    oemResponseMiddleware(false),
    requestIdInsertingMiddleware('FIBAWTCLIENT'),
    commonEnv.ENABLE_CACHE_CONTROL ? cacheControlMiddleware(window.location.href) : axios => axios,
  );
  services.contentApiClient = createContentApiClientService(
    {
      baseURL: `${window.location.protocol}//${window.location.host}/api/content`,
    },
    requestIdInsertingMiddleware('FIBAWTCLIENT'),
    commonEnv.ENABLE_CACHE_CONTROL ? cacheControlMiddleware(window.location.href) : axios => axios,
  );
  services.store = createStoreService(
    services,
    rootStore.rootReducer,
    initialState,
    createMiddleware(services),
    createScoresClientV2Middleware(commonEnv.SCORES_BACKEND_URL),
    commonEnv.GA_GOOGLE_TAG_WT
      ? analyticsMiddleware(
          window['gtag'],
          commonEnv.GA_GOOGLE_TAG_WT,
          window.location.pathname,
          lang,
        )
      : () => next => next,
  );
  services.contentful = createContentfulService({
    space: commonEnv.CONTENTFUL_SPACE,
    environment: commonEnv.CONTENTFUL_ENVIRONMENT,
    accessToken: commonEnv.CONTENTFUL_ACCESS_TOKEN,
    host: commonEnv.CONTENTFUL_HOST_URL,
    retryOnError: false,
  });
  services.cache = createCacheService(services);
  services.localizer = createLocalizerService(lang, translations);
  services.dispatch = services.store.dispatch;

  services.router = createRouterService(services, routes, [], TourLayout);

  return services;
}
