import TeamGameStats from 'fiba/common/core/models/api/stats/TeamGameStats';
import React from 'react';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {hcell, cell, decimalOrPlaceholder} from 'fiba/wt/ui/gameBoxScore/gameBoxScoreUtils';

interface IPossesionStat {
  type: string;
  count: number;
  ppp?: number;
}

type TableColumns = 'TYPE' | 'COUNT' | 'PPP';
type ColumnSpec = IColumnSpec<IPossesionStat>;
type ColumnSpecRecord = () => Record<TableColumns, ColumnSpec>;

const createColumnsSpecs: ColumnSpecRecord = () => ({
  TYPE: createSpec('Type', hcell('type'), 'text'),
  COUNT: createSpec('Count', cell('count'), 'number'),
  PPP: createSpec('PPP', cell('ppp'), 'number', 'points per possesion'),
});

const createSpec = (displayName, renderer, dataType, srName?) => ({
  name: displayName,
  srName: srName || displayName,
  dataType,
  renderColumn: renderer,
});

const mapGameStatToPossessionStat = (teamStats: TeamGameStats): IPossesionStat[] => [
  {
    type: 'Total',
    count: teamStats.totalPossessions,
    ppp: decimalOrPlaceholder(teamStats.totalPointsPerPossession),
  },
  {
    type: 'POS-1PT',
    count: teamStats.onePointPossessions,
    ppp: decimalOrPlaceholder(teamStats.onePointPointsPerPossession),
  },
  {
    type: 'POS-FT',
    count: teamStats.freeThrowPossessions,
    ppp: decimalOrPlaceholder(teamStats.freeThrowPointsPerPossession),
  },
  {
    type: 'POS-2PT',
    count: teamStats.twoPointPossessions,
    ppp: decimalOrPlaceholder(teamStats.twoPointPointsPerPossession),
  },
  {type: 'Turnovers', count: teamStats.turnovers},
];

interface Props {
  captionId: string;
  caption?: React.ReactNode;
  teamStats: TeamGameStats;
}

const GameStatsTeamPossessionsTable: React.FunctionComponent<Props> = ({
  caption,
  captionId,
  teamStats,
}) => (
  <DataTable<TableColumns, IPossesionStat>
    captionId={captionId}
    caption={caption}
    rows={mapGameStatToPossessionStat(teamStats)}
    columns={['TYPE', 'COUNT', 'PPP']}
    columnsSpec={createColumnsSpecs()}
    headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
    headerExtraClassName="dark-20"
    rowExtraClassName="striped--light-even bb bw1 b--silver-20"
  />
);

export default GameStatsTeamPossessionsTable;
