import React from 'react';
import {DataTable, IColumnSpec} from 'fiba/wt/ui/dataTable/DataTable';
import {VisuallyHidden} from 'fiba/wt/ui/visuallyHidden/VisuallyHidden';
// Static data that shows users the minimum criteria in generating the following stats
// These were given from the FIBA 3x3 managing team.
const STATS_MINIMA_TABLE = {
  columns: [
    'CATEGORY',
    'WINNING PERCENTAGES',
    'FREE THROW PERCENTAGE',
    '1-POINT PERCENTAGE',
    '2-POINT PERCENTAGE',
    'ALL OTHER STATS',
  ],
  rows: [
    [
      'NUMBER OF EVENTS',
      'MINIMUM NUMBER OF GAMES',
      'MINIMUM NUMBER OF MAKES',
      'MINIMUM NUMBER OF MAKES',
      'MINIMUM NUMBER OF MAKES',
      'MINIMUM NUMBER OF GAMES',
    ],
    ['1', '1', '1', '1', '1', '1'],
    ['2', '1', '1', '1', '1', '1'],
    ['3', '3', '5', '5', '5', '3'],
    ['4', '3', '5', '5', '5', '3'],
    ['5', '3', '5', '5', '5', '3'],
    ['6', '6', '5', '10', '5', '5'],
    ['7', '6', '5', '10', '5', '5'],
    ['8', '6', '5', '10', '10', '5'],
    ['9', '6', '5', '10', '10', '5'],
    ['10', '10', '10', '15', '10', '5'],
    ['11', '10', '10', '15', '10', '5'],
    ['12', '10', '10', '15', '10', '5'],
    ['13', '10', '10', '15', '10', '5'],
    ['14', '10', '10', '15', '10', '5'],
    ['15', '10', '10', '20', '15', '5'],
    ['16', '15', '10', '20', '15', '5'],
    ['17', '15', '10', '20', '15', '5'],
    ['18', '15', '10', '20', '15', '5'],
    ['19', '15', '10', '20', '15', '5'],
    ['20 and 20+', '15', '15', '15', '20', '10'],
  ],
};

type StatsMinimaTableColumns =
  | 'CATEGORY'
  | 'WINNING PERCENTAGES'
  | 'FREE THROW PERCENTAGE'
  | '1-POINT PERCENTAGE'
  | '2-POINT PERCENTAGE'
  | 'ALL OTHER STATS';

const columnSpec = (name, columnIndex): IColumnSpec<string[]> => ({
  dataType: 'number',
  name: name,
  renderColumn: ({rowData, Td, getTdProps}) => (
    <Td {...getTdProps({fontSize: '6'})}>{rowData[columnIndex]}</Td>
  ),
});
/**
 * Returns a record like this
 *
 * @example
 * {
 *    'CATEGORY': {
 *      'name': 'CATEGORY',
 *      'dataType': 'number',
 *      'renderColumn': ({rowData, Td, getTdProps}) => <Td>{rowData}</Td>
 *    },
 *    ...
 * }
 */
const statMinimaTableSpec = (): Record<StatsMinimaTableColumns, IColumnSpec<string[]>> =>
  STATS_MINIMA_TABLE.columns.reduce(
    (accumulatedRecord, column, index) => ({
      ...accumulatedRecord,
      [column]: columnSpec(column, index),
    }),
    {} as Record<StatsMinimaTableColumns, IColumnSpec<string[]>>,
  );

export function StatsMinimaTable() {
  return (
    <DataTable
      striped={true}
      caption={<VisuallyHidden>Tour & Pro-circuit statistical minima</VisuallyHidden>}
      captionId="stats-minimum-table-caption"
      rows={STATS_MINIMA_TABLE.rows}
      columns={STATS_MINIMA_TABLE.columns as StatsMinimaTableColumns[]}
      columnsSpec={statMinimaTableSpec()}
      headerStyleProps={{bgColor: 'fullwhite', fontWeight: '4'}}
      headerExtraClassName="dark-30 fw6"
      rowExtraClassName="bb bw1 b--silver-20"
    />
  );
}
