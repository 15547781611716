import React from 'react';
import {connect as _connect} from 'react-redux';
// HACK: typescript commonjs woes
import {bindActionCreators} from 'redux';
import {isArray} from 'lodash';
import {commonEnv} from 'fiba/common/config/env';

export {bindActionCreators};

// TODO: Install @types/react-redux
// This is a temporary hack around us converting the project to typescript from javascript
// and installing typings for react-redux would cause a major hassle to fix all type errors
// that would be caused by it. So type this function locally and use it where we have
// proper typings for component props
export function connect<
  TStateProps,
  TDispatchProps,
  TOwnProps,
  FinalProps = TStateProps & TDispatchProps & TOwnProps
>(
  mapStateToProps?: (state: any, props?: TOwnProps) => TStateProps,
  mapDispatchToProps?: (dispatch: any, props?: TOwnProps) => TDispatchProps,
  mergeProps?: (
    stateProps: TStateProps,
    dispatchProps: TDispatchProps,
    ownProps: TOwnProps,
  ) => FinalProps,
): (component: React.ComponentType<FinalProps>) => React.ComponentType<TOwnProps> {
  return _connect(mapStateToProps, mapDispatchToProps, mergeProps);
}

//
//  Create a render prop connector component
interface RenderPropWrapperChildProps<P> {
  children?: (props: P) => React.ReactNode;
}

const RenderPropWrapper = ({children, ...props}) => children(props);

export function connectToRenderProps<TStateProps, TDispatchProps, TOwnProps>(
  mapStateToProps?: (state: any, props?: TOwnProps) => TStateProps,
  mapDispatchToProps?: (dispatch: any, props?: TOwnProps) => TDispatchProps,
): React.ComponentType<
  TOwnProps & RenderPropWrapperChildProps<TStateProps & TDispatchProps & TOwnProps>
> {
  return connect(mapStateToProps, mapDispatchToProps)(RenderPropWrapper as any);
}

//
// WHAT THE WHY? TODO: Please let's remove this
// Redux helper to connect function component to given state keys
export const reduxify = (keys, fn) => {
  const map = state =>
    keys.reduce(
      (o, key) => ({
        ...o,
        [key]: state.get(key),
      }),
      {},
    );

  return _connect(map)(fn);
};

// Replaces newline characters with <br/> React element in given string
export const brify = str => {
  const r = str
    .split('\n')
    .reduce(
      (acc, s) =>
        acc.concat([
          React.createElement('span', {key: acc.length}, s),
          React.createElement('br', {key: acc.length + 1}),
        ]),
      [],
    );
  if (r.length) {
    r.pop();
  } // Remove trailing <br/>
  return r;
};

// TODO: Add the types here
export const preventDefault = fn => ev => {
  if (ev && typeof ev.preventDefault === 'function') {
    ev.preventDefault();
  }
  return fn ? fn(ev) : null;
};

interface RenderIfVisibleProps {
  isVisible?: boolean;
}

export const renderIfVisible = Component =>
  class extends React.Component<RenderIfVisibleProps> {
    constructor(props, context) {
      super(props, context);
    }

    render() {
      const {isVisible} = this.props;

      return isVisible ? React.createElement(Component, this.props) : null;
    }
  };

export const renderList = items => items.map((item, key) => React.cloneElement(item, {key}));

export function findChildrenOfType(type, node) {
  const props = (node && node.props) || {};
  const children = React.Children.toArray(props.children) || [];

  return children.filter((child: React.ReactElement<any>) => child && child.type === type);
}

export function findChildrenOfTypeRecursively(type, node) {
  return reducer([], node);

  function reducer(results, child) {
    if (!child || !React.Children.count(child)) {
      return results;
    }

    if (isArray(child)) {
      return child.reduce(reducer, results);
    }

    const props = child.props || {};
    const children = props.children || [];

    return reducer(
      child.type === type ? results.concat([child]) : results,
      children && React.Children.count(children) === 1
        ? children
        : React.Children.toArray(children),
    );
  }
}

export function bemModifiers(base: string, modifiers: Array<string | boolean>) {
  if (!base) {
    return '';
  }

  const filteredModifiers = modifiers.filter(mod => !!mod);
  if (!filteredModifiers.length) {
    return base;
  }

  const modifiersString = filteredModifiers.map(mod => `${base}--${mod}`).join(' ');
  return `${base} ${modifiersString}`;
}

export const assetPath = (path: string) => `/static/${commonEnv.FIBA_BUILD_ID}/${path}`;

export class ScrollToTop extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const {children} = this.props;

    return children;
  }
}
