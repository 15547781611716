import React, {useContext, isValidElement} from 'react';
import GalleryPreview from 'fiba/common/core/models/api/media/GalleryPreview';
import {connect} from 'fiba/common/utils/reactUtils';
import {Loading} from 'fiba/wt/ui/loading/Loading';
import {PhotoGallerySummary} from 'fiba/wt/ui/photoGallery/PhotoGallerySummary';
import {List} from 'immutable';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {eventPhotoGalleryLinkProvider, EventType} from 'fiba/wt/utils/linkUtils';
import {getEventType} from 'fiba/wt/stores/eventStore';
import {WebData} from 'fiba/wt/utils/RemoteData';
import {ViewWebData} from '../viewWebData/ViewWebData';
import {Heading, Section} from 'fiba/wt/ui/heading/Heading';
import {getConferenceIdForEvent} from 'fiba/nationsleague/store';

interface OwnProps {
  eventId: string;
  season: string;
  heading?: React.ReactNode | string;
  emptyStateContent?: React.ReactNode;
}

interface ReduxProps {
  galleries: List<GalleryPreview>;
  eventTypeData: WebData<EventType>;
  metaPaths?: string[];
}

export const mapStateToProps = (state, {eventId, season}: OwnProps): ReduxProps => {
  // If it's an NL event, fetch conference galleries instead
  const conferenceId = getConferenceIdForEvent(state, season, eventId);

  const metaPaths = conferenceId
    ? [`media/__meta/gallerypreviews/conferences/${conferenceId}`]
    : [`media/__meta/gallerypreviews/events/${eventId}`];
  const galleries: List<GalleryPreview> = conferenceId
    ? state.getIn(['media', 'gallerypreviews', 'conferences', conferenceId])
    : state.getIn(['media', 'gallerypreviews', 'events', eventId], List());

  // TODO: HACK: We should use getEventType to handle data loading here
  const eventTypeData = getEventType(state.get('events'), eventId);

  return {
    galleries,
    eventTypeData,
    metaPaths,
  };
};

/** Overview of a Event's galleries, as a single list/grid */
const _EventPhotoGallery: React.SFC<OwnProps & ReduxProps> = ({
  eventId,
  heading,
  eventTypeData,
  season,
  metaPaths,
  galleries,
  emptyStateContent = null,
}) => {
  const {seasonConfig} = useContext(SiteConfigContext);
  return (
    <div className="EventPhotoGallery">
      {/* NOTE: We are stacking loading states here, because events are WebData
          based, while galleries are metaPaths
      */}
      <Loading metaPaths={metaPaths}>
        {() => (
          <ViewWebData data={eventTypeData}>
            {eventType => {
              if (!galleries.size) {
                return emptyStateContent;
              }
              return (
                <section>
                  <Section>
                    {typeof heading === 'string' && (
                      <Heading fontSize="3" pb="3">
                        {heading}
                      </Heading>
                    )}
                    {isValidElement(heading) && heading}
                    <ul className="grid gt-gallery">
                      {galleries.map((photoGallery, index) => (
                        <PhotoGallerySummary
                          key={photoGallery.galleryId}
                          title={photoGallery.title}
                          galleryUrl={eventPhotoGalleryLinkProvider(
                            seasonConfig,
                            season,
                            eventId,
                            eventType,
                          )(photoGallery.galleryId)}
                          imageUrl={photoGallery.imageUrl}
                          total={photoGallery.total}
                          variant="subgrid"
                          index={index}
                        />
                      ))}
                    </ul>
                  </Section>
                </section>
              );
            }}
          </ViewWebData>
        )}
      </Loading>
    </div>
  );
};

export const EventPhotoGallery = connect<ReduxProps, {}, OwnProps>(mapStateToProps)(
  _EventPhotoGallery,
);
EventPhotoGallery.displayName = 'EventPhotoGallery';
