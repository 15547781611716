import React, {useContext} from 'react';
import {Box} from 'fiba/wt/ui/box/Box';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {EventFastestGames} from 'fiba/wt/ui/fastestGames/EventFastestGames';
import {SiteConfigContext} from 'fiba/wt/ui/siteConfigContext/SiteConfigContext';
import {EventType} from 'fiba/wt/utils/linkUtils';
import {FastestGamesSponsorLogo} from 'fiba/wt/ui/svg/FastestGamesSponsorLogo';
import {CategoryGenderTab} from 'fiba/wt/utils/categories';

export interface IEventStatisticsFastestGames {
  season: string;
  eventId: string;
  eventType: EventType;
  genderTab?: CategoryGenderTab;
}

// https://github.com/futurice/fiba-3x3-play/issues/2601
const SHOW_FASTEST_GAMES_SPONSOR = false;

export const EventStatisticsFastestGames: React.FunctionComponent<IEventStatisticsFastestGames> = props => {
  const siteConfig = useContext(SiteConfigContext);
  return (
    <Spacer vSpace="4" debugClassName="EventStatisticsFastestGames">
      {SHOW_FASTEST_GAMES_SPONSOR &&
      siteConfig.features.showFastestGamesSponsor &&
      props.eventType === 'WorldTour' ? (
        <Box textAlign="center">
          {/* NOTE: P and BlockText are both not fully centerable, hence regular p here */}
          <p>Presented by</p>
          <Box pa="3">
            <FastestGamesSponsorLogo width="225px" />
          </Box>
        </Box>
      ) : null}
      <EventFastestGames {...props} />
    </Spacer>
  );
};
