import React from 'react';
import {ContentfulArticle, ArticleTypes} from 'fiba/wt/ui/contentfulArticle/ContentfulArticle';
import {Spacer} from 'fiba/wt/ui/spacer/Spacer';
import {CompetitionCalendar} from 'fiba/wt/ui/competitionCalendar/CompetitionCalendar';
import {PageHeading} from 'fiba/wt/ui/pageHeading/PageHeading';
import {Box} from 'fiba/wt/ui/box/Box';

interface Props {
  season: string;
  tourId: string;
}

export const TourHowToQualifyPage: React.FunctionComponent<Props> = ({season, tourId}) => {
  return (
    <Spacer mb="3" pa={['3', '3', '0']}>
      <PageHeading containerProps={{mb: '3', mh: '1'}} text="How to Qualify" />
      <CompetitionCalendar
        id="howToQualifyEmbed"
        type="how-to-qualify"
        iframeTitle="Competition Calendar"
        hideSeasonSelector={true}
        defaultSeason={parseInt(season)}
        displayMode="stripped"
      />

      <Box ph="2" pv="4">
        <ContentfulArticle
          contentPath={`tours/${tourId}`}
          articleType={ArticleTypes.TourHowToQualify}
        />
      </Box>
    </Spacer>
  );
};
